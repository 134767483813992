import React from "react";
import { Button } from "@nef/core";

import { FinalizeType } from "../../../contexts/product";

interface CapFinalizeButtonProps {
  tryFinalize: (type: FinalizeType) => boolean;
}

const CapFinalizeButton = ({ tryFinalize }: CapFinalizeButtonProps) => {
  return (
    <div data-testid="finalizeButton">
      <Button
        onClick={() => tryFinalize("submit_for_approval")}
        data-testid="capForm_submitForApproval"
        disabled={false}
      >
        Submit for Review
      </Button>
    </div>
  );
};

export default CapFinalizeButton;
