import React, { useEffect, useState } from "react";

import { WizardProps } from "./types";
import WizardRoutes from "./WizardRoutes";
import styles from "./Wizard.module.scss";
import WizardRedirect from "./WizardRedirect";
import RouterNavigator from "./RouterNavigator";
import WizardContextProvider from "./WizardContextProvider";
import StateNavigator from "./StateNavigator";

const Wizard = ({
  header,
  children,
  redirects,
  stateNavigator = false
}: WizardProps) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const Navigator = stateNavigator ? StateNavigator : RouterNavigator;

  return (
    <Navigator>
      {({ navigateTo, matchStep }) => (
        <WizardContextProvider matchStep={matchStep} navigateTo={navigateTo}>
          <div className={`wizard-body ${styles.wizard}`}>
            <div className="wizard-nav" data-testid="Wizard_nav">
              {header}
            </div>
            <div
              className={`wizard-body ${styles.body}`}
              data-testid="Wizard_body"
            >
              {children}
            </div>
          </div>
          <WizardRoutes />
          {isMounted && (redirects || <WizardRedirect />)}
        </WizardContextProvider>
      )}
    </Navigator>
  );
};

export default Wizard;
