import React from "react";
import { FormSelectCreatable } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at } from "lodash";

import { formSchema } from "../../../schema";

import styles from "./index.module.scss";

const WelcomeEmailCC = ({ formFieldName }: { formFieldName: string }) => {
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext<yup.Asserts<typeof formSchema>>();

  const formFieldErrors = at(errors, formFieldName)[0];
  const formValue = getValues(formFieldName as any);

  const errorMessage =
    Array.isArray(formFieldErrors) && formFieldErrors.length > 0
      ? formFieldErrors.find(error => error && error.value)?.value.message
      : formFieldErrors?.message;

  return (
    <div className={styles["welcome-cc-container"]}>
      <Controller
        key={formValue}
        control={control}
        name={formFieldName as any}
        render={({ field: { onChange, value } }) => (
          <FormSelectCreatable
            optional
            placeholder="Select..."
            label="Welcome Email CC"
            data-testid="OrganizationsAddProductDrawer_welcomeEmailCc"
            className="OrganizationsAddProductDrawer_welcomeEmailCc"
            value={value}
            isMulti
            isClearable
            onChange={e => {
              onChange(e.value);
            }}
            feedback={errorMessage}
            invalid={!!formFieldErrors}
            classNamePrefix="modalFormSelect"
            noOptionsMessage={() => "Start typing to add contacts"}
          />
        )}
      />
    </div>
  );
};

export default WelcomeEmailCC;
