import React, { useState } from "react";
import { Button } from "@nef/core";

import { ConfirmationModal } from "../../../modals/ConfirmationModal";

interface PlanFormDeactivateButtonProps {
  deactivatePlan: () => void;
}

const PlanFormDeactivateButton = ({
  deactivatePlan
}: PlanFormDeactivateButtonProps) => {
  const [shouldConfirmDeactivate, setShouldConfirmDeactivate] = useState(false);

  const onConfirm = () => {
    setShouldConfirmDeactivate(false);
    deactivatePlan();
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setShouldConfirmDeactivate(true)}
        data-testid="planForm_deactivate"
      >
        Grandfather Plan
      </Button>
      <ConfirmationModal
        isOpen={shouldConfirmDeactivate}
        question="Are you sure you want to grandfather this pricing plan? This pricing plan will no longer be visible to users, but existing users will remain on this pricing plan."
        onConfirm={() => onConfirm()}
        onDismiss={() => setShouldConfirmDeactivate(false)}
      />
    </>
  );
};

export default PlanFormDeactivateButton;
