import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect, FormField, FormTextArea } from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { toast } from "react-toastify";

import { fetchVendors } from "../../../api/api";
import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import buildCapProductSchema from "../schema";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { CapProductToCreate, Vendor } from "../../../api/types";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import { BaseStepProps } from "../types";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";

export const LICENSED_FROM_VENDOR_DROPDOWN = ["No", "Yes"].map(value => ({
  value: value === "Yes",
  label: value
}));

const PRICING_OPTION_DROPDOWN = [
  "Pricing Plan",
  "Contact Sales",
  "Publish Internally"
].map(value => ({
  value,
  label: value
}));

const CapSurveyFormSection4Step2 = ({ fields }: BaseStepProps) => {
  const { product, saveCapMetadata } = useCapProductContext();
  const productSchema = buildCapProductSchema();
  const stepSchema = productSchema.pickNested(fields);

  // If neither value is set, set vendorId to -1 so vendor dropdown shows placeholder instead of `Other`
  if (
    !product.capProductMetadatum.vendorId &&
    !product.capProductMetadatum.vendorName
  )
    product.capProductMetadatum.vendorId = -1;

  const form = useForm<CapProductToCreate>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    setValue,
    reset
  } = form;

  const { dataSource } = product.capProductMetadatum;
  const { licensedFromVendor, vendorId } = watch("capProductMetadatum");

  const sourceIsThirdPartyVendor = dataSource === "Third-party vendors";

  useEffect(() => {
    reset(product);
  }, [product]);

  const [vendorOptions, setVendorOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<CapProductToCreate> = async formData => {
    await saveCapMetadata(formData.capProductMetadatum);
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  useEffect(() => {
    fetchVendors().then(response => {
      const sortedVendorDropdownOptions = response.data.vendors
        .map((v: Vendor) => {
          return {
            value: v.id,
            label: v.name
          };
        })
        .sort((vendorOne: any, vendorTwo: any) => {
          return vendorOne.label.localeCompare(vendorTwo.label.toLowerCase());
        });

      sortedVendorDropdownOptions.push({
        value: null,
        label: "Other"
      });

      setVendorOptions(sortedVendorDropdownOptions);
    });
  }, []);

  const handleVendorChange = (
    onChange: (...event: any[]) => void,
    formSelectOnChangeParam: any
  ) => {
    const newValue = extractFormSelectOnChangeValue(formSelectOnChangeParam);
    onChange(newValue);

    if (newValue) {
      setValue("capProductMetadatum.vendorName", null, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection4Step2">
          {sourceIsThirdPartyVendor && (
            <CapSurveyFormFieldRow tooltip={TOOLTIP.LICENSED_FROM_VENDOR}>
              <Controller
                name="capProductMetadatum.licensedFromVendor"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormSelect
                      id="gdprRestrictions"
                      name={name}
                      label="Licenced from Vendor"
                      value={
                        LICENSED_FROM_VENDOR_DROPDOWN.find(
                          o => o.value === value
                        ) || null
                      }
                      placeholder="Licenced from Vendor"
                      options={LICENSED_FROM_VENDOR_DROPDOWN}
                      optional={false}
                      isClearable={false}
                      onChange={(formSelectOnChangeParam: any) => {
                        onChange(
                          extractFormSelectOnChangeValue(
                            formSelectOnChangeParam
                          )
                        );
                      }}
                      classNamePrefix="licensedFromVendor"
                    />
                  );
                }}
              />
            </CapSurveyFormFieldRow>
          )}

          {licensedFromVendor && (
            <CapSurveyFormFieldRow
              tooltip={TOOLTIP.THIRD_PARTY_PROVIDER_LICENCE}
            >
              <Controller
                name="capProductMetadatum.thirdPartyProviderLicence"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormTextArea
                    id="vendorContactPhone"
                    name={name}
                    label="Third Party Provider Licence"
                    value={value || ""}
                    optional={false}
                    rows={4}
                    onChange={onChange}
                    data-testid="capSurveyForm_thirdPartyProviderLicence"
                  />
                )}
              />
            </CapSurveyFormFieldRow>
          )}

          {sourceIsThirdPartyVendor && (
            <>
              <CapSurveyFormFieldRow tooltip={TOOLTIP.VENDOR_NAME}>
                <Controller
                  name="capProductMetadatum.vendorId"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <FormSelect
                        id="vendorId"
                        name={name}
                        label="Vendor Name"
                        value={
                          vendorOptions.find(o => o.value === value) || null
                        }
                        placeholder="Select a vendor"
                        options={vendorOptions}
                        optional={false}
                        isClearable={false}
                        onChange={(formSelectOnChangeParam: any) => {
                          handleVendorChange(onChange, formSelectOnChangeParam);
                        }}
                        classNamePrefix="vendorId"
                      />
                    );
                  }}
                />
              </CapSurveyFormFieldRow>
              {vendorId === null && (
                <CapSurveyFormFieldRow tooltip="">
                  <Controller
                    name="capProductMetadatum.vendorName"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <FormField
                        id="vendorName"
                        name={name}
                        label="Vendor Name"
                        value={value || ""}
                        optional={false}
                        onChange={onChange}
                        data-testid="capSurveyForm_vendorName"
                      />
                    )}
                  />
                </CapSurveyFormFieldRow>
              )}
            </>
          )}

          {sourceIsThirdPartyVendor && (
            <CapSurveyFormFieldRow tooltip={TOOLTIP.VENDOR_CONTACT}>
              <Controller
                name="capProductMetadatum.vendorContactName"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormField
                    id="vendorContactName"
                    name={name}
                    label="Vendor Contact Name"
                    value={value || ""}
                    optional={false}
                    onChange={onChange}
                    data-testid="capSurveyForm_vendorContactName"
                  />
                )}
              />
              <Controller
                name="capProductMetadatum.vendorContactEmail"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormField
                    id="vendorContactName"
                    name={name}
                    label="Vendor Contact Email"
                    value={value || ""}
                    optional={false}
                    onChange={onChange}
                    type="email"
                    data-testid="capSurveyForm_vendorContactEmail"
                  />
                )}
              />

              <Controller
                name="capProductMetadatum.vendorContactPhone"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormField
                    id="vendorContactPhone"
                    name={name}
                    label="Vendor Contact Phone Number"
                    value={value || ""}
                    optional={true}
                    onChange={onChange}
                    data-testid="capSurveyForm_vendorContactPhone"
                  />
                )}
              />
            </CapSurveyFormFieldRow>
          )}

          {!sourceIsThirdPartyVendor && (
            <CapSurveyFormFieldRow
              tooltip={TOOLTIP.DATA_LICENCE_TERMS_CONDITIONS}
            >
              <Controller
                name="capProductMetadatum.dataLicenceTermsConditions"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormTextArea
                    id="dataLicenceTermsConditions"
                    name={name}
                    label="Data Licence Terms & Conditions"
                    value={value || ""}
                    optional={false}
                    rows={4}
                    onChange={onChange}
                    data-testid="capSurveyForm_dataLicenceTermsConditions"
                  />
                )}
              />
            </CapSurveyFormFieldRow>
          )}

          <CapSurveyFormFieldRow
            tooltip={TOOLTIP.PROCUREMENT_CONTRACTUAL_CONSIDERATIONS}
          >
            <Controller
              name="capProductMetadatum.procurementContractualConsiderations"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormTextArea
                  id="procurementContractualConsiderations"
                  name={name}
                  label="Procurement and Contractual Considerations"
                  value={value || ""}
                  optional={true}
                  rows={4}
                  onChange={onChange}
                  data-testid="capSurveyForm_procurementContractualConsiderations"
                />
              )}
            />
          </CapSurveyFormFieldRow>
          <CapSurveyFormFieldRow tooltip={TOOLTIP.PRICING_OPTIONS}>
            <Controller
              name="capProductMetadatum.pricingOption"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="pricingOption"
                    name={name}
                    label="Pricing Options"
                    value={
                      PRICING_OPTION_DROPDOWN.find(o => o.value === value) ||
                      null
                    }
                    placeholder="Pricing Options"
                    options={PRICING_OPTION_DROPDOWN}
                    optional={false}
                    isClearable={false}
                    onChange={(formSelectOnChangeParam: any) => {
                      onChange(
                        extractFormSelectOnChangeValue(formSelectOnChangeParam)
                      );
                    }}
                    classNamePrefix="pricingOption"
                  />
                );
              }}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default CapSurveyFormSection4Step2;
