import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormSelect,
  FontAwesomeIcon
} from "@nef/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation } from "jsonapi-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import { isEqual, sortBy, map } from "lodash";

import manageClient from "../../../../api/manage/client";
import { OrganizationUser } from "../../../../api/types";
import { MultiSelectOption } from "../../../../components/common/MultiSelect";
import Toast from "../../../../components/Toast";
import FormFieldLabel from "../../../../components/modals/components/FormFieldLabel";
import { UserGroup } from "../remove-user-group-modal";
import { ConfirmationModal } from "../../../../components/modals/ConfirmationModal";

import styles from "./index.module.scss";

const generateRequestBody = (organizationId: string) => {
  const requestBody: Record<string, string | boolean> = {};
  requestBody["filter[organization_id]"] = organizationId;
  requestBody["filter[is_default_team]"] = false;
  requestBody.include = "organization-users";

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const compareProductAccessChanges = (
  fetchedProducts: OrganizationUser[],
  updatedProducts: OrganizationUser[]
) => {
  const fetchedProductsIDs = map(fetchedProducts, "id");
  const updatedProductIDs = map(updatedProducts, "id");

  return isEqual(sortBy(fetchedProductsIDs), sortBy(updatedProductIDs));
};

const columnHelper = createColumnHelper<OrganizationUser>();

type OrganizationUsers = {
  organizationUsers: OrganizationUser[];
};

interface ManageUsersModal {
  close: () => void;
  organizationId: string;
  userGroup: UserGroup;
}

const userGroupRequestSchema = yup.object({
  organizationUsers: yup.object().nullable().shape({
    value: yup.string(),
    label: yup.string()
  })
});

const ManageUsersModal = ({
  close,
  organizationId,
  userGroup
}: ManageUsersModal) => {
  const [selectedOrganizationUsers, setSelectedOrganizationUsers] = useState<
    OrganizationUser[]
  >([]);
  const [userToAdd, setUserToAdd] = useState<MultiSelectOption | null>(null);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false }
  ]);
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] =
    useState(false);
  const [userToDelete, setUserToDelete] = useState<OrganizationUser | null>(
    null
  );
  const isDeleteUserModaOpen = userToDelete !== null;

  const [createOrganizationUserGroupRequest] = useMutation(
    ["organization-teams", userGroup.id],
    {
      method: "PATCH",
      client: manageClient
    }
  );

  const { data: organizationUsersData } = useQuery<OrganizationUser[]>([
    "organization-users",
    {
      "filter[organization_id]": organizationId
    }
  ]);

  const { data: organizationGroup } = useQuery<{
    organizationUsers: OrganizationUser[];
  }>(["organization-teams", userGroup.id, generateRequestBody(organizationId)]);

  useEffect(() => {
    if (organizationGroup) {
      setSelectedOrganizationUsers(
        organizationGroup?.organizationUsers as OrganizationUser[]
      );
    }
  }, [organizationGroup]);

  const userOptions =
    organizationUsersData && selectedOrganizationUsers
      ? organizationUsersData
          .filter(
            userData =>
              !selectedOrganizationUsers.some(
                (user: { id: string }) => user.id === userData.id
              )
          )
          .map(product => {
            return {
              value: product.id,
              label: (product.fullName || product.inviteEmail) as string
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];

  const columns = [
    columnHelper.accessor("fullName", {
      id: "name",
      cell: info => (
        <div
          className={`${styles["name-width"]}`}
          title={info.getValue() || "Invite sent"}
        >
          {info.getValue() || <em>Invite sent</em>}
        </div>
      ),
      header: () => (
        <button
          type="button"
          tabIndex={-1}
          className={`${styles.sorter} ${
            sorting[0]?.id === "name" ? styles["sorter-active"] : ""
          }`}
          onClick={() => handleSortChange("name")}
          onKeyPress={() => handleSortChange("name")}
        >
          <span>Name</span>
          {sorting[0]?.id === "name" && (
            <FontAwesomeIcon
              iconClassName={sorting[0].desc ? "fa-caret-down" : "fa-caret-up"}
              className={styles["caret-icon"]}
            />
          )}
        </button>
      )
    }),
    columnHelper.accessor("email", {
      id: "email",
      cell: info => (
        <div
          className={`${styles["email-width"]}`}
          title={info.getValue() || info.row.original.email}
        >
          {info.getValue() || info.row.original.email}
        </div>
      ),
      header: () => (
        <button
          type="button"
          tabIndex={-1}
          className={`${styles.center} ${
            sorting[0]?.id === "email" ? styles["sorter-active"] : ""
          }`}
          onClick={() => handleSortChange("email")}
          onKeyPress={() => handleSortChange("email")}
        >
          <span>Email</span>
          {sorting[0]?.id === "email" && (
            <FontAwesomeIcon
              iconClassName={sorting[0].desc ? "fa-caret-down" : "fa-caret-up"}
              className={styles["caret-icon"]}
            />
          )}
        </button>
      )
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      cell: info => {
        const val = info.getValue();
        if (val !== undefined) {
          return (
            <div className={styles.center}>
              {new Intl.DateTimeFormat("en-US").format(new Date(val))}
            </div>
          );
        }
        return "";
      },
      header: () => (
        <button
          type="button"
          tabIndex={-1}
          className={`${styles.sorter} ${
            sorting[0]?.id === "createdAt" ? styles["sorter-active"] : ""
          }`}
          onClick={() => handleSortChange("createdAt")}
          onKeyPress={() => handleSortChange("createdAt")}
        >
          <span>Date Added</span>
          {sorting[0]?.id === "createdAt" && (
            <FontAwesomeIcon
              iconClassName={sorting[0].desc ? "fa-caret-down" : "fa-caret-up"}
              className={styles["caret-icon"]}
            />
          )}
        </button>
      )
    }),
    columnHelper.accessor("id", {
      id: "id",
      cell: info =>
        info.getValue() ? (
          <div className={styles["user-actions"]}>
            <Button
              outline
              color="danger"
              size="sm"
              className={styles["table-delete-button"]}
              data-testid={`organizationsUsersTable_deleteUser-${info.row.original.id}`}
              onClick={() => {
                setUserToDelete(info.row.original);
              }}
            >
              Remove
            </Button>
          </div>
        ) : null,
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: selectedOrganizationUsers,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm<OrganizationUsers>({
    mode: "onChange",
    resolver: yupResolver(userGroupRequestSchema)
  });

  const handleRemoveUser = (userId: string) => {
    const updatedUsers = selectedOrganizationUsers.filter(
      (user: { id: string }) => user.id !== userId
    );
    setSelectedOrganizationUsers(updatedUsers);
    setUserToDelete(null);
  };

  const handleAddUser = () => {
    if (userToAdd) {
      const userData = organizationUsersData?.find(
        user => user.id === userToAdd.value
      );
      const updatedUsers = [...selectedOrganizationUsers, userData];

      setSelectedOrganizationUsers(updatedUsers as OrganizationUser[]);
      setUserToAdd(null);
    }
  };

  const handleSortChange = (columnId: string) => {
    const isDesc = sorting[0]?.id === columnId && !sorting[0]?.desc;

    setSorting([{ id: columnId, desc: isDesc }]);
  };

  const onSubmit: SubmitHandler<OrganizationUsers> = async () => {
    const updatedUsers = selectedOrganizationUsers.map(_userGroup => ({
      id: _userGroup.id,
      type: "organization-users"
    }));

    const response = await createOrganizationUserGroupRequest({
      "organization-users": updatedUsers
    });

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error updating the user group"
          details={[]}
        />
      );

      return;
    }

    toast(
      <Toast
        type="success"
        title="User group has been successfully updated"
        details={[]}
      />
    );

    handleClose();
  };

  const handleClose = () => {
    close();
  };

  const handleCancel = () => {
    const productAccessChanged = compareProductAccessChanges(
      organizationGroup?.organizationUsers as OrganizationUser[],
      selectedOrganizationUsers
    );

    if (productAccessChanged) {
      handleClose();
    } else {
      setIsDiscardChangesModalOpen(true);
    }
  };

  return (
    <Modal
      isOpen
      data-testid="OrganizationsManageUsersModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="New User Group"
        toggle={handleCancel}
        className={styles["modal-header"]}
        data-testid="OrganizationsManageUsersModal_header"
      >
        {userGroup.name} Users
      </ModalHeader>
      <ModalBody>
        <div className={`${!organizationId ? "" : styles["remove-margin"]}`}>
          <div>
            <FormFieldLabel
              title="Add User"
              tooltip="Please add a user"
              optional
            />
            <div className={styles["add-user-container"]}>
              <Controller
                control={control}
                name="organizationUsers"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormSelect
                      options={userOptions}
                      value={userToAdd ? value : ""}
                      onChange={val => {
                        onChange(val.value);
                        setUserToAdd(val.value as MultiSelectOption | null);
                      }}
                      placeholder="Select..."
                      invalid={!!formErrors.organizationUsers}
                      feedback={(formErrors?.organizationUsers as any)?.message}
                      data-testid="OrganizationsManageUsersModal_users"
                      classNamePrefix="organizationUser"
                      disabled={!userOptions.length}
                    />
                  );
                }}
              />
              <div className={styles["add-user-button-container"]}>
                <Button
                  outline
                  size="md"
                  className={styles["add-user-button"]}
                  onClick={handleAddUser}
                  disabled={!userOptions.length || !userToAdd}
                >
                  Add User
                </Button>
              </div>
            </div>
          </div>

          <div className={styles["table-container"]}>
            <table>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} data-testid={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={
                Object.keys(formErrors).length !== 0 ||
                !selectedOrganizationUsers?.length
              }
              data-testid="OrganizationsManageUsersModal_submit"
            >
              Save
            </Button>
            <Button
              color="light"
              outline
              onClick={handleCancel}
              data-testid="OrganizationsManageUsersModal_cancel"
            >
              Cancel
            </Button>

            {isDeleteUserModaOpen && (
              <ConfirmationModal
                data-testid={userToDelete?.id}
                isOpen={isDeleteUserModaOpen}
                title="Remove User From User Group"
                confirmText="Remove User"
                dismissText="Cancel"
                question={`Are you sure you want to remove ${
                  userToDelete.fullName ?? userToDelete.inviteEmail
                }? The user’s product access conferred via the user group will be removed. Individual user access will be retained.`}
                onConfirm={() => {
                  handleRemoveUser(userToDelete.id);
                }}
                danger
                onDismiss={() => {
                  setUserToDelete(null);
                }}
              />
            )}

            {isDiscardChangesModalOpen && (
              <ConfirmationModal
                isOpen={isDiscardChangesModalOpen}
                title="Save Changes"
                confirmText="Yes"
                dismissText="No"
                question="Would you like to discard changes?"
                onConfirm={() => {
                  setIsDiscardChangesModalOpen(false);
                  handleClose();
                }}
                onDismiss={() => {
                  setIsDiscardChangesModalOpen(false);
                }}
                danger
              />
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ManageUsersModal;
