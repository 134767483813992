import { QueryArg, useQuery } from "jsonapi-react";

import { Plan, PlanCategory } from "../api/types";

type usePlansQueryParams =
  | {
      planCategories: PlanCategory[] | undefined;
      active: boolean;
      includeSubscriberCount?: boolean;
    }
  | false;

const usePlansQuery = (params: usePlansQueryParams) => {
  let plansQuery: QueryArg = false;

  if (params) {
    const { planCategories, active, includeSubscriberCount } = params;
    const productCategoryIds = planCategories?.map(category => category.id);

    if (productCategoryIds?.length) {
      const plansQueryParam: any = {
        filter: {
          active,
          "plan-category-id": productCategoryIds
        }
      };

      if (includeSubscriberCount) {
        plansQueryParam.include_subscriber_count = true;
      }

      plansQuery = ["plans", plansQueryParam];
    }
  }

  return useQuery<Plan[]>(plansQuery);
};

export default usePlansQuery;
