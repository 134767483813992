import React from "react";
import { useQuery } from "jsonapi-react";
import { useParams } from "react-router-dom";

import { UploadFile } from "../../api/types";
import { type FileData } from "../../hooks/useFileManagerUpload";
import ViewSeletor, {
  ViewMode
} from "../../components/file-manager/ViewSelector";
import FileExplorer from "../../components/file-manager/FileExplorer";
import webClient from "../../api/manage/client";

export function parseFileList(objects?: UploadFile[]): FileData {
  const root: FileData = {
    type: "Directory",
    path: "/",
    name: "/",
    parentPath: "",
    children: [],
    date: new Date()
  };

  if (!objects) return root;
  const keyMap: { [key: string]: FileData } = {
    "/": root
  };
  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];
    const { path, size, lastModified } = object;

    const pathArray = path.split("/");
    let key = "";
    let parent = root;
    for (let j = 0; j < pathArray.length - 1; j++) {
      const folderName = pathArray[j];
      if (folderName.length === 0) {
        key += "/";
      } else {
        key += `${folderName}/`;
      }
      if (!keyMap[key]) {
        keyMap[key] = {
          type: "Directory",
          path: key,
          parentPath: parent.path,
          name: `${folderName}`,
          children: [],
          date: lastModified
        };
        parent.children.push(keyMap[key]);
      } else if (lastModified < keyMap[key].date) {
        keyMap[key].date = lastModified;
      }
      parent = keyMap[key];
    }
    const fileName = pathArray[pathArray.length - 1];
    // only empty folder will have filename equal to empty string
    if (fileName !== "") {
      parent.children.push({
        type: "File",
        name: fileName,
        parentPath: parent.path,
        path,
        children: [],
        size,
        date: lastModified
      });
    }
  }
  return root;
}

const FileManagerPage = () => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const { data: files, refetch } = useQuery<UploadFile[]>(
    [
      "upload/list",
      {
        path: "/",
        vendor_id: vendorId
      }
    ],
    { client: webClient }
  );
  const [viewMode, setViewMode] = React.useState(ViewMode.Table);
  const root = React.useMemo<FileData>(() => {
    return parseFileList(files);
  }, [files]);
  return (
    <FileExplorer
      refetch={refetch}
      fileData={root}
      viewMode={viewMode}
      vendorId={vendorId}
    >
      <ViewSeletor viewMode={viewMode} setViewMode={setViewMode} />
    </FileExplorer>
  );
};

export default FileManagerPage;
