import React, { useEffect, useMemo } from "react";
import { FormRadioCheckboxButton, FormRadioCheckboxGroup } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { StringMap } from "jsonapi-react";
import styled, { keyframes } from "styled-components";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";

import {
  BaseProductCategory,
  ProductCategory,
  ProductType
} from "../../../api/types";
import CapSurveyFormFieldRow from "../../cap-survey-form/CapSurveyFormFieldRow";
import TOOLTIP from "../../cap-survey-form/tooltips";
import Toast from "../../Toast";

import styles from "./ProductCategoriesInput.module.scss";

const CATEGORY_TOOLTIPS = Object.freeze({
  AssetClass: TOOLTIP.PRODUCT_CATEGORY_ASSET_CLASS,
  DataType: TOOLTIP.PRODUCT_CATEGORY_DATA_TYPE,
  Region: TOOLTIP.PRODUCT_CATEGORY_REGION,
  AreaofImpact: TOOLTIP.PRODUCT_CATEGORY_AREA_OF_IMPACT,
  SustainableDevelopmentGoals: TOOLTIP.PRODUCT_CATEGORY_SUSTAINABLE_DEV_GOALS,
  InvestmentStyle: TOOLTIP.PRODUCT_CATEGORY_INVESTMENT_STYLE,
  "Industry/Sector": TOOLTIP.PRODUCT_CATEGORY_INDUSTRY_SECTOR,
  DataOrigin: TOOLTIP.PRODUCT_CATEGORY_DATA_ORIGIN
});
type CategoryTooltipKey = keyof typeof CATEGORY_TOOLTIPS;

function isValidCategoryCode(code: string): code is CategoryTooltipKey {
  return (code as CategoryTooltipKey) !== undefined;
}

interface ProductCategoriesInputProps {
  productType: ProductType;
  value: BaseProductCategory[];
  mode?: "INTAKE" | "EDITOR";
  isGridLayout?: boolean;
  handleChange: (category: BaseProductCategory) => void;
  prodCategories: ProductCategory[] | undefined;
  productCategoriesError: StringMap | undefined;
  isProductCategoriesLoading: boolean | undefined;
}

const pulse = keyframes`
0%, 100% {
  opacity: 1;
}
50% {
  opacity: .5;
}
`;

const LoadingStateContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
  width: 100%;
`;

const LoadingSpan = styled.span`
  height: 1.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  width: 90%;
  content: "";
  border-radius: 3px;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const ProductCategoriesInput = ({
  productType,
  value,
  isGridLayout = true,
  mode = "INTAKE",
  handleChange,
  prodCategories: _productCategories,
  isProductCategoriesLoading: isLoading,
  productCategoriesError: error
}: ProductCategoriesInputProps) => {
  useEffect(() => {
    if (error) {
      toast(
        <Toast
          type="error"
          title="Loading product categories failed"
          details={[
            {
              message:
                "Sorry, we cannot load the product category at the moment, please refresh or try later.",
              description: error.title
            }
          ]}
        />
      );
    }
  }, [error]);

  const productCategories = useMemo(() => {
    if (!_productCategories) {
      return [];
    }

    // hide global option from front end
    if (productType === "CORE") {
      const regionCategoryIndex = _productCategories.findIndex(
        v => v.categoryType === "region"
      );

      if (regionCategoryIndex !== -1) {
        const result = cloneDeep(_productCategories);
        result[regionCategoryIndex].children = _productCategories[
          regionCategoryIndex
        ].children.filter(v => v.name !== "Global");
        return result;
      }
    }

    // hide asset class from adp request since we are reusing the product categories from core product
    if (productType === "ADP") {
      return _productCategories.filter(v => v.categoryType !== "asset_class");
    }

    // filter out sustainable_dev_goals as they are now read-only for the EDITOR and appearing in the product editor as SDG indicators
    if (mode === "EDITOR" && productType === "ESG") {
      return _productCategories.filter(
        v => v.categoryType !== "sustainable_dev_goals"
      );
    }

    return _productCategories;
  }, [_productCategories, productType]);

  const categoryGroups = productCategories.map(({ id, name, children }) => {
    return {
      id,
      name,
      children: children.map(category => {
        return {
          id: category.id,
          name: category.name,
          parentId: id
        };
      })
    };
  });
  const categoryOptionsGroups = categoryGroups
    // Always show region first
    .sort(v => {
      if (v.name === "Region") {
        return -1;
      }
      return 0;
    })
    .map(group => {
      return {
        id: group.id,
        name: group.name,
        code: group.name.replace(/\s/g, ""),
        options: group.children.map(c => {
          return { value: c.id, label: c.name };
        })
      };
    });

  const flatCategories = categoryGroups
    .map(group => {
      return [...group.children];
    })
    .flat();

  const handleProductCategoryChange = ({
    value: categoryId
  }: CustomChangeEvent) => {
    const foundCategory = flatCategories.find(c => c.id === categoryId);
    if (foundCategory) {
      handleChange({
        id: foundCategory.id,
        name: foundCategory.name,
        parentId: foundCategory.parentId
      });
    }
  };
  if (isLoading) {
    return (
      <CapSurveyFormFieldRow tooltip="">
        <LoadingStateContainer>
          {Array.from(Array(10).keys()).map(idx => (
            <LoadingSpan key={`loading-${idx}`} />
          ))}
        </LoadingStateContainer>
      </CapSurveyFormFieldRow>
    );
  }

  return (
    <>
      {categoryOptionsGroups?.map(({ id: parentId, name, code, options }) => {
        const tooltip = isValidCategoryCode(code)
          ? CATEGORY_TOOLTIPS[code]
          : "";
        return (
          <CapSurveyFormFieldRow tooltip={tooltip} key={parentId}>
            <FormRadioCheckboxGroup
              className={isGridLayout ? styles["radio-checkboxes-group"] : ""}
              id={`Product${code}`}
              type="checkbox"
              label={name}
              name="productCategories"
              onChange={handleProductCategoryChange}
              optional={true}
              key={parentId}
              value={value?.map(v => v.id)}
              data-testid={`productDetailForm_productCategories${code}`}
            >
              {options.map(option => {
                return (
                  <FormRadioCheckboxButton
                    id={`ProductCategories_${option.value}`}
                    label={option.label}
                    name={option.value}
                    key={option.value}
                    value={option.value}
                    optional={true}
                  />
                );
              })}
            </FormRadioCheckboxGroup>
          </CapSurveyFormFieldRow>
        );
      })}
    </>
  );
};

export default ProductCategoriesInput;
