import React, { useState } from "react";
import { Modal, ModalBody } from "@nef/core";

import { DatatablesNameDescription } from "../../api/types";

import HostedDatatableWizard from "./HostedDatatableWizard";
import NonHostedDatatableWizard from "./NonHostedDatatableWizard";

interface IntakeDatatableModalProps {
  hosted: boolean;
  datatable: DatatablesNameDescription;
  onSubmit: (values: DatatablesNameDescription) => void;
  // call when no changes happened
  onDismiss: () => void;
  // call when changes happened and need users to confirm
  onDiscard: () => void;
  existedCodes: string[];
}

const IntakeDatatableModal = ({
  hosted,
  datatable,
  onSubmit,
  onDismiss,
  onDiscard,
  existedCodes
}: IntakeDatatableModalProps) => {
  const [datatableValue, setDatatableValue] = useState({ ...datatable });

  const saveStep = (
    formValues: Partial<DatatablesNameDescription>,
    submit: boolean
  ) => {
    const newDatatableValue = {
      ...datatableValue,
      ...formValues
    };

    setDatatableValue(newDatatableValue);

    if (submit) {
      onSubmit(newDatatableValue);
    }
  };

  const onPreviousStep = (formState: any) => {
    const currentForm = formState.getValues();
    setDatatableValue(currentForm);
  };

  return (
    <Modal isOpen={true} toggle={onDismiss} closeOnOutsideClick={false}>
      <ModalBody>
        {hosted ? (
          <HostedDatatableWizard
            datatable={datatableValue}
            onDismiss={onDismiss}
            onDiscard={onDiscard}
            saveStep={saveStep}
            onPreviousStep={onPreviousStep}
            existedCodes={existedCodes}
          />
        ) : (
          <NonHostedDatatableWizard
            datatable={datatableValue}
            onDismiss={onDismiss}
            onDiscard={onDiscard}
            saveStep={saveStep}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default IntakeDatatableModal;
