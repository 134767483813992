import React from "react";

import IMAGES from "../../sdg-indicators/images";

import "./style.scss";

type Props = {
  description: string;
  premium: boolean;
  datatableProductType: string;
  intraday: boolean;
  sdgIndicators?: string[];
};

const ProductSummary: React.FC<Props> = ({
  description,
  premium,
  datatableProductType,
  intraday,
  sdgIndicators
}) => {
  const isTable = datatableProductType !== "";
  const category = isTable
    ? intraday
      ? "Custom API"
      : "Tables API"
    : "Time-series API";

  const isADP = datatableProductType === "ADP";
  const isESG = datatableProductType === "ESG";

  return (
    <div className="product-summary__container">
      <div className="product-summary__heading-container">
        <h2 className="product-summary__heading">Summary</h2>
        {premium && <div className="product-summary__pill">Premium</div>}
        {isADP && <div className="product-summary__pill">Institutional</div>}
        <div className="product-summary__pill">{category}</div>
      </div>

      <div
        className="product-summary__description"
        data-testid="productSummary_description"
      >
        {description}
      </div>
      {isESG && (
        <>
          <div
            className="product-summary__ESG-icons-container"
            data-testid="productPreview_sdgIcons"
          >
            {sdgIndicators?.map(SDGIcon => {
              return (
                <img
                  key={SDGIcon}
                  className="product-summary__ESG-icon"
                  src={IMAGES[SDGIcon]}
                  alt={SDGIcon}
                />
              );
            })}
          </div>
          <div className="product-summary__ESG-text">
            Nasdaq fully supports the Sustainable Development Goals
            (&quot;SDG&quot;s) created by the United Nations (&quot;UN&quot;).
            Corresponding logos & icons are used for informational purposes to
            inspire and help promote these goals for a more sustainable world.
          </div>
        </>
      )}
    </div>
  );
};

export default ProductSummary;
