import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "jsonapi-react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "@nef/core";

import { Datatable } from "../../types";

type Props = {
  initialDatatables: { code: string }[];
  onVersionChanged: (currentVersion: string, datatables?: Datatable[]) => void;
  setIsDatatableLoading: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
};

const generateRequestBody = (
  initialDatatables?: { code: string }[],
  versionCode?: string
) => {
  const requestBody: Record<string, string> = {};

  if (initialDatatables && initialDatatables.length > 0) {
    requestBody["filter[datatables]"] = initialDatatables
      .map(initialDatatable => initialDatatable.code)
      .join(",");
  }

  if (versionCode && versionCode !== "Default") {
    requestBody.version_code = versionCode;
  }

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const VersionDropdown: React.FC<Props> = ({
  initialDatatables,
  onVersionChanged,
  setIsDatatableLoading
}) => {
  const isFirstLoad = useRef(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [versions, setVersions] = useState<string[]>(["Default"]);
  const [currentVersion, setCurrentVersion] = useState<string>("Default");

  const {
    data: datatables,
    refetch: refetchDatatables,
    isLoading
  } = useQuery<Datatable[]>([
    "datatable_schema/index_by_codes_and_version",
    generateRequestBody(initialDatatables, currentVersion)
  ]);

  useEffect(() => {
    setIsDatatableLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      // Only run the version extraction once
      if (isFirstLoad.current) {
        const versionSet = new Set<string>();

        // Extract all the possible versions to show in the dropdown
        datatables?.forEach((datatable: Datatable) => {
          versionSet.add(datatable.version.code);

          datatable.versions.forEach(version => {
            versionSet.add(version.id);
          });
        });

        setVersions(["Default", ...Array.from(versionSet).sort()]);
      }

      isFirstLoad.current = false;

      onVersionChanged(currentVersion, datatables);
    }
  }, [datatables]);

  useEffect(() => {
    if (refetchDatatables) {
      refetchDatatables();
    }
  }, [currentVersion]);

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      <DropdownToggle
        color="light"
        size="sm"
        outline
        caret
        isLoading={isLoading}
        data-testid="Version-Dropdown-Toggle"
      >
        {`Version ${currentVersion}`}
      </DropdownToggle>
      <DropdownMenu>
        {versions.map(version => (
          <DropdownItem
            key={version}
            data-testid={`Version-Dropdown-Option-${version}`}
            onClick={() => {
              setCurrentVersion(version);
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            {version}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default VersionDropdown;
