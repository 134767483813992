import { createContext } from "react";

import { User } from "../api/types";

export type AuthenticatedUser = User | null;

export interface SessionAuthContextInterface {
  authenticatedUser: AuthenticatedUser;
  setAuthenticatedUser(user: AuthenticatedUser): any;
  authenticated(): boolean;
  isAuthenticating: boolean;
  login(): Promise<any>;
  logout(): void;
  isManagerAdmin: () => boolean;
  isPublisherUser: () => boolean;
  isOrganizationUser: () => boolean;
  isAdminableVendor: (vendorId: string) => boolean;
  isManageableVendor: (vendorId: string) => boolean;
  isManageableDatatableCollection: (datatableCollectionId: string) => boolean;
  isProductStatusEditor: (
    productApprovalStatus?: string | null,
    productApprovalType?: string | null
  ) => boolean;
  isProductStatusIntake: (productApprovalStatus?: string | null) => boolean;
}

const initialState: SessionAuthContextInterface = {
  authenticatedUser: null,
  setAuthenticatedUser: () => null,
  authenticated: () => false,
  isAuthenticating: true,
  login: async () => null,
  logout: () => null,
  isManagerAdmin: () => false,
  isPublisherUser: () => false,
  isOrganizationUser: () => false,
  isAdminableVendor: () => false,
  isManageableVendor: () => false,
  isManageableDatatableCollection: () => false,
  isProductStatusEditor: () => false,
  isProductStatusIntake: () => false
};

const SessionAuthContext = createContext(initialState);

export default SessionAuthContext;
