import React, { useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import ProductAccessModal from "../../components/modals/product-access/ProductAccessModal";

import styles from "./ProductAccessTable.module.scss";

interface ProductAccessData {
  name: string;
  id: string;
}

interface ProductTableProps {
  data: ProductAccessData[];
  onUpdate: (data: ProductAccessData[]) => void;
  vendorId: string;
}

const columnHelper = createColumnHelper<ProductAccessData>();

const ProductsTable = ({
  data: productData,
  onUpdate,
  vendorId
}: ProductTableProps) => {
  const [isProductAccessModalOpen, setProductAccessModalOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false }
  ]);

  const updateProductAccess = (newProductAccess: any) => {
    onUpdate(newProductAccess);
  };

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => (
        <div className={styles["name-column"]}>{info.getValue()}</div>
      ),
      header: header => {
        return (
          <button
            type="button"
            onClick={header.column.getToggleSortingHandler()}
            tabIndex={-1}
          >
            Name
          </button>
        );
      }
    })
  ];

  const table = useReactTable({
    data: productData || [],
    columns,
    state: {
      sorting
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  const handleAddProductClicked = () => {
    setProductAccessModalOpen(true);
  };

  return (
    <div className={styles.container} data-testid="productAccessTable">
      <div className={styles.header}>
        <p className={styles["products-span"]}>Product Access</p>
        <Button
          data-testid="productAccessTable_manageButton"
          size="sm"
          outline
          onClick={handleAddProductClicked}
        >
          <FontAwesomeIcon iconClassName="fa-plus-circle" />
          Manage
        </Button>
      </div>

      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ProductAccessModal
        isOpen={isProductAccessModalOpen}
        close={() => setProductAccessModalOpen(false)}
        vendorId={vendorId}
        selected={productData.map(product => product.id)}
        onUpdate={updateProductAccess}
      />
    </div>
  );
};

export default ProductsTable;
