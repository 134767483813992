import { FormRadioCheckboxButton, FormRadioCheckboxGroup } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import React from "react";
import styled from "styled-components";

interface FrequenciesInputProps {
  label: string;
  name: string;
  value: string[];
  optional: boolean;
  handleChange: (e: CustomChangeEvent) => void;
  options: { value: string; label: string }[];
  disabled?: boolean;
}

const StyledFormRadioCheckboxGroup = styled(FormRadioCheckboxGroup)`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;

  & > * {
    grid-column: span 2;
  }

  & > *:nth-child(4) {
    grid-column: 1/2;
  }
  & > *:nth-child(5) {
    grid-column: 2/3;
  }

  & > *:nth-child(8) {
    grid-column: 1/2;
  }
  & > *:nth-child(9) {
    grid-column: 2/3;
  }
`;

const FrequenciesInput: React.FC<FrequenciesInputProps> = ({
  label,
  name,
  value,
  optional,
  handleChange,
  options,
  disabled = false
}) => {
  const code = name.replace(/\s/g, "");

  return (
    <StyledFormRadioCheckboxGroup
      id={`Product${code}`}
      type="checkbox"
      label={label}
      name={name}
      onChange={handleChange}
      optional={optional}
      value={value}
      data-testid={`productDetailForm_${code}`}
    >
      {options.map(option => {
        return (
          <FormRadioCheckboxButton
            id={`Product${code}_${option.value}`}
            label={option.label}
            name={option.value}
            key={option.value}
            value={option.value}
            optional={true}
            disabled={disabled}
          />
        );
      })}
    </StyledFormRadioCheckboxGroup>
  );
};

export default FrequenciesInput;
