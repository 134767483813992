import React, { useEffect, useRef, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { parse } from "@vanillaes/csv";
import { toast } from "react-toastify";

import { DatatablesNameDescription, SchemaError } from "../../../api/types";
import IntakeUploader from "../../cap-survey-form/components/IntakeUploader";
import validate from "../table-schema-validator";
import { getDownloadFileLink } from "../../../api/api";
import Toast from "../../Toast";
import { useQueue } from "../../../contexts/queue";

interface SchemaUploaderProps {
  productId: string;
  datatableId: string;
  vendorId: string;
  showLabel?: boolean;
}

const useTriggerValidation = () => {
  const [fileContentData, setFileContentData] = useState<{
    fileContent: string | null;
    // `validatedAt` allows force trigger validation even when
    // user uploads the same file
    validatedAt: number | null;
  }>({
    fileContent: null,
    validatedAt: null
  });

  const [errors, setErrors] = useState<SchemaError[] | null>(null);

  const { control } = useFormContext<DatatablesNameDescription>();
  const [requiresPrimaryKeys, tableName] = useWatch({
    control,
    name: ["schemaRequiresPrimaryKeys", "name"]
  });

  const setFileContent = (newFileContent: string | null) => {
    setFileContentData({
      fileContent: newFileContent,
      validatedAt: window.performance.now()
    });
  };

  const validateFileContent = async () => {
    const { fileContent } = fileContentData;
    if (fileContent === null) {
      setErrors(null);
      return;
    }

    try {
      const csv = parse(fileContent);
      validate(csv, {
        requiresPrimaryKeys,
        tableName
      }).then(validationErrors => {
        setErrors(validationErrors);
      });
    } catch (error) {
      setErrors([
        {
          type: "invalid-csv",
          title: "The file is not a valid csv file",
          message:
            "Please re-upload a new table schema using the provided template"
        }
      ]);
    }
  };

  useEffect(() => {
    validateFileContent();
  }, [fileContentData, requiresPrimaryKeys, tableName]);

  return {
    setFileContent,
    errors
  };
};

const SchemaUploader = ({
  productId,
  datatableId,
  vendorId,
  showLabel = true
}: SchemaUploaderProps) => {
  const { control, getValues, setValue } =
    useFormContext<DatatablesNameDescription>();
  const { setFileContent, errors } = useTriggerValidation();
  const isUserUploading = useRef(false);

  // triggered by user action
  const readSchemaFileFromUploader = async (files: File[]) => {
    const reader = new FileReader();
    reader.addEventListener("load", e => {
      const fileContentString = e.target?.result as string;
      setFileContent(fileContentString);
    });
    reader.readAsText(files[0]);
  };

  // triggered by auto-validation upon component initialization
  const readSchemaFileFromUrl = async (url: string) => {
    const response = await fetch(url);
    const fileContentString = await response.text();
    setFileContent(fileContentString);
  };

  useEffect(() => {
    const fetchFile = async () => {
      const fileName = getValues("schemaFileUrl")[0];
      const response = await getDownloadFileLink(
        `/products/${productId}/${datatableId}/schema/${fileName}`,
        vendorId
      );
      readSchemaFileFromUrl(response.data);
    };

    fetchFile();
  }, []);

  const handleUploadToast = (
    newErrors: SchemaError[] | null,
    oldErrors: SchemaError[] | null
  ) => {
    if (newErrors === null || newErrors.length === 0) {
      toast(
        <Toast
          type="success"
          title="Valid Table Schema"
          details={[
            {
              message: "All errors have been remediated."
            }
          ]}
        />
      );
      return;
    }

    const errorToastMessage = oldErrors?.length
      ? "Table schema still contains errors. Please review."
      : "Table schema contains errors. Please review.";

    toast(
      <Toast
        type="error"
        title="Invalid Table Schema"
        details={[
          {
            message: errorToastMessage
          }
        ]}
      />
    );
  };

  useEffect(() => {
    if (errors !== null) {
      const oldErrors = getValues("schemaErrors");
      setValue("schemaErrors", errors);

      if (isUserUploading.current) {
        handleUploadToast(errors, oldErrors);
        isUserUploading.current = false;
      }
    }
  }, [errors]);

  const { enqueue } = useQueue();

  const SCHEMA_FILE_PATH = `/products/${productId}/${datatableId}/schema/`;

  const deleteSchemaFileDelay = (
    filesOnConfirm: string[],
    filesOnCancel: string[]
  ) => {
    if (filesOnConfirm.length !== 0) {
      filesOnConfirm.forEach(file => {
        const filename = SCHEMA_FILE_PATH + file;
        enqueue("delete-schema-files-save", filename, filename);
      });
    }
    if (filesOnCancel.length !== 0) {
      filesOnCancel.forEach(file => {
        const filename = SCHEMA_FILE_PATH + file;
        enqueue("delete-schema-files-discard", filename, filename);
      });
    }
  };

  const label = showLabel ? "Table Schema Upload" : "";

  return (
    <Controller
      name="schemaFileUrl"
      control={control}
      render={({ field: { onChange, value } }) => (
        <IntakeUploader
          label={label}
          path={`/products/${productId}/${datatableId}/schema/`}
          vendorId={vendorId}
          value={value}
          testid="schema-upload-field"
          maxFiles={1}
          onSuccess={(filenames: string[], files: File[]) => {
            isUserUploading.current = true;
            onChange(filenames);
            deleteSchemaFileDelay(value, filenames);
            readSchemaFileFromUploader(files);
          }}
          required
          templateDownloadUrl={`${process.env.PUBLIC_URL}/static/Data_Dictionary_v2.xlsx`}
          onDeleteFile={filename => {
            const updatedValue = value.filter(v => v !== filename);
            deleteSchemaFileDelay([filename], []);
            onChange(updatedValue);
          }}
          accept={{ "text/csv": [".csv"] }}
        />
      )}
    />
  );
};

export default SchemaUploader;
