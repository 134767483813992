import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FontAwesomeIcon,
  FormFieldWithIcon,
  Pagination
} from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { useQuery } from "jsonapi-react";
import { Redirect, useHistory } from "react-router-dom";

import NewVendorModal from "../../components/new-vendor/NewVendorModal";
import useQueryParam from "../../hooks/useQueryParam";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import { Vendor, VendorSortParam } from "../../api/types";

import styles from "./vendors.module.scss";
import VendorsTable from "./vendors-table";

const SORT_PARAM_TO_REQUEST_PARAM = {
  name: "name",
  "-name": "-name",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  vendor_users_count: "vendor_users_count",
  "-vendor_users_count": "-vendor_users_count",
  active_products_count: "active_products_count",
  "-active_products_count": "-active_products_count",
  products_count: "products_count",
  "-products_count": "-products_count"
};

type SortParam = keyof typeof SORT_PARAM_TO_REQUEST_PARAM;

interface RequestBody {
  filter: {
    search_by_name?: string; // eslint-disable-line camelcase
  };
  page: {
    number: number;
  };
  sort: string;
}

const isVendorSortParam = (query: string): query is VendorSortParam => {
  return Object.keys(SORT_PARAM_TO_REQUEST_PARAM).includes(query);
};

const VendorsPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { isManagerAdmin, isPublisherUser } = useDefaultAuth();
  const history = useHistory();
  const queryParams = useQueryParam();

  const query = queryParams.get("search") ?? "";
  const [searchQuery, setSearchQuery] = useState(query);
  const page = parseInt(queryParams.get("page") || "1", 10);

  const rawSortParam = useQueryParam().get("sort") || "";
  const sortParam = isVendorSortParam(rawSortParam) ? rawSortParam : "name";

  const generateRequestBody = () => {
    const filter: { [key: string]: string | number } = {
      search_by_name: query
    };

    Object.keys(filter).forEach(key => {
      if (!filter[key]) {
        delete filter[key];
      }
    });

    const requestBody: RequestBody = {
      filter,
      page: {
        number: page
      },
      sort: SORT_PARAM_TO_REQUEST_PARAM[sortParam as SortParam]
    };

    return requestBody;
  };

  const {
    data: vendors,
    links,
    isLoading
  } = useQuery<Vendor[]>(["vendors", generateRequestBody()]);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (!isLoading) {
      setTotalPages(extractPageNumber(links?.last));
    }
  }, [isLoading]);

  const extractPageNumber = (url?: string): number => {
    if (!url) {
      return 1;
    }

    const urlObject = new URL(url);
    const pageNumber = urlObject.searchParams.get("page[number]");

    return pageNumber ? parseInt(pageNumber, 10) : 1;
  };

  const handleSearch = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchQuery(enteredQuery);
  };

  const handleAction = () => {
    loadPageWithParams({
      search: searchQuery,
      page: 1,
      sort: sortParam
    });
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAction();
    }
  };

  const setSortParam = useCallback(
    (sort: string) => {
      loadPageWithParams({
        search: searchQuery,
        page,
        sort
      });
    },
    [searchQuery]
  );

  const setPageParam = useCallback(
    (pageNum: number) => {
      loadPageWithParams({
        search: searchQuery,
        page: pageNum,
        sort: sortParam
      });
    },
    [page, searchQuery, sortParam]
  );

  const loadPageWithParams = (params: {
    search: string;
    page: number;
    sort: string;
  }) => {
    const queryString = Object.entries(params).reduce((url, param) => {
      const [key, value] = param;
      if (!value) return url;

      return `${url}${url ? "&" : "?"}${key}=${value}`;
    }, "");

    history.push({
      search: queryString
    });
  };

  const onPageChange = (num: number) => {
    setPageParam(num + 1);
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPageParam(page + 1);
    }
  };

  const previousPage = () => {
    if (page !== 1) {
      setPageParam(page - 1);
    }
  };

  if (!isManagerAdmin() && !isPublisherUser()) {
    return <Redirect to="/" />;
  }

  return (
    <main className={styles.container} data-testid="publishers">
      <section className={styles["content-section"]}>
        <div className={styles.vendor}>
          <h1 className={styles.heading} data-testid="publisher">
            Publisher
          </h1>
          <div
            className={styles["buttons-container"]}
            data-testid="new-button-container"
          >
            <FormFieldWithIcon
              placeholder="Search"
              iconClassName="fa-search"
              addonType="append"
              value={searchQuery}
              onChange={handleSearch}
              onIconClick={handleAction}
              onKeyDown={handleSubmit}
            />
            {isManagerAdmin() && (
              <Button
                onClick={() => setModalOpen(true)}
                data-testid="new-button"
              >
                <FontAwesomeIcon
                  iconClassName="fa-plus-circle"
                  className={styles["circle-icon"]}
                />
                New Publisher
              </Button>
            )}
          </div>
          <NewVendorModal
            isOpen={isModalOpen}
            close={() => setModalOpen(false)}
          />
        </div>

        <VendorsTable
          vendors={vendors}
          sortParam={sortParam}
          setSortParam={setSortParam}
        />

        <Pagination
          page={page - 1}
          pages={totalPages}
          pageSize={0}
          pageSizeOptions={[]}
          onPageChange={onPageChange}
          onPageSizeChange={() => null}
          onPageUpdate={() => null}
          nextPage={nextPage}
          previousPage={previousPage}
          showPageSizeDropdown={false}
          showPageDataCount={false}
          totalDataLength={0}
          data-testid="publisher_pagination"
        />
      </section>
    </main>
  );
};

export default VendorsPage;
