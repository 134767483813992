import React, { useCallback } from "react";
import { Button, FormSelect, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "jsonapi-react";

import extractFormSelectOnChangeValue from "../../../../utils/nef-utils";
import Toast from "../../../../components/Toast";
import { OrganizationUser } from "../../../../api/types";
import manageClient from "../../../../api/manage/client";
import FormFieldLabel from "../../../../components/modals/components/FormFieldLabel";

import styles from "./index.module.scss";

interface AddAdminUserModalProps {
  close: () => void;
  organizationId: string;
  isLoading: boolean;
}

const AddAdminUserModal = ({
  close,
  organizationId,
  isLoading
}: AddAdminUserModalProps) => {
  const { data: organizationUsers } = useQuery<OrganizationUser[]>([
    "organization-users",
    {
      "filter[organization_id]": organizationId,
      "filter[role]": "user"
    }
  ]);

  const addUserAdminRequestSchema = yup.object({
    organizationUser: yup.string().required("User is a required field")
  });

  const {
    control,
    getValues,
    trigger,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      organizationUser: ""
    },
    resolver: yupResolver(addUserAdminRequestSchema)
  });

  const { organizationUser } = getValues();

  const [updateUser] = useMutation(["organization-users", organizationUser], {
    method: "PATCH",
    client: manageClient
  });

  const handleSubmit = useCallback(async () => {
    const response = await updateUser({
      role: "admin"
    });

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error adding the user"
          details={[]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully added"
        details={[]}
      />
    );

    handleClose();
  }, [updateUser, organizationUser]);

  const userOptions = organizationUsers?.map(user => {
    return {
      value: user.id,
      label: user.fullName ? `${user.fullName} <${user.email}>` : user.email
    };
  });

  const handleClose = () => {
    reset();
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="OrganizationsAddAdminUserModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Add Admin User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Add Admin User
      </ModalHeader>
      <ModalBody>
        <div>
          <div className={styles["remove-margin"]}>
            <FormFieldLabel
              title="Select User"
              tooltip="Please select a user"
            />
          </div>
          <Controller
            name="organizationUser"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => {
              return (
                <FormSelect
                  id="OrganizationUserId"
                  name={name}
                  isLoading={isLoading}
                  options={userOptions}
                  value={() => userOptions?.find(o => o.value === value)}
                  data-testid="OrganizationsAddAdminUserModal_organizationUserId"
                  onChange={formSelectOnChangeParam => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                    trigger("organizationUser");
                  }}
                  invalid={!!formErrors.organizationUser}
                  feedback="Select User is a required field"
                  onBlur={onBlur}
                  classNamePrefix="modalFormSelect"
                />
              );
            }}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={handleSubmit}
            disabled={
              !isDirty || !isValid || Object.keys(formErrors).length !== 0
            }
            data-testid="OrganizationsAddAdminUserModal_submit"
          >
            Add Admin
          </Button>
          <Button
            color="light"
            outline
            onClick={handleClose}
            data-testid="OrganizationsAddAdminUserModal_cancel"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddAdminUserModal;
