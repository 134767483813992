import React from "react";
import { DatePicker, FormField, FormSelect, FormTextArea } from "@nef/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import { at } from "lodash";

import { CONDITION_OPTIONS, formSchema } from "../../../schema";
import AddCSVButton from "../AddCSVButton";
import { capitalize } from "../../../../../../../helpers/string-helpers";

import styles from "./style.module.scss";

function FilterControl({
  filterIndex,
  code,
  type,
  formName
}: {
  filterIndex: number;
  code: string;
  type: string;
  formName: string;
}) {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
    trigger
  } = useFormContext<yup.Asserts<typeof formSchema>>();
  const operator = useWatch({
    control,
    name: `${formName}.${filterIndex}.operator` as any
  });

  const labelText = code === "lastupdated" ? "Last Updated" : capitalize(code);

  return (
    <div data-testid={`${formName}.${filterIndex}.filterControl`}>
      <Controller
        control={control}
        name={`${formName}.${filterIndex}.operator` as any}
        defaultValue=""
        render={({ field: { onChange, value, name } }) => {
          const v = value
            ? {
                value,
                label: value
              }
            : "";
          return (
            <FormSelect
              id={`${formName}.${filterIndex}.operator`}
              name={name}
              label={`${labelText} Operator`}
              options={CONDITION_OPTIONS.map((option: any) => ({
                label: option,
                value: option
              }))}
              placeholder="Select Operator"
              value={v}
              onChange={({ value: internalValue }: any) => {
                onChange(internalValue?.value || undefined);

                const filterObject = getValues(
                  `${formName}.${filterIndex}` as any
                );
                setValue(`${formName}.${filterIndex}` as any, {
                  ...filterObject,
                  value: "",
                  dateValue: "",
                  numberValue: ""
                });
                clearErrors(`${formName}.${filterIndex}` as any);
              }}
              invalid={!!at(errors, `${formName}.${filterIndex}.operator`)[0]}
              feedback={
                at(errors, `${formName}.${filterIndex}.operator`)[0]
                  ?.message as string
              }
              classNamePrefix="filterOperator"
            />
          );
        }}
      />
      {operator === "in" || operator === "nin" ? (
        <>
          <FormTextArea
            {...register(`${formName}.${filterIndex}.value` as any)}
            id={`${formName}.${filterIndex}.value`}
            label="Additional Instructions"
            type="text"
            optional={false}
            data-testid={`${formName}.${filterIndex}.in.nin`}
            invalid={!!at(errors, `${formName}.${filterIndex}.value`)[0]}
            feedback={
              at(errors, `${formName}.${filterIndex}.value`)[0]
                ?.message as string
            }
            className={styles["notes-textarea"]}
            rows={3}
            min={undefined}
            max={undefined}
          />
          <AddCSVButton
            onFinish={(text: any) =>
              setValue(`${formName}.${filterIndex}.value` as any, text)
            }
          />
        </>
      ) : (
        <>
          {type === "Date" ? (
            <>
              <div>
                <Controller
                  name={`${formName}.${filterIndex}.dateValue` as any}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => {
                    let v;
                    if (value) {
                      v = moment(value);
                    }
                    return (
                      <DatePicker
                        data-testid={`${formName}.${filterIndex}.dateValue`}
                        placeholder="YYYY-MM-DD"
                        label={`Choose ${
                          operator === "lt / gt"
                            ? "lt"
                            : operator === "lte / gte"
                            ? "lte"
                            : ""
                        } Date Value`}
                        onChange={({ value: dateValue }) => {
                          onChange(dateValue?.format("YYYY-MM-DD") || "");
                          trigger(
                            `${formName}.${filterIndex}.numberValue` as any
                          );
                        }}
                        value={v}
                        format="YYYY-MM-DD"
                        invalid={
                          !!at(
                            errors,
                            `${formName}.${filterIndex}.dateValue`
                          )[0]
                        }
                        feedback={
                          at(errors, `${formName}.${filterIndex}.dateValue`)[0]
                            ?.message as string
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name={`${formName}.${filterIndex}.numberValue` as any}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormField
                        onChange={v => {
                          onChange(v);
                          trigger(
                            `${formName}.${filterIndex}.dateValue` as any
                          );
                        }}
                        value={value}
                        label={`${labelText} ${
                          operator === "lt / gt"
                            ? "lt"
                            : operator === "lte / gte"
                            ? "lte"
                            : ""
                        } Entitlement Year`}
                        placeholder="Enter a negative value"
                        type="text"
                        data-testid={`${formName}.${filterIndex}.numberValue`}
                        invalid={
                          !!at(
                            errors,
                            `${formName}.${filterIndex}.numberValue`
                          )[0]
                        }
                        feedback={
                          at(
                            errors,
                            `${formName}.${filterIndex}.numberValue`
                          )[0]?.message as string
                        }
                      />
                    );
                  }}
                />
              </div>

              {(operator === "lt / gt" || operator === "lte / gte") && (
                <div>
                  <Controller
                    name={`${formName}.${filterIndex}.newDateValue` as any}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => {
                      let v;
                      if (value) {
                        v = moment(value);
                      }
                      return (
                        <DatePicker
                          data-testid={`${formName}.${filterIndex}.newDateValue`}
                          placeholder="YYYY-MM-DD"
                          label={`Choose ${
                            operator === "lt / gt"
                              ? "gt"
                              : operator === "lte / gte"
                              ? "gte"
                              : ""
                          } Date Value`}
                          onChange={({ value: dateValue }) => {
                            onChange(dateValue?.format("YYYY-MM-DD") || "");
                            trigger(
                              `${formName}.${filterIndex}.newNumberValue` as any
                            );
                          }}
                          value={v}
                          format="YYYY-MM-DD"
                          invalid={
                            !!at(
                              errors,
                              `${formName}.${filterIndex}.newDateValue`
                            )[0]
                          }
                          feedback={
                            at(
                              errors,
                              `${formName}.${filterIndex}.newDateValue`
                            )[0]?.message as string
                          }
                        />
                      );
                    }}
                  />
                  <Controller
                    name={`${formName}.${filterIndex}.newNumberValue` as any}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormField
                          onChange={v => {
                            onChange(v);
                            trigger(
                              `${formName}.${filterIndex}.newDateValue` as any
                            );
                          }}
                          value={value}
                          label={`${labelText}
                     ${
                       operator === "lt / gt"
                         ? "gt"
                         : operator === "lte / gte"
                         ? "gte"
                         : ""
                     } Entitlement Year`}
                          placeholder="Enter a negative value"
                          type="text"
                          min={undefined}
                          max={undefined}
                          data-testid={`${formName}.${filterIndex}.newNumberValue`}
                          invalid={
                            !!at(
                              errors,
                              `${formName}.${filterIndex}.newNumberValue`
                            )[0]
                          }
                          feedback={
                            at(
                              errors,
                              `${formName}.${filterIndex}.newNumberValue`
                            )[0]?.message as string
                          }
                        />
                      );
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <Controller
                name={`${formName}.${filterIndex}.value` as any}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormField
                      onChange={v => {
                        onChange(v);
                      }}
                      value={value}
                      label={`${labelText} ${
                        operator === "lt / gt"
                          ? "lt"
                          : operator === "lte / gte"
                          ? "lte"
                          : ""
                      } Value`}
                      placeholder="Enter Filter Value"
                      type="text"
                      min={undefined}
                      max={undefined}
                      data-testid={`${formName}.${filterIndex}.value`}
                      invalid={
                        !!at(errors, `${formName}.${filterIndex}.value`)[0]
                      }
                      feedback={
                        at(errors, `${formName}.${filterIndex}.value`)[0]
                          ?.message as string
                      }
                    />
                  );
                }}
              />
              {(operator === "lt / gt" || operator === "lte / gte") && (
                <Controller
                  name={`${formName}.${filterIndex}.newNumberValue` as any}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormField
                        onChange={onChange}
                        value={value}
                        label={`${labelText} ${
                          operator === "lt / gt"
                            ? "gt"
                            : operator === "lte / gte"
                            ? "gte"
                            : ""
                        } Value`}
                        placeholder="Enter Filter Value"
                        type="text"
                        min={undefined}
                        max={undefined}
                        data-testid={`${formName}.${filterIndex}.newValue`}
                        invalid={
                          !!at(errors, `${formName}.${filterIndex}.newValue`)[0]
                        }
                        feedback={
                          at(errors, `${formName}.${filterIndex}.newValue`)[0]
                            ?.message as string
                        }
                      />
                    );
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <div className={styles.divider} />
    </div>
  );
}

export default FilterControl;
