import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { QueryArg, useQuery } from "jsonapi-react";

import { ProductToEdit, Product } from "../../api/types";
import BaseProductForm from "../../components/product-form/BaseProductForm";
import useDefaultAuth from "../../hooks/useDefaultAuth";

const EditProductPage = () => {
  const { productId } = useParams<{ productId: string }>();
  const {
    isManagerAdmin,
    isPublisherUser,
    isAdminableVendor,
    isManageableVendor,
    isManageableDatatableCollection,
    isProductStatusEditor
  } = useDefaultAuth();

  const query: QueryArg = productId
    ? [
        "products",
        productId,
        {
          include: [
            "vendor",
            "product-categories",
            "product-metadatum",
            "cap-product-metadatum",
            "product-supporting-documents",
            "product-management-group",
            "plan-categories",
            "plan-categories.licence"
          ]
        }
      ]
    : false;

  const { data: existingProduct, isLoading } = useQuery<Product>(query);
  const [product, setProduct] = useState<ProductToEdit>();

  const defaultDocsContent = `
# Product Overview

${existingProduct?.overview || ""}

# Coverage and Data Organization

## Coverage

## Data Organization

# Column Definitions

# FAQ`;

  useEffect(() => {
    if (existingProduct) {
      setProduct({
        ...existingProduct,
        documentation: existingProduct?.documentation || defaultDocsContent,
        productCategories: existingProduct?.productCategories || []
      });
    }
  }, [existingProduct]);

  if (isLoading) return null;
  if (!existingProduct)
    return (
      <div data-testid="editProduct_notFound">Product cannot be found</div>
    );
  if (!product) return null;

  if (
    (!isLoading &&
      !isManagerAdmin() &&
      !(isPublisherUser() && isAdminableVendor(product?.vendor?.id)) &&
      !(
        isPublisherUser() &&
        isManageableVendor(product?.vendor?.id) &&
        isManageableDatatableCollection(productId)
      )) ||
    !isProductStatusEditor(
      product?.productApprovalStatus,
      product?.productApprovalType
    )
  ) {
    return <Redirect to="/" />;
  }

  return (
    <BaseProductForm
      product={product}
      initialProductValue={{
        ...existingProduct,
        documentation: existingProduct?.documentation || defaultDocsContent,
        productCategories: existingProduct?.productCategories || []
      }}
      setProduct={setProduct}
    />
  );
};

export default EditProductPage;
