import React from "react";
import { generatePath, Link } from "react-router-dom";
import { format } from "date-fns-tz";
import { Controller } from "react-hook-form";
import { Button } from "@nef/core";
import styled from "styled-components";

import { DatatableSchema, ProductFormMode } from "../../../api/types";
import { useProductContext } from "../../../hooks/useProductContext";
import styles from "../ProductSelectDatatableStep.module.scss";
import { PATHS } from "../../../routes";

interface ProductDatatableOptionProps {
  datatable: DatatableSchema;
  index: number;
}

const SelectButton = styled(Button)`
  background-color: #d1d1d1;
  border: #d1d1d1;
  color: #949494;

  &:hover,
  &:active {
    background-color: #d1d1d1;
    border: #d1d1d1;
    color: #949494;
  }

  &:focus {
    background-color: #d1d1d1;
    border: #d1d1d1;
    color: #949494;
    box-shadow: 0 0 1px 0.2rem rgba(209, 209, 209, 0.25);
  }
`;

const ProductDatatableOption = ({
  datatable,
  index
}: ProductDatatableOptionProps) => {
  const { productForm } = useProductContext(ProductFormMode.EDIT);
  const { getValues, watch, control } = productForm;

  const selectedDatatables = watch("datatables") || [];
  const getSelectedDatatables = (selectedCode: string) => {
    const existingDatatables = getValues("datatables") || [];

    const datatableIndex = existingDatatables.findIndex(
      (c: { code: string }) => c.code === selectedCode
    );

    if (datatableIndex > -1) {
      existingDatatables.splice(datatableIndex, 1);
    } else {
      existingDatatables.push({ code: selectedCode });
    }

    return existingDatatables;
  };

  const { version, code, vendorCode } = datatable;

  const datatableRoute = generatePath(PATHS.EDIT_DATATABLE, {
    vendorCode,
    datatableCode: code,
    versionCode: version.code
  });
  const datatableVendorAndCode = `${vendorCode}/${code}`;

  return (
    <div
      key={datatable.code}
      className={styles.record}
      data-testid={`selectDatatableForm_datatable-${index}`}
    >
      <div className={styles["record-info"]}>
        <Link
          to={datatableRoute}
          data-testid={`selectDatatableForm_datatable-name-${index}`}
        >
          {datatable.name}
        </Link>
        <div className={styles["record-timestamp"]}>
          Created {format(datatable.createdAt, "d/M/yyyy hh:mma zzz")}
        </div>
      </div>
      <div className={styles["record-action"]}>
        {selectedDatatables
          .map((d: { code: string }) => d.code)
          .includes(datatableVendorAndCode) ? (
          <Controller
            control={control}
            name="datatables"
            render={({ field: { onChange } }) => {
              return (
                <Button
                  disabled={false}
                  onClick={() =>
                    onChange(getSelectedDatatables(datatableVendorAndCode))
                  }
                  data-testid={`selectDatatableForm_datatable-${index}-selected`}
                >
                  Selected
                </Button>
              );
            }}
          />
        ) : (
          <Controller
            control={control}
            name="datatables"
            render={({ field: { onChange } }) => {
              return (
                <SelectButton
                  disabled={false}
                  onClick={() =>
                    onChange(getSelectedDatatables(datatableVendorAndCode))
                  }
                  data-testid={`selectDatatableForm_datatable-${index}-select`}
                >
                  Select
                </SelectButton>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDatatableOption;
