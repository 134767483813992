import React from "react";
import {
  FormField,
  FormRadioCheckboxButton,
  FormRadioCheckboxGroup,
  FormSelect
} from "@nef/core";
import { toast } from "react-toastify";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import CapSurveyFormFieldRow from "../cap-survey-form/CapSurveyFormFieldRow";
import { DatatablesNameDescription } from "../../api/types";
import { extractErrors } from "../../utils/react-hook-form-utils";
import Toast from "../Toast";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import FormLabel from "../common/FormLabel";
import TOOLTIP from "../cap-survey-form/tooltips";
import { buildHostedDatatableSchema } from "../cap-survey-form/schema";

import { BaseDatatableStepProps } from "./types";
import IntakeDatatableModalFooter from "./IntakeDatatableModalFooter";
import styles from "./HostedDatatableWizardStep2.module.scss";

const HostedDatatableWizardStep3 = ({
  datatable,
  save,
  cancel,
  discard,
  onPreviousStep,
  fields
}: BaseDatatableStepProps) => {
  const datatableSchema = buildHostedDatatableSchema();
  const stepSchema = datatableSchema.pickNested(fields);
  const form = useForm<DatatablesNameDescription>({
    defaultValues: datatable,
    resolver: yupResolver(stepSchema)
  });
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = form;

  const saveStep = async () => {
    await handleSubmit(
      async formData => onHandleSubmit(formData),
      onHandleSubmitError
    )().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<
    DatatablesNameDescription
  > = async formData => {
    await save(formData, false);
  };

  const onHandleSubmitError: SubmitErrorHandler<
    DatatablesNameDescription
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  const holidayOptions = [
    {
      label: "US Market Holidays",
      value: "US_FADF"
    },
    {
      label: "Chinese Market Holidays",
      value: "CN_SHNGX"
    },
    {
      label: "Japanese Market Holidays",
      value: "JP_TSX"
    },
    {
      label: "Canadian Market Holidays",
      value: "CA_TSE"
    },
    {
      label: "Swedish Market Holidays",
      value: "SE_FNS"
    },
    {
      label: "Singaporean Market Holidays",
      value: "SG_SMEPL"
    },
    {
      label: "UK Market Holidays",
      value: "GB_LSE"
    },
    {
      label: "Korean Market Holidays",
      value: "KR_KSE"
    },
    {
      label: "Custom",
      value: "Custom"
    },
    {
      label: "Other",
      value: "Other"
    },
    {
      label: "None",
      value: "None"
    }
  ];

  const POINT_IN_TIME_OPTIONS = ["Yes", "No"].map(value => ({
    value,
    label: value
  }));

  const DELIVERY_LAG_UNITS = [
    "N/A",
    "second(s)",
    "minute(s)",
    "hour(s)",
    "calendar day(s)",
    "business day(s)",
    "week(s)",
    "month(s)"
  ].map(value => {
    return {
      value,
      label: value
    };
  });

  const customIsSelected = watch("holidayImpacts").includes("Custom");
  const otherIsSelected = watch("holidayImpacts").includes("Other");
  const noneIsSelected = watch("holidayImpacts").includes("None");

  const getSelectedHolidayImpacts = (impact: any) => {
    const currentImpacts = getValues("holidayImpacts");

    const impactIndex = currentImpacts.findIndex(
      newImpact => newImpact === impact.currentTarget.value
    );

    if (impactIndex === -1) {
      currentImpacts.push(impact.currentTarget.value);
    } else {
      currentImpacts.splice(impactIndex, 1);
    }

    return currentImpacts;
  };

  const handleCustomHolidayImpact = (description: any) => {
    return description.value;
  };
  const deliveryLagUnit = watch("deliveryLag.unit");

  return (
    <>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_DELIVERY_LAG}>
        <FormLabel required={true}>Delivery Lag</FormLabel>
        <div className={styles["lag-input"]}>
          {deliveryLagUnit && deliveryLagUnit !== "N/A" && (
            <Controller
              name="deliveryLag.value"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormField
                  id="deliveryLagValue"
                  name={name}
                  type="number"
                  min={0}
                  label=""
                  placeholder="number"
                  value={`${value}`}
                  optional={false}
                  onChange={onChange}
                  data-testid="intakeDatatableModal_deliveryLagValue"
                />
              )}
            />
          )}
          <Controller
            name="deliveryLag.unit"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <FormSelect
                id="deliveryLagUnit"
                name={name}
                label=""
                value={DELIVERY_LAG_UNITS.find(o => o.value === value) || null}
                isClearable={false}
                options={DELIVERY_LAG_UNITS}
                onChange={(formSelectOnChangeParam: any) => {
                  onChange(
                    extractFormSelectOnChangeValue(formSelectOnChangeParam)
                  );
                }}
                classNamePrefix="modalFormSelect"
              />
            )}
          />
        </div>
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_POINT_IN_TIME}>
        <Controller
          name="pointInTime"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <FormSelect
                id="pointInTime"
                name={name}
                label="Point in Time"
                value={
                  value === null
                    ? ""
                    : POINT_IN_TIME_OPTIONS.find(
                        o => o.value === (value === true ? "Yes" : "No")
                      )
                }
                placeholder="Choose an option"
                options={POINT_IN_TIME_OPTIONS}
                optional={false}
                isClearable={false}
                onChange={(formSelectOnChangeParam: any) => {
                  onChange(
                    extractFormSelectOnChangeValue(formSelectOnChangeParam) ===
                      "Yes"
                  );
                }}
                classNamePrefix="modalFormSelect"
              />
            );
          }}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_HOLIDAY_IMPACTS}>
        <Controller
          name="holidayImpacts"
          control={control}
          render={({ field: { value, onChange, name } }) => {
            return (
              <>
                <FormRadioCheckboxGroup
                  id="holidayImpacts"
                  type="checkbox"
                  label="Holiday Impacts (Select all that apply)"
                  name={name}
                  optional={false}
                  onChange={(formRadioCheckboxGroupParam: any) => {
                    onChange(
                      getSelectedHolidayImpacts(formRadioCheckboxGroupParam)
                    );
                  }}
                  value={value}
                >
                  {holidayOptions.map(option => {
                    return (
                      <FormRadioCheckboxButton
                        id={`Checkbox_${option.value}`}
                        label={option.label}
                        value={option.value}
                        name={option.value}
                        key={option.value}
                        optional={false}
                        disabled={option.value !== "None" && noneIsSelected}
                      />
                    );
                  })}
                </FormRadioCheckboxGroup>
              </>
            );
          }}
        />
        <>
          {(customIsSelected || otherIsSelected) && (
            <Controller
              name="customHolidayImpactDescription"
              control={control}
              render={({ field: { onChange, name } }) => {
                return (
                  <FormField
                    id={name}
                    name={name}
                    type="text"
                    optional={false}
                    label={
                      otherIsSelected
                        ? "Other Holiday Calendar"
                        : "Custom Holiday Calendar"
                    }
                    placeholder={
                      otherIsSelected
                        ? "Specify other holiday or exchange calendar(s)"
                        : "Specify dates or holidays"
                    }
                    onChange={(newImpactDescription: any) => {
                      onChange(handleCustomHolidayImpact(newImpactDescription));
                    }}
                  />
                );
              }}
            />
          )}
        </>
      </CapSurveyFormFieldRow>

      <IntakeDatatableModalFooter
        save={saveStep}
        canAdvance={!isSubmitting && isSubmitSuccessful}
        cancel={isDirty ? discard : cancel}
        onPreviousStep={onPreviousStep}
        formState={form}
      />
    </>
  );
};

export default HostedDatatableWizardStep3;
