import React, { useState, useEffect } from "react";
import { DatePicker } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at } from "lodash";
import moment, { Moment } from "moment";

import { formSchema } from "../../../schema";

const AccessEnd = ({
  initialValue,
  resetValue,
  formFieldName,
  disabled
}: {
  initialValue?: string;
  resetValue: string;
  formFieldName: string;
  disabled: boolean;
}) => {
  const [currentDate, setCurrentDate] = useState<string | undefined>(
    initialValue
  );
  const {
    control,
    formState: { errors }
  } = useFormContext<yup.Asserts<typeof formSchema>>();

  const formFieldErrors = at(errors, formFieldName)[0];

  const endDate = (value?: string): Moment | undefined => {
    if (value) {
      return moment(value);
    }

    return undefined;
  };

  useEffect(() => {
    setCurrentDate(resetValue);
  }, [resetValue]);

  return (
    <Controller
      name={formFieldName as any}
      control={control}
      defaultValue={false}
      render={({ field: { onChange } }) => {
        return (
          <DatePicker
            data-testid="OrganizationsAddProductDrawer_accessEnd"
            placeholder="YYYY-MM-DD"
            label="Access Termination Date"
            onChange={({ value: dateValue }) => {
              onChange(dateValue);
              setCurrentDate(dateValue);
            }}
            value={endDate(currentDate)}
            format="YYYY-MM-DD"
            invalid={!!formFieldErrors}
            feedback={formFieldErrors?.message as string}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default AccessEnd;
