import React, { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Button, FontAwesomeIcon } from "@nef/core";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useMutation, useQuery } from "jsonapi-react";

import manageClient from "../../api/manage/client";
import Toast from "../../components/Toast";
import { VendorUser } from "../../api/types";

import styles from "./admins-table.module.scss";
import RemoveAdminUserModal from "./components/RemoveAdminUserModal";
import AddUserToAdminModal from "./components/add-user-to-admin-modal";

interface AdminTableProps {
  vendorId: string;
  hasEditAccess?: boolean;
}

interface Admin {
  id: string;
  userName: string;
  userEmail: string;
  userInviteEmail: string;
  createdAt: string;
}

const SORT_PARAM_TO_REQUEST_PARAM = {
  name: "user.first_name,user.last_name",
  "-name": "-user.first_name,-user.last_name",
  email: "user.email",
  "-email": "-user.email",
  created_at: "created_at",
  "-created_at": "-created_at"
};

type SortParam = keyof typeof SORT_PARAM_TO_REQUEST_PARAM;

const generateRequestBody = (vendorId: string, sortParam: SortParam) => {
  const requestBody: Record<string, string | boolean> = {};
  requestBody["filter[vendor_id]"] = vendorId;
  requestBody["filter[is_admin]"] = true;

  if (sortParam.trim() !== "") {
    requestBody.sort = SORT_PARAM_TO_REQUEST_PARAM[sortParam];
  }

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const columnHelper = createColumnHelper<Admin>();

const AdminTable = ({ vendorId, hasEditAccess }: AdminTableProps) => {
  const [sortParam, setSortParam] = useState<SortParam>("name");
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    message: ""
  });
  const [isAddUserToAdminModalOpen, setAddUserToAdminModalOpen] =
    useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [vendorUserId, setVendorUserId] = useState<string>("");

  const { data: adminData } = useQuery<Admin[]>([
    "vendor-users",
    generateRequestBody(vendorId, sortParam)
  ]);

  const { data: vendorUsers } = useQuery<VendorUser[]>([
    "vendor-users",
    {
      "filter[is_admin]": false,
      "filter[vendor_id]": vendorId
    }
  ]);

  const [updateUser] = useMutation(["vendor-users", vendorUserId], {
    method: "PATCH",
    client: manageClient
  });

  const ascending = !sortParam.startsWith("-");
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";
  const sortBy = ascending ? sortParam : sortParam.substring(1);

  const updateSortParam = useCallback(
    (newSortBy: SortParam) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as SortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const columns = [
    columnHelper.accessor("userName", {
      id: "name",
      cell: info => info.getValue() || <em>Invite sent</em>,
      header: () => (
        <div className={sortBy === "name" ? styles["sorter-active"] : ""}>
          <button
            type="button"
            onClick={() => updateSortParam("name")}
            onKeyPress={() => updateSortParam("name")}
            tabIndex={-1}
          >
            Name
          </button>
          {sortBy === "name" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      cell: info => {
        return new Intl.DateTimeFormat("en-US").format(
          new Date(info.getValue())
        );
      },
      header: () => (
        <div className={sortBy === "created_at" ? styles["sorter-active"] : ""}>
          <button
            type="button"
            onClick={() => updateSortParam("created_at")}
            onKeyPress={() => updateSortParam("created_at")}
            tabIndex={-1}
          >
            Created On
          </button>
          {sortBy === "created_at" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("userEmail", {
      id: "email",
      cell: info => info.getValue() || info.row.original.userInviteEmail,
      header: () => (
        <div className={sortBy === "email" ? styles["sorter-active"] : ""}>
          <button
            type="button"
            onClick={() => updateSortParam("email")}
            onKeyPress={() => updateSortParam("email")}
            tabIndex={-1}
          >
            Email
          </button>
          {sortBy === "email" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("id", {
      id: "id",
      cell: info =>
        info.getValue() && hasEditAccess ? (
          <>
            <Button
              outline
              size="sm"
              color="danger"
              className={styles["admin-action-buttons"]}
              onClick={() => {
                handleRemoveAdminClicked(
                  info.getValue(),
                  info.row.getValue("name") ||
                    info.row.original.userEmail ||
                    info.row.original.userInviteEmail
                );
              }}
            >
              Delete Admin
            </Button>
            <Link to={`/users/${info.getValue()}?from=dashboard`}>
              <Button
                size="sm"
                outline
                className={styles["admin-action-buttons"]}
              >
                View User
              </Button>
            </Link>
          </>
        ) : null,
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: adminData ?? [],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  const handleAddAdminClicked = () => {
    setAddUserToAdminModalOpen(true);
  };

  const handleRemoveAdminClicked = (id: string, adminName: string) => {
    setDeleteModal({
      isOpen: true,
      message: `Are you sure you want to remove manager admin access for ${adminName}?`
    });
    setVendorUserId(id);
  };

  const handleRemoveAdminConfirm = async () => {
    const response = await updateUser({
      "is-admin": false
    });

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error removing the user"
          details={[]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully removed"
        details={[]}
      />
    );

    setDeleteModal({ isOpen: false, message: "" });
  };

  const handleRemoveAdminDismiss = () => {
    setDeleteModal({ isOpen: false, message: "" });
  };

  return (
    <div className={styles.container} data-testid="adminsTable">
      <div className={styles.header}>
        <span>Product Administrator</span>
        {hasEditAccess && (
          <>
            <span
              data-tip="All Users Have Been Added to Group"
              data-tip-disable={vendorUsers && vendorUsers.length > 0}
            >
              <Button
                size="sm"
                disabled={vendorUsers && vendorUsers.length === 0}
                onClick={handleAddAdminClicked}
              >
                <FontAwesomeIcon iconClassName="fa-plus-circle" />
                Add Admin
              </Button>
            </span>
            <ReactTooltip place="top" type="dark" effect="solid" />
          </>
        )}
      </div>
      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {deleteModal.isOpen && (
        <RemoveAdminUserModal
          onConfirm={handleRemoveAdminConfirm}
          onDismiss={handleRemoveAdminDismiss}
          title="Remove Admin Access"
          question={deleteModal.message}
          confirmText="Remove Admin Access"
          dismissText="Cancel"
        />
      )}
      {isAddUserToAdminModalOpen && (
        <AddUserToAdminModal
          close={() => setAddUserToAdminModalOpen(false)}
          vendorId={vendorId}
          isLoading={false}
        />
      )}
    </div>
  );
};

export default AdminTable;
