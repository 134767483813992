import React, { useState } from "react";

import ContactSupportModal from "./ContactSupportModal";

const ContactSupportButton = () => {
  const [isModalOpen, setMoadlOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setMoadlOpen(true)}
        data-testid="contactSupportButton"
      >
        Contact Support
      </button>
      <ContactSupportModal
        isOpen={isModalOpen}
        close={() => setMoadlOpen(false)}
      />
    </>
  );
};

export default ContactSupportButton;
