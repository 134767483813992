import React, { createContext, useContext, useState, ReactNode } from "react";

interface DropdownValueProps {
  value: string;
  label: string;
}

interface SelectedVendorContextProps {
  selectedVendor: DropdownValueProps | null;
  setVendor: (vendor: DropdownValueProps | null) => void;
}

const SelectedVendorContext = createContext<
  SelectedVendorContextProps | undefined
>(undefined);

interface SelectedVendorProviderProps {
  children: ReactNode;
}

export const SelectedVendorProvider: React.FC<SelectedVendorProviderProps> = ({
  children
}) => {
  const [selectedVendor, setSelectedVendor] =
    useState<DropdownValueProps | null>(null);

  const setVendor = (vendor: DropdownValueProps | null) => {
    setSelectedVendor(vendor);
  };

  return (
    <SelectedVendorContext.Provider value={{ selectedVendor, setVendor }}>
      {children}
    </SelectedVendorContext.Provider>
  );
};

export const useSelectedVendor = (): SelectedVendorContextProps => {
  const context = useContext(SelectedVendorContext);
  if (!context) {
    throw new Error(
      "useSelectedVendor must be used within a SelectedVendorProvider"
    );
  }
  return context;
};
