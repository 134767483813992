import React, { useCallback } from "react";
import {
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  FormTextArea,
  ModalHeader
} from "@nef/core";
import * as yup from "yup";
import { useMutation } from "jsonapi-react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OptionalObjectSchema } from "yup/lib/object";
import { debounce, startCase } from "lodash";

import manageClient from "../../../api/manage/client";

import styles from "./editVendorTextModal.module.scss";

type EditVendorTextModalProps = {
  isOpen: boolean;
  validationSchema?: OptionalObjectSchema<any>;
  fieldName: string;
  validateUniqueness?: boolean;
  onSubmit: (value?: string) => void;
  close: () => void;
  initialValue?: string;
};

const vendorRequestSchema = yup.object({
  name: yup
    .string()
    .required("Publisher name is a required field")
    .min(2, "Publisher name needs to be at least 2 characters")
});

const EditVendorTextModal = ({
  isOpen,
  validationSchema,
  fieldName,
  validateUniqueness = false,
  onSubmit,
  close,
  initialValue
}: EditVendorTextModalProps) => {
  const [validateData] = useMutation(["vendors", "validate"], {
    client: manageClient
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors: formErrors },
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      [fieldName]: initialValue
    },
    resolver: validationSchema && yupResolver(validationSchema)
  });

  const validateNameFieldUniquenessAPI = async (
    field: "name",
    value: string
  ) => {
    if (!validateUniqueness) {
      return;
    }

    try {
      vendorRequestSchema.validateSyncAt(field, { [field]: value });
    } catch (err) {
      return;
    }

    const { errors } = await validateData({ [field]: value });

    const fieldHasBeenTaken = errors?.some(
      error =>
        error.title === "has already been taken" &&
        error.source.pointer.includes(field)
    );

    if (fieldHasBeenTaken) {
      setError(field, {
        type: "custom",
        message: `${startCase(field)} has already been taken`
      });
    }
  };

  const debounceValidateNameFieldUniquenessAPI = useCallback(
    debounce(validateNameFieldUniquenessAPI, 300),
    []
  );

  const handleOnSubmit = () => {
    const fieldValue = getValues()[fieldName];
    onSubmit(fieldValue);
    close();
  };

  return (
    <Modal isOpen={isOpen} toggle={close} data-testid="contentEditorModal">
      <ModalHeader toggle={close} className={styles["modal-header"]} />
      <ModalBody>
        <Controller
          name={fieldName}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormTextArea
              data-testid="contentEditorModal_textArea"
              cols={62}
              rows={4}
              value={value}
              onChange={event => {
                debounceValidateNameFieldUniquenessAPI(
                  "name",
                  event.value?.toString() || ""
                );
                onChange(event);
              }}
              invalid={!!formErrors[fieldName]}
              feedback={formErrors[fieldName]?.message}
              onBlur={onBlur}
            />
          )}
        />
      </ModalBody>
      <ModalFooter className={styles["modal-footer"]}>
        <Button
          size="md"
          disabled={!!formErrors[fieldName]}
          onClick={handleSubmit(handleOnSubmit)}
          data-testid="contentEditorModal_confirm"
        >
          Submit
        </Button>

        {close && (
          <Button
            size="md"
            color="light"
            outline
            onClick={close}
            data-testid="contentEditorModal_dismiss"
          >
            Cancel
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default EditVendorTextModal;
