import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { MONTH_OPTIONS, ScheduleRules } from "../util";
import styles from "../Scheduler.module.scss";

const MonthField = () => {
  const { control } = useFormContext<ScheduleRules>();

  return (
    <>
      <div className={styles.label}>Every</div>
      <Controller
        name="recurring.annually.bymonth"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <select
            id={name}
            value={value && value.length ? value[0] : 1}
            className={styles.input}
            onChange={e => {
              const newValue = parseInt(e.target.value, 10);
              onChange([newValue]);
            }}
          >
            {MONTH_OPTIONS.map(option => (
              <option key={`month-${option.value}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      />
    </>
  );
};

export default MonthField;
