const CHUNK_SIZE = 100 * 1024;

interface ParseOptions {
  chunkSize?: number;
  encoding?: string;
  delimiter?: string;
  header?: boolean;
}

export interface ParseResult {
  header?: string[];
  lines: string[][];
}

export class CsvReader {
  fileReader: FileReader;

  file: File | null;

  position: number;

  chunkSize: number;

  encoding: string;

  delimiter: string;

  header: boolean;

  constructor() {
    this.fileReader = new FileReader();
    this.file = null;

    this.position = 0;
    this.chunkSize = CHUNK_SIZE;
    this.encoding = "utf-8";
    this.delimiter = ",";
    this.header = false;
  }

  load(file: File, options: ParseOptions = {}) {
    this.file = file;

    this.position = 0;
    this.chunkSize = options.chunkSize || CHUNK_SIZE;
    this.encoding = options.encoding || "utf-8";
    this.delimiter = options.delimiter || ",";
    this.header = !!options.header;
  }

  parse() {
    if (!this.file) {
      return false;
    }

    const slice = this.file.slice(
      this.position,
      this.position + this.chunkSize
    );
    this.fileReader.readAsArrayBuffer(slice);

    const results: Promise<ParseResult> = new Promise((resolve, reject) => {
      this.fileReader.onload = () => {
        const lines: string[][] = [];

        const buffer = new Uint8Array(this.fileReader.result as ArrayBuffer);
        let lineStart = 0;
        for (let i = 0; i < buffer.length; i++) {
          if (buffer[i] === 10) {
            // if newline character
            const line = new TextDecoder(this.encoding)
              .decode(buffer.slice(lineStart, i))
              .split(this.delimiter);
            lines.push(line);

            // skip over newline character and start on the first character
            // in the new line
            lineStart = i + 1;
          }
        }

        const result: ParseResult = { lines };
        if (this.position === 0 && this.header) {
          result.header = lines.shift();
        }

        const increment = CHUNK_SIZE - (buffer.length - lineStart);
        this.position += increment;

        resolve(result);
      };
      this.fileReader.onerror = () => {
        reject(new Error("File Reader error"));
      };
    });

    return results;
  }
}

export default CsvReader;
