import React from "react";
import { FormField, FormTextArea } from "@nef/core";
import { StyledFormInput } from "@nef/core/lib/components/FormInput";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import {
  DatatableColumn,
  DatatableColumnBaseTypeOption
} from "../../../api/types";

const COLUMN_TYPE_TO_VALUE_INPUT_TYPES: Record<
  DatatableColumnBaseTypeOption,
  "text" | "number" | "date" | "datetime-local"
> = {
  string: "text",
  date: "date",
  integer: "number",
  datetime: "datetime-local",
  double: "number",
  decimal: "number"
};

const FormFieldForGrid = styled(FormField)`
  margin-bottom: 0 !important;

  /* The design has FormField and FormSelect on the same column, but they have different default
  left/right paddings. The override here makes the paddings more consistent*/
  ${StyledFormInput} {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
`;

const FormTextareaForGrid = styled(FormTextArea)`
  margin-bottom: 0 !important;
  width: 100%;
`;

interface DefaultFilterValueInputProps {
  index: number;
  name: string;
  value: string;
  onChange: () => void;
}

const DefaultFilterValueInput = ({
  index,
  name,
  value,
  onChange
}: DefaultFilterValueInputProps) => {
  const { control, getValues } = useFormContext<DatatableColumn>();
  const columnType = getValues("baseType");
  const watchOperator = useWatch({
    control,
    name: `defaultFilters.${index}.operator`
  });

  const valueInputIsTextarea = ["in", "nin"].includes(watchOperator || "");

  if (valueInputIsTextarea) {
    return (
      <FormTextareaForGrid
        name={name}
        type="text"
        label="Value"
        value={value || ""}
        onChange={onChange}
        data-testid="defaultFilterValueInput"
      />
    );
  }

  const valueInputType =
    columnType === "" ? "text" : COLUMN_TYPE_TO_VALUE_INPUT_TYPES[columnType];

  return (
    <FormFieldForGrid
      name={name}
      type={valueInputType}
      label="Value"
      value={value || ""}
      onChange={onChange}
      data-testid="defaultFilterValueInput"
    />
  );
};

export default DefaultFilterValueInput;
