import React from "react";

import MoreInfoTooltip from "../../MoreInfoTooltip";

import styles from "./FormFieldLabel.module.scss";

const FormFieldLabel = ({
  title,
  tooltip,
  optional
}: {
  title: string;
  tooltip: string;
  optional?: boolean;
}) => {
  return (
    <div className={styles.label}>
      {!optional && <span className={styles.star}>*</span>} {title}
      <span className={styles.icon}>
        <MoreInfoTooltip description={tooltip} icon="info" />
      </span>
    </div>
  );
};

export default FormFieldLabel;
