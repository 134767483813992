import NO_POVERTY_PNG from "../../assets/sdg-indicators/no_poverty.png";
import ZERO_HUNGER_PNG from "../../assets/sdg-indicators/zero_hunger.png";
import GOOD_HEALTH_WELLBEING_PNG from "../../assets/sdg-indicators/good_health_wellbeing.png";
import QUALITY_EDUCATION_PNG from "../../assets/sdg-indicators/quality_education.png";
import GENDER_EQUALITY_PNG from "../../assets/sdg-indicators/gender_equality.png";
import CLEAN_WATER_SANITATION_PNG from "../../assets/sdg-indicators/clean_water_sanitation.png";
import AFFORDABLE_CLEAN_ENERGY_PNG from "../../assets/sdg-indicators/affordable_clean_energy.png";
import DECENT_WORK_ECONOMIC_GROWTH_PNG from "../../assets/sdg-indicators/decent_work_economic_growth.png";
import INDUSTRY_INNOVATION_INFRASTRUCTURE_PNG from "../../assets/sdg-indicators/industry_innovation_infrastructure.png";
import REDUCED_INEQUALITIES_PNG from "../../assets/sdg-indicators/reduced_inequalities.png";
import SUSTAINABLE_CITIES_COMMUNITIES_PNG from "../../assets/sdg-indicators/sustainable_cities_communities.png";
import RESPONSIBLE_CONSUMPTION_PRODUCTION_PNG from "../../assets/sdg-indicators/responsible_consumption_production.png";
import CLIMATE_ACTION_PNG from "../../assets/sdg-indicators/climate_action.png";
import LIFE_BELOW_WATER_PNG from "../../assets/sdg-indicators/life_below_water.png";
import LIFE_ON_LAND_PNG from "../../assets/sdg-indicators/life_on_land.png";
import PEACE_JUSTICE_INSTITUTIONS_PNG from "../../assets/sdg-indicators/peace_justice_institutions.png";
import PARTNERSHIPS_FOR_THE_GOALS_PNG from "../../assets/sdg-indicators/partnerships_for_the_goals.png";

const IMAGES: Record<string, string> = {
  NO_POVERTY: NO_POVERTY_PNG,
  ZERO_HUNGER: ZERO_HUNGER_PNG,
  GOOD_HEALTH_WELLBEING: GOOD_HEALTH_WELLBEING_PNG,
  QUALITY_EDUCATION: QUALITY_EDUCATION_PNG,
  GENDER_EQUALITY: GENDER_EQUALITY_PNG,
  CLEAN_WATER_SANITATION: CLEAN_WATER_SANITATION_PNG,
  AFFORDABLE_CLEAN_ENERGY: AFFORDABLE_CLEAN_ENERGY_PNG,
  DECENT_WORK_ECONOMIC_GROWTH: DECENT_WORK_ECONOMIC_GROWTH_PNG,
  INDUSTRY_INNOVATION_INFRASTRUCTURE: INDUSTRY_INNOVATION_INFRASTRUCTURE_PNG,
  REDUCED_INEQUALITIES: REDUCED_INEQUALITIES_PNG,
  SUSTAINABLE_CITIES_COMMUNITIES: SUSTAINABLE_CITIES_COMMUNITIES_PNG,
  RESPONSIBLE_CONSUMPTION_PRODUCTION: RESPONSIBLE_CONSUMPTION_PRODUCTION_PNG,
  CLIMATE_ACTION: CLIMATE_ACTION_PNG,
  LIFE_BELOW_WATER: LIFE_BELOW_WATER_PNG,
  LIFE_ON_LAND: LIFE_ON_LAND_PNG,
  PEACE_JUSTICE_INSTITUTIONS: PEACE_JUSTICE_INSTITUTIONS_PNG,
  PARTNERSHIPS_FOR_THE_GOALS: PARTNERSHIPS_FOR_THE_GOALS_PNG
};

export default IMAGES;
