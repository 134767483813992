import React from "react";
import { Button, CircleButton, FontAwesomeIcon } from "@nef/core";
import styled from "styled-components";
import { useClient } from "jsonapi-react";

import FormLabel from "../../../common/FormLabel";
import { SupportingDocument } from "../../../../api/types";

import useSupportingDocsUpload from "./useSupportingDocsUpload";
import styles from "./SupportingDocsUploader.module.scss";

const UploadEntry = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const DownloadButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  flex: 1;
  text-align: start;
  padding: 1rem 1.5rem 1rem 0;
  color: #0092bc;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 1rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

type Document = {
  id?: string;
  supportingDocument: {
    identifier: string | null;
    url: string | null;
  };
};

function SupportingDocsUploader({
  productId,
  onSuccess,
  onDeleteFile,
  supportingDocs,
  testid,
  maxFiles = 1,
  label = "",
  required = false
}: {
  productId: string;
  onSuccess?: (doc: SupportingDocument) => void;
  onDeleteFile?: (_: string) => void;
  supportingDocs: Document[];
  testid: string;
  maxFiles?: number;
  label?: string;
  required?: boolean;
}) {
  const { getRootProps, getInputProps, isDragActive, open, isLoading } =
    useSupportingDocsUpload(productId, onSuccess, maxFiles);

  const client = useClient();
  const destroyProductSupportingDoc = (docToDeleteId: string) => {
    return client.delete(["product-supporting-documents", docToDeleteId]);
  };

  return (
    <>
      <FormLabel required={required}>{label}</FormLabel>
      <div
        className={`${styles.dropzone} ${
          isDragActive && styles["dropzone-active"]
        } ${isLoading && styles["dropzone-loading"]}`}
        {...getRootProps()}
      >
        <label htmlFor={testid}>
          <input {...getInputProps()} id={testid} data-testid={testid} />
          Drag and drop to upload or&nbsp;
          <Button
            className={styles.browse}
            outline={true}
            onClick={open}
            size="sm"
          >
            browse
          </Button>
        </label>
      </div>
      {supportingDocs.map(doc => (
        <UploadEntry key={doc?.supportingDocument?.identifier}>
          <DownloadButton
            type="button"
            title={doc?.supportingDocument?.identifier || ""}
          >
            <a
              href={doc?.supportingDocument?.url || undefined}
              target="_blank"
              rel="noopener noreferrer"
            >
              {doc?.supportingDocument?.identifier}
            </a>
          </DownloadButton>
          {onDeleteFile && (
            <CircleButton
              ghost
              size="sm"
              data-testid="delete-additional-material"
              onClick={async () => {
                if (!doc.id) return;

                await destroyProductSupportingDoc(doc.id);
                onDeleteFile(doc.id);
              }}
            >
              <FontAwesomeIcon iconClassName="fa-trash" />
            </CircleButton>
          )}
        </UploadEntry>
      ))}
    </>
  );
}

export default SupportingDocsUploader;
