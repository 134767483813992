import React, { useCallback, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircleButton,
  FontAwesomeIcon,
  FormField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "@nef/core";
import { toast } from "react-toastify";

import { createDirectory } from "../../api/api";
import Toast from "../Toast";

const CreateButton = styled(CircleButton)`
  margin-left: 1rem;
  height: 100%;
`;

const ErrorMessage = styled.span`
  color: #f9585f;
  font-size: 0.875rem;
`;

const createDirectorySchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(
      /^[a-zA-Z0-9-_]+$/,
      "directory name must contain only alphanumerical, dash and underscore characters"
    )
});

const CreateDirectoryButton = ({
  prefix = "/",
  refetch,
  groupId
}: {
  prefix: string;
  groupId: string;
  refetch?: () => void;
}) => {
  const [isOpen, setOpen] = useState(false);
  const { control, handleSubmit, setValue, clearErrors } = useForm({
    resolver: yupResolver(createDirectorySchema)
  });
  const onDismiss = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const onOpen = useCallback(() => {
    setOpen(true);
    setValue("name", "");
    clearErrors();
  }, [setOpen]);

  const onConfirm = useCallback(
    async formData => {
      try {
        await createDirectory(prefix + formData.name, groupId);
        setOpen(false);
        refetch?.();
      } catch (error: any) {
        toast(
          <Toast
            type="error"
            title="Create Directory Failed"
            details={[{ message: error?.message }]}
          />
        );
      } finally {
        toast(<Toast type="success" title="Directory created" details={[]} />);
      }
    },
    [setOpen, groupId]
  );
  return (
    <>
      <CreateButton onClick={onOpen} data-testid="createDirectoryModal_button">
        <FontAwesomeIcon
          iconClassName="fa-folder-plus"
          data-testid="moreInfoTooltip_icon"
        />
      </CreateButton>
      <Modal
        isOpen={isOpen}
        toggle={onDismiss}
        data-testid="createDirectoryModal"
      >
        <form onSubmit={handleSubmit(onConfirm)}>
          <ModalHeader
            toggle={onDismiss}
            data-testid="createDirectoryModal_title"
          >
            Create Directory
          </ModalHeader>
          <ModalBody data-testid="createDirectoryModal_body">
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error, isDirty }
              }) => {
                return (
                  <>
                    <FormField
                      id="name"
                      name={name}
                      type="text"
                      placeholder="Directory name"
                      label="Name"
                      value={value}
                      onChange={onChange}
                      invalid={!!error}
                      data-testid="createDirectoryModal_input"
                      aria-describedby="createDirectoryModal_nameError"
                    />
                    {(isDirty || !!error) && error?.message && (
                      <ErrorMessage
                        id="createDirectoryModal_nameError"
                        data-testid="createDirectoryModal_nameError"
                        aria-live="polite"
                      >
                        {error.message}
                      </ErrorMessage>
                    )}
                  </>
                );
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              size="md"
              color="light"
              onClick={onDismiss}
              data-testid="createDirectoryModal_dismiss"
            >
              Cancel
            </Button>

            <Button
              type="submit"
              size="md"
              data-testid="createDirectoryModal_confirm"
            >
              Create
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CreateDirectoryButton;
