import React from "react";

const DefaultIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.25" y="0.25" width="14.5" height="14.5" rx="7.25" fill="white" />
    <rect
      x="0.25"
      y="0.25"
      width="14.5"
      height="14.5"
      rx="7.25"
      stroke="#007899"
      strokeWidth="0.5"
    />
    <path
      d="M8.144 6.65999H10.79V8.15799H8.144V10.79H6.646V8.15799H4V6.65999H6.646V3.99999H8.144V6.65999Z"
      fill="#0094BD"
    />
  </svg>
);

export default DefaultIcon;
