const TOOLTIP = {
  PRODUCT_NAME:
    'A product name should be specific, clear, and descriptive eg. "End of Day US Stock Prices"',
  PRODUCT_CODE:
    'A product code is used to uniquely identify the product on Data Link. Consider using an acronym. For example, the product code for "Nasdaq Dorsey Wright Equity Technical Analysis Data" is NDWEQTA, and it\'s used in the product page URL for this product: https://data.nasdaq.com/databases/NDWEQTA',
  DESCRIPTION: "A one-line description of the dataset. Maximum 400 characters.",
  OVERVIEW:
    "A longer description of what the data covers, how much history is available, where the data is sourced, how frequently it updates and any unique aspects",
  DATA_FREQUENCY:
    "Provide the granularity of the data. For example, a dataset may show data at minutely intervals but the data is only refreshed and delivered once a day, in which case the granularity is minutely.",
  DELIVERY_FREQUENCY:
    "Indicate how often the data gets updated at the source and enter the time the data gets updated eg. Daily [10am ET Monday to Friday]. ",
  DELIVERY_LAG:
    "Delivery lag refers to the mechanics of ingesting data. Indicate the delivery lag which refers to the  amount of time between the data being generated at the source and the time it arrives where it can be ingested by Data Link eg. 1 day.",
  REPORTING_LAG:
    "Reporting lag refers to the data itself and the amount of time between the event being measured and the data being created eg. eVestment Holdings Data which is reported quarterly has 1-3 month reporting lag because users have up to 90 days to update their holdings from the previous quarer. ",
  POINT_IN_TIME:
    "Is the data point-in-time? That is, the data was collected at the time the event occurred, exactly as it was at the time data was originally collected and no historical adjustments or corrections have been applied. ",
  DATA_TIMEZONE:
    "Does the dataset contain data points related to times eg. trading hour? If so, please indicate the time zones those data points refer to. ",
  DATA_HOLIDAY_SCHEDULE:
    "Provide a list of dates when there will be no data or data will be delayed due to holidays. If there are no holidays, enter 'none' in this field.",
  INFOSEC_CLASSIFICATION:
    'What is the <a href="https://insiderfinder.nasdaq.com/hc/en-us/articles/4408306248729?lang=en" target="_blank" rel="noreferrer">Infosec classification</a> for this dataset?',
  GDPR_RESTRICTIONS:
    "Is this dataset subject to GDPR restrictions? If so, what controls are in place or  need to be implemented? ",
  PII: "Does the dataset contain personally identifiable information (PII)? If so, is it annonymized? ",
  SEC_REGULATION: "Is the product government regulated (e.g. SEC, MiFID II)?",
  COVERAGE:
    'Coverage indicates the market  coverage of the product eg. "US-listed stocks and ETPs"',
  FRAMEWORK: "Indicate any relevant Frameworks or Regulations",
  HISTORY:
    "Since: If the history is available from a particular date, select 'Since'. Rolling: If there is no history, select 'Rolling'. Projected: If the history is available to be projected to a certain date, select 'Projected'.",
  PRODUCT_CATEGORY_ASSET_CLASS:
    "What asset class does this dataset cover? What product category does this dataset fall under?",
  PRODUCT_CATEGORY_DATA_TYPE: "Indicate the data type for this dataset.",
  PRODUCT_CATEGORY_REGION:
    "Indicate regions covered in this dataset. If three or more regions are selected, the data will be tagged as Global in search on Data Link.",
  PRODUCT_CATEGORY_AREA_OF_IMPACT: "Indicate area of impact for this dataset.",
  PRODUCT_CATEGORY_SUSTAINABLE_DEV_GOALS:
    "Indicate any relevant Sustainable Development Goals.",
  PRODUCT_CATEGORY_INVESTMENT_STYLE: "Indicate the relevant investment styles.",
  PRODUCT_CATEGORY_INDUSTRY_SECTOR:
    "Indicate any relevant industries or sectors.",
  PRODUCT_CATEGORY_DATA_ORIGIN: "Indicate any relevant data origin sources.",
  USER_DEFINED_TAGS:
    "You may enter up to 5 tags to help better categorize the data. Press “enter” after typing a tag to add it.",
  DATA_HOSTING_TAGS: "Will your data be hosted on Nasdaq Data Link?",
  TABLE_NAMES_AND_DESCRIPTION:
    "Name and brief description for each table in this dataset.",
  DATA_DICTIONARY:
    "Provide a data dictionary for each table, outlining column names, column definitions, data type for each column  (string, date, integer, datetime, double, decimal), primary keys, and which columns you want as filters.",
  DATA_DICTIONARY_NO_LINK:
    "Provide a data schema for the table, outlining column names, column definitions, data type for each column, primary keys, and which columns you want as filters. Please download the template, fill in the first tab, and export it to CSV before uploading.",
  TABLE_NAME_DESCRIPTION:
    "Name and brief description for each table in this dataset.",
  TABLE_CODE:
    "This code will be used as a unique identifier in clients' API queries and when new tables are created during onboarding. Please specify a 4-5 character alphabetic code.",
  OTHER_DOCUMENTATION: "Provide any other documentation for this dataset.",
  ADDITIONAL_MATERIALS:
    "Please provide any additional materials e.g. whitepaper, research notes, technical specifications, or marketing materials.",
  KNOWN_ISSUES:
    "Disclose any known issues about this data such as quality issues, known gaps, etc.",
  SAMPLE_DATA: "Provide a sharepoint link to sample data.",
  UPLOAD_SAMPLE_DATA:
    "Please upload a file with five cycles of sample data. For example, if the data updates weekly, then 5 weeks of data should be provided. Please upload the file in CSV format. File size limit is 500 MB.",
  RELATED_DATA:
    "Is this dataset related to any other datasets you manage? If so,  please list which datasets this dataset is related to. Please feel free to include a note on how the datasets are related.",
  DATA_SOURCE:
    'Where does the data come from? Select "internal systems" if the data comes from your business unit\'s systems. Select "third-party vendors" if the data comes from vendors outside of Nasdaq. If third-party vendors is selected, then the vendor should upload a redistribution license document. Select "Clients" if the data comes from your business unit\'s clients. Otherwise, select "Other" and indicate the source of the data.',
  DATA_PROVENANCE:
    "Provide a detailed description of where the data originally comes from and what chages were made to it.",
  DATA_ORIGINAL_DERIVED:
    "Indicate if the data is original or was produced by being derived from other data sources.",
  LICENSED_FROM_VENDOR:
    "Does your business unit have a license to redistribute this data or use it in specified ways?",
  VENDOR_NAME:
    "Provide the vendor name or if vendor is already an existing vendor on Data Link, select the vendor name from the dropdown.",
  THIRD_PARTY_PROVIDER_LICENCE:
    "Please indicate third party provider terms, conditions and restrictions for this data.",
  VENDOR_CONTACT:
    "Provide write the name of the contact we have with this vendor.",
  VENDOR_CONTACT_NAME:
    "Provide write the name of the contact we have with this vendor.",
  VENDOR_CONTACT_EMAIL:
    "Please write the email we use to communicate with this contact.",
  VENDOR_CONTACT_PHONE:
    "Please write the phone we use to communicate with this contact. Please be sure to include the country code",
  DATA_LICENCE_TERMS_CONDITIONS:
    "Please indicate any licensing terms, conditions and restrictions for this data.",
  PROCUREMENT_CONTRACTUAL_CONSIDERATIONS:
    "Please indicate any procurement and contractual considerations, or attach any documents related to procucrement. If this is for a third-party vendor, then the vendor should upload a redistribution license document.",
  PRICING_OPTIONS:
    'How would you like this data to be sold or licensed to Data Link customers? Select "Pricing Plan" if you would like customers to be able to subscribe and pay on the Data Link website. Select "Contact Sales" if you would like customers to speak with a sales executive first.',
  VISIBILITY_PREFERENCE:
    "By default, this dataset will be visible to all Nasdaq internal users. Please select your visibility preference accordingly.",
  SOURCE_FORMAT:
    "What is the file type or format of the source data? Select all that apply, especially If there are multiple data tables in this dataset and they each have different formats.",
  INGEST_METHOD:
    "Where is the data coming from? Select all that apply, especially If there are multiple data tables in this dataset and they each have different formats.",
  DATASET_SIZE:
    'Indicate the dataset size across all data tables if there are multiple data tables. Enter "not sure" if you don\'t know.',
  APPROXIMATE_INCREMENTAL_UPDATE_SIZE:
    'Indicate the approximate total incremental update size for this data across all tables if there are multiple data tables eg. "1MB each day". Enter "not sure" if you don\'t know.',
  UPDATE_PATTERN:
    "Replace indicates that the table will be truncated and all existing data will be replaced. Upsert refers to updating the existing records based on the specified primary keys and new records will be inserted. Insert refers to appending all records regardless of whether or not they already exist in the table.",
  DATASET_OWNER:
    "Provide the name and email address of the go-to-person or product owner  for this dataset in your business unit.",
  TECHNICAL_CONTACT:
    "Enter the email addresses to alert relevant personnel from the data publisher teams in the event of a data incident such as delays in updates or unexpected changes in schema, separated by comma.",
  DATATABLE_DATA_GRANULARITY:
    "Provide the granularity of the data. For example, a dataset may show data at minutely intervals but the data is only refreshed and delivered once a day, in which case the granularity is minutely.",
  DATATABLE_UPDATE_FREQUENCY:
    "Indicate how often and when you will be sending the data and enter the time the data gets updated eg. Daily [10am ET Monday to Friday]. If the data is updated weekly, then please provide the day of the week. Please provide the time zone e.g. ET, UTC, GMT if applicable. ",
  DATATABLE_UPDATE_FREQUENCY_INTRADAY_DESCRIPTION:
    "Please describe the contents of the first delivery. eg. The dataset covers the first 15 minutes of the trading day.",
  DATATABLE_REPORTING_LAG:
    "Reporting lag refers to the data itself and the amount of time between the event being measured and the data being created. For example, if the data is reported quarterly, it has a 90 day reporting lag because users have up to 90 days to update their holdings from the previous quarter.",
  DATATABLE_DELIVERY_LAG:
    "Delivery lag refers to the mechanics of ingesting data. It refers to the amount of time between the data being generated at the source and the time it arrives where it can be ingested by Data Link e.g. 1 business day, 1 day, 1 week, 1 month.",
  DATATABLE_POINT_IN_TIME:
    "Is the data point-in-time? That is, the data was collected at the time the event occurred, exactly as it was at the time data was originally collected and no historical adjustments or corrections have been applied.",
  DATATABLE_HOLIDAY_IMPACTS:
    "Select the appropriate options to reflect when there will be no data or data will be delayed due to holidays. If there are no holidays, select 'None.' If Custom is selected, please provide the exact dates and/or holidays. If Other is selected, then please provide the applicable holiday or exchange calendar.",
  DATATABLE_SOURCE_FORMAT:
    "What is the file type or format of the source data?",
  DATATABLE_INGEST_METHOD:
    "Where is the full dataset coming from? Vendors are strongly encouraged to use NDL Uploader (tool to provide data directly to Data Link on an ongoing basis in a secure manner) if possible. If this method is not possible, then they should work with Platform Ops to provide access to their data.",
  DATATABLE_REMOTE_PATH:
    "Enter the remote path to or from which you would like files and folders transferred e.g. /users/sharadar/SF1/.*.csv(.zip)?",
  DATATABLE_FILE_PATTERN:
    "A file pattern could be the title of a file and file extension e.g. Options_Intraday_2022.csv or Options_Intraday_2022.zip.",
  DATATABLE_PORT_NUMBER:
    "Network port number used to connect to FTP/SFTP connection. Default port is 21 for FTP and 22 for SFTP.",
  DATATABLE_PUBLIC_KEY:
    "An alternative authentication method for establishing secure FTP connections. Please only provide a public key if you are not using a password.",
  DATATABLE_TABLE_SIZE: "Please indicate the approximate table size in GB.",
  DATATABLE_INCREMENTAL_UPDATE_SIZE:
    "Indicate the approximate total incremental update size per unit time for this table e.g. '1MB a day.'",
  DATATABLE_INCREMENTAL_ROW_COUNT:
    "Please provide the expected count of records (rows) per data delivery.",
  DATATABLE_UPDATE_PATTERN:
    "Replace indicates that the table will be truncated and all existing data will be replaced. Upsert refers to updating the existing records based on the specified primary keys and new records will be inserted. Insert refers to appending all records regardless of whether or not they already exist in the table.",
  SEARCH_TAGS:
    "Provide targeted keywords that highlight your product's main characteristics, use cases and other unique aspects to help users find it quickly, separated by commas. E.g., options pricing, real-time quotes, consumer behavior.",
  RANK: "This value is used to prioritize the product's rank in search results. A higher value will display the product in a higher position.",
  RECOMMENDED_PRODUCT_CODES:
    "Enter up to six product codes, separated by commas, to showcase recommended products on the product page. E.g., RTAT, SF1."
};

export default TOOLTIP;
