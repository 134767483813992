import { useContext } from "react";

import SessionAuthContext, {
  SessionAuthContextInterface
} from "../contexts/session-auth";

const useSessionAuth = (): SessionAuthContextInterface =>
  useContext(SessionAuthContext);

export default useSessionAuth;
