import React from "react";
import { Modal, ModalBody, ModalHeader } from "@nef/core";
import styled from "styled-components";

import { NewPlan, PlanCategory } from "../../../api/types";

import PlanForm from "./PlanForm";

interface PlanModalProps {
  close: () => void;
  planData: { plan: NewPlan; planCategory: PlanCategory };
}

const StyledModal = styled(Modal)`
  > div > div {
    max-width: 1200px;
  }
`;

const PlanModal = ({ close, planData }: PlanModalProps) => {
  const { plan, planCategory } = planData;

  return (
    <StyledModal
      isOpen={true}
      data-testid="planModal"
      toggle={close}
      closeOnOutsideClick={false}
      size="lg"
    >
      <ModalHeader toggle={close} />
      <ModalBody>
        <PlanForm plan={plan} planCategory={planCategory} close={close} />
      </ModalBody>
    </StyledModal>
  );
};

export default PlanModal;
