import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@nef/core";

import { DatatableSortParam, SortableDatatableField } from "../../api/types";

import styles from "./DatatableListHeader.module.scss";

interface DatatableListHeaderProps {
  sortParam: DatatableSortParam;
  setSortParam: (newSort: DatatableSortParam) => void;
}

const DatatableListHeader = ({
  sortParam,
  setSortParam
}: DatatableListHeaderProps) => {
  const ascending = !sortParam.startsWith("-");
  const sortBy = ascending ? sortParam : sortParam.substring(1);

  const sorterClassName = styles.sorter;
  const activeSorterClassName = `${styles.sorter} ${styles["sorter--active"]}`;

  const icon = ascending ? "fa-caret-up fa-2x" : "fa-caret-down fa-2x";
  const iconTestId = ascending
    ? "datatableListHeader_ascending"
    : "datatableListHeader_descending";

  const updateSortParam = useCallback(
    (newSortBy: SortableDatatableField) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as DatatableSortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy, setSortParam]
  );

  return (
    <div className={styles.header}>
      <div className={styles["name-column"]}>
        <div
          className={
            sortBy === "name" ? activeSorterClassName : sorterClassName
          }
          role="button"
          onClick={() => updateSortParam("name")}
          onKeyPress={() => updateSortParam("name")}
          tabIndex={-1}
          data-testid="datatableListHeader_nameSorter"
        >
          Name
          {sortBy === "name" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles.icon}
              data-testid={iconTestId}
            />
          )}
        </div>
      </div>
      <div>
        <div
          className={
            sortBy === "date" ? activeSorterClassName : sorterClassName
          }
          role="button"
          onClick={() => updateSortParam("date")}
          onKeyPress={() => updateSortParam("date")}
          tabIndex={-1}
          data-testid="datatableListHeader_dateSorter"
        >
          Date
          {sortBy === "date" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles.icon}
              data-testid={iconTestId}
            />
          )}
        </div>
      </div>
      <div />
    </div>
  );
};

export default DatatableListHeader;
