import React, { useEffect, useState } from "react";
import { FormField, FormSelect, FormTextArea } from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import union from "lodash/union";

import useCapProductContext from "../../../hooks/useCapProductContext";
import { BaseStepProps } from "../types";
import buildCapProductSchema from "../schema";
import {
  CapProductToCreate,
  CapProductToEdit,
  Vendor
} from "../../../api/types";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import { fetchVendors } from "../../../api/api";
import IntakeUploader from "../components/IntakeUploader";
import { useDirty } from "../cap-product-util";

import { LICENSED_FROM_VENDOR_DROPDOWN } from "./CapSurveyFormSection4Step2";

const IntakeFormSection7Internal = ({
  fields,
  setFormIsDirty
}: BaseStepProps) => {
  const { product: contextProduct, saveAllData } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const productSchema = buildCapProductSchema({
    internalProduct: true
  });
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToEdit>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    watch,
    setValue,
    reset,
    getValues
  } = form;
  useDirty(isDirty, setFormIsDirty);

  useEffect(() => {
    reset(product);
  }, [product]);

  const { licensedFromVendor, vendorId } = watch("capProductMetadatum");

  const sourceIsThirdPartyVendor =
    product.capProductMetadatum.dataSource === "Third-party vendors";

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<CapProductToCreate> = async formData => {
    await saveAllData({ capMetadata: formData.capProductMetadatum });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  const [vendorOptions, setVendorOptions] = useState<
    { value: number; label: string }[]
  >([]);

  useEffect(() => {
    fetchVendors().then(response => {
      const sortedVendorDropdownOptions = response.data.vendors
        .map((v: Vendor) => {
          return {
            value: v.id,
            label: v.name
          };
        })
        .sort((vendorOne: any, vendorTwo: any) => {
          return vendorOne.label.localeCompare(vendorTwo.label.toLowerCase());
        });

      sortedVendorDropdownOptions.push({
        value: null,
        label: "Other"
      });

      setVendorOptions(sortedVendorDropdownOptions);
    });
  }, []);

  const handleVendorChange = (
    onChange: (...event: any[]) => void,
    formSelectOnChangeParam: any
  ) => {
    const newValue = extractFormSelectOnChangeValue(formSelectOnChangeParam);
    onChange(newValue);

    if (newValue) {
      setValue("capProductMetadatum.vendorName", null, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="intakeFormSection7Internal">
          {sourceIsThirdPartyVendor && (
            <>
              <CapSurveyFormFieldRow tooltip={TOOLTIP.LICENSED_FROM_VENDOR}>
                <Controller
                  name="capProductMetadatum.licensedFromVendor"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <FormSelect
                        id="gdprRestrictions"
                        name={name}
                        label="Licenced from Vendor"
                        value={
                          LICENSED_FROM_VENDOR_DROPDOWN.find(
                            o => o.value === value
                          ) || null
                        }
                        placeholder="Licenced from Vendor"
                        options={LICENSED_FROM_VENDOR_DROPDOWN}
                        optional={false}
                        isClearable={false}
                        onChange={(formSelectOnChangeParam: any) => {
                          onChange(
                            extractFormSelectOnChangeValue(
                              formSelectOnChangeParam
                            )
                          );
                        }}
                        classNamePrefix="licensedFromVendor"
                      />
                    );
                  }}
                />
              </CapSurveyFormFieldRow>
              {licensedFromVendor && (
                <CapSurveyFormFieldRow
                  tooltip={TOOLTIP.THIRD_PARTY_PROVIDER_LICENCE}
                >
                  <Controller
                    name="capProductMetadatum.thirdPartyProviderLicence"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <FormTextArea
                        id="thirdPartyProviderLicence"
                        name={name}
                        label="Third Party Provider Licence"
                        value={value || ""}
                        optional={false}
                        rows={4}
                        onChange={onChange}
                        data-testid="capSurveyForm_thirdPartyProviderLicence"
                      />
                    )}
                  />
                </CapSurveyFormFieldRow>
              )}
            </>
          )}
          {!sourceIsThirdPartyVendor && (
            <CapSurveyFormFieldRow
              tooltip={TOOLTIP.DATA_LICENCE_TERMS_CONDITIONS}
            >
              <Controller
                name="capProductMetadatum.dataLicenceTermsConditions"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormTextArea
                    id="dataLicenceTermsConditions"
                    name={name}
                    label="Data Licence Terms & Conditions"
                    value={value || ""}
                    optional={false}
                    rows={4}
                    onChange={onChange}
                    data-testid="capSurveyForm_dataLicenceTermsConditions"
                  />
                )}
              />
            </CapSurveyFormFieldRow>
          )}

          {sourceIsThirdPartyVendor && (
            <>
              <CapSurveyFormFieldRow tooltip={TOOLTIP.VENDOR_NAME}>
                <Controller
                  name="capProductMetadatum.vendorId"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <FormSelect
                        id="vendorId"
                        name={name}
                        label="Vendor Name"
                        value={
                          vendorOptions.find(o => o.value === value) || null
                        }
                        placeholder="Select a vendor"
                        options={vendorOptions}
                        optional={false}
                        isClearable={false}
                        onChange={(formSelectOnChangeParam: any) => {
                          handleVendorChange(onChange, formSelectOnChangeParam);
                        }}
                        classNamePrefix="vendorId"
                      />
                    );
                  }}
                />
              </CapSurveyFormFieldRow>
              {vendorId === null && (
                <CapSurveyFormFieldRow tooltip="">
                  <Controller
                    name="capProductMetadatum.vendorName"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <FormField
                        id="vendorName"
                        name={name}
                        label="Vendor Name"
                        value={value || ""}
                        optional={false}
                        onChange={onChange}
                        data-testid="capSurveyForm_vendorName"
                      />
                    )}
                  />
                </CapSurveyFormFieldRow>
              )}
              <CapSurveyFormFieldRow tooltip={TOOLTIP.VENDOR_CONTACT_NAME}>
                <Controller
                  name="capProductMetadatum.vendorContactName"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormField
                      id="vendorContactName"
                      name={name}
                      label="Vendor Contact Name"
                      placeholder='eg. "James Money"'
                      value={value || ""}
                      optional={false}
                      onChange={onChange}
                      data-testid="capSurveyForm_vendorContactName"
                    />
                  )}
                />
              </CapSurveyFormFieldRow>
              <CapSurveyFormFieldRow tooltip={TOOLTIP.VENDOR_CONTACT_EMAIL}>
                <Controller
                  name="capProductMetadatum.vendorContactEmail"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormField
                      id="vendorContactName"
                      name={name}
                      label="Vendor Contact Email"
                      placeholder='eg. "james@money.com"'
                      value={value || ""}
                      optional={false}
                      onChange={onChange}
                      type="email"
                      data-testid="capSurveyForm_vendorContactEmail"
                    />
                  )}
                />
              </CapSurveyFormFieldRow>

              <CapSurveyFormFieldRow tooltip={TOOLTIP.VENDOR_CONTACT_PHONE}>
                <Controller
                  name="capProductMetadatum.vendorContactPhone"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormField
                      id="vendorContactPhone"
                      name={name}
                      label="Vendor Contact Phone"
                      placeholder='eg. "+1-800-867-5309"'
                      value={value || ""}
                      optional={false}
                      onChange={onChange}
                      data-testid="capSurveyForm_vendorContactPhone"
                    />
                  )}
                />
              </CapSurveyFormFieldRow>
            </>
          )}

          <CapSurveyFormFieldRow
            tooltip={TOOLTIP.PROCUREMENT_CONTRACTUAL_CONSIDERATIONS}
          >
            <Controller
              name="capProductMetadatum.procurementContractualConsiderations"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormTextArea
                  id="procurementContractualConsiderations"
                  name={name}
                  label="Procurement and Contractual Considerations"
                  value={value || ""}
                  optional={true}
                  rows={4}
                  onChange={onChange}
                  data-testid="capSurveyForm_procurementContractualConsiderations"
                />
              )}
            />
            <Controller
              name="capProductMetadatum.procurementDocumentUrls"
              control={control}
              render={({ field: { onChange, value } }) => (
                <IntakeUploader
                  label="Procurement Documents"
                  path={`/products/${product.id}/procurement/`}
                  vendorId={product.vendor.id}
                  value={value || []}
                  testid="procurement-document-upload-field"
                  onSuccess={v => {
                    const existingValues =
                      getValues(
                        "capProductMetadatum.procurementDocumentUrls"
                      ) || [];
                    onChange(union(v, existingValues));
                  }}
                  onDeleteFile={filename => {
                    if (!value) return;
                    const updatedValue = value.filter(v => v !== filename);
                    onChange(updatedValue);
                  }}
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormSection7Internal;
