import React from "react";
import { FontAwesomeIcon, FormSelect } from "@nef/core";
import { Controller, FieldArrayWithId, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { DatatableColumn } from "../../../api/types";

import styles from "./ColumnFiltersModal.module.scss";
import DefaultFilterValueInput from "./DefaultFilterValueInput";

interface DefaultFilterInputProps {
  filter: FieldArrayWithId<DatatableColumn, "defaultFilters", "id">;
  index: number;
  tryRemoveDefaultFilter: (
    defaultFilter: FieldArrayWithId<DatatableColumn, "defaultFilters", "id">
  ) => void;
}

const FormSelectForGrid = styled(FormSelect)`
  margin-bottom: 0 !important;
`;

const FILTER_OPERATOR_OPTIONS = [
  { value: "eq", label: "Equal" },
  { value: "in", label: "Includes" },
  { value: "nin", label: "Not included" },
  { value: "gt", label: "Greater-than" },
  { value: "gte", label: "Greater-than or equal to" },
  { value: "lt", label: "Less-than" },
  { value: "lte", label: "Less-than or equal to" }
];

const DefaultFilterInput = ({
  filter,
  index,
  tryRemoveDefaultFilter
}: DefaultFilterInputProps) => {
  const { control } = useFormContext();

  return (
    <div className={styles.row} key={filter.id}>
      <div className={styles.column}>&nbsp;</div>
      <div className={styles.column}>
        <Controller
          control={control}
          name={`defaultFilters.${index}.operator`}
          render={({ field: { value, name, onChange } }) => {
            return (
              <FormSelectForGrid
                name={name}
                label="Filter"
                value={FILTER_OPERATOR_OPTIONS.find(v => v.value === value)}
                options={FILTER_OPERATOR_OPTIONS}
                onChange={(e: any) => onChange(e.value.value)}
                classNamePrefix="operator"
              />
            );
          }}
        />
      </div>
      <div className={styles.column}>
        <Controller
          control={control}
          name={`defaultFilters.${index}.value`}
          render={({ field: { value, name, onChange } }) => (
            <DefaultFilterValueInput
              index={index}
              value={value}
              name={name}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={styles.column}>
        <div className={styles["delete-icon"]}>
          <button
            type="button"
            onClick={() => tryRemoveDefaultFilter(filter)}
            data-testid="columnFiltersModal_removeFilter"
          >
            <FontAwesomeIcon
              iconSetClassName="far"
              iconClassName="fa-trash-alt"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DefaultFilterInput;
