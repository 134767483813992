import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormFieldWithIcon,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "jsonapi-react";

import MultiSelect, { MultiSelectOption } from "../../common/MultiSelect";
import { ProductPermission } from "../../../api/types";

import styles from "./ProductAccessModal.module.scss";

interface NewUserGroupRequest {
  products: string[];
}

interface FormValues {
  products: string[];
}

interface ProductAccessModalProps {
  isOpen: boolean;
  close: () => void;
  vendorId: string;
  selected: string[];
  onUpdate: (products: any) => void;
}

const userGroupRequestSchema = yup.object({
  products: yup.array().of(yup.string())
});

const ProductAccessModal = ({
  isOpen,
  close,
  vendorId,
  selected,
  onUpdate
}: ProductAccessModalProps) => {
  const [productOptions, setProductOptions] = useState(
    [] as MultiSelectOption[]
  );
  const [filteredProductOptions, setFilteredProductOptions] = useState(
    [] as MultiSelectOption[]
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchQuery(enteredQuery);
  };

  const { data: products, refetch } = useQuery<ProductPermission[]>([
    "datatable-collections",
    {
      "filter[vendor_id]": vendorId
    }
  ]);

  useEffect(() => {
    if (isOpen && refetch) {
      refetch();
    }
  }, [isOpen]);

  useEffect(() => {
    if (products && isOpen) {
      setProductOptions(
        products
          .map((product: ProductPermission) => {
            return {
              value: product.id,
              label: product.name
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      );
      setValue("products", selected);
    }
  }, [products, isOpen]);

  useEffect(() => {
    if (productOptions) {
      setFilteredProductOptions(
        productOptions.filter((option: MultiSelectOption) => {
          return (
            searchQuery === "" ||
            option.label.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
      );
    }
  }, [productOptions, searchQuery]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    reset
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      products: []
    },
    resolver: yupResolver(userGroupRequestSchema)
  });

  const onSubmit: SubmitHandler<NewUserGroupRequest> = useCallback(
    async data => {
      if (products) {
        onUpdate(
          products.filter(product => data.products.includes(product.id)) || []
        );
      }

      handleClose();
    },
    [products]
  );

  const handleClose = () => {
    close();
    setSearchQuery("");
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="productAccessModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Product Access"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Product Access
      </ModalHeader>
      <ModalBody>
        <div>
          <FormFieldWithIcon
            data-testid="productAccessModal_search"
            placeholder="Search"
            iconClassName="fa-search"
            addonType="append"
            value={searchQuery}
            onChange={handleSearch}
          />

          <Controller
            control={control}
            name="products"
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                options={filteredProductOptions}
                value={value || selected}
                invalid={!!formErrors.products}
                feedback={(formErrors?.products as any)?.message}
                onChange={onChange}
              />
            )}
          />

          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(formErrors).length !== 0}
              data-testid="productAccessModal_submit"
            >
              Update
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="productAccessModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProductAccessModal;
