import React, { useEffect, useState } from "react";

import styles from "./CharacterCountWidget.module.scss";

interface CharacterCountWidgetProps {
  text: string;
  maxLength: number;
}

const CharacterCountWidget = ({
  text,
  maxLength
}: CharacterCountWidgetProps) => {
  const [characterLimitIndicatorStyles, setCharacterLimitIndicatorStyles] =
    useState<string>("");

  useEffect(() => {
    if (text.length > maxLength * 0.8 && text.length < maxLength) {
      setCharacterLimitIndicatorStyles(styles.warning);
    } else if (text.length >= maxLength) {
      setCharacterLimitIndicatorStyles(styles.exceeded);
    } else {
      setCharacterLimitIndicatorStyles("");
    }
  }, [text]);

  return (
    <div className={`${styles.counter} ${characterLimitIndicatorStyles}`}>
      {text.length} / {maxLength}
    </div>
  );
};

export default CharacterCountWidget;
