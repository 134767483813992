import React from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { CursorPageInfo, StringIndexable } from "../../api/types";

import styles from "./CursorPagination.module.scss";

interface CursorPaginationProps {
  pathname: string;
  baseQueryParams?: StringIndexable;
  pageInfo?: CursorPageInfo;
  beforeQueryParam?: string;
  afterQueryParam?: string;
}

const NavButton = styled(Button)`
  &:hover {
    text-decoration: none;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;

    > i {
      color: #949494;
    }
  `}
`;

const queryParamsToQueryString = (queryParms: StringIndexable) => {
  const queryParamList: string[] = [];
  Object.entries(queryParms).forEach(([key, value]) => {
    if (value) {
      queryParamList.push(`${key}=${value}`);
    }
  });

  return queryParamList.join("&");
};

const CursorPagination = ({
  pathname,
  baseQueryParams,
  pageInfo,
  beforeQueryParam = "before",
  afterQueryParam = "after"
}: CursorPaginationProps) => {
  const history = useHistory();
  const baseSearch = baseQueryParams || {};

  const prevPageSearch = {
    ...baseSearch,
    [beforeQueryParam]: pageInfo?.prevPage
  };
  const nextPageSearch = {
    ...baseSearch,
    [afterQueryParam]: pageInfo?.nextPage
  };

  const navigateTo = (search: StringIndexable) => {
    history.push({
      pathname,
      search: queryParamsToQueryString(search)
    });
  };

  return (
    <div className={styles.pagination} data-testid="cursor-pagination">
      <NavButton
        color="link"
        onClick={() => navigateTo(prevPageSearch)}
        disabled={!pageInfo?.prevPage}
        data-testid="cursor-pagination_previous"
      >
        <FontAwesomeIcon iconClassName="fa-angle-left" />
        <span className={styles.label}>Previous Page</span>
      </NavButton>
      <NavButton
        color="link"
        onClick={() => navigateTo(nextPageSearch)}
        disabled={!pageInfo?.nextPage}
        data-testid="cursor-pagination_next"
      >
        <span className={styles.label}>Next Page</span>
        <FontAwesomeIcon iconClassName="fa-angle-right" />
      </NavButton>
    </div>
  );
};

export default CursorPagination;
