import HeaderFormatValidation from "./HeaderFormatValidation";
import { Validation, ValidateContext } from "./types";
import PrimaryKeysValidation from "./PrimaryKeysValidation";
import TableNameValidation from "./TableNameValidation";
import ColumnNamesValidation from "./ColumnNamesValidation";
import FilterValidation from "./FilterValidation";
import DataTypesValidation from "./DataTypesValidation";

export const HEADER_ROW_COUNT = 4;
export const COLUMN_FIELD_INDEX = {
  NAME: 0,
  DEFINITION: 1,
  DATA_TYPE: 2,
  PRIMARY_KEY: 3,
  FILTER: 4
};

// - Validator works by running and return the results from each Validation.
// - Each Validation validates only a specific requirement from the criteria.
// - This should make it easier to track which requirements have been implemented,
// customizes error messages, and  minimizes side effects between different
// requirements.
class Validator {
  validations: Validation<any>[] = [];

  constructor(validations: Validation<any>[]) {
    this.validations = validations;
  }

  async run(headers: string[][], rows: string[][], context: ValidateContext) {
    const allResults = await Promise.all(
      this.validations.map(async validation => {
        const dataToValidate = validation.extractData(headers, rows);
        return validation.validate(dataToValidate, context);
      })
    );
    return allResults.flat();
  }
}

const DEFAULT_VALIDATIONS: Validation<any>[] = [
  HeaderFormatValidation,
  TableNameValidation,
  PrimaryKeysValidation,
  FilterValidation,
  ColumnNamesValidation,
  DataTypesValidation
];

const validate = async (
  rawData: string[][],
  context: ValidateContext,
  validations = DEFAULT_VALIDATIONS
) => {
  const { headers, rows } = extractHeadersAndRows(rawData);
  const validator = new Validator(validations);
  return validator.run(headers, rows, context);
};

const extractHeadersAndRows = (rawData: string[][]) => {
  const headers = Array.from({ length: HEADER_ROW_COUNT }).map(
    (_, index: number) => {
      return rawData[index] || [];
    }
  );

  const rows =
    rawData.length > HEADER_ROW_COUNT ? rawData.slice(HEADER_ROW_COUNT) : [];

  return { headers, rows };
};

export default validate;
