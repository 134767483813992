import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "jsonapi-react";

import DatatableContext from "../../contexts/datatable";
import EditDatatableForm from "../../components/datatables-form/EditDatatableForm";
import { DatatableSchema } from "../../api/types";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import { PATHS } from "../../routes";

const EditDatatablePage = () => {
  const history = useHistory();
  const { authenticatedUser } = useDefaultAuth();

  const { vendorCode, datatableCode, versionCode } = useParams<{
    vendorCode: string;
    datatableCode: string;
    versionCode: string;
  }>();

  const { data: existingDatatable, isLoading } = useQuery<DatatableSchema>([
    "datatable_schema",
    `${vendorCode}/${datatableCode}/${versionCode}`
  ]);

  const [datatable, setDatatable] = useState<any>();

  useEffect(() => {
    // ensures that when redirecting to a new version, the context inside
    // the children (especiall RFH related hooks) get the most up-to-date value
    setDatatable(undefined);
  }, [versionCode]);

  useEffect(() => {
    setDatatable(existingDatatable);
  }, [existingDatatable]);

  if (!authenticatedUser?.rolesArray.includes("manager_admin")) {
    history.push(PATHS.PUBLISHERS);
    return null;
  }

  if (isLoading) return null;
  if (!existingDatatable)
    return (
      <div data-testid="editDatatable_notFound">Datatable cannot be found</div>
    );
  if (!datatable) return null;

  const context = { datatable, setDatatable };

  return (
    <DatatableContext.Provider value={context}>
      <EditDatatableForm />
    </DatatableContext.Provider>
  );
};
export default EditDatatablePage;
