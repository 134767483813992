import React, { useCallback, useContext } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import axios from "axios";
import { toast } from "react-toastify";
import { generatePath, Link } from "react-router-dom";

import DatatableContext from "../../contexts/datatable";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import Toast from "../Toast";
import CSV_FILE_ICON from "../../assets/csv-file.svg";
import MoreInfoTooltip from "../MoreInfoTooltip";
import { PATHS } from "../../routes";

import styles from "./DataUploader.module.scss";
import { SAMPLE_FILE_UPLOAD_INFO } from "./hints";

interface DataUploaderProps {
  saveFileFormat: (redirect: boolean) => Promise<boolean>;
}

const MAX_FILE_SIZE = 1024 * 1024; // 1MB
const mapErrorCodeToMessage = (code: string) => {
  switch (code) {
    case "file-invalid-type":
      return "Data sample must be a .csv file";
    case "file-too-large":
      return "Data sample must be smaller than 1MB";
    default:
      return "";
  }
};

const DataUploader = ({ saveFileFormat }: DataUploaderProps) => {
  const { datatable } = useContext(DatatableContext);
  const { authenticatedUser } = useDefaultAuth();

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      const fileFormatResponse = await saveFileFormat(false);
      if (!fileFormatResponse) {
        return false;
      }

      try {
        const file = acceptedFiles[0];
        const { upload_link: uploadLink } = await generateUploadLink(file);

        await uploadFile(file, uploadLink);
        toast(
          <Toast
            type="info"
            title="File is uploaded for ingestion"
            details={[
              {
                message:
                  "Your sample data file has been successfully submitted for processing. Click “View Data Ingestion Logs” to see if the sample data matches the schema (status will indicate succeeded or failed)."
              }
            ]}
          />
        );
        return true;
      } catch (e) {
        toast(
          <Toast
            type="error"
            title="File upload failed"
            details={[
              {
                message: "Your sample data file could not be submitted."
              }
            ]}
          />
        );
        return false;
      }
    },
    [saveFileFormat]
  );

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const errorDetails = fileRejections
      .map(({ errors }) => {
        return errors.map(error => {
          return { message: mapErrorCodeToMessage(error.code) };
        });
      })
      .flat();

    toast(
      <Toast type="error" title="File upload failed" details={errorDetails} />
    );
  }, []);

  const generateUploadLink = async (file: File) => {
    const { vendorCode, code } = datatable;
    const versionCode = datatable.version.code;

    const headers = { "X-API-Token": authenticatedUser?.apiKey || "" };
    const url = `/manage/datatable_schema/${vendorCode}/${code}/${versionCode}/upload_link?filename=${file.name}`;
    return fetch(url, { method: "GET", headers }).then(response => {
      return response.json();
    });
  };

  const uploadFile = useCallback(async (file: File, uploadLink: string) => {
    const response = await axios.put(uploadLink, file);
    return response;
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    accept: {
      "text/csv": [".csv"]
    },
    maxSize: MAX_FILE_SIZE
  });

  const datatableIsPublished = datatable.version.code === "FINAL";

  return (
    <div className={styles.uploader} data-testid="dataUploader">
      <div
        className={`${styles.description} ${
          datatableIsPublished
            ? styles["justify-content-end"]
            : styles["justify-content-between"]
        }`}
      >
        {!datatableIsPublished && (
          <span data-testid="dataUploader_description">
            Optional: Upload a data sample to check Table Scheme
          </span>
        )}
        <Link
          to={generatePath(PATHS.DATATABLE_UPLOAD_LOGS, {
            vendorCode: datatable.vendorCode,
            datatableCode: datatable.code,
            versionCode: datatable.version.code
          })}
          className={styles["show-events"]}
          style={{}}
          data-testid="dataUploader_uploadLogsLink"
        >
          View Data Ingestion Logs
        </Link>
      </div>
      {!datatableIsPublished && (
        <div
          className={`${styles.dropzone} ${
            isDragActive && styles["dropzone-active"]
          }`}
          {...getRootProps({
            maxfiles: 1,
            multiple: false
          })}
          data-testid="dataUploader_dropzone"
        >
          <label htmlFor="upload-file">
            <input
              {...getInputProps()}
              id="upload-file"
              data-testid="dataUploader_input"
            />
            <img
              src={CSV_FILE_ICON}
              className={styles["file-icon"]}
              alt="CSV file icon"
            />
            Drag and drop to upload or&nbsp;
            <span
              role="button"
              className={styles.browse}
              onClick={open}
              onKeyPress={open}
              tabIndex={-1}
            >
              browse
            </span>
            &nbsp;
            <MoreInfoTooltip description={SAMPLE_FILE_UPLOAD_INFO} />
          </label>
        </div>
      )}
    </div>
  );
};

export default DataUploader;
