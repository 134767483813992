import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import useQueryParam from "../../hooks/useQueryParam";

import styles from "./Sidebar.module.scss";

interface SidebarNavLinkProps {
  to: string;
  pattern: string;
  children: React.ReactNode;
}

export const SidebarNavLinkWithVendor: React.FC<SidebarNavLinkProps> = ({
  to,
  pattern,
  children
}) => {
  const location = useLocation();
  const queryParams = useQueryParam();
  const queryVendorId = queryParams.get("vendorId") || null;

  const isActive = () => {
    const patternWithoutQuery = pattern.split("?")[0];
    const locationWithoutQuery = location.pathname.split("?")[0];

    return (
      locationWithoutQuery === patternWithoutQuery &&
      (pattern.includes("vendorId") ? queryVendorId !== null : true)
    );
  };

  return (
    <NavLink
      to={to}
      isActive={isActive}
      className={styles["list-item"]}
      activeClassName={styles["active-link"]}
    >
      {children}
    </NavLink>
  );
};

export const SidebarNavLink: React.FC<SidebarNavLinkProps> = ({
  to,
  pattern,
  children
}) => {
  const activeLink = (_match: any, location: any) => {
    if (pattern === "/publishers") {
      return location.pathname === pattern;
    }
    const urlMatchesPattern = location.pathname.includes(pattern);
    return urlMatchesPattern && !location.search.includes("vendorId");
  };

  return (
    <NavLink
      to={to}
      isActive={activeLink}
      className={styles["list-item"]}
      activeClassName={styles["active-link"]}
    >
      {children}
    </NavLink>
  );
};
