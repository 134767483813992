import React from "react";

import { Step } from "../wizard";

import CurrentPricingPlansSection from "./CurrentPricingPlansSection";

const PricingPlansStep = () => {
  return (
    <Step path="plans" name="Pricing Options" canAdvance={true}>
      <div data-testid="pricingPlansStep">
        <CurrentPricingPlansSection />
      </div>
    </Step>
  );
};

export default PricingPlansStep;
