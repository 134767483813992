import React from "react";
import { FlexGrid, FontAwesomeIcon } from "@nef/core";

import { SdgIndicator } from "../../../api/types";
import IMAGES from "../../sdg-indicators/images";

import styles from "./SdgIndicatorsInput.module.scss";

interface SdgIndicatorsInputProps {
  values: string[];
  sdgIndicators: SdgIndicator[];
  handleChange: (code: string) => void;
}

const SdgIndicatorsInput = ({
  values,
  sdgIndicators,
  handleChange
}: SdgIndicatorsInputProps) => {
  if (!sdgIndicators) {
    return null;
  }

  return (
    <FlexGrid data-testid="sdgIndicatorsInput">
      <FlexGrid.Row>
        <span className={styles["sdg-indicator-label"]}>SDG Indicators</span>
      </FlexGrid.Row>
      <FlexGrid.Row noGutters={true}>
        {sdgIndicators.map(({ code }) => {
          const optionClassNames = [styles.option];
          if (values?.includes(code)) {
            optionClassNames.push(styles["option-selected"]);
          }

          const isSelected = values?.includes(code) || false;

          return (
            <FlexGrid.Column xs={8} sm={6} md={4} key={`${code}`}>
              <div
                role="button"
                tabIndex={-1}
                className={optionClassNames.join(" ")}
                onClick={() => handleChange(code)}
                onKeyPress={() => handleChange(code)}
                data-testid={`sdgIndicatorsInput-${code}-${String(isSelected)}`}
              >
                <div className={styles["option-inner"]}>
                  <img src={IMAGES[code]} alt={code} />
                  <div className={styles["option-body"]}>
                    {isSelected ? (
                      <FontAwesomeIcon
                        iconSetClassName="far"
                        iconClassName="fa-check-square fa-lg"
                      />
                    ) : (
                      <FontAwesomeIcon
                        iconSetClassName="far"
                        iconClassName="fa-square fa-lg"
                      />
                    )}
                  </div>
                </div>
              </div>
            </FlexGrid.Column>
          );
        })}
      </FlexGrid.Row>
    </FlexGrid>
  );
};

export default SdgIndicatorsInput;
