import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import { useMutation } from "jsonapi-react";
import { useHistory } from "react-router-dom";

import manageClient from "../../api/manage/client";
import Toast from "../../components/Toast";
import useQueryParam from "../../hooks/useQueryParam";
import { UserGroup, VendorUser } from "../../api/types";

import styles from "./delete-user-from-group-modal.module.scss";

interface DeleteUserModalProps {
  isOpen: boolean;
  close: () => void;
  selectedUser: VendorUser | null;
  userGroup: UserGroup;
}

const DeleteUserFromGroupModal = ({
  isOpen,
  close,
  selectedUser,
  userGroup
}: DeleteUserModalProps) => {
  const history = useHistory();
  const queryParams = useQueryParam();
  const vendorId = queryParams?.get("vendorId") ?? "";

  const { vendorUsers, id, name } = userGroup;

  const [updatedUsers, setUpdatedUsers] = useState<VendorUser[] | null>(null);

  useEffect(() => {
    if (selectedUser) {
      const updatedVendorUsers = vendorUsers?.filter(
        user => user.id !== selectedUser?.id
      );

      setUpdatedUsers(updatedVendorUsers);
    }
  }, [selectedUser]);

  const [updateUserInUserGroups] = useMutation(["vendor-user-groups", id], {
    method: "PATCH",
    client: manageClient
  });

  const handleDeleteUserFromUserGroup = async () => {
    const response = await updateUserInUserGroups({
      id,
      "vendor-users": updatedUsers
    });

    const { data: responseData } = response;

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error deleting the user"
          details={[]}
        />
      );

      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully deleted"
        details={[]}
      />
    );

    if (responseData) {
      history.push(`/user-groups?vendorId=${vendorId}`);
    }

    handleClose();
  };

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="deleteUserFromUserGroupModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Delete User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Remove from User Group
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <p className={styles.description}>
              Are you sure you want to remove manager access for{" "}
              {selectedUser?.userName || selectedUser?.userInviteEmail} from{" "}
              {name}?
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              color="danger"
              onClick={handleDeleteUserFromUserGroup}
              data-testid="deleteUserFromUserGroupModal_submit"
            >
              Remove User
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="deleteUserFromUserGroupModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteUserFromGroupModal;
