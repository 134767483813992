import styled from "styled-components";
import { getColor } from "@nef/style-utils";

interface BaseFormLabelProps {
  required?: boolean;
}

const FormLabel = styled.label<BaseFormLabelProps>`
  :not(.form-check-label):not(.custom-control-label):not(.switch-label):not(.btn-outline-primary) {
    display: block;
    margin: 0 0 4px;
    color: ${getColor("gray", 600)};
    font-size: 0.9rem;
    font-weight: 500;
  }

  font-size: 18px;
  color: ${getColor("secondary", 500)};
  vertical-align: sub;

  ${props =>
    props.required &&
    `
    &:before {
      display: inline-block;
      content: "*";
      color: #b0008e;
      line-height: 0;
      vertical-align: sub;
      margin-right: 0.25rem;
      font-size: 18px;
    }
  `}
`;

export default FormLabel;
