import React from "react";
import { Box, FlexGrid, FormSelect } from "@nef/core";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import extractFormSelectOnChangeValue from "../../../utils/nef-utils";

const FullWidthBox = styled(Box)`
  width: 100%;
`;

enum PaymentScheduleOption {
  Monthly = "monthly",
  Quarterly = "quarterly",
  Yearly = "yearly"
}
type PaymentScheduleData = {
  key: PaymentScheduleOption;
  label: string;
  data: { interval: "month" | "year"; intervalCount: number };
};

const PAYMENT_SCHEDULE_DATA: PaymentScheduleData[] = [
  {
    key: PaymentScheduleOption.Monthly,
    label: "Monthly",
    data: { interval: "month", intervalCount: 1 }
  },
  {
    key: PaymentScheduleOption.Quarterly,
    label: "Quarterly",
    data: { interval: "month", intervalCount: 3 }
  },
  {
    key: PaymentScheduleOption.Yearly,
    label: "Yearly",
    data: { interval: "year", intervalCount: 1 }
  }
];
const PAYMENT_SCHEDULE_OPTIONS = PAYMENT_SCHEDULE_DATA.map(({ key, label }) => {
  return { value: key, label };
});

const PlanFormPaymentScheduleInput = () => {
  const { control, setValue } = useFormContext();
  const [interval, intervalCount] = useWatch({
    control,
    name: ["interval", "intervalCount"]
  });

  const setPaymentSchedule = (newInterval: string) => {
    const { data: matchedData } =
      PAYMENT_SCHEDULE_DATA.find(({ key }) => key === newInterval) ||
      PAYMENT_SCHEDULE_DATA[0];

    setValue("interval", matchedData.interval, { shouldDirty: true });
    setValue("intervalCount", matchedData.intervalCount, { shouldDirty: true });
  };

  const currentPaymentScheduleData =
    PAYMENT_SCHEDULE_DATA.find(({ data }) => {
      return data.interval === interval && data.intervalCount === intervalCount;
    }) || PAYMENT_SCHEDULE_DATA[0];

  return (
    <FlexGrid.Column md={5}>
      <FullWidthBox paddingVertical={4} paddingHorizontal={0}>
        <FormSelect
          id="paymentSchedule"
          name="paymentSchedule"
          label="Payment Schedule"
          options={PAYMENT_SCHEDULE_OPTIONS}
          value={PAYMENT_SCHEDULE_OPTIONS.find(
            o => o.value === currentPaymentScheduleData.key
          )}
          onChange={(formSelectOnChangeParam: any) => {
            const newInterval = extractFormSelectOnChangeValue(
              formSelectOnChangeParam
            );
            setPaymentSchedule(newInterval);
          }}
          isClearable={false}
          classNamePrefix="planPaymentSchedule"
        />
      </FullWidthBox>
    </FlexGrid.Column>
  );
};

export default PlanFormPaymentScheduleInput;
