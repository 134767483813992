import { Validation } from "./types";

import { COLUMN_FIELD_INDEX } from ".";

const isChecked = (value: string) => {
  return ["X", "x"].includes(value);
};

type FiltersObject = {
  filters: number[];
};

const FilterValidation: Validation<FiltersObject> = {
  extractData: (_, rows) => {
    const filters = rows
      .map((column, index) =>
        isChecked(column[COLUMN_FIELD_INDEX.FILTER]) ? index : -1
      )
      .filter(value => value !== -1);

    return {
      filters
    };
  },
  validate: data => {
    const { filters } = data;

    if (filters.length > 0) {
      return [];
    }

    return [
      {
        type: "filter-missing",
        title: "Filter field(s) not specified",
        message:
          "Apply to column E. At least one filter field should be specified."
      }
    ];
  }
};

export default FilterValidation;
