import React from "react";
import { Button } from "@nef/core";
import { useHistory } from "react-router-dom";

import useWizard from "../../../hooks/useWizard";
import CapSurveyFormBody from "../CapSurveyFormBody";

import styles from "./IntakeFormIntro.module.scss";

const INTRO_PART_1 = "Welcome to the Nasdaq Data Product Intake Form.";

const INTRO_PART_2 =
  "You can save your session any time by clicking the save button at the bottom of the page.";

const INTRO_PART_3 =
  "If you need assistance, please contact: datalinkonboarding@nasdaq.com";

const IntakeFormIntro = () => {
  const history = useHistory();
  const { nextPath } = useWizard();

  return (
    <CapSurveyFormBody>
      <p className={styles.intro} data-testid="capSurveyFormIntro">
        {INTRO_PART_1}
      </p>
      <p>{INTRO_PART_2}</p>
      <p>{INTRO_PART_3}</p>
      <Button
        onClick={() => history.push(nextPath)}
        data-testid="capSurveyFormIntro_start"
      >
        Start Now
      </Button>
    </CapSurveyFormBody>
  );
};

export default IntakeFormIntro;
