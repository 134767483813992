import React from "react";

import { Wizard, Step } from "../wizard";
import { DatatablesNameDescription } from "../../api/types";

import IntakeDatatableModalHeader from "./IntakeDatatableModalHeader";
import NonHostedDatatableWizardStep1 from "./NonHostedDatatableWizardStep1";

interface NonHostedDatatableWizardProps {
  datatable: DatatablesNameDescription;
  onDismiss: () => void;
  onDiscard: () => void;
  saveStep: (
    formValues: Partial<DatatablesNameDescription>,
    submit: boolean
  ) => void;
}

const NonHostedDatatableWizard = ({
  datatable,
  onDismiss,
  onDiscard,
  saveStep
}: NonHostedDatatableWizardProps) => {
  return (
    <Wizard stateNavigator={true} header={<IntakeDatatableModalHeader />}>
      <Step path="1" name="Non-Hosted Step 1" canAdvance={true}>
        <NonHostedDatatableWizardStep1
          datatable={datatable}
          save={saveStep}
          cancel={onDismiss}
          discard={onDiscard}
          fields={["name", "description", "schemaFileUrl"]}
        />
      </Step>
    </Wizard>
  );
};

export default NonHostedDatatableWizard;
