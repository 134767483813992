import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@nef/core";

import { formatData } from "../../../utils/table";
import { getTableData } from "../../../api/api";

import Table from "./table/Table";
import "./style.scss";

type Datatable = { code: string; name: string; graphable: {} };

type Props = {
  datatables: any[];
  productMetadata: any;
};

const DataVisualization: React.FC<Props> = ({
  datatables,
  productMetadata
}) => {
  const [selectedDatatableCode, setSelectedDatatableCode] = useState("");
  const [selectedDatatableTableData, setSelectedDatatableTableData] =
    useState<any>([]);

  const getDatatableTableData = async (code: string) => {
    const { data } = await getTableData(code, 7);

    setSelectedDatatableTableData(
      formatData(data.datatable.columns, data.datatable.data)
    );
  };

  const setSelectedDatatable = async (
    datatableCodeToSelect: string,
    datatablesToFind: Datatable[]
  ) => {
    const matchedDatatable = datatablesToFind?.find(
      datatable => datatable.code === datatableCodeToSelect
    );

    setSelectedDatatableCode(matchedDatatable?.code || "");

    if (matchedDatatable?.code) {
      await getDatatableTableData(matchedDatatable?.code);
    }
  };

  const handleDatatableChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newDatatableCode = event.target.value;
    setSelectedDatatable(newDatatableCode, datatables);
  };

  useEffect(() => {
    if (productMetadata?.code === undefined) {
      return;
    }
    setSelectedDatatable(productMetadata?.code, datatables);
  }, [datatables, productMetadata]);

  if (Object.keys(productMetadata).length === 0) {
    return <></>;
  }
  return (
    <section className="data-visualization__container">
      <div className="data-visualization__heading-box">
        {datatables.length > 0 && (
          <select
            onChange={handleDatatableChange}
            defaultValue={selectedDatatableCode}
            className="data-visualization__dropdown-menu"
          >
            {datatables.map((datatable: Datatable) => (
              <option key={datatable.code} value={datatable.code}>
                {datatable.name ?? datatable.code}
              </option>
            ))}
          </select>
        )}

        <button
          type="button"
          className="data-visualization__heading-button data-visualization__heading-button-disabled"
          disabled={true}
        >
          Download Sample
          <FontAwesomeIcon
            iconClassName="fa-chevron-down"
            iconSetClassName="fas"
            style={{ color: "#949494", marginLeft: "5px" }}
          />
        </button>
      </div>

      <Table data={selectedDatatableTableData} />
    </section>
  );
};

export default DataVisualization;
