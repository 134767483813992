import React from "react";
import { FormSelect } from "@nef/core";

import { StepProps } from "../../wizard/types";

interface SectionDropdownProps {
  steps: StepProps[];
  navigateTo: (path: string) => void;
  canAdvanceTo: (targetStep: StepProps) => boolean;
  currentStep: StepProps | undefined;
}

interface SectionDropdownOption {
  value: string;
  label: string;
  isDisabled: boolean;
  index: number;
}

const SectionDropdown = ({
  steps,
  navigateTo,
  canAdvanceTo,
  currentStep
}: SectionDropdownProps) => {
  const options = steps.reduce(
    (
      sections: { [sectionTitle: string]: SectionDropdownOption },
      step,
      index
    ) => {
      const { stepSectionTitle, path } = step;

      if (!stepSectionTitle) return sections;

      if (sections[stepSectionTitle]) return sections;

      // eslint-disable-next-line
      sections[stepSectionTitle] = {
        value: path,
        label: stepSectionTitle,
        isDisabled: !canAdvanceTo(step),
        index
      };

      return sections;
    },
    {}
  );

  const sortedOptions = Object.values(options).sort((step1, step2) => {
    return step1.index - step2.index;
  });

  return (
    <FormSelect
      id="sectionDropdown"
      value={
        sortedOptions.find(o => o.label === currentStep?.stepSectionTitle) ||
        null
      }
      placeholder=""
      options={sortedOptions}
      optional={false}
      isClearable={false}
      onChange={(formSelectOnChangeParam: any) => {
        navigateTo(formSelectOnChangeParam.value.value);
      }}
      classNamePrefix="sectionDropdown"
    />
  );
};

export default SectionDropdown;
