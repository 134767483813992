import React from "react";

import { Step } from "../wizard";

import GrandfatheredPricingPlansSection from "./GrandfatheredPricingPlansSection";

const GrandfatheredPricingPlansStep = () => {
  return (
    <Step
      path="plans/grandfathered"
      name="Grandfathered Pricing Plans"
      canAdvance={true}
    >
      <div data-testid="grandfatheredPricingPlansStep">
        <GrandfatheredPricingPlansSection />
      </div>
    </Step>
  );
};

export default GrandfatheredPricingPlansStep;
