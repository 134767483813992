import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormField, FormSelect } from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { toast } from "react-toastify";
import { IResult, useQuery } from "jsonapi-react";

import buildCapProductSchema from "../schema";
import useCapProductContext from "../../../hooks/useCapProductContext";
import {
  BaseProductCategory,
  CapProductToCreate,
  CapProductToEdit,
  ProductCategory
} from "../../../api/types";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import { BaseStepProps } from "../types";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import ProductCategoriesInput from "../../product-form/components/ProductCategoriesInput";
import { useDirty } from "../cap-product-util";
import webClient from "../../../api/web/client";

const CATEGORY_TO_SDG_INDICATOR = {
  "GOAL 1: No Poverty": "NO_POVERTY",
  "GOAL 2: Zero Hunger": "ZERO_HUNGER",
  "GOAL 3: Good Health and Well-being": "GOOD_HEALTH_WELLBEING",
  "GOAL 4: Quality Education": "QUALITY_EDUCATION",
  "GOAL 5: Gender Equality": "GENDER_EQUALITY",
  "GOAL 6: Clean Water and Sanitation": "CLEAN_WATER_SANITATION",
  "GOAL 7: Affordable and Clean Energy": "AFFORDABLE_CLEAN_ENERGY",
  "GOAL 8: Decent Work and Economic Growth": "DECENT_WORK_ECONOMIC_GROWTH",
  "GOAL 9: Industry, Innovation and Infrastructure":
    "INDUSTRY_INNOVATION_INFRASTRUCTURE",
  "GOAL 10: Reduced Inequality": "REDUCED_INEQUALITIES",
  "GOAL 11: Sustainable Cities and Communities":
    "SUSTAINABLE_CITIES_COMMUNITIES",
  "GOAL 12: Responsible Consumption and Production":
    "RESPONSIBLE_CONSUMPTION_PRODUCTION",
  "GOAL 13: Climate Action": "CLIMATE_ACTION",
  "GOAL 14: Life Below Water": "LIFE_BELOW_WATER",
  "GOAL 15: Life on Land": "LIFE_ON_LAND",
  "GOAL 16: Peace and Justice Strong Institutions":
    "PEACE_JUSTICE_INSTITUTIONS",
  "GOAL 17: Partnerships to Achieve the Goal": "PARTNERSHIPS_FOR_THE_GOALS"
};

type Category = keyof typeof CATEGORY_TO_SDG_INDICATOR;

const HISTORY_OPTIONS = [
  { value: "since", label: "Since" },
  { value: "rolling", label: "Rolling (no history, ongoing updates only)" },
  { value: "projected", label: "Projected" }
];

const IntakeFormStep2 = ({ fields, setFormIsDirty }: BaseStepProps) => {
  const { product: contextProduct, saveAllData } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;
  const { productType } = product;

  const productSchema = buildCapProductSchema();
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToEdit>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    data: coreProductCategories,
    isLoading: isLoadingCoreCategories,
    error: errorCoreCategories
  } = useQuery<ProductCategory[]>(
    [
      "product-categories",
      {
        filter: {
          product_types: "CORE",
          parent_id: "null"
        }
      }
    ],
    { client: webClient }
  );

  let esgProductCategoriesData: IResult<ProductCategory[]>;
  let adpProductCategoriesData: IResult<ProductCategory[]>;

  if (productType === "ESG" || productType === "ADP") {
    esgProductCategoriesData = useQuery<ProductCategory[]>(
      [
        "product-categories",
        {
          filter: {
            product_types: productType,
            parent_id: "null"
          }
        }
      ],
      { client: webClient }
    );

    adpProductCategoriesData = useQuery<ProductCategory[]>(
      [
        "product-categories",
        {
          filter: {
            product_types: productType,
            parent_id: "null"
          }
        }
      ],
      { client: webClient }
    );
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = form;
  const formHistoryValue = watch("history");
  useDirty(isDirty, setFormIsDirty);

  const handleHistoryOnChange = (
    onChange: (...event: any[]) => void,
    formSelectOnChangeParam: any
  ) => {
    const newValue = extractFormSelectOnChangeValue(formSelectOnChangeParam);
    onChange(newValue);

    if (newValue !== "since") {
      setValue("since", "", {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const getSelectedCategories = (category: BaseProductCategory) => {
    const currentCategories = getValues("productCategories");
    const newCategories = [...currentCategories];

    const categoryIndex = newCategories.findIndex(
      newCategory => newCategory.id === category.id
    );

    if (categoryIndex === -1) {
      newCategories.push(category);
    } else {
      newCategories.splice(categoryIndex, 1);
    }

    const globalIndex = newCategories.findIndex(v => v.name === "Global");
    const regionCategories = newCategories.filter(
      v => `${v.parentId}` === "25"
    );

    if (regionCategories.length <= 3 && globalIndex !== -1) {
      // remove the global if region has less than 3 options
      newCategories.splice(globalIndex, 1);
    } else if (regionCategories.length >= 3 && globalIndex === -1) {
      // add global option when the user select 3 or more options
      newCategories.push({ id: "35", name: "Global" });
    }
    return newCategories;
  };

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const presetSdgIndicators = () => {
    if (product.productType === "ESG") {
      const currentCategories = getValues("productCategories");
      const productCategories: string[] = currentCategories.map(
        (category: { name: string }) => category.name
      );
      const sdgValues = productCategories
        .map(
          (category: string) => CATEGORY_TO_SDG_INDICATOR[category as Category]
        )
        .filter(val => val !== undefined);
      setValue("productMetadatum.sdgIndicators", sdgValues);
    }
  };

  const onHandleSubmit: SubmitHandler<CapProductToEdit> = async formData => {
    if (formData.productType === "ESG") {
      presetSdgIndicators();
      await saveAllData({
        capProduct: formData,
        metadata: formData.productMetadatum
      });
    } else {
      await saveAllData({
        capProduct: formData
      });
    }
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection1Step2">
          <CapSurveyFormFieldRow tooltip={TOOLTIP.COVERAGE}>
            <Controller
              name="coverage"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="Coverage"
                  name={name}
                  type="text"
                  label="Coverage"
                  placeholder="Coverage"
                  value={value || ""}
                  onChange={onChange}
                  data-testid="capSurveyForm_coverage"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.HISTORY}>
            <>
              <Controller
                name="history"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormSelect
                    id="History"
                    name={name}
                    label="History"
                    value={HISTORY_OPTIONS.find(o => o.value === value) || null}
                    placeholder="History"
                    options={HISTORY_OPTIONS}
                    onChange={(formSelectOnChangeParam: any) => {
                      handleHistoryOnChange(onChange, formSelectOnChangeParam);
                    }}
                    classNamePrefix="history"
                  />
                )}
              />
              {formHistoryValue === "since" && (
                <Controller
                  name="since"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <FormField
                      id="Since"
                      name={name}
                      type="date"
                      label="Since"
                      placeholder="Since"
                      value={value}
                      onChange={onChange}
                      data-testid="capSurveyForm_since"
                    />
                  )}
                />
              )}
            </>
          </CapSurveyFormFieldRow>

          <Controller
            name="productCategories"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <ProductCategoriesInput
                  value={value}
                  handleChange={category =>
                    onChange(getSelectedCategories(category))
                  }
                  productType="CORE"
                  prodCategories={coreProductCategories}
                  productCategoriesError={errorCoreCategories}
                  isProductCategoriesLoading={isLoadingCoreCategories}
                />
                {productType === "ESG" && esgProductCategoriesData && (
                  <ProductCategoriesInput
                    value={value}
                    handleChange={category =>
                      onChange(getSelectedCategories(category))
                    }
                    productType="ESG"
                    prodCategories={esgProductCategoriesData.data}
                    productCategoriesError={esgProductCategoriesData.error}
                    isProductCategoriesLoading={
                      esgProductCategoriesData.isLoading
                    }
                  />
                )}
                {productType === "ADP" && adpProductCategoriesData && (
                  <ProductCategoriesInput
                    value={value}
                    handleChange={category =>
                      onChange(getSelectedCategories(category))
                    }
                    productType="ADP"
                    prodCategories={adpProductCategoriesData.data}
                    productCategoriesError={adpProductCategoriesData.error}
                    isProductCategoriesLoading={
                      adpProductCategoriesData.isLoading
                    }
                  />
                )}
              </>
            )}
          />
          {productType === "ESG" && (
            <CapSurveyFormFieldRow tooltip={TOOLTIP.FRAMEWORK}>
              <Controller
                name="productMetadatum.frameworkRegulationNotes"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormField
                    id="Framework"
                    optional
                    name={name}
                    type="text"
                    label="Frameworks or Regulations"
                    placeholder="Frameworks or Regulations"
                    value={value || ""}
                    onChange={onChange}
                    data-testid="capSurveyForm_framework"
                  />
                )}
              />
            </CapSurveyFormFieldRow>
          )}
          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormStep2;
