import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, CircleButton, FontAwesomeIcon } from "@nef/core";
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useQuery } from "jsonapi-react";
import ReactTooltip from "react-tooltip";

import { UserGroup, VendorUser } from "../../api/types";
import GroupPermissionsModal from "../../components/group-permissions/GroupPermissionsModal";

import DeleteUserFromGroupModal from "./delete-user-from-group-modal";
import DeleteUserGroupModal from "./delete-user-group-modal";
import AddUserToUserGroupModal from "./add-user-to-user-group-modal";
import styles from "./user-groups-table.module.scss";

export type SortDirection = "asc" | "desc";

export type ColumnSort = {
  id: string;
  desc: boolean;
};

export type SortingState = ColumnSort[];

export type SortingTableState = {
  sorting: SortingState;
};

const columnHelper = createColumnHelper<VendorUser>();

const UserActions = ({
  row,
  isAdmin,
  userGroup
}: {
  row: Row<VendorUser>;
  isAdmin: boolean;
  userGroup: UserGroup;
}) => {
  const {
    original: { id }
  } = row;

  const [
    isDeleteUserFromUserGroupModalOpen,
    setIsDeleteUserFromUserGroupModalOpen
  ] = useState(false);

  const handleDelete = () => {
    setIsDeleteUserFromUserGroupModalOpen(true);
  };

  if (!id) {
    return null;
  }

  return (
    <div className={styles["user-actions"]}>
      <DeleteUserFromGroupModal
        isOpen={isDeleteUserFromUserGroupModalOpen}
        close={() => setIsDeleteUserFromUserGroupModalOpen(false)}
        selectedUser={row.original}
        userGroup={userGroup}
      />
      <Link to={`/users/${id}?from=usergroups`}>
        <CircleButton
          outline
          className={styles["table-delete-button"]}
          data-testid="userGroupsTable_editUser"
        >
          <FontAwesomeIcon iconClassName="fa-edit" />
        </CircleButton>
      </Link>
      {isAdmin && (
        <CircleButton
          data-testid="userGroupsTable_deleteUser"
          outline
          color="danger"
          className={styles["table-delete-button"]}
          onClick={handleDelete}
        >
          <FontAwesomeIcon iconClassName="fa-user-minus" />
        </CircleButton>
      )}
    </div>
  );
};

interface UserGroupsTableProps {
  isAdmin: boolean;
  userGroup: UserGroup;
}

const UserGroupsTable = ({ isAdmin, userGroup }: UserGroupsTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "userName",
      desc: false
    }
  ]);
  const [isDeleteUserGroupModalOpen, setIsDeleteUserGroupModalOpen] =
    useState(false);
  const [isAddUserToUserGroupModalOpen, setIsAddUserToUserGroupModalOpen] =
    useState(false);
  const [isGroupPermissionsModalOpen, setIsGroupPermissionsModalOpen] =
    useState(false);

  const { vendorId } = userGroup;

  const { data: users, isLoading: isUsersLoading } = useQuery<VendorUser[]>([
    "vendor-users",
    {
      filter: {
        vendor_id: vendorId
      }
    }
  ]);

  const columns = [
    columnHelper.accessor("userName", {
      id: "userName",
      cell: info => (
        <div className={styles["left-align"]}>
          <Link className={styles.values} to={`/users/${info.row.original.id}`}>
            {info.getValue() || <em>Invite sent</em>}
          </Link>
        </div>
      ),
      header: () => {
        return <span>Name</span>;
      }
    }),
    columnHelper.accessor("userEmail", {
      id: "email",
      cell: info => info.getValue() || info.row.original.userInviteEmail,
      header: () => {
        return <span>Email</span>;
      }
    }),
    columnHelper.accessor("lastSignInAt", {
      id: "lastSignInAt",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(
          new Date(info.getValue() || Date.now())
        ),
      header: () => {
        return <span>Last Login</span>;
      }
    }),
    columnHelper.display({
      id: "userActions",
      cell: props => (
        <UserActions row={props.row} isAdmin={isAdmin} userGroup={userGroup} />
      )
    })
  ];
  const table = useReactTable({
    data: userGroup?.vendorUsers || [],
    columns,
    state: {
      sorting
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  const handleEditPermissions = () => {
    setIsGroupPermissionsModalOpen(true);
  };

  const handleAddUserToUserGroup = () => {
    setIsAddUserToUserGroupModalOpen(true);
  };

  const handleDeleteUserGroup = () => {
    setIsDeleteUserGroupModalOpen(true);
  };

  return (
    <section>
      <DeleteUserGroupModal
        isOpen={isDeleteUserGroupModalOpen}
        close={() => setIsDeleteUserGroupModalOpen(false)}
        userGroup={userGroup}
      />
      {isAddUserToUserGroupModalOpen && (
        <AddUserToUserGroupModal
          close={() => setIsAddUserToUserGroupModalOpen(false)}
          userGroup={userGroup}
          users={users as VendorUser[]}
          isLoading={isUsersLoading as boolean}
        />
      )}
      <GroupPermissionsModal
        isOpen={isGroupPermissionsModalOpen}
        close={() => setIsGroupPermissionsModalOpen(false)}
        vendorId={vendorId?.toString()}
        groupId={userGroup.id}
        name={userGroup.name}
      />
      <div className={styles.container}>
        {isAdmin && (
          <div className={styles.header}>
            <div className={styles["left-container"]}>
              <p className={styles["header-title"]}>{userGroup.name}</p>
              <p className={styles["left-text"]}>
                Total Number of Users:{" "}
                <span>{userGroup.totalNumberOfUsers}</span>
              </p>
              <p className={styles["left-text"]}>
                Created On:{" "}
                <span>
                  {new Intl.DateTimeFormat("en-US").format(
                    new Date(userGroup.createdAt) || Date.now()
                  )}
                </span>
              </p>
            </div>

            <div className={styles["left-container"]}>
              <button
                type="button"
                className={styles.delete}
                onClick={handleDeleteUserGroup}
                onKeyPress={handleDeleteUserGroup}
              >
                <span>Delete</span>
              </button>
              <Button
                color="light"
                size="sm"
                outline
                onClick={handleEditPermissions}
              >
                Edit Permissions
              </Button>
              <span
                data-tip="All Users Have Been Added to Group"
                data-tip-disable={
                  users?.length !== userGroup.vendorUsers.length
                }
              >
                <Button
                  size="sm"
                  onClick={handleAddUserToUserGroup}
                  disabled={users?.length === userGroup.vendorUsers.length}
                >
                  <FontAwesomeIcon iconClassName="fa-plus-circle" />
                  Add User
                </Button>
              </span>
              <ReactTooltip place="top" type="dark" effect="solid" />
            </div>
          </div>
        )}

        <div className={styles["table-container"]}>
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={
                          header.column.getIsSorted()
                            ? `${styles.selected} ${styles.label}`
                            : styles.label
                        }
                      >
                        {header.isPlaceholder ? null : (
                          <button
                            type="button"
                            {...{
                              className: `${
                                header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : ""
                              } ${
                                header.column.getIsSorted()
                                  ? `${styles["active-column"]}`
                                  : ""
                              } ${
                                header.column.id === "userName"
                                  ? styles["left-header"]
                                  : ""
                              }`,
                              onClick: header.column.getToggleSortingHandler()
                            }}
                            onKeyPress={header.column.getToggleSortingHandler}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <span>
                              {{
                                asc: (
                                  <FontAwesomeIcon
                                    iconClassName="fa-caret-up"
                                    className={styles["caret-icon"]}
                                  />
                                ),
                                desc: (
                                  <FontAwesomeIcon
                                    iconClassName="fa-caret-down"
                                    className={styles["caret-icon"]}
                                  />
                                )
                              }[header.column.getIsSorted() as string] ?? null}
                            </span>
                          </button>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default UserGroupsTable;
