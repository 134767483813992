import React, { useCallback, useEffect, useState } from "react";
import { Link, generatePath, useHistory } from "react-router-dom";
import {
  Button,
  FormField,
  FormRadioCheckboxButton,
  FormSelect,
  FormTextArea,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { QueryArg, useMutation, useQuery } from "jsonapi-react";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";

import manageClient from "../../api/manage/client";
import Toast from "../Toast";
import { CapProductMetadatum, Product, Vendor } from "../../api/types";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import FormFieldLabel from "../modals/components/FormFieldLabel";
import { PATHS } from "../../routes";

import {
  PRODUCT_MODAL_VENDOR_INFO,
  PRODUCT_MODAL_NAME_INFO,
  PRODUCT_MODAL_CODE_INFO,
  PRODUCT_MODAL_DESCRIPTION_INFO,
  PRODUCT_MODAL_ADMIN_CONTACT_INFO,
  PRODUCT_MODAL_DATA_TYPE_INFO,
  PRODUCT_MODAL_FOR_SALE_DISABLED_INFO
} from "./hints";
import SkipIntakeCheckbox from "./SkipIntakeCheckbox";
import styles from "./NewProductModal.module.scss";
import { NewProductRequest } from "./types";

const DATA_TYPE_OPTIONS = [
  { value: "CORE", label: "Core" },
  { value: "ADP", label: "ADP" },
  { value: "ESG", label: "ESG" },
  { value: "SKILL", label: "AI Skill" }
];

const productRequestSchema = yup.object({
  vendorId: yup.string().required("Publisher name is a required field"),
  name: yup.string().required("Product name is a required field"),
  code: yup
    .string()
    .required("Product code is a required field")
    .matches(/^[a-zA-Z0-9]*$/, "Product code must be alphanumeric")
    .min(4, "Product code must be 4 or 5 characters long")
    .max(5, "Product code must be 4 or 5 characters long"),
  description: yup.string().required("Product description is a required field"),
  administratorContactVendorUserId: yup
    .string()
    .required("Administrator contact is a required field"),
  productType: yup.string().required("Data type needs is a required field")
});

type Option = { value: string; label: string };

type AdminEmail = {
  id: string;
  userEmail: string;
  userInviteEmail: string;
};

interface NewProductModalProps {
  isOpen: boolean;
  hasLicences?: boolean;
  vendorId: string | null;
  close: () => void;
}

const NewProductModal = ({
  isOpen,
  hasLicences,
  vendorId,
  close
}: NewProductModalProps) => {
  const history = useHistory();
  const [submitSuccess, setSubmitSuccess] = useState<
    | {
        name: string;
        email: string;
        datatableCollectionId: string;
        skipIntake: boolean;
      }
    | undefined
  >();
  const [hasVendorLicences, setHasVendorLicences] = useState<boolean>();
  const form = useForm<NewProductRequest>({
    mode: "onChange",
    defaultValues: {
      vendorId: "",
      name: "",
      code: "",
      description: "",
      administratorContactVendorUserId: "",
      productType: "",
      internal: false,
      exclusive: true,
      forSale: false,
      premium: false,
      skipIntake: false
    },
    resolver: yupResolver(productRequestSchema)
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = form;
  const [createProduct] = useMutation<NewProductRequest>("products", {
    client: manageClient
  });

  const { data: vendors, isLoading: isVendorLoading } = useQuery<Vendor[]>([
    "vendors",
    { sort: "name", "fields[vendors]": "name,code,has-licences" }
  ]);

  const [validateData] = useMutation(["products", "validate"], {
    client: manageClient
  });

  const validateFieldUniquenessAPI = async (field: "code", value: string) => {
    try {
      productRequestSchema.validateSyncAt(field, { [field]: value });
    } catch (err) {
      return;
    }

    const { errors } = await validateData({
      code: value
    });

    const fieldHasBeenTaken = errors?.some(
      error =>
        error.title === "has already been taken" &&
        error.source.pointer.includes(field)
    );

    if (fieldHasBeenTaken) {
      setError(field, {
        type: "custom",
        message: `Product ${field} has already been taken`
      });
    }
  };

  const debounceUserInviteValidation = useCallback(
    debounce(validateFieldUniquenessAPI, 300),
    []
  );

  const vendorOptions = vendors?.map(vendor => {
    return {
      value: vendor.id,
      label: vendor.name
    };
  });

  const { data: administrators, isLoading: isAdministratorLoading } = useQuery<
    AdminEmail[]
  >([
    "vendor-users",
    {
      "filter[vendor_id]": watch("vendorId") || vendorId,
      "fields[vendor-users]": "user-name,user-email,user-invite-email,id"
    }
  ]);

  const [productId, setProductId] = useState<string>("");
  const query: QueryArg = productId && [
    "products",
    productId,
    {
      include: ["cap-product-metadatum"]
    }
  ];

  const { data: product } = useQuery<Product>(query);

  const [updateCapProductMetadatum] = useMutation<CapProductMetadatum>(
    ["cap-product-metadata", product?.capProductMetadatum.id],
    {
      method: "PATCH"
    }
  );

  useEffect(() => {
    if (product && product.skipIntake) {
      if (product.forSale) {
        const normalizedMetadatum = decamelizeKeys(
          product.capProductMetadatum,
          {
            separator: "-"
          }
        );

        updateCapProductMetadatum({
          ...normalizedMetadatum,
          "pricing-option": "Contact Sales"
        });
      }

      if (productId) {
        const path = generatePath(PATHS.EDIT_PRODUCT, {
          productId
        });
        history.push(path);
      }
    }
  }, [product]);

  const adminOptions: Option[] | undefined = administrators?.map(admin => {
    return {
      value: admin.id,
      label: admin.userEmail || admin.userInviteEmail
    };
  });

  useEffect(() => {
    if (vendors) {
      const selectedVendor = vendors.find(
        vendor => vendor.id === watch("vendorId")
      );

      setHasVendorLicences(selectedVendor?.hasLicences);
      setValue("forSale", false);
    }
  }, [vendors, watch("vendorId")]);

  useEffect(() => {
    if (vendors && getValues().vendorId === "") {
      setValue(
        "vendorId",
        vendors.find(vendor => vendor.id === vendorId)?.id ?? "0"
      );
    }
  }, [vendors]);

  const onSubmit: SubmitHandler<NewProductRequest> = useCallback(
    async rawData => {
      // Ensures `skipIntake` is overwritten properly for SKILL product.
      // This avoids the complex logic between `productType` and `skipIntake` in the UI
      const data = { ...rawData };
      if (data.productType === "SKILL") {
        data.skipIntake = false;
      }

      const normalizedProductRequest: any = decamelizeKeys(data, {
        separator: "-"
      });
      const response = await createProduct({
        ...normalizedProductRequest,
        premium: data.forSale
      });

      const { error, errors } = response;

      if (error || errors) {
        const responseErrors = error ? [error] : errors;

        if (!responseErrors) return;

        toast(
          <Toast
            type="error"
            title="There was an error creating the product"
            details={[]}
          />
        );

        return;
      }

      const admin = administrators?.find(
        o => o.id === data.administratorContactVendorUserId
      );

      // response.data should be defined here because there are no errors
      const responseData = response.data as NewProductRequest;

      setSubmitSuccess({
        name: responseData.name || "",
        email: admin?.userEmail || admin?.userInviteEmail || "",
        datatableCollectionId: responseData.id || "",
        skipIntake: responseData.skipIntake
      });

      setProductId(responseData?.id ?? "");
    },
    [administrators]
  );

  const handleClose = () => {
    close();
    reset();
    setSubmitSuccess(undefined);
  };

  return (
    <Modal isOpen={isOpen} data-testid="newProductModal">
      <ModalHeader
        title="New Product"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        New Product
      </ModalHeader>
      <ModalBody>
        {submitSuccess && !submitSuccess.skipIntake ? (
          <>
            <p className={styles["margin-zero"]}>
              {submitSuccess?.name} successfully created. An email has been sent
              to {submitSuccess?.email} with a link to the intake form.{" "}
            </p>
            <div className={styles.buttons}>
              <Link to={`/intake/${submitSuccess?.datatableCollectionId}`}>
                <Button
                  disabled={Object.keys(formErrors).length !== 0}
                  data-testid="newProductModal_continue"
                >
                  Continue to intake form
                </Button>
              </Link>
              <Button
                color="light"
                outline
                onClick={handleClose}
                data-testid="newProductModal_close"
              >
                Close
              </Button>
            </div>
          </>
        ) : (
          <FormProvider {...form}>
            {!vendorId && (
              <div style={{ marginTop: "-1rem" }}>
                <FormFieldLabel
                  title="Select Publisher"
                  tooltip={PRODUCT_MODAL_VENDOR_INFO}
                />
                <Controller
                  name="vendorId"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <FormSelect
                      id="VendorId"
                      name={name}
                      isLoading={isVendorLoading}
                      value={() =>
                        vendorOptions?.find(o => o.value === value) || vendorId
                      }
                      options={vendorOptions}
                      data-testid="newProductModal_vendorId"
                      onChange={formSelectOnChangeParam => {
                        onChange(
                          extractFormSelectOnChangeValue(
                            formSelectOnChangeParam
                          )
                        );
                      }}
                      invalid={!!formErrors.vendorId}
                      feedback={formErrors?.vendorId?.message}
                      onBlur={onBlur}
                      classNamePrefix="publisherSelect"
                    />
                  )}
                />
              </div>
            )}
            <div className={`${!vendorId ? "" : styles["remove-margin"]}`}>
              <FormFieldLabel
                title="Product Name"
                tooltip={PRODUCT_MODAL_NAME_INFO}
              />
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FormField
                    id="name"
                    name={name}
                    type="text"
                    value={value}
                    onChange={onChange}
                    data-testid="newProductModal_name"
                    invalid={!!formErrors.name}
                    feedback={formErrors?.name?.message}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
            <FormFieldLabel
              title="Product Code"
              tooltip={PRODUCT_MODAL_CODE_INFO}
            />
            <Controller
              name="code"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormField
                  id="Code"
                  name={name}
                  type="text"
                  placeholder="NDAQ"
                  value={value}
                  data-testid="newProductModal_code"
                  invalid={!!formErrors.code}
                  feedback={formErrors?.code?.message}
                  onBlur={onBlur}
                  onChange={event => {
                    debounceUserInviteValidation(
                      "code",
                      event.value?.toString() || ""
                    );
                    onChange(event);
                  }}
                  style={{ textTransform: "uppercase" }}
                />
              )}
            />
            <FormFieldLabel
              title="Product Description"
              tooltip={PRODUCT_MODAL_DESCRIPTION_INFO}
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormTextArea
                  id="Description"
                  name={name}
                  type="text"
                  value={value}
                  onChange={onChange}
                  data-testid="newProductModal_description"
                  invalid={!!formErrors.description}
                  feedback={formErrors?.description?.message}
                  onBlur={onBlur}
                  rows={3}
                  cols={175}
                  placeholder="Short description"
                />
              )}
            />
            <FormFieldLabel
              title="Administrator Contact"
              tooltip={PRODUCT_MODAL_ADMIN_CONTACT_INFO}
            />
            <Controller
              name="administratorContactVendorUserId"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormSelect
                  id="AdministratorContactEmail"
                  isLoading={isAdministratorLoading}
                  name={name}
                  value={adminOptions?.find(o => o.value === value) || null}
                  options={adminOptions}
                  data-testid="newProductModal_administratorContactId"
                  onChange={formSelectOnChangeParam => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  invalid={!!formErrors.administratorContactVendorUserId}
                  feedback={
                    formErrors?.administratorContactVendorUserId?.message
                  }
                  onBlur={onBlur}
                  classNamePrefix="administratorContactEmail"
                />
              )}
            />
            <FormFieldLabel
              title="Select Data Type"
              tooltip={PRODUCT_MODAL_DATA_TYPE_INFO}
            />
            <Controller
              name="productType"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormSelect
                  id="ProductType"
                  name={name}
                  value={
                    DATA_TYPE_OPTIONS?.find(o => o.value === value) || null
                  }
                  options={DATA_TYPE_OPTIONS}
                  data-testid="newProductModal_productType"
                  isClearable={false}
                  onChange={formSelectOnChangeParam => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  invalid={!!formErrors.productType}
                  feedback={formErrors?.productType?.message}
                  onBlur={onBlur}
                  classNamePrefix="productType"
                />
              )}
            />

            <div className={styles.checkboxes}>
              <div>
                <Controller
                  name="internal"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <FormRadioCheckboxButton
                      id="InternalCatalogue"
                      name={name}
                      type="checkbox"
                      label="Internal Catalogue"
                      value={value}
                      onChange={onChange}
                      data-testid="newProductModal_internalCatalogue"
                    />
                  )}
                />
              </div>
              <div>
                <span
                  data-tip={PRODUCT_MODAL_FOR_SALE_DISABLED_INFO}
                  data-tip-disable={hasLicences || hasVendorLicences}
                >
                  <Controller
                    name="forSale"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormRadioCheckboxButton
                        id="ForSale"
                        name={name}
                        type="checkbox"
                        label="For-Sale"
                        value={value}
                        checked={value}
                        onChange={onChange}
                        disabled={!hasLicences && !hasVendorLicences}
                        data-testid="newProductModal_forSale"
                      />
                    )}
                  />
                </span>
                <ReactTooltip place="top" type="dark" effect="solid" />
              </div>
              <div>
                <SkipIntakeCheckbox />
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={
                  !isDirty || !isValid || Object.keys(formErrors).length !== 0
                }
                data-testid="newProductModal_submit"
              >
                Submit
              </Button>
              <Button
                color="light"
                outline
                onClick={handleClose}
                data-testid="newProductModal_cancel"
              >
                Cancel
              </Button>
            </div>
          </FormProvider>
        )}
      </ModalBody>
    </Modal>
  );
};

export default NewProductModal;
