import React, { useState } from "react";
import {
  FormSelect,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button
} from "@nef/core";
import { Option } from "@nef/core/lib/components/components/FormBaseSelect";

import { EventActionMulti } from "../../types";

import styles from "./index.module.scss";

interface Props {
  datatableKeys: string[];
  onSubmit: (selectedDatatableKeys: string[]) => void;
  onClose: () => void;
}

const AddDatatable = ({ datatableKeys, onClose, onSubmit }: Props) => {
  const [selectedDatatableKeys, setSelectedDatatableKeys] = useState<string[]>(
    []
  );

  const options: Option[] = datatableKeys.map(datatableKey => {
    return {
      label: datatableKey,
      value: datatableKey
    };
  });

  const handleOnChange = (event: EventActionMulti) => {
    setSelectedDatatableKeys(event.value.map(item => item.value));
  };

  return (
    <Modal isOpen data-testid="addDatatableModal">
      <ModalHeader toggle={() => onClose()}>Select Datatable</ModalHeader>
      <ModalBody>
        <FormSelect
          placeholder="Select..."
          isMulti
          isClearable
          options={options}
          onChange={e => {
            handleOnChange(e as unknown as EventActionMulti);
          }}
          classNamePrefix="modalFormSelect"
        />
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button
          size="md"
          className="danger"
          color="danger"
          onClick={onClose}
          data-testid="confirmationModal_dismiss"
        >
          Cancel
        </Button>

        <Button
          size="md"
          onClick={() => onSubmit(selectedDatatableKeys)}
          data-testid="confirmationModal_confirm"
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddDatatable;
