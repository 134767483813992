import React, { useCallback, useEffect, useState } from "react";
import {
  FormFieldWithIcon,
  Pagination,
  Button,
  FontAwesomeIcon
} from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { useQuery } from "jsonapi-react";
import { Redirect, useHistory } from "react-router-dom";

import useQueryParam from "../../hooks/useQueryParam";
import { UserSortParam } from "../../api/types";
import NewUserModal from "../../components/new-user/NewUserModal";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import UsersTable, { User } from "./users-table";
import styles from "./user.module.scss";

const SORT_PARAM_TO_REQUEST_PARAM = {
  name: "user.first_name,user.last_name",
  "-name": "-user.first_name,-user.last_name",
  email: "email",
  "-email": "-email",
  last_login: "user.last_sign_in_at",
  "-last_login": "-user.last_sign_in_at",
  date_joined: "date_joined",
  "-date_joined": "-date_joined"
};

interface RequestBody {
  filter: {
    vendor_id?: string; // eslint-disable-line camelcase
    search_by_name_or_email?: string; // eslint-disable-line camelcase
  };
  page: {
    number: number;
  };
  sort: string;
}

type SortParam = keyof typeof SORT_PARAM_TO_REQUEST_PARAM;

const isProductSortParam = (query: string): query is UserSortParam => {
  return Object.keys(SORT_PARAM_TO_REQUEST_PARAM).includes(query);
};

const UsersPage = () => {
  const history = useHistory();
  const queryParams = useQueryParam();
  const { isManagerAdmin, isPublisherUser, isAdminableVendor } =
    useDefaultAuth();

  const query = queryParams.get("search") ?? "";
  const [searchQuery, setSearchQuery] = useState(query);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const vendorId = queryParams?.get("vendorId") ?? "";

  const page = parseInt(queryParams.get("page") || "1", 10);

  const rawSortParam = useQueryParam().get("sort") || "";
  const sortParam = isProductSortParam(rawSortParam) ? rawSortParam : "name";

  const generateRequestBody = () => {
    const filter: { [key: string]: string | number } = {
      vendor_id: vendorId,
      search_by_name_or_email: query
    };

    Object.keys(filter).forEach(key => {
      if (!filter[key]) {
        delete filter[key];
      }
    });

    const requestBody: RequestBody = {
      filter,
      page: {
        number: page
      },
      sort: SORT_PARAM_TO_REQUEST_PARAM[sortParam as SortParam]
    };

    return requestBody;
  };

  const {
    data: users,
    links,
    isLoading
  } = useQuery<User[]>(["vendor-users", generateRequestBody()]);

  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (!isLoading) {
      setTotalPages(extractPageNumber(links?.last));
    }
  }, [isLoading]);

  const extractPageNumber = (url?: string): number => {
    if (!url) {
      return 1;
    }

    const urlObject = new URL(url);
    const pageNumber = urlObject.searchParams.get("page[number]");

    return pageNumber ? parseInt(pageNumber, 10) : 1;
  };

  const handleSearch = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchQuery(enteredQuery);
  };

  const handleAction = () => {
    loadPageWithParams({
      search: searchQuery,
      vendorId,
      page: 1,
      sort: sortParam
    });
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAction();
    }
  };

  const setSortParam = useCallback(
    (sort: string) => {
      loadPageWithParams({
        search: searchQuery,
        vendorId,
        page,
        sort
      });
    },
    [searchQuery]
  );

  const setPageParam = useCallback(
    (pageNum: number) => {
      loadPageWithParams({
        search: searchQuery,
        vendorId,
        page: pageNum,
        sort: sortParam
      });
    },
    [page, searchQuery, sortParam]
  );

  const loadPageWithParams = (params: {
    search: string;
    vendorId: string;
    page: number;
    sort: string;
  }) => {
    const queryString = Object.entries(params).reduce((url, param) => {
      const [key, value] = param;
      if (!value) return url;

      return `${url}${url ? "&" : "?"}${key}=${value}`;
    }, "");

    history.push({
      search: queryString
    });
  };

  const onPageChange = (num: number) => {
    setPageParam(num + 1);
  };

  if (
    !isManagerAdmin() &&
    !(isPublisherUser() && isAdminableVendor(vendorId))
  ) {
    return <Redirect to="/" />;
  }

  const nextPage = () => {
    if (page < totalPages) {
      setPageParam(page + 1);
    }
  };

  const previousPage = () => {
    if (page !== 1) {
      setPageParam(page - 1);
    }
  };

  return (
    <main className={styles.container}>
      <section className={styles["user-section"]}>
        <div className={styles.user}>
          <h1 className={styles.heading}>Users</h1>
          <div className={styles["buttons-container"]}>
            <FormFieldWithIcon
              placeholder="Search"
              iconClassName="fa-search"
              addonType="append"
              value={searchQuery}
              onChange={handleSearch}
              onIconClick={handleAction}
              onKeyDown={handleSubmit}
            />
            <Button
              onClick={() => setIsNewUserModalOpen(true)}
              data-testid="new-user-button"
            >
              <FontAwesomeIcon
                iconClassName="fa-plus-circle"
                className={styles["circle-icon"]}
              />
              New User
            </Button>
          </div>
          {isNewUserModalOpen && (
            <NewUserModal
              isOpen={isNewUserModalOpen}
              vendorId={vendorId}
              close={() => setIsNewUserModalOpen(false)}
            />
          )}
        </div>
        <UsersTable
          users={users}
          sortParam={sortParam}
          setSortParam={setSortParam}
        />
        <Pagination
          page={page - 1}
          pages={totalPages}
          pageSize={0}
          pageSizeOptions={[]}
          onPageChange={onPageChange}
          onPageSizeChange={() => null}
          onPageUpdate={() => null}
          nextPage={nextPage}
          previousPage={previousPage}
          showPageSizeDropdown={false}
          showPageDataCount={false}
          totalDataLength={0}
        />
      </section>
    </main>
  );
};

export default UsersPage;
