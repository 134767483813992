import React from "react";
import { Modal, ModalBody, ModalHeader } from "@nef/core";
import { useDropzone, FileRejection } from "react-dropzone";
import { toast } from "react-toastify";
import { isValid, parseISO } from "date-fns";

import CSV_FILE_ICON from "../../../assets/csv-file.svg";
import MoreInfoTooltip from "../../MoreInfoTooltip";
import Toast from "../../Toast";
import CsvReader, { ParseResult } from "../../../lib/csv-reader";
import { AccumulatedColumnStats } from "../../../api/types";
import { SAMPLE_FILE_UPLOAD_INFO } from "../hints";

import styles from "./AutoSchemaPopulateModal.module.scss";

interface AutoSchemaPopulateModalProps {
  isOpen: boolean;
  close: () => void;
  onProcessed: (accumulated: AccumulatedColumnStats) => void;
}

const MAX_FILE_SIZE = 1 * 1024 * 1024;

const ERROR_CODE_TO_MESSAGES: { [key: string]: string } = {
  "file-invalid-type": "Data sample must be a .csv file",
  "file-too-large": "Data sample must be smaller than 1MB"
};

const mapErrorCodeToMessage = (code: string) => {
  return ERROR_CODE_TO_MESSAGES[code] || "";
};

const AutoSchemaPopulateModal = ({
  isOpen,
  close,
  onProcessed
}: AutoSchemaPopulateModalProps) => {
  const evaulateColumnType = (value: string) => {
    const sanitizedValue = value.trim();

    if (!Number.isNaN(Number(sanitizedValue))) {
      const numberParts = sanitizedValue.split(".");

      // whole = precision - scale. Tracking number of whole number and decimal digits separately
      // in order to calculate the final preicision that applies to all instances in the same column
      const whole = numberParts[0] || "";
      const decimal = numberParts[1] || "";

      return { type: "number", whole: whole.length, scale: decimal.length };
    }

    if (isValid(parseISO(sanitizedValue))) {
      return { type: "date" };
    }

    return { type: "string" };
  };

  /* eslint-disable no-param-reassign */
  const aggregateData = (
    accumulated: AccumulatedColumnStats,
    chunk: ParseResult
  ) => {
    chunk.header?.forEach((headerName, columnIndex) => {
      accumulated[columnIndex] = accumulated[columnIndex] || {
        types: {},
        header: headerName
      };
    });

    chunk.lines.forEach(values => {
      values.forEach((value, columnIndex) => {
        accumulated[columnIndex] = accumulated[columnIndex] || {
          types: {},
          header: ""
        };
        const typeObject = evaulateColumnType(value);
        const { type } = typeObject;

        accumulated[columnIndex].types[type] = accumulated[columnIndex].types[
          type
        ] || {
          type,
          count: 0
        };

        accumulated[columnIndex].types[type].count++;

        if (type === "number") {
          const maxWhole = accumulated[columnIndex].types[type].whole || 0;
          const maxScale = accumulated[columnIndex].types[type].scale || 0;
          accumulated[columnIndex].types[type].whole = Math.max(
            maxWhole,
            typeObject.whole || 0
          );
          accumulated[columnIndex].types[type].scale = Math.max(
            maxScale,
            typeObject.scale || 0
          );
        }
      });
    });
  };
  /* eslint-enable no-param-reassign */

  const onDropAccepted = async (acceptedFiles: File[]) => {
    try {
      const file = acceptedFiles[0];

      const reader = new CsvReader();
      reader.load(file, { header: true });

      const accumulated: AccumulatedColumnStats = {};
      let chunk: false | ParseResult = false;
      do {
        // Disabling `no-await-in-loop` check here because it is an acceptable exception according to
        // https://eslint.org/docs/latest/rules/no-await-in-loop (see When Not To Use It section)
        // eslint-disable-next-line no-await-in-loop
        chunk = await reader.parse();
        if (chunk) {
          aggregateData(accumulated, chunk);
        }
      } while (chunk && chunk.lines.length);

      onProcessed(accumulated);
    } catch (e) {
      toast(
        <Toast
          type="error"
          title="File upload failed"
          details={[
            {
              message:
                "Your csv file cannot be processed to auto-generate datatable schema."
            }
          ]}
        />
      );
    }
  };
  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errorDetails = fileRejections
      .map(({ errors }) => {
        return errors.map(error => {
          return { message: mapErrorCodeToMessage(error.code) };
        });
      })
      .flat();

    toast(
      <Toast type="error" title="File upload failed" details={errorDetails} />
    );
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    accept: {
      "text/csv": [".csv"]
    },
    maxSize: MAX_FILE_SIZE
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      size="lg"
      data-testid="autoSchemaPopulateModal"
      closeOnOutsideClick={false}
    >
      <ModalHeader toggle={close} />
      <ModalBody>
        <div
          className={`${styles.dropzone} ${
            isDragActive && styles["dropzone-active"]
          }`}
          {...getRootProps({
            maxfiles: 1,
            multiple: false
          })}
          data-testid="autoSchemaPopulateModal_dropzone"
        >
          <label htmlFor="upload-file">
            <input
              {...getInputProps()}
              id="upload-file"
              data-testid="autoSchemaPopulateModal_upload"
            />
            <img
              src={CSV_FILE_ICON}
              className={styles["file-icon"]}
              alt="CSV file icon"
            />
            Drag and drop to upload or&nbsp;
            <span
              role="button"
              className={styles.browse}
              onClick={open}
              onKeyPress={open}
              tabIndex={-1}
            >
              browse
            </span>
            &nbsp;
            <MoreInfoTooltip description={SAMPLE_FILE_UPLOAD_INFO} />
          </label>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AutoSchemaPopulateModal;
