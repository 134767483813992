import React, { useState } from "react";
import { Header } from "@nef/core";

import useWizard from "../../hooks/useWizard";
import useCapProductContext from "../../hooks/useCapProductContext";
import { ConfirmationModal } from "../modals/ConfirmationModal";

import styles from "./CapSurveyFormHeader.module.scss";
import SectionDropdown from "./components/SectionDropdown";

const INTRO_STEP_LENGTH = 1;

interface CapSurveyFormHeaderProps {
  isDirty: boolean;
}

interface OnPreviousProps {
  targetStep: string;
  isPreviousStep: boolean;
}

const CapSurveyFormHeader = ({ isDirty }: CapSurveyFormHeaderProps) => {
  const { baseSteps, currentStepIndex, canAdvanceTo, navigateTo, steps } =
    useWizard();
  const { product } = useCapProductContext();

  const progresses = baseSteps.slice(INTRO_STEP_LENGTH);
  const progressIndex = currentStepIndex + 1 - INTRO_STEP_LENGTH;
  const isIntroStep = currentStepIndex < INTRO_STEP_LENGTH;
  const isOutroStep = currentStepIndex === steps.length - 1;

  const showProgressBar = !isIntroStep && !isOutroStep;

  const currentStep = steps[progressIndex];
  const stepSectionTitle = currentStep?.stepSectionTitle;
  const [discardState, setDiscardState] = useState(false);
  const [stepForModalRedirect, setStepForModalRedirect] = useState("");

  const onPrevious = ({ targetStep, isPreviousStep }: OnPreviousProps) => {
    if (isPreviousStep && isDirty) {
      setStepForModalRedirect(targetStep);
      setDiscardState(true);
    } else {
      navigateTo(targetStep);
    }
  };

  return (
    <div className={styles.header} data-testid="capSurveyFormHeader">
      <div className={styles["header-row"]}>
        <div className={styles["product-name"]}>
          {!isIntroStep && (
            <Header size={3}>{product.name || "[Product Name]"}</Header>
          )}
        </div>
        <div className={styles["section-dropdown"]}>
          <SectionDropdown
            steps={progresses}
            currentStep={currentStep}
            navigateTo={navigateTo}
            canAdvanceTo={canAdvanceTo}
          />
        </div>
      </div>

      <div className={styles["header-row"]}>
        <div className={styles.title} data-testid="capSurveyFormHeader_title">
          <Header size={1}>{stepSectionTitle}</Header>
        </div>
        {showProgressBar && (
          <div
            className={styles.counter}
            data-testid="capSurveyFormHeader_counter"
          >
            <Header size={3}>
              {progressIndex} / {progresses.length}
            </Header>
          </div>
        )}
      </div>

      <div className={styles["header-row"]}>
        {showProgressBar && (
          <div
            className={styles["progress-bar"]}
            data-testid="capSurveyFormHeader_progressBar"
          >
            {progresses.map((step, stepIndex) => {
              const classNames = [styles.progress];
              const isPreviousStep = stepIndex < currentStepIndex;
              if (isPreviousStep) {
                classNames.push(styles["progress--running"]);
              }

              return (
                <button
                  key={step.path}
                  type="button"
                  className={classNames.join(" ")}
                  onClick={() =>
                    onPrevious({ targetStep: step.path, isPreviousStep })
                  }
                  disabled={!canAdvanceTo(step)}
                  data-testid={`capSurveyFormHeader_progress_${step.path}`}
                >
                  &nbsp;
                </button>
              );
            })}
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={discardState}
        title="Unsaved changes"
        question="There are unsaved changes. Please click cancel, then Save Session at the bottom of the page or they will be discarded."
        confirmText="confirm"
        dismissText="cancel"
        onConfirm={() => {
          setDiscardState(false);
          navigateTo(stepForModalRedirect);
        }}
        onDismiss={() => {
          setDiscardState(false);
        }}
      />
    </div>
  );
};

export default CapSurveyFormHeader;
