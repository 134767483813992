import React, { useState } from "react";

import styles from "./Accordian.module.scss";
import DefaultIcon from "./icons/DefaultIcon";
import CloseIcon from "./icons/CloseIcon";
import HoverIcon from "./icons/HoverIcon";

type AccordianProps = {
  title: string;
  children: JSX.Element;
  testId: string;
};

const AccordianIcon = ({
  isOpen,
  isHover
}: {
  isOpen: boolean;
  isHover: boolean;
}) => {
  if (isHover && !isOpen) {
    return (
      <div className="circle-icon-blue">
        <HoverIcon />
      </div>
    );
  }
  if (isOpen) {
    return (
      <div className="circle-icon-blue">
        <CloseIcon />
      </div>
    );
  }
  return <DefaultIcon />;
};

const AccordianTab = ({ title, testId, children }: AccordianProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  return (
    <div className={styles["accordian-container"]}>
      <div className={styles["accordian-tab"]} data-testid={testId}>
        {title}
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          data-testid={`${testId}_button`}
        >
          <AccordianIcon isOpen={isOpen} isHover={hover} />
        </button>
      </div>
      {isOpen && (
        <section className={styles["accordian-content"]}> {children} </section>
      )}
    </div>
  );
};

export default AccordianTab;
