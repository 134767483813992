import React, { useEffect, useState } from "react";
import { Button } from "@nef/core";

import useWizard from "../../../hooks/useWizard";

import styles from "./new-skill-modal-footer.module.scss";

interface AiSkillModalFooterProps {
  save: (shouldAdvance?: boolean) => Promise<void>;
  cancel: () => void;
  canAdvance?: boolean;
  onPreviousStep?: (formState: any) => void;
  formState?: any;
}

const AiSkillModalFooter = ({
  save,
  cancel,
  canAdvance,
  onPreviousStep,
  formState
}: AiSkillModalFooterProps) => {
  const { nextPath, previousPath, navigateTo, steps } = useWizard();
  const [shouldAdvance, setShouldAdvance] = useState(false);

  const onPrevious = () => {
    setShouldAdvance(false);
    if (onPreviousStep) {
      onPreviousStep(formState);
    }
    navigateTo(previousPath);
  };

  const onNext = () => {
    setShouldAdvance(true);
    save(true);
  };

  useEffect(() => {
    if (shouldAdvance && canAdvance) {
      navigateTo(nextPath);
    }
  }, [shouldAdvance, canAdvance, nextPath]);

  return (
    <div className={styles.footer}>
      <div className={styles.group}>
        {steps.length > 1 && (
          <Button onClick={onPrevious} disabled={!previousPath} outline={true}>
            Previous
          </Button>
        )}
        <Button onClick={onNext} data-testid="footer_next">
          {nextPath ? "Next" : "Submit"}
        </Button>
        <Button onClick={cancel} outline={true}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AiSkillModalFooter;
