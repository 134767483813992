import React, { useState } from "react";
import { Button } from "@nef/core";
import { IResult, useMutation } from "jsonapi-react";
import { toast } from "react-toastify";

import { Plan, PlanCategory } from "../../../api/types";
import Toast from "../../Toast";

import ReactivateConfirmationModal from "./ReactivateConfirmationModal";

interface ReactivatePlanButton {
  plan: Plan;
  planCategory: PlanCategory;
}

const ReactivatePlanButton = ({ plan, planCategory }: ReactivatePlanButton) => {
  const [isReactivating, setReactivating] = useState(false);
  const [reactivatePlan] = useMutation<Plan>(["plans", plan.id, "reactivate"], {
    method: "PATCH"
  });

  if (plan.active) {
    return null;
  }

  const onConfirm = async () => {
    const response = await reactivatePlan({});

    try {
      handleResponseErrors(response);

      toast(
        <Toast
          type="success"
          title="Pricing plan reactivated"
          details={[
            {
              message: "The pricing plan has been reactivated"
            }
          ]}
        />
      );
    } catch (errorDetails: any) {
      toast(
        <Toast
          type="error"
          title="The pricing plan cannot be deactivated"
          details={errorDetails}
        />
      );
    } finally {
      setReactivating(false);
    }
  };

  const handleResponseErrors = (response: IResult) => {
    const { error, errors } = response;
    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      const formattedErrors = responseErrors.map(e => ({
        message: e.detail
      }));

      throw formattedErrors;
    }
  };

  return (
    <>
      <Button
        outline={true}
        onClick={() => setReactivating(true)}
        data-testid="reactivatePlanButton"
      >
        Reactivate
      </Button>
      {isReactivating && (
        <ReactivateConfirmationModal
          plan={plan}
          planCategory={planCategory}
          onConfirm={() => onConfirm()}
          onDismiss={() => setReactivating(false)}
        />
      )}
    </>
  );
};

export default ReactivatePlanButton;
