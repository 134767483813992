export const { PUBLIC_URL } = process.env;
export const APP_ROOT = process.env.REACT_APP_APP_ROOT;
export const SAMPLE_VARIABLE = process.env.REACT_APP_SAMPLE_VARIABLE;
export const FAQ_URL = process.env.REACT_APP_FAQ_LINK;
export const SAML_STRATEGY_ENABLED = Boolean(
  process.env.REACT_APP_SAML_STRATEGY_ENABLED
);
export const SQL_WRITE_ENABLED = Boolean(
  process.env.REACT_APP_SQL_WRITE_ENABLED
);
