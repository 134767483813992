import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@nef/core";
import ReactTooltip from "react-tooltip";
import { nanoid } from "nanoid";

import styles from "./MoreInfoTooltip.module.scss";

interface MoreInfoTooltipProps {
  description: string;
  icon?: "info" | "question";
}

const MoreInfoTooltip = ({
  description,
  icon = "question"
}: MoreInfoTooltipProps) => {
  const [internalId, setInternalId] = useState("");

  useEffect(() => {
    if (!internalId) {
      setInternalId(nanoid());
    }
  }, []);

  return (
    <>
      {icon === "info" ? (
        <FontAwesomeIcon
          iconClassName="fa-info-circle"
          className={styles.icon}
          data-testid="moreInfoTooltip_icon"
          data-tip={description}
          data-for={internalId}
        />
      ) : (
        <FontAwesomeIcon
          iconClassName="fa-question-circle"
          className={styles.icon}
          data-testid="moreInfoTooltip_icon"
          data-tip={description}
          data-for={internalId}
        />
      )}
      {internalId && (
        <ReactTooltip
          id={internalId}
          className={styles["tool-tip"]}
          delayHide={500}
          effect="solid"
          html={true}
        />
      )}
    </>
  );
};

export default MoreInfoTooltip;
