import React, { useEffect, useState } from "react";
import { Button, FormSelect, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import { useMutation } from "jsonapi-react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Toast from "../../components/Toast";
import { UserGroup, VendorUser } from "../../api/types";
import manageClient from "../../api/manage/client";
import FormFieldLabel from "../../components/modals/components/FormFieldLabel";

import styles from "./add-user-to-user-group-modal.module.scss";

interface AddUserToUserGroupModalProps {
  close: () => void;
  userGroup: UserGroup;
  users: VendorUser[];
  isLoading: boolean;
}

const AddUserToUserGroupModal = ({
  close,
  userGroup,
  users,
  isLoading
}: AddUserToUserGroupModalProps) => {
  const { id, vendorUsers, name: userGroupName } = userGroup;

  const [selectedUser, setSelectedUser] = useState<{
    label: string;
    value: string;
  } | null>({
    label: "",
    value: ""
  });
  const [updatedUsers, setUpdatedUsers] = useState<VendorUser[] | null>(null);

  useEffect(() => {
    if (selectedUser) {
      const user = users?.find(u => u?.id === selectedUser?.value);
      setUpdatedUsers([...vendorUsers, user] as VendorUser[]);
    }
  }, [selectedUser]);

  const [updateUserInUserGroups] = useMutation(["vendor-user-groups", id], {
    method: "PATCH",
    client: manageClient
  });

  const filteredUsers = users?.filter(
    user => !vendorUsers.some(vendorUser => vendorUser.id === user.id)
  );

  const addUserGroupRequestSchema = yup.object({
    user: yup
      .object({
        value: yup.string().required(),
        label: yup.string().required()
      })
      .required("Select user is a required field")
  });

  const {
    control,
    formState: { errors: formErrors },
    reset
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      user: {
        label: "",
        value: ""
      }
    },
    resolver: yupResolver(addUserGroupRequestSchema)
  });

  const userOptions = filteredUsers?.map(user => {
    return {
      value: user.id,
      label: user.userName
        ? `${user.userName} <${user.userEmail}>`
        : user.userInviteEmail
    };
  });

  const handleAddUserToUserGroupModal = async () => {
    const response = await updateUserInUserGroups({
      id,
      "vendor-users": updatedUsers
    });

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error adding the user"
          details={[]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully added"
        details={[]}
      />
    );

    handleClose();
  };

  const handleClose = () => {
    setSelectedUser({
      label: "",
      value: ""
    });
    setUpdatedUsers(null);
    reset();
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="AddUserToUserGroupModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Add User to User Group"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Add User to {userGroupName}
      </ModalHeader>
      <ModalBody>
        <div className={styles["remove-margin"]}>
          <FormFieldLabel title="Select User" tooltip="Please select a user" />
        </div>
        <Controller
          name="user"
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <FormSelect
              id="VendorId"
              name={name}
              isLoading={isLoading}
              options={userOptions}
              value={value}
              data-testid="addUsertoUserGroupModal_vendorId"
              onChange={(e: any) => {
                setSelectedUser(e.value);
                onChange(e.value);
              }}
              invalid={!!formErrors.user}
              feedback="Select User is a required field"
              onBlur={onBlur}
              classNamePrefix="modalFormSelect"
            />
          )}
        />

        <div className={styles.buttons}>
          <Button
            onClick={handleAddUserToUserGroupModal}
            disabled={
              Object.keys(formErrors).length !== 0 || selectedUser?.value === ""
            }
            data-testid="AddUserToUserGroupModal_submit"
          >
            Add User
          </Button>
          <Button
            color="light"
            outline
            onClick={handleClose}
            data-testid="AddUserToUserGroupModal_cancel"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUserToUserGroupModal;
