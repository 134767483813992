import React, { useEffect, useState } from "react";
import {
  generatePath,
  Redirect,
  useHistory,
  useParams
} from "react-router-dom";
import FontAwesomeIcon from "@nef/core/lib/components/FontAwesomeIcon";
import { Button } from "@nef/core";
import { useQuery } from "jsonapi-react";
import { toast } from "react-toastify";
import isEqual from "lodash/isEqual";

import { PATHS } from "../../../../../routes";
import useQueryParam from "../../../../../hooks/useQueryParam";
import Toast from "../../../../../components/Toast";
import {
  OrganizationUser,
  OrganizationProductPermission,
  NewUserGroup
} from "../../../../../api/types";
import useDefaultAuth from "../../../../../hooks/useDefaultAuth";
import { updateOrganizationUser } from "../../../../../api/api";
import RegenerateApiKeyButton from "../regenerate-button.tsx";
import ProductsTable from "../table/ProductAccessTable";
import UserGroupsTable from "../table/UserGroupsTable";
import DeleteUserModal from "../modals/delete-user";

import styles from "./index.module.scss";

const dateFormatCa = new Intl.DateTimeFormat("en-US");

const OrganizationsUsersPage = () => {
  const { userId, organizationId } =
    useParams<{ userId: string; organizationId: string }>();
  const queryParams = useQueryParam();
  const history = useHistory();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userGroups, setUserGroups] = useState<NewUserGroup[]>([]);
  const [productPermissions, setProductPermissions] = useState<
    OrganizationProductPermission[]
  >([]);
  const [initialUserGroupsData, setInitialUserGroupsData] =
    useState<NewUserGroup[]>();
  const [initialProductPermissionsData, setInitialProductPermissionsData] =
    useState<OrganizationProductPermission[]>();

  const { isManagerAdmin, isOrganizationUser } = useDefaultAuth();

  const { data, isLoading } = useQuery<OrganizationUser>([
    "organization-users",
    `${userId}`,
    { include: ["organization-teams"] }
  ]);

  useEffect(() => {
    if (!isLoading && data) {
      setUserGroups(data?.organizationTeams || []);
      setProductPermissions(data?.productAccess || []);
      setInitialUserGroupsData(data?.organizationTeams || []);
      setInitialProductPermissionsData(data?.productAccess || []);
    }
  }, [isLoading, data]);

  const onUpdateUserGroup = (groups: any) => {
    setUserGroups(groups);
  };

  const onUpdateProductPermissions = (products: any) => {
    setProductPermissions(products);
  };

  const handleDeleteUser = () => {
    setIsDeleteModalOpen(true);
  };

  const handleBack = () => {
    if (queryParams.get("from") === "organization") {
      return history.push(
        `${generatePath(PATHS.EDIT_ORGANIZATIONS, {
          organizationId
        })}?tab=users`
      );
    }

    if (queryParams.get("from")) {
      return history.goBack();
    }

    return history.push(PATHS.ORGANIZATIONS);
  };

  const onSubmit = async () => {
    try {
      await updateOrganizationUser(userId, {
        product_access: productPermissions.map(product => {
          return product?.id;
        }),
        user_groups: userGroups.map(group => {
          return group?.id;
        })
      });

      toast(
        <Toast
          type="success"
          title="Permissions has been successfully updated"
          details={[]}
        />
      );
    } catch (err) {
      toast(
        <Toast
          type="error"
          title="There was an error updating the permissions"
          details={[]}
        />
      );
    }
  };

  if (!data) {
    return null;
  }

  const { id, fullName, email, inviteEmail, createdAt, lastLoginAt } = data;

  if (!isManagerAdmin() && !isOrganizationUser()) {
    return <Redirect to="/" />;
  }

  const disableIfNotChanged =
    isEqual(initialUserGroupsData, userGroups) &&
    isEqual(initialProductPermissionsData, productPermissions);

  return (
    <div className={styles.page} data-testid="editUserPage">
      <div className={styles.container}>
        <header className={styles.header}>
          <button
            type="button"
            onClick={handleBack}
            className={styles["back-link"]}
          >
            <FontAwesomeIcon iconClassName="fa-arrow-left" />
            Back to Users
          </button>
          <div className={styles["button-container"]}>
            <Button color="danger" onClick={handleDeleteUser}>
              Remove From Organization
            </Button>

            {isDeleteModalOpen && (
              <DeleteUserModal
                redirect={true}
                close={() => setIsDeleteModalOpen(false)}
                selectedUser={{
                  id,
                  userName: fullName,
                  userInviteEmail: inviteEmail
                }}
                organizationId={organizationId}
              />
            )}
            <Button
              disabled={disableIfNotChanged}
              color="primary"
              onClick={onSubmit}
              data-testid="editUserPage_saveChangesButton"
            >
              Save Changes
            </Button>
          </div>
        </header>
        <div className={styles["edit-user-data"]}>
          <div
            className={`${styles["flex-container"]} ${styles["name-api-key"]} `}
          >
            <div className={`${styles["edit-user-name"]} ${styles.center}`}>{`${
              fullName || inviteEmail || ""
            }`}</div>
            <RegenerateApiKeyButton userId={id} disabled={!!inviteEmail} />
          </div>
          <div>
            <span> {email || inviteEmail || ""} </span>
            <span>
              {" | "}
              <strong>Date Joined: </strong>
              {userId && createdAt
                ? dateFormatCa.format(new Date(createdAt))
                : ""}
              {" | "}
            </span>
            <span>
              <strong>Last Login: </strong>
              {lastLoginAt ? dateFormatCa.format(new Date(lastLoginAt)) : ""}
            </span>
          </div>
        </div>
        <div className={styles["flex-container"]}>
          <div className={styles["products-container"]}>
            <ProductsTable
              onUpdate={onUpdateProductPermissions}
              data={productPermissions}
              organizationId={organizationId || ""}
            />
          </div>
          <div className={styles["user-groups-container"]}>
            <UserGroupsTable
              onUpdate={onUpdateUserGroup}
              data={userGroups}
              organizationId={organizationId || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationsUsersPage;
