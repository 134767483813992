import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { RecurringFreq, ScheduleRules, integers } from "../util";
import styles from "../Scheduler.module.scss";

const FREQ_TO_UNIT = {
  daily: "day(s)",
  weekly: "week(s)",
  monthly: "month(s)",
  annually: "year(s)"
};

const IntervalField = ({
  freq,
  fixedInterval
}: {
  freq: RecurringFreq;
  fixedInterval?: number;
}) => {
  const { control } = useFormContext<ScheduleRules>();

  return (
    <>
      <div className={styles.label}>Every</div>
      <Controller
        name={`recurring.${freq}.interval`}
        control={control}
        key={`interval_${freq}`}
        render={({ field: { onChange, value, name } }) => (
          <div>
            <select
              id={name}
              value={fixedInterval || value}
              className={styles.input}
              onChange={e => {
                onChange(parseInt(e.target.value, 10));
              }}
              disabled={!!fixedInterval}
            >
              {integers(1, 99).map(option => (
                <option key={`interval-${option}`} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {FREQ_TO_UNIT[freq]}
          </div>
        )}
      />
    </>
  );
};

export default IntervalField;
