import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { useMutation } from "jsonapi-react";
import { toast } from "react-toastify";

import Toast from "../../../../../../components/Toast";
import { OrganizationProducts } from "../../../../../../api/types";
import manageClient from "../../../../../../api/manage/client";

import styles from "./index.module.scss";

interface EnableDisableProductConfirmationModalProps {
  close: () => void;
  selectedProduct: any;
  setSelectedProduct?: React.Dispatch<
    React.SetStateAction<OrganizationProducts | null>
  >;
}

const EnableDisableProductConfirmationModal = ({
  close,
  selectedProduct,
  setSelectedProduct
}: EnableDisableProductConfirmationModalProps) => {
  const path = selectedProduct?.enabled ? "disable" : "enable";

  const [enableDisableProductConfirmation] = useMutation(
    ["organization-team-invoices", selectedProduct?.id, path],
    {
      client: manageClient,
      method: "PATCH"
    }
  );

  const handleEnableDisableProduct = async () => {
    const { errors, error } = await enableDisableProductConfirmation({});

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title={`There was an error ${
            selectedProduct?.enabled ? "disabling" : "enabling"
          } the product`}
          details={[]}
        />
      );
    } else {
      toast(
        <Toast
          type="success"
          title={`Product has been successfully ${
            selectedProduct?.enabled ? "disabled" : "enabled"
          }`}
          details={[]}
        />
      );

      handleClose();
    }
  };

  const handleClose = () => {
    if (setSelectedProduct) setSelectedProduct(null);
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="OrganizationsEnableDisableProductConfirmationModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Enable Disable Product Modal"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        {selectedProduct?.enabled ? "Disable" : "Enable"} Product
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <p className={styles.description}>
              Are you sure you would like to{" "}
              {selectedProduct?.enabled ? "disable" : "enable"}{" "}
              {selectedProduct?.productName}?
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              color="danger"
              onClick={handleClose}
              data-testid="OrganizationsEnableDisableProductConfirmationModal_cancel"
            >
              No
            </Button>
            <Button
              onClick={handleEnableDisableProduct}
              data-testid="OrganizationsEnableDisableProductConfirmationModal_submit"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EnableDisableProductConfirmationModal;
