import React, { useCallback, useEffect, useState } from "react";
import {
  FormFieldWithIcon,
  Pagination,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
  FontAwesomeIcon
} from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { useQuery } from "jsonapi-react";
import { useHistory } from "react-router-dom";
import { startCase } from "lodash";

import useQueryParam from "../../hooks/useQueryParam";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import { ProductSortParam, Vendor } from "../../api/types";
import { PATHS } from "../../routes";
import NewProductModal from "../../components/new-product/NewProductModal";
import manageClient from "../../api/manage/client";

import styles from "./product.module.scss";
import "./swiper.scss";
import ProductsTable from "./products-table";

const SORT_PARAM_TO_REQUEST_PARAM = {
  name: "name",
  "-name": "-name",
  created_at: "created_at",
  "-created_at": "-created_at"
};

const productDropdownOptions = {
  all: "All",
  in_progress: "In Progress",
  submitted: "Submitted",
  published: "Published"
};

interface RequestBody {
  filter: {
    vendor_id?: string; // eslint-disable-line camelcase
    name?: string;
    status?: string;
  };
  page: {
    number: number;
  };
  sort: string;
}

type SortParam = keyof typeof SORT_PARAM_TO_REQUEST_PARAM;

const isProductSortParam = (query: string): query is ProductSortParam => {
  return Object.keys(SORT_PARAM_TO_REQUEST_PARAM).includes(query);
};

const ProductPage = () => {
  const history = useHistory();
  const queryParams = useQueryParam();
  const { isManagerAdmin, isPublisherUser } = useDefaultAuth();

  const query = queryParams.get("search") ?? "";
  const [searchQuery, setSearchQuery] = useState(query);
  const [isNewProdModalOpen, setNewProdModalOpen] = useState(false);
  const vendorId = queryParams?.get("vendorId") ?? "";

  const page = parseInt(queryParams.get("page") || "1", 10);

  const rawSortParam = useQueryParam().get("sort") || "";
  const sortParam = isProductSortParam(rawSortParam) ? rawSortParam : "name";

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    null | string
  >(null);

  const { data } = useQuery<Vendor>(["vendors", vendorId], {
    client: manageClient
  });

  const toggleDropdown = () => {
    setIsDropdownOpen(prevIsOpen => !prevIsOpen);
  };

  const onClickHandler = (_e: any, opt: string) => {
    const selectedKey = (
      Object.keys(productDropdownOptions) as Array<
        keyof typeof productDropdownOptions
      >
    )?.find(key => productDropdownOptions[key] === opt);

    setSelectedDropdownValue(selectedKey || null);
    toggleDropdown();
  };

  const generateRequestBody = () => {
    const filter: { [key: string]: string | number } = {
      vendor_id: vendorId,
      status: selectedDropdownValue as string,
      search_by_name_or_code: query
    };

    Object.keys(filter).forEach(key => {
      if (!filter[key]) {
        delete filter[key];
      }
    });

    const requestBody: RequestBody = {
      filter,
      page: {
        number: page
      },
      sort: SORT_PARAM_TO_REQUEST_PARAM[sortParam as SortParam]
    };

    return requestBody;
  };

  const {
    data: datatable,
    links,
    isLoading
  } = useQuery<any[]>(["datatable-collections", generateRequestBody()]);

  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (!isLoading) {
      setTotalPages(extractPageNumber(links?.last));
    }
  }, [isLoading]);

  const extractPageNumber = (url?: string): number => {
    if (!url) {
      return 1;
    }

    const urlObject = new URL(url);
    const pageNumber = urlObject.searchParams.get("page[number]");

    return pageNumber ? parseInt(pageNumber, 10) : 1;
  };

  const handleSearch = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchQuery(enteredQuery);
  };

  const handleAction = () => {
    loadPageWithParams({
      search: searchQuery,
      vendorId,
      page: 1,
      sort: sortParam
    });
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAction();
    }
  };

  const setSortParam = useCallback(
    (sort: string) => {
      loadPageWithParams({
        search: searchQuery,
        vendorId,
        page,
        sort
      });
    },
    [searchQuery, vendorId]
  );

  const setPageParam = useCallback(
    (pageNum: number) => {
      loadPageWithParams({
        search: searchQuery,
        vendorId,
        page: pageNum,
        sort: sortParam
      });
    },
    [page, searchQuery, sortParam, vendorId]
  );

  const loadPageWithParams = (params: {
    search: string;
    vendorId: string; // eslint-disable-line camelcase
    page: number;
    sort: string;
  }) => {
    const queryString = Object.entries(params).reduce((url, param) => {
      const [key, value] = param;
      if (!value) return url;

      return `${url}${url ? "&" : "?"}${key}=${value}`;
    }, "");

    history.push({
      search: queryString
    });
  };

  const onPageChange = (num: number) => {
    setPageParam(num + 1);
  };

  if (!isManagerAdmin() && !(isPublisherUser() && vendorId)) {
    history.push(PATHS.PUBLISHERS);
    return null;
  }

  const nextPage = () => {
    if (page < totalPages) {
      setPageParam(page + 1);
    }
  };

  const previousPage = () => {
    if (page !== 1) {
      setPageParam(page - 1);
    }
  };

  return (
    <main className={styles.container} data-testid="productPage">
      <section className={styles["content-section"]}>
        <div className={styles.vendor}>
          <h1 className={styles.heading}>Products</h1>
          <div className={styles["buttons-container"]}>
            <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle color="light" outline caret>
                {selectedDropdownValue
                  ? startCase(selectedDropdownValue)
                  : "Filters"}
              </DropdownToggle>
              <DropdownMenu>
                {Object.values(productDropdownOptions).map(value => {
                  return (
                    <DropdownItem
                      key={value}
                      onClick={(_e: any) => onClickHandler(_e, value)}
                    >
                      {value}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <FormFieldWithIcon
              placeholder="Search"
              iconClassName="fa-search"
              addonType="append"
              value={searchQuery}
              onChange={handleSearch}
              onIconClick={handleAction}
              onKeyDown={handleSubmit}
            />
            {isManagerAdmin() && (
              <Button onClick={() => setNewProdModalOpen(true)}>
                <FontAwesomeIcon
                  iconClassName="fa-plus-circle"
                  className={styles["circle-icon"]}
                />
                New Product
              </Button>
            )}
            {isNewProdModalOpen && (
              <NewProductModal
                isOpen={isNewProdModalOpen}
                vendorId={vendorId}
                close={() => setNewProdModalOpen(false)}
                hasLicences={data?.hasLicences}
              />
            )}
          </div>
        </div>
        <ProductsTable
          datatable={datatable}
          sortParam={sortParam}
          setSortParam={setSortParam}
        />
        <Pagination
          page={page - 1}
          pages={totalPages}
          pageSize={0}
          pageSizeOptions={[]}
          onPageChange={onPageChange}
          onPageSizeChange={() => null}
          onPageUpdate={() => null}
          nextPage={nextPage}
          previousPage={previousPage}
          showPageSizeDropdown={false}
          showPageDataCount={false}
          totalDataLength={0}
        />
      </section>
    </main>
  );
};

export default ProductPage;
