import { createContext } from "react";

import { DatatableSchema } from "../api/types";

interface DatatableContextInterface {
  datatable: DatatableSchema;
  setDatatable: (datatable: DatatableSchema) => void;
}

const initialValue: DatatableContextInterface = {
  datatable: {
    name: "",
    code: "",
    vendorCode: "",
    columns: [
      {
        name: "",
        type: "integer",
        baseType: "integer",
        isNew: true
      }
    ],
    source: null,
    creator: { firstName: "", lastName: "", email: "" },
    version: { code: "", default: true, description: "" },
    createdAt: new Date(),
    updatedAt: new Date(),
    backend: "postgres",
    partitions: []
  },
  setDatatable: () => null
};

const DatatableContext = createContext<DatatableContextInterface>(initialValue);

export default DatatableContext;
