import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import FontAwesomeIcon from "@nef/core/lib/components/FontAwesomeIcon";

import {
  NewProductSupportingDocument,
  ProductSupportingDocument
} from "../../../api/types";

type Props = {
  datatableProductType: string;
  supportingDocuments:
    | ProductSupportingDocument[]
    | NewProductSupportingDocument[]
    | undefined;
};

const ProductSupportingDocuments: React.FC<Props> = ({
  datatableProductType,
  supportingDocuments
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const isADP = datatableProductType === "ADP";
  const isESG = datatableProductType === "ESG";

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    window.addEventListener("click", handleClickOutside);

    return () => window.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <>
      {(isADP || isESG) && (
        <section>
          {!(supportingDocuments && supportingDocuments.length > 0) ? (
            <p>Loading...</p>
          ) : (
            <div className="supporting-documents-section">
              {supportingDocuments && (
                <div ref={menuRef}>
                  <button
                    type="button"
                    className="supporting-documents-button"
                    onClick={() => setIsDropdownOpen(prevState => !prevState)}
                  >
                    Supporting Documents
                    <FontAwesomeIcon
                      iconClassName={`${
                        isDropdownOpen
                          ? "fa-angle-up fa-lg"
                          : "fa-angle-down fa-lg"
                      }`}
                      iconSetClassName="fas"
                      style={{ color: "#6e6e6e", marginLeft: "7px" }}
                    />
                  </button>
                  {isDropdownOpen && (
                    <div className="dropdown-menu-container">
                      {supportingDocuments?.map(option => (
                        <div className="dropdown-menu" key={option.id}>
                          <p className="dropdown-menu-text">
                            <span>{option.name}</span>
                            <FontAwesomeIcon
                              iconClassName="fa-file-pdf"
                              iconSetClassName="far"
                              style={{ color: "#595959", marginLeft: "5px" }}
                            />
                          </p>
                          <div className="dropdown-menu-link">
                            <FontAwesomeIcon
                              iconClassName="fa-arrow-down"
                              iconSetClassName="fas"
                              style={{ color: "#949494", marginRight: "5px" }}
                            />
                            <p>Download</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default ProductSupportingDocuments;
