import React, { useEffect, useState } from "react";
import { Button } from "@nef/core";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import useWizard from "../../hooks/useWizard";
import useCapProductContext from "../../hooks/useCapProductContext";
import { ConfirmationModal } from "../modals/ConfirmationModal";

import styles from "./CapSurveyFormStepFooter.module.scss";
import CapSurveyFormFieldRow from "./CapSurveyFormFieldRow";

interface CapSurveyFormStepFooterProps {
  save: (shouldAdvance?: boolean) => Promise<void>;
  canAdvance?: boolean;
  isDirty?: boolean;
}

const TextButton = styled(Button)`
  padding-left: 0 !important; /* !important to override NEF styles */
  padding-right: 0 !important; /* !important to override NEF styles */
`;

const CapSurveyFormStepFooter = ({
  save,
  canAdvance,
  isDirty = false
}: CapSurveyFormStepFooterProps) => {
  const history = useHistory();
  const { nextPath, previousPath } = useWizard();
  const { isReady } = useCapProductContext();
  const [shouldAdvance, setShouldAdvance] = useState(false);
  const [discardState, setDiscardState] = useState(false);

  const onSaveSession = () => {
    setShouldAdvance(false);
    save(false).catch(() => {});
  };

  const onPrevious = () => {
    setShouldAdvance(false);
    history.push(previousPath);
  };

  const onNext = () => {
    setShouldAdvance(true);
    save(true).catch(() => {
      // This ensures `shouldAdvance` is reset to `false` after save fails,
      // and prevents other side effects (eg. other workflows that trigger
      // `useForm().handleSubmit`).
      // Note that this works only if `handleSubmit` does not swallow errors.`).
      setShouldAdvance(false);
    });
  };

  const onDiscardAttempt = () => {
    if (isDirty) {
      setDiscardState(true);
    } else {
      onPrevious();
    }
  };

  useEffect(() => {
    if (shouldAdvance && canAdvance && isReady) {
      history.push(nextPath);
    }
  }, [shouldAdvance, canAdvance, isReady, nextPath]);

  return (
    <CapSurveyFormFieldRow tooltip="">
      <div className={styles.footer}>
        <TextButton
          onClick={onSaveSession}
          color="link"
          data-testid="capSurveyFormStepFooter_saveSession"
        >
          Save Session
        </TextButton>
        <div
          className={styles.group}
          data-testid="capSurveyFormStepFooter_previous"
        >
          <Button
            onClick={onDiscardAttempt}
            disabled={!previousPath}
            outline={true}
          >
            Previous
          </Button>
          <Button
            onClick={onNext}
            disabled={!nextPath}
            data-testid="capSurveyFormStepFooter_next"
          >
            Next
          </Button>
        </div>
      </div>

      <ConfirmationModal
        isOpen={discardState}
        title="Unsaved changes"
        question="There are unsaved changes. Please click cancel, then Save Session at the bottom of the page or they will be discarded."
        confirmText="confirm"
        dismissText="cancel"
        onConfirm={() => {
          setDiscardState(false);
          onPrevious();
        }}
        onDismiss={() => {
          setDiscardState(false);
        }}
      />
    </CapSurveyFormFieldRow>
  );
};

export default CapSurveyFormStepFooter;
