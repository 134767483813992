import React from "react";
import { Box, FlexGrid, FormSelect } from "@nef/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import AmountCentsInput from "../../common/AmountCentsInput";

const FullWidthBox = styled(Box)`
  width: 100%;
`;

const PAYMENT_TYPE_OPTIONS = [
  { value: "allowPayment", label: "Subscription" },
  { value: "allowContactSales", label: "Contact Sales" }
];

const PlanFormSubscriptionInputs = () => {
  const { control, setValue } = useFormContext();
  const [allowContactSales, allowPayment] = useWatch({
    control,
    name: ["allowContactSales", "allowPayment"]
  });

  const setPaymentType = (allowPaymentOrContactSales: string) => {
    const newAllowPayment = allowPaymentOrContactSales === "allowPayment";
    const newAllowContactSales =
      allowPaymentOrContactSales === "allowContactSales";

    setValue("allowPayment", newAllowPayment, { shouldDirty: true });
    setValue("allowContactSales", newAllowContactSales, { shouldDirty: true });

    if (newAllowContactSales) {
      setValue("amountCents", null, { shouldDirty: true });
    } else if (allowContactSales === true) {
      // This ensures the `amountCents` field is a valid number right when user switches from "Contact Sales" to "Subscription".
      // The extra `if` check on the existing `allowContactSales` is just to ensure the user did indeed just make the swtich.
      // This is just in case if there is some unknown mechanism that triggers FormSelect.onChange even though the new selection
      // is the same value.
      setValue("amountCents", 0, { shouldDirty: true });
    }
  };

  const currentPaymentType = allowPayment
    ? "allowPayment"
    : "allowContactSales";

  return (
    <>
      <FlexGrid.Column md={5}>
        <FullWidthBox paddingVertical={4} paddingHorizontal={0}>
          <FormSelect
            id="type"
            name="paymentType"
            label="Payment Type"
            options={PAYMENT_TYPE_OPTIONS}
            value={PAYMENT_TYPE_OPTIONS.find(
              o => o.value === currentPaymentType
            )}
            onChange={(formSelectOnChangeParam: any) => {
              setPaymentType(
                extractFormSelectOnChangeValue(formSelectOnChangeParam)
              );
            }}
            isClearable={false}
            classNamePrefix="planPaymentType"
          />
        </FullWidthBox>
      </FlexGrid.Column>
      <FlexGrid.Column md={4}>
        <FullWidthBox paddingVertical={4} paddingHorizontal={0}>
          <Controller
            name="currency"
            control={control}
            render={({ field: { name } }) => (
              <FormSelect
                id="currency"
                name={name}
                label="Currency"
                placeholder="USD"
                disabled={true}
                isClearable={false}
                classNamePrefix="planCurrency"
              />
            )}
          />
        </FullWidthBox>
      </FlexGrid.Column>
      <FlexGrid.Column md={5}>
        <FullWidthBox paddingVertical={4} paddingHorizontal={0}>
          <AmountCentsInput
            name="amountCents"
            label="Dollar Amount"
            testid="planFormSubscriptionInputs_amountCents"
            disabled={allowContactSales}
          />
        </FullWidthBox>
      </FlexGrid.Column>
    </>
  );
};

export default PlanFormSubscriptionInputs;
