import React, { ReactNode, useState } from "react";

import WizardContext from "../../contexts/wizard";

import { StepProps, WizardConfigs } from "./types";

interface WizardContextProviderProps {
  matchStep: (step: StepProps) => boolean;
  navigateTo: (path: string, replace?: boolean) => void;
  children: ReactNode;
}

const WizardContextProvider = ({
  matchStep,
  navigateTo,
  children
}: WizardContextProviderProps) => {
  const [steps, setSteps] = useState<StepProps[]>([]);

  const registerStep = (step: StepProps) => {
    setSteps(prevState => {
      return [...prevState, step];
    });
  };
  const unregisterStep = (step: StepProps) => {
    setSteps(prevState => {
      const stepIndex = prevState.findIndex(s => s.path === step.path);

      if (stepIndex > -1) {
        const newSteps = [...prevState];
        newSteps.splice(stepIndex, 1);
        return newSteps;
      }

      return prevState;
    });
  };
  const updateStep = (step: StepProps) => {
    setSteps(prevState => {
      const stepIndex = prevState.findIndex(s => s.path === step.path);

      if (stepIndex > -1) {
        const newSteps = [...prevState];
        newSteps[stepIndex] = { ...step };
        return newSteps;
      }

      return prevState;
    });
  };

  const baseSteps = steps.filter(step => {
    return !step.path.includes("/");
  });

  const currentStepIndex = steps.findIndex(matchStep);

  const numOfSteps = steps.length;
  const currentStepPath =
    currentStepIndex > -1 ? steps[currentStepIndex].path : "";
  const previousPath =
    currentStepIndex > 0 ? steps[currentStepIndex - 1].path : "";
  const nextPath =
    currentStepIndex < numOfSteps - 1 ? steps[currentStepIndex + 1].path : "";

  const canAdvanceTo = (targetStep: StepProps) => {
    const firstUnadvancableStepIndex = steps.findIndex(
      step => !step.canAdvance
    );
    const targetStepIndex = steps.findIndex(step => step === targetStep);

    return (
      firstUnadvancableStepIndex < 0 ||
      targetStepIndex <= firstUnadvancableStepIndex
    );
  };

  const wizardContextValue: WizardConfigs = {
    steps,
    baseSteps,
    registerStep,
    unregisterStep,
    updateStep,
    currentStepIndex,
    currentStepPath,
    previousPath,
    nextPath,
    canAdvanceTo,
    navigateTo
  };

  return (
    <WizardContext.Provider value={wizardContextValue}>
      {children}
    </WizardContext.Provider>
  );
};

export default WizardContextProvider;
