import { useLocation } from "react-router-dom";

import { APP_ROOT, SAML_STRATEGY_ENABLED } from "../../appConfig";

const NdlLoginRedirect = () => {
  const { pathname } = useLocation();
  const ndlUrl = window.location.origin;

  if (SAML_STRATEGY_ENABLED) {
    const samlLoginUrl = new URL(`${ndlUrl}/users/saml/sign_in`);
    window.location.assign(samlLoginUrl.href);
  } else {
    const ndlLoginUrl = new URL(`${ndlUrl}/login`);
    ndlLoginUrl.searchParams.append("fromManager", `${APP_ROOT}${pathname}`);

    window.location.assign(ndlLoginUrl.href);
  }

  return null;
};

export default NdlLoginRedirect;
