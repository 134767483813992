import React, { useCallback, useState } from "react";
import styled from "styled-components";
import FormInput, {
  CustomChangeEvent
} from "@nef/core/lib/components/FormInput";
import { Button } from "@nef/core";
import { generatePath, Link, useHistory } from "react-router-dom";
import { useQuery } from "jsonapi-react";

import useQueryParam from "../../hooks/useQueryParam";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import { DatatableSchema, DatatableSortParam, Vendor } from "../../api/types";
import DatatableSchemaRecord from "../../components/datatables/DatatableSchemaRecord";
import CursorPagination from "../../components/cursor-pagination/CursorPagination";
import DatatableListHeader from "../../components/datatables/DatatableListHeader";
import { PATHS } from "../../routes";

import styles from "./datatables.module.scss";

const DatatableSearch = styled(FormInput)`
  width: 20vw;
  min-width: 260px;
  background-color: #f8f8f8;
  font-size: 13px;
`;

const SORT_PARAM_TO_REQUEST_PARAM: Record<DatatableSortParam, string> = {
  name: "name",
  "-name": "-name",
  date: "updated-at",
  "-date": "-updated-at"
};

const isDatatableSortParam = (query: string): query is DatatableSortParam => {
  return Object.keys(SORT_PARAM_TO_REQUEST_PARAM).includes(query);
};

const DatatablesPage = () => {
  const { authenticatedUser } = useDefaultAuth();
  const queryParams = useQueryParam();
  const query = queryParams.get("search") || "";
  const vendorId = queryParams?.get("vendorId") ?? "";
  const [searchQuery, setSearchQuery] = useState(query);

  const { data: selectedVendor, isLoading: isVendorLoading } = useQuery<Vendor>(
    ["vendors", vendorId]
  );

  const before = queryParams.get("before") || "";
  const after = queryParams.get("after") || "";
  const page = parseInt(queryParams.get("page") || "1", 10);
  const rawSortParam = queryParams.get("sort") || "";
  const sortParam = isDatatableSortParam(rawSortParam) ? rawSortParam : "name";

  const history = useHistory();

  const generateRequestBody = () => {
    const filter: { [key: string]: string | number | undefined } = {
      vendor_code: selectedVendor?.code,
      before,
      after,
      name: query
    };

    Object.keys(filter).forEach(key => {
      if (!filter[key]) {
        delete filter[key];
      }
    });

    const requestBody: any = {
      filter,
      page: {
        number: page
      },
      sort: SORT_PARAM_TO_REQUEST_PARAM[sortParam]
    };

    return requestBody;
  };

  const {
    data: datatables,
    meta,
    isLoading
  } = useQuery<DatatableSchema[]>(
    !isVendorLoading && [
      selectedVendor?.code
        ? "datatable_schema/index_by_vendor"
        : "datatable_schema",
      generateRequestBody()
    ]
  );

  const onTypeSearch = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchQuery(enteredQuery);
  };

  const onSubmitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;

    history.push({
      search: searchQuery ? `?search=${searchQuery}` : ""
    });

    loadPageWithParams({
      search: searchQuery,
      vendorId,
      page,
      sort: sortParam
    });
  };

  const setSortParam = useCallback(
    (sort: string) => {
      loadPageWithParams({
        search: searchQuery,
        vendorId,
        page,
        sort
      });
    },
    [searchQuery, vendorId]
  );

  const loadPageWithParams = (params: {
    search: string;
    vendorId: string;
    page: number;
    before?: string;
    after?: string;
    sort: string;
  }) => {
    const queryString = Object.entries(params).reduce((url, param) => {
      const [key, value] = param;
      if (!value) return url;

      return `${url}${url ? "&" : "?"}${key}=${value}`;
    }, "");

    history.push({
      search: queryString
    });
  };

  if (!authenticatedUser?.rolesArray.includes("manager_admin")) {
    history.push(PATHS.PUBLISHERS);
    return null;
  }

  if (isVendorLoading) {
    return null;
  }

  return (
    <main className={styles.container}>
      <div className={styles.actions}>
        <Button
          as={Link}
          to={generatePath(PATHS.NEW_DATATABLE)}
          style={{ fontSize: "14px" }}
          data-testid="datatables_addDatatable"
        >
          Add Data Table
        </Button>
        <DatatableSearch
          type="search"
          placeholder="Search..."
          data-testid="datatables_search"
          onChange={onTypeSearch}
          value={searchQuery}
          onKeyDown={onSubmitSearch}
        />
      </div>
      <div
        className={styles.datatables}
        data-testid={`datatables_${selectedVendor?.id}`}
      >
        <DatatableListHeader
          sortParam={sortParam}
          setSortParam={setSortParam}
        />

        {isLoading && (
          <div data-testid="datatables_loading" className={styles.loading}>
            Loading datatables...
          </div>
        )}

        {!isLoading &&
          datatables?.map(d => (
            <DatatableSchemaRecord
              key={d.code}
              datatable={d}
              vendor={selectedVendor}
            />
          ))}

        {!isLoading && !datatables?.length && (
          <div data-testid="datatables_noRecords" className={styles.empty}>
            You do not have access to any datatables.
          </div>
        )}

        {!isLoading && (
          <div
            data-testid="datatables_pagination"
            className={styles.pagination}
          >
            <CursorPagination
              pathname={generatePath(PATHS.DATATABLES)}
              baseQueryParams={{
                search: query,
                vendorId,
                sort: sortParam
              }}
              pageInfo={meta?.pagination}
            />
          </div>
        )}
      </div>
    </main>
  );
};

export default DatatablesPage;
