import React from "react";
import { Button } from "@nef/core";
import { generatePath, Link, useHistory, useParams } from "react-router-dom";

import DataView from "../../components/datatables-form/components/DataView";
import { PATHS } from "../../routes";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import styles from "./upload-logs.module.scss";

const DataPage = () => {
  const history = useHistory();
  const { authenticatedUser } = useDefaultAuth();
  const { vendorCode, datatableCode, versionCode } = useParams<{
    vendorCode: string;
    datatableCode: string;
    versionCode: string;
  }>();

  if (!authenticatedUser?.rolesArray.includes("manager_admin")) {
    history.push(PATHS.PUBLISHERS);
    return null;
  }

  if (versionCode !== "FINAL") {
    history.push(
      generatePath(`${PATHS.EDIT_DATATABLE}/dataLocation`, {
        vendorCode,
        datatableCode,
        versionCode
      })
    );

    return null;
  }

  return (
    <div data-testid="dataPage">
      <Button
        as={Link}
        to={generatePath(`${PATHS.EDIT_DATATABLE}/dataLocation`, {
          vendorCode,
          datatableCode,
          versionCode
        })}
        color="link"
        data-testid="uploadLogs_previousStep"
      >
        &lt; Back to Data Location
      </Button>
      <div className={styles.container}>
        <h3 className={styles.header}>Data Table</h3>

        <DataView vendorCode={vendorCode} datatableCode={datatableCode} />
      </div>
    </div>
  );
};

export default DataPage;
