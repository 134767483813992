import React, { useCallback, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { Button, CircleButton, FontAwesomeIcon } from "@nef/core";
import {
  Row,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useQuery } from "jsonapi-react";

import NewUserModal from "../../components/new-user/NewUserModal";
import { PATHS } from "../../routes";
import DeleteUserModal from "../user/delete-user-modal";

import styles from "./users-table.module.scss";

interface User {
  id: string;
  userName: string;
  userEmail: string;
  userInviteEmail: string;
  createdAt: string;
}

const SORT_PARAM_TO_REQUEST_PARAM = {
  name: "user.first_name,user.last_name",
  "-name": "-user.first_name,-user.last_name",
  email: "user.email",
  "-email": "-user.email",
  created_at: "created_at",
  "-created_at": "-created_at"
};

type SortParam = keyof typeof SORT_PARAM_TO_REQUEST_PARAM;

const generateRequestBody = (vendorId: string, sortParam: SortParam) => {
  const requestBody: Record<string, string> = {};
  requestBody["filter[vendor_id]"] = vendorId;

  if (sortParam.trim() !== "") {
    requestBody.sort = SORT_PARAM_TO_REQUEST_PARAM[sortParam];
  }

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const columnHelper = createColumnHelper<User>();

const UserActions = ({
  row,
  hasEditAccess,
  handleDelete
}: {
  row: Row<User>;
  hasEditAccess?: boolean;
  handleDelete: (info: User) => void;
}) => {
  const {
    original: { id }
  } = row;

  if (!id) {
    return null;
  }

  return (
    <div className={styles["user-actions"]}>
      {hasEditAccess && (
        <>
          <Link
            to={`/users/${id}?from=dashboard`}
            data-testid="usersTable_editUser"
          >
            <CircleButton outline className={styles["table-delete-button"]}>
              <FontAwesomeIcon iconClassName="fa-edit" />
            </CircleButton>
          </Link>
          <CircleButton
            outline
            color="danger"
            className={styles["table-delete-button"]}
            onClick={() => handleDelete(row.original)}
            data-testid="usersTable_deleteUser"
          >
            <FontAwesomeIcon iconClassName="fa-trash" />
          </CircleButton>
        </>
      )}
    </div>
  );
};

interface UsersTableProps {
  hasEditAccess?: boolean;
  vendorId: string;
}

const UsersTable = ({ hasEditAccess, vendorId }: UsersTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortParam, setSortParam] = useState<SortParam>("name");
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<null | User>(null);
  const { data: userData } = useQuery<User[]>([
    "vendor-users",
    generateRequestBody(vendorId, sortParam)
  ]);

  const ascending = !sortParam.startsWith("-");
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";
  const sortBy = ascending ? sortParam : sortParam.substring(1);

  const updateSortParam = useCallback(
    (newSortBy: SortParam) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as SortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const handleDeleteUser = (info: User) => {
    setSelectedUser(info);
    setIsDeleteModalOpen(true);
  };

  const columns = [
    columnHelper.accessor("userName", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          {hasEditAccess ? (
            <Link
              className={styles.values}
              to={generatePath(PATHS.EDIT_USER, {
                userId: info.row.original.id
              })}
            >
              {info.getValue() || <em>Invite sent</em>}
            </Link>
          ) : (
            <span className={styles.values}>
              {info.getValue() || <em>Invite sent</em>}
            </span>
          )}
        </div>
      ),
      header: () => {
        return (
          <div className={sortBy === "name" ? styles["sorter-active"] : ""}>
            <button
              type="button"
              onClick={() => updateSortParam("name")}
              onKeyPress={() => updateSortParam("name")}
              tabIndex={-1}
            >
              Name
            </button>
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("userEmail", {
      id: "email",
      cell: info => info.getValue() || info.row.original.userInviteEmail,
      header: () => {
        return (
          <div className={sortBy === "email" ? styles["sorter-active"] : ""}>
            <button
              type="button"
              onClick={() => updateSortParam("email")}
              onKeyPress={() => updateSortParam("email")}
              tabIndex={-1}
            >
              Email
            </button>
            {sortBy === "email" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("createdAt", {
      id: "dateCreated",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(new Date(info.getValue())),
      header: () => {
        return (
          <div
            className={sortBy === "created_at" ? styles["sorter-active"] : ""}
          >
            <button
              type="button"
              onClick={() => updateSortParam("created_at")}
              onKeyPress={() => updateSortParam("created_at")}
            >
              Date Created
            </button>
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.display({
      id: "userActions",
      cell: props => (
        <UserActions
          row={props.row}
          hasEditAccess={hasEditAccess}
          handleDelete={handleDeleteUser}
        />
      )
    })
  ];
  const table = useReactTable({
    data: userData || [],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={styles.container} data-testid="usersTable">
      <DeleteUserModal
        redirect={false}
        isOpen={isDeleteModalOpen}
        close={() => setIsDeleteModalOpen(false)}
        selectedUser={selectedUser}
        vendorId={vendorId}
      />

      {hasEditAccess && (
        <div className={styles.header}>
          <span>Users</span>
          <Button
            color="light"
            size="sm"
            outline
            onClick={() => setIsNewUserModalOpen(true)}
          >
            <FontAwesomeIcon iconClassName="fa-plus-circle" />
            New User
          </Button>
          {isNewUserModalOpen && (
            <NewUserModal
              isOpen={isNewUserModalOpen}
              vendorId={vendorId}
              close={() => setIsNewUserModalOpen(false)}
            />
          )}
        </div>
      )}

      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersTable;
