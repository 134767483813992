import React from "react";

import styles from "./CapSurveyFormBody.module.scss";

interface CapSurveyFormBodyProps {
  children: JSX.Element | JSX.Element[];
}

const CapSurveyFormBody = ({ children }: CapSurveyFormBodyProps) => {
  return (
    <div className={styles.body}>
      <div className={styles.form}>{children}</div>
    </div>
  );
};

export default CapSurveyFormBody;
