import React, { useState } from "react";
import { Button, Link } from "@nef/core";
import styled from "styled-components";

import { DatatableColumn, DefaultFilter } from "../../../api/types";

import ColumnFiltersModal from "./ColumnFiltersModal";

export const StyledColumnFiltersButton = styled(Button)`
  padding: 0;
  letter-spacing: 0;
  text-transform: none;
`;

const ColumnFiltersButton = ({
  column,
  index,
  updateColumn
}: {
  column: DatatableColumn;
  index: number;
  updateColumn: (
    index: number,
    newAttributes: Partial<DatatableColumn>
  ) => void;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const save = (newDefaultFilters: DefaultFilter[]) => {
    updateColumn(index, { defaultFilters: newDefaultFilters });
  };

  return (
    <>
      <StyledColumnFiltersButton
        as={Link}
        color="link"
        size="sm"
        onClick={() => setModalOpen(true)}
        data-testid="datatableSchemaForm_columnFilter"
      >
        Filter
      </StyledColumnFiltersButton>
      <ColumnFiltersModal
        isOpen={isModalOpen}
        index={index}
        column={column}
        save={save}
        close={() => setModalOpen(false)}
      />
    </>
  );
};

export default ColumnFiltersButton;
