import React from "react";

import { Product } from "../../api/types";

import styles from "./product-status-pill.module.scss";

interface ProductPrivateStatusPillProps {
  product: Product;
}

const ProductPrivateStatusPill = ({
  product
}: ProductPrivateStatusPillProps) => {
  if (!product.exclusive) {
    return null;
  }

  const isProductCAP = product.productType === "CAP";

  const classNames = isProductCAP
    ? `${styles.pill} ${styles["cap-product-pill"]}`
    : styles.pill;

  return (
    <div className={classNames}>
      {isProductCAP ? "Nasdaq Internal Users" : "Private"}
    </div>
  );
};

export default ProductPrivateStatusPill;
