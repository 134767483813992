import React, { useCallback, useEffect, useState } from "react";
import {
  FormFieldWithIcon,
  Pagination,
  Button,
  FontAwesomeIcon
} from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { useQuery } from "jsonapi-react";
import { useHistory, Redirect } from "react-router-dom";

import useQueryParam from "../../hooks/useQueryParam";
import { UserGroup, UserGroupSortParam } from "../../api/types";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import NewUserGroupModal from "../../components/new-user-group/NewUserGroupModal";

import UserGroupsTable from "./user-groups-table";
import styles from "./user-group.module.scss";

const SORT_PARAM_TO_REQUEST_PARAM = {
  name: "name",
  "-name": "-name",
  created_at: "created_at",
  "-created_at": "-created_at",
  total_number_of_users: "total_number_of_users",
  "-total_number_of_users": "-total_number_of_users"
};

interface RequestBody {
  filter: {
    vendor_id?: string; // eslint-disable-line camelcase
    name?: string;
  };
  include: string;
  page: {
    number: number;
  };
  sort: string;
}

type SortParam = keyof typeof SORT_PARAM_TO_REQUEST_PARAM;

const isUserGroupSortParam = (query: string): query is UserGroupSortParam => {
  return Object.keys(SORT_PARAM_TO_REQUEST_PARAM).includes(query);
};

const UserGroupsPage = () => {
  const history = useHistory();
  const queryParams = useQueryParam();
  const { isManagerAdmin, isPublisherUser, isAdminableVendor } =
    useDefaultAuth();

  const query = queryParams.get("search") ?? "";
  const [searchQuery, setSearchQuery] = useState(query);
  const [isNewUserGroupModalOpen, setIsNewUserGroupModalOpen] = useState(false);
  const vendorId = queryParams?.get("vendorId") ?? "";

  const page = parseInt(queryParams.get("page") || "1", 10);

  const rawSortParam = useQueryParam().get("sort") || "";
  const sortParam = isUserGroupSortParam(rawSortParam) ? rawSortParam : "name";

  const generateRequestBody = () => {
    const filter: { [key: string]: string | number } = {
      vendor_id: vendorId,
      name: query
    };

    Object.keys(filter).forEach(key => {
      if (!filter[key]) {
        delete filter[key];
      }
    });

    const requestBody: RequestBody = {
      filter,
      include: "vendor-users",
      page: {
        number: page
      },
      sort: SORT_PARAM_TO_REQUEST_PARAM[sortParam as SortParam]
    };

    return requestBody;
  };

  const {
    data: userGroups,
    links,
    isLoading
  } = useQuery<UserGroup[]>(["vendor-user-groups", generateRequestBody()]);

  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (!isLoading) {
      setTotalPages(extractPageNumber(links?.last));
    }
  }, [isLoading]);

  const extractPageNumber = (url?: string): number => {
    if (!url) {
      return 1;
    }

    const urlObject = new URL(url);
    const pageNumber = urlObject.searchParams.get("page[number]");

    return pageNumber ? parseInt(pageNumber, 10) : 1;
  };

  const handleSearch = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchQuery(enteredQuery);
  };

  const handleAction = () => {
    loadPageWithParams({
      search: searchQuery,
      vendorId,
      page: 1,
      sort: sortParam
    });
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAction();
    }
  };

  const setSortParam = useCallback(
    (sort: string) => {
      loadPageWithParams({
        search: searchQuery,
        vendorId,
        page,
        sort
      });
    },
    [searchQuery]
  );

  const setPageParam = useCallback(
    (pageNum: number) => {
      loadPageWithParams({
        search: searchQuery,
        vendorId,
        page: pageNum,
        sort: sortParam
      });
    },
    [page, searchQuery, sortParam]
  );

  const loadPageWithParams = (params: {
    search: string;
    vendorId: string; // eslint-disable-line camelcase
    page: number;
    sort: string;
  }) => {
    const queryString = Object.entries(params).reduce((url, param) => {
      const [key, value] = param;
      if (!value) return url;

      return `${url}${url ? "&" : "?"}${key}=${value}`;
    }, "");

    history.push({
      search: queryString
    });
  };

  const onPageChange = (num: number) => {
    setPageParam(num + 1);
  };

  const ascending = !sortParam.startsWith("-");
  const sortBy = ascending ? sortParam : sortParam.substring(1);
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";

  const updateSortParam = useCallback(
    (newSortBy: string) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as UserGroupSortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  if (
    !isManagerAdmin() &&
    !(isPublisherUser() && isAdminableVendor(vendorId))
  ) {
    return <Redirect to="/" />;
  }

  const nextPage = () => {
    if (page < totalPages) {
      setPageParam(page + 1);
    }
  };

  const previousPage = () => {
    if (page !== 1) {
      setPageParam(page - 1);
    }
  };

  return (
    <main className={styles.container} data-testid="userGroupsPage">
      <section className={styles["user-section"]}>
        <div className={styles.user}>
          <h1 className={styles.heading}>User Groups</h1>
          <div className={styles["buttons-container"]}>
            <FormFieldWithIcon
              placeholder="Search"
              iconClassName="fa-search"
              addonType="append"
              value={searchQuery}
              onChange={handleSearch}
              onIconClick={handleAction}
              onKeyDown={handleSubmit}
            />
            <Button onClick={() => setIsNewUserGroupModalOpen(true)}>
              <FontAwesomeIcon
                iconClassName="fa-plus-circle"
                className={styles["circle-icon"]}
              />
              New User Group
            </Button>
          </div>
          {isNewUserGroupModalOpen && (
            <NewUserGroupModal
              isOpen={isNewUserGroupModalOpen}
              _vendorId={vendorId}
              close={() => setIsNewUserGroupModalOpen(false)}
            />
          )}
        </div>
        <div className={styles["sort-container"]}>
          <button
            className={`${styles["sort-heading"]}
         ${sortBy === "name" ? styles["sorter-active"] : ""}`}
            type="button"
            onClick={() => updateSortParam("name")}
            tabIndex={-1}
          >
            Name
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon-sort"]}
              />
            )}
          </button>
          <button
            className={`${styles["sort-heading"]}
              ${
                sortBy === "total_number_of_users"
                  ? styles["sorter-active"]
                  : ""
              }`}
            type="button"
            onClick={() => updateSortParam("total_number_of_users")}
            tabIndex={-1}
          >
            Number of Users
            {sortBy === "total_number_of_users" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon-sort"]}
              />
            )}
          </button>
          <button
            className={`${styles["sort-heading"]}
          ${sortBy === "created_at" ? styles["sorter-active"] : ""}`}
            type="button"
            onClick={() => updateSortParam("created_at")}
            tabIndex={-1}
          >
            Date
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon-sort"]}
              />
            )}
          </button>
        </div>
        {userGroups && userGroups.length > 0 ? (
          userGroups?.map(userGroup => {
            return (
              <div key={userGroup.id}>
                <UserGroupsTable
                  userGroup={userGroup}
                  isAdmin={!!isManagerAdmin() || !!isPublisherUser()}
                />
              </div>
            );
          })
        ) : (
          <div style={{ padding: "16px 0 40px 0" }}>
            There are no user groups.
          </div>
        )}

        <Pagination
          page={page - 1}
          pages={totalPages}
          pageSize={0}
          pageSizeOptions={[]}
          onPageChange={onPageChange}
          onPageSizeChange={() => null}
          onPageUpdate={() => null}
          nextPage={nextPage}
          previousPage={previousPage}
          showPageSizeDropdown={false}
          showPageDataCount={false}
          totalDataLength={0}
        />
      </section>
    </main>
  );
};

export default UserGroupsPage;
