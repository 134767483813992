import React, { useEffect } from "react";
import { useParams, Link, generatePath } from "react-router-dom";
import { useQuery } from "jsonapi-react";
import { Button, FontAwesomeIcon, FormSelect } from "@nef/core";

import { PATHS } from "../../routes";
import {
  AISerivces,
  AISkillPathParams,
  DatatableCollection
} from "../../api/types";
import { setupSkillUrl } from "../../api/api";

import styles from "./ai-skills-detail.module.scss";

function AISkillsDetail() {
  const { skillId } = useParams<AISkillPathParams>();
  const {
    data: datatableCollection,
    isLoading,
    refetch,
    isFetching
  } = useQuery<DatatableCollection>(["datatable-collections", skillId]);

  const { data: aiServices, isLoading: isLoadingList } = useQuery<AISerivces[]>(
    ["skill/list_services"]
  );

  const dateFormatCa = new Intl.DateTimeFormat("en-US");
  const createdOn = datatableCollection?.createdAt
    ? dateFormatCa.format(new Date(datatableCollection.createdAt))
    : "";
  const updatedOn = datatableCollection?.updatedAt
    ? dateFormatCa.format(new Date(datatableCollection.updatedAt))
    : "";

  const [skillUrl, setSkillUrl] = React.useState<string>("");
  useEffect(() => {
    if (datatableCollection?.skillUrl && !isFetching) {
      setSkillUrl(datatableCollection.skillUrl);
    }
  }, [datatableCollection?.skillUrl, isFetching]);

  const saveSkillUrl = () => {
    if (datatableCollection) {
      setupSkillUrl(datatableCollection.id, skillUrl);
    }
    refetch?.();
  };
  if (isLoading) {
    return null;
  }
  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <Link
          to={generatePath(PATHS.AI_SKILLS)}
          className={styles["back-link"]}
        >
          <FontAwesomeIcon iconClassName="fa-arrow-left" />
          Back to SKILLS
        </Link>
      </header>

      <div className={styles["title-container"]}>
        <div className={styles["header-button"]}>
          <p className={styles.header}>
            {datatableCollection?.name || "AI Skill Name"}
          </p>
        </div>

        <div className={styles["sub-title-container"]}>
          <span>
            <strong>Created On:</strong> {createdOn}
          </span>
          <span>
            <strong>Last Updated:</strong> {updatedOn}
          </span>
        </div>
      </div>
      <div>
        <h3>k8s service routing</h3>
        <FormSelect
          options={aiServices?.map(item => ({
            value: item.url,
            label: item.url
          }))}
          value={skillUrl ? { value: skillUrl, label: skillUrl } : null}
          disabled={
            isLoading || isLoadingList || !!datatableCollection?.skillUrl
          }
          onChange={(formSelectOnChangeParam: any) => {
            setSkillUrl(formSelectOnChangeParam.value.value);
          }}
        />
        {skillUrl !== datatableCollection?.skillUrl && (
          <Button onClick={saveSkillUrl}>Save</Button>
        )}
      </div>
    </div>
  );
}

export default AISkillsDetail;
