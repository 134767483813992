import React, { useState, useMemo, useEffect } from "react";
import {
  Button,
  FontAwesomeIcon,
  FormFieldWithIcon,
  Pagination,
  EmptyState
} from "@nef/core";
import { Loader } from "@nef/icons";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { useQuery } from "jsonapi-react";

import useDefaultAuth from "../../hooks/useDefaultAuth";
import useSearchParams from "../../hooks/useSearchParams";
import type { DatatableCollection } from "../../api/types";

import NewSkillModal from "./components/new-skill-modal";
import SkillTable from "./components/skill-table";
import styles from "./ai-skills-dashboard.module.scss";

const extractPageNumber = (url?: string): number => {
  if (!url) {
    return 1;
  }

  const urlObject = new URL(url);
  const pageNumber = urlObject.searchParams.get("page[number]");

  return pageNumber ? parseInt(pageNumber, 10) : 1;
};

const DEFAULT_PAGE = "1";
const DEFAULT_SORT_BY = "name";
const DEFAULT_SORT_ORDER = "asc";

function AISkillsDashboard() {
  const [isModalOpen, setModalOpen] = useState(false);
  const { authenticatedUser } = useDefaultAuth();
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, _setSearchParams] = useSearchParams({
    page: DEFAULT_PAGE,
    sortBy: DEFAULT_SORT_BY,
    sortOrder: DEFAULT_SORT_ORDER
  });
  const setSearchParams = (params: Record<string, string | string[]>) => {
    const searchParamsObject = Object.fromEntries(searchParams);
    _setSearchParams({ ...searchParamsObject, ...params });
  };

  const paramSearch = searchParams.get("search");
  const paramPage = searchParams.get("page") || DEFAULT_PAGE;
  const paramSortBy = searchParams.get("sortBy") || DEFAULT_SORT_BY;
  const paramSortOrder = searchParams.get("sortOrder") || DEFAULT_SORT_ORDER;

  const sort = useMemo(() => {
    const res = `${paramSortOrder === "asc" ? "" : "-"}${paramSortBy}`;
    if (res.length === 0) {
      return undefined;
    }
    return res;
  }, [paramSortBy, paramSortOrder]);

  const page = useMemo(() => {
    return parseInt(paramPage, 10);
  }, [paramPage]);

  const onPageChange = (num: number) => {
    if (num === 0) {
      setSearchParams({ page: [] });
    } else {
      setSearchParams({ page: `${num + 1}` });
    }
  };
  const nextPage = () => {
    if (page < totalPages) {
      setSearchParams({
        page: `${page + 1}`
      });
    }
  };
  const previousPage = () => {
    if (page === 2) {
      setSearchParams({
        page: []
      });
    } else if (page !== 1) {
      setSearchParams({
        page: `${page - 1}`
      });
    }
  };
  const {
    data: datatableCollections,
    links,
    isLoading
  } = useQuery<DatatableCollection[]>([
    "datatable-collections",
    {
      filter: {
        product_type: "SKILL",
        name: paramSearch || []
      },
      page: {
        number: page
      },
      sort
    }
  ]);
  useEffect(() => {
    if (!isLoading) {
      setTotalPages(extractPageNumber(links?.last));
    }
  }, [isLoading]);

  const [searchString, setSearchString] = useState("");

  const handleSearchInput = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchString(enteredQuery);
  };
  const handleAction = () => {
    setSearchParams({ search: searchString || [], page: [] });
  };
  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAction();
  };

  const updateSortParam = (sortBy: string) => {
    let sortOrder = DEFAULT_SORT_ORDER;
    if (sortBy === paramSortBy) {
      // toggle if sortBy is already selected
      sortOrder = paramSortOrder === "asc" ? "desc" : "asc";
    }

    if (sortBy === DEFAULT_SORT_BY && sortOrder === DEFAULT_SORT_ORDER) {
      setSearchParams({
        sortBy: [],
        sortOrder: [],
        page: []
      });
      return;
    }
    setSearchParams({
      sortBy,
      sortOrder,
      page: []
    });
  };
  return (
    <section className={styles["content-section"]}>
      <div className={styles.header}>
        <div className={styles["heading-container"]}>
          <h1 className={styles.heading} data-testid="ai-skills-title">
            AI Skills
          </h1>
          {isLoading && (
            <span data-testid="skill-loading">
              <Loader />
            </span>
          )}
        </div>
        <div
          className={styles["buttons-container"]}
          data-testid="new-button-container"
        >
          <form onSubmit={handleSubmit}>
            <FormFieldWithIcon
              placeholder="Search"
              iconClassName="fa-search"
              addonType="append"
              value={searchString}
              onChange={handleSearchInput}
              onIconClick={handleAction}
            />
          </form>
          {(authenticatedUser?.rolesArray?.includes("admin") ||
            authenticatedUser?.rolesArray?.includes("manager_admin")) && (
            <Button
              onClick={() => setModalOpen(true)}
              data-testid="new-button"
              disabled={true}
            >
              <FontAwesomeIcon
                iconClassName="fa-plus-circle"
                className={styles["circle-icon"]}
              />
              New Skill
            </Button>
          )}
        </div>
        <NewSkillModal isOpen={isModalOpen} close={() => setModalOpen(false)} />
      </div>

      {!isLoading && datatableCollections?.length === 0 ? (
        <EmptyState icon="sloth" title="No Ai Skills Found">
          No Ai Skills Found
        </EmptyState>
      ) : (
        <>
          <SkillTable
            datatableCollections={datatableCollections}
            sortBy={paramSortBy}
            sortOrder={paramSortOrder}
            updateSortParam={updateSortParam}
          />
          <Pagination
            page={page - 1}
            pages={totalPages}
            pageSize={0}
            pageSizeOptions={[]}
            onPageChange={onPageChange}
            onPageSizeChange={() => null}
            onPageUpdate={() => null}
            nextPage={nextPage}
            previousPage={previousPage}
            showPageSizeDropdown={false}
            showPageDataCount={false}
            totalDataLength={0}
            data-testid="skill_pagination"
          />
        </>
      )}
    </section>
  );
}

export default AISkillsDashboard;
