import {
  FieldError,
  FieldErrors,
  FieldNamesMarkedBoolean,
  FieldValues
} from "react-hook-form";

// `react-hook-form` `useForm` returns associated errors as a nested object of string values.
// This util function traverses the RFH errors and returns a nested array of the error string values.
export const extractErrors = (
  errorValues: (FieldError | FieldErrors)[]
): any[] => {
  return errorValues
    .map(errorValue => {
      if (errorValue === undefined) {
        return [];
      }

      if (Array.isArray(errorValue)) {
        return extractErrors(errorValue);
      }

      if ((errorValue as FieldError).message === undefined) {
        return extractErrors(Object.values(errorValue));
      }

      return errorValue;
    })
    .flat();
};

// https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
// There is a discussion on how to extract dirty values from dirty fields on react-hook-form github.
// But the suggestion there is causing typing issues when extracting nested dirty values. So the
// implementation here is loosely based on the suggestion, but only does not deal with nested values.
export const extractDirtyValues = <K extends FieldValues>(
  dirtyFields: false | FieldNamesMarkedBoolean<K>,
  allValues: K
): Partial<K> => {
  if (!dirtyFields) {
    return allValues;
  }

  const result: any = {};
  Object.keys(dirtyFields).forEach(key => {
    if (!dirtyFields) return;

    // @ts-ignore
    const dirtyValue = dirtyFields[key];
    if (dirtyValue === true) {
      result[key] = allValues[key];
    }
  });

  return result as Partial<K>;
};

export const getIndexIdentifier = (path: string) => {
  const indexRegex = /^\w+\[(\d+)\]/;

  const index = path.match(indexRegex);

  if (!index) return "";

  return (parseInt(index[1], 10) + 1).toString();
};
