import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormField, FormSelect, FormTextArea } from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  UnpackNestedValue,
  useForm
} from "react-hook-form";
import { toast } from "react-toastify";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";

import buildCapProductSchema from "../schema";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { CapProductToCreate } from "../../../api/types";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import { BaseStepProps } from "../types";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import CharacterCountWidget from "../components/CharacterCountWidget";

const PRICING_OPTION_DROPDOWN = ["Pricing Plan", "Contact Sales"].map(
  value => ({
    value,
    label: value
  })
);

const PRODUCT_DESCRIPTION_CHARACTER_LIMIT = 400;
const PRODUCT_NAME_CHARACTER_LIMIT = 64;

const IntakeFormStep1 = ({ fields }: BaseStepProps) => {
  const { product, saveAllData } = useCapProductContext();

  const productSchema = buildCapProductSchema({
    forSaleProduct: product.forSale !== null ? product.forSale : false
  });
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToCreate>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = form;

  const productName = watch("name");
  const productDescription = watch("description");

  const saveStep = async () => {
    await handleSubmit(
      async formData => onHandleSubmit(formData),
      onHandleSubmitError
    )().catch(() => {});
  };

  const onHandleSubmit = async (
    formData: UnpackNestedValue<CapProductToCreate>
  ) => {
    await saveAllData({
      capProduct: formData,
      capMetadata: formData.capProductMetadatum
    });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  const isForSale = product.forSale;

  // TODO: the current version FormField does not support limit the character input
  const handleNameChange = (onChange: any, limit: number) => {
    return (evt: CustomChangeEvent) => {
      if (evt.value) {
        if (evt.value.length <= limit) {
          onChange(evt);
        }
      } else {
        onChange(evt);
      }
    };
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection1Step1">
          <CapSurveyFormFieldRow tooltip={TOOLTIP.PRODUCT_NAME}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="Name"
                  name={name}
                  type="text"
                  label="Product Name"
                  placeholder="Product Name"
                  value={value}
                  onChange={handleNameChange(onChange, 64)}
                  data-testid="capSurveyForm_name"
                />
              )}
            />

            <CharacterCountWidget
              text={productName}
              maxLength={PRODUCT_NAME_CHARACTER_LIMIT}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.DESCRIPTION}>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormTextArea
                  id="Description"
                  name={name}
                  label="Description"
                  placeholder="Description"
                  value={value}
                  onChange={onChange}
                  rows={4}
                  maxLength={PRODUCT_DESCRIPTION_CHARACTER_LIMIT}
                  data-testid="capSurveyForm_description"
                />
              )}
            />

            <CharacterCountWidget
              text={productDescription}
              maxLength={PRODUCT_DESCRIPTION_CHARACTER_LIMIT}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.OVERVIEW}>
            <Controller
              name="overview"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormTextArea
                  id="Overview"
                  name={name}
                  label="Overview"
                  placeholder="Overview"
                  value={value || ""}
                  onChange={onChange}
                  rows={4}
                  data-testid="capSurveyForm_overview"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          {isForSale && (
            <CapSurveyFormFieldRow tooltip={TOOLTIP.PRICING_OPTIONS}>
              <Controller
                name="capProductMetadatum.pricingOption"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormSelect
                      id="pricingOption"
                      name={name}
                      label="Pricing Options"
                      value={
                        PRICING_OPTION_DROPDOWN.find(o => o.value === value) ||
                        null
                      }
                      placeholder="Pricing Options"
                      options={PRICING_OPTION_DROPDOWN}
                      optional={false}
                      isClearable={false}
                      onChange={(formSelectOnChangeParam: any) => {
                        onChange(
                          extractFormSelectOnChangeValue(
                            formSelectOnChangeParam
                          )
                        );
                      }}
                      classNamePrefix="pricingOption"
                    />
                  );
                }}
              />
            </CapSurveyFormFieldRow>
          )}
          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormStep1;
