import React from "react";
import { FormSelectCreatable } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at } from "lodash";

import { formSchema } from "../../../schema";

const TechnicalContacts = ({ formFieldName }: { formFieldName: string }) => {
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext<yup.Asserts<typeof formSchema>>();

  const formFieldErrors = at(errors, formFieldName)[0];
  const formValue = getValues(formFieldName as any);

  const errorMessage =
    Array.isArray(formFieldErrors) && formFieldErrors.length > 0
      ? formFieldErrors.find(error => error && error.value)?.value.message
      : formFieldErrors?.message;

  return (
    <Controller
      key={formValue}
      control={control}
      name={formFieldName as any}
      render={({ field: { onChange, value } }) => (
        <FormSelectCreatable
          placeholder="Select..."
          label="Technical Contact"
          data-testid="OrganizationsAddProductDrawer_technicalContacts"
          className="OrganizationsAddProductDrawer_technicalContacts"
          value={value}
          isMulti
          isClearable
          onChange={e => {
            onChange(e.value);
          }}
          feedback={errorMessage}
          invalid={!!formFieldErrors}
          classNamePrefix="modalFormSelect"
          noOptionsMessage={() => "Start typing to add contacts"}
        />
      )}
    />
  );
};

export default TechnicalContacts;
