import React from "react";
import { Link } from "react-router-dom";

import logo from "../assets/nasdaq-logo.svg";
import { FAQ_URL } from "../appConfig";

import ContactSupportButton from "./contact-support/ContactSupportButton";
import styles from "./Nav.module.scss";

const Nav = () => {
  return (
    <nav className={styles.nav} data-testid="nav">
      <Link className={styles.title} to="/" data-testid="nav_title">
        <img src={logo} className={styles.logo} width="42" alt="Nasdaq" />
        <div className={styles.divider} />
        Data Link Manager
      </Link>

      <ul className={styles.links}>
        <li className={styles.link} data-testid="nav_contactSupport">
          <ContactSupportButton />
        </li>
        <li className={styles.link} data-testid="nav_contactSupport">
          <a href={FAQ_URL} target="_blank" rel="noreferrer">
            FAQ
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
