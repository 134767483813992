import React from "react";

import SftpVariant from "./components/SftpVariant";
import FtpVariant from "./components/FtpVariant";
import S3Variant from "./components/S3Variant";
import OtherVariant from "./components/OtherVariant";
import NdlUploaderVariant from "./components/NdlUploaderVariant";

const IntakeDatatableIngestMethodOptions = ({
  formMethods,
  ingestMethod
}: any) => {
  return (
    <>
      {ingestMethod === "SFTP" && <SftpVariant formMethods={formMethods} />}
      {ingestMethod === "FTP" && <FtpVariant formMethods={formMethods} />}
      {ingestMethod === "S3" && <S3Variant formMethods={formMethods} />}
      {ingestMethod === "NDL Uploader" && <NdlUploaderVariant />}
      {ingestMethod === "Other" && <OtherVariant formMethods={formMethods} />}
    </>
  );
};

export default IntakeDatatableIngestMethodOptions;
