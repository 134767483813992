import React, { useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  useReactTable
} from "@tanstack/react-table";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at } from "lodash";

import { ConfirmationModal } from "../../../../components/modals/ConfirmationModal";

import styles from "./product-access.module.scss";
import AddUserProductAccessModal from "./modal/user";
import { ProductAccessOption } from "./types";
import { formSchema } from "./schema";
import AddUserGroupProductAccessModal from "./modal/user-group";

const columnHelper = createColumnHelper<ProductAccessOption>();

type Props = {
  formFieldName: string;
  confirmToRemove?: boolean;
};

type ProductAccessState = {
  id: string | null;
  type: "User" | "User Group" | null;
};

const ProductAccess = ({ formFieldName, confirmToRemove }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false }
  ]);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isAddUserGroupOpen, setIsAddUserGroupOpen] = useState(false);

  const [productAccessToDelete, setProductAccessToDelete] =
    useState<ProductAccessState>({ id: null, type: null });
  const isDeleteProductAccessOpen = productAccessToDelete.id !== null;

  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext<yup.Asserts<typeof formSchema>>();

  const formValue = watch(formFieldName as any);
  const formFieldErrors = at(errors, formFieldName)[0];

  const columns = [
    columnHelper.accessor("label", {
      id: "name",
      cell: info => (
        <div className={`${styles["name-width"]}`} title={info.getValue()}>
          {info.getValue()}
        </div>
      ),
      header: () => (
        <button
          type="button"
          tabIndex={-1}
          className={`${styles.sorter} ${
            sorting[0]?.id === "name" ? styles["sorter-active"] : ""
          }`}
          onClick={() => handleSort("name")}
          onKeyPress={() => handleSort("name")}
        >
          <span>Name</span>
          {sorting[0]?.id === "name" && (
            <FontAwesomeIcon
              iconClassName={sorting[0].desc ? "fa-caret-down" : "fa-caret-up"}
              className={styles["caret-icon"]}
            />
          )}
        </button>
      )
    }),
    columnHelper.accessor("type", {
      id: "type",
      cell: info => info.getValue(),
      header: () => (
        <button
          type="button"
          tabIndex={-1}
          className={`${styles.sorter} ${
            sorting[0]?.id === "type" ? styles["sorter-active"] : ""
          }`}
          onClick={() => handleSort("type")}
          onKeyPress={() => handleSort("type")}
        >
          <span>Type</span>
          {sorting[0]?.id === "type" && (
            <FontAwesomeIcon
              iconClassName={sorting[0].desc ? "fa-caret-down" : "fa-caret-up"}
              className={styles["caret-icon"]}
            />
          )}
        </button>
      )
    }),
    columnHelper.accessor("label", {
      id: "id",
      cell: info => (
        <Button
          data-testid={info.getValue()}
          outline
          size="sm"
          color="danger"
          className={`${styles["table-delete-button"]} ${styles["admin-action-buttons"]}`}
          onClick={() =>
            confirmToRemove
              ? setProductAccessToDelete({
                  id: info.getValue(),
                  type: info.row.getValue("type")
                })
              : handleRemove(info.getValue(), info.row.getValue("type"))
          }
        >
          Remove
        </Button>
      ),
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: [...(formValue?.users ?? []), ...(formValue?.userGroups ?? [])],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  const handleRemove = (identifier: string, type: "User" | "User Group") => {
    if (type === "User") {
      const setUserValue = {
        userGroups: formValue.userGroups ?? [],
        users:
          formValue.users.filter(
            (user: ProductAccessOption) => user.label !== identifier
          ) ?? []
      };

      setValue(formFieldName as any, setUserValue);
    }
    if (type === "User Group") {
      const setUserGroupsValue = {
        users: formValue.users ?? [],
        userGroups:
          formValue.userGroups.filter(
            (group: ProductAccessOption) => group.label !== identifier
          ) ?? []
      };

      setValue(formFieldName as any, setUserGroupsValue);
    }
  };

  const handleSort = (columnId: string) => {
    const isDesc = sorting[0]?.id === columnId && !sorting[0]?.desc;

    setSorting([{ id: columnId, desc: isDesc }]);
  };

  return (
    <Controller
      data-testid="OrganizationsProductAccess"
      name={formFieldName as any}
      control={control}
      render={({ field: { onChange } }) => (
        <>
          <div className={styles["header-container"]}>
            <h4>
              <span className={styles["required-star"]}>*</span>Product Access
            </h4>
            <div className={styles["header-buttons-container"]}>
              <Button outline size="sm" onClick={() => setIsAddUserOpen(true)}>
                Add User
              </Button>
              {isAddUserOpen && (
                <AddUserProductAccessModal
                  existingUsers={formValue?.users || []}
                  close={() => setIsAddUserOpen(false)}
                  onSubmit={newValues =>
                    onChange({ ...formValue, users: newValues })
                  }
                />
              )}
              <Button
                outline
                size="sm"
                onClick={() => setIsAddUserGroupOpen(true)}
              >
                Add User Group
              </Button>
              {isAddUserGroupOpen && (
                <AddUserGroupProductAccessModal
                  existingUserGroups={formValue?.userGroups || []}
                  close={() => setIsAddUserGroupOpen(false)}
                  onSubmit={newValues =>
                    onChange({ ...formValue, userGroups: newValues })
                  }
                />
              )}
            </div>
          </div>
          <div
            className={`${styles["table-container"]} ${
              formFieldErrors ? styles.error : ""
            }`}
          >
            <table>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={
                          header.column.getIsSorted() ? styles.selected : ""
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {formFieldErrors && (
            <small className={styles["error-message"]}>
              {formFieldErrors.message}
            </small>
          )}

          {isDeleteProductAccessOpen && (
            <ConfirmationModal
              isOpen={isDeleteProductAccessOpen}
              title="Remove Product Access"
              confirmText="Remove Access"
              dismissText="Cancel"
              question={`Are you sure you want to remove product access for ${productAccessToDelete.id}?`}
              onConfirm={() => {
                handleRemove(
                  productAccessToDelete.id as string,
                  productAccessToDelete.type as "User" | "User Group"
                );
                setProductAccessToDelete({ id: null, type: null });
              }}
              danger
              onDismiss={() => {
                setProductAccessToDelete({ id: null, type: null });
              }}
            />
          )}
        </>
      )}
    />
  );
};

export default ProductAccess;
