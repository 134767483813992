import React from "react";
import ReactTooltip from "react-tooltip";
import { first } from "lodash";
import "./style.scss";

function findCheapestPlan(plans: any[]) {
  return first(
    plans
      ?.filter((plan: any) => plan.allowPayment)
      ?.sort((a, b) => a.amountCents - b.amountCents)
  );
}

type Props = {
  plans: any[];
};

const ProductPricing: React.FC<Props> = ({ plans }) => {
  const cheapestPlan = findCheapestPlan(plans);

  return (
    <section className="product-pricing-section">
      <div className="personal-pricing-section">
        <div className="personal-pricing-container">
          <h4 className="personal-pricing-heading">From</h4>
          <p
            className="personal-pricing-description"
            data-testid="productPricing_plan"
          >
            <b>
              {cheapestPlan?.currencySymbol ?? "$"}
              {cheapestPlan?.amountCents ?? 0}
            </b>
            /{cheapestPlan?.interval ?? "month"}
          </p>
          <span data-tip="Pricing information will be shown when the page is published. If you'd like to make edits please navigate to the Pricing tab.">
            <button type="button" className="personal-pricing-button" disabled>
              View Pricing Options
            </button>
          </span>
          <ReactTooltip place="top" type="dark" effect="solid" />
          <div className="personal-pricing-sub-heading">
            View License Information
            <svg
              width="54px"
              height="5px"
              viewBox="0 0 54 5"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              className="product-information__arrow"
            >
              <g
                id="Artboard"
                transform="translate(-101.000000, -72.000000)"
                fill="#000000"
                fillRule="nonzero"
              >
                <path
                  fill="#00000"
                  d="M127.999916,47.5 L130.5,58.2229161 L128.625,58.222 L128.625,101.5 L127.375,101.5 L127.375,58.222 L125.5,58.2229161 L127.999916,47.5 Z"
                  id="Right---Long-Arrow-White"
                  transform="translate(128.000000, 74.500000) rotate(90.000000) translate(-128.000000, -74.500000) "
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductPricing;
