import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormField,
  FormSelect,
  Modal,
  ModalBody,
  ModalFooter
} from "@nef/core";
import styled from "styled-components";
import { StyledFormInput } from "@nef/core/lib/components/FormInput";
import {
  FieldArrayWithId,
  FormProvider,
  SubmitErrorHandler,
  useFieldArray,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { DatatableColumn, DefaultFilter } from "../../../api/types";
import { ConfirmationModal } from "../../modals/ConfirmationModal";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";

import styles from "./ColumnFiltersModal.module.scss";
import DefaultFilterInput from "./DefaultFilterInput";
import columnFiltersSchema from "./columnFiltersSchema";

interface ColumnFiltersModalProps {
  isOpen: boolean;
  index: number;
  column: DatatableColumn;
  close: () => void;
  save: (newDefaultFilters: DefaultFilter[]) => void;
}

const FormFieldForGrid = styled(FormField)`
  margin-bottom: 0 !important;

  /* The design has FormField and FormSelect on the same column, but they have different default
  left/right paddings. The override here makes the paddings more consistent*/
  ${StyledFormInput} {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
`;

const FormSelectForGrid = styled(FormSelect)`
  margin-bottom: 0 !important;
`;

const ColumnFiltersModal = ({
  isOpen,
  index,
  column,
  save,
  close
}: ColumnFiltersModalProps) => {
  const [defaultFilterReadyForDeletion, setDefaultFilterReadyForDeletion] =
    useState<FieldArrayWithId<
      DatatableColumn,
      "defaultFilters",
      "id"
    > | null>();
  const [showDirtyDismissMessage, setShowDirtyDismissMessage] = useState(false);

  const initialDefaultFilterIds = useRef<string[]>([]);

  const transformValueToString = (
    rawValue: null | string | string[] | number | number[]
  ) => {
    if (Array.isArray(rawValue)) {
      return rawValue.join(",");
    }

    if (typeof rawValue === "number") {
      return String(rawValue);
    }

    if (column.baseType === "datetime" && !!rawValue) {
      return format(parseISO(rawValue), "yyyy-MM-dd'T'HH:mm");
    }

    return rawValue || "";
  };

  const transformColumnForForm = (col: DatatableColumn) => {
    const defaultFilters = (col.defaultFilters || []).map(filter => {
      return {
        ...filter,
        value: transformValueToString(filter.value)
      };
    });

    return {
      ...column,
      defaultFilters
    };
  };

  const methods = useForm<DatatableColumn>({
    defaultValues: transformColumnForForm(column),
    resolver: yupResolver(columnFiltersSchema)
  });
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty }
  } = methods;

  const {
    fields: defaultFilters,
    append: appendFieldArrayFilter,
    remove: removeFieldArrayFilter
  } = useFieldArray({
    control,
    name: "defaultFilters"
  });

  useEffect(() => {
    initialDefaultFilterIds.current = defaultFilters.map(filter => filter.id);
  }, []);

  const addDefaultFilter = () => {
    const newDefaultFilter = {
      key: column.name,
      operator: null,
      value: ""
    };

    appendFieldArrayFilter(newDefaultFilter);
  };

  const tryRemoveDefaultFilter = (
    defaultFilter: FieldArrayWithId<DatatableColumn, "defaultFilters", "id">
  ) => {
    if (initialDefaultFilterIds.current.includes(defaultFilter.id)) {
      setDefaultFilterReadyForDeletion(defaultFilter);
    } else {
      removeDefaultFilter(defaultFilter);
    }
  };

  const removeDefaultFilter = (
    defaultFilterToRemove: FieldArrayWithId<
      DatatableColumn,
      "defaultFilters",
      "id"
    >
  ) => {
    const removeIndex = defaultFilters.findIndex(
      defaultFilter => defaultFilter.id === defaultFilterToRemove.id
    );
    removeFieldArrayFilter(removeIndex);
    setDefaultFilterReadyForDeletion(null);
  };

  const tryCloseModal = () => {
    if (isDirty) {
      setShowDirtyDismissMessage(true);
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    setShowDirtyDismissMessage(false);
    reset(transformColumnForForm(column));
    close();
  };

  const submitDefaultFilters = (data: any) => {
    save(data.defaultFilters);
  };

  const submitDefaultFiltersError: SubmitErrorHandler<
    DatatableColumn
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Filters cannot be saved"
        details={errorDetails}
      />
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={close}
        size="lg"
        data-testid="columnFiltersModal"
        closeOnOutsideClick={false}
      >
        <ModalBody>
          <div className={styles["content-grid"]}>
            <div className={`${styles.row} ${styles.header}`}>
              <div
                className={`${styles.column} ${styles["column--align-center"]}`}
                data-testid="columnFiltersModal_columnNumber"
              >
                {index + 1}
              </div>
              <div className={styles.column}>
                <FormFieldForGrid
                  name="columnName"
                  type="text"
                  value={column.name}
                  disabled={true}
                  data-testid="columnFiltersModal_columnName"
                />
              </div>
              <div className={styles.column}>
                <FormSelectForGrid
                  name="columnBaseType"
                  disabled={true}
                  value={{ value: column.baseType, label: column.baseType }}
                  classNamePrefix="baseType"
                />
              </div>
              <div
                className={`${styles.column} ${styles["column--justify-end"]}`}
              >
                <Button
                  color="secondary"
                  data-testid="columnFiltersModal_addFilter"
                  onClick={addDefaultFilter}
                >
                  Add Filter
                </Button>
              </div>
            </div>

            <FormProvider {...methods}>
              {defaultFilters.map((filter, filterIndex) => {
                return (
                  <DefaultFilterInput
                    filter={filter}
                    index={filterIndex}
                    tryRemoveDefaultFilter={tryRemoveDefaultFilter}
                    key={filter.id}
                  />
                );
              })}
            </FormProvider>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            size="md"
            color="light"
            onClick={tryCloseModal}
            data-testid="columnFiltersModal_close"
          >
            Close
          </Button>

          <Button
            size="md"
            onClick={handleSubmit(
              submitDefaultFilters,
              submitDefaultFiltersError
            )}
            data-testid="columnFiltersModal_save"
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmationModal
        isOpen={!!defaultFilterReadyForDeletion}
        question="Are you sure you want to remove this existing default filter?"
        onConfirm={() =>
          removeDefaultFilter(
            defaultFilterReadyForDeletion as FieldArrayWithId<
              DatatableColumn,
              "defaultFilters",
              "id"
            >
          )
        }
        onDismiss={() => setDefaultFilterReadyForDeletion(null)}
      />
      <ConfirmationModal
        isOpen={showDirtyDismissMessage}
        question="You will discard any unsaved changes made to the column filters. Are you sure?"
        onConfirm={closeModal}
        onDismiss={() => setShowDirtyDismissMessage(false)}
      />
    </>
  );
};

export default ColumnFiltersModal;
