import { Switch } from "@nef/core";
import styled from "styled-components";

const Toggle = styled(Switch)`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0 !important;

  > div {
    margin-right: 0;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    width: 40px;
  }
`;

export default Toggle;
