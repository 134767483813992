import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { useQuery } from "jsonapi-react";
import { toast } from "react-toastify";

import { OrganizationTeam, OrganizationUser } from "../../../../api/types";
import { updateUserAccess, updateUserGroupAccess } from "../../../../api/api";
import Toast from "../../../../components/Toast";
import { ConfirmationModal } from "../../../../components/modals/ConfirmationModal";

import styles from "./index.module.scss";

interface Props {
  table: "usersTable" | "userGroupsTable";
  organizationUserId?: string;
  setProductToEdit?: any;
  organizationUserGroupId?: string;
  handleClose: () => void;
  refetch: () => void;
}

type ProductAccessState = {
  id: string | null;
  productName: string | null;
};

const useCustomProductsQuery = (
  table: "usersTable" | "userGroupsTable",
  userId: string,
  userGroupId: string
) => {
  let endpoint = "";
  if (table === "usersTable") {
    endpoint = `organization-users/${userId}`;
  } else if (table === "userGroupsTable") {
    endpoint = `organization-teams/${userGroupId}`;
  }

  const { data, isLoading } = useQuery<OrganizationUser | OrganizationTeam>(
    endpoint
  );
  return { data, isLoading };
};

const UserProductAccessModal = ({
  table,
  organizationUserId,
  organizationUserGroupId,
  setProductToEdit,
  handleClose,
  refetch
}: Props) => {
  const [selectedProducts, setSelectedProducts] = useState<
    ProductAccessState[]
  >([]);
  const [hasProductChanges, setHasProductChanges] = useState(false);
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] =
    useState(false);

  const [userProductAccessToDelete, setUserProductAccessToDelete] =
    useState<ProductAccessState>({ id: null, productName: null });
  const isDeleteUserProductAccessOpen = userProductAccessToDelete.id !== null;

  const { data: invoiceData, isLoading } = useCustomProductsQuery(
    table,
    organizationUserId as string,
    organizationUserGroupId as string
  );

  useEffect(() => {
    if (invoiceData) {
      setSelectedProducts(invoiceData?.productAccess as ProductAccessState[]);
    }
  }, [invoiceData]);

  const handleRemoveUser = (productId: string) => {
    const updatedUsers = selectedProducts.filter(
      product => product.id !== productId
    );
    setSelectedProducts(updatedUsers);
    setHasProductChanges(true);
  };

  const handleUpdateProductAccess = async () => {
    const productAccess = selectedProducts.map(product => product.id);

    const response =
      table === "usersTable"
        ? await updateUserAccess(
            { product_access: productAccess },
            organizationUserId as string
          )
        : await updateUserGroupAccess(
            { product_access: productAccess },
            organizationUserGroupId as string
          );

    if (response.status !== 200) {
      const { error, errors } = response.data;
      const responseErrors = error ? [error] : errors;

      if (responseErrors) {
        toast(
          <Toast
            type="error"
            title="There was an error updating product access"
            details={[]}
          />
        );
        handleClose();
        return;
      }
    }
    toast(
      <Toast
        type="success"
        title="Product access has been successfully updated"
        details={[]}
      />
    );
    handleClose();
    refetch();
  };

  const handleCancel = () => {
    if (hasProductChanges) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      {!isLoading && (
        <Modal
          toggle={handleClose}
          isOpen
          closeOnOutsideClick={false}
          data-testid="OrganizationsUserProductAccessModal"
        >
          <>
            <ModalHeader
              className={styles.header}
              toggle={handleCancel}
              data-testid="OrganizationsUserProductAccessModal_header"
            >
              Product Access
            </ModalHeader>
            <ModalBody className={styles.body}>
              <div
                className={styles.table}
                data-testid="OrganizationsUserProductAccessModal_formContainer"
              >
                {selectedProducts.length > 0 ? (
                  selectedProducts?.map(product => {
                    return (
                      <div
                        data-testid={product.productName}
                        key={product.id}
                        className={styles.row}
                      >
                        <div className={styles.name}>{product.productName}</div>
                        <div className={styles["buttons-container"]}>
                          <Button
                            outline
                            color="primary"
                            size="sm"
                            className={styles.buttons}
                            data-testid={`OrganizationsUserProductAccessModal_editAccess${product.id}`}
                            onClick={() => {
                              setProductToEdit(product);
                              handleClose();
                            }}
                          >
                            Edit
                          </Button>

                          <Button
                            outline
                            color="danger"
                            size="sm"
                            className={styles.buttons}
                            data-testid={`OrganizationsUserProductAccessModal_removeAccess${product.id}`}
                            onClick={() => {
                              setUserProductAccessToDelete({
                                id: product.id,
                                productName: product.productName
                              });
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>

              <div className={styles["footer-buttons-container"]}>
                <Button
                  onClick={handleUpdateProductAccess}
                  data-testid="OrganizationsUserProductAccessModal_submit"
                >
                  Save
                </Button>
                <Button
                  data-testid="OrganizationsUserProductAccessModal_cancel"
                  color="light"
                  outline
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </>
        </Modal>
      )}
      {isDiscardChangesModalOpen && (
        <ConfirmationModal
          isOpen={isDiscardChangesModalOpen}
          title="Save Changes"
          confirmText="Yes"
          dismissText="No"
          question="Would you like to discard changes?"
          onConfirm={() => {
            setIsDiscardChangesModalOpen(false);
            handleClose();
          }}
          onDismiss={() => {
            setIsDiscardChangesModalOpen(false);
          }}
          danger
        />
      )}

      {isDeleteUserProductAccessOpen && (
        <ConfirmationModal
          isOpen={isDeleteUserProductAccessOpen}
          title="Remove Product Access"
          confirmText="Remove Access"
          dismissText="Cancel"
          question={`Are you sure you want to remove product access for ${userProductAccessToDelete.productName}?`}
          onConfirm={() => {
            handleRemoveUser(userProductAccessToDelete.id as string);
            setUserProductAccessToDelete({ id: null, productName: null });
          }}
          danger
          onDismiss={() => {
            setUserProductAccessToDelete({ id: null, productName: null });
          }}
        />
      )}
    </>
  );
};

export default UserProductAccessModal;
