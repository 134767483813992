import React, { useState } from "react";
import { FormField, FormSelect, FontAwesomeIcon } from "@nef/core";
import styled from "styled-components";
import { Controller, FieldArrayWithId, useFormContext } from "react-hook-form";

import {
  DatatableColumn,
  DatatableColumnBaseTypeOption,
  DatatableSchema,
  FilterableColumnValues
} from "../../api/types";
import MoreInfoTooltip from "../MoreInfoTooltip";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import LabelValueWithHint from "../common/LabelValueWithHint";

import styles from "./DatatableSchemaForm.module.scss";
import {
  SCHEMA_COLUMN_ORDER_INFO,
  SCHEMA_DATE_FORMAT_INFO,
  SCHEMA_DATETIME_FORMAT_INFO,
  SCHEMA_PRECISION_INFO,
  SCHEMA_SCALE_INFO,
  SCHEMA_INDEX_INFO,
  SCHEMA_PRIMARY_KEY_INFO,
  SCHEMA_SAMPLE_COLUMN_INFO
} from "./hints";
import ColumnIndexKeyToggle from "./components/ColumnIndexKeyToggle";
import Toggle from "./components/Toggle";
import PartitionSortButton from "./components/PartitionSortButton";
import PartitionListener from "./components/PartitionListener";

const COLUMN_TYPES = [
  "string",
  "date",
  "integer",
  "datetime",
  "double",
  "decimal"
];

const FormFieldNoMargin = styled(FormField)`
  margin-bottom: 0 !important;
`;

const FormSelectNoMargin = styled(FormSelect)`
  margin-bottom: 0 !important;
`;

const DateFormatFormField = styled(FormSelect)`
  margin-bottom: 0 !important;
  label {
    font-size: 0.7rem !important;
    margin-bottom: 0 !important;
  }
`;

const PrecisionScaleFormField = styled(FormField)`
  margin-bottom: 0 !important;
  label {
    font-size: 0.7rem !important;
    margin-bottom: 0 !important;
  }
`;

const DATE_FORMAT_OPTIONS = [
  { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
  { label: "YYYY-MM-DD HH:MM:SS", value: "YYYY-MM-DD HH:MM:SS" }
];

const COLUMN_TYPE_OPTIONS = COLUMN_TYPES.map(type => {
  return { value: type, label: type };
});

interface DatatableSchemaFormProps {
  datatableIsPublished: boolean;
  tryRemoveColumn: (
    column: FieldArrayWithId<DatatableSchema, "columns", "id">
  ) => void;
  updateColumn: (
    index: number,
    newAttributes: Partial<DatatableColumn>
  ) => void;
  fields: FieldArrayWithId<DatatableSchema, "columns", "id">[];
  currentFilter: FilterableColumnValues;
  backend: "postgres" | "s3";
}

interface BaseTypeUpdateData {
  index: number;
  newType: DatatableColumnBaseTypeOption;
  oldType: DatatableColumnBaseTypeOption;
}

const DatatableSchemaForm = ({
  datatableIsPublished,
  tryRemoveColumn,
  updateColumn,
  fields,
  currentFilter,
  backend
}: DatatableSchemaFormProps) => {
  const [baseTypeUpdateData, setBaseTypeUpdateData] =
    useState<BaseTypeUpdateData>();

  const { control } = useFormContext();

  const tryUpdateBaseType = (
    index: number,
    newType: DatatableColumnBaseTypeOption,
    oldType: DatatableColumnBaseTypeOption
  ) => {
    // It's possible for an index column with default filters to not be indexed anymore during
    // an edit session. The if condition here accounts for this temporary state edge case.
    if (fields[index].defaultFilters?.length) {
      setBaseTypeUpdateData({ index, newType, oldType });
    } else {
      updateBaseType(index, newType, false);
    }
  };

  const abortUpdateBaseType = () => {
    if (!baseTypeUpdateData) return;

    const { index, oldType } = baseTypeUpdateData;
    updateBaseType(index, oldType, false);
    setBaseTypeUpdateData(undefined);
  };

  const updateBaseType = (
    index: number,
    newType: DatatableColumnBaseTypeOption,
    shouldClearColumnFilters: boolean
  ) => {
    setBaseTypeUpdateData(undefined);

    const newColumn: Partial<DatatableColumn> = {
      precision: null,
      scale: null,
      dateFormat: null,
      baseType: newType
    };

    if (newType === "date") {
      newColumn.dateFormat = "YYYY-MM-DD";
    }

    if (newType === "datetime") {
      newColumn.dateFormat = "YYYY-MM-DD HH:MM:SS";
    }

    if (newType === "decimal") {
      newColumn.precision = 1;
      newColumn.scale = 0;
    }

    if (shouldClearColumnFilters) {
      newColumn.defaultFilters = [];
    }

    updateColumn(index, newColumn);
  };

  const updateToggle = (index: number, field: string, checked: boolean) => {
    updateColumn(index, { [field]: checked } as Partial<DatatableColumn>);
  };

  const shouldShowColumn = (
    column: FieldArrayWithId<DatatableSchema, "columns", "id">
  ) => {
    if (!currentFilter) return true;

    return column[currentFilter];
  };

  return (
    <div
      className={`${styles["schema-form"]} ${
        datatableIsPublished ? styles["reduced-columns"] : ""
      } ${backend === "s3" ? styles["s3-form"] : ""}`}
    >
      <div className={styles["columns-header"]}>
        <div className={`${styles.column} ${styles["text-uppercase"]}`}>
          Column Order{" "}
          <MoreInfoTooltip description={SCHEMA_COLUMN_ORDER_INFO} />
        </div>
        <div className={`${styles.column} ${styles["text-uppercase"]}`}>
          Column Name
        </div>
        <div className={`${styles.column} ${styles["text-uppercase"]}`}>
          Data Type
        </div>
        <div className={styles.column}>&nbsp;</div>
        <div className={`${styles.column} ${styles["text-center"]}`}>
          Allow sample data filtering
          <span className={styles.tooltip}>
            <MoreInfoTooltip description={SCHEMA_INDEX_INFO} />
          </span>
        </div>
        {backend === "s3" && (
          <div className={`${styles.column} ${styles["text-center"]}`}>
            Partitions
            <Controller
              control={control}
              name="partitions"
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <PartitionListener fields={fields} />
                    <PartitionSortButton
                      value={value}
                      setValue={v => {
                        onChange(v);
                      }}
                      disabled={datatableIsPublished}
                    />
                  </>
                );
              }}
            />
          </div>
        )}
        <div className={`${styles.column} ${styles["text-center"]}`}>
          Primary Key
          <span className={styles.tooltip}>
            <MoreInfoTooltip description={SCHEMA_PRIMARY_KEY_INFO} />
          </span>
        </div>
        <div className={`${styles.column} ${styles["text-center"]}`}>
          Display as Sample Column
          <span className={styles.tooltip}>
            <MoreInfoTooltip description={SCHEMA_SAMPLE_COLUMN_INFO} />
          </span>
        </div>
        {!datatableIsPublished && (
          <div className={`${styles.column} ${styles["text-center"]}`}>
            Remove
          </div>
        )}
      </div>
      <div className={styles.rows} data-testid="datatableSchemaForm_form">
        {fields.map(
          (
            column: FieldArrayWithId<DatatableSchema, "columns", "id">,
            index: number
          ) => {
            return (
              shouldShowColumn(column) && (
                <div
                  className={styles.row}
                  key={column.id}
                  data-testid="datatableSchemaForm_columnRow"
                >
                  <div className={styles.column}>{index + 1}</div>
                  <div className={styles.column}>
                    <Controller
                      control={control}
                      name={`columns.${index}.name`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <FormFieldNoMargin
                            id={`columnName-${column.id}`}
                            name={name}
                            type="text"
                            value={value}
                            disabled={datatableIsPublished}
                            onChange={(e: any) =>
                              onChange(e.value.toLowerCase())
                            }
                            data-testid="datatableSchemaForm_name"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={styles.column}>
                    <Controller
                      control={control}
                      name={`columns.${index}.baseType`}
                      render={({ field: { value, name, ref } }) => {
                        return (
                          <FormSelectNoMargin
                            id={`baseType-${column.id}`}
                            name={name}
                            value={COLUMN_TYPE_OPTIONS.find(
                              v => v.value === value
                            )}
                            ref={ref}
                            disabled={datatableIsPublished}
                            options={COLUMN_TYPE_OPTIONS}
                            onChange={(e: any) => {
                              tryUpdateBaseType(index, e.value.value, value);
                            }}
                            classNamePrefix="baseType"
                          />
                        );
                      }}
                    />
                  </div>

                  <div className={styles.column}>
                    {(column.baseType === "date" ||
                      column.baseType === "datetime") && (
                      <div className={styles["dateformat-controls"]}>
                        <Controller
                          control={control}
                          name={`columns.${index}.dateFormat`}
                          render={({ field: { onChange, value, name } }) => {
                            const tooltipHint =
                              column.baseType === "datetime"
                                ? SCHEMA_DATETIME_FORMAT_INFO
                                : SCHEMA_DATE_FORMAT_INFO;

                            return (
                              <DateFormatFormField
                                id={`dateFormat-${column.id}`}
                                name={name}
                                options={DATE_FORMAT_OPTIONS}
                                value={DATE_FORMAT_OPTIONS.find(
                                  d => d.value === value
                                )}
                                label={
                                  (
                                    <LabelValueWithHint
                                      label="Date Format"
                                      hint={tooltipHint}
                                    />
                                  ) as any
                                }
                                disabled={true}
                                optional={false}
                                onChange={(e: any) => onChange(e.value.value)}
                                classNamePrefix="dateFormat"
                              />
                            );
                          }}
                        />
                      </div>
                    )}
                    {column.baseType === "decimal" && (
                      <div className={styles["decimal-controls"]}>
                        <Controller
                          control={control}
                          name={`columns.${index}.precision`}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <PrecisionScaleFormField
                                id={`precision-${column.id}`}
                                name={name}
                                type="number"
                                min={1}
                                placeholder="Precision"
                                label={
                                  (
                                    <LabelValueWithHint
                                      label="Precision"
                                      hint={SCHEMA_PRECISION_INFO}
                                    />
                                  ) as any
                                }
                                disabled={datatableIsPublished}
                                optional={false}
                                value={value}
                                onChange={onChange}
                                data-testid="datatableSchemaForm_precision"
                              />
                            );
                          }}
                        />
                        <Controller
                          control={control}
                          name={`columns.${index}.scale`}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <PrecisionScaleFormField
                                id={`scale-${column.id}`}
                                name={name}
                                type="number"
                                min={0}
                                placeholder="Scale"
                                label={
                                  (
                                    <LabelValueWithHint
                                      label="Scale"
                                      hint={SCHEMA_SCALE_INFO}
                                    />
                                  ) as any
                                }
                                disabled={datatableIsPublished}
                                optional={false}
                                value={value}
                                onChange={onChange}
                                data-testid="datatableSchemaForm_scale"
                              />
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className={`${styles.column} ${styles["text-center"]}`}>
                    <ColumnIndexKeyToggle
                      index={index}
                      datatableIsPublished={datatableIsPublished}
                      updateToggle={updateToggle}
                      updateColumn={updateColumn}
                    />
                  </div>
                  {backend === "s3" && (
                    <div
                      className={`${styles.column} ${styles["text-center"]}`}
                    >
                      <Controller
                        control={control}
                        name={`columns.${index}.isPartitioned`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Toggle
                              name={name}
                              checked={value}
                              data-checked={value}
                              showText={false}
                              disabled={
                                datatableIsPublished ||
                                column.baseType === "double" ||
                                column.baseType === "decimal"
                              }
                              data-disabled={
                                datatableIsPublished ||
                                column.baseType === "double" ||
                                column.baseType === "decimal"
                              }
                              onChange={e => onChange(e.checked)}
                              data-testid="datatableSchemaForm_partition"
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className={`${styles.column} ${styles["text-center"]}`}>
                    <Controller
                      control={control}
                      name={`columns.${index}.isPrimaryKey`}
                      render={({ field: { value, name } }) => {
                        return (
                          <Toggle
                            name={name}
                            checked={value}
                            data-checked={value}
                            showText={false}
                            disabled={datatableIsPublished}
                            data-disabled={datatableIsPublished}
                            onChange={e =>
                              updateToggle(index, "isPrimaryKey", e.checked)
                            }
                            data-testid="datatableSchemaForm_primaryKey"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={`${styles.column} ${styles["text-center"]}`}>
                    <Controller
                      control={control}
                      name={`columns.${index}.isSampleColumn`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <Toggle
                            name={name}
                            checked={value}
                            data-checked={value}
                            showText={false}
                            onChange={e => onChange(e.checked)}
                            data-testid="datatableSchemaForm_sampleColumn"
                          />
                        );
                      }}
                    />
                  </div>
                  {!datatableIsPublished && (
                    <button
                      type="button"
                      className={`${styles.column} ${styles["remove-column"]} ${styles["text-center"]}`}
                      onClick={() => tryRemoveColumn(column)}
                      data-testid="datatableSchemaForm_removeColumn"
                    >
                      <FontAwesomeIcon
                        iconSetClassName="far"
                        iconClassName="fa-trash-alt"
                      />
                    </button>
                  )}
                </div>
              )
            );
          }
        )}
      </div>
      {!!baseTypeUpdateData && (
        <ConfirmationModal
          isOpen={true}
          question="Changing column type will clear sample filters associated with this column. Are you sure?"
          onConfirm={() =>
            updateBaseType(
              baseTypeUpdateData.index,
              baseTypeUpdateData.newType,
              true
            )
          }
          onDismiss={() => abortUpdateBaseType()}
        />
      )}
    </div>
  );
};

export default DatatableSchemaForm;
