import React, { useEffect, useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  generatePath,
  Link,
  useParams,
  Prompt,
  Redirect
} from "react-router-dom";
import { useMutation, useQuery } from "jsonapi-react";
import { toast } from "react-toastify";
import * as yup from "yup";
import isEqual from "lodash/isEqual";

import { PATHS } from "../../routes";
import manageClient from "../../api/manage/client";
import Toast from "../../components/Toast";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import { Vendor } from "../../api/types";
import useQueryParam from "../../hooks/useQueryParam";

import EditVendorTextModal from "./components/editVendorTextModal";
import styles from "./edit-vendor.module.scss";
import AdminTable from "./admins-table";
import ProductsTable from "./products-table";
import UsersTable from "./users-table";
import EditVendorLogoModal from "./components/editVendorLogoModal";

const nameValidationSchema = yup.object({
  name: yup
    .string()
    .required("Publisher name is a required field")
    .min(2, "Publisher name needs to be at least 2 characters")
});

const EditVendorPage = () => {
  const queryParams = useQueryParam();
  const { publisherId: vendorId } = useParams<{ publisherId: string }>();
  const [activeTab, setActiveTab] = useState<"products" | "users">(
    queryParams.get("tab") === "users" ? "users" : "products"
  );
  const [initialVendorData, setInitialVendorData] = useState<Vendor>();

  const [vendorData, setVendorData] = useState<Vendor>({
    id: "",
    name: "Publisher Name",
    description: "Publisher Description",
    createdAt: "",
    vendorUsersLastLoggedInAt: "",
    code: ""
  });
  const [logoPreviewFile, setLogoPreviewFile] = useState<
    string | ArrayBuffer | null
  >();
  const [isEditDescriptionOpen, setIsEditDescriptionOpen] = useState(false);
  const [isVendorNameOpen, setIsVendorNameOpen] = useState(false);
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  const [editVendor] = useMutation<Vendor>(["vendors", vendorId], {
    method: "PATCH",
    client: manageClient
  });

  const { data, isLoading: isVendorLoading } = useQuery<Vendor>(
    ["vendors", vendorId],
    { client: manageClient }
  );

  const {
    isManagerAdmin,
    isPublisherUser,
    isAdminableVendor,
    isManageableVendor
  } = useDefaultAuth();

  useEffect(() => {
    if (!isVendorLoading && data) {
      setVendorData(data);
      setInitialVendorData(data);
    }
  }, [isVendorLoading]);

  const submitForm = async () => {
    const response = await editVendor({
      name: vendorData.name,
      description: vendorData.description,
      "profile-image": logoPreviewFile
    });

    const { data: respData, error } = response;

    if (error) {
      toast(
        <Toast
          type="error"
          title="There was an error updating the publisher"
          details={[]}
        />
      );

      return;
    }
    setLogoPreviewFile(undefined);
    toast(
      <Toast
        type="success"
        title="Publisher has been successfully updated"
        details={[]}
      />
    );

    if (respData) {
      setVendorData(respData);
      setInitialVendorData(respData);
    }
  };

  const handleSetImage = (file?: string | ArrayBuffer | null) => {
    if (file) {
      setLogoPreviewFile(file);
    }
  };

  const dateFormatCa = new Intl.DateTimeFormat("en-US");
  const createdOn = vendorData.createdAt
    ? dateFormatCa.format(new Date(vendorData.createdAt))
    : "";

  const updatedAtDate = new Date(
    vendorData.vendorUsersLastLoggedInAt || Date.now()
  );
  const lastUsed = vendorData.vendorUsersLastLoggedInAt
    ? `${dateFormatCa.format(
        updatedAtDate
      )} at ${updatedAtDate.toLocaleTimeString("en-US")}`
    : "";

  const hasEditAccess = isManagerAdmin() || isAdminableVendor(vendorId);

  if (
    !isManagerAdmin() &&
    !(isPublisherUser() && isAdminableVendor(vendorId)) &&
    !(isPublisherUser() && isManageableVendor(vendorId))
  ) {
    return <Redirect to="/" />;
  }

  const confirmNavigation = () => {
    if (
      !isEqual(initialVendorData, vendorData) ||
      (logoPreviewFile &&
        !isEqual(initialVendorData?.profileImage, logoPreviewFile))
    ) {
      if (
        // eslint-disable-next-line no-alert
        !window.confirm(
          "You have unsaved changes. Are you sure you want to leave this page?"
        )
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className={styles.page}>
      <Prompt when={true} message={confirmNavigation} />
      <div className={styles.container}>
        <header className={styles.header}>
          <Link
            to={generatePath(PATHS.PUBLISHERS)}
            className={styles["back-link"]}
          >
            <FontAwesomeIcon iconClassName="fa-arrow-left" />
            Back to Publishers
          </Link>
          {hasEditAccess && (
            <Button
              data-testid="saveChangesButton"
              outline
              disabled={
                isEqual(initialVendorData, vendorData) && !logoPreviewFile
              }
              color="primary"
              onClick={() => submitForm()}
            >
              Save Changes
            </Button>
          )}
        </header>

        <div className={styles["title-container"]}>
          <label htmlFor="logo-upload" className={styles["logo-upload-button"]}>
            {vendorData.profileImage?.thumb.url || logoPreviewFile ? (
              <div className={styles["description-text-input-container"]}>
                <div
                  tabIndex={0}
                  aria-label="Open modal"
                  role="button"
                  onKeyDown={e => {
                    if (e.key === "Enter" && hasEditAccess) {
                      setIsLogoModalOpen(true);
                    }
                  }}
                  onClick={() => hasEditAccess && setIsLogoModalOpen(true)}
                >
                  <img
                    className={styles["logo-upload-preview-image"]}
                    src={
                      (logoPreviewFile as string) ||
                      vendorData.profileImage?.thumb.url
                    }
                    alt=""
                  />
                </div>
              </div>
            ) : (
              <div
                data-testid="uploadLogoButton"
                tabIndex={0}
                role="button"
                onKeyDown={e => {
                  if (e.key === "Enter" && hasEditAccess) {
                    setIsLogoModalOpen(true);
                  }
                }}
                className={styles["description-text-input-container"]}
                onClick={() => hasEditAccess && setIsLogoModalOpen(true)}
              >
                Upload Logo
                <FontAwesomeIcon iconClassName="fa-file-upload" />
              </div>
            )}
          </label>
          <button
            data-testid="editNameButton"
            type="button"
            className={styles["header-button"]}
            onClick={() => hasEditAccess && setIsVendorNameOpen(true)}
          >
            <p className={styles.header}>{vendorData.name}</p>
            {hasEditAccess && (
              <FontAwesomeIcon iconClassName="fa-pen" className={styles.icon} />
            )}
          </button>
          {isVendorNameOpen && (
            <EditVendorTextModal
              isOpen={isVendorNameOpen}
              initialValue={vendorData.name}
              validateUniqueness={true}
              validationSchema={nameValidationSchema}
              fieldName="name"
              onSubmit={name => {
                setVendorData(prev => {
                  return { ...prev, name: name || "" };
                });
              }}
              close={() => setIsVendorNameOpen(false)}
            />
          )}
          <span data-testid="editVendor_createdOn">
            <strong>Created On:</strong> {createdOn}
          </span>
          <span data-testid="editVendor_lastUsed">
            <strong>Last Used:</strong> {lastUsed || ""}
          </span>
          <span data-testid="editVendor_code">
            <strong>Code:</strong> {vendorData?.code}
          </span>
        </div>

        {isEditDescriptionOpen && (
          <EditVendorTextModal
            isOpen={isEditDescriptionOpen}
            initialValue={vendorData.description}
            fieldName="description"
            onSubmit={description => {
              setVendorData(prev => {
                return { ...prev, description };
              });
            }}
            close={() => setIsEditDescriptionOpen(false)}
          />
        )}

        <button
          data-testid="editDescriptionButton"
          type="button"
          className={styles["description-text-input-container"]}
          onClick={() => hasEditAccess && setIsEditDescriptionOpen(true)}
        >
          <p>{vendorData.description || "Publisher Description"}</p>
          {hasEditAccess && <FontAwesomeIcon iconClassName="fa-pen" />}
        </button>

        <AdminTable vendorId={vendorId} hasEditAccess={hasEditAccess} />

        <div className={styles["tabs-container"]}>
          <button
            className={activeTab === "products" ? styles.active : ""}
            type="button"
            onClick={() => setActiveTab("products")}
          >
            Products
          </button>
          {hasEditAccess && (
            <button
              data-testid="usersTab"
              className={activeTab === "users" ? styles.active : ""}
              type="button"
              onClick={() => setActiveTab("users")}
            >
              Users
            </button>
          )}
        </div>
        {activeTab === "users" && (
          <UsersTable vendorId={vendorId} hasEditAccess={hasEditAccess} />
        )}
        {activeTab === "products" && (
          <ProductsTable
            vendorId={vendorId}
            hasEditAccess={hasEditAccess}
            hasManagerAccess={isManagerAdmin()}
            hasLicences={vendorData.hasLicences}
          />
        )}
      </div>
      <EditVendorLogoModal
        isOpen={isLogoModalOpen}
        onSubmit={handleSetImage}
        imagePreview={logoPreviewFile as string}
        close={() => setIsLogoModalOpen(false)}
      />
    </div>
  );
};

export default EditVendorPage;
