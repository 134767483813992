import React from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import { toast } from "react-toastify";

import Toast from "../../../../../components/Toast";
import { regenerateApiKey } from "../../../../../api/api";

import styles from "./index.module.scss";

const RegenerateApiKeyButton = ({
  userId,
  disabled
}: {
  userId: string;
  disabled: boolean;
}) => {
  const handleRegenerate = async () => {
    try {
      await regenerateApiKey(userId);
      toast(<Toast type="success" title="Api key regenerated" details={[]} />);
    } catch (err) {
      toast(
        <Toast
          type="error"
          title="SORRY, YOU CAN ONLY REPLACE YOUR API KEY ONCE EVERY 24 HOURS."
          details={[]}
        />
      );
    }
  };

  return (
    <Button disabled={disabled} onClick={handleRegenerate}>
      <FontAwesomeIcon
        className={styles["refresh-api-icon"]}
        iconClassName="fa-undo"
      />
      Regenerate API Key
    </Button>
  );
};

export default RegenerateApiKeyButton;
