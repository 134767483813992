export const PRODUCT_MODAL_VENDOR_INFO = "Please select a publisher";
export const PRODUCT_MODAL_NAME_INFO = "Please submit a product name";
export const PRODUCT_MODAL_CODE_INFO = "Please submit a unique publisher code";
export const PRODUCT_MODAL_DESCRIPTION_INFO =
  "Please provide a short product description";
export const PRODUCT_MODAL_ADMIN_CONTACT_INFO =
  "Add an email address to be used for administrator contact";
export const PRODUCT_MODAL_DATA_TYPE_INFO = "Please select a data type";
export const PRODUCT_MODAL_FOR_SALE_DISABLED_INFO =
  "Publisher does not have valid licences";
