import React from "react";
import { FormField, FormSelect } from "@nef/core";
import { toast } from "react-toastify";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import CapSurveyFormFieldRow from "../cap-survey-form/CapSurveyFormFieldRow";
import { DatatablesNameDescription } from "../../api/types";
import { extractErrors } from "../../utils/react-hook-form-utils";
import Toast from "../Toast";
import { buildHostedDatatableSchema } from "../cap-survey-form/schema";
import TOOLTIP from "../cap-survey-form/tooltips";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";

import { BaseDatatableStepProps } from "./types";
import IntakeDatatableModalFooter from "./IntakeDatatableModalFooter";
import FormSelectWithFreeTextField from "./FormSelectWithFreeTextField";
import IntakeDatatableIngestMethodOptions from "./components/IntakeDatatableMethodOptions/IntakeDatatableIngestMethodOptions";

const SOURCE_FORMATS = [
  "CSV",
  "csv.gz",
  "JSON",
  "XML",
  "XLS",
  "TXT",
  "Parquet",
  "Avro",
  "ORC",
  "Other"
];
const INGEST_METHODS = ["S3", "SFTP", "FTP", "NDL Uploader", "Other"].map(
  value => {
    return {
      value,
      label: value
    };
  }
);
const UPDATE_PATTERNS = ["Replace full data", "Upsert", "Insert", "Other"];

const HostedDatatableWizardStep4 = ({
  datatable,
  fields,
  save,
  cancel,
  onPreviousStep,
  discard
}: BaseDatatableStepProps) => {
  const datatableSchema = buildHostedDatatableSchema();
  const stepSchema = datatableSchema.pickNested(fields);

  const formMethods = useForm<DatatablesNameDescription>({
    defaultValues: datatable,
    resolver: yupResolver(stepSchema)
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = formMethods;

  const saveStep = async () => {
    await handleSubmit(
      async formData => onHandleSubmit(formData),
      onHandleSubmitError
    )().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<
    DatatablesNameDescription
  > = async formData => {
    await save(formData, true);
  };

  const onHandleSubmitError: SubmitErrorHandler<
    DatatablesNameDescription
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  const currentIngestMethod = watch("ingestMethod");

  return (
    <FormProvider {...formMethods}>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_SOURCE_FORMAT}>
        <FormSelectWithFreeTextField
          name="sourceFormat"
          label="Source Format"
          placeholder="Choose an option"
          options={SOURCE_FORMATS}
        />
      </CapSurveyFormFieldRow>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_INGEST_METHOD}>
        <Controller
          name="ingestMethod"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <FormSelect
                  id="ingestMethod"
                  name={name}
                  label="Ingest Method"
                  value={INGEST_METHODS.find(o => o.value === value) || null}
                  isClearable={false}
                  placeholder="Choose an option"
                  options={INGEST_METHODS}
                  onChange={(formSelectOnChangeParam: any) => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  classNamePrefix="modalFormSelect"
                />
              </>
            );
          }}
        />
      </CapSurveyFormFieldRow>
      <IntakeDatatableIngestMethodOptions
        formMethods={formMethods}
        ingestMethod={currentIngestMethod}
      />
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_TABLE_SIZE}>
        <Controller
          name="tableSize"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="tableSize"
              min={0}
              name={name}
              label="Table Size"
              type="number"
              value={`${value}`}
              optional={false}
              onChange={onChange}
              data-testid="intakeDatatableModal_tableSize"
            />
          )}
        />
      </CapSurveyFormFieldRow>
      <CapSurveyFormFieldRow
        tooltip={TOOLTIP.DATATABLE_INCREMENTAL_UPDATE_SIZE}
      >
        <Controller
          name="incrementalUpdateSize"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="incrementalUpdateSize"
              name={name}
              label="Approximate Incremental Update Size"
              value={value || ""}
              optional={false}
              onChange={onChange}
              data-testid="intakeDatatableModal_incrementalUpdateSize"
            />
          )}
        />
      </CapSurveyFormFieldRow>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_INCREMENTAL_ROW_COUNT}>
        <Controller
          name="incrementalRowCount"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              min={0}
              id="incrementalRowCount"
              type="number"
              name={name}
              label="Incremental Row Count"
              value={`${value}`}
              optional={false}
              onChange={onChange}
              data-testid="intakeDatatableModal_incrementalRowCount"
            />
          )}
        />
      </CapSurveyFormFieldRow>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_UPDATE_PATTERN}>
        <FormSelectWithFreeTextField
          name="updatePattern"
          label="Update Pattern"
          placeholder="Choose an option"
          options={UPDATE_PATTERNS}
        />
      </CapSurveyFormFieldRow>
      <IntakeDatatableModalFooter
        save={saveStep}
        canAdvance={!isSubmitting && isSubmitSuccessful}
        cancel={isDirty ? discard : cancel}
        onPreviousStep={onPreviousStep}
        formState={formMethods}
      />
    </FormProvider>
  );
};

export default HostedDatatableWizardStep4;
