import React, { useEffect, useState } from "react";
import { isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useWatch } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { first } from "lodash";

import { ProductFormMode } from "../../api/types";
import { useProductContext } from "../../hooks/useProductContext";
import { getDataTableMeta, getTableData } from "../../api/api";

import DocumentationNavigation from "./DocumentationNavigation";
import styles from "./ProductPreview.module.scss";
import ProductSummary from "./product-summary/ProductSummary";
import ProductHeader from "./product-header/ProductHeader";
import ProductMetadata from "./product-metadata/ProductMetadata";
import ProductPricing from "./product-pricing/ProductPricing";
import ProductSupportingDocuments from "./product-supporting-documents/ProductSupportingDocuments";
import ProductNav from "./product-nav/ProductNav";
import DataVisualization from "./data-visualization/DataVisualization";

interface ProductPreviewInterface {
  productType: string;
  currentTab: "documentation" | "metadata";
}

const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const ProductPreview = ({
  productType,
  currentTab
}: ProductPreviewInterface) => {
  const { productForm, product } = useProductContext(ProductFormMode.EDIT);

  if (!productForm) return null;

  const [
    deliveryFrequency,
    dataFrequency,
    history,
    since,
    exclusive,
    premium,
    name,
    documentation,
    description,
    reportingLag,
    coverage,
    intraday,
    datatables,
    productMetadatum
  ] = useWatch({
    control: productForm.control,
    name: [
      "deliveryFrequency",
      "dataFrequency",
      "history",
      "since",
      "exclusive",
      "premium",
      "name",
      "documentation",
      "description",
      "reportingLag",
      "coverage",
      "intraday",
      "datatables",
      "productMetadatum"
    ]
  });

  const deliveryFrequencyString = deliveryFrequency?.map(capitalize).join(", ");
  const dataFrequencyString = dataFrequency?.map(capitalize).join(", ");

  const [dataTables, setDataTables] = useState<any>([]);
  const [productMetadata, setProductMetadata] = useState<any>({});

  let historyString = history;
  if (history === "since") {
    const dateSince = new Date(since);
    historyString = isValid(dateSince)
      ? formatInTimeZone(new Date(since), "UTC", "MMM yyyy")
      : "";
  } else if (history === "rolling") {
    historyString = "Rolling Updates";
  }

  let availabilityString = "Free";
  if (exclusive) {
    availabilityString = "Exclusive";
  } else if (premium) {
    availabilityString = "Premium";
  }

  useEffect(() => {
    const getDatatables = async () => {
      const datatableCodes = product?.datatables?.map(
        datatable => datatable.code
      );

      if (datatableCodes === undefined) {
        return;
      }

      const datatablesResponseDataOrNulls = await Promise.all(
        datatableCodes.map(async code => {
          try {
            const { data } = await getDataTableMeta(code);
            return data.datatable;
          } catch {
            return null;
          }
        })
      );

      const datatablesResponseData = datatablesResponseDataOrNulls?.filter(
        data => data !== null
      );

      const dataTableDataOrNulls = await Promise.all(
        datatableCodes?.map(async (code: string) => {
          try {
            const { data } = await getTableData(code, 1);
            if (data?.datatable?.data.length) {
              return code;
            }
            return null;
          } catch {
            return null;
          }
        })
      );

      const dataTableValidatedCodes = dataTableDataOrNulls?.filter(
        data => data !== null
      );

      const availableDataTables = datatablesResponseData
        .map(dataTableResponse => {
          if (dataTableValidatedCodes.includes(dataTableResponse?.code)) {
            return dataTableResponse;
          }
          return null;
        })
        .filter(data => data !== null);

      setDataTables(availableDataTables);
      setProductMetadata(first(availableDataTables) || {});
    };

    getDatatables();
  }, [product]);

  return (
    <div className={styles.preview} data-testid="productPreview">
      <div className={styles["preview-inner"]}>
        <ProductNav />
        <ProductHeader name={name} />
        <div className={styles.body}>
          <div className={styles.row}>
            <ProductSummary
              description={description}
              premium={premium}
              datatableProductType={productType}
              intraday={intraday}
              sdgIndicators={productMetadatum?.sdgIndicators}
            />
            <ProductMetadata
              deliveryFrequency={deliveryFrequencyString}
              dataFrequency={dataFrequencyString}
              reportingLag={reportingLag}
              history={historyString}
              coverage={coverage}
              availability={availabilityString}
              productType={productType}
              frameworkRegulationNotes={
                productMetadatum?.frameworkRegulationNotes
              }
            />
            <div className={styles["pricing-container"]}>
              <ProductPricing plans={[]} />
              {product?.productSupportingDocuments &&
                product?.productSupportingDocuments?.length > 0 && (
                  <ProductSupportingDocuments
                    datatableProductType={productType}
                    supportingDocuments={product?.productSupportingDocuments}
                  />
                )}
            </div>
          </div>
          {datatables && (
            <div className={styles.row}>
              <DataVisualization
                datatables={dataTables}
                productMetadata={productMetadata}
              />
            </div>
          )}
          {documentation && (
            <>
              <div className={styles.row}>
                <h2 className={styles.documentation__heading}>Documentation</h2>
              </div>
              {currentTab === "documentation" && (
                <div className={`${styles.row} ${styles["row-border"]}`}>
                  <DocumentationNavigation documentation={documentation} />
                  <div
                    className={styles.documentation}
                    data-testid="productPreview_documentation"
                  >
                    <ReactMarkdown
                      className={styles["documentation-markdown"]}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw] as any}
                    >
                      {documentation}
                    </ReactMarkdown>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductPreview;
