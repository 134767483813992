import React from "react";
import { FlexGrid, FormField, FormTextArea, FormSelect } from "@nef/core";
import { Controller, FormProvider } from "react-hook-form";
import styled from "styled-components";

import LabelValueWithHint from "../common/LabelValueWithHint";
import { useProductContext } from "../../hooks/useProductContext";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";

import { PRODUCT_DESCRIPTION_INFO } from "./hints";
import FormFieldRow from "./components/other-docs-input/FormFieldRow";
import TOOLTIP from "./components/other-docs-input/tooltips";

const FullWidthTextArea = styled(FormTextArea)`
  width: 100%;
`;

const EXCLUSIVE_DROPDOWN = ["Yes", "No"].map(value => ({
  value,
  label: value
}));

const ProductDetailStep = () => {
  const { productForm } = useProductContext();
  const { authenticatedUser } = useDefaultAuth();

  if (!productForm) return null;

  const { control } = productForm;

  const isManagerAdmin =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  return (
    <FormProvider {...productForm}>
      <FlexGrid.Row>
        <FlexGrid.Column xs={24}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormField
                id="ProductName"
                name="name"
                type="text"
                label="Product Name"
                value={value}
                onChange={onChange}
                data-testid="productDetailForm_name"
              />
            )}
          />
        </FlexGrid.Column>
      </FlexGrid.Row>
      <FlexGrid.Row>
        <FlexGrid.Column xs={24}>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FullWidthTextArea
                id="ProductDescription"
                name="description"
                type="text"
                label={
                  (
                    <LabelValueWithHint
                      label="Description"
                      hint={PRODUCT_DESCRIPTION_INFO}
                    />
                  ) as any
                }
                value={value}
                onChange={onChange}
                rows={4}
                maxLength={1000}
                autoResize={true}
                data-testid="productDetailForm_description"
              />
            )}
          />
        </FlexGrid.Column>
      </FlexGrid.Row>
      <FormFieldRow tooltip={TOOLTIP.OVERVIEW}>
        <Controller
          name="overview"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormTextArea
              id="Overview"
              name={name}
              label="Overview"
              placeholder="Overview"
              value={value || ""}
              onChange={onChange}
              rows={4}
              data-testid="productDetailForm_overview"
            />
          )}
        />
      </FormFieldRow>
      {isManagerAdmin && (
        <FormFieldRow tooltip={TOOLTIP.EXCLUSIVE}>
          <div data-testid="productDetailForm_exclusive">
            <Controller
              name="exclusive"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="ProductExclusive"
                    name={name}
                    label="Exclusive"
                    value={
                      value === null
                        ? ""
                        : EXCLUSIVE_DROPDOWN.find(
                            o => o.value === (value === true ? "Yes" : "No")
                          )
                    }
                    placeholder="Exclusive"
                    options={EXCLUSIVE_DROPDOWN}
                    optional={false}
                    isClearable={false}
                    onChange={(formSelectOnChangeParam: any) => {
                      const changeValue =
                        extractFormSelectOnChangeValue(
                          formSelectOnChangeParam
                        ) === "Yes";
                      onChange(changeValue);
                    }}
                    classNamePrefix="exclusive"
                  />
                );
              }}
            />
          </div>
        </FormFieldRow>
      )}
    </FormProvider>
  );
};

export default ProductDetailStep;
