import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormField,
  FormRadioCheckboxButton,
  FormRadioCheckboxGroup
} from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { toast } from "react-toastify";

import buildCapProductSchema from "../schema";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { CapProductToCreate, CapProductToEdit } from "../../../api/types";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import { BaseStepProps } from "../types";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import { useDirty } from "../cap-product-util";

const IntakeFormStep9Internal = ({ fields, setFormIsDirty }: BaseStepProps) => {
  const { product: contextProduct, saveAllData } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const productSchema = buildCapProductSchema();
  const stepSchema = productSchema.pickNested(fields);
  const [matchTechnicalContact, setMatchTechnicalContact] =
    useState<boolean>(false);
  const options = [
    {
      value: "MatchContactDataToggle",
      label: "Same as above"
    }
  ];

  const form = useForm<CapProductToEdit>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    watch,
    setValue
  } = form;
  useDirty(isDirty, setFormIsDirty);

  const dataOwnerName = watch("capProductMetadatum.dataOwnerName");
  const dataOwnerEmail = watch("capProductMetadatum.dataOwnerEmail");

  const toggleTechnicalContactMatch = () => {
    setMatchTechnicalContact(!matchTechnicalContact);

    if (!matchTechnicalContact) {
      setValue("capProductMetadatum.technicalContactName", null);
      setValue("capProductMetadatum.technicalContactEmail", null);
    }
  };

  const manageTechnicalInfo = () => {
    if (matchTechnicalContact) {
      setValue("capProductMetadatum.technicalContactName", dataOwnerName);
      setValue("capProductMetadatum.technicalContactEmail", dataOwnerEmail);
    }
  };

  useEffect(() => {
    manageTechnicalInfo();
  }, [dataOwnerName, dataOwnerEmail, matchTechnicalContact]);

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<CapProductToEdit> = async formData => {
    await saveAllData({ capMetadata: formData.capProductMetadatum });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection7Step1">
          <CapSurveyFormFieldRow tooltip={TOOLTIP.DATASET_OWNER}>
            <Controller
              name="capProductMetadatum.dataOwnerName"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="capProductMetadatum.dataOwnerName"
                  name={name}
                  type="text"
                  label="Dataset Owner Name"
                  placeholder="Dataset Owner Name"
                  value={value || ""}
                  optional={false}
                  onChange={onChange}
                  data-testid="capSurveyForm_datasetOwnerName"
                />
              )}
            />

            <Controller
              name="capProductMetadatum.dataOwnerEmail"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="capProductMetadatum.dataOwnerEmail"
                  name={name}
                  type="email"
                  label="Dataset Owner Email"
                  placeholder="Dataset Owner Email"
                  value={value || ""}
                  optional={false}
                  onChange={onChange}
                  data-testid="capSurveyForm_datasetOwnerEmail"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.TECHNICAL_CONTACT}>
            <FormRadioCheckboxGroup
              id="contactMatchToggleGroup"
              type="checkbox"
              label="Technical Contact Info"
              name="contactMatchToggleGroup"
              optional={false}
            >
              {options.map(option => {
                return (
                  <FormRadioCheckboxButton
                    id={`Checkbox_${option.value}`}
                    label={option.label}
                    name={option.value}
                    key={option.value}
                    optional={false}
                    onClick={toggleTechnicalContactMatch}
                  />
                );
              })}
            </FormRadioCheckboxGroup>

            <Controller
              name="capProductMetadatum.technicalContactName"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="capProductMetadatum.technicalContactName"
                  name={name}
                  type="text"
                  label="Technical Contact Name(s)"
                  placeholder="Technical Contact name(s) (comma-separated)"
                  value={value || ""}
                  optional={false}
                  onChange={onChange}
                  disabled={matchTechnicalContact}
                  data-testid="capSurveyForm_technicalContactName"
                />
              )}
            />

            <Controller
              name="capProductMetadatum.technicalContactEmail"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="capProductMetadatum.technicalContactEmail"
                  name={name}
                  type="text"
                  label="Technical Contact Email(s)"
                  placeholder="Technical Contact Email (s) (comma-separated)"
                  value={value || ""}
                  optional={false}
                  onChange={onChange}
                  disabled={matchTechnicalContact}
                  data-testid="capSurveyForm_technicalContactEmail"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormStep9Internal;
