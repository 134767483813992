import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";

import { getDatatableData } from "../../../api/api";
import { DatasetData } from "../../../api/types";

import styles from "./DataView.module.scss";

interface DataViewProps {
  vendorCode: string;
  datatableCode: string;
}

interface DataResponse {
  datasetData?: DatasetData;
  errorMessage?: string;
}

const GENERIC_ERROR_MESSAGE = "The data table cannot be fetched.";

const DataView = ({ vendorCode, datatableCode }: DataViewProps) => {
  const [dataResponse, setDataResponse] = useState<DataResponse>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDatatableData(vendorCode, datatableCode)
      .then(response => {
        setDataResponse({
          datasetData: response.data.datatable
        });
      })
      .catch((error: AxiosError) => {
        setDataResponse({
          errorMessage:
            error?.response?.data?.quandl_error.message || GENERIC_ERROR_MESSAGE
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Fetching data...</div>;
  }

  const { datasetData, errorMessage } = dataResponse;

  if (errorMessage) {
    return <div data-testid="dataView-error">{errorMessage}</div>;
  }

  return (
    <div className={styles.container} data-testid="dataView">
      <table className={styles.table}>
        <thead>
          <tr>
            {datasetData?.columns.map(column => {
              return (
                <th key={column.name} data-testid="dataView-header">
                  {column.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {datasetData?.data?.length ? (
            datasetData.data.map((row, rowIndex) => {
              /* eslint-disable react/no-array-index-key */
              return (
                <tr data-testid="dataView-row" key={rowIndex}>
                  {row.map((column, columnIndex) => {
                    return (
                      <td key={columnIndex} data-testid="dataView-column">
                        {column}
                      </td>
                    );
                  })}
                </tr>
                /* eslint-enable react/no-array-index-key */
              );
            })
          ) : (
            <tr data-testid="dataView-row">
              <td
                colSpan={datasetData?.columns.length}
                data-testid="dataView-empty"
              >
                No data is available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataView;
