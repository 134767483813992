import React, { useState, useEffect } from "react";
import { Button, FormSelect, Modal, ModalBody, ModalHeader } from "@nef/core";
import { Option } from "@nef/core/lib/components/components/FormBaseSelect";
import { useQuery } from "jsonapi-react";
import { useParams } from "react-router-dom";

import { OrganizationTeam } from "../../../../../../api/types";
import { EventActionMulti, ProductAccessOption } from "../../types";

import styles from "./index.module.scss";

interface Props {
  existingUserGroups: ProductAccessOption[];
  close: () => void;
  onSubmit: (data: ProductAccessOption[]) => void;
}

const AddUserGroupProductAccessModal = ({
  existingUserGroups,
  close,
  onSubmit
}: Props) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [selectedValues, setSelectedValues] = useState<ProductAccessOption[]>(
    []
  );

  const { data } = useQuery<OrganizationTeam[]>([
    "organization-teams",
    {
      "filter[organization_id]": organizationId,
      "filter[is_single_user_team]": false,
      "filter[is_default_team]": false
    }
  ]);

  useEffect(() => {
    setSelectedValues(existingUserGroups);
  }, [existingUserGroups]);

  const options: Option[] =
    (
      data
        ?.map(group => {
          if (group?.name) {
            return {
              label: group.name,
              value: group.id
            };
          }
          return null;
        })
        ?.filter(item => item !== null) as Option[]
    )?.filter(
      option =>
        !existingUserGroups.some(
          existingGroup => existingGroup.value.toString() === option.value
        )
    ) || [];

  const handleOnChange = (event: EventActionMulti) => {
    setSelectedValues(
      event.value.map(item => ({
        label: item.label,
        value: item.value,
        type: "User Group"
      }))
    );
  };

  const handleSubmit = () => {
    onSubmit([...existingUserGroups, ...selectedValues]);
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="userGroupAccessModal"
      toggle={close}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Add User Group"
        toggle={() => close()}
        className={styles["modal-header"]}
      >
        Add User Group
      </ModalHeader>
      <ModalBody>
        <div data-testid="userModalInput">
          <FormSelect
            placeholder="Select..."
            label="User Groups"
            isMulti
            isClearable
            defaultValue={selectedValues}
            options={options}
            onChange={e => {
              handleOnChange(e as unknown as EventActionMulti);
            }}
            classNamePrefix="modalFormSelect"
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={handleSubmit}
            data-testid="userGroupAccessModal_submit"
          >
            Submit
          </Button>
          <Button
            color="light"
            outline
            onClick={() => close()}
            data-testid="userGroupAccessModal_cancel"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUserGroupProductAccessModal;
