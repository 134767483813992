import React from "react";

const CloseIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="14.5"
      height="14.5"
      rx="7.25"
      fill="#3DBDE1"
    />
    <rect
      x="0.25"
      y="0.25"
      width="14.5"
      height="14.5"
      rx="7.25"
      stroke="#3DBDE1"
      strokeWidth="0.5"
    />
    <path
      d="M11.7857 6.5C12.1652 6.5 12.5 6.8125 12.5 7.16667V7.83333C12.5 8.20833 12.1652 8.5 11.7857 8.5H3.21429C2.8125 8.5 2.5 8.20833 2.5 7.83333V7.16667C2.5 6.8125 2.8125 6.5 3.21429 6.5H11.7857Z"
      fill="white"
    />
  </svg>
);

export default CloseIcon;
