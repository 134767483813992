import React, { useState } from "react";

import UPDATES_ENABLED_IMAGE from "../../assets/datatable-record/update-enabled.svg";
import { ConfirmationModal } from "../modals/ConfirmationModal";

import styles from "./DisableDataIngestionButton.module.scss";

interface DisableDataIngestionButtonProps {
  onConfirm: () => Promise<void>;
}

const DisableDataIngestionButton = ({
  onConfirm
}: DisableDataIngestionButtonProps) => {
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const confirmAndCloseModal = () => {
    return onConfirm().then(() => {
      setShouldConfirm(false);
    });
  };

  return (
    <>
      <button
        type="button"
        className={styles.button}
        onClick={() => {
          setShouldConfirm(true);
        }}
        data-testid="disableDataIngestionButton"
      >
        <img
          src={UPDATES_ENABLED_IMAGE}
          height={14}
          alt="Data ingestions are enabled"
          data-testid="disableDataIngestionButton_ingestionEnabled"
        />
      </button>
      <ConfirmationModal
        isOpen={shouldConfirm}
        title="Disable Data Ingestion"
        question="Are you sure you want to disable all future data ingestions for this table?"
        onConfirm={confirmAndCloseModal}
        onDismiss={() => setShouldConfirm(false)}
      />
    </>
  );
};

export default DisableDataIngestionButton;
