export const formatColumns = (columns: any = []) => {
  if (!columns.length) return [{ Header: "", accessor: "" }];
  return columns?.map((column: any) => {
    return { Header: column.name, accessor: column.name };
  });
};

export const formatData = (columns: any = [], data: any = []) => {
  if (!columns.length || !data.length) return [];
  return data?.reduce((acc: any, item: any) => {
    const obj: any = {};
    item?.forEach((a: any, i: number) => {
      const { name } = columns[i];
      obj[name] = a;
    });
    acc.push(obj);
    return acc;
  }, []);
};
