import React, { useRef, useState } from "react";
import { Button } from "@nef/core";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { difference, mergeWith } from "lodash";
import { Loader } from "@nef/icons";

import { formSchema } from "../schema";

import styles from "./index.module.scss";
import DatatableTabs from "./DatatableTabs";
import { Datatable, InitialVersionedFilterState } from "./types";
import VersionDropdown from "./components/VersionDropdown";

interface Props {
  formName: string;
  initialDatatables: { code: string }[];
  existingFilters: {
    datatableCode: string;
    filters: [];
    columns: {
      [key: string]: {
        name: string;
        type: string;
      }[];
    };
  }[];
}

const parseUnversionedData = (
  datatable: Datatable | undefined,
  data: { [key: string]: string }[]
) => {
  const versionSet = new Set<string>();

  if (datatable) {
    versionSet.add(datatable.version.code);

    datatable.versions.forEach(version => {
      versionSet.add(version.id);
    });
  }

  let parsedData = {};
  versionSet.forEach(version => {
    parsedData = {
      ...parsedData,
      [version]: data || []
    };
  });

  return parsedData;
};

const parseColumnDiff = (datatable: any, data: any) => {
  const versionSet = new Set<string>();

  if (datatable) {
    versionSet.add(datatable.version.code);

    datatable.versions.forEach((version: any) => {
      versionSet.add(version.id);
    });
  }

  let parsedData = {};
  versionSet.forEach(version => {
    const columnNames = (datatable?.columns || []).map(
      (c: { name: string }) => c.name
    );

    parsedData = {
      ...parsedData,
      [version]:
        !data[version] || data[version].length === 0
          ? []
          : difference(columnNames, data[version])
    };
  });

  return parsedData;
};

const DatatableEntitlements = ({
  formName,
  initialDatatables,
  existingFilters
}: Props) => {
  const [currentVersion, setCurrentVersion] = useState<string>("Default");
  const [isAddDatatableOpen, setAddDatatableOpen] = useState(false);
  const [isAddDatatableDisabled, setAddDatatableDisabled] = useState(true);
  const [isDatatableLoading, setIsDatatableLoading] = useState<
    boolean | undefined
  >(undefined);
  const isInitialLoad = useRef<boolean>(true);
  const fallbackUnversionedState: InitialVersionedFilterState = {};
  initialDatatables.forEach(dt => {
    fallbackUnversionedState[dt.code] = {
      filters: {},
      selectedColumns: {}
    };
  });
  const [initialVersionedFilterState, setInitialVersionedFilterState] =
    useState<InitialVersionedFilterState>(fallbackUnversionedState);

  const { getValues, setValue } =
    useFormContext<yup.Asserts<typeof formSchema>>();

  const currentFormDatatables = getValues(formName as any);

  const updateFormDatatables = (version: string, datatables?: Datatable[]) => {
    let existingFormDatatables = {};
    let incomingDatatables = {};
    let initialisedVersionedFilterState = {};

    if (isInitialLoad.current) {
      if (existingFilters.length > 0) {
        existingFilters.forEach((existingFilter: any) => {
          const existingDatatable = datatables?.find(
            datatable => datatable.code === existingFilter.datatableCode
          );

          existingFormDatatables = {
            ...existingFormDatatables,
            [existingFilter.datatableCode]: {
              datatableCode: existingFilter.datatableCode,
              filters: Array.isArray(existingFilter.filters)
                ? parseUnversionedData(
                    existingDatatable,
                    existingFilter.filters
                  )
                : existingFilter.filters,
              selectedColumns:
                Array.isArray(existingFilter.columns) || !existingFilter.columns
                  ? parseUnversionedData(
                      existingDatatable,
                      existingFilter.columns
                    )
                  : parseColumnDiff(existingDatatable, existingFilter.columns),
              diffColumns: {}
            }
          };
        });
      }

      datatables?.forEach((datatable: Datatable) => {
        let versionKeys = {
          [datatable.version.code]: []
        };

        datatable.versions.forEach(v => {
          versionKeys = {
            ...versionKeys,
            [v.id]: []
          };
        });

        initialisedVersionedFilterState = {
          ...initialisedVersionedFilterState,
          [datatable.code]: {
            columns: datatable.columns,
            filterable: datatable.filterable,
            defaultVersion: datatable.version.code,
            selectedColumns: {
              ...versionKeys
            },
            filters: {
              ...versionKeys
            },
            diffColumns: {
              ...versionKeys
            }
          }
        };

        incomingDatatables = {
          ...incomingDatatables,
          [datatable.code]: {
            filterable: datatable.filterable,
            columns: datatable.columns,
            defaultVersion: datatable.version.code,
            selectedColumns: {
              ...versionKeys
            },
            filters: {
              ...versionKeys
            },
            diffColumns: {
              ...versionKeys
            }
          }
        };
      });

      setInitialVersionedFilterState(initialisedVersionedFilterState);
      isInitialLoad.current = false;
    } else {
      // Subsequent Loads (version dependent)
      datatables?.forEach((datatable: Datatable) => {
        incomingDatatables = {
          ...incomingDatatables,
          [datatable.code]: {
            filterable: datatable.filterable,
            columns: datatable.columns
          }
        };
      });
    }

    const mergedFormDatatables = mergeWith(
      {},
      incomingDatatables,
      existingFormDatatables,
      currentFormDatatables,
      (objValue, srcValue, key) => {
        if (key === "columns" || key === "filterable") {
          return objValue;
        }

        return undefined;
      }
    );

    setCurrentVersion(version);
    setValue(formName as any, mergedFormDatatables);
  };

  const disableAddDatatableButton = (status: boolean) => {
    setAddDatatableDisabled(status);
  };

  return (
    <>
      <div className={styles["header-container"]}>
        <h4>Data Table</h4>
        <div className={styles["header-buttons-container"]}>
          <Button
            size="sm"
            outline
            onClick={() => setAddDatatableOpen(true)}
            data-testid="datatableEntitlements_add"
            disabled={isAddDatatableDisabled}
          >
            Add Datatable
          </Button>
          <VersionDropdown
            initialDatatables={initialDatatables}
            onVersionChanged={updateFormDatatables}
            setIsDatatableLoading={setIsDatatableLoading}
          />
        </div>
      </div>

      {isDatatableLoading ? (
        <div className={styles["loading-indicator"]}>
          <Loader size="lg" color="primary" intensity={300} />
          <div data-testid="infiniteScroll_loadingMessage">Loading...</div>
        </div>
      ) : (
        currentFormDatatables &&
        Object.keys(currentFormDatatables).length > 0 && (
          <DatatableTabs
            formName={formName}
            setAddDatatableOpen={setAddDatatableOpen}
            isAddDatatableOpen={isAddDatatableOpen}
            currentVersion={currentVersion}
            disableAddDatatableButton={disableAddDatatableButton}
            initialVersionedFilterState={initialVersionedFilterState}
          />
        )
      )}
    </>
  );
};

export default DatatableEntitlements;
