const FREQUENCY_OPTIONS = [
  "intraday",
  "daily",
  "weekly",
  "bi-weekly",
  "monthly",
  "quarterly",
  "annual",
  "semi-annual",
  "irregular"
].map(frequency => {
  return {
    value: frequency,
    // capitalize `frequency` as `label`
    label: frequency.charAt(0).toUpperCase() + frequency.slice(1)
  };
});

const CAP_DATA_FREQUENCY_OPTIONS = [
  "< minutely",
  "minutely",
  "30-minute",
  "hourly",
  "intraday",
  "daily",
  "weekly",
  "bi-weekly",
  "monthly",
  "quarterly",
  "annual",
  "semi-annual",
  "irregular"
].map(frequency => {
  return {
    value: frequency,
    // capitalize `frequency` as `label`
    label:
      frequency === "irregular"
        ? "Irregular or Other"
        : frequency.charAt(0).toUpperCase() + frequency.slice(1)
  };
});

const CAP_DELIVERY_FREQUENCY_OPTIONS = [
  "realtime",
  "intraday",
  "daily",
  "open and close of day",
  "weekly",
  "bi-weekly",
  "monthly",
  "quarterly",
  "annual",
  "semi-annual",
  "history only",
  "irregular"
].map(frequency => {
  let label;
  switch (frequency) {
    case "irregular":
      label = "Irregular or Other";
      break;
    case "realtime":
      label = "Real time";
      break;
    default:
      // capitalize `frequency` as `label`
      label = frequency.charAt(0).toUpperCase() + frequency.slice(1);
  }

  return {
    value: frequency,
    label
  };
});

export {
  FREQUENCY_OPTIONS,
  CAP_DATA_FREQUENCY_OPTIONS,
  CAP_DELIVERY_FREQUENCY_OPTIONS
};
