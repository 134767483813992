import React, { useState, useEffect } from "react";
import { FormSelect } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at } from "lodash";

import { formSchema } from "../../../schema";
import { SalesManager } from "../../../index";

import styles from "./index.module.scss";

const SalesRepresentative = ({
  formFieldName,
  salesManagers,
  initialValue,
  resetValue
}: {
  formFieldName: string;
  salesManagers: SalesManager[];
  initialValue?: string;
  resetValue: string;
}) => {
  const [salesRep, setSalesRep] = useState<string>(initialValue || "");

  const {
    control,
    formState: { errors }
  } = useFormContext<yup.Asserts<typeof formSchema>>();

  const getSalesRep = (_salesManagers: SalesManager[], id: string) => {
    const matchSalesRep = _salesManagers.find(
      (vl: { id: string }) => vl.id === id
    );
    return matchSalesRep
      ? {
          value: matchSalesRep.id,
          label: `${matchSalesRep.firstName} ${matchSalesRep.lastName}`
        }
      : null;
  };

  const formFieldErrors = at(errors, formFieldName)[0];

  useEffect(() => {
    setSalesRep(resetValue);
  }, [resetValue]);

  return (
    <div className={styles["sales-container"]}>
      <Controller
        control={control}
        name={formFieldName as any}
        render={({ field: { onChange, name: internalName } }) => {
          const v = salesRep ? getSalesRep(salesManagers, salesRep) : null;
          const currentSalesRepresentative = initialValue
            ? getSalesRep(salesManagers, initialValue)
            : "";

          return (
            <FormSelect
              id={formFieldName}
              data-testid="OrganizationsAddProductDrawer_salesRepresentative"
              name={internalName}
              label="Sales Representative"
              options={salesManagers.map(option => ({
                value: option.id,
                label: `${option.firstName} ${option.lastName}`
              }))}
              placeholder="Select..."
              value={v ?? currentSalesRepresentative}
              onChange={({ value: internalValue }: any) => {
                onChange(internalValue?.value || undefined);
                setSalesRep(internalValue?.value);
              }}
              invalid={!!formFieldErrors}
              feedback={formFieldErrors?.message}
            />
          );
        }}
      />
    </div>
  );
};

export default SalesRepresentative;
