import React from "react";
import { FormField } from "@nef/core";
import { Controller } from "react-hook-form";

import CapSurveyFormFieldRow from "../../../../cap-survey-form/CapSurveyFormFieldRow";
import TOOLTIP from "../../../../cap-survey-form/tooltips";

const S3Variant = ({ formMethods }: any) => {
  const { control } = formMethods;

  return (
    <>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_REMOTE_PATH}>
        <Controller
          name="remotePath"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="remotePath"
              name={name}
              label="Remote Path"
              value={value || ""}
              optional={false}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_FILE_PATTERN}>
        <Controller
          name="filePattern"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="filePattern"
              name={name}
              label="File Pattern"
              value={value || ""}
              optional={false}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>
    </>
  );
};

export default S3Variant;
