import { useEffect } from "react";
import intersection from "lodash/intersection";
import isEqual from "lodash/isEqual";
import { FieldArrayWithId, useFormContext, useWatch } from "react-hook-form";

import { DatatableSchema } from "../../../api/types";

function PartitionListener({
  fields
}: {
  fields: FieldArrayWithId<DatatableSchema, "columns", "id">[];
}) {
  const { control, setValue } = useFormContext();
  const partitions: string[] = useWatch({
    control,
    name: "partitions"
  });
  const partitionArray: boolean[] = useWatch({
    control,
    name: fields.map((_, idx) => `columns.${idx}.isPartitioned`)
  });
  const nameArray: string[] = useWatch({
    control,
    name: fields.map((_, idx) => `columns.${idx}.name`)
  });
  const typeArray: string[] = useWatch({
    control,
    name: fields.map((_, idx) => `columns.${idx}.baseType`)
  });
  useEffect(() => {
    const newPartitions = [];
    const existPartitions = [];
    for (let i = 0; i < partitionArray.length; i++) {
      const isPartitioned = partitionArray[i];
      if (isPartitioned) {
        const baseType = typeArray[i];
        // partition does not work on double and decimal data type
        if (baseType !== "double" && baseType !== "decimal") {
          const name = nameArray[i];
          if (!partitions.includes(name)) {
            newPartitions.push(name);
          } else {
            existPartitions.push(name);
          }
        } else {
          // toggle isPartitioned when user change from other type to double or decimal
          setValue(`columns.${i}.isPartitioned`, false, { shouldDirty: true });
        }
      }
    }
    let result: string[] = intersection(partitions, existPartitions);
    if (newPartitions.length !== 0) {
      result = result.concat(newPartitions);
    }
    if (!isEqual(result, partitions)) {
      setValue("partitions", result, { shouldDirty: true });
    }
  }, [partitionArray, nameArray, typeArray, setValue]);
  return null;
}

export default PartitionListener;
