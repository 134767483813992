import { createContext } from "react";

import { WizardConfigs } from "../components/wizard/types";

const initialState: WizardConfigs = {
  steps: [],
  baseSteps: [],
  registerStep: () => null,
  unregisterStep: () => null,
  updateStep: () => null,
  currentStepIndex: 0,
  currentStepPath: "",
  previousPath: "",
  nextPath: "",
  canAdvanceTo: () => true,
  navigateTo: () => null
};

const WizardContext = createContext<WizardConfigs>(initialState);

export default WizardContext;
