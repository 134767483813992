const HISTORY_OPTIONS = [
  { value: "since", label: "since" },
  { value: "rolling", label: "rolling" },
  { value: "projected", label: "projected" }
];

const INTRADAY_OPTIONS = [
  { value: false, label: "No" },
  { value: true, label: "Yes" }
];

export { HISTORY_OPTIONS, INTRADAY_OPTIONS };
