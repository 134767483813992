import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@nef/core";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Link, generatePath } from "react-router-dom";
import { format } from "date-fns";

import { DatatableSortParam } from "../../api/types";
import { PATHS } from "../../routes";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import ProductStatusPill from "./product-status-pill";
import ProductPrivateStatusPill from "./product-status-private-pill";
import styles from "./products-table.module.scss";

const columnHelper = createColumnHelper<any>();

interface DatatableListHeaderProps {
  datatable?: any[];
  sortParam: string;
  setSortParam: (newSort: DatatableSortParam) => void;
}

const ProductsTable = ({
  datatable,
  sortParam,
  setSortParam
}: DatatableListHeaderProps) => {
  const { authenticatedUser } = useDefaultAuth();

  const ascending = !sortParam.startsWith("-");
  const sortBy = ascending ? sortParam : sortParam.substring(1);
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";

  const updateSortParam = useCallback(
    (newSortBy: string) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as DatatableSortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy, sortParam]
  );

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => {
        const {
          name,
          description,
          active,
          code,
          id,
          productApprovalStatus,
          productApprovalType,
          vendorId
        } = info.row.original;

        return (
          <div className={styles["name-container"]}>
            <div className={styles["heading-container"]}>
              <div className={styles["heading-left"]}>
                <h3 className={styles.heading}>{name}</h3>
                <div className={styles["pills-container"]}>
                  <ProductStatusPill product={info.row.original} />
                  <ProductPrivateStatusPill product={info.row.original} />
                </div>
              </div>
              <div className={styles["heading-right"]}>
                {(authenticatedUser?.rolesArray?.includes("manager_admin") ||
                  authenticatedUser?.adminableVendorIds?.includes(
                    parseInt(vendorId, 10)
                  ) ||
                  authenticatedUser?.manageableDatatableCollectionIds.includes(
                    parseInt(info.row.original.id, 10)
                  )) && (
                  <>
                    <div data-testid="intake-icon">
                      {(productApprovalStatus === "intake_draft" ||
                        productApprovalStatus ===
                          "intake_pending_approval") && (
                        <Link
                          to={generatePath(PATHS.EDIT_INTAKE, {
                            productId: id
                          })}
                        >
                          <FontAwesomeIcon
                            className={styles.icons}
                            iconSetClassName="fas"
                            iconClassName="fa-edit"
                          />
                        </Link>
                      )}
                    </div>

                    {(productApprovalStatus === "draft" ||
                      productApprovalStatus === "pending_approval" ||
                      productApprovalType === "no_approval_required") && (
                      <div data-testid="product-builder-icon">
                        <Link
                          to={generatePath(PATHS.EDIT_PRODUCT, {
                            productId: id
                          })}
                        >
                          <FontAwesomeIcon
                            className={styles.icons}
                            iconSetClassName="fas"
                            iconClassName="fa-eye"
                          />
                        </Link>
                      </div>
                    )}
                  </>
                )}

                {active && (
                  <a
                    aria-label="Open databases"
                    href={`${process.env.REACT_APP_DATA_LINK_URL}/databases/${code}`}
                  >
                    <FontAwesomeIcon
                      className={styles.icons}
                      iconSetClassName="fas"
                      iconClassName="fa-external-link-alt"
                    />
                  </a>
                )}
              </div>
            </div>
            <div className={styles.description}>{description}</div>
          </div>
        );
      },
      header: () => {
        return (
          <div
            className={sortBy === "name" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("name")}
            onKeyPress={() => updateSortParam("name")}
            tabIndex={-1}
          >
            Name
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      cell: info => {
        const {
          code,
          vendorName,
          administratorContactVendorUserName: adminName,
          administratorContactVendorUserId: adminId,
          administratorContactVendorUserInviteEmail: adminInviteEmail,
          lastUpdatedBy,
          updatedAt
        } = info.row.original;

        return (
          <div className={styles["date-container"]}>
            <div className={styles["info-container"]}>
              <p className={styles["info-title"]}>Code</p>
              <p className={styles["info-value"]}>{code}</p>
            </div>
            <div className={styles["info-container"]}>
              <p className={styles["info-title"]}>Published by</p>
              <p className={styles["info-value"]}>{vendorName}</p>
            </div>
            <div className={styles["info-container"]}>
              <p className={styles["info-title"]}>Admin</p>
              <Link to={`/users/${adminId}`}>
                <p className={styles["info-value"]}>
                  {adminName || adminInviteEmail}
                </p>
              </Link>
            </div>
            <div className={styles["info-container"]}>
              <p className={styles["info-title"]}>Last Saved At</p>
              <p className={styles["info-value"]}>
                {format(new Date(updatedAt), "d/M/yyyy hh:mm a")}
              </p>
            </div>
            <div className={styles["info-container"]}>
              <p className={styles["info-title"]}>Last Saved By</p>
              <p className={styles["info-value"]}>{lastUpdatedBy}</p>
            </div>
          </div>
        );
      },
      header: () => {
        return (
          <div
            className={sortBy === "created_at" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("created_at")}
            onKeyPress={() => updateSortParam("created_at")}
            tabIndex={-1}
          >
            Date
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    })
  ];

  const table = useReactTable({
    data: datatable ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <section>
      <table className={styles["products-table"]}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={`${
                    header.column.getIsSorted()
                      ? `${styles.selected} ${styles.label} ${styles["left-align"]}`
                      : `${styles.label} ${styles["left-align"]}`
                  }`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ProductsTable;
