import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@nef/core";

import { getUploadEvents } from "../../api/api";
import Toast from "../../components/Toast";
import UploadLog from "../../components/datatables-form/UploadLog";
import { UploadEvent } from "../../api/types";
import { PATHS } from "../../routes";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import styles from "./upload-logs.module.scss";

const UploadLogsPage = () => {
  const history = useHistory();
  const { authenticatedUser } = useDefaultAuth();

  const { vendorCode, datatableCode, versionCode } = useParams<{
    vendorCode: string;
    datatableCode: string;
    versionCode: string;
  }>();

  const [events, setEvents] = useState<UploadEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const isUnmounted = useRef(false);

  const grabUploadEvents = async () => {
    setIsLoading(true);

    let response;
    try {
      response = await getUploadEvents(vendorCode, datatableCode, versionCode);
    } catch (e) {
      toast(
        <Toast
          type="error"
          title="Error grabbing upload events"
          details={[{ message: "Please try again later" }]}
        />
      );
    }

    if (!isUnmounted.current) {
      if (response) {
        setEvents(response.data.events);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    grabUploadEvents();

    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const noEvents = !isLoading && events.length === 0;

  if (!authenticatedUser?.rolesArray.includes("manager_admin")) {
    history.push(PATHS.PUBLISHERS);
    return null;
  }

  return (
    <div data-testid="uploadLogs">
      <Button
        as={Link}
        to={generatePath(`${PATHS.EDIT_DATATABLE}/fileInfo`, {
          vendorCode,
          datatableCode,
          versionCode
        })}
        color="link"
        data-testid="uploadLogs_previousStep"
      >
        &lt; Back to File Specifications
      </Button>
      <div className={styles.container}>
        <h3 className={styles.header}>Upload Logs</h3>
        <Button
          disabled={isLoading}
          onClick={() => grabUploadEvents()}
          data-testid="uploadLogs_refreshEvents"
        >
          Refresh Logs
        </Button>

        {noEvents && (
          <div
            data-testid="uploadLogs_noResults"
            className={styles["no-results"]}
          >
            No data has been updated for this datatable.
          </div>
        )}
        {!isLoading &&
          events.map((event: UploadEvent) => {
            return <UploadLog key={event.id} event={event} />;
          })}
      </div>
    </div>
  );
};

export default UploadLogsPage;
