import React, { useState } from "react";

import UPDATES_DISABLED_IMAGE from "../../assets/datatable-record/update-disabled.svg";
import { ConfirmationModal } from "../modals/ConfirmationModal";

import styles from "./EnableDataIngestionButton.module.scss";

interface EnableDataIngestionButtonProps {
  onConfirm: () => Promise<void>;
}

const EnableDataIngestionButton = ({
  onConfirm
}: EnableDataIngestionButtonProps) => {
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const confirmAndCloseModal = () => {
    return onConfirm().then(() => {
      setShouldConfirm(false);
    });
  };

  return (
    <>
      <button
        type="button"
        className={styles.button}
        onClick={() => {
          setShouldConfirm(true);
        }}
        data-testid="enableDataIngestionButton"
      >
        <img
          src={UPDATES_DISABLED_IMAGE}
          height={14}
          alt="Data ingestions are disabled"
          data-testid="enableDataIngestionButton_ingestionDisabled"
        />
      </button>
      <ConfirmationModal
        isOpen={shouldConfirm}
        title="Enable Data Ingestion"
        question="Are you sure you want to enable all future data ingestions for this table?"
        onConfirm={confirmAndCloseModal}
        onDismiss={() => setShouldConfirm(false)}
      />
    </>
  );
};

export default EnableDataIngestionButton;
