import { FontAwesomeIcon, NavbarLogo } from "@nef/core";
import React from "react";
import "./style.scss";

const ProductNav = () => {
  return (
    <>
      <div className="mac-style">
        <div className="mac-style__container">
          <div className="mac-style__container-items">
            <div className="mac-dots-container">
              <div className="mac-dots" />
              <div className="mac-dots" />
              <div className="mac-dots" />
            </div>
            <p className="mac-style__text" aria-disabled>
              Page preview is scaled by 60%
            </p>
            <div />
          </div>
        </div>
      </div>
      <div className="prod-nav-container">
        <div className="prod-nav-container-left">
          <NavbarLogo className="nav-logo" />
          <h1 className="link link--brand">nasdaq data link</h1>
          <div className="search-container">
            <div className="search-bar">
              <input
                className="input-bar"
                type="text"
                placeholder="SEARCH"
                data-testid="search-bar"
                disabled
              />
              <input hidden name="query" readOnly />
              <button type="submit" className="search-icon">
                <FontAwesomeIcon iconClassName="fa-search" />
              </button>
            </div>
            <div className="link">explore</div>
          </div>
        </div>
        <div className="prod-nav-container-right">
          <div className="button-container">
            <div className="dropdown-menu-link yellow-link">
              <p>Data Fabric</p>
            </div>
            <div className="dropdown-menu-link blue-link">
              <p>Monetize Your Data</p>
            </div>
            <div className="dropdown-menu-link">
              <p>Contact Sales</p>
            </div>
          </div>
          <FontAwesomeIcon iconClassName="fa-user" />
          <FontAwesomeIcon iconClassName="fa-th" />
        </div>
      </div>
    </>
  );
};

export default ProductNav;
