import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import Toast from "../components/Toast";
import { getUploadFileLink } from "../api/api";

export type FileData = {
  type: "Directory" | "File";
  parentPath: string;
  path: string;
  name: string;
  size?: string;
  // for file, this date is the created date
  // for directory, this date is equal to the date of the earlist created file within
  date: Date;
  children: FileData[];
};

function useFileManagerUpload(
  path: string,
  vendorId: string,
  onSuccess: (names: string[], files: File[]) => void = () => {},
  maxFiles = 1,
  accept = {},
  randomizeFileName = false
) {
  const [isLoading, setIsLoading] = useState(false);
  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      toast(
        <Toast
          type="error"
          title="File upload failed"
          details={[{ message: "No accepted file(s) uploaded" }]}
        />
      );
      return;
    }
    setIsLoading(true);
    const allPromises: Promise<string>[] = [];
    acceptedFiles.forEach(file => {
      allPromises.push(
        getUploadFileLink(file, path, vendorId, randomizeFileName)
      );
    });
    try {
      const response = await Promise.allSettled(allPromises);
      let uploadedFileCount = 0;
      const uploadedFiles: string[] = [];
      const errors: any[] = [];
      response.forEach(d => {
        if (d.status === "fulfilled") {
          uploadedFileCount++;
          uploadedFiles.push(d.value);
        } else if (d.status === "rejected") {
          errors.push(d.reason.message);
        }
      });
      if (errors.length !== 0) {
        toast(
          <Toast
            type="error"
            title="File upload failed"
            details={errors.map(e => ({
              message: e
            }))}
          />
        );
      }
      if (uploadedFileCount >= 1) {
        toast(
          <Toast
            type="info"
            title={`${
              uploadedFileCount === 1
                ? "File is"
                : `${uploadedFileCount} files are`
            } uploaded for ingestion`}
            details={[
              {
                message: `Your ${
                  uploadedFileCount === 1
                    ? "file has"
                    : `${uploadedFileCount} files have`
                } been successfully uploaded.`
              }
            ]}
          />,
          {
            autoClose: 4000
          }
        );
        onSuccess(uploadedFiles, acceptedFiles);
      }
    } catch (e) {
      toast(
        <Toast
          type="error"
          title="File upload failed"
          details={[
            {
              message: `Your file(s) could not be submitted. ${
                e instanceof Error && e.message
              }`
            }
          ]}
        />
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onDropRejected = (fileRejections: FileRejection[]) => {
    toast(
      <Toast
        type="error"
        title="File upload failed"
        details={fileRejections.map(e => ({
          message: e.errors.map(v => v.message).join(", ")
        }))}
      />
    );
  };

  const { getRootProps, isDragActive, getInputProps, open } = useDropzone({
    noDragEventsBubbling: true,
    onDropAccepted,
    onDropRejected,
    noClick: true,
    maxFiles,
    multiple: maxFiles > 1,
    accept
  });

  return { isLoading, getRootProps, isDragActive, open, getInputProps };
}

export default useFileManagerUpload;
