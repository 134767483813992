import React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "@nef/core";

import styles from "./index.module.scss";

interface Props {
  code: string;
  onSubmit: (data: string) => void;
  onClose: () => void;
}

const RemoveDatatable = ({ code, onClose, onSubmit }: Props) => {
  return (
    <Modal isOpen data-testid="selectDatatableModal">
      <ModalHeader toggle={() => onClose()}>Delete Datatable</ModalHeader>
      <ModalBody>Are you sure you wish to remove {code}?</ModalBody>
      <ModalFooter className={styles.footer}>
        <Button
          size="md"
          className="danger"
          color="danger"
          onClick={onClose}
          data-testid="removeDatatable_dismiss"
        >
          No
        </Button>

        <Button
          size="md"
          onClick={() => onSubmit(code)}
          data-testid="removeDatatable_confirm"
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveDatatable;
