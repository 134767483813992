const TOOLTIP = {
  ADDITIONAL_MATERIALS:
    "Please provide any additional materials e.g. whitepaper, research notes, technical specifications, or marketing materials.",
  OVERVIEW:
    "A longer description of what the data covers, how much history is available, where the data is sourced, how frequently it updates and any unique aspects",
  EXCLUSIVE:
    "When a product is set to exclusive, it cannot be viewed by customers unless they have been explicitly granted access. Also, it will not appear in the search index unless the product is internal."
};

export default TOOLTIP;
