import React from "react";
import { MemoryRouter, BrowserRouter } from "react-router-dom";
import NEFThemeProvider from "@nef/style-utils";
import { ApiProvider } from "jsonapi-react";
import { ToastContainer, cssTransition } from "react-toastify";
import { Container as ModalContainer } from "react-modal-promise";

import client from "../api/manage/client";
import { APP_ROOT } from "../appConfig";

import { SessionAuthProvider } from "./authentication";

import "react-toastify/dist/ReactToastify.min.css";

interface ApplicationProviderInterface {
  children: JSX.Element;
  isTest?: boolean;
  initialEntries?: string[];
}

const Router = ({
  children,
  isTest,
  initialEntries
}: ApplicationProviderInterface) => {
  return (
    <>
      {isTest ? (
        <MemoryRouter initialEntries={initialEntries}>{children}</MemoryRouter>
      ) : (
        <BrowserRouter basename={APP_ROOT}>{children}</BrowserRouter>
      )}
    </>
  );
};

const ApplicationProvider = ({
  children,
  isTest = false,
  initialEntries
}: ApplicationProviderInterface) => {
  return (
    <>
      <NEFThemeProvider mode="lightWithDarkSidebar">
        <Router isTest={isTest} initialEntries={initialEntries}>
          <ApiProvider client={client}>
            <SessionAuthProvider>{children}</SessionAuthProvider>
          </ApiProvider>
        </Router>
        <ToastContainer
          autoClose={5000}
          position="top-center"
          hideProgressBar={true}
          transition={cssTransition({
            enter: "fade-in",
            exit: "fade-out"
          })}
        />
        <ModalContainer />
      </NEFThemeProvider>
    </>
  );
};

export default ApplicationProvider;
