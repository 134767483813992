import React from "react";
import { Frequency, RRule, Weekday } from "rrule";
import { isMatch, parse } from "date-fns";

import { RecurringSchedule, ByDay } from "../../../../api/types";

import { RRULE_DATE_FORMAT } from "./util";
import styles from "./Scheduler.module.scss";

interface RecurringTextProps {
  schedule: RecurringSchedule;
}

const FREQ_TO_RRULE_FREQ_MAP: {
  [freq in RecurringSchedule["freq"]]: Frequency;
} = {
  daily: RRule.DAILY,
  weekly: RRule.WEEKLY,
  monthly: RRule.MONTHLY,
  annually: RRule.YEARLY
};

const BYDAY_TO_BYWEEKDAY: {
  [key in ByDay]: Weekday;
} = {
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU
};

const RecurringText = ({ schedule }: RecurringTextProps) => {
  const {
    freq,
    dtstart,
    tzid,
    interval,
    count,
    until,
    byday,
    bymonth,
    bymonthday,
    bysetpos
  } = schedule;

  const rrule = new RRule({
    freq: FREQ_TO_RRULE_FREQ_MAP[freq],
    dtstart: isMatch(dtstart, RRULE_DATE_FORMAT)
      ? parse(dtstart, RRULE_DATE_FORMAT, new Date())
      : undefined,
    tzid,
    interval,
    count,
    until: isMatch(until || "", RRULE_DATE_FORMAT)
      ? parse(until || "", RRULE_DATE_FORMAT, new Date())
      : undefined,
    byweekday: byday?.map(b => BYDAY_TO_BYWEEKDAY[b]),
    bymonth,
    bymonthday,
    // NOTE: `RRule.toText()` seems to be missing `bysetpos` - may need to find an alternative
    bysetpos
  });

  return (
    <div className={styles["preview-text"]}>
      The data gets updated {rrule.toText()}
    </div>
  );
};

export default RecurringText;
