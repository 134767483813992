import React, { useCallback, useState } from "react";
import { Button } from "@nef/core";

import { AccumulatedColumnStats } from "../../../api/types";
import { ConfirmationModal } from "../../modals/ConfirmationModal";

import AutoSchemaPopulateModal from "./AutoSchemaPopulateModal";

interface AutoSchemaPopulateButtonProps {
  hasExistingColumns: boolean;
  onProcessed: (accumulated: AccumulatedColumnStats) => void;
}

const AutoSchemaPopulateButton = ({
  hasExistingColumns,
  onProcessed
}: AutoSchemaPopulateButtonProps) => {
  const [isAutoSchemaModalOpen, setAutoSchemaModalOpen] = useState(false);
  const [accumulatedToProcess, setAccumulatedToProcess] =
    useState<AccumulatedColumnStats>();

  const tryProcessData = useCallback(
    (accumulated: AccumulatedColumnStats) => {
      if (hasExistingColumns) {
        setAccumulatedToProcess(accumulated);
      } else {
        processData(accumulated);
      }
    },
    [hasExistingColumns]
  );

  const processData = (accumulated: AccumulatedColumnStats) => {
    onProcessed(accumulated);
    exitProcessData();
  };

  const exitProcessData = () => {
    setAutoSchemaModalOpen(false);
    setAccumulatedToProcess(undefined);
  };

  return (
    <>
      <Button
        outline={true}
        onClick={() => setAutoSchemaModalOpen(true)}
        data-testid="autoSchemaPopulateButton"
      >
        Auto Populate with CSV file
      </Button>
      <AutoSchemaPopulateModal
        isOpen={isAutoSchemaModalOpen}
        close={() => setAutoSchemaModalOpen(false)}
        onProcessed={tryProcessData}
      />
      <ConfirmationModal
        isOpen={hasExistingColumns && !!accumulatedToProcess}
        question="This will override existing datatable columns. Are you sure?"
        onConfirm={() =>
          processData(accumulatedToProcess as AccumulatedColumnStats)
        }
        onDismiss={() => exitProcessData()}
      />
    </>
  );
};

export default AutoSchemaPopulateButton;
