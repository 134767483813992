import React from "react";
import { FormField } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { Controller, useFormContext } from "react-hook-form";

interface AmountCentsInputProps {
  name: string;
  label: string;
  testid: string;
  disabled?: boolean;
}

const AmountCentsInput = ({
  name,
  label,
  testid,
  disabled
}: AmountCentsInputProps) => {
  const { control } = useFormContext();

  const centsToInputValue = (cents: any) => {
    // only support whole dollar values for now, so always rounded to whole dollar
    return cents === null ? "" : (cents / 100).toFixed(0);
  };

  const inputValueToCents = (inputValue: string | string[] | undefined) => {
    if (inputValue === undefined || Array.isArray(inputValue)) {
      return false;
    }

    if (inputValue === "") {
      return null;
    }

    // only support whole dollar values for now, so only 0-9 are accepted
    if (/^[0-9]*$/.test(inputValue)) {
      const dollar = Number(inputValue);
      return Math.floor(dollar * 100);
    }

    return false;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value: cents, name: inputName } }) => {
        return (
          <FormField
            id={inputName}
            type="text"
            pattern="\d*"
            name={inputName}
            label={label}
            disabled={!!disabled}
            value={centsToInputValue(cents)}
            onChange={(e: CustomChangeEvent) => {
              const newCents = inputValueToCents(e.value);
              if (newCents !== false) {
                onChange(newCents);
              }
            }}
            data-testid={testid}
          />
        );
      }}
    />
  );
};

export default AmountCentsInput;
