import React, { useEffect, useRef } from "react";
import { Button } from "@nef/core";
import Papa from "papaparse";

import styles from "./style.module.scss";

const parseCSVData = (data: string) => {
  const { data: parsedata } = Papa.parse(data);
  return parsedata
    .flat()
    .filter(d => d)
    .join("\n");
};

function AddCSVButton({ onFinish }: { onFinish: (_: string) => void }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const readerRef = useRef<FileReader>(new FileReader());

  useEffect(() => {
    readerRef.current.addEventListener("load", e => {
      if (e.target) {
        const csvdata = e.target.result as string;
        onFinish(parseCSVData(csvdata));
      }
    });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.[0]) {
      const fileUploaded = event.target.files[0];
      readerRef.current.readAsText(fileUploaded);
    }
  };

  return (
    <div className={styles["margin-button"]} data-testid="addCSVButton">
      <Button
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.value = "";
            inputRef.current.click();
          }
        }}
      >
        Add CSV
      </Button>
      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        onChange={handleChange}
      />
    </div>
  );
}

export default AddCSVButton;
