// @ts-ignore
import { ApiClient, Serializer } from "jsonapi-react";
import { camelizeKeys } from "humps";

import UserService from "../../services/user";

import schema from "./schema";
import createPaginationInfo from "./pagination";

const client = new ApiClient({
  url: "/manage",
  schema,
  headers: {
    "X-API-Token": UserService.getUser()?.apiKey
  },
  // @ts-ignore
  normalize: (data, dataSchema) => {
    const camelizedData: any = camelizeKeys(data);

    camelizedData.meta = camelizedData.meta || {};
    camelizedData.meta.pagination = createPaginationInfo(data);

    return new Serializer({ schema: dataSchema }).deserialize(camelizedData);
  }
});

export default client;
