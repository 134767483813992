import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import { useClient } from "jsonapi-react";
import { generatePath, useHistory } from "react-router-dom";

import { PATHS } from "../../../../../../routes";
import Toast from "../../../../../../components/Toast";

import styles from "./index.module.scss";

interface DeleteUserModalProps {
  close: () => void;
  selectedUser: {
    id: string;
    userName: string;
    userInviteEmail?: string;
  } | null;
  redirect: boolean;
  organizationId: string;
}

const DeleteUserModal = ({
  close,
  selectedUser,
  redirect,
  organizationId
}: DeleteUserModalProps) => {
  const history = useHistory();

  const client = useClient();

  const handleDeleteUser = async () => {
    const response = await client.delete([
      "organization-users",
      selectedUser?.id
    ]);

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error deleting the user"
          details={[]}
        />
      );
    } else {
      toast(
        <Toast
          type="success"
          title="User has been successfully deleted"
          details={[]}
        />
      );

      if (redirect) {
        history.push(
          `${generatePath(PATHS.EDIT_ORGANIZATIONS, {
            organizationId
          })}?tab=users`
        );
        return;
      }

      handleClose();
    }
  };

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="deleteUserModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Delete User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Remove from Organization
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <p className={styles.description}>
              Are you sure you want to remove{" "}
              {selectedUser?.userName || selectedUser?.userInviteEmail} from the
              organization?
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              color="danger"
              onClick={handleDeleteUser}
              data-testid="deleteUserModal_submit"
            >
              Remove Access
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="deleteUserModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteUserModal;
