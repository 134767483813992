import React from "react";
import { useQuery } from "jsonapi-react";

import { Plan, PlanCategory } from "../../../api/types";
import LoadingIndicator from "../../common/LoadingIndicator";
import { ConfirmationModal } from "../../modals/ConfirmationModal";

interface ReactivateConfirmationModalProps {
  plan: Plan;
  planCategory: PlanCategory;
  onConfirm: () => void;
  onDismiss: () => void;
}

const ReactivateConfirmationModal = ({
  plan,
  planCategory,
  onConfirm,
  onDismiss
}: ReactivateConfirmationModalProps) => {
  const { data: similarActivePlans, isLoading } = useQuery<Plan[]>([
    "plans",
    {
      filter: {
        "plan-category-id": planCategory.id,
        active: true,
        "interval-count": plan.intervalCount,
        interval: plan.interval
      }
    }
  ]);

  if (isLoading) {
    return <LoadingIndicator message="Checking Active Pricing Plans..." />;
  }

  const confirmationQuestion = similarActivePlans?.length
    ? "This pricing plan will be reactivated immediately. An existing active pricing plan with the same payment schedule and history will be grandfathered. Do you wish to continue?"
    : "This pricing plan will be reactivated immediately. Do you wish to continue?";

  return (
    <ConfirmationModal
      title="Warning"
      isOpen={true}
      question={confirmationQuestion}
      onConfirm={() => onConfirm()}
      onDismiss={() => onDismiss()}
    />
  );
};

export default ReactivateConfirmationModal;
