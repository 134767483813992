import React, { useCallback, useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useQuery } from "jsonapi-react";
import { Link } from "react-router-dom";

import { OrganizationUser } from "../../../../api/types";
import RemoveUserModal from "../remove-user-modal";
import AddUserModal from "../add-user-modal";
import UserProductAccessModal from "../user-product-access-modal";
import ProductDrawer from "../product-drawer";

import styles from "./index.module.scss";

type SortParam =
  | "user.first_name,user.last_name"
  | "-user.first_name,-user.last_name"
  | "email"
  | "-email"
  | "products_count"
  | "-products_count"
  | "created_at"
  | "-created_at";

const generateRequestBody = (organizationId: string, sortParam: SortParam) => {
  const requestBody: Record<string, string> = {};
  requestBody["filter[organization_id]"] = organizationId;

  if (sortParam.trim() !== "") {
    requestBody.sort = sortParam;
  }

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const columnHelper = createColumnHelper<OrganizationUser>();

interface UsersTableProps {
  organizationId: string;
  organizationName?: string;
  organizationType?: string;
}

const UsersTable = ({
  organizationId,
  organizationName,
  organizationType
}: UsersTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortParam, setSortParam] = useState<SortParam>(
    "user.first_name,user.last_name"
  );

  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<null | OrganizationUser>(
    null
  );

  const [userProductModalToEditOrDelete, setUserProductModalToEditOrDelete] =
    useState<null | OrganizationUser>(null);
  const isEditOrDeleteUserProductModalOpen =
    userProductModalToEditOrDelete !== null;

  const [productToEdit, setProductToEdit] = useState<null | {
    id: string;
    name: string;
  }>(null);

  const { data: userData, refetch } = useQuery<OrganizationUser[]>([
    "organization-users",
    generateRequestBody(organizationId, sortParam)
  ]);

  const ascending = !sortParam.startsWith("-");
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";
  const sortBy = ascending ? sortParam : sortParam.replace(/-/g, "");

  const updateSortParam = useCallback(
    (newSortBy: SortParam) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;

      const sortFields = newSortBy.split(",");
      const newSortFields = sortFields.map(field =>
        newAscending ? field : `-${field.replace(/^-/, "")}`
      );
      const newSortParam = newSortFields.join(",") as SortParam;

      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const columns = [
    columnHelper.accessor("fullName", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          {info.getValue() || <em>Invite sent</em>}
        </div>
      ),
      header: () => {
        return (
          <div
            className={
              sortBy === "user.first_name,user.last_name"
                ? styles["sorter-active"]
                : ""
            }
          >
            <button
              type="button"
              onClick={() => updateSortParam("user.first_name,user.last_name")}
              onKeyPress={() =>
                updateSortParam("user.first_name,user.last_name")
              }
              tabIndex={-1}
            >
              Name
            </button>
            {sortBy === "user.first_name,user.last_name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("email", {
      id: "email",
      cell: info => (
        <div className={styles.center}>
          {info.getValue() || info.row.original.email}
        </div>
      ),
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "email" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("email")}
              onKeyPress={() => updateSortParam("email")}
              tabIndex={-1}
            >
              Email
            </button>
            {sortBy === "email" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("createdAt", {
      id: "dateCreated",
      cell: info => {
        const val = info.getValue();
        if (val !== undefined) {
          return (
            <div className={styles.center}>
              {new Intl.DateTimeFormat("en-US").format(new Date(val))}
            </div>
          );
        }
        return "";
      },
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "created_at" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("created_at")}
              onKeyPress={() => updateSortParam("created_at")}
            >
              Created On
            </button>
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("productsCount", {
      id: "products",
      cell: info => (
        <button
          data-testid={info.row.original.inviteEmail}
          type="button"
          tabIndex={-1}
          className={styles["users-button"]}
          onKeyPress={() => {
            setUserProductModalToEditOrDelete(info.row.original);
          }}
          onClick={() => {
            setUserProductModalToEditOrDelete(info.row.original);
          }}
        >
          {info.getValue()}
        </button>
      ),
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "products_count" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("products_count")}
              onKeyPress={() => updateSortParam("products_count")}
            >
              Products
            </button>
            {sortBy === "products_count" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("id", {
      id: "id",
      cell: info =>
        info.getValue() ? (
          <div className={styles["user-actions"]}>
            <Button
              outline
              color="danger"
              size="sm"
              className={styles["table-delete-button"]}
              data-testid="organizationsUsersTable_deleteUser"
              onClick={() => {
                setSelectedUser(info.row.original);
                setIsRemoveUserModalOpen(true);
              }}
            >
              Delete User
            </Button>
            <Link
              to={`/organizations/${organizationId}/users/${info.row.original?.id}?from=organization`}
            >
              <Button
                size="sm"
                outline
                data-testid="organizationsUsersTable_viewUser"
              >
                View User
              </Button>
            </Link>
          </div>
        ) : null,
      header: () => {
        return null;
      }
    })
  ];
  const table = useReactTable({
    data: userData || [],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={styles.container} data-testid="usersTable">
      {isRemoveUserModalOpen && (
        <RemoveUserModal
          redirect={false}
          close={() => setIsRemoveUserModalOpen(false)}
          selectedUser={selectedUser}
          organizationName={organizationName as string}
          organizationId={organizationId}
        />
      )}

      {isEditOrDeleteUserProductModalOpen && (
        <UserProductAccessModal
          table="usersTable"
          organizationUserId={userProductModalToEditOrDelete.id}
          setProductToEdit={setProductToEdit}
          refetch={refetch as () => void}
          handleClose={() => setUserProductModalToEditOrDelete(null)}
        />
      )}

      {productToEdit && (
        <ProductDrawer
          organizationId={organizationId as string}
          organizationName={organizationName}
          organizationType={organizationType}
          organizationTeamInvoiceId={productToEdit.id}
          refetch={() => {}}
          handleClose={() => setProductToEdit(null)}
        />
      )}

      <div className={styles.header}>
        <span>Users</span>
        <Button
          size="sm"
          outline
          onClick={() => setIsNewUserModalOpen(true)}
          data-testid="organizationsUsersTable_newUser"
        >
          <FontAwesomeIcon iconClassName="fa-plus-circle" />
          New User
        </Button>
        {isNewUserModalOpen && (
          <AddUserModal
            organizationId={organizationId}
            close={() => setIsNewUserModalOpen(false)}
          />
        )}
      </div>

      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                data-testid={`organization-users-table-row-${row.id}`}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    data-testid={`organization-users-table-${cell.column.id}-${row.id}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersTable;
