import React, { useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import { Button } from "@nef/core";

import useWizard from "../../hooks/useWizard";
import DatatableContext from "../../contexts/datatable";
import { PATHS } from "../../routes";
import useIsSubmitting from "../../hooks/useIsSubmitting";

import styles from "./Header.module.scss";

const Header = ({ backend = "postgres" }: { backend?: string }) => {
  const { steps, currentStepIndex, previousPath } = useWizard();

  const { datatable } = useContext(DatatableContext);

  const isDatatableNew = !datatable.version.code;
  const isDatatablePublished = datatable.version.code === "FINAL";

  const isFirstStep = currentStepIndex === 0;

  const hasMoreSteps =
    backend !== "s3" &&
    (isDatatableNew || currentStepIndex !== steps.length - 1);

  const currentStep = currentStepIndex > -1 ? steps[currentStepIndex] : null;

  const [isSubmitting, saveAndWait, finishAndWait] = useIsSubmitting([
    currentStep?.save,
    currentStep?.finish
  ]);

  const backToListingLink = () => {
    if (typeof datatable.vendorId === "number") {
      return generatePath(PATHS.VENDOR_DATATABLES, {
        vendorId: datatable.vendorId
      });
    }

    return generatePath(PATHS.DATATABLES);
  };

  return (
    <div className={styles.nav}>
      <div className={styles.navPrevious}>
        {isFirstStep ? (
          <Button
            as={Link}
            to={backToListingLink()}
            color="link"
            data-testid="datatablesHeader_backToListing"
          >
            &lt; Back To Listing
          </Button>
        ) : (
          <Button
            as={Link}
            to={previousPath}
            color="link"
            data-testid="datatablesHeader_previous"
          >
            &lt; Previous Step
          </Button>
        )}
      </div>

      <div className={styles.actions}>
        {backend === "s3" ? (
          <>
            {!isDatatablePublished && (
              <Button
                data-testid="datatablesHeader_save"
                outline={true}
                onClick={() => {
                  saveAndWait?.();
                }}
                disabled={isSubmitting}
              >
                Save
              </Button>
            )}
            <Button
              data-testid="datatablesHeader_finish"
              onClick={() => {
                finishAndWait?.();
              }}
              disabled={isSubmitting}
            >
              Finish
            </Button>
          </>
        ) : (
          <>
            {saveAndWait && hasMoreSteps && (
              <Button
                outline={true}
                onClick={saveAndWait}
                data-testid="datatablesHeader_nextStep"
                disabled={isSubmitting}
              >
                Next Step
              </Button>
            )}
            {saveAndWait && !hasMoreSteps && (
              <>
                {isDatatablePublished && (
                  <Button
                    as={Link}
                    to={generatePath(`${PATHS.DATATABLE_DATA}/data`, {
                      vendorCode: datatable.vendorCode,
                      datatableCode: datatable.code,
                      versionCode: datatable.version.code
                    })}
                    outline={true}
                    data-testid="datatablesHeader_previewDataTable"
                  >
                    Preview Data Table
                  </Button>
                )}
                <Button
                  outline={true}
                  onClick={saveAndWait}
                  disabled={isSubmitting}
                  data-testid={
                    isDatatablePublished
                      ? "datatablesHeader_update"
                      : "datatablesHeader_publish"
                  }
                >
                  {isDatatablePublished
                    ? isSubmitting
                      ? "Updating"
                      : "Update"
                    : isSubmitting
                    ? "Publishing"
                    : "Publish"}
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
