import React from "react";
import { Button, Tab, Tabs } from "@nef/core";

import useDefaultAuth from "../../hooks/useDefaultAuth";
import useWizard from "../../hooks/useWizard";
import { useProductContext } from "../../hooks/useProductContext";

import styles from "./Header.module.scss";
import FinalizeButton from "./FinalizeButton";

const Header = () => {
  const {
    steps,
    baseSteps,
    currentStepIndex,
    currentStepPath,
    canAdvanceTo,
    navigateTo
  } = useWizard();
  const currentStep = currentStepIndex > -1 ? steps[currentStepIndex] : null;

  const productContext = useProductContext();
  const { product } = productContext;
  const isPublished = product.active && !product.hidden;
  const { authenticatedUser } = useDefaultAuth();

  const isProductApprover =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  return (
    <div className={styles.nav} data-testid="productFormHeader">
      <div className={styles["nav-steps"]}>
        {baseSteps.length > 1 && (
          <Tabs>
            {baseSteps.map(step => {
              const pathSegments = currentStepPath.split("/");
              const active = step.path === pathSegments[0];

              return (
                <Tab
                  key={step.path}
                  className="nav-step"
                  onClick={() => navigateTo(step.path)}
                  disabled={!canAdvanceTo(step)}
                  data-testid={`productFormHeader_${step.path}`}
                  active={active}
                >
                  {step.name}
                </Tab>
              );
            })}
          </Tabs>
        )}
      </div>
      <div className={styles["nav-next"]}>
        {currentStep && currentStep.save && !isPublished && (
          <Button
            outline={true}
            onClick={() => currentStep.save && currentStep.save()}
            data-testid="productFormHeader_save"
          >
            {isProductApprover &&
            product?.productApprovalStatus === "pending_approval"
              ? "Save Changes"
              : "Save Session"}
          </Button>
        )}

        <FinalizeButton />
      </div>
    </div>
  );
};

export default Header;
