import { FontAwesomeIcon } from "@nef/core";
import React from "react";

import styles from "./Toast.module.scss";

interface ToastDetails {
  message: string;
  description?: string;
}
interface ToastProps {
  type: "success" | "info" | "error";
  title: string;
  details: ToastDetails[];
}

const TYPE_TO_ICON = {
  success: "fa-check-circle",
  error: "fa-minus-circle",
  info: "fa-info-circle"
};

const Toast = ({ type, title, details }: ToastProps) => {
  const icon = TYPE_TO_ICON[type];

  return (
    <div className={styles.toast} data-testid={`toast-${type}`}>
      <div className={`${styles.border} ${styles[`border-${type}`]}`} />
      <div
        className={`${styles.header} ${styles[`header-${type}`]}`}
        data-testid="toast_header"
      >
        <strong data-testid="toast_title">
          <FontAwesomeIcon iconClassName={icon} className={styles.icon} />
          {title}
        </strong>
      </div>
      <div>
        {details.map(detail => {
          return (
            <div className={styles.detail} key={detail.message}>
              <div className={styles.message} data-testid="toast_message">
                {detail.message}
              </div>
              <div
                className={styles.description}
                data-testid="toast_description"
              >
                {detail.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Toast;
