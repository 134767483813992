import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@nef/core";

import useFileManagerUpload, {
  type FileData
} from "../../hooks/useFileManagerUpload";

import { ViewMode } from "./ViewSelector";
import styles from "./FileExplorer.module.scss";
import TableView from "./TableView";
import GridView from "./GridView";
import NavBackIcon from "./NavBackIcon";
import CreateDirectoryButton from "./CreateDirectoryButton";

const Body = styled.div`
  flex: 1;
  padding: 1rem 0 5rem;
  background: ${(props: { isDragActive: boolean }) =>
    props.isDragActive ? "#cccccc" : undefined};
  & tr:hover {
    background: ${(props: { isDragActive: boolean }) =>
      props.isDragActive ? "#cccccc" : undefined};
  }
`;

const UploadButton = styled(Button)`
  margin-left: 1rem;
  height: 100%;
`;

const FileExplorer = ({
  fileData,
  viewMode,
  refetch,
  children,
  vendorId
}: React.PropsWithChildren<{
  fileData: FileData;
  viewMode: ViewMode;
  refetch?: () => void;
  vendorId: string;
}>) => {
  if (fileData.type !== "Directory")
    throw new Error("File explorer fileData type is not a directory");
  const { getRootProps, getInputProps, isDragActive, open } =
    useFileManagerUpload(fileData.path, vendorId, refetch);
  return (
    <Switch>
      <Route exact path={`/publishers/${vendorId}/file${fileData.path}`}>
        <div className={styles["file-screen-container"]}>
          {fileData.parentPath !== "" ? (
            <Link
              className={styles["back-button"]}
              to={`/publishers/${vendorId}/file${fileData.parentPath}`}
            >
              <NavBackIcon />
              BACK TO PREVIOUS LEVEL
            </Link>
          ) : null}
          <div className={styles.header}>
            <h1 className={styles.title} data-testid="folder_title">
              {fileData.path === "/" ? "File Management" : fileData.name}
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              {children}
              <CreateDirectoryButton
                prefix={fileData.path}
                groupId={vendorId}
                refetch={refetch}
              />
              <input
                {...getInputProps()}
                style={{ display: "none" }}
                data-testid="file_uploadInput"
              />
              <UploadButton
                data-testid="file_uploadButton"
                onClick={open}
                onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) =>
                  e.key === "Enter" && open()
                }
                type="button"
              >
                UPLOAD
              </UploadButton>
            </div>
          </div>
          <Body
            {...getRootProps({
              multiple: true
            })}
            isDragActive={isDragActive}
            data-testid="explorer_body"
          >
            {viewMode === ViewMode.Table && (
              <TableView
                fileData={fileData}
                refetch={refetch}
                vendorId={vendorId}
              />
            )}
            {viewMode === ViewMode.Default && (
              <GridView
                fileData={fileData}
                refetch={refetch}
                column={3}
                vendorId={vendorId}
              />
            )}
            {viewMode === ViewMode.Compact && (
              <GridView
                fileData={fileData}
                refetch={refetch}
                column={4}
                vendorId={vendorId}
              />
            )}
          </Body>
        </div>
      </Route>
      {fileData.children.map(
        (file: FileData) =>
          file.type === "Directory" && (
            <Route
              key={`/publishers/${vendorId}/file${file.path}`}
              path={`/publishers/${vendorId}/file${file.path}`}
            >
              <FileExplorer
                fileData={file}
                viewMode={viewMode}
                refetch={refetch}
                vendorId={vendorId}
              >
                {children}
              </FileExplorer>
            </Route>
          )
      )}
    </Switch>
  );
};

export default FileExplorer;
