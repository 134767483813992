import React from "react";
import { format } from "date-fns";

import { UploadEvent } from "../../api/types";

import styles from "./UploadLog.module.scss";

interface UploadLogProps {
  event: UploadEvent;
}

const parseErrors = (event: UploadEvent) => {
  if (!event.body?.errors?.length) return [];

  const errors: string[] = [];

  event.body.errors.forEach(error => {
    try {
      // using JSON.parse to handle case 2 (JSON stringified + newline character string)
      const parsedErrors: string[] = JSON.parse(error.replace(/\n/g, "\\n"));
      parsedErrors.forEach(parsedError => {
        errors.push(...parsedError.split("\n").filter(e => !!e));
      });
    } catch (e) {
      // assume anything non-parsable error string to be plain, readable string
      errors.push(error);
    }
  });

  return errors;
};

const UploadLog = ({ event }: UploadLogProps) => {
  const errors = parseErrors(event);

  return (
    <div className={styles.log} data-testid="uploadLog">
      <div className={styles.attribute}>
        <div
          className={styles["attribute-label"]}
          data-testid="uploadLogLabel_createdAt"
        >
          Created At
        </div>
        <div
          className={styles["attribute-value"]}
          data-testid="uploadLogValue_createdAt"
        >
          {format(new Date(event.createdAt), "d/M/yyyy hh:mm a")}
        </div>
      </div>

      <div className={styles.attribute}>
        <div
          className={styles["attribute-label"]}
          data-testid="uploadLogLabel_media"
        >
          Media
        </div>
        <div
          className={styles["attribute-value"]}
          data-testid="uploadLogValue_media"
        >
          {event.media}
        </div>
      </div>

      <div className={styles.attribute}>
        <div
          className={styles["attribute-label"]}
          data-testid="uploadLogLabel_status"
        >
          Status
        </div>
        <div
          data-testid="uploadLogValue_status"
          className={`${styles["attribute-value"]} ${
            event.status === "succeeded"
              ? styles["attribute-value--success"]
              : ""
          } ${
            event.status === "failed" ? styles["attribute-value--error"] : ""
          }`}
        >
          {event.status}
        </div>
      </div>

      {errors.length > 0 && (
        <>
          <div className={styles.attribute} data-testid="uploadLogLabel_errors">
            <div className={styles["attribute-label"]}>Errors</div>
            <div className={styles["attribute-value"]}>
              {errors.map((e, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} data-testid="uploadLogValue_error">
                    {e}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.attribute}>
            <div
              className={styles["attribute-label"]}
              data-testid="uploadLogLabel_fix"
            >
              How To Fix
            </div>
            <div
              className={styles["attribute-value"]}
              data-testid="uploadLogValue_fix"
            >
              <a
                href="https://help.manager.data.nasdaq.com/article/678-im-checking-my-schema-but-i-see-status-failed-what-should-i-do"
                target="_blank"
                rel="noreferrer"
              >
                Please see this article for pointers
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UploadLog;
