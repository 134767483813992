import { Validation } from "./types";

const COLUMN_HEADERS = [
  "ColumnName",
  "ColumnDefinition",
  "DataType",
  "PrimaryKey",
  "Filter",
  "Currency",
  "Unit",
  "Timezone",
  "Minimum",
  "Maximum",
  "Notes"
];

const COLUMN_HEADERS_STRING = COLUMN_HEADERS.join(",");

type HeaderFormatObject = {
  tableNameLabel: string;
  columnHeaders: string[];
  columnHeaderDescriptions: string[];
};

const HeaderFormatValidation: Validation<HeaderFormatObject> = {
  extractData: headers => {
    const tableNameLabel = headers[1][0] || "";
    const columnHeaders = headers[2];
    const columnHeaderDescriptions = headers[3];

    return {
      tableNameLabel,
      columnHeaders,
      columnHeaderDescriptions
    };
  },
  validate: data => {
    const invalidLabels: string[] = [];
    const { tableNameLabel, columnHeaders, columnHeaderDescriptions } = data;

    if (tableNameLabel !== "TableName") {
      invalidLabels.push("Table Name Label");
    }

    if (
      !Array.isArray(columnHeaders) ||
      columnHeaders.join(",") !== COLUMN_HEADERS_STRING
    ) {
      invalidLabels.push("Column Headers");
    }

    if (!Array.isArray(columnHeaderDescriptions)) {
      invalidLabels.push("Column Header Descriptions");
    }

    if (invalidLabels.length === 0) {
      return [];
    }

    return [
      {
        type: "header-format-invalid",
        title: `The file does not contain valid header format for ${invalidLabels.join(
          ", "
        )}`,
        message:
          "Please re-upload a new table schema using the provided template"
      }
    ];
  }
};

export default HeaderFormatValidation;
