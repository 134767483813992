import { decamelizeKeys } from "humps";

// eslint-disable-next-line import/prefer-default-export
export const createProductBody = (
  product: any,
  isSubmittingForApproval = false,
  isManagerAdmin = false
) => {
  const normalizedProduct: any = decamelizeKeys(product, {
    separator: "-"
  });
  normalizedProduct.shares = { [normalizedProduct.vendor.id]: 1 };

  normalizedProduct["data-frequency"] =
    normalizedProduct["data-frequency"].join(",");
  normalizedProduct["delivery-frequency"] =
    normalizedProduct["delivery-frequency"].join(",");

  delete normalizedProduct["product-approval-type"];
  delete normalizedProduct.code;
  delete normalizedProduct.premium;
  delete normalizedProduct["published-at"];
  delete normalizedProduct["featured-at"];
  delete normalizedProduct["product-type"];
  delete normalizedProduct["can-show-in-multiple-indexes"];
  delete normalizedProduct["income-account-ref"];
  delete normalizedProduct["created-at"];
  delete normalizedProduct["updated-at"];
  delete normalizedProduct["product-models-updated-at"];
  delete normalizedProduct["product-models-updated-by"];
  delete normalizedProduct["product-management-group-id"];
  delete normalizedProduct["vendor-id"];
  delete normalizedProduct.internal;
  delete normalizedProduct.vendor;
  delete normalizedProduct["for-sale"];
  delete normalizedProduct["administrator-contact-vendor-user-id"];
  delete normalizedProduct["skip-intake"];

  if (isSubmittingForApproval) {
    normalizedProduct["product-approval-status"] = "pending_approval";
  } else {
    delete normalizedProduct["product-approval-status"];
  }

  if (!isManagerAdmin) {
    delete normalizedProduct.exclusive;
    delete normalizedProduct["search-tags"];
    delete normalizedProduct["recommended-product-codes"];
  }

  return normalizedProduct;
};
