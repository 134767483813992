import React from "react";
import { Modal, ModalBody } from "@nef/core";

import { Wizard, Step } from "../../../components/wizard";

import AiSkillModalFooter from "./new-skill-modal-footer";

export default function NewSkillModal({
  isOpen,
  close
}: {
  isOpen: boolean;
  close: () => void;
}) {
  const handleClose = () => {
    close();
  };
  return (
    <Modal
      isOpen={isOpen}
      data-testid="newSkillModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalBody>
        <Wizard stateNavigator={true} header={<div />}>
          <Step path="1" name="Step 1" canAdvance={true}>
            Step1
            <AiSkillModalFooter
              save={() => Promise.resolve()}
              cancel={close}
              canAdvance={true}
            />
          </Step>
          <Step path="2" name="Step 2" canAdvance={true}>
            Step2
            <AiSkillModalFooter
              save={() => Promise.resolve()}
              cancel={close}
              canAdvance={true}
            />
          </Step>
        </Wizard>
      </ModalBody>
    </Modal>
  );
}
