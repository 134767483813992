import React, { useMemo } from "react";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { useFormContext, Controller } from "react-hook-form";

const MarkdownEditor = () => {
  const { control } = useFormContext();
  const documentEditorOptions = useMemo(() => {
    return {
      spellChecker: false
    };
  }, []);

  return (
    <Controller
      name="documentation"
      control={control}
      render={({ field: { onChange, value } }) => (
        <SimpleMDE
          style={{ width: "100%" }}
          value={value}
          options={documentEditorOptions}
          onChange={onChange}
          data-testid="productDetailForm_documentation"
        />
      )}
    />
  );
};

export default MarkdownEditor;
