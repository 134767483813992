import React, { useEffect, useState } from "react";
import { Button, Drawer, DrawerHeader, DrawerBody } from "@nef/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "jsonapi-react";
import { decamelizeKeys } from "humps";
import moment from "moment";
import { toast } from "react-toastify";
import { difference } from "lodash";

import { createOrganizationOnboarding } from "../../../../api/api";
import Toast from "../../../../components/Toast";

import styles from "./index.module.scss";
import { formSchema } from "./schema";
import ProductSearch from "./product-search";
import { ProductAccessOption } from "./types";
import { FormDatatable } from "./datatable/types";

export interface SalesManager {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Product {
  name: string;
  code: string;
  productType: string;
  datatables: { code: string }[];
}

interface User {
  name: string;
  email: string;
  fullName: string;
}
interface UserGroup {
  name: string;
  id: string;
}

interface ProductAccess {
  users: User[];
  userGroups: UserGroup[];
}

export interface OrganizationTeamInvoice {
  productName: string;
  productCode: string;
  autoRenewal: boolean | null;
  comments: string;
  endDate: string;
  startDate: string;
  salesManagerId: string;
  trial: boolean;
  subscriptionType: string;
  productAccess: ProductAccess;
  businessContacts: string[];
  technicalContacts: string[];
  welcomeEmailCc: string[];
  filters: {
    datatableCode: string;
    filters: [];
    columns: {
      [key: string]: {
        name: string;
        type: string;
      }[];
    };
  }[];
  datatableCodes: { code: string }[];
}

interface Props {
  organizationId: string;
  organizationName?: string;
  organizationType?: string;
  organizationTeamInvoiceId?: string;
  handleClose: () => void;
  refetch: () => void;
}

interface FormData {
  newProducts: any[];
  newProductKeys: { value: Product }[];
  singleProductControl: boolean;
}

export const splitCombinedOperators = (filters: any) => {
  const createFilter = (filter: any, operator: string, isNew: boolean) => {
    const baseFilter = {
      ...filter,
      operator,
      value: isNew ? filter.newValue : filter.value ? filter.value : "",
      numberValue: isNew ? filter.newNumberValue : filter.numberValue,
      dateValue: isNew ? filter.newDateValue : filter.dateValue
    };

    delete baseFilter.newValue;
    delete baseFilter.newNumberValue;
    delete baseFilter.newDateValue;

    return baseFilter;
  };

  const filteredMap = new Map();

  filters.forEach((filter: any) => {
    if (filter.operator === "lt / gt") {
      filteredMap.set(filter.key, [
        createFilter(filter, "lt", false),
        createFilter(filter, "gt", true)
      ]);
    } else if (filter.operator === "lte / gte") {
      filteredMap.set(filter.key, [
        createFilter(filter, "lte", false),
        createFilter(filter, "gte", true)
      ]);
    }

    if (!filteredMap.has(filter.key)) {
      filteredMap.set(filter.key, createFilter(filter, filter.operator, false));
    }
  });

  return Array.from(filteredMap.values()).flat();
};

const processContactsArray = (
  contactsArray: { value: string; label: string }[]
) => {
  return contactsArray.length > 0 && typeof contactsArray[0] === "object"
    ? contactsArray.map((item: { value: string }) => item.value)
    : contactsArray;
};

const processDatatablesArray = (
  datatables: {
    [datatableCode: string]: FormDatatable;
  },
  productDatatableCodes: { code: string }[]
) => {
  let formattedDatatables = [];

  if (Object.keys(datatables).length > 0) {
    formattedDatatables = Object.keys(datatables).map(datatableCode => {
      const currentDatatable = datatables[datatableCode];
      const datatableFilters = Object.keys(currentDatatable?.filters);

      const updatedFilters: any = {};

      datatableFilters?.forEach(filterVersion => {
        updatedFilters[filterVersion] = splitCombinedOperators(
          currentDatatable.filters[filterVersion]
        );
      });

      let updatedColumns = {};

      Object.keys(currentDatatable.selectedColumns).forEach(version => {
        updatedColumns = {
          ...updatedColumns,
          [version]:
            currentDatatable.selectedColumns[version]?.length === 0
              ? []
              : difference(
                  currentDatatable.diffColumns[version],
                  currentDatatable.selectedColumns[version]
                )
        };
      });

      return {
        datatable_code: datatableCode,
        filters: updatedFilters,
        columns: updatedColumns
      };
    });
  } else {
    formattedDatatables = productDatatableCodes.map(datatable => {
      return {
        datatable_code: datatable.code,
        columns: [],
        filters: []
      };
    });
  }

  return formattedDatatables;
};

const ProductDrawer = ({
  organizationId,
  organizationName,
  organizationType,
  organizationTeamInvoiceId,
  handleClose,
  refetch
}: Props) => {
  const [inputProductValue, setInputProductValue] = useState("");

  const { data: existingInvoice, isLoading: isInvoiceLoading } =
    useQuery<OrganizationTeamInvoice>(
      organizationTeamInvoiceId && [
        `organization-team-invoices/${organizationTeamInvoiceId}`
      ]
    );

  const { data: salesManagers } = useQuery<SalesManager[]>([
    "users/sales_managers"
  ]);

  const { data: products } = useQuery<Product[]>(
    !organizationTeamInvoiceId &&
      inputProductValue && [
        "datatable-collections",
        {
          "fields[datatable-collections]": "name,code,datatables",
          "filter[search_by_name_or_code]": inputProductValue,
          "filter[active]": true
        }
      ]
  );

  const productForm = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues: {
      newProducts: [],
      newProductKeys: [],
      singleProductControl: true
    }
  });

  const { handleSubmit, setValue } = productForm;
  const onSubmit: SubmitHandler<FormData> = async data => {
    const payload = {
      organization: {
        id: organizationId,
        name: organizationName,
        type: organizationType?.toLowerCase()
      },
      products: [] as any,
      is_manager_entitlement: true,
      single_product_control: data.singleProductControl
    };

    const productsCount = existingInvoice ? 1 : data.newProductKeys.length;

    for (let i = 0; i < productsCount; i++) {
      const newProductIndex = data.singleProductControl ? 0 : i;

      payload.products.push({
        ...data.newProducts[newProductIndex],

        comments: data.newProducts[newProductIndex].notes,
        salesManagerId: data.newProducts[newProductIndex].salesRepresentative,
        autoRenewal:
          data.newProducts[newProductIndex].subscriptionType === "autoRenew",
        type:
          data.newProducts[newProductIndex].subscriptionType === "trial"
            ? "trial"
            : "subscription",
        name: existingInvoice
          ? existingInvoice.productName
          : data.newProductKeys[i].value.name,
        code: existingInvoice
          ? existingInvoice.productCode
          : data.newProductKeys[i].value.code,
        productType: "DatatableCollection",
        organizationTeamInvoiceId,
        productAccess: {
          users:
            data.newProducts[newProductIndex]?.productAccess?.users?.map(
              (user: ProductAccessOption) => user.value
            ) ?? [],
          userGroups:
            data.newProducts[newProductIndex]?.productAccess?.userGroups?.map(
              (group: ProductAccessOption) => group.value
            ) ?? []
        },
        businessContacts: processContactsArray(
          data.newProducts[newProductIndex].businessContacts
        ),
        technicalContacts: processContactsArray(
          data.newProducts[newProductIndex].technicalContacts
        ),
        welcomeEmailCc: processContactsArray(
          data.newProducts[newProductIndex].welcomeEmailCc || []
        ),
        datatables: processDatatablesArray(
          data.newProducts[newProductIndex].datatables || {},
          data.newProductKeys[i]?.value?.datatables || []
        )
      });
    }

    const response = await createOrganizationOnboarding(
      decamelizeKeys(payload)
    );

    if (response.status !== 200) {
      const { error, errors } = response.data;
      const responseErrors = error ? [error] : errors;

      if (responseErrors) {
        toast(
          <Toast
            type="error"
            title={`There was an error ${
              organizationTeamInvoiceId ? "updating" : "creating"
            } the entitlement(s)`}
            details={[]}
          />
        );
        handleClose();
        return;
      }
    }
    toast(
      <Toast
        type="success"
        title={`Entitlement(s) has been successfully ${
          organizationTeamInvoiceId ? "updated" : "created"
        }`}
        details={[]}
      />
    );
    refetch();
    handleClose();
  };

  useEffect(() => {
    if (!isInvoiceLoading && existingInvoice) {
      setValue("newProducts", [
        {
          subscriptionType: existingInvoice.autoRenewal
            ? "autoRenew"
            : existingInvoice.trial
            ? "trial"
            : "fixedTerm",
          accessStart: existingInvoice.startDate
            ? moment(existingInvoice.startDate)
            : null,
          accessEnd: existingInvoice.endDate
            ? moment(existingInvoice.endDate)
            : null,
          notes: existingInvoice.comments,
          salesRepresentative: existingInvoice.salesManagerId.toString(),
          productAccess: {
            users: existingInvoice?.productAccess?.users.map(
              (user: User): ProductAccessOption => ({
                label: user.fullName || user.email,
                type: "User",
                value: user.email
              })
            ),
            userGroups: existingInvoice?.productAccess?.userGroups.map(
              (group: UserGroup): ProductAccessOption => ({
                label: group.name,
                type: "User Group",
                value: group.id
              })
            )
          },
          businessContacts: existingInvoice.businessContacts?.map(email => ({
            label: email,
            value: email
          })),
          technicalContacts: existingInvoice.technicalContacts?.map(email => ({
            label: email,
            value: email
          })),
          welcomeEmailCc: existingInvoice.welcomeEmailCc?.map(email => ({
            label: email,
            value: email
          }))
        }
      ]);
    }
  }, [isInvoiceLoading, existingInvoice, setValue]);

  return (
    <Drawer
      toggle={handleClose}
      isOpen={true}
      backdrop={false}
      className={styles.drawer}
      data-testid="OrganizationsAddProductDrawer"
    >
      {!isInvoiceLoading && (
        <>
          <DrawerHeader
            className={styles.header}
            toggle={handleClose}
            title={
              existingInvoice ? existingInvoice.productName : "Add Products"
            }
            data-testid="OrganizationsAddProductDrawer_header"
          />
          <DrawerBody className={styles.body}>
            <FormProvider {...productForm}>
              <div
                className={styles.search}
                data-testid="OrganizationsAddProductDrawer_formContainer"
              >
                <ProductSearch
                  products={products || []}
                  salesManagers={salesManagers || []}
                  existingInvoice={existingInvoice as OrganizationTeamInvoice}
                  inputProductValue={inputProductValue}
                  setInputProductValue={setInputProductValue}
                />
              </div>
              <div className={styles["footer-buttons-container"]}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  data-testid="OrganizationsAddProductDrawer_submit"
                >
                  Submit
                </Button>
                <Button
                  data-testid="OrganizationsAddProductDrawer_cancel"
                  color="light"
                  outline
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </div>
            </FormProvider>
          </DrawerBody>
        </>
      )}
    </Drawer>
  );
};

export default ProductDrawer;
