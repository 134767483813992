import React, { useState } from "react";
import { StringMap, useMutation } from "jsonapi-react";
import { decamelizeKeys } from "humps";

import {
  AllCapFormProductData,
  CapProductMetadatum,
  CapProductMutationResponse,
  CapProductToCreate,
  CapProductToEdit,
  ProductMetadatum,
  ProductMetadatumToEdit
} from "../../api/types";
import { Step, Wizard } from "../wizard";
import CapProductContext, {
  CapProductContextProps
} from "../../contexts/cap-product";
import { createProductBody } from "../../api/normalizers/product";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import buildCapProductSchema from "./schema";
import CapSurveyFormHeader from "./CapSurveyFormHeader";
import { CapProductFieldPath } from "./types";
import { handleCapProductResponseToast } from "./cap-product-util";
import CapSurveyFormComplete from "./steps/CapSurveyFormComplete";
import CapWizardRedirect from "./CapWizardRedirect";
import IntakeFormSection6Internal from "./steps/IntakeFormSection6Internal";
import IntakeFormSection7Internal from "./steps/IntakeFormSection7Internal";
import IntakeFormSection8Internal from "./steps/IntakeFormSection8Internal";
import IntakeFormSection9External from "./steps/IntakeFormSection9External";
import IntakeFormStep9Internal from "./steps/IntakeFormStep9Internal";
import IntakeFormStep1 from "./steps/IntakeFormStep1";
import IntakeFormStep2 from "./steps/IntakeFormStep2";
import IntakeFormStep4 from "./steps/IntakeFormStep4";
import IntakeFormStep3 from "./steps/IntakeFormStep3";
import IntakeFormIntro from "./steps/IntakeFormIntro";
import IntakeFormStep5 from "./steps/IntakeFormStep5";
import styles from "./CapSurveyForm.module.scss";

type CapSurveyFormProps = {
  product: CapProductToEdit;
  isReady: boolean;
};

const CapSurveyForm = ({ product, isReady }: CapSurveyFormProps) => {
  const { authenticatedUser } = useDefaultAuth();
  const isManagerAdmin =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  const productSchema = buildCapProductSchema();

  const [updateProduct] = useMutation<CapProductMutationResponse>([
    "products",
    product.id
  ]);

  const [updateCapProductMetadataum] = useMutation<CapProductMetadatum>(
    ["cap-product-metadata", product?.capProductMetadatum?.id],
    {
      invalidate: ["products"]
    }
  );

  const [updateProductMetadataum] = useMutation<ProductMetadatum>(
    ["product-metadata", product?.productMetadatum?.id],
    {
      invalidate: ["products"]
    }
  );

  const saveCapProduct = async (
    formData: CapProductToCreate | CapProductToEdit
  ) => {
    const normalizedProduct = createProductBody(
      formData,
      false,
      isManagerAdmin
    );
    normalizedProduct["gis-sales-team-email"] = null;
    delete normalizedProduct["cap-product-metadatum"];

    const productResponse = await saveProduct(normalizedProduct);

    return productResponse;
  };

  const saveProduct = async (normalizedProduct: any) => {
    const { data, error, errors } = await updateProduct(normalizedProduct);
    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    return { data, errors: allErrors };
  };

  const saveCapMetadata = async (formData: CapProductMetadatum) => {
    const normalizedMetadata: any = decamelizeKeys(formData, {
      separator: "-"
    });

    if (normalizedMetadata["vendor-id"] === -1)
      normalizedMetadata["vendor-id"] = null;

    if (normalizedMetadata["data-source"] !== "Third-party vendors") {
      normalizedMetadata["licensed-from-vendor"] = false;
      normalizedMetadata["vendor-id"] = null;
      normalizedMetadata["vendor-name"] = null;
      normalizedMetadata["vendor-contact-name"] = null;
      normalizedMetadata["vendor-contact-email"] = null;
      normalizedMetadata["vendor-contact-phone"] = null;
    } else {
      normalizedMetadata["data-licence-terms-conditions"] = null;
    }

    if (!normalizedMetadata["licensed-from-vendor"])
      normalizedMetadata["third-party-provider-licence"] = null;

    const { data, error, errors } = await updateCapProductMetadataum(
      normalizedMetadata
    );

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    return { data, errors: allErrors };
  };

  const saveMetadata = async (formData: ProductMetadatumToEdit) => {
    const normalizedMetadata: any = decamelizeKeys(formData, {
      separator: "-"
    });

    const { data, error, errors } = await updateProductMetadataum(
      normalizedMetadata
    );

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    return { data, errors: allErrors };
  };

  const saveAllData = async ({
    capProduct,
    metadata,
    capMetadata
  }: AllCapFormProductData) => {
    let productResponse;
    let metadataResponse;
    let capMetadataResponse;

    if (capProduct) {
      productResponse = await saveCapProduct(capProduct);
    }
    if (metadata) {
      metadataResponse = await saveMetadata(metadata);
    }
    if (capMetadata) {
      capMetadataResponse = await saveCapMetadata(capMetadata);
    }

    const allErrors = [
      ...(productResponse?.errors || []),
      metadataResponse?.errors || [],
      capMetadataResponse?.errors || []
    ]
      .filter((e): e is StringMap => !!e)
      .flat();

    handleCapProductResponseToast({
      productName: product.name,
      isPublished: product.active,
      errors: allErrors
    });

    if (allErrors.length) {
      throw allErrors;
    }

    return {
      product: productResponse,
      metadata: metadataResponse,
      capMetadata: capMetadataResponse
    };
  };

  /**
   * ProductContext
   */
  const contextValue: CapProductContextProps = {
    isReady,
    product,
    saveProduct: saveCapProduct,
    saveCapMetadata,
    saveMetadata,
    saveAllData
  };

  const stepFields: CapProductFieldPath[][] = [
    // section 1
    ["name", "description", "overview", "capProductMetadatum.pricingOption"],
    [
      "coverage",
      "history",
      "since",
      "productMetadatum.frameworkRegulationNotes"
    ],
    [
      "capProductMetadatum.tags",
      "capProductMetadatum.dataHosting",
      "capProductMetadatum.datatablesNameDescriptions"
    ],
    [
      "capProductMetadatum.knownIssues",
      "capProductMetadatum.sampleDataUrl",
      "capProductMetadatum.relatedData",
      "capProductMetadatum.additionalMaterialUrls"
    ],
    // section 2
    [
      "dataFrequency",
      "dataFrequencyNotes",
      "deliveryFrequency",
      "deliveryFrequencyNotes",
      "reportingLag"
    ],
    [
      "capProductMetadatum.deliveryLag",
      "capProductMetadatum.pointInTime",
      "capProductMetadatum.dataTimezone",
      "capProductMetadatum.dataHolidaySchedule"
    ],
    // section 3
    [
      "capProductMetadatum.infosecClassification",
      "capProductMetadatum.gdprRestrictions",
      "capProductMetadatum.gdprRestrictionsNotes",
      "capProductMetadatum.personallyIdentifiableInfo",
      "capProductMetadatum.secRegulation"
    ],
    // section 4
    [
      "capProductMetadatum.dataSource",
      "capProductMetadatum.dataSourceNotes",
      "capProductMetadatum.dataProvenance",
      "capProductMetadatum.dataOriginalOrDerived"
    ],
    [
      "capProductMetadatum.licensedFromVendor",
      "capProductMetadatum.vendorName",
      "capProductMetadatum.vendorId",
      "capProductMetadatum.vendorContactName",
      "capProductMetadatum.vendorContactEmail",
      "capProductMetadatum.vendorContactPhone",
      "capProductMetadatum.thirdPartyProviderLicence",
      "capProductMetadatum.dataLicenceTermsConditions",
      "capProductMetadatum.procurementContractualConsiderations"
    ],
    // section 5
    ["capProductMetadatum.visibilityPreference"],
    // section 6
    [
      "capProductMetadatum.dataSourceFormat",
      "capProductMetadatum.dataSourceFormatNotes",
      "capProductMetadatum.dataIngestMethod",
      "capProductMetadatum.dataIngestMethodNotes",
      "capProductMetadatum.dataSize",
      "capProductMetadatum.dataUpdateSize",
      "capProductMetadatum.updatePattern",
      "capProductMetadatum.updatePatternNotes"
    ],
    // Section 7
    [
      "capProductMetadatum.dataOwnerName",
      "capProductMetadatum.dataOwnerEmail",
      "capProductMetadatum.technicalContactName",
      "capProductMetadatum.technicalContactEmail"
    ]
  ];

  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);

  return (
    <CapProductContext.Provider value={contextValue}>
      <div className={styles.container}>
        <Wizard
          header={<CapSurveyFormHeader isDirty={formIsDirty} />}
          redirects={<CapWizardRedirect />}
        >
          <Step
            path="intro"
            name="Intro"
            stepSectionTitle="Data Product Intake Form"
            canAdvance={true}
          >
            <IntakeFormIntro />
          </Step>
          <Step
            path="step-1"
            name="Step 1"
            stepSectionTitle="Product Details"
            canAdvance={productSchema
              .pickNested(stepFields[0])
              .isValidSync(product)}
          >
            <IntakeFormStep1 fields={stepFields[0]} />
          </Step>
          <Step
            path="step-2"
            name="Product Details - Step 2"
            stepSectionTitle="Product Details"
            canAdvance={productSchema
              .pickNested(stepFields[1])
              .isValidSync(product)}
          >
            <IntakeFormStep2
              fields={stepFields[1]}
              setFormIsDirty={setFormIsDirty}
            />
          </Step>
          <Step
            path="step-3"
            name="Product Details - Step 3"
            stepSectionTitle="Product Details"
            canAdvance={productSchema
              .pickNested(stepFields[2])
              .isValidSync(product)}
          >
            <IntakeFormStep3
              fields={stepFields[2]}
              setFormIsDirty={setFormIsDirty}
            />
          </Step>

          <Step
            path="step-4"
            name="Product Details - Step 4"
            stepSectionTitle="Product Details"
            canAdvance={productSchema
              .pickNested(stepFields[3])
              .isValidSync(product)}
          >
            <IntakeFormStep4
              fields={stepFields[3]}
              setFormIsDirty={setFormIsDirty}
            />
          </Step>

          {product.internal ? (
            <>
              <Step
                path="step-5"
                name="Regulations"
                stepSectionTitle="Regulations"
                canAdvance={productSchema
                  .pickNested([
                    "capProductMetadatum.infosecClassification",
                    "capProductMetadatum.gdprRestrictions",
                    "capProductMetadatum.gdprRestrictionsNotes",
                    "capProductMetadatum.personallyIdentifiableInfo",
                    "capProductMetadatum.secRegulation"
                  ])
                  .isValidSync(product)}
              >
                <IntakeFormStep5
                  fields={[
                    "capProductMetadatum.infosecClassification",
                    "capProductMetadatum.gdprRestrictions",
                    "capProductMetadatum.gdprRestrictionsNotes",
                    "capProductMetadatum.personallyIdentifiableInfo",
                    "capProductMetadatum.secRegulation"
                  ]}
                  setFormIsDirty={setFormIsDirty}
                />
              </Step>
              <Step
                path="step-6"
                name="Product Source, Licensing, and Distribution"
                stepSectionTitle="Product Source, Licensing, and Distribution"
                canAdvance={productSchema
                  .pickNested([
                    "capProductMetadatum.dataSource",
                    "capProductMetadatum.dataSourceNotes",
                    "capProductMetadatum.dataProvenance",
                    "capProductMetadatum.dataOriginalOrDerived"
                  ])
                  .isValidSync(product)}
              >
                <IntakeFormSection6Internal
                  fields={[
                    "capProductMetadatum.dataSource",
                    "capProductMetadatum.dataSourceNotes",
                    "capProductMetadatum.dataProvenance",
                    "capProductMetadatum.dataOriginalOrDerived"
                  ]}
                  setFormIsDirty={setFormIsDirty}
                />
              </Step>

              <Step
                path="step-7"
                name="Product Source, Licensing, and Distribution"
                stepSectionTitle="Product Source, Licensing, and Distribution"
                canAdvance={productSchema
                  .pickNested([
                    "capProductMetadatum.dataLicenceTermsConditions",
                    "capProductMetadatum.vendorContactName",
                    "capProductMetadatum.vendorContactEmail",
                    "capProductMetadatum.vendorContactPhone",
                    "capProductMetadatum.procurementContractualConsiderations",
                    "capProductMetadatum.procurementDocumentUrls"
                  ])
                  .isValidSync(product)}
              >
                <IntakeFormSection7Internal
                  fields={[
                    "capProductMetadatum.licensedFromVendor",
                    "capProductMetadatum.thirdPartyProviderLicence",
                    "capProductMetadatum.dataLicenceTermsConditions",
                    "capProductMetadatum.vendorContactName",
                    "capProductMetadatum.vendorContactEmail",
                    "capProductMetadatum.vendorContactPhone",
                    "capProductMetadatum.procurementContractualConsiderations",
                    "capProductMetadatum.procurementDocumentUrls"
                  ]}
                  setFormIsDirty={setFormIsDirty}
                />
              </Step>

              <Step
                path="step-8"
                name="Visibility and Discoverability"
                stepSectionTitle="Visibility and Discoverability"
                canAdvance={productSchema
                  .pickNested(["capProductMetadatum.visibilityPreference"])
                  .isValidSync(product)}
              >
                <IntakeFormSection8Internal
                  fields={["capProductMetadatum.visibilityPreference"]}
                  setFormIsDirty={setFormIsDirty}
                />
              </Step>
              <Step
                path="step-9"
                name="Contact Information"
                stepSectionTitle="Contact Information"
                canAdvance={productSchema
                  .pickNested([
                    "capProductMetadatum.dataOwnerName",
                    "capProductMetadatum.dataOwnerEmail",
                    "capProductMetadatum.technicalContactName",
                    "capProductMetadatum.technicalContactEmail"
                  ])
                  .isValidSync(product)}
              >
                <IntakeFormStep9Internal
                  fields={[
                    "capProductMetadatum.dataOwnerName",
                    "capProductMetadatum.dataOwnerEmail",
                    "capProductMetadatum.technicalContactName",
                    "capProductMetadatum.technicalContactEmail"
                  ]}
                  setFormIsDirty={setFormIsDirty}
                />
              </Step>
            </>
          ) : (
            <>
              <Step
                path="step-5"
                name="Regulations"
                stepSectionTitle="Regulations"
                canAdvance={productSchema
                  .pickNested([
                    "capProductMetadatum.gdprRestrictions",
                    "capProductMetadatum.gdprRestrictionsNotes",
                    "capProductMetadatum.personallyIdentifiableInfo",
                    "capProductMetadatum.secRegulation"
                  ])
                  .isValidSync(product)}
              >
                <IntakeFormStep5
                  fields={[
                    "capProductMetadatum.gdprRestrictions",
                    "capProductMetadatum.gdprRestrictionsNotes",
                    "capProductMetadatum.personallyIdentifiableInfo",
                    "capProductMetadatum.secRegulation"
                  ]}
                  setFormIsDirty={setFormIsDirty}
                />
              </Step>
              <Step
                path="step-9"
                name="Contact Information"
                stepSectionTitle="Contact Information"
                canAdvance={productSchema
                  .pickNested([
                    "capProductMetadatum.technicalContactName",
                    "capProductMetadatum.technicalContactEmail"
                  ])
                  .isValidSync(product)}
              >
                <IntakeFormSection9External
                  fields={[
                    "capProductMetadatum.technicalContactName",
                    "capProductMetadatum.technicalContactEmail"
                  ]}
                  setFormIsDirty={setFormIsDirty}
                />
              </Step>
            </>
          )}

          <Step
            path="complete"
            name="Complete"
            stepSectionTitle="Complete"
            canAdvance={false}
          >
            <CapSurveyFormComplete />
          </Step>
        </Wizard>
      </div>
    </CapProductContext.Provider>
  );
};

export default CapSurveyForm;
