import React from "react";

import { Wizard, Step } from "../wizard";
import { DatatablesNameDescription } from "../../api/types";
import { buildHostedDatatableSchema } from "../cap-survey-form/schema";

import IntakeDatatableModalHeader from "./IntakeDatatableModalHeader";
import HostedDatatableWizardStep1 from "./HostedDatatableWizardStep1";
import HostedDatatableWizardStep2 from "./HostedDatatableWizardStep2";
import HostedDatatableWizardStep3 from "./HostedDatatableWizardStep3";
import HostedDatatableWizardStep4 from "./HostedDatatableWizardStep4";
import { IntakeProductDatatableFieldPath } from "./types";

interface HostedDatatableWizardProps {
  datatable: DatatablesNameDescription;
  onDismiss: () => void;
  onDiscard: () => void;
  onPreviousStep: (formState: any) => void;
  saveStep: (
    formValues: Partial<DatatablesNameDescription>,
    submit: boolean
  ) => void;
  existedCodes: string[];
}

const HostedDatatableWizard = ({
  datatable,
  onDismiss,
  onDiscard,
  onPreviousStep,
  saveStep,
  existedCodes
}: HostedDatatableWizardProps) => {
  const stepFields: IntakeProductDatatableFieldPath[][] = [
    ["name", "code", "description", "sampleDataUrl", "schemaFileUrl"],
    [
      "dataGranularity",
      "dataGranularityNotes",
      "updateFrequency",
      "reportingLag"
    ],
    [
      "deliveryLag",
      "pointInTime",
      "holidayImpacts",
      "customHolidayImpactDescription"
    ],
    [
      "sourceFormat",
      "ingestMethod",
      "remotePath",
      "filePattern",
      "portNumber",
      "username",
      "password",
      "pubKey",
      "ingestDetails",
      "tableSize",
      "incrementalUpdateSize",
      "incrementalRowCount",
      "updatePattern"
    ]
  ];

  const datatableSchema = buildHostedDatatableSchema(existedCodes);

  return (
    <Wizard stateNavigator={true} header={<IntakeDatatableModalHeader />}>
      <Step
        path="1"
        name="Step 1"
        canAdvance={datatableSchema
          .pickNested(stepFields[0])
          .isValidSync(datatable)}
      >
        <HostedDatatableWizardStep1
          datatable={datatable}
          save={saveStep}
          cancel={onDismiss}
          discard={onDiscard}
          fields={stepFields[0]}
          existedCodes={existedCodes}
        />
      </Step>
      <Step
        path="2"
        name="Step 2"
        canAdvance={datatableSchema
          .pickNested(stepFields[1])
          .isValidSync(datatable)}
      >
        <HostedDatatableWizardStep2
          datatable={datatable}
          save={saveStep}
          cancel={onDismiss}
          discard={onDiscard}
          onPreviousStep={onPreviousStep}
          fields={stepFields[1]}
        />
      </Step>
      <Step
        path="3"
        name="Step 3"
        canAdvance={datatableSchema
          .pickNested(stepFields[2])
          .isValidSync(datatable)}
      >
        <HostedDatatableWizardStep3
          datatable={datatable}
          save={saveStep}
          cancel={onDismiss}
          discard={onDiscard}
          onPreviousStep={onPreviousStep}
          fields={stepFields[2]}
        />
      </Step>
      <Step
        path="4"
        name="Step 4"
        canAdvance={datatableSchema
          .pickNested(stepFields[3])
          .isValidSync(datatable)}
      >
        <HostedDatatableWizardStep4
          datatable={datatable}
          save={saveStep}
          cancel={onDismiss}
          discard={onDiscard}
          onPreviousStep={onPreviousStep}
          fields={stepFields[3]}
        />
      </Step>
    </Wizard>
  );
};

export default HostedDatatableWizard;
