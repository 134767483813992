import { FontAwesomeIcon } from "@nef/core";
import React, { useMemo } from "react";

import { parsePages } from "../../utils/markdown";
import "./DocumentationNavigationStyle.scss";

interface DocumentationNavigationInterface {
  documentation: string;
}

const DocumentationNavigation = ({
  documentation
}: DocumentationNavigationInterface) => {
  const sections = useMemo(() => {
    return parsePages(documentation);
  }, [documentation]);

  if (!sections.length) {
    return null;
  }

  return (
    sections && (
      <ul className="product-documentation__doc-heading">
        {sections?.map(section => {
          return (
            <li
              key={section.title}
              className="product-documentation__topic-list"
            >
              <div className="product-documentation__topic-div">
                <FontAwesomeIcon
                  iconClassName="fa-caret-down"
                  iconSetClassName="fas"
                  className="fa-caret-icon"
                />
                <span className="product-documentation__topic-link active">
                  {section.title}
                </span>
              </div>
              <ul className="product-documentation__sub-topic-heading">
                {section.pages.map(subtopic => {
                  return (
                    <li
                      key={subtopic.title}
                      className="product-documentation__sub-topic-list"
                    >
                      <span className="product-documentation__sub-topic-link active">
                        {subtopic.title}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    )
  );
};

export default DocumentationNavigation;
