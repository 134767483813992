import React from "react";
import { Button } from "@nef/core";

import { useProductContext } from "../../hooks/useProductContext";
import { FinalizeType } from "../../contexts/product";
import useDefaultAuth from "../../hooks/useDefaultAuth";

const FinalizeButton = () => {
  const productContext = useProductContext();
  const { mode, isReady } = productContext;
  const { authenticatedUser } = useDefaultAuth();

  const isProductApprover =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  const computeFinalizeType = () => {
    if (productContext.mode === "create") {
      return null;
    }

    const { productApprovalType, productApprovalStatus } = productContext;

    if (
      productApprovalType === "no_approval_required" ||
      (productApprovalStatus === "pending_approval" && isProductApprover)
    ) {
      return "publish";
    }

    if (
      productApprovalType === "approval_required" &&
      productApprovalStatus === "draft"
    ) {
      return "submit_for_approval";
    }

    return null;
  };

  const finalizeType: FinalizeType | null = computeFinalizeType();

  return (
    <div data-testid="finalizeButton">
      {mode === "edit" && finalizeType === "publish" && isProductApprover && (
        <Button
          onClick={() => productContext.tryFinalize("publish")}
          data-testid="productFormHeader_publish"
          disabled={!isReady}
        >
          Publish Changes
        </Button>
      )}

      {mode === "edit" && finalizeType === "submit_for_approval" && (
        <Button
          onClick={() => productContext.tryFinalize("submit_for_approval")}
          data-testid="productFormHeader_submitForApproval"
          disabled={!isReady}
        >
          Submit for Approval
        </Button>
      )}
    </div>
  );
};

export default FinalizeButton;
