import React, { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Button, FontAwesomeIcon } from "@nef/core";
import { toast } from "react-toastify";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useMutation, useQuery } from "jsonapi-react";
import { Link } from "react-router-dom";

import manageClient from "../../../../api/manage/client";
import Toast from "../../../../components/Toast";
import { OrganizationUser } from "../../../../api/types";
import RemoveAdminUserModal from "../remove-admin-user-modal";
import AddUserToAdminModal from "../add-admin-user-modal";

import styles from "./index.module.scss";

interface AdminTableProps {
  organizationId: string;
}

type SortParam =
  | "user.first_name,user.last_name"
  | "-user.first_name,-user.last_name"
  | "email"
  | "-email"
  | "created_at"
  | "-created_at"
  | "products_count"
  | "-products_count";

export const generateRequestBody = (
  organizationId: string,
  sortParam: SortParam
): Record<string, string | boolean> | "" => {
  const requestBody: Record<string, string | boolean> = {
    "filter[organization_id]": organizationId,
    "filter[role]": "admin"
  };

  if (sortParam.trim() !== "") {
    requestBody.sort = sortParam;
  }

  return requestBody;
};

const columnHelper = createColumnHelper<OrganizationUser>();

const AdminTable = ({ organizationId }: AdminTableProps) => {
  const [sortParam, setSortParam] = useState<SortParam>(
    "user.first_name,user.last_name"
  );

  const [isAddAdminUserModalOpen, setIsAddAdminUserModalOpen] = useState(false);
  const [adminUserToRemove, setAdminUserToRemove] =
    useState<null | OrganizationUser>(null);

  const [sorting, setSorting] = useState<SortingState>([]);

  const { data: adminData } = useQuery<OrganizationUser[]>([
    "organization-users",
    generateRequestBody(organizationId, sortParam)
  ]);

  const { data: usersData } = useQuery<OrganizationUser[]>([
    "organization-users",
    {
      "filter[organization_id]": organizationId,
      "filter[role]": "user"
    }
  ]);

  const [updateUser] = useMutation(
    ["organization-users", adminUserToRemove?.id],
    {
      method: "PATCH",
      client: manageClient
    }
  );

  const ascending = !sortParam.startsWith("-");
  const sortBy = ascending ? sortParam : sortParam.replace(/-/g, "");

  const icon = ascending ? "fa-caret-up" : "fa-caret-down";

  const updateSortParam = useCallback(
    (newSortBy: SortParam) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;

      const sortFields = newSortBy.split(",");
      const newSortFields = sortFields.map(field =>
        newAscending ? field : `-${field.replace(/^-/, "")}`
      );
      const newSortParam = newSortFields.join(",") as SortParam;

      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const columns = [
    columnHelper.accessor("fullName", {
      id: "name",
      cell: info => info.getValue() || <em>Invite sent</em>,
      header: () => (
        <div
          className={
            sortBy === "user.first_name,user.last_name"
              ? styles["sorter-active"]
              : ""
          }
        >
          <button
            type="button"
            onClick={() => updateSortParam("user.first_name,user.last_name")}
            onKeyPress={() => updateSortParam("user.first_name,user.last_name")}
            tabIndex={-1}
          >
            Name
          </button>
          {sortBy === "user.first_name,user.last_name" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("email", {
      id: "email",
      cell: info => info.getValue() || info.row.original.email,
      header: () => (
        <div className={sortBy === "email" ? styles["sorter-active"] : ""}>
          <button
            type="button"
            onClick={() => updateSortParam("email")}
            onKeyPress={() => updateSortParam("email")}
            tabIndex={-1}
          >
            Email
          </button>
          {sortBy === "email" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      cell: info => {
        const val = info.getValue();
        if (val !== undefined) {
          return (
            <div className={styles.center}>
              {new Intl.DateTimeFormat("en-US").format(new Date(val))}
            </div>
          );
        }
        return "";
      },
      header: () => (
        <div className={sortBy === "created_at" ? styles["sorter-active"] : ""}>
          <button
            type="button"
            onClick={() => updateSortParam("created_at")}
            onKeyPress={() => updateSortParam("created_at")}
            tabIndex={-1}
          >
            Created On
          </button>
          {sortBy === "created_at" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("id", {
      id: "id",
      cell: info =>
        info.getValue() ? (
          <>
            <Button
              outline
              size="sm"
              color="danger"
              className={styles["admin-action-buttons"]}
              onClick={() => {
                setAdminUserToRemove(info.row.original);
              }}
            >
              Delete Admin
            </Button>
            <Link
              to={`/organizations/${organizationId}/users/${info.row.original?.id}?from=organization`}
            >
              <Button
                size="sm"
                outline
                className={styles["admin-action-buttons"]}
              >
                View User
              </Button>
            </Link>
          </>
        ) : null,
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: adminData ?? [],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  const handleRemoveAdminConfirm = async () => {
    const response = await updateUser({
      role: "user"
    });

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error removing the user"
          details={[]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully removed"
        details={[]}
      />
    );

    setAdminUserToRemove(null);
  };

  return (
    <div className={styles.container} data-testid="OrganizationAdminTable">
      <div className={styles.header}>
        <span>Administrator</span>
        <span
          data-tip="All Users Have Been Added As Admin"
          data-tip-disable={usersData && usersData.length > 0}
        >
          <Button
            size="sm"
            disabled={usersData && usersData.length === 0}
            onClick={() => setIsAddAdminUserModalOpen(true)}
          >
            <FontAwesomeIcon iconClassName="fa-plus-circle" />
            Add Admin
          </Button>
        </span>
        <ReactTooltip place="top" type="dark" effect="solid" />
      </div>
      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                data-testid={`organization-admin-table-row-${row.id}`}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    data-testid={`organization-admin-table-${cell.column.id}-${row.id}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {adminUserToRemove && (
        <RemoveAdminUserModal
          onConfirm={handleRemoveAdminConfirm}
          onDismiss={() => setAdminUserToRemove(null)}
          title="Remove Admin Access"
          question={`Are you sure you want to remove manager admin access for ${
            adminUserToRemove?.fullName || adminUserToRemove?.email
          }?`}
          confirmText="Remove Admin Access"
          dismissText="Cancel"
        />
      )}
      {isAddAdminUserModalOpen && (
        <AddUserToAdminModal
          close={() => setIsAddAdminUserModalOpen(false)}
          organizationId={organizationId}
          isLoading={false}
        />
      )}
    </div>
  );
};

export default AdminTable;
