import React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "@nef/core";
import { create, InstanceProps } from "react-modal-promise";

const DEFAULT_MODAL_OPTIONS = {
  title: "Confirmation",
  question: "Are you sure?",
  dismissText: "No",
  confirmText: "Yes"
};

interface ConfirmationModalContent {
  title?: string;
  question?: string;
  confirmText?: string;
  dismissText?: string;
  danger?: boolean;
}
interface ConfirmationModalProps extends ConfirmationModalContent {
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss?: () => void;
}

interface ConfirmationModalPromisifiedProps extends ConfirmationModalContent {
  isOpen: boolean;
  onResolve: () => void;
  onReject: () => void;
}

const ConfirmationModal = ({
  isOpen,
  onConfirm,
  onDismiss,
  title = DEFAULT_MODAL_OPTIONS.title,
  question = DEFAULT_MODAL_OPTIONS.question,
  confirmText = DEFAULT_MODAL_OPTIONS.confirmText,
  dismissText = DEFAULT_MODAL_OPTIONS.dismissText,
  children,
  danger
}: React.PropsWithChildren<ConfirmationModalProps>) => {
  return (
    <>
      <Modal isOpen={isOpen} toggle={onDismiss} data-testid="confirmationModal">
        <ModalHeader toggle={onDismiss} data-testid="confirmationModal_title">
          {title}
        </ModalHeader>
        <ModalBody
          data-testid="confirmationModal_question"
          className="modal-body"
        >
          {question}
          {children}
        </ModalBody>
        <ModalFooter>
          {onDismiss && (
            <Button
              size="md"
              className="danger"
              color={danger ? "primary" : "light"}
              onClick={onDismiss}
              data-testid="confirmationModal_dismiss"
            >
              {dismissText}
            </Button>
          )}

          <Button
            size="md"
            onClick={onConfirm}
            color={danger ? "danger" : "primary"}
            data-testid="confirmationModal_confirm"
          >
            {confirmText}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const PromiseConfirmationModal: React.FC<
  ConfirmationModalPromisifiedProps & InstanceProps<unknown>
> = ({
  isOpen,
  onResolve,
  onReject,
  title = DEFAULT_MODAL_OPTIONS.title,
  question = DEFAULT_MODAL_OPTIONS.question,
  confirmText = DEFAULT_MODAL_OPTIONS.confirmText,
  dismissText = DEFAULT_MODAL_OPTIONS.dismissText,
  danger
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onReject()}
      data-testid="confirmationModal"
    >
      <ModalHeader toggle={() => onReject()}>{title}</ModalHeader>
      <ModalBody>{question}</ModalBody>
      <ModalFooter>
        <Button
          size="md"
          className="danger"
          color={danger ? "primary" : "light"}
          onClick={() => onReject()}
          data-testid="confirmationModal_dismiss"
        >
          {dismissText}
        </Button>

        <Button
          size="md"
          color={danger ? "danger" : "primary"}
          onClick={() => onResolve()}
          data-testid="confirmationModal_confirm"
        >
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmationModalPromisified = create(PromiseConfirmationModal);

export { ConfirmationModal, ConfirmationModalPromisified };
