import React, { useState, useEffect } from "react";
import { FormRadioCheckboxGroup, FormRadioCheckboxButton } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at } from "lodash";

import { formSchema } from "../../../schema";

import styles from "./index.module.scss";

const SubscriptionType = ({
  initialValue,
  resetValue,
  formFieldName,
  autoRenew,
  trial
}: {
  initialValue?: string;
  formFieldName: string;
  autoRenew: boolean;
  trial: boolean;
  resetValue: string;
}) => {
  const fieldValue = initialValue
    ? autoRenew
      ? "autoRenew"
      : trial
      ? "trial"
      : "fixedTerm"
    : "trial";
  const [subscriptionType, setSubscriptionType] = useState<string>(fieldValue);

  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext<yup.Asserts<typeof formSchema>>();

  const formFieldErrors = at(errors, formFieldName)[0];

  useEffect(() => {
    if (resetValue !== undefined) {
      setSubscriptionType(resetValue);
      setValue(formFieldName as any, resetValue);
    }
  }, [resetValue]);

  useEffect(() => {
    setValue(formFieldName as any, fieldValue);
  }, []);

  return (
    <Controller
      control={control}
      name={formFieldName as any}
      render={({ field: { onChange, name: internalName } }) => {
        return (
          <FormRadioCheckboxGroup
            id={internalName}
            name={internalName}
            data-testid="OrganizationsAddProductDrawer_subscriptionType"
            type="radio"
            defaultValue=""
            value={subscriptionType}
            onChange={event => {
              onChange(event.value);
              setSubscriptionType(event.value as string);
            }}
            optional={false}
            inline
            label="Is this a trial, fixed term subscription, or auto renew subscription? If auto renew, then no termination date is required."
            invalid={!!formFieldErrors}
            feedback={formFieldErrors?.message}
            className={styles["subscription-renewal"]}
          >
            <FormRadioCheckboxButton label="Auto Renew" value="autoRenew" />
            <FormRadioCheckboxButton label="Fixed-Term" value="fixedTerm" />
            <FormRadioCheckboxButton label="Trial" value="trial" />
          </FormRadioCheckboxGroup>
        );
      }}
    />
  );
};

export default SubscriptionType;
