import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormField,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { debounce, startCase } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation, QueryArg } from "jsonapi-react";

import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import manageClient from "../../api/manage/client";
import Toast from "../Toast";
import MultiSelect, { MultiSelectOption } from "../common/MultiSelect";
import { ProductPermission, Vendor } from "../../api/types";
import FormFieldLabel from "../modals/components/FormFieldLabel";

import {
  NEW_USER_GROUP_MODAL_VENDOR_INFO,
  NEW_USER_GROUP_MODAL_NAME_INFO,
  NEW_USER_GROUP_MODAL_PERMISSION_INFO
} from "./hints";
import styles from "./NewUserGroupModal.module.scss";

interface NewUserGroupRequest {
  vendorId: string;
  name: string;
  products: string[];
}

interface FormValues {
  vendorId: string;
  name: string;
  products: string[];
}

interface NewVendorModalProps {
  isOpen: boolean;
  close: () => void;
  _vendorId: string;
}

const userGroupRequestSchema = yup.object({
  vendorId: yup.string().required("Publisher name is a required field"),
  name: yup
    .string()
    .required("Name is a required field")
    .min(2, "Name needs to be at least 2 characters"),
  products: yup
    .array()
    .of(yup.string())
    .min(1, "At least one permission must be selected")
    .required("Permissions are a required field")
});

const NewUserGroupModal = ({
  isOpen,
  close,
  _vendorId
}: NewVendorModalProps) => {
  const [productOptions, setProductOptions] = useState(
    [] as MultiSelectOption[]
  );
  const [vendorOptions, setVendorOptions] = useState([] as MultiSelectOption[]);
  const [createVendorUserGroupRequest] = useMutation("vendor-user-groups", {
    client: manageClient
  });

  const [validateData] = useMutation(["vendor-user-groups", "validate"], {
    client: manageClient
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    trigger,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      vendorId: _vendorId,
      name: "",
      products: []
    },
    resolver: yupResolver(userGroupRequestSchema)
  });

  const { vendorId } = getValues();

  const queryVendors: QueryArg<any> = _vendorId
    ? false
    : [
        "vendors",
        {
          sort: "name",
          "fields[vendors]": "name,code,products"
        }
      ];
  const { data: vendors } = useQuery<Vendor[]>(queryVendors);

  const {
    data: products,
    isLoading: isVendorLoading,
    refetch: refetchProducts
  } = useQuery<ProductPermission[]>([
    "datatable-collections",
    {
      "filter[vendor_id]": vendorId
    }
  ]);

  useEffect(() => {
    if (vendors) {
      setVendorOptions(
        vendors.map(vendor => {
          return {
            value: vendor.id,
            label: vendor.name
          };
        })
      );
    }
  }, [vendors]);

  useEffect(() => {
    if (products) {
      setValue("products", []);
      setProductOptions(
        products
          .map((product: ProductPermission) => {
            return {
              value: product.id,
              label: product.name
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [products]);

  useEffect(() => {
    if (vendors && getValues().vendorId === "") {
      setValue(
        "vendorId",
        vendors.find(vendor => vendor.id === _vendorId)?.id || vendors[0].id
      );
    }
  }, [vendors]);

  const validateFieldUniquenessAPI = async () => {
    const { name }: { name: string; vendorId: string } = getValues();

    if (!vendorId || name.length <= 1) {
      return;
    }

    const { errors } = await validateData({
      name,
      "vendor-id": vendorId
    });

    clearErrors("name");
    const fieldHasBeenTaken = errors?.some(
      error =>
        error.title === "Name already exists for this vendor" &&
        error.source.pointer.includes("name")
    );

    if (fieldHasBeenTaken) {
      setError("name", {
        type: "custom",
        message: `${startCase("name")} already exists for this vendor`
      });
    }
  };

  const debounceValidateFieldUniquenessAPI = useCallback(
    debounce(validateFieldUniquenessAPI, 300),
    []
  );

  const onSubmit: SubmitHandler<NewUserGroupRequest> = useCallback(
    async data => {
      const normalizedRequest: any = decamelizeKeys(data, {
        separator: "-"
      });

      normalizedRequest.products = normalizedRequest.products.map(
        (id: string) => ({
          id,
          type: "products"
        })
      );

      const response = await createVendorUserGroupRequest(normalizedRequest);
      const { error, errors } = response;

      if (error || errors) {
        const responseErrors = error ? [error] : errors;

        if (!responseErrors) return;

        toast(
          <Toast
            type="error"
            title="There was an error creating the user group"
            details={[]}
          />
        );

        return;
      }

      toast(
        <Toast
          type="success"
          title="User group has been successfully created"
          details={[]}
        />
      );

      handleClose();
    },
    []
  );

  const handleClose = () => {
    close();
    reset();
    setProductOptions([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="newUserGroupModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="New User Group"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        New User Group
      </ModalHeader>
      <ModalBody>
        <div>
          {!_vendorId && (
            <>
              <div className={styles["remove-margin"]}>
                <FormFieldLabel
                  title="Publisher Name"
                  tooltip={NEW_USER_GROUP_MODAL_VENDOR_INFO}
                />
              </div>
              <Controller
                name="vendorId"
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FormSelect
                    id="VendorId"
                    name={name}
                    isLoading={isVendorLoading}
                    value={() =>
                      vendorOptions?.find(o => o.value === value) || _vendorId
                    }
                    options={vendorOptions}
                    data-testid="newUserGroupModal_vendorId"
                    onChange={formSelectOnChangeParam => {
                      onChange(
                        extractFormSelectOnChangeValue(formSelectOnChangeParam)
                      );
                      if (refetchProducts) {
                        refetchProducts();
                      }
                    }}
                    invalid={!!formErrors.vendorId}
                    feedback={formErrors?.vendorId?.message}
                    onBlur={() => {
                      onBlur();
                      validateFieldUniquenessAPI();
                    }}
                    classNamePrefix="modalFormSelect"
                  />
                )}
              />
            </>
          )}
          <div className={`${!_vendorId ? "" : styles["remove-margin"]}`}>
            <FormFieldLabel
              title="Group Name"
              tooltip={NEW_USER_GROUP_MODAL_NAME_INFO}
            />
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormField
                  id="GroupName"
                  name={name}
                  type="text"
                  placeholder="Name"
                  value={value}
                  onChange={event => {
                    debounceValidateFieldUniquenessAPI();
                    onChange(event);
                  }}
                  data-testid="newUserGroupModal_name"
                  invalid={!!formErrors.name}
                  feedback={formErrors?.name?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <FormFieldLabel
            title="Permissions"
            tooltip={NEW_USER_GROUP_MODAL_PERMISSION_INFO}
          />
          <Controller
            control={control}
            name="products"
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                options={productOptions}
                value={value || []}
                invalid={!!formErrors.products}
                feedback={(formErrors?.products as any)?.message}
                onChange={onChange}
                validate={() => {
                  trigger("products");
                }}
              />
            )}
          />

          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || !isValid}
              data-testid="newUserGroupModal_submit"
            >
              Create User Group
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="newUserGroupModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewUserGroupModal;
