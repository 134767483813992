import React from "react";
import "./style.scss";

type Props = {
  deliveryFrequency: string;
  dataFrequency: string;
  reportingLag: string;
  history: string;
  coverage: string;
  availability: string;
  productType: string;
  frameworkRegulationNotes?: string | null;
};

const ProductMetadata: React.FC<Props> = ({
  deliveryFrequency,
  dataFrequency,
  reportingLag,
  coverage,
  history,
  availability,
  productType,
  frameworkRegulationNotes
}) => {
  return (
    <div className="product-metadata__container">
      <div className="product-metadata__heading-container">
        <h2 className="product-metadata__heading">Data Specifications</h2>
      </div>
      <div className="product-metadata__items">
        <div className="product-metadata__item">
          <p className="product-metadata__item-key">Delivery Frequency</p>
          <p
            className="product-metadata__item-value"
            data-testid="productPreview_deliveryFrequency"
          >
            {deliveryFrequency.length > 0 ? deliveryFrequency : "-"}
          </p>
        </div>
        <div className="product-metadata__item">
          <p className="product-metadata__item-key">Data Frequency</p>
          <p
            className="product-metadata__item-value"
            data-testid="productPreview_dataFrequency"
          >
            {dataFrequency.length > 0 ? dataFrequency : "-"}
          </p>
        </div>
        <div className="product-metadata__item">
          <p className="product-metadata__item-key">Reporting Lag</p>
          <p
            className="product-metadata__item-value"
            data-testid="productPreview_reportingLag"
          >
            {reportingLag ?? "-"}
          </p>
        </div>
        <div className="product-metadata__item">
          <p className="product-metadata__item-key">History</p>
          <p
            className="product-metadata__item-value"
            data-testid="productPreview_history"
          >
            {history ?? "-"}
          </p>
        </div>
        <div className="product-metadata__item">
          <p className="product-metadata__item-key">Coverage</p>
          <p
            className="product-metadata__item-value"
            data-testid="productPreview_coverage"
          >
            {coverage ?? "-"}
          </p>
        </div>
        {productType !== "ADP" && (
          <div className="product-metadata__item">
            <p className="product-metadata__item-key">Availability</p>
            <p
              className="product-metadata__item-value"
              data-testid="productPreview_availability"
            >
              {availability ?? "-"}
            </p>
          </div>
        )}
        {productType === "ESG" && (
          <div className="product-metadata__item">
            <p className="product-metadata__item-key">
              Framework / Regulations
            </p>
            <p
              className="product-metadata__item-value"
              data-testid="productPreview_framework_regulations"
            >
              {frameworkRegulationNotes}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductMetadata;
