export const OPTION_VALUES = {
  SOURCE_FORMAT: [
    "CSV",
    "JSON",
    "XML",
    "XLS",
    "TXT",
    "Parquet",
    "Avro",
    "ORC",
    "Other"
  ] as const,
  INGEST_METHOD: ["S3", "SFTP", "FTP", "Scraped", "Other"] as const,
  UPDATE_PATTERN: [
    "Not Sure",
    "Other",
    "Full data",
    "Latest",
    "Updates",
    "Deltas"
  ] as const,
  DATA_TIMEZONE: [
    "No time-related data points",
    "Not Sure",
    "GMT",
    "UTC",
    "ECT",
    "EET",
    "ART",
    "EAT",
    "MET",
    "NET",
    "PLT",
    "IST",
    "BST",
    "VST",
    "CTT",
    "JST",
    "ACT",
    "AET",
    "SST",
    "NST",
    "MIT",
    "HST",
    "AST",
    "PST",
    "PNT",
    "MST",
    "CST",
    "EST",
    "IET",
    "PRT",
    "CNT",
    "AGT",
    "BET",
    "CAT"
  ]
};

type OptionValueMap = typeof OPTION_VALUES;
type OptionKey = keyof OptionValueMap;
type OptionMap = {
  [Property in OptionKey]: { value: string; label: string }[];
};

const valueToOption = (value: string) => {
  return {
    value,
    label: value
  };
};

// Manually populating from OPTION_VALUES keys because it's tricky to satisfy
// Typescript checks using Object.keys / Object.entries / Array.reduce
export const OPTIONS: OptionMap = Object.freeze({
  SOURCE_FORMAT: OPTION_VALUES.SOURCE_FORMAT.map(valueToOption),
  INGEST_METHOD: OPTION_VALUES.INGEST_METHOD.map(valueToOption),
  UPDATE_PATTERN: OPTION_VALUES.UPDATE_PATTERN.map(valueToOption),
  DATA_TIMEZONE: OPTION_VALUES.DATA_TIMEZONE.map(valueToOption)
});
