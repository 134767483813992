import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Header } from "@nef/core";

import { DatatablesNameDescription } from "../../api/types";

import styles from "./IntakeDatatableErrorModal.module.scss";

const IntakeDatatableErrors = () => {
  const { control } = useFormContext<DatatablesNameDescription>();
  const schemaErrors = useWatch({ control, name: "schemaErrors" });

  const errors = schemaErrors || [];

  return (
    <div className={styles.section}>
      {errors.length ? (
        <>
          <Header size={3}>Errors</Header>

          <div className={styles.errors}>
            {errors.map((error, index) => {
              /* eslint-disable react/no-array-index-key */
              return (
                <div key={index} className={styles.error}>
                  {error.title}
                </div>
              );
              /* eslint-enable react/no-array-index-key */
            })}
          </div>
        </>
      ) : (
        <div className={styles.valid}>The table schema file looks valid</div>
      )}
    </div>
  );
};

export default IntakeDatatableErrors;
