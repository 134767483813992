import React from "react";

const NavBackIcon = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.617188 5.64219C0.321875 5.97031 0.321875 6.4625 0.617188 6.75781L5.07969 11.2203C5.40781 11.5484 5.9 11.5484 6.19531 11.2203L6.95 10.4984C7.24531 10.1703 7.24531 9.67813 6.95 9.38281L3.76719 6.2L6.95 3.05C7.24531 2.75469 7.24531 2.22969 6.95 1.93438L6.19531 1.17969C5.9 0.884376 5.40781 0.884376 5.07969 1.17969L0.617188 5.64219Z"
      fill="#0094BD"
    />
  </svg>
);

export default NavBackIcon;
