import React, { Suspense } from "react";

import Routes from "../routes";
import useSessionAuth from "../hooks/useSessionAuth";
import { SelectedVendorProvider } from "../contexts/selectedVendor";

import ErrorBoundary from "./ErrorBoundary";
import styles from "./App.module.scss";
import Nav from "./Nav";
import Sidebar from "./sidebar/Sidebar";

function App() {
  const { authenticatedUser } = useSessionAuth();

  return (
    <SelectedVendorProvider>
      <div className={styles.body}>
        <ErrorBoundary>
          <Suspense fallback={<div />}>
            <Nav />
            {authenticatedUser && <Sidebar />}
            <Routes />
          </Suspense>
        </ErrorBoundary>
      </div>
    </SelectedVendorProvider>
  );
}

export default App;
