import { PlanCategory } from "../api/types";

import usePlansQuery from "./usePlansQuery";

const useHydratePlanCategories = (
  planCategories: PlanCategory[] | undefined,
  active: boolean,
  includeSubscriberCount = false
) => {
  const { data: allPlans, isLoading } = usePlansQuery({
    planCategories,
    active,
    includeSubscriberCount
  });

  const hydratedPlanCategories = planCategories?.map(category => {
    return {
      ...category,
      plans:
        allPlans?.filter(plan => `${plan.planCategoryId}` === category.id) || []
    };
  });

  return { planCategories: hydratedPlanCategories, isLoading };
};

export default useHydratePlanCategories;
