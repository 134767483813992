import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  ModalHeader,
  FontAwesomeIcon
} from "@nef/core";
import { FileRejection, useDropzone } from "react-dropzone";

import styles from "./editVendorLogoModal.module.scss";

type EditVendorLogoModalProps = {
  isOpen: boolean;
  onSubmit: (value?: string | ArrayBuffer | null) => void;
  imagePreview: string;
  close: () => void;
};

const toBase64 = (file?: File) => {
  if (!file) {
    return Promise.reject();
  }
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const EditVendorLogoModal = ({
  isOpen,
  onSubmit,
  imagePreview,
  close
}: EditVendorLogoModalProps) => {
  const [fileRejection, setFileRejection] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState<
    string | ArrayBuffer | null
  >();

  const onDrop = useCallback(
    async <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[]
    ) => {
      setFileRejection(!!fileRejections);
      const base64File = await toBase64(acceptedFiles[0]);
      setAcceptedFile(base64File);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"]
    }
  });

  const handleOnSubmit = async () => {
    onSubmit(acceptedFile);
    setFileRejection(false);
    setAcceptedFile(undefined);
    close();
  };

  const onClose = () => {
    setFileRejection(false);
    setAcceptedFile(undefined);
    close();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} data-testid="contentEditorModal">
      <ModalHeader toggle={onClose} />
      <ModalBody data-testid="contentEditorModal_textArea">
        <div
          className={`${styles.dropzone} ${
            isDragActive && styles["dropzone-active"]
          }`}
          {...getRootProps({
            maxfiles: 1,
            multiple: false
          })}
        >
          {!imagePreview && !acceptedFile && (
            <FontAwesomeIcon
              className={styles["upload-icon"]}
              iconSetClassName="fas"
              iconClassName="fa-image"
            />
          )}
          <label htmlFor="upload-file">
            <input
              {...getInputProps()}
              id="upload-file"
              data-testid="contentEditorModal_fileUpload"
            />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                {!imagePreview && !acceptedFile ? (
                  <>
                    <p>
                      Drag and Drop to Upload or{" "}
                      <span
                        role="button"
                        className={styles.browse}
                        onClick={open}
                        onKeyPress={open}
                        tabIndex={-1}
                      >
                        Browse
                      </span>{" "}
                    </p>
                    {fileRejection && (
                      <p>
                        <em>(Only *.jpeg and *.png images will be accepted)</em>
                      </p>
                    )}
                  </>
                ) : (
                  <img
                    className={styles["logo-upload-preview-image"]}
                    src={(acceptedFile as string) || imagePreview}
                    alt=""
                  />
                )}
              </>
            )}
          </label>
        </div>
      </ModalBody>
      <ModalFooter className={styles["modal-footer"]}>
        <Button
          size="md"
          onClick={handleOnSubmit}
          data-testid="contentEditorModal_confirm"
        >
          Submit
        </Button>

        <Button
          size="md"
          color="light"
          outline
          onClick={onClose}
          data-testid="contentEditorModal_dismiss"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditVendorLogoModal;
