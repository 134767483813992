import React from "react";
import { useParams, Link, generatePath } from "react-router-dom";
import { useQuery } from "jsonapi-react";
import { Button } from "@nef/core";

import { Product } from "../../api/types";
import LicenceInfo from "../../components/product-form/components/LicenceInfo";
import { PATHS } from "../../routes";

const LicencePage = () => {
  const { productId, licenceId } =
    useParams<{ productId: string; licenceId: string }>();

  const { data: product } = useQuery<Product>([
    "products",
    productId,
    {
      include: ["vendor", "vendor.licences"]
    }
  ]);

  const licence = product?.vendor.licences?.find(l => l.id === licenceId);

  if (!product || !licence) return null;

  return (
    <>
      <Button
        to={generatePath(`${PATHS.EDIT_PRODUCT}/plans`, {
          productId: product.id
        })}
        as={Link}
        color="link"
        data-testid="licence_backToPlans"
      >
        &lt; Back to Plans
      </Button>

      <LicenceInfo
        licence={licence}
        productName={product.name}
        vendorName={product.vendor.name}
      />
    </>
  );
};

export default LicencePage;
