import React from "react";
import { FormField } from "@nef/core";
import { Controller } from "react-hook-form";

import CapSurveyFormFieldRow from "../../../../cap-survey-form/CapSurveyFormFieldRow";
import TOOLTIP from "../../../../cap-survey-form/tooltips";

const FtpVariant = ({ formMethods }: any) => {
  const { control } = formMethods;

  return (
    <>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_REMOTE_PATH}>
        <Controller
          name="remotePath"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="remotePath"
              name={name}
              label="Remote Path"
              value={value || ""}
              optional={false}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_FILE_PATTERN}>
        <Controller
          name="filePattern"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="filePattern"
              name={name}
              label="File Pattern"
              value={value || ""}
              optional={false}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_PORT_NUMBER}>
        <Controller
          name="portNumber"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="portNumber"
              name={name}
              label="Port Number"
              value={value || ""}
              optional={false}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip="">
        <Controller
          name="username"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="username"
              name={name}
              label="Username"
              value={value || ""}
              optional={false}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip="">
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="password"
              name={name}
              label="Password"
              value={value || ""}
              optional={false}
              onChange={onChange}
              type="password"
            />
          )}
        />
      </CapSurveyFormFieldRow>
    </>
  );
};

export default FtpVariant;
