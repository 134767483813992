import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormField } from "@nef/core";

import { DatatablesNameDescription } from "../../../../../api/types";
import CapSurveyFormFieldRow from "../../../CapSurveyFormFieldRow";
import TOOLTIP from "../../../tooltips";

const IntradayDescriptionFieldRow = () => {
  const { control } = useFormContext<DatatablesNameDescription>();
  const freq = useWatch({ control, name: "updateFrequency.freq" });

  return freq === "intraday" ? (
    <CapSurveyFormFieldRow
      tooltip={TOOLTIP.DATATABLE_UPDATE_FREQUENCY_INTRADAY_DESCRIPTION}
      stacked={true}
    >
      <Controller
        name="updateFrequency.custom.description"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <FormField
            id={name}
            name={name}
            label="Description"
            value={value || ""}
            optional={false}
            onChange={onChange}
          />
        )}
      />
    </CapSurveyFormFieldRow>
  ) : null;
};

export default IntradayDescriptionFieldRow;
