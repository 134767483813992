import React from "react";
import { useHistory } from "react-router-dom";

import useDefaultAuth from "../../hooks/useDefaultAuth";
import { PATHS } from "../../routes";
import CreateDatatableForm from "../../components/datatables-form/CreateDatatableForm";

const NewDatatablePage = () => {
  const history = useHistory();
  const { authenticatedUser } = useDefaultAuth();

  if (!authenticatedUser?.rolesArray.includes("manager_admin")) {
    history.push(PATHS.PUBLISHERS);
    return null;
  }

  return <CreateDatatableForm />;
};
export default NewDatatablePage;
