import React from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Button, FontAwesomeIcon } from "@nef/core";
import { Link } from "react-router-dom";

import type { DatatableCollection } from "../../../api/types";

import styles from "./skill-table.module.scss";

const columnHelper = createColumnHelper<DatatableCollection>();

interface SkillTableProps {
  datatableCollections?: DatatableCollection[];
  sortBy?: string;
  sortOrder?: string;
  updateSortParam: (sortBy: string) => void;
}

function SkillTable({
  datatableCollections,
  sortBy,
  sortOrder,
  updateSortParam
}: SkillTableProps) {
  const icon = sortOrder === "asc" ? "fa-caret-up" : "fa-caret-down";
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          <Link
            className={styles.values}
            to={`/ai-skills/${info.row.original.id}`}
          >
            {info.getValue()}
          </Link>
        </div>
      ),
      header: () => {
        return (
          <div
            className={sortBy === "name" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("name")}
            onKeyPress={() => updateSortParam("name")}
            tabIndex={-1}
            data-testid="name-sort"
          >
            Name
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(
          new Date(info.getValue() || Date.now())
        ),
      header: () => {
        return (
          <div
            className={sortBy === "created_at" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("created_at")}
            onKeyPress={() => updateSortParam("created_at")}
            tabIndex={-1}
            data-testid="created-at-sort"
          >
            Created On
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("updatedAt", {
      id: "updatedAt",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(
          new Date(info.getValue() || Date.now())
        ),
      header: () => (
        <div
          className={sortBy === "updated_at" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("updated_at")}
          onKeyPress={() => updateSortParam("updated_at")}
          tabIndex={-1}
          data-testid="updated-at-sort"
        >
          Last Update
          {sortBy === "updated_at" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("id", {
      id: "viewSkill",
      cell: info => (
        <div className={styles["right-align"]}>
          <Button color="light" outline size="sm">
            <Link className={styles.link} to={`/ai-skills/${info.getValue()}`}>
              View Skill
            </Link>
          </Button>
        </div>
      ),
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: datatableCollections ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <section data-testid="skill-record">
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={`${
                    header.column.getIsSorted()
                      ? `${styles.selected} ${styles.label}`
                      : styles.label
                  } ${header.column.id === "name" ? styles["left-align"] : ""}`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-testid="skill-table-body">
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} data-testid={`skill-table-row-${row.id}`}>
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className={
                    cell.column.id === "name"
                      ? `${styles.values} ${styles["name-cell"]}`
                      : styles.values
                  }
                  data-testid={`skill-table-${cell.column.id}-${row.id}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default SkillTable;
