import { useState } from "react";

type Action = (() => Promise<any> | void) | undefined | null;
type ResultType = [boolean, ...((() => Promise<any>) | null)[]];

const useIsSubmitting = (actions: Action | Action[]): ResultType => {
  const [isSubmitting, setSubmitting] = useState(false);

  const actionList = Array.isArray(actions) ? actions : [actions];
  const decoratedActions = actionList.map(action => {
    if (!action) {
      return null;
    }

    return async () => {
      setSubmitting(true);

      try {
        return await action();
      } finally {
        setSubmitting(false);
      }
    };
  });

  return [isSubmitting, ...decoratedActions];
};

export default useIsSubmitting;
