import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormField,
  Modal,
  ModalBody,
  ModalHeader,
  FormSelect
} from "@nef/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { debounce, startCase } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation } from "jsonapi-react";

import manageClient from "../../../../api/manage/client";
import { ProductPermission } from "../../../../api/types";
import { MultiSelectOption } from "../../../../components/common/MultiSelect";
import Toast from "../../../../components/Toast";
import FormFieldLabel from "../../../../components/modals/components/FormFieldLabel";

import styles from "./index.module.scss";

interface UserGroup {
  organizationId: string;
  name: string;
  organizationUsers: { label: string; value: string }[];
}

interface AddUserGroupModal {
  close: () => void;
  organizationId: string;
}

const userGroupRequestSchema = yup.object({
  organizationId: yup
    .string()
    .required("Organization name is a required field"),
  name: yup
    .string()
    .required("Name is a required field")
    .min(3, "Name needs to be at least 3 characters"),
  organizationUsers: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required()
      })
    )
    .min(1, "At least one user must be selected")
    .required("Users are a required field")
});

const AddUserGroupModal = ({ close, organizationId }: AddUserGroupModal) => {
  const [userOptions, setUserOptions] = useState<MultiSelectOption[]>([]);
  const [createOrganizationUserGroupRequest] = useMutation(
    "organization-teams",
    {
      client: manageClient
    }
  );

  const [validateData] = useMutation(["organization-teams", "validate"], {
    client: manageClient,
    invalidate: false
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = useForm<UserGroup>({
    mode: "onChange",
    defaultValues: {
      organizationId,
      name: "",
      organizationUsers: []
    },
    resolver: yupResolver(userGroupRequestSchema)
  });

  const { data: organizationUsersData } = useQuery<ProductPermission[]>([
    "organization-users",
    {
      "filter[organization_id]": organizationId
    }
  ]);

  useEffect(() => {
    if (organizationUsersData) {
      setValue("organizationUsers", []);
      setUserOptions(
        organizationUsersData
          .map((product: any) => {
            return {
              value: product.id,
              label: product.fullName || product.inviteEmail
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [organizationUsersData]);

  const validateFieldUniquenessAPI = async () => {
    const { name }: { name: string; organizationId: string } = getValues();

    if (!organizationId || name.length <= 1) {
      return;
    }

    const { errors } = await validateData({
      name,
      "organization-id": organizationId
    });

    const fieldHasBeenTaken = errors?.filter(
      error =>
        error.title.includes("already exists") &&
        error.source.pointer.includes("name")
    );

    if (fieldHasBeenTaken) {
      setError("name", {
        type: "custom",
        message: `${startCase("name")} already exists for this organization`
      });
    }
  };

  const debounceValidateFieldUniquenessAPI = useCallback(
    debounce(validateFieldUniquenessAPI, 300),
    []
  );

  const onSubmit: SubmitHandler<UserGroup> = useCallback(async data => {
    const normalizedRequest: any = decamelizeKeys(data, {
      separator: "-"
    });

    normalizedRequest["organization-users"] = normalizedRequest[
      "organization-users"
    ].map((id: { value: string }) => ({
      id: id.value,
      type: "organization-users"
    }));

    const response = await createOrganizationUserGroupRequest(
      normalizedRequest
    );
    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error creating the user group"
          details={[]}
        />
      );

      return;
    }

    toast(
      <Toast
        type="success"
        title="User group has been successfully created"
        details={[]}
      />
    );

    handleClose();
  }, []);

  const handleClose = () => {
    setUserOptions([]);
    close();
    reset();
  };

  return (
    <Modal
      isOpen
      data-testid="OrganizationsAddUserGroupModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="New User Group"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        New User Group
      </ModalHeader>
      <ModalBody>
        <div>
          <div className={`${!organizationId ? "" : styles["remove-margin"]}`}>
            <FormFieldLabel
              title="Group Name"
              tooltip="Please submit a unique organization group name"
            />
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormField
                  id="GroupName"
                  name={name}
                  type="text"
                  placeholder="Name"
                  value={value}
                  onChange={event => {
                    debounceValidateFieldUniquenessAPI();
                    onChange(event);
                  }}
                  data-testid="OrganizationsAddUserGroupModal_name"
                  invalid={!!formErrors.name}
                  feedback={formErrors?.name?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <FormFieldLabel title="Users" tooltip="Please add a user" />
          <Controller
            control={control}
            name="organizationUsers"
            render={({ field: { onChange, value } }) => (
              <FormSelect
                options={userOptions}
                value={value}
                isMulti
                onChange={val => onChange(val.value)}
                invalid={!!formErrors.organizationUsers}
                feedback={(formErrors?.organizationUsers as any)?.message}
                data-testid="OrganizationsAddUserGroupModal_users"
                classNamePrefix="organizationUser"
              />
            )}
          />

          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={
                Object.keys(formErrors).length !== 0 || !isDirty || !isValid
              }
              data-testid="OrganizationsAddUserGroupModal_submit"
            >
              Create User Group
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="OrganizationsAddUserGroupModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUserGroupModal;
