import convertColumnTypes from "../serializers/datatable";
import { DatatableColumn } from "../types";

/**
 * jsonapi-react documentation is a bit lacking on how `type` works, especially with multi-word type.
 * The following example illustrates how it should work based on jsonapi-react code base:
 * TL;DR - any resource type should be plural and dasherized
 *
 * schema = {
 *   // Resource Key: basically the resource type, and is used to match the resource type in responses. [Dasherized] [Plural]
 *   "products": {
 *     // Resource "Type": this field seems to be non functional, and if it's empty, it's actually gets filled with Resource Key anyway
 *     type: "products",
 *     fields: {},
 *     // seems to be used for serializing data for requests. Used to convert related resources from an `attribute` to a `relationship` in request body.
 *     relationships: {
 *       // Relationship Attribute: attribute name of the relationship and should match responses. If this is not defined here, the `relationship` is
 *       // serialized as an attribute in requests and may cause erors. [Dasherized]
 *       "metadatum": {
 *         // Relationship Resource Type: set `data.relationships.<resource>.type` in requests. Non-functional in frontend but must match a valid resource type
 *         // in the server. Must be plural but interestingly can be either Dasherized or Underscored
 *         type: "product-metadata"
 *       },
 *       "invalid_resources": {
 *         // When serializing, as long as this matches a Resource Key, this will convert to a `relationship` successfully
 *         type: "cannot_deserialize"
 *       }
 *     }
 *   },
 *   "product-metadata": {
 *     type: "product-metadata",
 *     fields: {},
 *     relationships: {
 *       "product": {
 *          type: "products"
 *       }
 *     }
 *   },
 *   // Because resource type comes in dasherized form in responses, this definition won't match. `jsonapi-react` will deserialize as is but none of the
 *   // rules declared in `fields` will be used to deserialize the data
 *   "cannot_deserialize": {
 *     type: "cannot_deserialize",
 *     fields: { ... }
 *   }
 * }
 */

const schema = {
  product_management_groups: {
    type: "product_management_groups"
  },
  product_categories: {
    type: "product_categories"
  },
  products: {
    type: "products",
    fields: {
      deliveryFrequency: {
        serialize: (value: string[]) => value.join(","),
        resolve: (value: string) =>
          value === "" || value === null ? [] : value?.split(",")
      },
      dataFrequency: {
        serialize: (value: string[]) => value.join(","),
        resolve: (value: string) =>
          value === "" || value === null ? [] : value?.split(",")
      },
      active: {
        readOnly: true
      },
      hidden: {
        readOnly: true
      },
      createdAt: {
        type: "date",
        readOnly: true
      },
      updatedAt: {
        type: "date",
        readOnly: true
      },
      productModelsUpdatedAt: {
        type: "date",
        readOnly: true
      },
      "product-approval-type": {
        readOnly: true
      },
      "product-metadatum": {
        readOnly: true
      },
      "product-supporting-documents": {
        readOnly: true
      },
      "cap-product-metadatum": {
        readOnly: true
      }
    },
    relationships: {
      "product-categories": {
        type: "product_categories"
      },
      vendor: {
        type: "vendors"
      },
      product_metadatum: {
        type: "product_metadatum"
      },
      cap_product_metadatum: {
        type: "cap_product_metadatum"
      },
      product_supporting_documents: {
        type: "product_supporting_document"
      },
      "plan-categories": {
        type: "plan-categories",
        readOnly: true
      },
      plans: {
        type: "plans",
        readOnly: true
      },
      "product-models-updater": {
        type: "users",
        readOnly: true
      },
      "product-management-group": {
        type: "product_management_groups",
        readOnly: true
      }
    }
  },
  "product-metadata": {
    type: "product-metadata",
    fields: {
      createdAt: {
        type: "date",
        readOnly: true
      },
      updatedAt: {
        type: "date",
        readOnly: true
      },
      tags: {
        readOnly: true
      }
    }
  },
  "product-supporting-documents": {
    type: "product-supporting-documents",
    fields: {
      createdAt: {
        type: "date",
        readOnly: true
      },
      updatedAt: {
        type: "date",
        readOnly: true
      }
    },
    relationships: {
      product: {
        type: "products"
      }
    }
  },
  sdg_indicators: {
    type: "sdg_indicators"
  },
  vendors: {
    type: "vendors",
    relationships: {
      product: {
        type: "products"
      },
      licences: {
        type: "licences"
      }
    }
  },
  plans: {
    type: "plans",
    fields: {
      updatedAt: {
        type: "date",
        readOnly: true
      }
    },
    relationships: {
      "plan-category": {
        type: "plan-categories"
      }
    }
  },
  "plan-categories": {
    type: "plan-categories",
    relationships: {
      plans: {
        type: "plans"
      },
      licence: {
        type: "licence"
      },
      product: {
        type: "product"
      }
    }
  },
  "cap-product-metadata": {
    type: "cap-product-metadata",
    fields: {
      createdAt: {
        type: "date",
        readOnly: true
      },
      updatedAt: {
        type: "date",
        readOnly: true
      }
    }
  },
  "datatable-schemas": {
    type: "datatable_schemas",
    fields: {
      updatedAt: {
        type: "date",
        readonly: true
      },
      createdAt: {
        type: "date",
        readonly: true
      },
      datatableCode: {
        resolve: (value: string, response: any) => response.code
      },
      productManagementGroupId: {
        resolve: (value: number, response: any) => response.groupId
      },
      partitions: {
        resolve: (value: string[], response: any) =>
          response.fileFormat?.partitions || []
      },
      columns: {
        resolve: (value: DatatableColumn[], response: any) =>
          convertColumnTypes(
            value,
            response.filterable,
            response.primaryKey,
            response.defaultColumns,
            response.defaultFilters,
            response.fileFormat?.partitions || []
          )
      }
    }
  },
  "datatable-schema-filters": {
    type: "datatable-schema-filters"
  },
  "vendor-user-groups": {
    type: "vendor-user-groups",
    relationships: {
      products: {
        type: "products"
      },
      "vendor-users": {
        type: "vendor-users"
      }
    }
  },
  "organization-teams": {
    type: "organization-teams",
    relationships: {
      "organization-users": {
        type: "organization-users"
      }
    }
  },
  "organization-users": {
    type: "organization-users",
    relationships: {
      "organization-teams": {
        type: "organization-teams"
      }
    }
  },
  helpscout_categories: {
    type: "helpscout_categories"
  },
  uploads: {
    type: "uploads",
    fields: {
      lastModified: {
        type: "date",
        readonly: true
      },
      path: {
        readonly: true
      },
      size: {
        readonly: true
      }
    }
  },
  "vendor-users": {
    type: "vendor-users",
    fields: {
      "user-name": {
        type: "date",
        readOnly: true
      },
      "user-email": {
        type: "date",
        readOnly: true
      },
      "created-at": {
        type: "date",
        readOnly: true
      },
      "last-sign-in-at": {
        type: "date",
        readOnly: true
      }
    },
    relationships: {
      products: {
        type: "products"
      },
      "vendor-user-groups": {
        type: "vendor-user-groups"
      }
    }
  }
};

export default schema;
