import React, { useState, useEffect } from "react";
import {
  Button,
  FormSelectCreatable,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import { Option } from "@nef/core/lib/components/components/FormBaseSelect";
import { useQuery } from "jsonapi-react";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { OrganizationUser } from "../../../../../../api/types";
import { EventActionMulti, ProductAccessOption } from "../../types";

import styles from "./index.module.scss";

interface Props {
  existingUsers: ProductAccessOption[];
  close: () => void;
  onSubmit: (data: ProductAccessOption[]) => void;
}

const newInputItem = yup.object({
  label: yup.string(),
  value: yup.string().email().defined()
});

const schema = yup.array(newInputItem);

const AddUserProductAccessModal = ({
  existingUsers,
  close,
  onSubmit
}: Props) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [selectedValues, setSelectedValues] = useState<ProductAccessOption[]>(
    []
  );
  const [error, setError] = useState<string>("");

  const { data } = useQuery<OrganizationUser[]>([
    "organization-users",
    {
      "filter[organization_id]": organizationId
    }
  ]);

  const existingUsersValues = existingUsers.map(
    existingUser => existingUser.value
  );

  useEffect(() => {
    setSelectedValues(existingUsers);
  }, [existingUsers]);

  const validateAllSelectedValues = (eventValues: Option[]) => {
    try {
      schema.validateSync(eventValues);

      eventValues.forEach(eventValue => {
        if (existingUsersValues.includes(eventValue.value)) {
          const duplicatedError = new Error();
          duplicatedError.name = "DuplicatedError";

          throw duplicatedError;
        }
      });

      setError("");
    } catch (err) {
      if ((err as Error).name === "DuplicatedError") {
        setError("User email has already been added");
      } else {
        setError("Must be valid email address");
      }
    }
  };

  const options: Option[] =
    (
      data
        ?.map(user => {
          if (user?.email || user.inviteEmail) {
            return {
              label: user?.email || user.inviteEmail,
              value: user?.email || user.inviteEmail
            };
          }
          return null;
        })
        ?.filter(item => item !== null) as Option[]
    )?.filter(
      option =>
        !existingUsers.some(existingUser => existingUser.value === option.value)
    ) || [];

  const handleOnChange = (event: EventActionMulti) => {
    validateAllSelectedValues(event.value);
    setSelectedValues(
      event.value.map(item => ({
        label: item.label,
        value: item.value,
        type: "User"
      }))
    );
  };

  const handleSubmit = () => {
    onSubmit([...existingUsers, ...selectedValues]);
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="selectableModal"
      toggle={close}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Add User"
        toggle={() => close()}
        className={styles["modal-header"]}
      >
        Add User
      </ModalHeader>
      <ModalBody>
        <div data-testid="userModalInput">
          <FormSelectCreatable
            placeholder="Select..."
            label="Users"
            isMulti
            isClearable
            defaultValue={selectedValues}
            options={options}
            onChange={e => {
              handleOnChange(e as unknown as EventActionMulti);
            }}
            feedback={error}
            invalid={!!error}
            classNamePrefix="modalFormSelect"
          />
        </div>

        <div className={styles.buttons}>
          <Button
            onClick={handleSubmit}
            disabled={!!error}
            data-testid="selectableModal_submit"
          >
            Submit
          </Button>
          <Button
            color="light"
            outline
            onClick={() => close()}
            data-testid="selectableModal_cancel"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUserProductAccessModal;
