import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import useDefaultAuth from "../../hooks/useDefaultAuth";
import { inviteUserAccept } from "../../api/api";
import Toast from "../../components/Toast";
import { PATHS } from "../../routes";
import RedirectUnauthenticatedUserModal from "../../components/modals/RedirectUnauthenticatedUserModal";

const AcceptInvitePage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { authenticatedUser, login } = useDefaultAuth();
  const [isForbidden, setIsForbidden] = useState(false);

  const queryParams = new URLSearchParams(search);
  const inviteToken = queryParams.get("token");

  useEffect(() => {
    async function acceptInvite() {
      if (inviteToken) {
        try {
          await inviteUserAccept(inviteToken);
          await login();
          history.push(PATHS.PUBLISHERS);
        } catch (err) {
          if (
            authenticatedUser?.rolesArray?.includes("manager_publisher_user")
          ) {
            history.push(PATHS.PUBLISHERS);
          } else {
            setIsForbidden(true);

            toast(
              <Toast
                type="error"
                title="Invite could not be accepted"
                details={[]}
              />
            );
          }
        }
      }
    }

    acceptInvite();
  }, []);

  if (isForbidden) {
    return <RedirectUnauthenticatedUserModal />;
  }

  return null;
};

export default AcceptInvitePage;
