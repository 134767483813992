import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextArea } from "@nef/core";
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FormProvider,
  Controller
} from "react-hook-form";
import { toast } from "react-toastify";

import { CapProductToEdit, CapProductToCreate } from "../../../api/types";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import buildCapProductSchema from "../schema";
import TOOLTIP from "../tooltips";
import { BaseStepProps } from "../types";
import { useDirty } from "../cap-product-util";

const IntakeFormStep4 = ({ fields, setFormIsDirty }: BaseStepProps) => {
  const { product: contextProduct, saveAllData } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const productSchema = buildCapProductSchema();
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToEdit>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = form;
  useDirty(isDirty, setFormIsDirty);

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<CapProductToEdit> = async formData => {
    await saveAllData({ capMetadata: formData.capProductMetadatum });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection1Step4">
          <CapSurveyFormFieldRow tooltip={TOOLTIP.KNOWN_ISSUES}>
            <Controller
              name="capProductMetadatum.knownIssues"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormTextArea
                  id="knownIssues"
                  name={name}
                  label="Known Issues"
                  placeholder="Known Issues"
                  value={value || ""}
                  onChange={onChange}
                  optional={true}
                  rows={4}
                  data-testid="capSurveyForm_knownIssues"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.RELATED_DATA}>
            <Controller
              name="capProductMetadatum.relatedData"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormTextArea
                  id="relatedData"
                  name={name}
                  label="Related Data"
                  placeholder="Related Data"
                  value={value || ""}
                  onChange={onChange}
                  optional={true}
                  rows={4}
                  data-testid="capSurveyForm_relatedData"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormStep4;
