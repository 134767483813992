import { intersectionWith, isEqual, uniq, uniqWith } from "lodash";

interface ColumnBlacklistOption {
  name: string;
  type: string;
}

interface Filter {
  key: string;
}

interface FilterByKey {
  [key: string]: Filter;
}

export const copyIntersectingColumns = (
  originSelectedCols: string[],
  originColumnsOptions: ColumnBlacklistOption[],
  targetSelectedCols: string[],
  targetColumnsOptions: ColumnBlacklistOption[]
): string[] => {
  const selectedColsAsOptions = originColumnsOptions.filter(column =>
    originSelectedCols.includes(column.name)
  );
  const intersectingColumns = intersectionWith(
    selectedColsAsOptions,
    targetColumnsOptions,
    isEqual
  );
  const fieldsToAdd = intersectingColumns.map(col => col.name);

  return uniq([...targetSelectedCols, ...fieldsToAdd]);
};

export const copyIntersectingFilters = (
  originAddedFilters: Filter[],
  targetExistingFilters: Filter[],
  targetFilterableKeys: string[]
): Filter[] => {
  const originFiltersByKey: FilterByKey = {};
  originAddedFilters.forEach(filter => {
    originFiltersByKey[filter.key] = filter;
  });

  const originFiltersKeys = originAddedFilters.map(filter => filter.key);

  const intersectingFilters = intersectionWith(
    originFiltersKeys,
    targetFilterableKeys,
    isEqual
  );

  const fieldsToAdd = intersectingFilters.map(
    filterName => originFiltersByKey[filterName]
  );

  return uniqWith(
    [...fieldsToAdd, ...targetExistingFilters],
    (arrVal, othVal) => {
      return arrVal.key === othVal.key;
    }
  );
};
