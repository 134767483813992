import React from "react";
import { Redirect, matchPath, useRouteMatch } from "react-router-dom";

import useWizard from "../../hooks/useWizard";
import { PATHS } from "../../routes";

const CapWizardRedirect = () => {
  const { steps, currentStepIndex } = useWizard();
  const routeMatch = useRouteMatch();
  const root = routeMatch.url;

  const firstUnadvancableStepIndex = steps.findIndex(step => !step.canAdvance);

  const onValidStep =
    currentStepIndex >= 0 && currentStepIndex <= firstUnadvancableStepIndex;

  if (onValidStep) return null;

  const onRootEditPage = !matchPath(root, {
    path: PATHS.NEW_CAP_SURVEY,
    exact: true,
    strict: true
  });

  if (onRootEditPage) {
    const firstUnadvancableStep = steps.find(step => !step.canAdvance);

    if (firstUnadvancableStep === steps[steps.length - 1]) {
      // Form completed, redirect to first step of section 1
      return <Redirect to={`${root}/${steps[1].path}`} />;
    }
    // Form not completed, redirect to first unadvancable step
    return <Redirect to={`${root}/${firstUnadvancableStep?.path}`} />;
  }

  // On /new route, redirect to intro
  return <Redirect to={`${root}/${steps[0].path}`} />;
};

export default CapWizardRedirect;
