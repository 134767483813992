import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef
} from "react";

const QueueContext = createContext<
  | {
      enqueue: (queueName: string, id: string, args: any) => void;
      dequeue: (queueName: string, id: string) => void;
      clear: () => void;
      getQueue: (queueName: string) => undefined | { [_: string]: any };
    }
  | undefined
>(undefined);

function QueueProvider({
  queues,
  children
}: PropsWithChildren<{ queues: string[] }>) {
  const queueRef = useRef<Map<string, { [_: string]: any }>>(
    new Map(queues.map(i => [i, {}]))
  );

  const enqueue = useCallback((queueName: string, id: string, args: any) => {
    const value = queueRef.current.get(queueName);
    if (!value) {
      throw Error(`(queue): ${queueName} does not exist`);
    }
    value[id] = args;
    queueRef.current.set(queueName, value);
  }, []);

  const dequeue = useCallback((queueName: string, id: string) => {
    const value = queueRef.current.get(queueName);
    if (!value) {
      throw Error(`(queue): ${queueName} does not exist`);
    }
    delete value[id];
    queueRef.current.set(queueName, value);
  }, []);

  const clear = useCallback(() => {
    queueRef.current.forEach((_, key) => {
      queueRef.current.set(key, {});
    });
  }, []);

  const getQueue = useCallback((queueName: string) => {
    return queueRef.current.get(queueName);
  }, []);

  return (
    <QueueContext.Provider
      value={{
        enqueue,
        dequeue,
        clear,
        getQueue
      }}
    >
      {children}
    </QueueContext.Provider>
  );
}

function useQueue() {
  const context = useContext(QueueContext);
  if (!context) {
    throw new Error("useQueue must be used within a QueueProvider");
  }
  return context;
}

export { QueueProvider, useQueue };
