import React, { useCallback } from "react";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FlexGrid, FormField } from "@nef/core";
import { toast } from "react-toastify";
import { IResult, useClient, useMutation } from "jsonapi-react";
import styled from "styled-components";

import { NewPlan, PlanCategory, ProductFormMode } from "../../../api/types";
import {
  extractDirtyValues,
  extractErrors
} from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import createPlanBody from "../../../api/normalizers/plan";
import { useProductContext } from "../../../hooks/useProductContext";

import PlanFormSubscriptionInputs from "./PlanFormSubscriptionInputs";
import PlanFormPaymentScheduleInput from "./PlanFormPaymentScheduleInput";
import PlanFormActivateButton from "./plan-form/PlanFormActivateButton";
import PlanFormDeleteButton from "./plan-form/PlanFormDeleteButton";
import PlanFormDeactivateButton from "./plan-form/PlanFormDeactivateButton";

interface PlanFormProps {
  plan: NewPlan;
  planCategory: PlanCategory;
  close: () => void;
}

const FullWidthBox = styled(Box)`
  width: 100%;
`;

const MAX_INT_32 = 2147483647;
const MAX_DOLLAR_VALUE = new Intl.NumberFormat("en-US").format(
  Math.floor(MAX_INT_32 / 100)
);

const PlanForm = ({ plan, planCategory, close }: PlanFormProps) => {
  const [createPlan] = useMutation<NewPlan>("plans");
  const [editPlan] = useMutation<NewPlan>(["plans", plan.id]);
  const [deactivatePlan] = useMutation(["plans", plan.id, "deactivate"], {
    method: "PATCH"
  });

  const client = useClient();

  const { product } = useProductContext(ProductFormMode.EDIT);

  const isProductInDraft = !product.active;

  const planSchema = yup.object({
    allowPayment: yup.boolean(),
    allowContactSales: yup.boolean(),
    currency: yup.string().oneOf(["usd"]),
    amountCents: yup
      .mixed()
      .label("Dollar Amount")
      .when("allowPayment", {
        is: true,
        then: yup
          .number()
          .moreThan(0)
          .max(
            MAX_INT_32,
            `Dollar Amount must be less than or equal to $${MAX_DOLLAR_VALUE}`
          )
          .required()
      })
      .when("allowContactSales", {
        is: true,
        then: yup.mixed().oneOf([null])
      }),
    interval: yup.string().oneOf(["month", "year"]),
    intervalCount: yup.number(),
    history: yup.string()
  });

  const methods = useForm({
    defaultValues: plan,
    resolver: yupResolver(planSchema)
  });
  const {
    handleSubmit,
    formState: { dirtyFields }
  } = methods;

  const onSubmitPlan = useCallback(async () => {
    const onSubmit: SubmitHandler<NewPlan> = async data => {
      const createMode = !plan.id;
      const sanitizedPlan = extractDirtyValues(
        !createMode && dirtyFields,
        data
      );

      const normalizedPlan: any = createPlanBody({
        plan: sanitizedPlan,
        planCategory
      });

      const response = createMode
        ? await createPlan(normalizedPlan)
        : await editPlan(normalizedPlan);

      handleResponseErrors(response);

      const toastData = createMode
        ? {
            title: "Pricing plan created",
            message: `A new pricing plan has been added to the ${planCategory.name} licence`
          }
        : {
            title: "Pricing plan updated",
            message: "The pricing plan has been updated successfully"
          };

      toast(
        <Toast
          type="success"
          title={toastData.title}
          details={[{ message: toastData.message }]}
        />
      );

      close();
    };
    await handleSubmit(onSubmit, onValidationErrors)().catch(errorDetails => {
      toast(
        <Toast
          type="error"
          title="The pricing plan cannot be submitted"
          details={errorDetails}
        />
      );
    });
  }, [dirtyFields, plan.id]);

  const canDelete = plan.id && isProductInDraft;

  const onDeletePlan = async () => {
    const onSubmit = async () => {
      const response = await client.delete(["plans", plan.id]);
      handleResponseErrors(response);

      toast(
        <Toast
          type="success"
          title="The pricing plan was successfully deleted"
          details={[]}
        />
      );

      close();
    };

    await onSubmit().catch((errorDetails: { message: any }[]) => {
      toast(
        <Toast
          type="error"
          title="The pricing plan cannot be deleted"
          details={errorDetails}
        />
      );
    });
  };

  const onCreateActivePlan = async () => {
    const onSubmit: SubmitHandler<NewPlan> = async data => {
      // ensures that, when updating an active plan, the id of the original plan is stripped before passing into the normalizer
      const newData = { ...data, id: undefined };
      const normalizedPlan: any = createPlanBody({
        plan: newData,
        planCategory
      });
      const response = await createPlan(normalizedPlan);

      handleResponseErrors(response);

      toast(
        <Toast
          type="success"
          title="Pricing plan activated"
          details={[
            {
              message: `A new active pricing plan has been added to the ${planCategory.name} licence`
            }
          ]}
        />
      );

      close();
    };

    await handleSubmit(onSubmit, onValidationErrors)().catch(errorDetails => {
      toast(
        <Toast
          type="error"
          title="The pricing plan cannot be activated"
          details={errorDetails}
        />
      );
    });
  };

  const canDeactivate = plan.id && plan.active;
  const onDeactivatePlan = async () => {
    const onSubmit: SubmitHandler<NewPlan> = async () => {
      const response = await deactivatePlan({});

      handleResponseErrors(response);

      toast(
        <Toast
          type="success"
          title="Pricing plan deactivated"
          details={[
            {
              message: "The pricing plan has been deactivated"
            }
          ]}
        />
      );

      close();
    };

    await handleSubmit(onSubmit, onValidationErrors)().catch(errorDetails => {
      toast(
        <Toast
          type="error"
          title="The pricing plan cannot be deactivated"
          details={errorDetails}
        />
      );
    });
  };

  const onValidationErrors: SubmitErrorHandler<NewPlan> = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();
    throw errorDetails;
  };

  const handleResponseErrors = (response: IResult) => {
    const { error, errors } = response;
    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      const formattedErrors = responseErrors.map(e => ({
        message: e.detail
      }));

      throw formattedErrors;
    }
  };

  return (
    <>
      <form>
        <FlexGrid fluid={true}>
          <FlexGrid.Row>
            <FlexGrid.Column md={6}>
              <FormField
                disabled={true}
                label="Licence Type"
                value={planCategory.name}
                optional={true}
                data-testid="planForm_licenceType"
              />
            </FlexGrid.Column>
          </FlexGrid.Row>
          <FlexGrid.Row>
            <FormProvider {...methods}>
              <PlanFormSubscriptionInputs />
              <PlanFormPaymentScheduleInput />
              <FlexGrid.Column md={5}>
                <FullWidthBox paddingVertical={4} paddingHorizontal={0}>
                  <FormField
                    type="text"
                    disabled={true}
                    label="History"
                    value="Full history"
                    data-testid="planForm_history"
                  />
                </FullWidthBox>
              </FlexGrid.Column>
            </FormProvider>
          </FlexGrid.Row>
          <FlexGrid.Row>
            {plan.active ? (
              <FlexGrid.Column md="auto">
                <PlanFormActivateButton
                  activatePlan={onCreateActivePlan}
                  isExistingPlan={!!plan.id}
                />
              </FlexGrid.Column>
            ) : (
              <FlexGrid.Column md="auto">
                <Button
                  color="secondary"
                  onClick={() => onSubmitPlan()}
                  data-testid="planForm_submit"
                >
                  Submit and Validate
                </Button>
              </FlexGrid.Column>
            )}

            {canDelete && (
              <FlexGrid.Column md="auto">
                <PlanFormDeleteButton deletePlan={onDeletePlan} />
              </FlexGrid.Column>
            )}

            {canDeactivate && (
              <FlexGrid.Column md="auto">
                <PlanFormDeactivateButton deactivatePlan={onDeactivatePlan} />
              </FlexGrid.Column>
            )}
          </FlexGrid.Row>
        </FlexGrid>
      </form>
    </>
  );
};

export default PlanForm;
