import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation } from "jsonapi-react";

import manageClient from "../../api/manage/client";
import Toast from "../Toast";
import MultiSelect, { MultiSelectOption } from "../common/MultiSelect";
import { ProductPermission, Vendor } from "../../api/types";
import FormFieldLabel from "../modals/components/FormFieldLabel";

import EDIT_USER_GROUP_MODAL_PERMISSION_INFO from "./hints";
import styles from "./GroupPermissionsModal.module.scss";

interface NewUserGroupRequest {
  products: string[];
}

interface FormValues {
  products: string[];
}

interface GroupPermissionsModalProp {
  isOpen: boolean;
  close: () => void;
  vendorId: string;
  groupId: string;
  name: string;
}

const userGroupRequestSchema = yup.object({
  products: yup
    .array()
    .of(yup.string())
    .min(1, "At least one permission must be selected")
    .required("Permissions are a required field")
});

const GroupPermissionsModal = ({
  isOpen,
  close,
  name,
  vendorId,
  groupId
}: GroupPermissionsModalProp) => {
  const [productOptions, setProductOptions] = useState(
    [] as MultiSelectOption[]
  );
  const [selectedOptions, setSelectedOptions] = useState([] as any);

  const [createVendorUserGroupRequest] = useMutation(
    ["vendor-user-groups", groupId],
    {
      client: manageClient,
      method: "PATCH"
    }
  );

  const { data: products, refetch: refetchProducts } = useQuery<
    ProductPermission[]
  >([
    "datatable-collections",
    {
      "filter[vendor_id]": vendorId
    }
  ]);

  const { data: selected, refetch: refetchSelected } = useQuery<Vendor>([
    "vendor-user-groups",
    groupId,
    {
      include: "products"
    }
  ]);

  useEffect(() => {
    if (isOpen && refetchProducts && refetchSelected) {
      refetchProducts();
      refetchSelected();
    }
  }, [isOpen]);

  useEffect(() => {
    if (products) {
      setProductOptions(
        products.map((product: ProductPermission) => {
          return {
            value: product.id,
            label: product.name
          };
        })
      );
    }
  }, [products]);

  useEffect(() => {
    if (selected && selected.products) {
      setSelectedOptions(
        selected.products.map((product: ProductPermission) => {
          return {
            value: product.id,
            label: product.name
          };
        })
      );
      setValue(
        "products",
        selected.products.map((product: ProductPermission) => product.id)
      );
    }
  }, [selected, isOpen]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    reset
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      products: []
    },
    resolver: yupResolver(userGroupRequestSchema)
  });

  const onSubmit: SubmitHandler<NewUserGroupRequest> = useCallback(
    async data => {
      const normalizedRequest: any = decamelizeKeys(data, {
        separator: "-"
      });

      normalizedRequest.id = groupId;
      normalizedRequest.products = normalizedRequest.products.map(
        (id: string) => ({
          id,
          type: "products"
        })
      );

      const response = await createVendorUserGroupRequest(normalizedRequest);
      const { error, errors } = response;

      if (error || errors) {
        const responseErrors = error ? [error] : errors;

        if (!responseErrors) return;

        toast(
          <Toast
            type="error"
            title="There was an error updating the permissions"
            details={[]}
          />
        );

        return;
      }

      toast(
        <Toast
          type="success"
          title="Permissions have been successfully updated"
          details={[]}
        />
      );

      handleClose();
    },
    []
  );

  const handleClose = () => {
    close();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="groupPermissionsModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        data-testid="groupPermissionsModal_header"
        title={`${name}: Permissions`}
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        {name}: Permissions
      </ModalHeader>
      <ModalBody>
        <div>
          <FormFieldLabel
            title="Permissions"
            tooltip={EDIT_USER_GROUP_MODAL_PERMISSION_INFO}
          />
          <Controller
            control={control}
            name="products"
            render={({ field: { onChange, value } }) => {
              return (
                <MultiSelect
                  options={productOptions}
                  value={value || selectedOptions}
                  invalid={!!formErrors.products}
                  feedback={(formErrors?.products as any)?.message}
                  onChange={onChange}
                />
              );
            }}
          />

          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(formErrors).length !== 0}
              data-testid="groupPermissionsModal_submit"
            >
              Update
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="groupPermissionsModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default GroupPermissionsModal;
