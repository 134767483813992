import { setLocale } from "yup";

const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

// eslint-disable-next-line import/prefer-default-export
export const configure = () => {
  setLocale({
    mixed: {
      required: ({ path }) => {
        return `${capitalizeWord(path)} cannot be blank`;
      }
    }
  });
};
