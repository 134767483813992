import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ProductToEdit, ProductFormValues } from "../../api/types";

import EditProductForm from "./EditProductForm";
import buildProductSchema from "./schema";

type BaseProductFormProps = {
  product: ProductToEdit;
  initialProductValue: ProductToEdit;
  setProduct: (product: ProductToEdit) => void;
};

const BaseProductForm = ({
  product,
  initialProductValue,
  setProduct
}: BaseProductFormProps) => {
  /**
   * Product hooks
   */

  const dataHosted = !!product?.capProductMetadatum?.dataHosting;
  const productSchema = buildProductSchema(dataHosted);
  const productForm = useForm<ProductFormValues>({
    defaultValues: initialProductValue,
    resolver: yupResolver(productSchema),
    context: { isDraftProduct: !product.active }
  });

  useEffect(() => {
    // This ensures `resetField` works on `active` and `hidden` fields.
    // `resetFields` only works with fields registered to RFH, mostly done
    // through `<Controller />` in this project. Because `active/hidden`
    // fields do not have UI inputs, they have to be manually registered
    // here as "virtual inputs"
    productForm.register("active");
    productForm.register("hidden");
    productForm.register("productApprovalStatus");
  }, [productForm]);

  /**
   * render function
   */
  return (
    <FormProvider {...productForm}>
      <EditProductForm
        product={product}
        setProduct={setProduct}
        productSchema={productSchema}
      />
    </FormProvider>
  );
};

export default BaseProductForm;
