import React from "react";

import { SchemaError } from "../../../api/types";

import styles from "./DatatableInput.module.scss";

const ERROR_TYPE_TO_SUMMARY = {
  "invalid-csv": "Invalid file type",
  "header-format-invalid": "Invalid file content",
  "table-name-missing": "Table name not provided",
  "table-name-mismatch": "Table name in the schema does not match form",
  "column-name-missing": "Column name(s) not specified",
  "column-name-with-space": "Space(s) included in column name(s)",
  "column-name-with-invalid-character":
    "Non-standard character(s) included in column name(s)",
  "data-type-missing": "Data type(s) not specified",
  "data-type-not-supported": "Data type(s) not supported",
  "filter-missing": "Filter field(s) not specified",
  "primary-key-missing": "Primary key(s) not specified"
};

// This feature is hidden for now (through CSS) until it is more thought out.
const DatatableEntryErrorSummary = ({
  schemaErrors
}: {
  schemaErrors: SchemaError[] | null;
}) => {
  const errorTypes = (schemaErrors || []).map(error => error.type);
  const uniqueErrorTypes = Array.from(new Set(errorTypes));
  const summaries = uniqueErrorTypes.map(type => ERROR_TYPE_TO_SUMMARY[type]);

  return (
    <div className={styles["table-error-summary"]}>
      {summaries.map(summary => (
        <div key={summary}>{summary}</div>
      ))}
    </div>
  );
};

export default DatatableEntryErrorSummary;
