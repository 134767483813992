import React from "react";
import { toast } from "react-toastify";

import Toast from "../Toast";
import { SubmitType } from "../../contexts/product";

interface StringMap {
  [key: string]: any;
}

interface handleProductResponseToastArgs {
  productName: string;
  errors?: StringMap[];
  action: SubmitType;
}

const handleProductResponseToast = ({
  productName,
  errors,
  action
}: handleProductResponseToastArgs) => {
  if (errors?.length) {
    const errorDetails = errors.map(e => {
      return { message: e.detail };
    });

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );

    return;
  }

  if (action === "unpublish") {
    toast(
      <Toast
        type="success"
        title="Product is unpublished"
        details={[{ message: `${productName} is unpublished successfully` }]}
      />
    );

    return;
  }

  if (action === "publish") {
    toast(
      <Toast
        type="success"
        title="Product is published"
        details={[{ message: `${productName} is published successfully` }]}
      />
    );

    return;
  }

  if (action === "submit_for_approval") {
    toast(
      <Toast
        type="success"
        title="Product is submitted for approval"
        details={[{ message: `${productName} is now being reviewed` }]}
      />
    );

    return;
  }

  if (action === "approve") {
    toast(
      <Toast
        type="success"
        title="Product is approved"
        details={[{ message: `${productName} is approved successfully` }]}
      />
    );

    return;
  }

  if (action === "update_live") {
    toast(
      <Toast
        type="success"
        title="Product is updated"
        details={[{ message: `${productName} is updated succesfully` }]}
      />
    );

    return;
  }

  toast(
    <Toast
      type="info"
      title="Progress is Saved"
      details={[
        {
          message: "Saved the draft",
          description: "You can go back to list and continue next time"
        }
      ]}
    />
  );
};

export default handleProductResponseToast;
