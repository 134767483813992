import React from "react";
import { FormTextArea } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";

import { formSchema } from "../../../schema";

import styles from "./index.module.scss";

const Notes = ({ formFieldName }: { formFieldName: string }) => {
  const { control, getValues } =
    useFormContext<yup.Asserts<typeof formSchema>>();

  const formValue = getValues(formFieldName as any);

  return (
    <Controller
      key={formValue}
      name={formFieldName as any}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormTextArea
          id={formFieldName}
          rows={4}
          data-testid="OrganizationsAddProductDrawer_notes"
          label="Notes"
          type="text"
          optional={true}
          className={styles.textarea}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default Notes;
