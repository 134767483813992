import React, { useEffect, useState } from "react";

import useWizard from "../../hooks/useWizard";

import { StepProps } from "./types";

const Step: React.FC<StepProps> = props => {
  const { path, children, canAdvance, save } = props;

  const [isMounted, setMounted] = useState(false);
  const { registerStep, unregisterStep, updateStep, currentStepPath } =
    useWizard();

  useEffect(() => {
    registerStep(props);
    setMounted(true);

    // The cleanup here ensures a Step is properly setup/taken down
    // when dynamically added/removed. While there is no actual use case
    // for it currently, this behaviour occurs during development when
    // live-loading code changes and it has to be handled.
    return () => {
      unregisterStep(props);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      updateStep(props);
    }
  }, [canAdvance, save]);

  if (currentStepPath !== path) {
    return null;
  }

  return <>{children}</>;
};

export default Step;
