import { Product } from "../api/types";

export const PRODUCT_STATUS = Object.freeze({
  UNSAVED: "Unsaved",
  DRAFT: "Draft",
  UNPUBLISHED: "Unpublished",
  WAITING_APPROVAL: "Waiting Approval",
  PUBLISHED: "Published"
});

const useProductStatus = (product: Product) => {
  if (!product.id) {
    return PRODUCT_STATUS.UNSAVED;
  }

  if (
    (product.productApprovalStatus === "pending_approval" ||
      product.productApprovalStatus === "intake_pending_approval") &&
    product.productApprovalType === "approval_required"
  ) {
    return PRODUCT_STATUS.WAITING_APPROVAL;
  }

  if (!product.active) {
    return PRODUCT_STATUS.DRAFT;
  }

  if (product.hidden) {
    return PRODUCT_STATUS.UNPUBLISHED;
  }

  return PRODUCT_STATUS.PUBLISHED;
};

export default useProductStatus;
