import React from "react";
import styled from "styled-components";
import { Button } from "@nef/core";
import { generatePath, Link } from "react-router-dom";

import { PlanCategory, Plan, ProductFormMode } from "../../../api/types";
import { capitalize, capitalizeWords } from "../../../helpers/string-helpers";
import { useProductContext } from "../../../hooks/useProductContext";
import { PATHS } from "../../../routes";

import styles from "./PlanCategoryCard.module.scss";

interface PlanCategoryCardProps {
  planCategory: PlanCategory;
  openNewPlan: () => void;
  openExistingPlan: (plan: Plan) => void;
}

const AddPricingPlanButton = styled(Button)`
  width: 190px;
  height: 24px;
  font-size: 11px;
  font-family: "Roboto";
  padding: 6px 12px;
`;

const PlanCategoryCard = ({
  planCategory,
  openNewPlan,
  openExistingPlan
}: PlanCategoryCardProps) => {
  const { product } = useProductContext(ProductFormMode.EDIT);

  const getPlanName = (plan: Plan) => {
    const dollarValue = new Intl.NumberFormat("en-US").format(
      (plan.amountCents || 0) / 100
    );

    const paymentPiece = plan.allowPayment
      ? `${plan.currency.toUpperCase()} $${dollarValue}`
      : "Contact Sales";

    return `${paymentPiece} / ${capitalize(plan.humanizedAdverb)} - ${
      "history" in plan.filterLabels &&
      capitalizeWords(plan.filterLabels.history)
    }`;
  };

  const visiblePlans = planCategory.plans?.filter(plan => {
    return plan.active || !product.active;
  });

  return (
    <div className={styles.container} data-testid="planCategoryCard">
      <h2 className={styles.title} data-testid="planCategoryCard_name">
        {planCategory.name} Licence
      </h2>

      <Link
        to={generatePath(PATHS.LICENCE, {
          productId: product.id,
          licenceId: planCategory.licence.id
        })}
        data-testid="planCategoryCard_licence"
        className={styles.licence}
      >
        Licence Agreement
      </Link>

      <AddPricingPlanButton
        onClick={() => openNewPlan()}
        data-testid="planCategoryCard_addPricingButton"
      >
        + Add Pricing Option
      </AddPricingPlanButton>

      <div className={styles["plans-list"]}>
        {visiblePlans?.map((plan: Plan) => {
          return (
            <div
              key={plan.id}
              className={styles.plan}
              onClick={() => openExistingPlan(plan)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                e.key === "Enter" && openExistingPlan(plan)
              }
              role="button"
              tabIndex={0}
              data-testid="planCategoryCard_plan"
            >
              {getPlanName(plan)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PlanCategoryCard;
