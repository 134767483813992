import React from "react";

import styles from "./FormFieldRow.module.scss";

interface CapSurveyFormFieldRowProps {
  tooltip: string;
  children: JSX.Element | JSX.Element[];
  stacked?: boolean;
}

const CapSurveyFormFieldRow = ({
  tooltip,
  children,
  stacked = false
}: CapSurveyFormFieldRowProps) => {
  const className = [styles.row, stacked && styles["row--stacked"]]
    .filter(v => !!v)
    .join(" ");

  return (
    <div className={className}>
      <div
        className={styles.tooltip}
        dangerouslySetInnerHTML={{ __html: tooltip }} // eslint-disable-line react/no-danger
      />
      <div className={styles.field}>{children}</div>
    </div>
  );
};

export default CapSurveyFormFieldRow;
