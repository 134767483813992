import React from "react";
import { FormField } from "@nef/core";
import { Controller } from "react-hook-form";

import CapSurveyFormFieldRow from "../../../../cap-survey-form/CapSurveyFormFieldRow";

const OtherVariant = ({ formMethods }: any) => {
  const { control } = formMethods;

  return (
    <>
      <CapSurveyFormFieldRow tooltip="">
        <Controller
          name="ingestDetails"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormField
              id="ingestDetails"
              name={name}
              label="Ingest Details"
              value={value || ""}
              optional={false}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>
    </>
  );
};

export default OtherVariant;
