import { useContext } from "react";

import { ProductFormMode } from "../api/types";
import ProductContext, {
  CreateProductContextProps,
  EditProductContextProps
} from "../contexts/product";

export function useProductContext():
  | CreateProductContextProps
  | EditProductContextProps;
export function useProductContext(
  mode: ProductFormMode.CREATE
): CreateProductContextProps;
export function useProductContext(
  mode: ProductFormMode.EDIT
): EditProductContextProps;
export function useProductContext(mode?: ProductFormMode) {
  const contextValues = useContext(ProductContext);

  if (contextValues === null) {
    throw Error("Product Context has not been provided.");
  }

  if (!!mode && mode !== contextValues.mode) {
    throw Error("Mismatched Product Context was provided.");
  }

  return contextValues;
}
