/**
 * Typescript-safe (ish) solution to clone an object with selected properties
 * (reference: https://stackoverflow.com/a/56162151)
 */
export default function pick<T extends object, U extends keyof T>(
  obj: T,
  keys: Array<U>
): Pick<T, U> {
  const result = Object.create(null);
  keys.forEach(key => {
    result[key] = obj[key];
  });

  return result;
}

export const extractPageNumber = (url?: string): number => {
  if (!url) {
    return 1;
  }

  const urlObject = new URL(url);
  const pageNumber = urlObject.searchParams.get("page[number]");

  return pageNumber ? parseInt(pageNumber, 10) : 1;
};
