import React from "react";
import { format } from "date-fns";

import { Plan, PlanCategory } from "../../../api/types";
import { capitalize, capitalizeWords } from "../../../helpers/string-helpers";

import styles from "./PlanCategoryTable.module.scss";
import ReactivatePlanButton from "./ReactivatePlanButton";

interface PlanCategoryTableProps {
  planCategory: PlanCategory & { plans: Plan[] };
}

const PlanCategoryTable = ({ planCategory }: PlanCategoryTableProps) => {
  return (
    <div className={styles.wrapper} data-testid="planCategoryTable">
      <div className={styles.name} data-testid="planCategoryTable_name">
        {planCategory.name}
      </div>
      <table className={styles.table} data-testid="planCategoryTable_table">
        <thead>
          <tr>
            <th>Payment Type</th>
            <th>Amount</th>
            <th>Payment Schedule</th>
            <th>History</th>
            <th>Last Change</th>
            <th># of users</th>
            <th aria-label="actions" className={styles.actions} />
          </tr>
        </thead>
        <tbody>
          {planCategory.plans.length ? (
            planCategory.plans.map(plan => {
              const dollarValue = new Intl.NumberFormat("en-US").format(
                (plan.amountCents || 0) / 100
              );

              return (
                <tr key={plan.id} data-testid="planCategoryTable_plan">
                  <td data-testid="planCategoryTable_planType">
                    {plan.allowPayment ? "Subscription" : "Contact Sales"}
                  </td>
                  <td data-testid="planCategoryTable_planAmount">
                    {plan.allowPayment
                      ? `${plan.currency.toUpperCase()} $${dollarValue}`
                      : "-"}
                  </td>
                  <td data-testid="planCategoryTable_planPaymentSchedule">
                    {plan.allowPayment ? capitalize(plan.humanizedAdverb) : "-"}
                  </td>
                  <td data-testid="planCategoryTable_planHistory">
                    {capitalizeWords(plan.filterLabels.history)}
                  </td>
                  <td data-testid="planCategoryTable_planLastChange">
                    {format(plan.updatedAt, "MM/yyyy")}
                  </td>
                  <td data-testid="planCategoryTable_planSubscribers">
                    {plan.subscriberCount || 0}
                  </td>
                  <td width="40%">
                    <ReactivatePlanButton
                      plan={plan}
                      planCategory={planCategory}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7}>
                There are no grandfathered plans for this licence
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PlanCategoryTable;
