import React from "react";

import MoreInfoTooltip from "../MoreInfoTooltip";

import styles from "./LabelValueWithHint.module.scss";

interface LabelValueWithHintProps {
  label: string;
  hint: string;
}

const LabelValueWithHint = ({ label, hint }: LabelValueWithHintProps) => {
  return (
    <span data-testid="labelValueWithHint" className={styles.label}>
      {label} <MoreInfoTooltip description={hint} />
    </span>
  );
};

export default LabelValueWithHint;
