import React from "react";
import { FlexGrid, FormField } from "@nef/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const DataLocationCredentialInput = () => {
  const { control } = useFormContext();

  const typeValue = useWatch({ control, name: "type" });
  const showCredentialsInputs = ["ftp", "sftp"].includes(typeValue);

  if (!showCredentialsInputs) {
    return null;
  }

  return (
    <>
      <FlexGrid.Column md={12} lg={4}>
        <Controller
          name="username"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <FormField
                id="username"
                name={name}
                type="text"
                placeholder="Enter user name"
                label="User name"
                value={value}
                onChange={onChange}
                data-testid="dataLocationStep_username"
              />
            );
          }}
        />
      </FlexGrid.Column>
      <FlexGrid.Column md={12} lg={4}>
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <FormField
                id="password"
                name={name}
                type="password"
                placeholder="Enter password"
                label="Password"
                value={value}
                onChange={onChange}
                data-testid="dataLocationStep_password"
              />
            );
          }}
        />
      </FlexGrid.Column>
    </>
  );
};

export default DataLocationCredentialInput;
