import React from "react";
import { FormField, FormSelect } from "@nef/core";
import { toast } from "react-toastify";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import CapSurveyFormFieldRow from "../cap-survey-form/CapSurveyFormFieldRow";
import { DatatablesNameDescription, Schedule } from "../../api/types";
import { extractErrors } from "../../utils/react-hook-form-utils";
import Toast from "../Toast";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import TOOLTIP from "../cap-survey-form/tooltips";
import { buildHostedDatatableSchema } from "../cap-survey-form/schema";
import FormLabel from "../common/FormLabel";
import { CAP_DATA_FREQUENCY_OPTIONS } from "../product-form/frequency-options";
import Scheduler from "../cap-survey-form/components/Scheduler/Scheduler";
import IntradayDescriptionFieldRow from "../cap-survey-form/components/Scheduler/fields/IntradayDescriptionFieldRow";

import { BaseDatatableStepProps } from "./types";
import IntakeDatatableModalFooter from "./IntakeDatatableModalFooter";
import styles from "./HostedDatatableWizardStep2.module.scss";

const REPORTING_LAG_UNITS = [
  "N/A",
  "second(s)",
  "minute(s)",
  "hour(s)",
  "calendar day(s)",
  "business day(s)",
  "week(s)",
  "month(s)"
].map(value => {
  return {
    value,
    label: value
  };
});

const HostedDatatableWizardStep2 = ({
  fields,
  datatable,
  save,
  cancel,
  discard,
  onPreviousStep
}: BaseDatatableStepProps) => {
  const datatableSchema = buildHostedDatatableSchema();
  const stepSchema = datatableSchema.pickNested(fields);

  const form = useForm<DatatablesNameDescription>({
    defaultValues: datatable,
    resolver: yupResolver(stepSchema)
  });
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = form;

  const saveStep = async () => {
    await handleSubmit(
      async formData => onHandleSubmit(formData),
      onHandleSubmitError
    )().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<
    DatatablesNameDescription
  > = async formData => {
    await save(formData, false);
  };

  const onHandleSubmitError: SubmitErrorHandler<
    DatatablesNameDescription
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  const dataGranularity = watch("dataGranularity");
  const reportingLagUnit = watch("reportingLag.unit");

  const shouldShowDataGranularityNotes = dataGranularity.includes("irregular");

  // only need its initial value to initialize `<Scheduler />`
  const updateFrequency = getValues("updateFrequency");

  return (
    <>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATA_FREQUENCY}>
        <Controller
          name="dataGranularity"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <FormSelect
              id="dataGranularity"
              name={name}
              label="Data Granularity"
              value={
                CAP_DATA_FREQUENCY_OPTIONS.find(o => o.value === value) || null
              }
              isClearable={false}
              options={CAP_DATA_FREQUENCY_OPTIONS}
              onChange={(formSelectOnChangeParam: any) => {
                onChange(
                  extractFormSelectOnChangeValue(formSelectOnChangeParam)
                );
              }}
              classNamePrefix="modalFormSelect"
            />
          )}
        />
      </CapSurveyFormFieldRow>
      {shouldShowDataGranularityNotes && (
        <CapSurveyFormFieldRow tooltip="">
          <Controller
            name="dataGranularityNotes"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <FormField
                id="dataGranularityNotes"
                name={name}
                label="Data Granularity Notes"
                value={value || ""}
                optional={false}
                onChange={onChange}
                data-testid="capSurveyForm_dataGranularityNotes"
              />
            )}
          />
        </CapSurveyFormFieldRow>
      )}
      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_UPDATE_FREQUENCY}>
        <FormLabel required={true}>Update Frequency</FormLabel>
        <Scheduler
          value={updateFrequency}
          setValue={(newValue: Schedule) => {
            setValue("updateFrequency", newValue);
          }}
        />
      </CapSurveyFormFieldRow>

      <FormProvider {...form}>
        <IntradayDescriptionFieldRow />
      </FormProvider>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATATABLE_REPORTING_LAG}>
        <FormLabel required={true}>Reporting Lag</FormLabel>
        <div className={styles["lag-input"]}>
          {reportingLagUnit && reportingLagUnit !== "N/A" && (
            <Controller
              name="reportingLag.value"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormField
                  id="reportingLagValue"
                  name={name}
                  type="number"
                  label=""
                  min={0}
                  placeholder="number"
                  value={`${value}`}
                  optional={false}
                  onChange={onChange}
                  data-testid="intakeDatatableModal_reportingLagValue"
                />
              )}
            />
          )}
          <Controller
            name="reportingLag.unit"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <FormSelect
                id="reportingLagUnit"
                name={name}
                label=""
                value={REPORTING_LAG_UNITS.find(o => o.value === value) || null}
                isClearable={false}
                options={REPORTING_LAG_UNITS}
                onChange={(formSelectOnChangeParam: any) => {
                  onChange(
                    extractFormSelectOnChangeValue(formSelectOnChangeParam)
                  );
                }}
                classNamePrefix="modalFormSelect"
              />
            )}
          />
        </div>
      </CapSurveyFormFieldRow>
      <IntakeDatatableModalFooter
        save={saveStep}
        canAdvance={!isSubmitting && isSubmitSuccessful}
        cancel={isDirty ? discard : cancel}
        onPreviousStep={onPreviousStep}
        formState={form}
      />
    </>
  );
};

export default HostedDatatableWizardStep2;
