import React, { useEffect, useState } from "react";
import { Button } from "@nef/core";

import useWizard from "../../hooks/useWizard";

import styles from "./IntakeDatatableModalFooter.module.scss";

interface IntakeDatatableModalFooterProps {
  save: (shouldAdvance?: boolean) => Promise<void>;
  cancel: () => void;
  canAdvance?: boolean;
  onPreviousStep?: (formState: any) => void;
  formState?: any;
}

const IntakeDatatableModalFooter = ({
  save,
  cancel,
  canAdvance,
  onPreviousStep,
  formState
}: IntakeDatatableModalFooterProps) => {
  const { nextPath, previousPath, navigateTo, steps } = useWizard();
  const [shouldAdvance, setShouldAdvance] = useState(false);

  const onPrevious = () => {
    setShouldAdvance(false);
    if (onPreviousStep) {
      onPreviousStep(formState);
    }
    navigateTo(previousPath);
  };

  const onNext = () => {
    setShouldAdvance(true);
    save(true);
  };

  useEffect(() => {
    if (shouldAdvance && canAdvance) {
      navigateTo(nextPath);
    }
  }, [shouldAdvance, canAdvance, nextPath]);

  return (
    <div className={styles.footer}>
      <div
        className={styles.group}
        data-testid="capSurveyFormStepFooter_previous"
      >
        {steps.length > 1 && (
          <Button onClick={onPrevious} disabled={!previousPath} outline={true}>
            Previous
          </Button>
        )}
        <Button onClick={onNext} data-testid="capSurveyFormStepFooter_next">
          {nextPath ? "Next" : "Submit"}
        </Button>
        <Button onClick={cancel} outline={true}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default IntakeDatatableModalFooter;
