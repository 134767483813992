import React, { useCallback } from "react";
import { Button, FormField, Modal, ModalBody, ModalHeader } from "@nef/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { StringMap, useMutation } from "jsonapi-react";
import { debounce, startCase } from "lodash";
import { useHistory } from "react-router-dom";

import manageClient from "../../api/manage/client";
import Toast from "../Toast";
import FormFieldLabel from "../modals/components/FormFieldLabel";

import {
  VENDOR_MODAL_ADMIN_CONTACT_INFO,
  VENDOR_MODAL_CODE_INFO,
  VENDOR_MODAL_NAME_INFO
} from "./hints";
import styles from "./NewVendorModal.module.scss";

interface NewVendorRequest {
  name: string;
  code: string;
  administratorContactEmail: string;
}

interface NewVendorModalProps {
  isOpen: boolean;
  close: () => void;
}

const vendorRequestSchema = yup.object({
  name: yup
    .string()
    .required("Publisher name is a required field")
    .min(2, "Publisher name needs to be at least 2 characters"),
  administratorContactEmail: yup
    .string()
    .email("Administrator contact needs to be a valid email")
    .required("Administrator contact is a required field"),
  code: yup
    .string()
    .required("Publisher code is a required field")
    .min(2, "Publisher code needs to be at least 2 characters")
});

const NewVendorModal = ({ isOpen, close }: NewVendorModalProps) => {
  const history = useHistory();
  const [createVendorRequest] = useMutation("vendors", {
    client: manageClient
  });

  const [validateData] = useMutation(["vendors", "validate"], {
    client: manageClient
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      code: "",
      administratorContactEmail: ""
    },
    resolver: yupResolver(vendorRequestSchema)
  });

  const validateFieldUniquenessAPI = async (
    field: "code" | "name",
    value: string
  ) => {
    try {
      vendorRequestSchema.validateSyncAt(field, { [field]: value });
    } catch (err) {
      return;
    }

    const { errors } = await validateData({ [field]: value });

    const fieldHasBeenTaken = errors?.some(
      error =>
        error.title === "has already been taken" &&
        error.source.pointer.includes(field)
    );

    if (fieldHasBeenTaken) {
      setError(field, {
        type: "custom",
        message: `${startCase(field)} has already been taken`
      });
    }
  };

  const debounceValidateFieldUniquenessAPI = useCallback(
    debounce(validateFieldUniquenessAPI, 300),
    []
  );

  const onSubmit: SubmitHandler<NewVendorRequest> = useCallback(async data => {
    const normalizedVendorRequest: any = decamelizeKeys(data, {
      separator: "-"
    });

    const response = await createVendorRequest(normalizedVendorRequest);

    const { data: responseData } = response;

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error creating the publisher"
          details={[]}
        />
      );

      return;
    }

    toast(
      <Toast
        type="success"
        title="Publisher has been successfully created"
        details={[]}
      />
    );

    if (responseData) {
      history.push(`/publishers/${(responseData as StringMap)?.id}`);
    }

    handleClose();
  }, []);

  const handleClose = () => {
    close();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="newVendorModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="New Publisher"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        New Publisher
      </ModalHeader>
      <ModalBody>
        <div>
          <div className={styles["remove-margin"]}>
            <FormFieldLabel
              title="Publisher Name"
              tooltip={VENDOR_MODAL_NAME_INFO}
            />
          </div>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FormField
                id="Name"
                name={name}
                type="text"
                value={value}
                onChange={event => {
                  debounceValidateFieldUniquenessAPI(
                    "name",
                    event.value?.toString() || ""
                  );
                  onChange(event);
                }}
                data-testid="newPublisherModal_name"
                invalid={!!formErrors.name}
                feedback={formErrors?.name?.message}
                onBlur={onBlur}
              />
            )}
          />
          <FormFieldLabel
            title="Publisher Code"
            tooltip={VENDOR_MODAL_CODE_INFO}
          />
          <Controller
            name="code"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FormField
                id="Code"
                name={name}
                type="text"
                placeholder="NDAQ"
                value={value}
                onChange={event => {
                  debounceValidateFieldUniquenessAPI(
                    "code",
                    event.value?.toString() || ""
                  );
                  onChange(event);
                }}
                data-testid="newPublisherModal_code"
                invalid={!!formErrors.code}
                feedback={formErrors?.code?.message}
                onBlur={onBlur}
              />
            )}
          />
          <FormFieldLabel
            title="Administrator Contact"
            tooltip={VENDOR_MODAL_ADMIN_CONTACT_INFO}
          />
          <Controller
            name="administratorContactEmail"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FormField
                id="AdministratorContactEmail"
                name={name}
                type="email"
                placeholder=""
                value={value}
                onChange={onChange}
                data-testid="newPublisherModal_administratorContactEmail"
                invalid={!!formErrors.administratorContactEmail}
                feedback={formErrors?.administratorContactEmail?.message}
                onBlur={onBlur}
              />
            )}
          />
          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={
                !isDirty || !isValid || Object.keys(formErrors).length !== 0
              }
              data-testid="newPublisherModal_submit"
            >
              Submit
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="newPublisherModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewVendorModal;
