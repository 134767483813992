import React, { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { FormSelect } from "@nef/core";

import { BaseStepProps } from "../types";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { CapProductToCreate, CapProductToEdit } from "../../../api/types";
import buildCapProductSchema from "../schema";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import { useDirty } from "../cap-product-util";

const VISIBILITY_PREFERENCE_OPTIONS = [
  "Internal – Visible to my business unit only",
  "Internal – Visible to all of Nasdaq"
].map(value => {
  return {
    value,
    label: value
  };
});

const IntakeFormSection8Internal = ({
  fields,
  setFormIsDirty
}: BaseStepProps) => {
  const { product: contextProduct, saveAllData } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const productSchema = buildCapProductSchema({
    internalProduct: true
  });
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToEdit>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = form;
  useDirty(isDirty, setFormIsDirty);

  useEffect(() => {
    if (!product.capProductMetadatum.visibilityPreference) {
      setValue(
        "capProductMetadatum.visibilityPreference",
        VISIBILITY_PREFERENCE_OPTIONS[0].value
      );
    }
  }, []);

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<CapProductToEdit> = async formData => {
    await saveAllData({ capMetadata: formData.capProductMetadatum });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection5Step1">
          <CapSurveyFormFieldRow tooltip={TOOLTIP.VISIBILITY_PREFERENCE}>
            <Controller
              name="capProductMetadatum.visibilityPreference"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormSelect
                  id="VisibilityPreference"
                  name={name}
                  label="Visibility Preference"
                  value={VISIBILITY_PREFERENCE_OPTIONS.find(
                    o => o.value === value
                  )}
                  placeholder="Choose an option"
                  options={VISIBILITY_PREFERENCE_OPTIONS}
                  isClearable={false}
                  onChange={(formSelectOnChangeParam: any) => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  classNamePrefix="visibilityPreference"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormSection8Internal;
