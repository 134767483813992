import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FontAwesomeIcon
} from "@nef/core";
import Sortable from "sortablejs";
import styled from "styled-components";

import styles from "./PartitionSortButton.module.scss";

const StyledListItem = styled.div`
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: -1px;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 1rem;
  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

const StyledHint = styled.p`
  color: #0094bd;
`;

export function SortList({
  value,
  setValue
}: {
  value: string[];
  setValue: (_: string[]) => void;
}) {
  const listRef = useRef<HTMLDivElement>(null);

  const sortableRef = useRef<Sortable>();

  useEffect(() => {
    if (listRef.current) {
      sortableRef.current = new Sortable(listRef.current, {
        onSort: event => {
          const items: HTMLElement[] = Array.from(
            event.to.children
          ) as HTMLElement[];
          const result: string[] = [];
          for (let i = 0; i < items.length; i++) {
            result.push(items[i].dataset.key || "");
          }
          setValue(result);
        },
        ghostClass: styles.ghost
      });
    }
    return () => {
      sortableRef.current?.destroy();
    };
  }, []);
  return (
    <div ref={listRef} data-testid="partitionSortModal_list">
      {value.map(name => (
        <StyledListItem
          key={name}
          data-key={name}
          data-testid="partitionSortModal_listItem"
        >
          <FontAwesomeIcon iconClassName="fa-grip-lines" />
          <span data-testid="partitionSortModal_listItemTitle">
            {name || "Unnamed column"}
          </span>
        </StyledListItem>
      ))}
    </div>
  );
}

function PartitionSortButton({
  value,
  setValue,
  disabled = false
}: {
  value: string[];
  setValue?: (_: string[]) => void;
  disabled?: boolean;
}) {
  const [isOpen, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onDismiss = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    // filter out the unexisted columns
    const result = internalValue.filter(v => value.includes(v));
    // keep the original order, push the new columns at the end
    value.forEach(v => {
      if (!result.includes(v)) {
        result.push(v);
      }
    });
    setInternalValue(result);
  }, [value]);

  const onConfirm = useCallback(() => {
    setValue?.(internalValue);
    onDismiss();
  }, [onDismiss, internalValue]);

  if (internalValue.length <= 1) return null;

  return (
    <>
      <button
        className={styles.button}
        type="button"
        onClick={onOpen}
        data-testid="partitionSortTrigger"
      >
        order
      </button>
      <Modal isOpen={isOpen} toggle={onDismiss}>
        <ModalHeader toggle={onDismiss} data-testid="partitionSortModal_title">
          Partitions Order
        </ModalHeader>
        <ModalBody>
          <SortList value={value} setValue={setInternalValue} />
          <StyledHint>Drag and drop to rearrange items</StyledHint>
        </ModalBody>
        <ModalFooter>
          <Button
            size="md"
            className="danger"
            color="light"
            onClick={onDismiss}
            data-testid="partitionSortModal_dismiss"
          >
            Cancel
          </Button>

          {!disabled && (
            <Button
              size="md"
              onClick={onConfirm}
              data-testid="partitionSortModal_confirm"
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PartitionSortButton;
