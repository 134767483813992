import { User } from "../api/types";

import SessionStorageBuilder from "./SessionStorageBuilder";
import LocalStorageBuilder from "./LocalStorageBuilder";

const userStorage = SessionStorageBuilder("user");
const userLocalStorage = LocalStorageBuilder("user");

const UserService = {
  saveUser: (user: User | null) => {
    userStorage.set(user);
  },
  getUser: (): User | null => {
    return userStorage.get();
  },
  removeUser: () => {
    userStorage.set(null);
    userLocalStorage.set(null);
  }
};

export default UserService;
