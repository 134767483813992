import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { Loader } from "@nef/icons";

import useInfiniteLoading from "../../hooks/useInfiniteLoading";

import styles from "./InfiniteScroll.module.scss";

interface InfiniteScrollProps<T> {
  renderItem: (data: T, index: number) => JSX.Element;
  renderEmptyList: string | JSX.Element;
  fetchData: (
    cursor: string | null
  ) => Promise<{ data: T[]; cursor: string | null }>;
  queries?: string[];
  testid?: string;
}

const InfiniteScroll = <T extends unknown>({
  fetchData,
  renderItem,
  renderEmptyList,
  queries,
  testid
}: InfiniteScrollProps<T>) => {
  const { data, initialized, hasMoreData, fetchMoreData, cursor, isLoading } =
    useInfiniteLoading<T>({
      fetchData,
      queries
    });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { ref } = useInView({
    root: wrapperRef.current,
    // "50%" for bottom rootMargin allow pre-loading data before reaching the end of list, and makes the scrolling more seamless
    rootMargin: "0px 0px 50% 0px",
    onChange: inView => {
      if (inView) {
        fetchMoreData();
      }
    }
  });

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
      data-testid="infiniteScroll_wrapper"
    >
      {initialized &&
        (data.length ? (
          <div data-testid={testid}>
            {data.map((datum, index) => renderItem(datum, index))}
          </div>
        ) : (
          renderEmptyList
        ))}

      {isLoading && (
        <div className={styles["loading-indicator"]}>
          <Loader size="lg" color="primary" intensity={300} />
          <div data-testid="infiniteScroll_loadingMessage">Loading...</div>
        </div>
      )}

      {hasMoreData ? (
        <div
          className={styles.loader}
          ref={ref}
          key={cursor}
          data-testid="infiniteScroll_loader"
        />
      ) : (
        initialized && <div data-testid="infiniteScroll_endOfList" />
      )}
    </div>
  );
};

export default InfiniteScroll;
