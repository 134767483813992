import React, { useCallback, useState } from "react";
import { CircleButton, FontAwesomeIcon } from "@nef/core";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { UserSortParam } from "../../api/types";
import useQueryParam from "../../hooks/useQueryParam";

import DeleteUserModal from "./delete-user-modal";
import styles from "./users-table.module.scss";

export interface User {
  id: string;
  userId: string;
  userEmail: string;
  userName: string;
  status: string;
  lastSignInAt: string;
  userInviteEmail?: string;
  userInviteAcceptedAt: string;
  createdAt: string;
}

const columnHelper = createColumnHelper<User>();

interface UsersListHeaderProps {
  users?: User[];
  sortParam: string;
  setSortParam: (newSort: UserSortParam) => void;
}

const UsersTable = ({
  users,
  sortParam,
  setSortParam
}: UsersListHeaderProps) => {
  const ascending = !sortParam.startsWith("-");
  const sortBy = ascending ? sortParam : sortParam.substring(1);
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";

  const queryParams = useQueryParam();
  const vendorId = queryParams?.get("vendorId") ?? "";

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<null | User>(null);

  const handleDelete = (info: User) => {
    setSelectedUser(info);
    setIsDeleteModalOpen(true);
  };

  const updateSortParam = useCallback(
    (newSortBy: string) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as UserSortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const columns = [
    columnHelper.accessor("userName", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          <Link className={styles.values} to={`/users/${info.row.original.id}`}>
            {info.getValue() || <em>Invite sent</em>}
          </Link>
        </div>
      ),
      header: () => {
        return (
          <button
            className={sortBy === "name" ? styles["sorter-active"] : ""}
            type="button"
            onClick={() => updateSortParam("name")}
            tabIndex={-1}
          >
            Name
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </button>
        );
      }
    }),
    columnHelper.accessor("userEmail", {
      id: "email",
      cell: info => info.getValue() || info.row.original.userInviteEmail,
      header: () => {
        return (
          <div
            className={sortBy === "email" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("email")}
            onKeyPress={() => updateSortParam("email")}
            tabIndex={-1}
          >
            Email
            {sortBy === "email" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("lastSignInAt", {
      id: "lastSignInAt",
      cell: info =>
        info.row.original.lastSignInAt
          ? new Intl.DateTimeFormat("en-US").format(
              new Date(info.row.original.lastSignInAt)
            )
          : "-",
      header: () => (
        <div
          className={sortBy === "last_login" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("last_login")}
          onKeyPress={() => updateSortParam("last_login")}
          tabIndex={-1}
        >
          Last Login
          {sortBy === "last_login" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("userInviteAcceptedAt", {
      id: "userInviteAcceptedAt",
      cell: info => {
        const { userId, createdAt, userInviteAcceptedAt } = info.row.original;

        if (userId) {
          return new Intl.DateTimeFormat("en-US").format(new Date(createdAt));
        }

        return userInviteAcceptedAt
          ? new Intl.DateTimeFormat("en-US").format(
              new Date(userInviteAcceptedAt)
            )
          : "-";
      },
      header: () => {
        return (
          <div
            className={sortBy === "date_joined" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("date_joined")}
            onKeyPress={() => updateSortParam("date_joined")}
            tabIndex={-1}
          >
            Date Joined
            {sortBy === "date_joined" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("id", {
      id: "viewVendor",
      cell: info => (
        <div className={styles["right-align"]}>
          <Link
            to={`/users/${info.getValue()}?from=users`}
            data-testid="edit-button"
          >
            <CircleButton outline className={styles["table-delete-button"]}>
              <FontAwesomeIcon iconClassName="fa-edit" />
            </CircleButton>
          </Link>
          <CircleButton
            outline
            color="danger"
            className={styles["table-delete-button"]}
            onClick={() => handleDelete(info.row.original)}
            data-testid="delete-button"
          >
            <FontAwesomeIcon iconClassName="fa-trash" />
          </CircleButton>
        </div>
      ),
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: users ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <section>
      {isDeleteModalOpen && (
        <DeleteUserModal
          redirect={false}
          isOpen={isDeleteModalOpen}
          close={() => setIsDeleteModalOpen(false)}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          vendorId={vendorId}
        />
      )}
      <table data-testid="users-table-section">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={`${
                    header.column.getIsSorted()
                      ? `${styles.selected} ${styles.label}`
                      : styles.label
                  } ${header.column.id === "name" ? styles["left-align"] : ""}`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-testid="users-table-body">
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} data-testid={`users-table-row-${row.id}`}>
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className={
                    cell.column.id === "name"
                      ? `${styles.values} ${styles["left-align"]}`
                      : styles.values
                  }
                  data-testid={`users-table-${cell.column.id}-${row.id}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {table.getRowModel().rows.length === 0 && (
        <div style={{ borderTop: "1px black solid", padding: "40px 0" }}>
          There are no users.
        </div>
      )}
    </section>
  );
};

export default UsersTable;
