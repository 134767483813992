import { parse, parseISO, isDate } from "date-fns";
import * as yup from "yup";

import { getIndexIdentifier } from "../../../utils/react-hook-form-utils";
import emptyStringToUndefined from "../../../utils/yup-utils";

const parseDateString = (value: string, originalValue: string) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());
  return parsedDate;
};

const parseDateTimeString = (value: string, originalValue: string) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parseISO(originalValue);
  return parsedDate;
};
const createFilterSchemaByType = (columnType: string) => {
  const operatorValidation = yup
    .string()
    .oneOf(["eq", "nin", "in", "gte", "lte", "lt", "gt"])
    .required();

  let valueElementSchema: yup.BaseSchema;
  switch (columnType) {
    case "number":
      valueElementSchema = yup.number().required();
      break;
    case "date":
      valueElementSchema = yup.date().transform(parseDateString).required();
      break;
    case "datetime":
      valueElementSchema = yup.date().transform(parseDateTimeString).required();
      break;
    default:
      valueElementSchema = yup.string().required();
  }

  const valueValidation = yup.mixed().when("operator", operator => {
    let schema;

    switch (operator) {
      case "nin":
      case "in":
        schema = yup
          .array()
          .transform((value: any, originalValue: string) => {
            return originalValue
              ? originalValue.split(",").map(str => str.trim())
              : [];
          })
          .test(
            "valid-comma-delimited-string",
            "Invalid comma delimited string",
            (values, { createError, originalValue, path }: any) => {
              const transformedValues: string[] = originalValue
                ? originalValue.split(",").map((str: string) => str.trim())
                : [];

              if (!transformedValues?.length) {
                const columnIdentifer = getIndexIdentifier(path);
                return createError({
                  message: `Filter ${columnIdentifer} is missing values`
                });
              }

              const result = (transformedValues || []).every(value => {
                return valueElementSchema.isValidSync(value);
              });

              return (
                result ||
                createError({
                  message: `\${originalValue} must be a valid ${columnType} list`,
                  params: {
                    originalValue
                  }
                })
              );
            }
          )
          .of(valueElementSchema);
        break;
      default:
        schema = valueElementSchema
          .transform(emptyStringToUndefined)
          .typeError(change => {
            const columnIdentifer = getIndexIdentifier(change.path);
            return `Filter ${columnIdentifer} must be a valid ${columnType}`;
          })
          .required(change => {
            const columnIdentifer = getIndexIdentifier(change.path);
            return `Filter ${columnIdentifer} is missing a value`;
          });
    }

    return schema;
  });

  const result = yup.array().of(
    yup.object({
      operator: operatorValidation,
      value: valueValidation
    })
  );

  return result;
};

const columnFiltersSchema = yup.object({
  defaultFilters: yup
    .mixed()
    .when("baseType", {
      is: (type: string) => ["integer", "decimal", "double"].includes(type),
      then: createFilterSchemaByType("number")
    })
    .when("baseType", {
      is: (type: string) => ["date"].includes(type),
      then: createFilterSchemaByType("date")
    })
    .when("baseType", {
      is: (type: string) => ["datetime"].includes(type),
      then: createFilterSchemaByType("datetime")
    })
    .when("baseType", {
      is: (type: string) => ["string"].includes(type),
      then: createFilterSchemaByType("string")
    })
});

export default columnFiltersSchema;
