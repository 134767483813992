import { Validation } from "./types";

type TableNameObject = {
  tableName: string;
};

const TableNameValidation: Validation<TableNameObject> = {
  extractData: headers => {
    const tableName = headers[1][1];

    return {
      tableName
    };
  },
  validate: (data, context) => {
    const { tableName } = data;

    if (!tableName) {
      return [
        {
          type: "table-name-missing",
          title: "Table Name not provided",
          message: "Please fill out Table Name"
        }
      ];
    }

    if (tableName !== context.tableName) {
      return [
        {
          type: "table-name-mismatch",
          title:
            "Table Name in the schema does not match the table name in the form",
          message: "Please ensure the correct table schema file is uploaded"
        }
      ];
    }

    return [];
  }
};

export default TableNameValidation;
