import React from "react";
import { Loader } from "@nef/icons";

import styles from "./LoadingIndicator.module.scss";

interface LoadingIndicatorProps {
  message?: string;
}

const LoadingIndicator = ({ message }: LoadingIndicatorProps) => {
  return (
    <div className={styles["loading-indicator"]} data-testid="loadingIndicator">
      <Loader size="lg" color="primary" intensity={300} />
      <div data-testid="loadingIndicator_message">{message}</div>
    </div>
  );
};

export default LoadingIndicator;
