import React from "react";
import { Route } from "react-router-dom";

import useWizard from "../../hooks/useWizard";

const WizardRoutes = () => {
  const { steps } = useWizard();

  return (
    <>
      {steps.map(step => {
        return <Route key={step.path} path={step.path} />;
      })}
    </>
  );
};

export default WizardRoutes;
