import { Validation } from "./types";

import { COLUMN_FIELD_INDEX } from ".";

const isChecked = (value: string) => {
  return ["X", "x"].includes(value);
};
type PrimaryKeysObject = {
  primaryKeys: number[];
};

const PrimaryKeysValidation: Validation<PrimaryKeysObject> = {
  extractData: (_, rows) => {
    const primaryKeys = rows
      .map((column, index) =>
        isChecked(column[COLUMN_FIELD_INDEX.FILTER]) ? index : -1
      )
      .filter(value => value !== -1);

    return {
      primaryKeys
    };
  },
  validate: (data, context) => {
    if (!context.requiresPrimaryKeys) {
      return [];
    }

    const { primaryKeys } = data;

    if (primaryKeys.length > 0) {
      return [];
    }

    return [
      {
        type: "primary-key-missing",
        title: "Primary key(s) not specified",
        message:
          "Apply to column D. At least one primary key should be specified if the primary key checkbox is selected in the intake form."
      }
    ];
  }
};

export default PrimaryKeysValidation;
