import React, { useCallback, useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useQuery } from "jsonapi-react";
import { Link, generatePath } from "react-router-dom";

import { DatatableCollection } from "../../api/types";
import useDefaultAuth from "../../hooks/useDefaultAuth";
import NewProductModal from "../../components/new-product/NewProductModal";
import { PATHS } from "../../routes";

import styles from "./products-table.module.scss";

interface ProductTableProps {
  vendorId: string;
  hasEditAccess?: boolean;
  hasManagerAccess?: boolean;
  hasLicences?: boolean;
}

const SORT_PARAM_TO_REQUEST_PARAM = {
  name: "name",
  "-name": "-name",
  created_at: "created_at",
  "-created_at": "-created_at",
  last_update: "updated_at",
  "-last_update": "-updated_at",
  admin: "admin",
  "-admin": "-admin",
  code: "code",
  "-code": "-code"
};

type SortParam = keyof typeof SORT_PARAM_TO_REQUEST_PARAM;

const generateRequestBody = (vendorId: string, sortParam: SortParam) => {
  const requestBody: Record<string, string> = {};
  requestBody["filter[vendor_id]"] = vendorId;

  if (sortParam.trim() !== "") {
    requestBody.sort = SORT_PARAM_TO_REQUEST_PARAM[sortParam];
  }

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const columnHelper = createColumnHelper<DatatableCollection>();

const ProductsTable = ({
  vendorId,
  hasEditAccess,
  hasManagerAccess,
  hasLicences
}: ProductTableProps) => {
  const { authenticatedUser, isProductStatusEditor, isProductStatusIntake } =
    useDefaultAuth();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortParam, setSortParam] = useState<SortParam>("name");
  const [isNewProdModalOpen, setNewProdModalOpen] = useState(false);

  const ascending = !sortParam.startsWith("-");
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";
  const sortBy = ascending ? sortParam : sortParam.substring(1);

  const updateSortParam = useCallback(
    (newSortBy: SortParam) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as SortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const { data: productData } = useQuery<DatatableCollection[]>([
    "datatable-collections",
    generateRequestBody(vendorId, sortParam)
  ]);

  const productBuilderAccess = (id: string) => {
    const data = productData?.find(dataItem => dataItem.id === id);

    return isProductStatusEditor(
      data?.productApprovalStatus,
      data?.productApprovalType
    );
  };

  const intakeAccess = (id: string) => {
    const data = productData?.find(dataItem => dataItem.id === id);

    return isProductStatusIntake(data?.productApprovalStatus);
  };

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          {hasEditAccess ||
          authenticatedUser?.manageableDatatableCollectionIds.includes(
            parseInt(info.row.original.id, 10)
          ) ? (
            <>
              {productBuilderAccess(info.row.original.id) && (
                <Link
                  className={styles["product-name"]}
                  to={generatePath(PATHS.EDIT_PRODUCT, {
                    productId: info.row.original.id
                  })}
                >
                  {info.getValue()}
                </Link>
              )}
              {intakeAccess(info.row.original.id) && (
                <Link
                  className={styles["product-name"]}
                  to={generatePath(PATHS.EDIT_INTAKE, {
                    productId: info.row.original.id
                  })}
                >
                  {info.getValue()}
                </Link>
              )}
            </>
          ) : (
            <span>{info.getValue()}</span>
          )}
        </div>
      ),
      header: () => {
        return (
          <div className={sortBy === "name" ? styles["sorter-active"] : ""}>
            <button
              type="button"
              onClick={() => updateSortParam("name")}
              onKeyPress={() => updateSortParam("name")}
              tabIndex={-1}
            >
              Name
            </button>
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("code", {
      id: "code",
      cell: info => info.getValue(),
      header: () => {
        return (
          <div className={sortBy === "code" ? styles["sorter-active"] : ""}>
            <button
              type="button"
              className={styles.columnHeader}
              onClick={() => updateSortParam("code")}
              onKeyPress={() => updateSortParam("code")}
              tabIndex={-1}
            >
              Code
            </button>
            {sortBy === "code" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("createdAt", {
      id: "createdOn",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(new Date(info.getValue())),
      header: () => {
        return (
          <div
            className={sortBy === "created_at" ? styles["sorter-active"] : ""}
          >
            <button
              type="button"
              className={styles.columnHeader}
              onClick={() => updateSortParam("created_at")}
              onKeyPress={() => updateSortParam("created_at")}
              tabIndex={-1}
            >
              Created On
            </button>
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("updatedAt", {
      id: "lastUpdate",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(new Date(info.getValue())),
      header: () => {
        return (
          <div
            className={sortBy === "last_update" ? styles["sorter-active"] : ""}
          >
            <button
              type="button"
              onClick={() => updateSortParam("last_update")}
              onKeyPress={() => updateSortParam("last_update")}
              tabIndex={-1}
            >
              Last Update
            </button>
            {sortBy === "last_update" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("administratorContactVendorUserName", {
      id: "admin",
      cell: info => {
        const {
          administratorContactVendorUserId,
          administratorContactVendorUserName,
          administratorContactVendorUserInviteEmail
        } = info.row.original;

        const adminText =
          administratorContactVendorUserName ||
          administratorContactVendorUserInviteEmail;

        if (administratorContactVendorUserId) {
          return hasEditAccess ? (
            <Link
              className={styles["product-name"]}
              to={generatePath(PATHS.EDIT_USER, {
                userId: administratorContactVendorUserId
              })}
            >
              {adminText}
            </Link>
          ) : (
            <span>{adminText}</span>
          );
        }

        return null;
      },
      header: () => {
        return (
          <div className={sortBy === "admin" ? styles["sorter-active"] : ""}>
            <button
              type="button"
              onClick={() => updateSortParam("admin")}
              onKeyPress={() => updateSortParam("admin")}
              tabIndex={-1}
            >
              Admin
            </button>
            {sortBy === "admin" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("id", {
      id: "id",
      cell: info =>
        hasManagerAccess ||
        hasEditAccess ||
        authenticatedUser?.manageableDatatableCollectionIds.includes(
          parseInt(info.row.original.id, 10)
        ) ? (
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            {intakeAccess(info.getValue()) && (
              <Link
                className={`${styles["product-link"]} ${
                  !intakeAccess(info.getValue()) ? styles["disable-link"] : ""
                }`}
                to={generatePath(PATHS.EDIT_INTAKE, {
                  productId: info.getValue()
                })}
              >
                <Button
                  className={styles["view-product-button"]}
                  color="light"
                  size="sm"
                  outline
                >
                  View Intake
                </Button>
              </Link>
            )}
            {productBuilderAccess(info.getValue()) && (
              <Link
                className={`${styles["product-link"]} ${
                  !productBuilderAccess(info.getValue())
                    ? styles["disable-link"]
                    : ""
                }`}
                to={generatePath(PATHS.EDIT_PRODUCT, {
                  productId: info.getValue()
                })}
              >
                <Button
                  className={styles["view-product-button"]}
                  color="light"
                  size="sm"
                  outline
                >
                  View Product Builder
                </Button>
              </Link>
            )}
          </div>
        ) : null,
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: productData || [],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={styles.container} data-testid="productsTable">
      {hasManagerAccess && (
        <div className={styles.header}>
          <span>Products</span>
          <Button
            color="light"
            size="sm"
            outline
            onClick={() => setNewProdModalOpen(true)}
            disabled={!hasManagerAccess}
          >
            <FontAwesomeIcon iconClassName="fa-plus-circle" />
            Add Product
          </Button>
          {isNewProdModalOpen && (
            <NewProductModal
              isOpen={isNewProdModalOpen}
              hasLicences={hasLicences}
              vendorId={vendorId}
              close={() => setNewProdModalOpen(false)}
            />
          )}
        </div>
      )}

      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsTable;
