import {
  Option,
  Action
} from "@nef/core/lib/components/components/FormBaseSelect";

interface ActionMeta {
  action: Action;
  name: string;
}

interface FormSelectOnChangeParam {
  value: Option;
  action: ActionMeta | "clear";
}

const extractFormSelectOnChangeValue = (formSelectOnChangeParam: any) => {
  const { value, action } = formSelectOnChangeParam as FormSelectOnChangeParam;

  const newValue = action === "clear" ? "" : value.value;
  return newValue;
};

export default extractFormSelectOnChangeValue;
