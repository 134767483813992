import React from "react";
import { FieldArrayWithId } from "react-hook-form";

import filterIcon from "../../../styles/svgs/filter.svg";
import { FilterableColumnValues, DatatableSchema } from "../../../api/types";

import FilterColumnButtom from "./FilterColumnButton";
import styles from "./DatatableColumnFilters.module.scss";

interface DatatableColumnFiltersProps {
  setFilter: (filterName: FilterableColumnValues) => void;
  currentFilter: FilterableColumnValues;
  fields: FieldArrayWithId<DatatableSchema, "columns", "id">[];
}

const DatatatableColumnFilters = ({
  setFilter,
  currentFilter,
  fields
}: DatatableColumnFiltersProps) => {
  const numIndex = fields.filter(f => f.isIndex).length;
  const numPrimaryKey = fields.filter(f => f.isPrimaryKey).length;

  return (
    <div className={styles.container}>
      <img alt="Filter columns" src={filterIcon} />
      <FilterColumnButtom
        name="Index"
        amount={numIndex}
        field="isIndex"
        setFilter={setFilter}
        currentFilter={currentFilter}
      />
      <FilterColumnButtom
        name="Primary Key"
        amount={numPrimaryKey}
        field="isPrimaryKey"
        setFilter={setFilter}
        currentFilter={currentFilter}
      />
    </div>
  );
};

export default DatatatableColumnFilters;
