import React, { useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import UserGroupsModal from "../../components/modals/user-groups/UserGroupsModal";
import MoreInfoTooltip from "../../components/MoreInfoTooltip";

import styles from "./UserGroupsTable.module.scss";

interface UserData {
  name: string;
  id: string;
}

interface UserGroupsTableProps {
  data: UserData[];
  onUpdate: (data: UserData[]) => void;
  vendorId: string;
}

const columnHelper = createColumnHelper<UserData>();

const UserGroupsTable = ({
  data: userGroupData,
  onUpdate,
  vendorId
}: UserGroupsTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false }
  ]);
  const [isUserGroupsModalOpen, setUserGroupsModalOpen] = useState(false);

  const updateGroupAccess = (newGroupAccess: any) => {
    onUpdate(newGroupAccess);
  };

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => (
        <div className={styles["name-column"]}>{info.getValue()}</div>
      ),
      header: header => {
        return (
          <button
            type="button"
            onClick={header.column.getToggleSortingHandler()}
            tabIndex={-1}
          >
            Name
          </button>
        );
      }
    })
  ];

  const table = useReactTable({
    data: userGroupData || [],
    columns,
    state: {
      sorting
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    getCoreRowModel: getCoreRowModel()
  });

  const handleAddProductClicked = () => {
    setUserGroupsModalOpen(true);
  };

  return (
    <div className={styles.container} data-testid="userGroupsTable">
      <div className={styles.header}>
        <div className={styles["user-groups-container"]}>
          <p className={styles["user-groups-span"]}>User Groups</p>
          <div className={styles["tooltip-container"]}>
            <MoreInfoTooltip
              description="Assign or remove this user to different user groups. User groups contain sets of permissions for a publisher."
              icon="info"
            />
          </div>
        </div>
        <Button
          size="sm"
          outline
          onClick={handleAddProductClicked}
          data-testid="userGroupsTable_manageButton"
        >
          <FontAwesomeIcon iconClassName="fa-plus-circle" />
          Manage
        </Button>
      </div>

      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UserGroupsModal
        isOpen={isUserGroupsModalOpen}
        close={() => setUserGroupsModalOpen(false)}
        vendorId={vendorId}
        selected={userGroupData.map(group => group.id)}
        onUpdate={updateGroupAccess}
      />
    </div>
  );
};

export default UserGroupsTable;
