import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FormProvider,
  Controller,
  useWatch
} from "react-hook-form";
import { toast } from "react-toastify";
import { FormSelect } from "@nef/core";

import { CapProductToEdit, CapProductToCreate } from "../../../api/types";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import buildCapProductSchema from "../schema";
import TOOLTIP from "../tooltips";
import { BaseStepProps } from "../types";
import Toast from "../../Toast";
import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import DatatableInput from "../components/DatatableInput";
import { useDirty } from "../cap-product-util";
import { QueueProvider } from "../../../contexts/queue";

const DATA_HOSTING_DROPDOWN = ["Yes", "No"].map(value => ({
  value,
  label: value
}));

const IntakeFormStep3 = ({ fields, setFormIsDirty }: BaseStepProps) => {
  const { product: contextProduct, saveAllData } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const productSchema = buildCapProductSchema();
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToEdit>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful, isDirty }
  } = form;
  useDirty(isDirty, setFormIsDirty);

  const datatablesNameDescriptionsValue = useWatch({
    name: "capProductMetadatum.datatablesNameDescriptions",
    exact: true,
    control
  });

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)();
  };

  const onHandleSubmit: SubmitHandler<CapProductToEdit> = async formData => {
    await saveAllData({ capMetadata: formData.capProductMetadatum });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );

    throw errors;
  };

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection1Step3">
          <CapSurveyFormFieldRow tooltip={TOOLTIP.DATA_HOSTING_TAGS}>
            <Controller
              name="capProductMetadatum.dataHosting"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="dataHosting"
                    name={name}
                    label="Data Hosting"
                    value={
                      value === null
                        ? ""
                        : DATA_HOSTING_DROPDOWN.find(
                            o => o.value === (value === true ? "Yes" : "No")
                          )
                    }
                    placeholder="Data Hosting"
                    options={DATA_HOSTING_DROPDOWN}
                    optional={false}
                    isClearable={false}
                    onChange={(formSelectOnChangeParam: any) => {
                      const changeValue =
                        extractFormSelectOnChangeValue(
                          formSelectOnChangeParam
                        ) === "Yes";
                      onChange(changeValue);
                    }}
                    classNamePrefix="dataHosting"
                    disabled={datatablesNameDescriptionsValue?.length !== 0}
                  />
                );
              }}
            />
          </CapSurveyFormFieldRow>
          <QueueProvider
            queues={[
              "delete-schema-files-save",
              "delete-sample-files-save",
              "delete-schema-files-discard",
              "delete-sample-files-discard"
            ]}
          >
            <DatatableInput />
          </QueueProvider>
          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormStep3;
