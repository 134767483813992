import React from "react";

import { DatatableSchema } from "../../api/types";
import styles from "../StatusBadge.module.scss";

interface DatatableSchemaUploadStatusBadgeProps {
  datatable: DatatableSchema;
}

const DatatableSchemaUploadStatusBadge = ({
  datatable
}: DatatableSchemaUploadStatusBadgeProps) => {
  if (datatable.version.code !== "FINAL") return null;
  if (datatable.source?.cronTime) return null;

  return (
    <div className={styles.badge} data-testid="datatableUploadStatusBadge">
      Disabled
    </div>
  );
};

export default DatatableSchemaUploadStatusBadge;
