import React, { useState } from "react";
import { Button } from "@nef/core";

import { ConfirmationModal } from "../../../modals/ConfirmationModal";

interface PlanFormDeleteButtonProps {
  deletePlan: () => void;
}

const PlanFormDeleteButton = ({ deletePlan }: PlanFormDeleteButtonProps) => {
  const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false);

  const onConfirm = () => {
    setShouldConfirmDelete(false);
    deletePlan();
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setShouldConfirmDelete(true)}
        data-testid="planForm_delete"
      >
        Delete Plan
      </Button>
      <ConfirmationModal
        isOpen={shouldConfirmDelete}
        question="Are you sure you want to delete this pricing plan?"
        onConfirm={() => onConfirm()}
        onDismiss={() => setShouldConfirmDelete(false)}
      />
    </>
  );
};

export default PlanFormDeleteButton;
