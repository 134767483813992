import { useCallback, useEffect, useState } from "react";

interface useInfiniteLoadingProps<T> {
  fetchData: (
    cursor: string | null
  ) => Promise<{ data: T[]; cursor: string | null }>;
  queries?: string[];
}

interface DataState<T> {
  initialized: boolean;
  data: T[];
  cursor: string | null;
}

const useInfiniteLoading = <T extends unknown>(
  props: useInfiniteLoadingProps<T>
) => {
  const { fetchData, queries = [] } = props;

  const [dataState, setDataState] = useState<DataState<T>>({
    initialized: false,
    data: [],
    cursor: null
  });
  const [isLoading, setLoading] = useState(false);

  const { data, initialized, cursor } = dataState;

  useEffect(() => {
    // Query changes (eg. search query) should revert this hook to uninitialized state
    setDataState({
      initialized: false,
      data: [],
      cursor: null
    });
  }, [...queries]);

  useEffect(() => {
    // Auto-triggers data loading when in uninitialized state. Subsequent data loading
    // should be triggered externally (eg. scroll to end-of-list)
    if (!initialized) {
      fetchMoreData();
    }
  }, [initialized]);

  const fetchMoreData = useCallback(async () => {
    if (!cursor && initialized) return;

    setLoading(true);

    const { data: newData, cursor: newCursor } = await fetchData(cursor);

    if (!newData) {
      setLoading(false);
      return;
    }

    setDataState(prevState => {
      return {
        initialized: true,
        data: [...prevState.data, ...newData],
        cursor: newCursor
      };
    });

    setLoading(false);
  }, [cursor, initialized]);

  return {
    data,
    initialized,
    fetchMoreData,
    hasMoreData: initialized && !!cursor,
    cursor,
    isLoading
  };
};

export default useInfiniteLoading;
