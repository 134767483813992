import React, { useCallback, useContext } from "react";
import * as yup from "yup";
import { Box, FlexGrid, Header } from "@nef/core";
import { toast } from "react-toastify";
import { generatePath, useHistory } from "react-router-dom";
import { IResult, useMutation } from "jsonapi-react";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { decamelizeKeys } from "humps";

import { DatatableSchema, DatatableFileFormat } from "../../api/types";
import DatatableContext from "../../contexts/datatable";
import { Step } from "../wizard";
import { extractErrors } from "../../utils/react-hook-form-utils";
import Toast from "../Toast";
import globalStyles from "../../styles/common.module.scss";
import { PATHS } from "../../routes";

import FileFormatForm from "./FileFormatForm";
import DataUploader from "./DataUploader";

const DEFAULT_FILE_FORMAT: DatatableFileFormat = {
  delimiter: ",",
  skipRows: 0,
  skipBottomRows: 0,
  quotedWith: '"',
  encoding: "latin-1"
};

const FileInfoStep = () => {
  const { datatable, setDatatable } = useContext(DatatableContext);
  const { fileFormat } = datatable;

  const [editDatatable] = useMutation<DatatableSchema>(
    [
      "datatable_schema",
      `${datatable.vendorCode}/${datatable.code}/${datatable.version.code}`
    ],
    { method: "PUT" }
  );

  const history = useHistory();
  const fileFormatSchema = yup.object({
    delimiter: yup
      .string()
      .oneOf(
        [",", "|", ";"],
        'delimiter must be one of the following values: ",", "|", ";"'
      )
      .required(),
    skipRows: yup.number().min(0).required(),
    skipBottomRows: yup.number().min(0).required(),
    encoding: yup.string().oneOf(["latin-1", "utf"]).required()
  });

  const methods = useForm({
    defaultValues: fileFormat || { ...DEFAULT_FILE_FORMAT },
    resolver: yupResolver(fileFormatSchema)
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods;

  const onSaveFileFormat: SubmitHandler<DatatableFileFormat> = useCallback(
    async newDatatableFileFormat => {
      if (fileFormat && !isDirty) {
        return true;
      }

      const requestBody = {
        file_format: decamelizeKeys(newDatatableFileFormat, {
          separator: "_"
        })
      };

      const response = await editDatatable(requestBody);
      return handleFileFormatResponse(response);
    },
    [fileFormat, isDirty]
  );

  const handleFileFormatResponse = (response: IResult<DatatableSchema>) => {
    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return true;

      const formattedErrors = responseErrors
        .map(e => e.detail)
        .flat()
        .map(message => {
          return { message };
        });

      toast(
        <Toast
          type="error"
          title="Cannot save file format"
          details={formattedErrors}
        />
      );

      return false;
    }

    toast(
      <Toast
        type="info"
        title="Progress is saved"
        details={[{ message: "You may go back to edit file format info" }]}
      />
    );

    const responseData = response.data as DatatableSchema;
    const updatedFileFormat = responseData?.fileFormat;

    setDatatable({
      ...datatable,
      fileFormat: updatedFileFormat
    });
    reset(updatedFileFormat);

    return true;
  };

  const onSaveFileFormatError: SubmitErrorHandler<
    DatatableFileFormat
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );

    return false;
  };

  const handleSubmitFileFormat: (redirect: boolean) => Promise<boolean> =
    useCallback(
      (redirect = true) => {
        return new Promise(resolve => {
          handleSubmit(
            async data => {
              const success = await onSaveFileFormat(data);

              if (success && redirect) {
                history.push(
                  generatePath(`${PATHS.EDIT_DATATABLE}/dataLocation`, {
                    vendorCode: datatable.vendorCode,
                    datatableCode: datatable.code,
                    versionCode: datatable.version.code
                  })
                );
              }

              resolve(success);
            },
            errors => {
              onSaveFileFormatError(errors);
              resolve(false);
            }
          )();
        });
      },
      [onSaveFileFormat]
    );

  return (
    <Step
      name="File Specifications"
      path="fileInfo"
      canAdvance={true}
      save={() => handleSubmitFileFormat(true)}
    >
      <FlexGrid fluid={true} data-testid="datatableFileInfoStep">
        <FlexGrid.Row>
          <FlexGrid.Column xs={24} md={12}>
            <Box paddingBottom={4}>
              <Header size={1}>File Specifications</Header>
              <div className={globalStyles.subheader}>
                This section allows you to define the properties of the data
                file. This includes the file format and the properties of the
                data. You can also upload a test file to make sure the data
                schema was defined correctly. You can review the upload logs for
                any errors and successes.
              </div>
            </Box>
          </FlexGrid.Column>
        </FlexGrid.Row>
      </FlexGrid>

      <FormProvider {...methods}>
        <FileFormatForm />
      </FormProvider>

      <FlexGrid fluid={true}>
        <FlexGrid.Row>
          <FlexGrid.Column xs={24}>
            <DataUploader saveFileFormat={handleSubmitFileFormat} />
          </FlexGrid.Column>
        </FlexGrid.Row>
      </FlexGrid>
    </Step>
  );
};

export default FileInfoStep;
