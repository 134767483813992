import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect, FormField } from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { toast } from "react-toastify";

import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import buildCapProductSchema from "../schema";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { CapProductToCreate } from "../../../api/types";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import { BaseStepProps } from "../types";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import Toast from "../../Toast";
import { useDirty } from "../cap-product-util";

const INFOSEC_CLASS_OPTIONS = [
  "Public",
  "Internal Use Only",
  "Confidential",
  "Highly Confidential"
].map(value => ({
  value,
  label: value
}));

const GDPR_OPTIONS = ["No", "Yes"].map(value => ({
  value: value === "Yes",
  label: value
}));

const PII_OPTIONS = [
  "No PII",
  "Yes and PII has been anonymized",
  "Yes and PII has not been anonymized"
].map(value => ({
  value,
  label: value
}));

const SEC_REGULATION_OPTIONS = ["No", "Yes"].map(value => ({
  value: value === "Yes",
  label: value
}));

const IntakeFormStep5 = ({ fields, setFormIsDirty }: BaseStepProps) => {
  const { product, saveAllData } = useCapProductContext();
  const internalProduct = product.internal;
  const productSchema = buildCapProductSchema({
    internalProduct: internalProduct !== null ? internalProduct : false
  });
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToCreate>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    watch
  } = form;
  useDirty(isDirty, setFormIsDirty);

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<CapProductToCreate> = async formData => {
    await saveAllData({ capMetadata: formData.capProductMetadatum });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  const gdprRestrictions = watch("capProductMetadatum.gdprRestrictions");

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="capSurveyFormSection3Step1">
          {internalProduct && (
            <CapSurveyFormFieldRow tooltip={TOOLTIP.INFOSEC_CLASSIFICATION}>
              <Controller
                name="capProductMetadatum.infosecClassification"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormSelect
                      id="infosecClassification"
                      name={name}
                      label="Infosec Classification"
                      value={
                        INFOSEC_CLASS_OPTIONS.find(o => o.value === value) ||
                        null
                      }
                      placeholder="Infosec Classification"
                      options={INFOSEC_CLASS_OPTIONS}
                      optional={false}
                      isClearable={false}
                      onChange={(formSelectOnChangeParam: any) => {
                        onChange(
                          extractFormSelectOnChangeValue(
                            formSelectOnChangeParam
                          )
                        );
                      }}
                      classNamePrefix="infosecClassification"
                    />
                  );
                }}
              />
            </CapSurveyFormFieldRow>
          )}

          <CapSurveyFormFieldRow tooltip={TOOLTIP.GDPR_RESTRICTIONS}>
            <Controller
              name="capProductMetadatum.gdprRestrictions"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="gdprRestrictions"
                    name={name}
                    label="GDPR Restrictions"
                    value={GDPR_OPTIONS.find(o => o.value === value) || null}
                    placeholder="GDPR Restrictions"
                    options={GDPR_OPTIONS}
                    optional={false}
                    isClearable={false}
                    onChange={(formSelectOnChangeParam: any) => {
                      onChange(
                        extractFormSelectOnChangeValue(formSelectOnChangeParam)
                      );
                    }}
                    classNamePrefix="gdprRestrictions"
                  />
                );
              }}
            />
          </CapSurveyFormFieldRow>

          {gdprRestrictions && (
            <CapSurveyFormFieldRow tooltip="">
              <Controller
                name="capProductMetadatum.gdprRestrictionsNotes"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormField
                    id="gdprRestrictionsNotes"
                    name={name}
                    label="GDPR Restriction Notes"
                    value={value || ""}
                    optional={false}
                    onChange={onChange}
                    data-testid="capSurveyForm_gdprRestrictionsNotes"
                  />
                )}
              />
            </CapSurveyFormFieldRow>
          )}

          <CapSurveyFormFieldRow tooltip={TOOLTIP.PII}>
            <Controller
              name="capProductMetadatum.personallyIdentifiableInfo"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="personallyIdentifiableInfo"
                    name={name}
                    label="PII"
                    value={PII_OPTIONS.find(o => o.value === value) || null}
                    placeholder="PII"
                    options={PII_OPTIONS}
                    optional={false}
                    isClearable={false}
                    onChange={(formSelectOnChangeParam: any) => {
                      onChange(
                        extractFormSelectOnChangeValue(formSelectOnChangeParam)
                      );
                    }}
                    classNamePrefix="personallyIdentifiableInfo"
                  />
                );
              }}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.SEC_REGULATION}>
            <Controller
              name="capProductMetadatum.secRegulation"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="secRegulation"
                    name={name}
                    label="Government Regulation"
                    value={
                      SEC_REGULATION_OPTIONS.find(o => o.value === value) ||
                      null
                    }
                    placeholder="Government Regulation"
                    options={SEC_REGULATION_OPTIONS}
                    optional={false}
                    isClearable={false}
                    onChange={(formSelectOnChangeParam: any) => {
                      onChange(
                        extractFormSelectOnChangeValue(formSelectOnChangeParam)
                      );
                    }}
                    classNamePrefix="secRegulation"
                  />
                );
              }}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormStep5;
