import React from "react";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormField, FormSelect, FormTextArea } from "@nef/core";
import { toast } from "react-toastify";

import { BaseStepProps } from "../types";
import useCapProductContext from "../../../hooks/useCapProductContext";
import buildCapProductSchema from "../schema";
import { CapProductToCreate } from "../../../api/types";
import { extractErrors } from "../../../utils/react-hook-form-utils";
import CapSurveyFormBody from "../CapSurveyFormBody";
import CapSurveyFormFieldRow from "../CapSurveyFormFieldRow";
import TOOLTIP from "../tooltips";
import Toast from "../../Toast";
import CapSurveyFormStepFooter from "../CapSurveyFormStepFooter";
import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import { useDirty } from "../cap-product-util";

const DATA_SOURCE_OPTIONS = [
  "Internal systems",
  "Third-party vendors",
  "Clients",
  "Other"
].map(value => ({
  value,
  label: value
}));

const DATA_ORIGINAL_DERIVED_OPTIONS = [
  "Original",
  "Derived",
  "A combination of original and derived"
].map(value => ({
  value,
  label: value
}));

const IntakeFormSection6Internal = ({
  fields,
  setFormIsDirty
}: BaseStepProps) => {
  const { product, saveAllData } = useCapProductContext();

  const productSchema = buildCapProductSchema({
    internalProduct: true
  });
  const stepSchema = productSchema.pickNested(fields);

  const form = useForm<CapProductToCreate>({
    defaultValues: product,
    resolver: yupResolver(stepSchema)
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    watch
  } = form;
  useDirty(isDirty, setFormIsDirty);

  const saveStep = async () => {
    await handleSubmit(onHandleSubmit, onHandleSubmitError)().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<CapProductToCreate> = async formData => {
    await saveAllData({ capMetadata: formData.capProductMetadatum });
  };

  const onHandleSubmitError: SubmitErrorHandler<
    CapProductToCreate
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  const dataSource = watch("capProductMetadatum.dataSource");

  return (
    <CapSurveyFormBody>
      <FormProvider {...form}>
        <form data-testid="intakeFormSection6Internal">
          <CapSurveyFormFieldRow tooltip={TOOLTIP.DATA_SOURCE}>
            <>
              <Controller
                name="capProductMetadatum.dataSource"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormSelect
                      id="dataSource"
                      name={name}
                      label="Source"
                      value={
                        DATA_SOURCE_OPTIONS.find(o => o.value === value) || null
                      }
                      placeholder="Source"
                      options={DATA_SOURCE_OPTIONS}
                      optional={false}
                      isClearable={false}
                      onChange={(formSelectOnChangeParam: any) => {
                        onChange(
                          extractFormSelectOnChangeValue(
                            formSelectOnChangeParam
                          )
                        );
                      }}
                      classNamePrefix="dataSource"
                    />
                  );
                }}
              />

              {dataSource === "Other" && (
                <Controller
                  name="capProductMetadatum.dataSourceNotes"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormField
                      id="dataSourceNotes"
                      name={name}
                      label="Source Notes"
                      value={value || ""}
                      optional={false}
                      onChange={onChange}
                      data-testid="capSurveyForm_dataSourceNotes"
                    />
                  )}
                />
              )}
            </>
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.DATA_PROVENANCE}>
            <Controller
              name="capProductMetadatum.dataProvenance"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormTextArea
                  id="dataProvenance"
                  name={name}
                  label="Data Provenance"
                  value={value || ""}
                  rows={4}
                  optional={true}
                  onChange={onChange}
                  data-testid="capSurveyForm_dataProvenance"
                />
              )}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormFieldRow tooltip={TOOLTIP.DATA_ORIGINAL_DERIVED}>
            <Controller
              name="capProductMetadatum.dataOriginalOrDerived"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="dataOriginalOrDerived"
                    name={name}
                    label="Original or Derived"
                    value={
                      DATA_ORIGINAL_DERIVED_OPTIONS.find(
                        o => o.value === value
                      ) || null
                    }
                    placeholder="Original or Derived"
                    options={DATA_ORIGINAL_DERIVED_OPTIONS}
                    optional={false}
                    isClearable={false}
                    onChange={(formSelectOnChangeParam: any) => {
                      onChange(
                        extractFormSelectOnChangeValue(formSelectOnChangeParam)
                      );
                    }}
                    classNamePrefix="dataOriginalOrDerived"
                  />
                );
              }}
            />
          </CapSurveyFormFieldRow>

          <CapSurveyFormStepFooter
            save={saveStep}
            canAdvance={!isSubmitting && isSubmitSuccessful}
            isDirty={isDirty}
          />
        </form>
      </FormProvider>
    </CapSurveyFormBody>
  );
};

export default IntakeFormSection6Internal;
