import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormRadioCheckboxGroup, FormRadioCheckboxButton } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import styled from "styled-components";

import { DAY_OPTIONS, RecurringFreq, ScheduleRules } from "../util";

const StyledFormRadioCheckboxGroup = styled(FormRadioCheckboxGroup)`
  margin-bottom: 0 !important;
`;

const StyledFormRadioCheckboxButton = styled(FormRadioCheckboxButton)`
  margin: 0.25rem 0;
`;

const DayOfWeekField = ({ freq }: { freq: RecurringFreq }) => {
  const { control } = useFormContext<ScheduleRules>();

  const computeNewDays = (days: string[], selectedDay: string) => {
    const newDays = [...days];
    const dayIndex = newDays.findIndex(day => day === selectedDay);

    if (dayIndex > -1) {
      newDays.splice(dayIndex, 1);
    } else {
      newDays.push(selectedDay);
    }

    return newDays;
  };

  return (
    <>
      <div> </div>
      <Controller
        name="recurring.weekly.byday"
        control={control}
        key={`byday_${freq}`}
        render={({ field: { onChange, value, name } }) => (
          <StyledFormRadioCheckboxGroup
            id={name}
            type="checkbox"
            name={name}
            onChange={({ value: selectedDay }: CustomChangeEvent) => {
              const newDays = computeNewDays(
                value || [],
                selectedDay as string
              );
              onChange(newDays);
            }}
            value={value}
          >
            {DAY_OPTIONS.map(option => {
              return (
                <StyledFormRadioCheckboxButton
                  id={`ByDay_${option.value}`}
                  label={option.label}
                  name={option.value}
                  key={option.value}
                  value={option.value}
                  optional={true}
                />
              );
            })}
          </StyledFormRadioCheckboxGroup>
        )}
      />
    </>
  );
};

export default DayOfWeekField;
