import React from "react";
import styled from "styled-components";

import LIST_TYPE_ICON from "../../assets/file-manager/list-type.svg";
import COMPACT_TYPE_ICON from "../../assets/file-manager/compact-type.svg";
import DEFAULT_TYPE_ICON from "../../assets/file-manager/default-type.svg";

const SelectContainer = styled.div`
  display: flex;
  background-color: #f0f0f0;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
`;
const SelectButton = styled.button`
  display: inline-flex;
  align-self: stretch;
  width: 100%;
  border: none;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  padding: 0.75rem;
  border-radius: 0.3125rem;
  align-item: center;
  ${(props: { selected: boolean }) =>
    props.selected &&
    `background-color: #fff;
`}
`;
const SelectIcon = styled.img`
  height: 100%;
  width: 100%;
`;

export enum ViewMode {
  Table,
  Default,
  Compact
}

function ViewSeletor({
  viewMode,
  setViewMode
}: {
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
}) {
  return (
    <SelectContainer>
      <SelectButton
        title="table view"
        selected={viewMode === ViewMode.Table}
        onClick={() => {
          setViewMode(ViewMode.Table);
        }}
        data-testid="table-view"
        data-selected={viewMode === ViewMode.Table}
      >
        <SelectIcon src={LIST_TYPE_ICON} alt="list file icon" />
      </SelectButton>
      <SelectButton
        title="default view"
        selected={viewMode === ViewMode.Default}
        onClick={() => {
          setViewMode(ViewMode.Default);
        }}
        data-testid="default-view"
        data-selected={viewMode === ViewMode.Default}
      >
        <SelectIcon src={DEFAULT_TYPE_ICON} alt="default file icon" />
      </SelectButton>
      <SelectButton
        title="compact view"
        selected={viewMode === ViewMode.Compact}
        onClick={() => {
          setViewMode(ViewMode.Compact);
        }}
        data-testid="compact-view"
        data-selected={viewMode === ViewMode.Compact}
      >
        <SelectIcon src={COMPACT_TYPE_ICON} alt="compact file icon" />
      </SelectButton>
    </SelectContainer>
  );
}

export default ViewSeletor;
