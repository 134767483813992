import { useContext } from "react";

import CapProductContext from "../contexts/cap-product";

export default function useCapProductContext() {
  const contextValues = useContext(CapProductContext);

  if (contextValues === null) {
    throw Error("CAP Product Context has not been provided.");
  }

  return contextValues;
}
