import React from "react";
import { Header } from "@nef/core";

import useWizard from "../../hooks/useWizard";

import styles from "./IntakeDatatableModalHeader.module.scss";

const IntakeDatatableModalHeader = () => {
  const { currentStepIndex, canAdvanceTo, navigateTo, steps } = useWizard();

  return (
    <div className={styles.header} data-testid="intakeDatatableModalHeader">
      <div className={styles["header-row"]}>
        <div
          className={styles.title}
          data-testid="intakeDatatableModalHeader_title"
        >
          <Header size={2}>Table Details</Header>
        </div>
        <div
          className={styles["progress-bar"]}
          data-testid="intakeDatatableModalHeader_progressBar"
        >
          {steps.map((step, stepIndex) => {
            const classNames = [styles.progress];
            if (stepIndex <= currentStepIndex) {
              classNames.push(styles["progress--running"]);
            }

            return (
              <button
                key={step.path}
                type="button"
                className={classNames.join(" ")}
                onClick={() => navigateTo(step.path)}
                disabled={!canAdvanceTo(step)}
                data-testid={`intakeDatatableModalHeader_progress_${step.path}`}
              >
                &nbsp;
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IntakeDatatableModalHeader;
