import React from "react";

const FolderIcon = () => (
  <svg
    width="163"
    height="150"
    viewBox="0 0 163 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.8834 24.8736L82.7187 25.9874H84.1109H156.62C158.273 25.9874 159.637 27.3511 159.637 29.0038V133.417C159.637 135.07 158.273 136.434 156.62 136.434H5.80075C4.1481 136.434 2.78436 135.07 2.78436 133.417V5.80075C2.78436 4.1481 4.1481 2.78436 5.80075 2.78436H63.8083C64.8438 2.78436 65.535 3.14667 66.2498 4.02883L81.8834 24.8736Z"
      stroke="#D1D1D1"
      strokeWidth="5.56872"
    />
  </svg>
);

export default FolderIcon;
