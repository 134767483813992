import { createContext } from "react";

import {
  AllCapFormProductData,
  CapProductToCreate,
  CapProductMetadatum,
  ProductMetadatumToEdit,
  saveProductResponse,
  saveMetadataResponse,
  saveCapMetadataResponse
} from "../api/types";

export interface CapProductContextProps {
  isReady: boolean;
  product: CapProductToCreate;
  saveProduct: (formData: CapProductToCreate) => Promise<saveProductResponse>;
  saveCapMetadata: (
    formData: CapProductMetadatum
  ) => Promise<saveCapMetadataResponse>;
  saveMetadata: (
    formData: ProductMetadatumToEdit
  ) => Promise<saveMetadataResponse>;
  saveAllData: (formData: AllCapFormProductData) => Promise<{
    product: saveProductResponse | undefined;
    metadata: saveMetadataResponse | undefined;
    capMetadata: saveCapMetadataResponse | undefined;
  }>;
}

const CapProductContext = createContext<CapProductContextProps | null>(null);
export default CapProductContext;
