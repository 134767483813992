import React from "react";
import { Modal, ModalFooter, ModalBody, Button } from "@nef/core";
import styled from "styled-components";

import useDefaultAuth from "../../hooks/useDefaultAuth";

const SpacedModalFooter = styled(ModalFooter)`
  justify-content: end;
`;

const BackToNdlButton = styled(Button)`
  padding: 0 !important;
`;

const RedirectUnauthenticatedUserModal = () => {
  const { authenticatedUser } = useDefaultAuth();

  return (
    <>
      <Modal
        isOpen={true}
        size="lg"
        data-testid="redirectUnauthenticatedUserModal"
      >
        <ModalBody>
          Your account <strong>{authenticatedUser?.email}</strong> is not
          authorized for Data Link Manager. Please login with a different
          account, or access data products through Nasdaq Data Link.
        </ModalBody>
        <SpacedModalFooter>
          <BackToNdlButton
            size="md"
            data-testid="redirectUnauthenticatedUserModal_redirect"
          >
            <a
              href="/"
              rel="noopener noreferrer"
              style={{
                color: "white",
                textDecoration: "none",
                padding: "1rem 1.5rem"
              }}
            >
              Navigate to <strong>Nasdaq Data Link</strong>
            </a>
          </BackToNdlButton>
        </SpacedModalFooter>
      </Modal>
    </>
  );
};

export default RedirectUnauthenticatedUserModal;
