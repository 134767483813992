import { SchemaError } from "../../../api/types";

import { Validation } from "./types";

import { HEADER_ROW_COUNT, COLUMN_FIELD_INDEX } from ".";

type DataTypesObject = {
  dataTypes: string[];
};

const SUPPORTED_DATA_TYPES = [
  "string",
  "date",
  "integer",
  "BigInt",
  "boolean",
  "datetime",
  "double",
  "decimal"
];

const DataTypesValidation: Validation<DataTypesObject> = {
  extractData: (_, rows) => {
    const dataTypes = rows.map(column => column[COLUMN_FIELD_INDEX.DATA_TYPE]);

    return {
      dataTypes
    };
  },
  validate: data => {
    const { dataTypes } = data;

    const results: SchemaError[] = [];

    dataTypes.forEach((dataType, index) => {
      const rowNumber = index + 1 + HEADER_ROW_COUNT;

      if (!dataType) {
        results.push({
          type: "data-type-missing",
          title: `Data type not specified in Row ${rowNumber}. Please refer to the Data Type column description for included data types.`,
          message: "Apply to column C. All rows should be populated"
        });
      } else if (!SUPPORTED_DATA_TYPES.includes(dataType)) {
        results.push({
          type: "data-type-not-supported",
          title: `Data type not supported in Row ${rowNumber}. Please refer to the Data Type column description for included data types.`,
          message: "Apply to column C. All rows should be populated"
        });
      }
    });

    return results;
  }
};

export default DataTypesValidation;
