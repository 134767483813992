import React from "react";
import { FlexGrid, FormField, FormSelect, Link } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";

import LabelValueWithHint from "../common/LabelValueWithHint";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";

import styles from "./FileFormatForm.module.scss";
import {
  FILE_FORMAT_DELIMITER_INFO,
  FILE_FORMAT_SKIP_ROWS_INFO,
  FILE_FORMAT_SKIP_BOTTOM_ROWS_INFO,
  FILE_FORMAT_ENCODING_INFO
} from "./hints";

const DELIMITER_OPTIONS = [
  { value: ",", label: "," },
  { value: "|", label: "|" },
  { value: ";", label: ";" }
];
const ENCODING_OPTIONS = [
  { value: "latin-1", label: "latin-1" },
  { value: "utf", label: "utf" }
];

const FileFormatForm = () => {
  const { control } = useFormContext();

  return (
    <form>
      <FlexGrid fluid={true} data-testid="datatableFileFormatForm">
        <FlexGrid.Row>
          <FlexGrid.Column xs={24}>
            <div className={styles["section-title"]}>File Format</div>
          </FlexGrid.Column>
        </FlexGrid.Row>
        <FlexGrid.Row>
          <FlexGrid.Column xs={12} md={8} lg={4} xl={3}>
            <Controller
              name="type"
              control={control}
              render={({ field: { name } }) => (
                <FormSelect
                  id="type"
                  name={name}
                  label="File Type"
                  placeholder="CSV"
                  disabled={true}
                  classNamePrefix="type"
                />
              )}
            />
          </FlexGrid.Column>

          <FlexGrid.Column xs={12} md={12} lg={4} xl={3}>
            <Controller
              name="delimiter"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormSelect
                  id="delimiter"
                  as={Link}
                  name={name}
                  label={
                    (
                      <LabelValueWithHint
                        label="Delimiter"
                        hint={FILE_FORMAT_DELIMITER_INFO}
                      />
                    ) as any
                  }
                  value={DELIMITER_OPTIONS.find(v => v.value === value)}
                  options={DELIMITER_OPTIONS}
                  isClearable={false}
                  onChange={(formSelectOnChangeParam: any) => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  classNamePrefix="delimiter"
                />
              )}
            />
          </FlexGrid.Column>

          <FlexGrid.Column xs={12} md={8} lg={4} xl={3}>
            <Controller
              name="skipRows"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="FileFormatSkipRows"
                  name={name}
                  type="number"
                  label={
                    (
                      <LabelValueWithHint
                        label="# of Top Rows to Skip"
                        hint={FILE_FORMAT_SKIP_ROWS_INFO}
                      />
                    ) as any
                  }
                  value={value}
                  onChange={onChange}
                  data-testid="datatableFileFormatForm_skipRows"
                />
              )}
            />
          </FlexGrid.Column>

          <FlexGrid.Column xs={12} md={8} lg={4} xl={3}>
            <Controller
              name="skipBottomRows"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="FileFormatSkipBottomRows"
                  name={name}
                  type="number"
                  label={
                    (
                      <LabelValueWithHint
                        label="# of Bottom Rows to Skip"
                        hint={FILE_FORMAT_SKIP_BOTTOM_ROWS_INFO}
                      />
                    ) as any
                  }
                  value={value}
                  onChange={onChange}
                  data-testid="datatableFileFormatForm_skipBottomRows"
                />
              )}
            />
          </FlexGrid.Column>

          <FlexGrid.Column xs={12} md={8} lg={4} xl={3}>
            <Controller
              name="encoding"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormSelect
                  id="encoding"
                  name={name}
                  label={
                    (
                      <LabelValueWithHint
                        label="Encoding"
                        hint={FILE_FORMAT_ENCODING_INFO}
                      />
                    ) as any
                  }
                  value={ENCODING_OPTIONS.find(v => v.value === value)}
                  options={ENCODING_OPTIONS}
                  isClearable={false}
                  onChange={(formSelectOnChangeParam: any) => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  classNamePrefix="encoding"
                />
              )}
            />
          </FlexGrid.Column>
        </FlexGrid.Row>
      </FlexGrid>
    </form>
  );
};

export default FileFormatForm;
