import React, { useState, useEffect } from "react";
import { DatePicker } from "@nef/core";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at } from "lodash";
import moment from "moment";

import { formSchema } from "../../../schema";

const AccessStart = ({
  initialValue,
  resetValue,
  formFieldName
}: {
  initialValue?: string;
  resetValue: string;
  formFieldName: string;
}) => {
  const [currentDate, setCurrentDate] = useState<string | undefined>(
    initialValue
  );
  const {
    control,
    formState: { errors }
  } = useFormContext<yup.Asserts<typeof formSchema>>();

  const formFieldErrors = at(errors, formFieldName)[0];

  useEffect(() => {
    setCurrentDate(resetValue);
  }, [resetValue]);

  return (
    <Controller
      name={formFieldName as any}
      control={control}
      defaultValue={false}
      render={({ field: { onChange } }) => {
        return (
          <DatePicker
            data-testid="OrganizationsAddProductDrawer_accessStart"
            placeholder="YYYY-MM-DD"
            label="Access Start Date"
            onChange={({ value: dateValue }) => {
              onChange(dateValue);
              setCurrentDate(dateValue);
            }}
            value={currentDate ? moment(currentDate) : undefined}
            format="YYYY-MM-DD"
            invalid={!!formFieldErrors}
            feedback={formFieldErrors?.message as string}
          />
        );
      }}
    />
  );
};

export default AccessStart;
