import React from "react";
import { Route } from "react-router-dom";

import useSessionAuth from "../../hooks/useDefaultAuth";
import RedirectUnauthenticatedUserModal from "../modals/RedirectUnauthenticatedUserModal";

import NdlLoginRedirect from "./NdlLoginRedirect";

const SessionProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { authenticated, authenticatedUser, isAuthenticating } =
    useSessionAuth();

  return (
    <Route
      {...rest}
      render={(props): any => {
        if (isAuthenticating) {
          return (
            <div data-testid="sessionProtectedRoute_authenticating">
              Authenticating...
            </div>
          );
        }

        if (!authenticated()) {
          return <NdlLoginRedirect />;
        }

        const isManagerUser =
          authenticatedUser?.rolesArray?.includes("manager_admin") ||
          authenticatedUser?.rolesArray?.includes("manager_publisher_user") ||
          authenticatedUser?.rolesArray?.includes("manager_organization_user");

        return isManagerUser ? (
          <Component {...rest} {...props} />
        ) : (
          <RedirectUnauthenticatedUserModal />
        );
      }}
    />
  );
};

export default SessionProtectedRoute;
