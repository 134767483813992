import React from "react";
import Markdown from "marked-react";

import { Licence } from "../../../api/types";

import styles from "./LicenceInfo.module.scss";

interface LicenceInfoProps {
  productName: string;
  vendorName: string;
  licence: Licence;
}

const LicenceInfo = ({
  productName,
  vendorName,
  licence
}: LicenceInfoProps) => {
  const findAndReplace = (
    content: string,
    toFind: string,
    replaceWith: string
  ) => {
    return content.split(toFind).join(replaceWith);
  };

  let content = licence.body;

  content = findAndReplace(content, "{{vendorName}}", vendorName);
  content = findAndReplace(content, "{{productName}}", productName);

  return (
    <div className={styles.container} data-testid="licenceInfo">
      <h1
        className={styles["licence-name"]}
        data-testid="licenceInfo_licenceName"
      >
        {licence.name} Terms
      </h1>
      <h1
        className={styles["product-name"]}
        data-testid="licenceInfo_productName"
      >
        {productName}
      </h1>

      <div className={styles.licence}>
        <div className={styles.body} data-testid="licenceInfo_body">
          <Markdown gfm={true} value={content} />
        </div>
        <div className={styles.summary} data-testid="licenceInfo_summary">
          <h1 className={styles["summary-title"]}>
            {licence.name} License Terms Simplified
          </h1>

          <div className={styles["summary-content"]}>
            <Markdown gfm={true} value={licence.summary} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenceInfo;
