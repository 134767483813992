import React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "@nef/core";

import styles from "./index.module.scss";

const DEFAULT_MODAL_OPTIONS = {
  title: "Confirmation",
  question: "Are you sure?",
  dismissText: "No",
  confirmText: "Yes"
};

interface Props {
  title?: string;
  question?: string;
  confirmText?: string;
  dismissText?: string;
  onConfirm: () => void;
  onDismiss?: () => void;
}

const RemoveAdminUserModal = ({
  onConfirm,
  onDismiss,
  title = DEFAULT_MODAL_OPTIONS.title,
  question = DEFAULT_MODAL_OPTIONS.question,
  confirmText = DEFAULT_MODAL_OPTIONS.confirmText,
  dismissText = DEFAULT_MODAL_OPTIONS.dismissText
}: Props) => {
  return (
    <Modal
      isOpen
      toggle={onDismiss}
      data-testid="OrganizationsRemoveAdminUserModal"
    >
      <ModalHeader
        toggle={onDismiss}
        data-testid="OrganizationsRemoveAdminUserModal_title"
        className={styles["modal-header"]}
      >
        {title}
      </ModalHeader>
      <ModalBody data-testid="OrganizationsRemoveAdminUserModal_question">
        {question}
      </ModalBody>
      <ModalFooter className={styles["modal-footer"]}>
        <Button
          size="md"
          color="danger"
          onClick={onConfirm}
          data-testid="OrganizationsRemoveAdminUserModal_confirm"
        >
          {confirmText}
        </Button>

        {onDismiss && (
          <Button
            size="md"
            onClick={onDismiss}
            data-testid="OrganizationsRemoveAdminUserModal_dismiss"
          >
            {dismissText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default RemoveAdminUserModal;
