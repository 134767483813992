import React, { useCallback } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { Vendor, VendorSortParam } from "../../api/types";

import styles from "./vendors-table.module.scss";

const columnHelper = createColumnHelper<Vendor>();

interface VendorListHeaderProps {
  vendors?: Vendor[];
  sortParam: string;
  setSortParam: (newSort: VendorSortParam) => void;
}

const VendorsTable = ({
  vendors,
  sortParam,
  setSortParam
}: VendorListHeaderProps) => {
  const ascending = !sortParam.startsWith("-");
  const sortBy = ascending ? sortParam : sortParam.substring(1);
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";

  const updateSortParam = useCallback(
    (newSortBy: string) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as VendorSortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          <Link
            className={styles.values}
            to={`/publishers/${info.row.original.id}`}
          >
            {info.getValue()}
          </Link>
        </div>
      ),
      header: () => {
        return (
          <div
            className={sortBy === "name" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("name")}
            onKeyPress={() => updateSortParam("name")}
            tabIndex={-1}
            data-testid="name-sort"
          >
            Name
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(
          new Date(info.getValue() || Date.now())
        ),
      header: () => {
        return (
          <div
            className={sortBy === "created_at" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("created_at")}
            onKeyPress={() => updateSortParam("created_at")}
            tabIndex={-1}
            data-testid="created-at-sort"
          >
            Created On
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("updatedAt", {
      id: "updatedAt",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(
          new Date(info.getValue() || Date.now())
        ),
      header: () => (
        <div
          className={sortBy === "updated_at" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("updated_at")}
          onKeyPress={() => updateSortParam("updated_at")}
          tabIndex={-1}
          data-testid="updated-at-sort"
        >
          Last Update
          {sortBy === "updated_at" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("vendorUsersCount", {
      id: "numberOfUsers",
      cell: info => info.getValue(),
      header: () => (
        <div
          className={
            sortBy === "vendor_users_count" ? styles["sorter-active"] : ""
          }
          role="button"
          onClick={() => updateSortParam("vendor_users_count")}
          onKeyPress={() => updateSortParam("vendor_users_count")}
          tabIndex={-1}
          data-testid="vendor-users-count-sort"
        >
          Number of Users
          {sortBy === "vendor_users_count" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("activeProductsCount", {
      id: "numberOfLiveProducts",
      cell: info => info.getValue(),
      header: () => (
        <div
          className={
            sortBy === "active_products_count" ? styles["sorter-active"] : ""
          }
          role="button"
          onClick={() => updateSortParam("active_products_count")}
          onKeyPress={() => updateSortParam("active_products_count")}
          tabIndex={-1}
          data-testid="active-products-count-sort"
        >
          Number of Live Products
          {sortBy === "active_products_count" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("productsCount", {
      id: "numberOfTotalProducts",
      cell: info => info.getValue(),
      header: () => (
        <div
          className={sortBy === "products_count" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("products_count")}
          onKeyPress={() => updateSortParam("products_count")}
          tabIndex={-1}
          data-testid="products-count-sort"
        >
          Number of Total Products
          {sortBy === "products_count" && (
            <FontAwesomeIcon
              iconClassName={icon}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("id", {
      id: "viewVendor",
      cell: info => (
        <div className={styles["right-align"]}>
          <Button color="light" outline size="sm">
            <Link className={styles.link} to={`/publishers/${info.getValue()}`}>
              View Publisher
            </Link>
          </Button>
        </div>
      ),
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: vendors ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <section data-testid="publisher-record">
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={`${
                    header.column.getIsSorted()
                      ? `${styles.selected} ${styles.label}`
                      : styles.label
                  } ${header.column.id === "name" ? styles["left-align"] : ""}`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-testid="publisher-table-body">
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} data-testid={`publisher-table-row-${row.id}`}>
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className={
                    cell.column.id === "name"
                      ? `${styles.values} ${styles["left-align"]}`
                      : styles.values
                  }
                  data-testid={`publisher-table-${cell.column.id}-${row.id}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default VendorsTable;
