import * as yup from "yup";

import { getIndexIdentifier } from "../../utils/react-hook-form-utils";
import emptyStringToUndefined from "../../utils/yup-utils";
import "../../config/yup_testers";

const DATATABLE_COLUMN_NAME_REGEX = /^[\w$]{0,64}$/;
const DATATABLE_CODE_REGEX = /^[a-zA-Z][a-zA-Z0-9]*$/;

const datatableColumnSchema = yup.object({
  name: yup
    .string()
    .matches(DATATABLE_COLUMN_NAME_REGEX, {
      message: change => {
        const columnIdentifer = getIndexIdentifier(change.path);
        return `Column ${columnIdentifer} name must be equal to or fewer than 64 characters, including only lowercase letters, underscores, digits (0-9), or dollar sign ($). It cannot contain spaces, &, @, %, ^, *, (, )`;
      }
    })
    .required(change => {
      const columnIdentifer = getIndexIdentifier(change.path);
      return `Column ${columnIdentifer} is missing a value for name`;
    }),
  baseType: yup.string().required(change => {
    const columnIdentifer = getIndexIdentifier(change.path);

    return `Column ${columnIdentifer} is missing a data type`;
  }),
  precision: yup.number().when("baseType", {
    is: "decimal",
    then: yup
      .number()
      .transform(emptyStringToUndefined)
      .min(1)
      .required(change => {
        const columnIdentifer = getIndexIdentifier(change.path);

        return `Column ${columnIdentifer} is missing a value for precision`;
      })
      .min(yup.ref("scale"), change => {
        const columnIdentifer = getIndexIdentifier(change.path);

        return `Column ${columnIdentifer} - Precision must be higher than scale`;
      }),
    otherwise: yup.number().nullable()
  }),
  scale: yup.number().when("baseType", {
    is: "decimal",
    then: yup
      .number()
      .min(0)
      .required(change => {
        const columnIdentifer = getIndexIdentifier(change.path);

        return `Column ${columnIdentifer} is missing a value for scale`;
      }),
    otherwise: yup.number().nullable()
  })
});

const datatableSchema = yup.object({
  name: yup.string().required(),
  code: yup
    .string()
    .required()
    .matches(
      DATATABLE_CODE_REGEX,
      "code must contain only alphanumerical characters and not start with a number"
    ),
  vendorCode: yup.string().required(),
  columns: yup
    .array()
    .unique((c: any) => c.name, "Columns must have unique names")
    .of(datatableColumnSchema)
    .min(1, "At least one column must be created")
});

export default datatableSchema;
