import React, { useMemo, useState } from "react";
import { Button, CircleButton, FontAwesomeIcon } from "@nef/core";
import { useFormContext, useFieldArray, SubmitHandler } from "react-hook-form";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import { uniq } from "lodash";

import {
  CapProductToEdit,
  DatatablesNameDescription,
  SchemaError
} from "../../../api/types";
import IntakeDatatableModal from "../../intake-datatable-modal/IntakeDatatableModal";
import { ConfirmationModal } from "../../modals/ConfirmationModal";
import { deleteDirectoryOrFile } from "../../../api/api";
import useCapProductContext from "../../../hooks/useCapProductContext";
import Toast from "../../Toast";
import IntakeDatatableErrorModal from "../../intake-datatable-error-modal/IntakeDatatableErrorModal";
import { useQueue } from "../../../contexts/queue";

import styles from "./DatatableInput.module.scss";
import DatatableEntryErrorSummary from "./DatatableEntryErrorSummary";

function DatatatableEntry({
  name,
  description,
  schemaErrors,
  uuid,
  remove,
  edit,
  showErrors
}: {
  name: string;
  description: string;
  schemaErrors: SchemaError[] | null;
  uuid: string;
  remove?: (_: string) => void;
  edit?: (_: string) => void;
  showErrors: () => void;
}) {
  const removeDatatableEntryByUUID = () => {
    remove?.(uuid);
  };
  const editDatatableEntryByUUID = () => {
    edit?.(uuid);
  };

  const tableItemClassNameModifier = schemaErrors
    ? schemaErrors.length
      ? "table-item--error"
      : "table-item--valid"
    : ""; // for old datatables or any corner cases where the schema hasn't been validated

  const tableItemClassNames = `${styles["table-item"]} ${styles[tableItemClassNameModifier]}`;

  return (
    <div className={tableItemClassNames}>
      <div className={styles["table-status"]} />
      <div className={styles["table-name"]}>{name}</div>
      <div className={styles["table-description"]}>{description}</div>
      <div className={styles["table-actions"]}>
        <CircleButton
          outline
          color="danger"
          className={styles["table-delete-button"]}
          onClick={removeDatatableEntryByUUID}
        >
          <FontAwesomeIcon iconClassName="fa-trash" />
        </CircleButton>
        <CircleButton
          className={styles["table-delete-button"]}
          outline
          onClick={editDatatableEntryByUUID}
        >
          <FontAwesomeIcon iconClassName="fa-edit" />
        </CircleButton>
      </div>
      <div className={styles["table-error"]}>
        <div
          role="button"
          tabIndex={-1}
          onClick={showErrors}
          onKeyPress={showErrors}
        >
          (contains errors - click to view)
        </div>
      </div>
      <DatatableEntryErrorSummary schemaErrors={schemaErrors} />
    </div>
  );
}

function DatatableInput() {
  const { control, watch, handleSubmit, getValues, setValue } =
    useFormContext<CapProductToEdit>();
  const {
    product: contextProduct,
    saveCapMetadata,
    saveAllData
  } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const dataHosting = watch("capProductMetadatum.dataHosting");
  const [order, setOrder] = useState<string>("name-asc");
  const {
    fields: datatables,
    append,
    update,
    remove,
    replace
  } = useFieldArray({
    control,
    name: "capProductMetadatum.datatablesNameDescriptions",
    keyName: "rfid"
  });

  const datatablesSorted = useMemo(() => {
    const tables = product.capProductMetadatum.datatablesNameDescriptions;
    if (tables === null) return [];

    if (order === "name-asc") {
      return tables.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }
    if (order === "name-desc") {
      return tables.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    }
    return tables;
  }, [product.capProductMetadatum.datatablesNameDescriptions, order]);

  const handleNameSortToggle = () => {
    if (order === "name-desc") {
      setOrder("name-asc");
    } else if (order === "name-asc") {
      setOrder("name-desc");
    }
  };

  const [selectedDatatable, setSelectedDatatable] =
    useState<DatatablesNameDescription | null>(null);

  const [selectedDatatableWithError, setSelectedDatatableWithError] =
    useState<DatatablesNameDescription | null>(null);

  const createDatatable = () => {
    if (dataHosting === null) {
      toast(
        <Toast
          type="error"
          title="Progress cannot be continued"
          details={[
            {
              message:
                "Please select a data hosting option before adding a new datatable."
            }
          ]}
        />
      );
      return;
    }
    setSelectedDatatable({
      id: nanoid(),
      name: "",
      code: "",
      description: "",
      dataGranularity: "",
      dataGranularityNotes: "",
      updateFrequency: {
        freq: "history only",
        custom: {
          note: ""
        }
      },
      reportingLag: { value: 0, unit: "" },
      deliveryLag: { value: 0, unit: "" },
      pointInTime: null,
      holidayImpacts: [],
      customHolidayImpactDescription: "",
      sourceFormat: "",
      ingestMethod: "",
      remotePath: "",
      filePattern: "",
      portNumber: "",
      username: "",
      password: "",
      pubKey: "",
      ingestDetails: "",
      tableSize: 0,
      incrementalUpdateSize: "",
      incrementalRowCount: 0,
      updatePattern: "",
      sampleDataUrl: [],
      schemaFileUrl: [],
      schemaErrors: null,
      schemaRequiresPrimaryKeys: true
    });
  };

  const presetFieldsForEditor = () => {
    const capFrequencyData = getValues(
      "capProductMetadatum.datatablesNameDescriptions"
    );

    const reportingLagPrefill = capFrequencyData
      ?.map(items => {
        if (items?.deliveryLag?.value && items?.deliveryLag?.value) {
          return {
            lag: `${items?.deliveryLag?.value} ${items?.deliveryLag?.unit}`
          };
        }
        return null;
      })
      .filter(item => item !== null);

    if (reportingLagPrefill?.[0]?.lag) {
      const reportingLag =
        reportingLagPrefill.length >= 1
          ? "Varies"
          : reportingLagPrefill?.[0]?.lag;
      setValue("reportingLag", reportingLag);
    }

    const dataFrequency = capFrequencyData?.map(items => items.dataGranularity);
    if (dataFrequency) {
      setValue("dataFrequency", uniq(dataFrequency));
    }

    const deliveryFrequency = capFrequencyData?.map(items => {
      return items.updateFrequency.freq === "annually"
        ? "annual"
        : items.updateFrequency.freq;
    });
    if (deliveryFrequency) {
      setValue("deliveryFrequency", uniq(deliveryFrequency) || [""]);
    }
  };

  const saveDatatable: SubmitHandler<CapProductToEdit> = formData => {
    presetFieldsForEditor();
    commitDelete("save");
    return saveAllData({
      capProduct: getValues(),
      capMetadata: formData.capProductMetadatum
    });
  };

  const onSubmitDatatable = (values: DatatablesNameDescription) => {
    const newDatatable = { ...selectedDatatable, ...values };
    const foundDatatableIndex = datatables?.findIndex(
      datatable => datatable.id === newDatatable.id
    );
    const previousFormValue = datatables;
    if (foundDatatableIndex > -1) {
      update(foundDatatableIndex, newDatatable);
    } else {
      append(newDatatable);
    }

    setSelectedDatatable(null);
    handleSubmit(saveDatatable)().catch(() => {
      toast(
        <Toast
          type="error"
          title="Datatable cannot be saved"
          details={[
            {
              message: `${
                foundDatatableIndex > -1 ? "Update" : "Add"
              } datatable failed`
            }
          ]}
        />
      );
      replace(previousFormValue);
    });
  };

  const onSubmitDatatableWithError = (values: DatatablesNameDescription) => {
    const newDatatable = { ...selectedDatatable, ...values };
    const foundDatatableIndex = datatables?.findIndex(
      datatable => datatable.id === newDatatable.id
    );
    const previousFormValue = datatables;
    if (foundDatatableIndex > -1) {
      update(foundDatatableIndex, newDatatable);
    } else {
      append(newDatatable);
    }

    setSelectedDatatableWithError(null);
    handleSubmit(saveDatatable)().catch(() => {
      toast(
        <Toast
          type="error"
          title="Datatable cannot be saved"
          details={[
            {
              message: `${
                foundDatatableIndex > -1 ? "Update" : "Add"
              } datatable failed`
            }
          ]}
        />
      );
      replace(previousFormValue);
    });
  };

  const onDeleteDatatable = async () => {
    if (deleteId !== null) {
      const deleteIndex = datatables.findIndex(v => v.id === deleteId);
      if (deleteIndex !== -1 && datatables[deleteIndex]?.id) {
        deleteDirectoryOrFile(
          `/products/${product.id}/${datatables[deleteIndex].id}`,
          product.vendor.id
        );
      }
      const previousFormValue = datatables;
      remove(deleteIndex);
      setDeleteId(null);
      const formData = getValues();
      saveCapMetadata(formData.capProductMetadatum).catch(() => {
        toast(
          <Toast
            type="error"
            title="Progress cannot be saved"
            details={[
              {
                message: "Delete datatable failed"
              }
            ]}
          />
        );
        replace(previousFormValue);
      });
    }
  };
  const { getQueue, clear } = useQueue();
  const commitDelete = (action: string) => {
    const deleteSchemaQueue = Object.values(
      getQueue(`delete-schema-files-${action}`) || {}
    );
    const deleteSampleQueue = Object.values(
      getQueue(`delete-sample-files-${action}`) || {}
    );

    Promise.allSettled(
      deleteSchemaQueue
        .concat(deleteSampleQueue)
        .map(file => deleteDirectoryOrFile(file, product.vendor.id))
    ).then(() => {
      clear();
    });
  };

  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [discardState, setDiscardState] = useState<boolean>(false);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>Data Tables</h3>
          <Button outline={true} onClick={createDatatable}>
            <FontAwesomeIcon iconClassName="fa-plus" />
            &nbsp; ADD TABLE
          </Button>
        </div>
        <div className={styles["sub-header"]}>
          <Button size="small" color="link" onClick={handleNameSortToggle}>
            NAME &nbsp;
            {order === "name-desc" && (
              <FontAwesomeIcon
                iconClassName={`fa-chevron-down ${styles["sort-icon"]}`}
              />
            )}
            {order === "name-asc" && (
              <FontAwesomeIcon
                iconClassName={`fa-chevron-up ${styles["sort-icon"]}`}
              />
            )}
          </Button>
        </div>
        <div className={styles["table-list"]}>
          {datatablesSorted.map(datatable => (
            <DatatatableEntry
              key={datatable.id}
              name={datatable.name}
              description={datatable.description}
              schemaErrors={datatable.schemaErrors}
              uuid={datatable.id}
              edit={() => setSelectedDatatable(datatable)}
              remove={() => {
                setDeleteId(datatable.id);
              }}
              showErrors={() => setSelectedDatatableWithError(datatable)}
            />
          ))}
        </div>
      </div>
      {selectedDatatable && (
        <IntakeDatatableModal
          hosted={!!dataHosting}
          datatable={selectedDatatable}
          onSubmit={onSubmitDatatable}
          onDismiss={() => {
            commitDelete("discard");
            setSelectedDatatable(null);
          }}
          onDiscard={() => setDiscardState(true)}
          existedCodes={datatablesSorted
            .map(v => v.code)
            .filter(v => v && v !== selectedDatatable.code)}
        />
      )}
      {selectedDatatableWithError && (
        <IntakeDatatableErrorModal
          datatable={selectedDatatableWithError}
          onSubmit={onSubmitDatatableWithError}
          onDismiss={() => setSelectedDatatableWithError(null)}
        />
      )}
      <ConfirmationModal
        isOpen={deleteId !== null}
        title="Remove Data Table"
        question={`Are you sure you want to remove ${
          deleteId !== null ? datatables.find(v => v.id === deleteId)?.name : ""
        }? This process is permanent and, once complete, the deleted data table can’t be recovered. `}
        onConfirm={onDeleteDatatable}
        onDismiss={() => {
          setDeleteId(null);
        }}
      />
      <ConfirmationModal
        isOpen={discardState}
        title="Unsaved changes"
        question="There are unsaved changes. Please complete the module and click submit to save changes."
        onConfirm={() => {
          commitDelete("discard");
          setSelectedDatatable(null);
          setDiscardState(false);
        }}
        onDismiss={() => {
          setDiscardState(false);
        }}
      />
    </>
  );
}
export default DatatableInput;
