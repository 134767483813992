import humps from "humps";

const h1 = /^#(?!#)\s*(.*)/;
const h2 = /^##(?!#)\s*(.*)/;

/**
 * Extract pages from markdown - h1 are parsed top level pages, following h2 are child pages.
 * @param markdown
 * @returns {Array}
 */
export function parsePages(rawMarkdown: string): Page[] {
  const markdown = rawMarkdown || "# DOCUMENTATION";

  const pages: Page[] = [];
  let rootPage: Page | null = null;
  let page: Page | null = null;
  let lines = [];

  const defaultRootPage = new Page({ line: "# DOCUMENTATION" }); // Uppercase for consistency with real documentation

  function processLine(line: string) {
    if (h1.test(line)) {
      page = null; // reset page
      rootPage = new Page({ line });
      pages.push(rootPage);
      return;
    }
    const parent = rootPage || defaultRootPage;
    if (h2.test(line)) {
      page = new Page({ parent, line });
      parent.pushPage(page);
      return;
    }
    if (page) {
      page.pushLine(line);
      return;
    }
    if (rootPage) {
      // rootPage is true only when inside of h1
      rootPage.pushLine(line);
      return;
    }
    if (parent) {
      parent.pushLine(line);
    }
  }

  lines = markdown.replace(/^[\r\n]+/g, "\n").split("\n");
  lines.forEach(processLine);
  if (defaultRootPage.pages?.length > 0 || defaultRootPage.lines?.length > 0) {
    pages.unshift(defaultRootPage);
  }
  return pages;
}

export class Page {
  parent: Page | null;

  pages: Page[];

  lines: string[];

  line: string | null;

  constructor({ parent, pages, lines, line }: Partial<Page>) {
    this.parent = parent || null;
    this.pages = pages || [];
    this.lines = lines || [];
    this.line = line || null;
  }

  get isContentEmpty(): boolean {
    return !!this.content;
  }

  get isWithoutChildren(): boolean {
    return this.pages === null || this.pages.length === 0;
  }

  get isNotVisible(): boolean {
    return this.isContentEmpty && this.isWithoutChildren;
  }

  get isVisible(): boolean {
    return !this.isNotVisible;
  }

  get title(): string | undefined {
    if (!this.line) {
      return undefined;
    }

    let title = "";
    let matched = this.line.match(h1);
    if (matched) {
      title = matched[1]; // eslint-disable-line
    }
    if (!title) {
      matched = this.line.match(h2);
      if (matched) {
        title = matched[1]; // eslint-disable-line
      }
    }
    return title;
  }

  get slug(): string {
    if (!this.title) {
      return "";
    }

    return humps
      .decamelize(this.title.trim(), { separator: "-" })
      .toLowerCase()
      .replace(/[^\w]+/g, "-");
  }

  get computed() {
    return `#${this.slug}`;
  }

  // Used to produce a unique reference for the purposes of finding the link to this
  // page in our cucumber test suite.
  get cssClass(): string {
    return `link-${this.slug}`;
  }

  get href(): string {
    let href = this.slug;
    const parentHref = this.parent ? this.parent.href : "";
    if (parentHref) {
      href = `${parentHref}/${href}`;
    }
    return href;
  }

  get content(): string {
    return (this.lines || []).join("\n");
  }

  pushLine(line: string) {
    this.lines.push(line);
  }

  pushPage(page: Page) {
    this.pages.push(page);
  }
}
