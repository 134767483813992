import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import { useClient } from "jsonapi-react";
import { useHistory } from "react-router-dom";

import Toast from "../../components/Toast";
import useQueryParam from "../../hooks/useQueryParam";
import { UserGroup } from "../../api/types";

import styles from "./delete-user-from-group-modal.module.scss";

interface DeleteUserModalProps {
  isOpen: boolean;
  close: () => void;
  userGroup: UserGroup;
}

const DeleteUserGroupModal = ({
  isOpen,
  close,
  userGroup
}: DeleteUserModalProps) => {
  const history = useHistory();
  const queryParams = useQueryParam();
  const vendorId = queryParams?.get("vendorId") ?? "";
  const client = useClient();
  const { id } = userGroup;

  const handleDeleteUserUserGroup = async () => {
    const response = await client.delete(["vendor-user-groups", id]);

    const { data: responseData } = response;

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error deleting the user group"
          details={[]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="success"
        title="User group has been successfully deleted"
        details={[]}
      />
    );

    if (responseData) {
      history.push(`/user-groups?vendorId=${vendorId}`);
    }

    handleClose();
  };

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="deleteUserGroupModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Delete User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Delete User Group
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <p className={styles.description}>
              Are you sure you want to delete {userGroup.name}?
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              color="danger"
              onClick={handleDeleteUserUserGroup}
              data-testid="deleteUserGroupModal_submit"
            >
              Delete User Group
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="deleteUserGroupModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteUserGroupModal;
