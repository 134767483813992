import React, { useState } from "react";

import { Wizard } from "../wizard";

import Header from "./Header";
import DatatableSchemaStep from "./DatatableSchemaStep";

const CreateDatatableForm = () => {
  const [backend, setBackend] = useState("postgres");

  return (
    <Wizard header={<Header backend={backend} />}>
      <DatatableSchemaStep createMode={true} setBackend={setBackend} />
    </Wizard>
  );
};

export default CreateDatatableForm;
