import React, { useEffect } from "react";

import useWizard from "../../hooks/useWizard";

const RedirectInvalidPath = () => {
  const { steps, currentStepIndex, navigateTo } = useWizard();

  useEffect(() => {
    if (currentStepIndex < 0) {
      // No current use case where there are 0 steps, but just giving it
      // a sensible default anyway
      const redirectPath = steps.length > 0 ? steps[0].path : "";
      navigateTo(redirectPath, true);
    }
  }, [steps, currentStepIndex, navigateTo]);

  return null;
};

const RedirectUnadvanceableStep = () => {
  const { steps, currentStepIndex, navigateTo } = useWizard();

  useEffect(() => {
    const firstUnadvancableStepIndex = steps.findIndex(
      step => !step.canAdvance
    );
    if (
      firstUnadvancableStepIndex >= 0 &&
      currentStepIndex > firstUnadvancableStepIndex
    ) {
      navigateTo(steps[firstUnadvancableStepIndex].path, true);
    }
  }, [steps, currentStepIndex, navigateTo]);

  return null;
};

const WizardRedirect = () => {
  return (
    <>
      <RedirectInvalidPath />
      <RedirectUnadvanceableStep />
    </>
  );
};

export default WizardRedirect;
