import React from "react";
import { FlexGrid } from "@nef/core";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import { useQuery } from "jsonapi-react";

import {
  SdgIndicator,
  ProductCategory,
  ProductFormMode
} from "../../api/types";
import Step from "../wizard/Step";
import ProductPreview from "../product-preview/ProductPreview";
import { useProductContext } from "../../hooks/useProductContext";
import webClient from "../../api/web/client";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import AccordianTab from "./components/accordian/Accordian";
import ProductDetailStep from "./ProductDetailsStep";
import ProductSelectDatatableStep from "./ProductSelectDatatableStep";
import ProductMetadataStep from "./ProductMetadataStep";

const SplitPane = styled(FlexGrid)`
  overflow: hidden;
  height: 100%;
`;

const SplitPaneInner = styled(FlexGrid.Row)`
  overflow: hidden;
  height: 100%;
`;

const SplitPaneContent = styled(FlexGrid.Column)`
  overflow: auto;
  height: 100%;
`;

const ProductStep = () => {
  const { authenticatedUser } = useDefaultAuth();
  const isManagerAdmin =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  const { mode, product, productForm, saveProduct } = useProductContext(
    ProductFormMode.EDIT
  );

  const { data: sdgIndicators } = useQuery<SdgIndicator[]>("sdg-indicators", {
    client: webClient
  });

  const {
    data: prodCategories,
    isLoading,
    error
  } = useQuery<ProductCategory[]>(
    [
      "product-categories",
      {
        filter: {
          product_types: product.productType,
          parent_id: "null"
        }
      }
    ],
    { client: webClient }
  );

  const {
    data: coreProductCategories,
    isLoading: isLoadingCoreCategories,
    error: errorCoreCategories
  } = useQuery<ProductCategory[]>(
    [
      "product-categories",
      {
        filter: {
          product_types: "CORE",
          parent_id: "null"
        }
      }
    ],
    { client: webClient }
  );

  if (!productForm) return null;

  const canAdvance = mode === "edit" && !!product.id;

  return (
    <Step
      path="details"
      name="Product Details"
      canAdvance={canAdvance}
      save={() => saveProduct()}
    >
      <SplitPane fluid={true} data-testid="productDetailForm">
        <SplitPaneInner alignItems="start">
          <FormProvider {...productForm}>
            <SplitPaneContent xs={24} md={10}>
              <AccordianTab
                title="Product Overview"
                testId="productStep_overview"
              >
                <ProductDetailStep />
              </AccordianTab>
              <AccordianTab
                title="Product Metadata"
                testId="productStep_metadata"
              >
                <ProductMetadataStep
                  productSupportingDocs={product?.productSupportingDocuments}
                  sdgIndicators={sdgIndicators as SdgIndicator[]}
                  prodCategories={prodCategories as ProductCategory[]}
                  productCategoriesError={error}
                  isProductCategoriesLoading={isLoading}
                  coreProductCategories={coreProductCategories}
                  isLoadingCoreCategories={isLoadingCoreCategories}
                  errorCoreCategories={errorCoreCategories}
                  requireContactSales={product?.allowContactSales}
                />
              </AccordianTab>
              {!!isManagerAdmin && (
                <AccordianTab
                  title="Datatables"
                  testId="productStep_datatables"
                >
                  <ProductSelectDatatableStep />
                </AccordianTab>
              )}
            </SplitPaneContent>
            <SplitPaneContent xs={24} md={14}>
              <FlexGrid.Row alignItems="stretch">
                <FlexGrid.Column xs={24}>
                  <ProductPreview
                    productType={product.productType}
                    currentTab="documentation"
                  />
                </FlexGrid.Column>
              </FlexGrid.Row>
            </SplitPaneContent>
          </FormProvider>
        </SplitPaneInner>
      </SplitPane>
    </Step>
  );
};

export default ProductStep;
