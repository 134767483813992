import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import { DatatableColumn } from "../../../api/types";

import ColumnFiltersButton, {
  StyledColumnFiltersButton
} from "./ColumnFiltersButton";
import Toggle from "./Toggle";

interface ColumnIndexKeyToggleProps {
  index: number;
  datatableIsPublished: boolean;
  updateToggle: (index: number, field: string, checked: boolean) => void;
  updateColumn: (
    index: number,
    newAttributes: Partial<DatatableColumn>
  ) => void;
}

const MAX_NUMBER_OF_INDEXED_COLUMNS = 4;

const StyledColumnIndexKeyToggle = styled.div`
  ${Toggle} + ${StyledColumnFiltersButton} {
    margin-top: -1rem;
    height: 1rem;
  }
`;

const ColumnIndexKeyToggle = ({
  index,
  datatableIsPublished,
  updateToggle,
  updateColumn
}: ColumnIndexKeyToggleProps) => {
  const { control } = useFormContext();
  const columns = useWatch({ control, name: "columns" });
  const column = columns[index];

  const isIndexDisabled = (columnIndex: number) => {
    return (
      datatableIsPublished ||
      (maxNumberOfIndexedColumns && !columns[columnIndex].isIndex)
    );
  };

  const maxNumberOfIndexedColumns =
    columns.filter((c: DatatableColumn) => c.isIndex).length >=
    MAX_NUMBER_OF_INDEXED_COLUMNS;

  return (
    <Controller
      control={control}
      name={`columns.${index}.isIndex`}
      render={({ field: { value, name } }) => {
        return (
          <StyledColumnIndexKeyToggle>
            <Toggle
              name={name}
              checked={value}
              data-checked={value}
              showText={false}
              disabled={isIndexDisabled(index)}
              data-disabled={isIndexDisabled(index)}
              onChange={e => updateToggle(index, "isIndex", e.checked)}
              data-testid="datatableSchemaForm_index"
            />
            {value && (
              <ColumnFiltersButton
                column={column}
                index={index}
                updateColumn={updateColumn}
              />
            )}
          </StyledColumnIndexKeyToggle>
        );
      }}
    />
  );
};

export default ColumnIndexKeyToggle;
