import * as yup from "yup";

import { OPTION_VALUES } from "./options";
import { rruleToFieldDate } from "./components/Scheduler/util";

type BuildCapProductSchemaOptions = {
  internalProduct?: boolean;
  forSaleProduct?: boolean;
};

const buildCapProductSchema = (opt?: BuildCapProductSchemaOptions) => {
  const { internalProduct = false, forSaleProduct = false } = opt || {};
  const datatablesNameDescriptionsSchema = buildHostedDatatableSchema();

  const nonHostedDatatablesNameDescriptionsSchema =
    buildNonHostedDatatableSchema();

  const capProductMetadatum = yup.object({
    deliveryLag: yup.string().nullable().label("Delivery Lag"),
    pointInTime: yup.string().required().nullable().label("Point In Time"),
    dataTimezone: yup
      .array()
      .ensure()
      .of(yup.string().oneOf([...OPTION_VALUES.DATA_TIMEZONE]))
      .min(0)
      .label("Timezone"),
    dataHolidaySchedule: yup.string().nullable().label("Holiday Schedule"),
    customHolidayImpactDescription: yup
      .string()
      .nullable()
      .label("Custom Holiday Schedule"),
    infosecClassification: yup
      .string()
      .required()
      .nullable()
      .label("Infosec Classification"),
    gdprRestrictions: yup.boolean().required().label("GDPR Restrictions"),
    gdprRestrictionsNotes: yup
      .string()
      .nullable()
      .when("gdprRestrictions", {
        is: true,
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      })
      .label("GDPR Restriction Notes"),
    personallyIdentifiableInfo: yup.string().required().nullable().label("PII"),
    secRegulation: yup.boolean().required().label("SEC Regulation"),
    datatablesNameDescriptions: yup
      .array()
      .label("Data Tables")
      .when("dataHosting", {
        is: true,
        then: schema =>
          schema.of(datatablesNameDescriptionsSchema).min(1).required(),
        otherwise: schema =>
          schema.of(nonHostedDatatablesNameDescriptionsSchema).min(1).required()
      }),

    dataHosting: yup.boolean().nullable().required().label("Data Hosting"),
    knownIssues: yup.string().nullable().label("Known Issues"),
    sampleDataUrl: yup.string().nullable().label("Sample Data"),
    relatedData: yup.string().nullable().label("Related Data"),
    dataSource: yup
      .string()
      .nullable()
      .requiredIf(internalProduct)
      .label("Source"),
    dataSourceNotes: yup
      .string()
      .nullable()
      .when("dataSource", {
        is: "Other",
        then: schema => schema.requiredIf(internalProduct)
      })
      .label("Source Notes"),
    dataProvenance: yup.string().nullable().label("Data Provenance"),
    dataOriginalOrDerived: yup
      .string()
      .nullable()
      .requiredIf(internalProduct)
      .label("Original or Derived"),
    licensedFromVendor: yup
      .boolean()
      .when("dataSource", {
        is: "Third-party vendors",
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      })
      .label("Licensed from Vendor"),
    thirdPartyProviderLicence: yup
      .string()
      .nullable()
      .when("licensedFromVendor", {
        is: true,
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      })
      .label("Third Party Provider License"),
    vendorId: yup
      .number()
      .nullable()
      .when("dataSource", {
        is: "Third-party vendors",
        then: schem => schem.positive("Vendor Name is required"),
        otherwise: schem => schem.notRequired()
      })
      .label("Vendor Name"),
    vendorName: yup
      .string()
      .nullable()
      .when(["dataSource", "vendorId"], {
        is: (dataSource: string, vendorId: number) =>
          !vendorId && dataSource === "Third-party vendors",
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      })
      .label("Vendor Name"),
    vendorContactName: yup
      .string()
      .nullable()
      .when("dataSource", {
        is: "Third-party vendors",
        then: schem => schem.requiredIf(internalProduct)
      })
      .label("Vendor Contact Name"),
    vendorContactEmail: yup
      .string()
      .nullable()
      .email()
      .when("dataSource", {
        is: "Third-party vendors",
        then: schem => schem.requiredIf(internalProduct)
      })
      .label("Vendor Contact Email"),
    vendorContactPhone: yup
      .string()
      .nullable()
      .when("dataSource", {
        is: "Third-party vendors",
        then: schem => schem.requiredIf(internalProduct)
      })
      .label("Vendor Contact Phone"),
    dataLicenceTermsConditions: yup
      .string()
      .ensure()
      .when("dataSource", {
        is: "Third-party vendors",
        otherwise: schem => schem.requiredIf(internalProduct)
      })
      .label("Data Licence Terms & Conditions"),
    pricingOption: yup
      .string()
      .requiredIf(forSaleProduct)
      .nullable()
      .label("Pricing Option"),
    procurementContractualConsiderations: yup
      .string()
      .nullable()
      .label("Procurement and  Contractual Considerations"),
    visibilityPreference: yup
      .string()
      .ensure()
      .oneOf([
        "Internal – Visible to my business unit only",
        "Internal – Visible to all of Nasdaq"
      ])
      .requiredIf(internalProduct)
      .label("Visibility Preference"),
    dataSourceFormat: yup
      .array()
      .ensure()
      .of(yup.string().oneOf([...OPTION_VALUES.SOURCE_FORMAT]))
      .min(1)
      .label("Source Format"),
    dataSourceFormatNotes: yup
      .string()
      .nullable()
      .when("dataSourceFormat", {
        is: (values: string[]) => values?.includes("Other"),
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      })
      .label("Source Format Notes"),
    dataIngestMethod: yup
      .array()
      .ensure()
      .of(yup.string().oneOf([...OPTION_VALUES.INGEST_METHOD]))
      .min(0)
      .label("Ingest Method"),
    dataIngestMethodNotes: yup
      .string()
      .nullable()
      .when("dataIngestMethod", {
        is: (values: string[]) =>
          ["Scraped", "Other"].some(v => values?.includes(v)),
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      })
      .label("Ingest Method Notes"),
    dataSize: yup.string().nullable().label("Dataset Size"),
    dataUpdateSize: yup
      .string()
      .ensure()
      .nullable()
      .label("Approximate Incremental Update Size"),
    updatePattern: yup
      .string()
      .ensure()
      .oneOf([...OPTION_VALUES.UPDATE_PATTERN])
      .required()
      .label("Update Pattern"),
    updatePatternNotes: yup
      .string()
      .nullable()
      .when("updatePattern", {
        is: "Other",
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      })
      .label("Update Pattern Notes"),
    dataOwnerName: yup
      .string()
      .required()
      .nullable()
      .label("Dataset Owner Name"),
    dataOwnerEmail: yup
      .string()
      .nullable()
      .email()
      .required()
      .label("Dataset Owner Email"),
    technicalContactName: yup
      .string()
      .required()
      .nullable()
      .label("Technical Contact Name"),
    technicalContactEmail: yup
      .string()
      .nullable()
      .email()
      .required()
      .label("Technical Contact Email")
  });

  const productMetadatum = yup.object({
    frameworkRegulationNotes: yup
      .string()
      .nullable()
      .label("Frameworks or Regulations")
  });

  const otherDocumentationSchema = yup
    .object({
      private: yup.boolean().oneOf([true]),
      documentType: yup.string().oneOf(["OTHER"]).label("Document Type")
    })
    .label("Other Documentation");

  const baseProductSupportingDocuments = yup
    .array(
      yup.object({
        private: yup.boolean().oneOf([true])
      })
    )
    .transform((values: any[]) => values.filter(value => value.id))
    .minOf(0, otherDocumentationSchema, "documentType");

  const schema = yup.object({
    name: yup.string().min(2).max(64).required().label("Product Name"),
    code: yup.string().required().uppercase().label("Product Code"),
    description: yup.string().required().label("Description"),
    documentation: yup.string().required(),
    overview: yup.string().required().label("Overview"),
    reportingLag: yup.string().nullable(),
    dataFrequency: yup
      .array()
      .of(yup.string().required().label("Data Granularity"))
      .min(1)
      .label("Data Granularity"),
    dataFrequencyNotes: yup
      .string()
      .when("dataFrequency", {
        is: (value: string) => value?.includes("irregular"),
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      })
      .label("Data Granularity Notes"),
    deliveryFrequency: yup
      .array()
      .of(yup.string())
      .min(1)
      .label("Update Frequency"),
    deliveryFrequencyNotes: yup
      .string()
      .when("deliveryFreuqency", {
        is: (value: string) => value?.includes("irregular"),
        then: schem => schem.required()
      })
      .label("Update Frequency Notes"),
    coverage: yup.string().required().label("Coverage"),
    history: yup.string().required().label("History"),
    since: yup
      .string()
      .nullable()
      .when("history", {
        is: "since",
        then: yup.string().ensure().required()
      })
      .label("Since"),
    capProductMetadatum,
    productMetadatum,
    additionalMaterialUrls: yup
      .array()
      .ensure()
      .of(yup.string().required())
      .label("Additional Materials"),
    procurementDocumentUrls: yup.array().ensure().of(yup.string().required()),
    productSupportingDocuments: baseProductSupportingDocuments
  });

  return schema;
};

export const buildNonHostedDatatableSchema = () => {
  const schema = yup.object({
    name: yup.string().required().label("Table Name"),
    description: yup.string().required().label("Table Description"),
    schemaFileUrl: yup
      .array()
      .ensure()
      .of(yup.string().required())
      .min(1)
      .label("Table Schema Upload")
  });

  return schema;
};

export const buildHostedDatatableSchema = (existedCodes: string[] = []) => {
  const schema = yup.object({
    name: yup.string().required().label("Table Name"),
    code: yup
      .string()
      .required()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Table code can only have alphabet characters.")
      .min(4)
      .max(5)
      .notOneOf(existedCodes, "Table code has already been taken")
      .label("Table Code"),
    description: yup.string().required().label("Table Description"),
    sampleDataUrl: yup
      .array()
      .ensure()
      .of(yup.string().required())
      .min(1)
      .label("Sample Data Upload"),
    schemaFileUrl: yup
      .array()
      .ensure()
      .of(yup.string().required())
      .min(1)
      .label("Table Schema Upload"),
    dataGranularity: yup.string().required().label("Data Granularity"),
    dataGranularityNotes: yup
      .string()
      .when("dataGranularity", {
        is: (value: string) => value?.includes("irregular"),
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      })
      .label("Data Granularity Notes"),
    updateFrequency: buildUpdateFrequencySchema(),
    reportingLag: yup.object({
      value: yup
        .number()
        .label("Reporting Lag Value")
        .when("unit", {
          is: "N/A",
          then: schem => schem,
          otherwise: schem => schem.required().moreThan(0)
        }),
      unit: yup.string().required().label("Reporting Lag Unit")
    }),
    deliveryLag: yup.object({
      value: yup
        .number()
        .label("Delivery Lag Value")
        .when("unit", {
          is: "N/A",
          then: schem => schem,
          otherwise: schem => schem.required().moreThan(0)
        }),
      unit: yup.string().required().label("Delivery Lag Unit")
    }),
    pointInTime: yup.boolean().required().nullable().label("Point in Time"),
    holidayImpacts: yup
      .array()
      .ensure()
      .of(yup.string().required())
      .min(1)
      .label("Holiday Impacts"),
    customHolidayImpactDescription: yup
      .string()
      .ensure()
      .when("holidayImpacts", {
        is: (holidayImpacts: string[]) => holidayImpacts.includes("Custom"),
        then: schem => schem.required()
      })
      .label("Custom Holiday Impacts"),
    sourceFormat: yup.string().required().label("Source Format"),
    ingestMethod: yup.string().required().label("Ingest Method"),
    remotePath: yup
      .string()
      .label("Remote Path")
      .when("ingestMethod", {
        is: (ingestMethod: string) =>
          ["FTP", "SFTP", "S3"].includes(ingestMethod),
        then: schem => schem.required()
      }),
    filePattern: yup
      .string()
      .label("File Pattern")
      .when("ingestMethod", {
        is: (ingestMethod: string) =>
          ["FTP", "SFTP", "S3"].includes(ingestMethod),
        then: schem => schem.required()
      }),
    portNumber: yup
      .string()
      .label("Port Number")
      .when("ingestMethod", {
        is: (ingestMethod: string) => ["FTP", "SFTP"].includes(ingestMethod),
        then: schem => schem.required()
      }),
    username: yup
      .string()
      .label("Username")
      .when("ingestMethod", {
        is: (ingestMethod: string) => ["FTP", "SFTP"].includes(ingestMethod),
        then: schem => schem.required()
      }),
    password: yup
      .string()
      .label("Password")
      .when("ingestMethod", {
        is: (ingestMethod: string) => ingestMethod === "SFTP",
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      }),
    pubKey: yup
      .string()
      .label("Public Key")
      .required()
      .when("ingestMethod", {
        is: (ingestMethod: string) => ingestMethod === "SFTP",
        then: schem => schem.required(),
        otherwise: schem => schem.notRequired()
      }),
    ingestDetails: yup
      .string()
      .label("Ingest Details")
      .when("ingestMethod", {
        is: (ingestMethod: string) => ingestMethod === "Other",
        then: schem => schem.required()
      }),
    tableSize: yup.number().min(1).required().label("Table Size"),
    incrementalUpdateSize: yup
      .string()
      .required()
      .label("Approximate Incremental Update Size"),
    incrementalRowCount: yup
      .number()
      .required()
      .moreThan(0)
      .label("Incremental Row Count"),
    updatePattern: yup.string().required().label("Update Pattern")
  });

  return schema;
};

export const buildUpdateFrequencySchema = () => {
  const baseSchema = yup.object({
    freq: yup
      .string()
      .required()
      .oneOf(
        [
          "realtime",
          "irregular",
          "intraday",
          "daily",
          "weekly",
          "monthly",
          "annually",
          "history only"
        ],
        "Update Frequency Repeat must be a valid value"
      )
      .label("Update Frequency Repeat")
  });

  // using `yup.lazy` to handle different variants of `updateFrequency` where multiple fields
  // may or may not be required. Will have to observe if there are performance impact and if
  // there is a need to refactor `updateFrequency` structure
  return yup.lazy(value => {
    switch (value.freq) {
      case "realtime":
      case "history only":
      case "irregular":
        return baseSchema.concat(
          yup.object({
            custom: yup.object({
              note: yup.string().required().label("Update Frequency Notes")
            })
          })
        );
      case "intraday":
        return baseSchema.concat(
          yup.object({
            custom: yup.object({
              deliveryInterval: yup
                .object({
                  value: yup
                    .number()
                    .min(1)
                    .required()
                    .label("Update Frequency Delivery Interval Value"),
                  unit: yup
                    .string()
                    .required()
                    .label("Update Frequency Delivery Interval Unit")
                })
                .required(),
              firstDelivery: yup
                .string()
                .required()
                .label("Update Frequency First Delivery"),
              lastDelivery: yup
                .string()
                .required()
                .label("Update Frequency Last Delivery"),
              timezone: yup
                .string()
                .required()
                .label("Update Frequency Timezone"),
              description: yup
                .string()
                .required()
                .label("Update Frequency Description")
            })
          })
        );
      case "daily":
      case "weekly":
      case "monthly":
      case "annually":
        return baseSchema.concat(
          yup.object({
            dtstart: yup
              .string()
              .required(
                "Starting Date/Time must be specified for recurring updates"
              )
              .label("Recurring Update Starting Date/Tiem"),
            tzid: yup
              .string()
              .required("Timezone must be specified for recurring updates")
              .label("Recurring Update Timezone"),
            interval: yup
              .number()
              .required(
                "Recurring interval must be specified for recurring updates"
              )
              .label("Update Frequency `Every`"),
            count: yup
              .number()
              .integer()
              .min(1)
              .label("Update Frequency Occurrences"),
            until: yup.string().when("dtstart", {
              is: (dtstart: string) => !!dtstart,
              then: schema => {
                return schema.test(
                  "min-until",
                  "End date must be the same or later than starting date",
                  (until, context) => {
                    if (until === undefined) {
                      return true;
                    }

                    const normalizedDtstart = rruleToFieldDate(
                      context.parent.dtstart,
                      "yyyy-MM-dd"
                    );

                    const normalizedUntil = rruleToFieldDate(
                      until || "",
                      "yyyy-MM-dd"
                    );

                    return normalizedUntil >= normalizedDtstart;
                  }
                );
              }
            })
            // skipping validaitons for byday/bymonthday/bymonth/bysetpos
            // because those values are computed from user inputs. Any failed
            // validation would be due to bugs, and users have no way to correct
            // the errors
          })
        );

      default:
        return baseSchema;
    }
  });
};

export default buildCapProductSchema;
