import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormFieldWithIcon,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "jsonapi-react";

import MultiSelect, { MultiSelectOption } from "../../common/MultiSelect";

import styles from "./UserGroupsModal.module.scss";

interface NewUserGroupRequest {
  groups: string[];
}

interface FormValues {
  groups: string[];
}

interface UserGroupsModalProps {
  isOpen: boolean;
  close: () => void;
  vendorId: string;
  selected: string[];
  onUpdate: (groups: any) => void;
}

type UserGroup = {
  id: string;
  name: string;
};

const userGroupRequestSchema = yup.object({
  groups: yup.array().of(yup.string())
});

const UserGroupsModal = ({
  isOpen,
  close,
  vendorId,
  selected,
  onUpdate
}: UserGroupsModalProps) => {
  const [options, setOptions] = useState<MultiSelectOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(
    [] as MultiSelectOption[]
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchQuery(enteredQuery);
  };

  const { data: userGroups, refetch } = useQuery<UserGroup[]>([
    "vendor-user-groups",
    {
      filter: {
        vendorId
      }
    }
  ]);

  useEffect(() => {
    if (isOpen && refetch) {
      refetch();
    }
  }, [isOpen]);

  useEffect(() => {
    if (userGroups) {
      setOptions(
        userGroups
          .map((group: UserGroup) => {
            return {
              value: group.id,
              label: group.name
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      );
      setValue("groups", selected);
    }
  }, [userGroups]);

  useEffect(() => {
    if (options) {
      setFilteredOptions(
        options.filter((option: MultiSelectOption) => {
          return (
            searchQuery === "" ||
            option.label.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
      );
    }
  }, [options, searchQuery]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    reset
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      groups: []
    },
    resolver: yupResolver(userGroupRequestSchema)
  });

  const onSubmit: SubmitHandler<NewUserGroupRequest> = useCallback(
    async data => {
      onUpdate(
        userGroups?.filter(group => data.groups.includes(group.id)) || []
      );

      handleClose();
    },
    [userGroups]
  );

  const handleClose = () => {
    close();
    setSearchQuery("");
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="userGroupsModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="User Groups"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        User Groups
      </ModalHeader>
      <ModalBody>
        <div>
          <FormFieldWithIcon
            data-testid="userGroupsModal_search"
            placeholder="Search"
            iconClassName="fa-search"
            addonType="append"
            value={searchQuery}
            onChange={handleSearch}
          />

          <Controller
            control={control}
            name="groups"
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                options={filteredOptions}
                value={value || selected}
                invalid={!!formErrors.groups}
                feedback={(formErrors?.groups as any)?.message}
                onChange={onChange}
              />
            )}
          />

          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(formErrors).length !== 0}
              data-testid="userGroupsModal_submit"
            >
              Update
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="userGroupsModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UserGroupsModal;
