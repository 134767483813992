// @ts-ignore
import { ApiClient, Serializer } from "jsonapi-react";
import { camelizeKeys } from "humps";

import UserService from "../../services/user";

import schema from "./schema";

const client = new ApiClient({
  url: "/web",
  schema,
  headers: {
    "X-API-Token": UserService.getUser()?.apiKey
  },
  // @ts-ignore
  normalize: (data, dataSchema) => {
    const camelizedData: any = camelizeKeys(data);

    return new Serializer({ schema: dataSchema }).deserialize(camelizedData);
  }
});

export default client;
