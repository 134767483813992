import { createContext } from "react";
import { UseFormReturn } from "react-hook-form";

import { ProductToCreate, ProductToEdit, ProductFormMode } from "../api/types";

export type FinalizeType =
  | "publish"
  | "unpublish"
  | "submit_for_approval"
  | "approve";
export type SubmitType = "update" | "update_live" | FinalizeType;

export interface CreateProductContextProps {
  mode: ProductFormMode.CREATE;
  isReady: boolean;
  product: ProductToCreate;
  setProduct: (product: ProductToCreate) => void;
  productForm: UseFormReturn<ProductToEdit, any>;
  saveProduct: () => Promise<any>;
}

export interface EditProductContextProps {
  mode: ProductFormMode.EDIT;
  isReady: boolean;
  product: ProductToEdit;
  setProduct: (product: ProductToEdit) => void;
  productForm: UseFormReturn<ProductToEdit, any>;
  saveProduct: () => Promise<any>;
  tryFinalize: (type: FinalizeType) => boolean;
  productApprovalType: string | null;
  productApprovalStatus: string | null;
}

const ProductContext = createContext<
  CreateProductContextProps | EditProductContextProps | null
>(null);
export default ProductContext;
