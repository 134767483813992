import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField, FormSelect } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";

import FormLabel from "../../../common/FormLabel";
import extractFormSelectOnChangeValue from "../../../../utils/nef-utils";

import { ScheduleRules } from "./util";
import styles from "./Scheduler.module.scss";
import TimezoneField from "./fields/TimezoneField";

const DELIVERY_INTERVAL_UNITS = ["second(s)", "minute(s)", "hour(s)"].map(
  value => {
    return {
      value,
      label: value
    };
  }
);

const IntradayView = () => {
  const { control } = useFormContext<ScheduleRules>();

  return (
    <>
      <div className={styles.row}>
        <FormLabel required={true}>Delivery Interval</FormLabel>

        <div className={styles["lag-input"]}>
          <Controller
            name="intraday.custom.deliveryInterval.value"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id={name}
                name={name}
                type="number"
                label=""
                placeholder="number"
                value={`${value}`}
                optional={false}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="intraday.custom.deliveryInterval.unit"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <FormSelect
                id={name}
                name={name}
                label=""
                value={
                  DELIVERY_INTERVAL_UNITS.find(o => o.value === value) || null
                }
                isClearable={false}
                placeholder="unit"
                options={DELIVERY_INTERVAL_UNITS}
                onChange={(formSelectOnChangeParam: any) => {
                  onChange(
                    extractFormSelectOnChangeValue(formSelectOnChangeParam)
                  );
                }}
                classNamePrefix="modalFormSelect"
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Controller
          name="intraday.custom.firstDelivery"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              id={name}
              name={name}
              type="time"
              label="First Delivery"
              placeholder="First Delivery"
              value={value}
              optional={false}
              onChange={({ value: newValue }: CustomChangeEvent) => {
                onChange(newValue);
              }}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="intraday.custom.lastDelivery"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              id={name}
              name={name}
              type="time"
              label="Last Delivery"
              placeholder="Last Delivery"
              value={value}
              optional={false}
              onChange={({ value: newValue }: CustomChangeEvent) => {
                onChange(newValue);
              }}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="intraday.custom.timezone"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <FormLabel required={true}>Timezone</FormLabel>
              <TimezoneField
                value={value}
                onChange={onChange}
                nefStyle={true}
              />
            </>
          )}
        />
      </div>
    </>
  );
};

export default IntradayView;
