import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useMutation } from "jsonapi-react";
import { decamelizeKeys } from "humps";

import { uploadProductSupportingDocument } from "../../../../api/api";
import Toast from "../../../Toast";
import {
  NewProductSupportingDocument,
  NewProductSupportingDocumentPayload as NewDocPayload,
  SupportingDocument
} from "../../../../api/types";

type WithId<T extends {}> = T & {
  id: string;
};

function useSupportingDocsUpload(
  productId: string,
  onSuccess: (doc: SupportingDocument) => void = () => {},
  maxFiles = 1
) {
  const [createProductSupportingDoc] = useMutation<
    WithId<NewProductSupportingDocument>
  >(["product-supporting-documents"], {
    invalidate: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const createSupportingDocEntry: (
    fileName: string
  ) => Promise<SupportingDocument> = async (fileName: string) => {
    const newDoc: NewDocPayload = {
      name: fileName,
      documentType: "OTHER",
      private: false,
      product: {
        id: productId
      }
    };

    const normalizedProductSupportingDoc = decamelizeKeys(newDoc, {
      separator: "-"
    });

    const { data } = await createProductSupportingDoc(
      normalizedProductSupportingDoc
    );

    if (!data) {
      throw new Error("Document cannot be created");
    }

    return data;
  };

  const uploadFile = useCallback(
    async (file: File) => {
      setIsLoading(true);
      try {
        const productSupportingDoc = await createSupportingDocEntry(file.name);
        if (!productSupportingDoc?.id) {
          throw new Error("Could not create supporting doc entry");
        }

        const body = new FormData();
        body.append("file", file);

        const { data: supportingDocument } =
          await uploadProductSupportingDocument(productSupportingDoc.id, body);

        onSuccess({
          ...productSupportingDoc,
          supportingDocument
        });
      } catch (e) {
        toast(
          <Toast
            type="error"
            title="File upload failed"
            details={[
              {
                message: `Your file(s) could not be submitted. ${
                  e instanceof Error && e.message
                }`
              }
            ]}
          />
        );
      } finally {
        setIsLoading(false);
      }
    },
    [createSupportingDocEntry]
  );

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    try {
      await uploadFile(acceptedFiles[0]);

      toast(
        <Toast
          type="info"
          title="Upload Succeeded"
          details={[
            {
              message: "Documentation is uploaded"
            }
          ]}
        />
      );
      return true;
    } catch (e) {
      toast(
        <Toast
          type="error"
          title="Upload Failed"
          details={[
            {
              message: "Documentation cannot be uploaded"
            }
          ]}
        />
      );
      return false;
    }
  }, []);

  const onDropRejected = (fileRejections: FileRejection[]) => {
    toast(
      <Toast
        type="error"
        title="File upload failed"
        details={fileRejections.map(e => ({
          message: e.errors.map(v => v.message).join(", ")
        }))}
      />
    );
  };

  const { getRootProps, isDragActive, getInputProps, open } = useDropzone({
    noDragEventsBubbling: true,
    onDropAccepted,
    onDropRejected,
    noClick: true,
    maxFiles,
    multiple: maxFiles > 1
  });

  return { isLoading, getRootProps, isDragActive, open, getInputProps };
}

export default useSupportingDocsUpload;
