import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { StepProps } from "./types";

interface RouterNavigatorProps {
  children: (props: {
    navigateTo: (path: string, replace?: boolean) => void;
    matchStep: (step: StepProps) => boolean;
  }) => JSX.Element;
}

const RouterNavigator = ({ children }: RouterNavigatorProps) => {
  const location = useLocation();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const root = routeMatch.url;

  const navigateTo = (path: string, replace = false) => {
    const toPath = `${root}/${path}`;

    if (replace) {
      history.replace(toPath);
    } else {
      history.push(toPath);
    }
  };

  const matchStep = (step: StepProps) => {
    return `${root}/${step.path}` === location.pathname;
  };

  return children({ navigateTo, matchStep });
};

export default RouterNavigator;
