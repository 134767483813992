import React from "react";
import {
  ITimezone,
  useTimezoneSelect,
  allTimezones
} from "react-timezone-select";

import styles from "../Scheduler.module.scss";

interface TimezoneFieldProps {
  value: ITimezone;
  onChange: (value: string) => void;
  nefStyle?: boolean;
}

// replace default "America/Detroit" with "America/New_York" for Eastern Time
const { "America/Detroit": _, ...restTimezones } = allTimezones;
const TIMEZONES = {
  ...restTimezones,
  "America/New_York": "Eastern Time"
};

const TimezoneField = ({
  value,
  onChange,
  nefStyle = false
}: TimezoneFieldProps) => {
  const { options, parseTimezone } = useTimezoneSelect({
    timezones: TIMEZONES
  });

  const className = [styles.input, nefStyle && styles["input--nef"]]
    .filter(v => !!v)
    .join(" ");

  return (
    <select
      id="Timezone"
      className={className}
      onChange={e => onChange(parseTimezone(e.currentTarget.value).value)}
      value={parseTimezone(value).value}
    >
      <option value=""> </option>
      {options.map(option => {
        return (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
};

export default TimezoneField;
