import React, { useCallback, useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useQuery } from "jsonapi-react";

import { OrganizationProducts } from "../../../../api/types";
import ProductDrawer from "../product-drawer";
import ProductAccessModal from "../product-access-modal";
import EnableDisableProductConfirmationModal from "../product-drawer/modal/enable-disable-product-confirmation";

import styles from "./index.module.scss";

interface ProductTableProps {
  hasAdminAccess?: boolean;
  organizationId: string;
  organizationName?: string;
  organizationType?: string;
}

type SortParam =
  | "product_name"
  | "-product_name"
  | "subscription_type"
  | "-subscription_type"
  | "updated_at"
  | "-updated_at"
  | "users_count"
  | "-users_count";

const generateRequestBody = (organizationId: string, sortParam: SortParam) => {
  const requestBody: Record<string, string> = {};
  requestBody["filter[organization_id]"] = organizationId;

  if (sortParam.trim() !== "") {
    requestBody.sort = sortParam;
  }

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const columnHelper = createColumnHelper<OrganizationProducts>();

const ProductsTable = ({
  hasAdminAccess,
  organizationId,
  organizationName,
  organizationType
}: ProductTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortParam, setSortParam] = useState<SortParam>("product_name");

  const [isNewProdModalOpen, setNewProdModalOpen] = useState(false);

  const [selectedProduct, setSelectedProduct] =
    useState<null | OrganizationProducts>(null);
  const isEnableDisableProductConfirmationModalOpen = selectedProduct !== null;

  const [productToEdit, setProductToEdit] =
    useState<null | OrganizationProducts>(null);
  const [productAccessToEdit, setProductAccessToEdit] =
    useState<null | OrganizationProducts>(null);

  const ascending = !sortParam.startsWith("-");
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";
  const sortBy = ascending ? sortParam : sortParam.substring(1);

  const updateSortParam = useCallback(
    (newSortBy: SortParam) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as SortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const { data: organizationProducts, refetch } = useQuery<
    OrganizationProducts[]
  >([
    "organization-team-invoices",
    generateRequestBody(organizationId, sortParam)
  ]);

  const columns = [
    columnHelper.accessor("productName", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]} data-testid={info.getValue()}>
          <span className={styles.values}>
            {info.getValue() || <em>Invite sent</em>}
          </span>
        </div>
      ),
      header: () => {
        return (
          <div
            className={sortBy === "product_name" ? styles["sorter-active"] : ""}
          >
            <button
              type="button"
              onClick={() => updateSortParam("product_name")}
              onKeyPress={() => updateSortParam("product_name")}
              tabIndex={-1}
            >
              Name
            </button>
            {sortBy === "product_name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("subscriptionType", {
      id: "subscriptionType",
      cell: info => <div className={styles.center}>{info.getValue()}</div>,
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "subscription_type" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("subscription_type")}
              onKeyPress={() => updateSortParam("subscription_type")}
            >
              Subscription Type
            </button>
            {sortBy === "subscription_type" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("usersCount", {
      id: "usersCount",
      cell: info => {
        const usersCount =
          info.row.original.productAccess.userGroups.length +
          info.row.original.productAccess.users.length;

        return (
          <div className={styles.center}>
            <button
              data-testid={info.row.original.id}
              type="button"
              onKeyPress={() => {
                setProductAccessToEdit(info.row.original);
              }}
              onClick={() => {
                setProductAccessToEdit(info.row.original);
              }}
              className={styles["users-button"]}
            >
              {usersCount}
            </button>
          </div>
        );
      },
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "users_count" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("users_count")}
              onKeyPress={() => updateSortParam("users_count")}
            >
              Number of Users
            </button>
            {sortBy === "users_count" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("updatedAt", {
      id: "updatedAt",
      cell: info => {
        const val = info.getValue();
        if (val !== undefined) {
          return (
            <div className={styles.center}>
              {new Intl.DateTimeFormat("en-US").format(new Date(val))}
            </div>
          );
        }
        return "";
      },
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "updated_at" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("updated_at")}
              onKeyPress={() => updateSortParam("updated_at")}
            >
              Last Updated
            </button>
            {sortBy === "updated_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("id", {
      id: "id",
      cell: info =>
        info.getValue() ? (
          <div className={styles["user-actions"]}>
            {hasAdminAccess && (
              <>
                <Button
                  outline
                  color={info.row.original.enabled ? "light" : "success"}
                  size="sm"
                  className={styles["action-buttons"]}
                  onClick={() => {
                    setSelectedProduct(info.row.original);
                  }}
                  data-testid="productsTable_disable"
                >
                  {info.row.original.enabled ? "Disable" : "Enable"}
                </Button>
              </>
            )}
            <Button
              size="sm"
              outline
              className={styles["action-buttons"]}
              data-testid="productsTable_edit"
              onClick={() => {
                setProductToEdit(info.row.original);
              }}
              disabled={!info.row.original.enabled}
            >
              Edit
            </Button>
          </div>
        ) : null,
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: organizationProducts || [],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={styles.container} data-testid="productsTable">
      {productToEdit && (
        <ProductDrawer
          organizationId={organizationId}
          organizationName={organizationName}
          organizationType={organizationType}
          organizationTeamInvoiceId={productToEdit.id}
          refetch={refetch as () => void}
          handleClose={() => setProductToEdit(null)}
        />
      )}
      {productAccessToEdit && (
        <ProductAccessModal
          organizationId={organizationId}
          organizationName={organizationName}
          organizationType={organizationType}
          organizationTeamInvoiceId={productAccessToEdit.id}
          refetch={refetch as () => void}
          handleClose={() => setProductAccessToEdit(null)}
        />
      )}
      {hasAdminAccess && (
        <div className={styles.header}>
          <span>Products</span>
          <Button
            size="sm"
            outline
            onClick={() => setNewProdModalOpen(true)}
            disabled={!hasAdminAccess}
          >
            <FontAwesomeIcon iconClassName="fa-plus-circle" />
            Add Product
          </Button>
          {isNewProdModalOpen && (
            <ProductDrawer
              organizationId={organizationId}
              organizationName={organizationName as string}
              organizationType={organizationType}
              refetch={refetch as () => void}
              handleClose={() => setNewProdModalOpen(false)}
            />
          )}

          {isEnableDisableProductConfirmationModalOpen && (
            <EnableDisableProductConfirmationModal
              close={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
          )}
        </div>
      )}

      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} data-testid={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsTable;
