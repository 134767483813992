import React from "react";

const FileIcon = () => (
  <svg
    width="117"
    height="150"
    viewBox="0 0 117 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M107.297 149.22C112.102 149.22 116.024 145.306 116.024 140.493V33.8545C116.024 33.7918 115.992 33.7418 115.986 33.6865C115.967 33.4183 115.93 33.1501 115.831 32.9007C115.7 32.5887 115.512 32.3154 115.275 32.0837L84.5161 1.19876C84.2855 0.968155 84.011 0.781376 83.7116 0.656175C83.4121 0.532005 83.0824 0.463135 82.7516 0.463135L9.10265 0.464178C4.29761 0.464178 0.376221 4.37809 0.376221 9.19061V140.501C0.376221 145.313 4.29654 149.227 9.10265 149.227L107.295 149.226L107.297 149.22ZM5.36511 61.4158L111.037 61.4346V110.276H5.36511V61.4158ZM85.2514 8.99263L107.528 31.356H85.2514V8.99263ZM5.36511 9.19193C5.36511 7.12901 7.04193 5.45218 9.10485 5.45218H80.2668V33.8555C80.2668 35.2329 81.3886 36.3484 82.7597 36.3484H111.037V56.4495L7.39045 56.4505L5.36511 56.438V9.19193ZM5.36511 140.494V115.257H111.037V140.494C111.037 142.557 109.36 144.234 107.297 144.234H9.10485C7.04193 144.234 5.36511 142.557 5.36511 140.494Z"
      fill="#D1D1D1"
    />
  </svg>
);

export default FileIcon;
