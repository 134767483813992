import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Schedule } from "../../../../api/types";

import styles from "./Scheduler.module.scss";
import IntradayView from "./IntradayView";
import RecurringView from "./RecurringView";
import {
  DEFAULT_SCHEDULE_RULES,
  Freq,
  FREQUENCY_OPTIONS,
  isIntraday,
  isIntradayFreq,
  isNonRecurring,
  isNonRecurringFreq,
  isRecurring,
  isRecurringFreq,
  ScheduleRules
} from "./util";
import RecurringText from "./RecurringText";
import NonRecurringView from "./NonRecurringView";

interface FrequencyInputProps {
  value: Schedule;
  setValue: (newValue: Schedule) => void;
}

const scheduleToRules = (schedule: Schedule): ScheduleRules => {
  const rules = {
    ...DEFAULT_SCHEDULE_RULES
  };

  if (isRecurring(schedule)) {
    rules.recurring.dtstart = schedule.dtstart || rules.recurring.dtstart;
    rules.recurring.tzid = schedule.tzid;
    rules.recurring.until = schedule.until || undefined;
    rules.recurring.count = schedule.count;

    rules.recurring[schedule.freq].interval = Number(schedule.interval);
    rules.recurring[schedule.freq].byday = schedule.byday;
    rules.recurring[schedule.freq].bymonthday = schedule.bymonthday;
    rules.recurring[schedule.freq].bysetpos = schedule.bysetpos;
  }

  if (isIntraday(schedule)) {
    rules.intraday.custom = schedule.custom;
  }

  if (isNonRecurring(schedule)) {
    rules.nonRecurring.custom = schedule.custom;
  }

  return rules;
};

const modeAndRulesToSchedule = (freq: Freq, rules: ScheduleRules): Schedule => {
  if (isRecurringFreq(freq)) {
    return {
      freq,
      dtstart: rules.recurring.dtstart,
      tzid: rules.recurring.tzid,
      until: rules.recurring.until,
      count: rules.recurring.count,
      ...rules.recurring[freq]
    };
  }

  if (isIntradayFreq(freq)) {
    return {
      freq,
      custom: { ...rules.intraday.custom }
    };
  }

  return {
    freq,
    custom: { ...rules.nonRecurring.custom }
  };
};

/**
 * The Scheduler component works in the following way:
 * - reads initial `Schedule` value
 * - converts initial value to internal `mode` (ie. frequency options) and `rules` states
 * - maintains frequency-specific rules for easier UX when switching between different frequency options
 * - outputs `Schedule` value so only currently selected frequency/rules are recorded
 */

function Scheduler({ value, setValue }: FrequencyInputProps) {
  const [currentFreq, setCurrentFreq] = useState(value.freq);
  const scheduleRulesForm = useForm<ScheduleRules>({
    defaultValues: scheduleToRules(value)
  });

  const { watch } = scheduleRulesForm;
  const formValue = watch();

  // JSON.stringify() on shallow object for useEffect dependencies
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const currentSchedule = useMemo(() => {
    return modeAndRulesToSchedule(currentFreq, formValue);
  }, [currentFreq, JSON.stringify(formValue)]);

  useEffect(() => {
    setValue(currentSchedule);
  }, [JSON.stringify(currentSchedule)]);

  return (
    <div className={styles.grid}>
      <div className={styles.label}>Repeat</div>
      <select
        id="UpdateFrequency_Freq"
        className={styles.input}
        onChange={e => setCurrentFreq(e.currentTarget.value as Freq)}
        value={currentFreq}
      >
        {FREQUENCY_OPTIONS.map(option => {
          return (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <FormProvider {...scheduleRulesForm}>
        {isNonRecurringFreq(currentFreq) && <NonRecurringView />}
        {isIntradayFreq(currentFreq) && <IntradayView />}
        {isRecurringFreq(currentFreq) && <RecurringView freq={currentFreq} />}
        {isRecurring(currentSchedule) && (
          <RecurringText schedule={currentSchedule} />
        )}
      </FormProvider>
    </div>
  );
}

export default Scheduler;
