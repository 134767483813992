import { camelizeKeys } from "humps";

import {
  JSONAPIResponse,
  PaginationLinkFields,
  PaginationLinks,
  ResponseWithCursorPageInfo,
  CursorPageInfo
} from "../types";

interface PaginationMeta extends Record<string, any> {
  first: PaginationInfo;
  last: PaginationInfo;
  next: PaginationInfo;
  prev: PaginationInfo;
}

interface PaginationInfo {
  size: number;
  number: number;
}

const createPaginationInfo = (
  response: JSONAPIResponse<any> | ResponseWithCursorPageInfo<any>
) => {
  if (!response) return {};
  if (!Array.isArray(response.data)) return {};

  const responseLinks = (response as JSONAPIResponse<any>).links;
  if (responseLinks) {
    return createPaginationInfoFromJsonApi(responseLinks);
  }

  const responsePageInfo = (response as ResponseWithCursorPageInfo<any>)
    .page_info;
  if (responsePageInfo) {
    const pageInfo = camelizeKeys(responsePageInfo) as CursorPageInfo;
    return createPaginationInfoFromPageInfo(pageInfo);
  }

  return {};
};

const createPaginationInfoFromJsonApi = (links: PaginationLinks) => {
  const pagination: Partial<PaginationMeta> = {};

  Object.keys(links).forEach(type => {
    const link = links[type as PaginationLinkFields];
    const url = new URL(link);
    const urlParams = url.searchParams;

    const pageSize = parseInt(urlParams.get("page[size]") as string, 10);
    const pageNumber = parseInt(urlParams.get("page[number]") as string, 10);

    pagination[type] = {
      size: pageSize,
      number: pageNumber
    };
  });

  return pagination;
};

const createPaginationInfoFromPageInfo = (pageInfo: CursorPageInfo) => {
  return pageInfo;
};

export default createPaginationInfo;
