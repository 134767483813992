const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

const capitalizeWords = (s: string) => {
  const words = s.split(" ");
  const capitalizedWords = words.map(w => capitalize(w)).join(" ");

  return capitalizedWords;
};

export { capitalize, capitalizeWords };
