import React, { useCallback, useState } from "react";
import { Button, FormField, Modal, ModalBody, ModalHeader } from "@nef/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "jsonapi-react";
import { startCase, debounce } from "lodash";

import manageClient from "../../../../api/manage/client";
import Toast from "../../../../components/Toast";
import FormFieldLabel from "../../../../components/modals/components/FormFieldLabel";

import styles from "./index.module.scss";

interface AddUserRequest {
  organizationId: string;
  inviteEmail: string;
}

interface AddUserModalProps {
  organizationId: string;
  close: () => void;
}

const userRequestSchema = yup.object({
  organizationId: yup
    .string()
    .required("Organization name is a required field"),
  inviteEmail: yup
    .string()
    .email("Email needs to be a valid email")
    .required("Email contact is a required field")
});

const AddUserModal = ({ organizationId, close }: AddUserModalProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = useForm({
    mode: "onChange",
    defaultValues: {
      organizationId: "",
      inviteEmail: ""
    },
    resolver: yupResolver(userRequestSchema)
  });
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  setValue("organizationId", organizationId);

  const [validateData] = useMutation(["organization-users", "validate"], {
    client: manageClient,
    invalidate: false
  });

  const validateFieldUniquenessAPI = async (
    field: "inviteEmail",
    value: string
  ) => {
    try {
      userRequestSchema.validateSyncAt(field, { [field]: value });
    } catch (err) {
      return;
    }

    const { errors } = await validateData({
      "invite-email": value,
      "organization-id": organizationId
    });

    const fieldHasBeenTaken = errors?.filter(
      error =>
        error.title.includes("already been added") &&
        error.source.pointer.includes("invite-email")
    );

    if (fieldHasBeenTaken) {
      setError(field, {
        type: "custom",
        message: `${startCase(field)} has already been added`
      });
    }
  };

  const debounceUserInviteValidation = useCallback(
    debounce(validateFieldUniquenessAPI, 300),
    []
  );
  const [createUserRequest] = useMutation(["organization-users"], {
    client: manageClient
  });

  const onSubmit: SubmitHandler<AddUserRequest> = useCallback(async data => {
    setIsSaveLoading(true);
    const normalizedUserRequest: any = decamelizeKeys(data, {
      separator: "-"
    });

    const response = await createUserRequest(normalizedUserRequest);

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) {
        setIsSaveLoading(false);
        return;
      }

      toast(
        <Toast
          type="error"
          title="There was an error creating the user"
          details={[]}
        />
      );

      setIsSaveLoading(false);
      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully created"
        details={[]}
      />
    );

    setIsSaveLoading(false);
    handleClose();
  }, []);

  const handleClose = () => {
    close();
    reset();
  };

  return (
    <Modal
      isOpen
      data-testid="OrganizationsAddUserModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="New User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        New User
      </ModalHeader>
      <ModalBody className={styles["remove-margin"]}>
        <FormFieldLabel
          title="Email"
          tooltip="Please submit an email address"
        />
        <Controller
          name="inviteEmail"
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <FormField
              id="inviteEmail"
              name={name}
              type="email"
              placeholder=""
              value={value}
              onChange={event => {
                debounceUserInviteValidation(
                  "inviteEmail",
                  event.value?.toString() || ""
                );
                onChange(event);
              }}
              data-testid="OrganizationsAddUserModal_email"
              invalid={!!formErrors.inviteEmail}
              feedback={formErrors?.inviteEmail?.message}
              onBlur={onBlur}
            />
          )}
        />
        <div className={styles.buttons}>
          <Button
            onClick={handleSubmit(onSubmit)}
            isLoading={isSaveLoading}
            disabled={
              isSaveLoading ||
              !isDirty ||
              (!isValid && Object.keys(formErrors).length !== 0)
            }
            data-testid="OrganizationsAddUserModal_submit"
          >
            Create New User
          </Button>
          <Button
            color="light"
            outline
            onClick={handleClose}
            data-testid="OrganizationsAddUserModal_cancel"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUserModal;
