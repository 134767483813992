import React, { useCallback, useState } from "react";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useQuery } from "jsonapi-react";

import { OrganizationTeam } from "../../../../api/types";
import RemoveUserGroupModal, { UserGroup } from "../remove-user-group-modal";
import AddUserGroupModal from "../add-user-group-modal";
import ManageUsersModal from "../manage-users-modal";
import UserProductAccessModal from "../user-product-access-modal";
import ProductDrawer from "../product-drawer";

import styles from "./index.module.scss";

type SortParam =
  | "name"
  | "-name"
  | "created_at"
  | "-created_at"
  | "users_count"
  | "-users_count"
  | "products_count"
  | "-products_count";

const generateRequestBody = (organizationId: string, sortParam: SortParam) => {
  const requestBody: Record<string, string | boolean> = {};
  requestBody["filter[organization_id]"] = organizationId;
  requestBody["filter[is_single_user_team]"] = false;
  requestBody["filter[is_default_team]"] = false;
  if (sortParam.trim() !== "") {
    requestBody.sort = sortParam;
  }

  return Object.keys(requestBody).length === 0 ? "" : requestBody;
};

const columnHelper = createColumnHelper<OrganizationTeam>();

interface UsersTableProps {
  organizationId: string;
  organizationName?: string;
  organizationType?: string;
}

const UserGroupsTable = ({
  organizationId,
  organizationName,
  organizationType
}: UsersTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortParam, setSortParam] = useState<SortParam>("name");

  const [isAddUserGroupModalOpen, setIsAddUserGroupModalOpen] = useState(false);

  const [userGroupToEdit, setUserGroupToEdit] =
    useState<null | OrganizationTeam>(null);
  const isEditUserGroupModalOpen = userGroupToEdit !== null;

  const [userGroupToDelete, setUserGroupToDelete] =
    useState<null | OrganizationTeam>(null);
  const isDeleteUserGroupModalOpen = userGroupToDelete !== null;

  const [
    userGroupProductModalToEditOrDelete,
    setUserGroupProductModalToEditOrDelete
  ] = useState<null | OrganizationTeam>(null);
  const isEditOrDeleteUserGroupProductModalOpen =
    userGroupProductModalToEditOrDelete !== null;

  const [productToEdit, setProductToEdit] = useState<null | {
    id: string;
    name: string;
  }>(null);

  const { data: organizationTeams, refetch } = useQuery<OrganizationTeam[]>([
    "organization-teams",
    generateRequestBody(organizationId, sortParam)
  ]);
  const ascending = !sortParam.startsWith("-");
  const icon = ascending ? "fa-caret-up" : "fa-caret-down";
  const sortBy = ascending ? sortParam : sortParam.substring(1);

  const updateSortParam = useCallback(
    (newSortBy: SortParam) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as SortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy]
  );

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          <span className={styles.values}>
            {info.getValue() || <em>Invite sent</em>}
          </span>
        </div>
      ),
      header: () => {
        return (
          <div className={sortBy === "name" ? styles["sorter-active"] : ""}>
            <button
              type="button"
              onClick={() => updateSortParam("name")}
              onKeyPress={() => updateSortParam("name")}
              tabIndex={-1}
            >
              Name
            </button>
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("createdAt", {
      id: "dateCreated",
      cell: info => {
        const val = info.getValue();
        if (val !== undefined) {
          return new Intl.DateTimeFormat("en-US").format(new Date(val));
        }
        return "";
      },
      header: () => {
        return (
          <div
            className={sortBy === "created_at" ? styles["sorter-active"] : ""}
          >
            <button
              type="button"
              onClick={() => updateSortParam("created_at")}
              onKeyPress={() => updateSortParam("created_at")}
            >
              Created On
            </button>
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("usersCount", {
      id: "users",
      cell: info => (
        <button
          type="button"
          tabIndex={-1}
          className={styles["users-button"]}
          onClick={() => {
            setUserGroupToEdit(info.row.original);
          }}
          onKeyPress={() => {
            setUserGroupToEdit(info.row.original);
          }}
        >
          {info.getValue()}
        </button>
      ),
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "users_count" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("users_count")}
              onKeyPress={() => updateSortParam("users_count")}
            >
              Number of Users
            </button>
            {sortBy === "users_count" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("productsCount", {
      id: "products",
      cell: info => (
        <button
          type="button"
          tabIndex={-1}
          className={styles["users-button"]}
          onKeyPress={() => {
            setUserGroupProductModalToEditOrDelete(info.row.original);
          }}
          onClick={() => {
            setUserGroupProductModalToEditOrDelete(info.row.original);
          }}
        >
          {info.getValue()}
        </button>
      ),
      header: () => {
        return (
          <div
            className={`${styles.center} ${
              sortBy === "products_count" ? styles["sorter-active"] : ""
            }`}
          >
            <button
              type="button"
              onClick={() => updateSortParam("products_count")}
              onKeyPress={() => updateSortParam("products_count")}
            >
              Products
            </button>
            {sortBy === "products_count" && (
              <FontAwesomeIcon
                iconClassName={icon}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("id", {
      id: "id",
      cell: info =>
        info.getValue() ? (
          <div className={styles["user-actions"]}>
            <Button
              outline
              color="danger"
              size="sm"
              className={styles["table-delete-button"]}
              data-testid="organizationsUserGroupsTable_deleteUser"
              onClick={() => {
                setUserGroupToDelete(info.row.original);
              }}
            >
              Delete
            </Button>
            <Button
              size="sm"
              outline
              onClick={() => {
                setUserGroupToEdit(info.row.original);
              }}
              data-testid="organizationsUserGroupsTable_manageUsers"
            >
              <FontAwesomeIcon
                className={styles["add-icon"]}
                iconClassName="fa-plus-circle"
              />
              Manage Users
            </Button>
          </div>
        ) : null,
      header: () => {
        return null;
      }
    })
  ];
  const table = useReactTable({
    data: organizationTeams || [],
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={styles.container} data-testid="usersTable">
      {isDeleteUserGroupModalOpen && (
        <RemoveUserGroupModal
          close={() => setUserGroupToDelete(null)}
          userGroup={userGroupToDelete as UserGroup}
        />
      )}

      {isEditUserGroupModalOpen && (
        <ManageUsersModal
          close={() => setUserGroupToEdit(null)}
          userGroup={userGroupToEdit as UserGroup}
          organizationId={organizationId}
        />
      )}

      {isEditOrDeleteUserGroupProductModalOpen && (
        <UserProductAccessModal
          table="userGroupsTable"
          organizationUserGroupId={userGroupProductModalToEditOrDelete.id}
          setProductToEdit={setProductToEdit}
          refetch={refetch as () => void}
          handleClose={() => setUserGroupProductModalToEditOrDelete(null)}
        />
      )}

      {productToEdit && (
        <ProductDrawer
          organizationId={organizationId as string}
          organizationName={organizationName}
          organizationType={organizationType}
          organizationTeamInvoiceId={productToEdit.id}
          refetch={() => {}}
          handleClose={() => setProductToEdit(null)}
        />
      )}

      <div className={styles.header}>
        <span>Users Groups</span>
        <Button
          size="sm"
          outline
          onClick={() => setIsAddUserGroupModalOpen(true)}
        >
          <FontAwesomeIcon iconClassName="fa-plus-circle" />
          New User Group
        </Button>
        {isAddUserGroupModalOpen && (
          <AddUserGroupModal
            close={() => setIsAddUserGroupModalOpen(false)}
            organizationId={organizationId}
          />
        )}
      </div>

      <div className={styles["table-container"]}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      header.column.getIsSorted() ? styles.selected : ""
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon iconClassName="fa-caret-up" />,
                      desc: <FontAwesomeIcon iconClassName="fa-caret-down" />
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                data-testid={`organization-user-groups-table-row-${row.id}`}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    data-testid={`organization-user-groups-table-${cell.column.id}-${row.id}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserGroupsTable;
