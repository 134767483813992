import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@nef/core";

import CapSurveyFormFieldRow from "../../../../cap-survey-form/CapSurveyFormFieldRow";
import useCapProductContext from "../../../../../hooks/useCapProductContext";

const NdlUploaderVariant = () => {
  const { product } = useCapProductContext();

  return (
    <>
      <CapSurveyFormFieldRow tooltip="">
        <Link
          to={`/publishers/${product?.vendor?.id}/file`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>NDL UPLOADER</Button>
        </Link>
      </CapSurveyFormFieldRow>
    </>
  );
};

export default NdlUploaderVariant;
