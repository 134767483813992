import React from "react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";

type Props = {
  data: any[];
};

const Table: React.FC<Props> = ({ data }) => {
  const columnHelper = createColumnHelper();

  const columns: ColumnDef<unknown, unknown>[] = [];

  const properties = data && data.length > 0 ? Object.keys(data[0]) : [];

  properties?.forEach((property: any) => {
    const column = columnHelper.accessor(property, {
      cell: info => info.renderValue()
    });

    columns.push(column);
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className="data-visualization__table-container">
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              <th aria-hidden key="empty-top-left-th" />

              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
              <th
                key="empty-th-for-less-columns"
                aria-hidden
                className="empty-cell"
              />
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className={row.getIsSelected() ? "selected" : ""}>
              <td key={row.id}>{parseInt(row.id, 10) + 1}</td>

              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}

              <td key="empty-td-for-less-columns" className="empty-cell" />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
