import axios, { AxiosResponse } from "axios";
import { camelizeKeys } from "humps";
import { nanoid } from "nanoid";

import UserService from "../services/user";
import LocalStorageBuilder from "../services/LocalStorageBuilder";

import { User } from "./types";

const client = axios.create({
  baseURL: "/manage",
  headers: {
    Accept: "application/json"
  }
});

client.interceptors.response.use((response: AxiosResponse) => {
  if (response.data) response.data = camelizeKeys(response.data);

  return response;
});

export const loginUser = (email: string, password: string) => {
  return client.post<User>("/users/sign_in", {
    session: {
      account: email,
      password
    }
  });
};

export const resetPassword = (email: string) => {
  return client.post<User>("/users/passwords", {
    session: {
      account: email
    }
  });
};

export const setupSkillUrl = (skillId: string, url: string) => {
  return client.post(
    "/skill/bind_service",
    {
      skill_id: skillId,
      url
    },
    {
      headers: {
        "X-API-Token": UserService.getUser()?.apiKey || ""
      }
    }
  );
};

export const fetchUserSession = () => {
  return client({
    baseURL: "/api/v3",
    url: "/users/me",
    headers: {
      "Request-Source": "next",
      "X-Requested-With": "XMLHttpRequest",
      "X-API-Token": LocalStorageBuilder("user").get()?.api_key
    }
  });
};

export const fetchVendors = () => {
  return client({
    baseURL: "/api/v3",
    url: "/vendors",
    headers: {
      "Request-Source": "next",
      "X-Requested-With": "XMLHttpRequest"
    }
  });
};

export const inviteUserAccept = (token: string) => {
  return client.post(`/vendor-users/${token}/accept`, null, {
    headers: {
      "Request-Source": "next",
      "X-Requested-With": "XMLHttpRequest",
      "X-API-Token": LocalStorageBuilder("user").get()?.api_key
    }
  });
};

export const uploadProductSupportingDocument = (id: string, body: FormData) => {
  return client.post(`/product-supporting-documents/${id}/upload`, body, {
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const getUploadEvents = (
  vendorCode: string,
  datatableCode: string,
  datatableVersion: string
) => {
  return client.get(
    `/datatable_schema/${vendorCode}/${datatableCode}/${datatableVersion}/events`,
    {
      headers: {
        "X-API-Token": UserService.getUser()?.apiKey || ""
      }
    }
  );
};

export const testDataLocationConnect = (
  vendorCode: string,
  datatableCode: string,
  datatableVersion: string
) => {
  return client.post(
    `/datatable_schema/${vendorCode}/${datatableCode}/${datatableVersion}/test_connection`,
    {},
    {
      headers: {
        "X-API-Token": UserService.getUser()?.apiKey || ""
      }
    }
  );
};

export const getDatatableData = (vendorCode: string, datatableCode: string) => {
  return client({
    baseURL: "/api/v3",
    url: `/datatables/${vendorCode}/${datatableCode}?qopts.per_page=5`,
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || "",
      "X-USER-ID": UserService.getUser()?.id || ""
    }
  });
};

export const getUploadFileLink = (
  file: File,
  path: string,
  vendorId: string,
  randomizeFileName = false
) => {
  const fileName = randomizeFileName ? `${nanoid()}_${file.name}` : file.name;
  return client
    .get(`/upload?vendor_id=${vendorId}&path=/${path.slice(1)}${fileName}`, {
      responseType: "text",
      headers: {
        "X-API-Token": UserService.getUser()?.apiKey || ""
      }
    })
    .then(response => {
      client.put(response.data, file);
      return fileName;
    })
    .catch(e => {
      const msg = e instanceof Error ? e.message : "";
      throw new Error(`failed to upload file ${file.name} - ${msg}`);
    });
};

export const getDownloadFileLink = (path: string, vendorId: string) => {
  return client.get(`/upload/download?vendor_id=${vendorId}&path=${path}`, {
    responseType: "text",
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const deleteDirectoryOrFile = (path: string, vendorId: string) => {
  return client.delete(`/upload?vendor_id=${vendorId}&path=${path}`, {
    responseType: "text",
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const createDirectory = (path: string, vendorId: string) => {
  return client
    .get(`/upload/directory?vendor_id=${vendorId}&path=${path}`, {
      responseType: "text",
      headers: {
        "X-API-Token": UserService.getUser()?.apiKey || ""
      }
    })
    .then(response => {
      client.put(response.data, null);
    })
    .catch(e => {
      const msg = e instanceof Error ? e.message : "";
      throw new Error(`failed to create folder ${msg}`);
    });
};

export const getProductCode = () => {
  return client.get("products/code", {
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const getTableData = (
  code: string,
  rowNum: number,
  nextCursorId = ""
) => {
  let payload;
  if (nextCursorId !== "") {
    payload = {
      "qopts.per_page": rowNum,
      "qopts.cursor_id": nextCursorId,
      api_key: UserService.getUser()?.apiKey
    };
  } else {
    payload = {
      "qopts.per_page": rowNum,
      api_key: UserService.getUser()?.apiKey
    };
  }
  return client.get(`/api/v3/datatables/${code}`, { params: payload });
};

export const getProductSupportingDocuments = (documentId: number) => {
  return client
    .get(`/api/v3/product-supporting-documents/${documentId}`)
    .then(rawData => {
      return rawData.data;
    });
};

export const getDataTableMeta = (productCode: string) => {
  return client.get(`/api/v3/datatables/${productCode}/metadata`, {
    params: {
      graphable: true,
      api_key: UserService.getUser()?.apiKey
    }
  });
};

export const createOrganizationOnboarding = (body: any) => {
  return client.post("/organization_onboarding/submit", body, {
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const updateProductAccess = (body: any, id: string) => {
  return client.patch(
    `/organization-team-invoices/${id}/update_product_access`,
    body,
    {
      headers: {
        "X-API-Token": UserService.getUser()?.apiKey || ""
      }
    }
  );
};

export const updateUserAccess = (body: any, id: string) => {
  return client.patch(`/organization-users/${id}/update_user_access`, body, {
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const updateOrganizationUser = (id: string, body: any) => {
  return client.patch(`/organization-users/${id}/update_user_access`, body, {
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const updateUserGroupAccess = (body: any, id: string) => {
  return client.patch(`/organization-teams/${id}/update_team_access`, body, {
    headers: {
      "X-API-Token": UserService.getUser()?.apiKey || ""
    }
  });
};

export const regenerateApiKey = (id: string) => {
  return client.patch(
    `/organization-users/${id}/update_api_key`,
    {},
    {
      headers: {
        "X-API-Token": UserService.getUser()?.apiKey || ""
      }
    }
  );
};
