import React, { useState } from "react";
import { FontAwesomeIcon } from "@nef/core";
import { generatePath, Link, Redirect, useParams } from "react-router-dom";
import { useQuery } from "jsonapi-react";

import { PATHS } from "../../../routes";
import manageClient from "../../../api/manage/client";
import useDefaultAuth from "../../../hooks/useDefaultAuth";
import { Organization } from "../../../api/types";
import useQueryParam from "../../../hooks/useQueryParam";

import AdminTable from "./admin-table";
import styles from "./index.module.scss";
import UsersTable from "./users-table";
import UserGroupsTable from "./user-groups-table";
import ProductsTable from "./products-table";

const EditOrganizationPage = () => {
  const queryParams = useQueryParam();
  const { organizationId } = useParams<{ organizationId: string }>();

  const [activeTab, setActiveTab] = useState<
    "products" | "users" | "user-groups"
  >(
    queryParams.get("tab") === "users"
      ? "users"
      : queryParams.get("tab") === "user-groups"
      ? "user-groups"
      : "products"
  );

  const { data: organization } = useQuery<Organization>(
    ["organizations", organizationId],
    {
      client: manageClient
    }
  );

  const { isManagerAdmin, isOrganizationUser } = useDefaultAuth();

  const dateFormatCa = new Intl.DateTimeFormat("en-US");
  const createdOn = organization?.createdAt
    ? dateFormatCa.format(new Date(organization.createdAt))
    : "";

  if (!isManagerAdmin() && !isOrganizationUser()) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <header className={styles.header}>
          <Link
            to={generatePath(PATHS.ORGANIZATIONS)}
            className={styles["back-link"]}
          >
            <FontAwesomeIcon iconClassName="fa-arrow-left" />
            Back to Organizations
          </Link>
        </header>
        <div className={styles["title-container"]}>
          <div className={styles["header-button"]}>
            <p className={styles.header}>
              {organization?.name || "Publisher Name"}
            </p>
          </div>

          <div className={styles["sub-title-container"]}>
            <span data-testid="editOrganization_createdOn">
              <strong>Created On:</strong> {createdOn}
            </span>
            <span data-testid="editOrganization_accountType">
              <strong>Account Type:</strong>{" "}
              {organization?.organizationType || ""}
            </span>
          </div>
        </div>

        <AdminTable organizationId={organizationId} />

        <div className={styles["tabs-container"]}>
          <button
            data-testid="productsTab"
            className={activeTab === "products" ? styles.active : ""}
            type="button"
            onClick={() => setActiveTab("products")}
          >
            Products
          </button>
          <button
            data-testid="usersTab"
            className={activeTab === "users" ? styles.active : ""}
            type="button"
            onClick={() => setActiveTab("users")}
          >
            Users
          </button>

          <button
            data-testid="usersGroupsTab"
            className={activeTab === "user-groups" ? styles.active : ""}
            type="button"
            onClick={() => setActiveTab("user-groups")}
          >
            User Groups
          </button>
        </div>

        {activeTab === "products" && (
          <ProductsTable
            hasAdminAccess={isManagerAdmin()}
            organizationId={organizationId}
            organizationName={organization?.name}
            organizationType={organization?.organizationType}
          />
        )}
        {activeTab === "users" && (
          <UsersTable
            organizationId={organizationId}
            organizationName={organization?.name}
            organizationType={organization?.organizationType}
          />
        )}
        {activeTab === "user-groups" && (
          <UserGroupsTable
            organizationId={organizationId}
            organizationName={organization?.name}
            organizationType={organization?.organizationType}
          />
        )}
      </div>
    </div>
  );
};

export default EditOrganizationPage;
