import React, { useReducer } from "react";
import { Button } from "@nef/core";

import { NewPlan, PlanCategory, Plan, ProductFormMode } from "../../api/types";
import useHydratePlanCategories from "../../hooks/useHydratePlanCategories";
import useWizard from "../../hooks/useWizard";
import { useProductContext } from "../../hooks/useProductContext";

import styles from "./CurrentPricingPlansSection.module.scss";
import ProductStepHeader from "./components/ProductStepHeader";
import PlanCategoryCard from "./components/PlanCategoryCard";
import PlanModal from "./components/PlanModal";

type ActionTypes = "EXISTING_PLAN" | "NEW_PLAN" | "NO_PLAN";
type ActionPayload = { plan: NewPlan; planCategory: PlanCategory };

interface Action {
  type: ActionTypes;
  payload?: ActionPayload;
}

const openedPlanReducer = (
  _state: ActionPayload | null,
  action: Action
): ActionPayload | null => {
  switch (action.type) {
    case "EXISTING_PLAN":
      return action.payload || null;
    case "NEW_PLAN":
      return action.payload || null;
    case "NO_PLAN":
      return null;
    default:
      return null;
  }
};

const CurrentPricingPlansSection = () => {
  const { navigateTo } = useWizard();
  const [openedPlan, dispatch] = useReducer(openedPlanReducer, null);
  const { product } = useProductContext(ProductFormMode.EDIT);

  const { planCategories } = useHydratePlanCategories(
    product.planCategories,
    product.active
  );

  const buildNewPlan = () => {
    const newPlan: NewPlan = {
      name: "",
      active: product.active,
      allowContactSales: false,
      allowPayment: true,
      amountCents: 0,
      currency: "usd",
      interval: "month",
      intervalCount: 1,
      filterLabels: { history: "Full history" }
    };

    return newPlan;
  };

  return (
    <div className={styles.container} data-testid="currentPricingPlans">
      <div className={styles["header-bar"]}>
        <ProductStepHeader size={1}>Pricing Plans</ProductStepHeader>
        {product.active && (
          <Button
            outline={true}
            onClick={() => navigateTo("plans/grandfathered")}
            data-testid="currentPricingPlans_showGrandfatheredPlans"
          >
            Grandfathered Plan
          </Button>
        )}
      </div>
      <div
        className={styles.categories}
        data-testid="currentPricingPlans_categories"
      >
        {planCategories?.map((category: PlanCategory) => {
          return (
            <PlanCategoryCard
              key={category.id}
              planCategory={category}
              openNewPlan={() =>
                dispatch({
                  type: "NEW_PLAN",
                  payload: {
                    plan: buildNewPlan(),
                    planCategory: category
                  }
                })
              }
              openExistingPlan={(plan: Plan) =>
                dispatch({
                  type: "EXISTING_PLAN",
                  payload: {
                    plan: plan as NewPlan,
                    planCategory: category
                  }
                })
              }
            />
          );
        })}
      </div>

      {openedPlan && (
        <PlanModal
          close={() => dispatch({ type: "NO_PLAN" })}
          planData={{
            plan: openedPlan.plan,
            planCategory: openedPlan.planCategory
          }}
        />
      )}
    </div>
  );
};

export default CurrentPricingPlansSection;
