import React, { useMemo, useEffect, useState } from "react";
import { FormSelect } from "@nef/core";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { at, difference, uniqueId } from "lodash";

import FilterControl from "../FilterControl";
import { formSchema } from "../../../schema";

import styles from "./style.module.scss";

type Filter = {
  key: string;
  type: string;
  operator: string;
  value: string;
  newValue?: string;
  numberValue?: string;
  newNumberValue?: string;
  dateValue?: string;
  newDateValue?: string;
};

export const mergeFilters = (filters: Filter[]): Filter[] => {
  const mergedFilters: Filter[] = [];

  filters.forEach(filter => {
    const existingFilter = mergedFilters.find(
      f =>
        f.key === filter.key &&
        (f.operator === "lt" ||
          f.operator === "gt" ||
          f.operator === "lte" ||
          f.operator === "gte")
    );

    if (existingFilter) {
      if (
        (existingFilter.operator === "lt" && filter.operator === "gt") ||
        (existingFilter.operator === "lte" && filter.operator === "gte")
      ) {
        existingFilter.operator = `${existingFilter.operator} / ${filter.operator}`;
        existingFilter.newValue = filter.value;

        if (filter.type === "Date") {
          existingFilter.newDateValue = filter.dateValue;
          existingFilter.newNumberValue = filter.numberValue;
        }
      }
    } else {
      mergedFilters.push({ ...filter });
    }
  });

  return mergedFilters;
};

const TabContent = ({
  formName,
  currentVersion
}: {
  formName: string;
  currentVersion: string;
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger
  } = useFormContext<yup.Asserts<typeof formSchema>>();
  const [filtersFormatted, setFiltersFormatted] = useState<boolean>();
  const datatable = getValues(formName as any);

  if (datatable === undefined) {
    return null;
  }

  const versionKey =
    currentVersion === "Default" ? datatable.defaultVersion : currentVersion;

  const columnFormKey = `${formName}.selectedColumns[${versionKey}]`;
  const diffColumnFormKey = `${formName}.diffColumns[${versionKey}]`;
  const filterFormKey = `${formName}.filters[${versionKey}]`;

  const filters = getValues(filterFormKey as any);

  useEffect(() => {
    const updatedFilters = mergeFilters(getValues(filterFormKey as any));
    setValue(filterFormKey as any, updatedFilters, { shouldTouch: true });
    setValue(
      diffColumnFormKey as any,
      datatable.columns.map((c: any) => c.name)
    );
    setFiltersFormatted(true);
  }, []);

  if (filters === undefined) {
    return null;
  }

  const { remove, append } = useFieldArray({
    control,
    name: filterFormKey as any
  });

  const columnTypeMap = useMemo(() => {
    const result: { [key: string]: string } = {};

    datatable?.columns.forEach((column: any) => {
      result[column.name] = column.type;
    });

    return result;
  }, [datatable]);

  return (
    <>
      {filtersFormatted && (
        <div className={styles["card-wrapper"]}>
          <Controller
            key={columnFormKey}
            control={control}
            name={columnFormKey as any}
            defaultValue={[]}
            render={({ field: { onChange, value, name } }) => {
              const columnNames = datatable.columns.map((c: any) => c.name);
              const v = value
                ?.map((vl: any) => ({ label: vl, value: vl }))
                .filter((d: any) => columnNames.includes(d.value));

              const remainingColumns = datatable.columns.filter(
                (column: { name: string; type: string }) =>
                  !v.find((c: { value: string }) => c.value === column.name)
              );

              return (
                <FormSelect
                  id={columnFormKey}
                  label="Column Exclude List"
                  name={name}
                  optional={true}
                  options={
                    remainingColumns.map((column: any) => ({
                      label: column.name,
                      value: column.name
                    })) || []
                  }
                  value={v}
                  onChange={({ value: internalValue }: any) => {
                    onChange(internalValue.map((vl: any) => vl.value));
                  }}
                  placeholder="Select"
                  data-testid={columnFormKey}
                  invalid={!!at(errors, columnFormKey)[0]}
                  feedback={at(errors, columnFormKey)[0]?.message as string}
                  isMulti={true}
                />
              );
            }}
          />
          <FormSelect
            key={`${filterFormKey}${uniqueId()}`}
            id={filterFormKey}
            label="Filterable Columns"
            optional={true}
            options={
              datatable?.filterable.map((column: any) => ({
                label: column,
                value: column
              })) || []
            }
            value={filters?.map((filter: { key: string }) => ({
              value: filter.key,
              label: filter.key
            }))}
            onChange={({ value: internalValue }: any) => {
              const filterKeys = filters?.map((v: any) => v.key);
              const changedFilterKeys = internalValue.map(
                (vl: any) => vl.value
              );

              const removedKeys = difference(filterKeys, changedFilterKeys);
              if (removedKeys.length !== 0) {
                const removedIndexes = removedKeys.map(v =>
                  filterKeys.indexOf(v)
                );
                remove(removedIndexes);
              }
              const addedKeys = difference(changedFilterKeys, filterKeys);
              if (addedKeys.length !== 0) {
                append(
                  addedKeys.map((key: any) => ({
                    key,
                    type: columnTypeMap[key],
                    operator: "",
                    value: ""
                  }))
                );
              }
              trigger(columnFormKey as any);
            }}
            placeholder="Select"
            data-testid={filterFormKey}
            isMulti={true}
          />

          <div className={styles.filters}>
            {filters.map(
              (
                filter: { key: string; type: string; operator: string },
                idx: number
              ) => (
                <FilterControl
                  key={`${filterFormKey}${filter.key}${filter.operator}`}
                  code={filter.key}
                  filterIndex={idx}
                  type={filter.type}
                  formName={filterFormKey}
                />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TabContent;
