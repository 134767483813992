import React from "react";
import { FontAwesomeIcon, Popover } from "@nef/core";

import styles from "./index.module.scss";

type Props = {
  datatableCode: string;
  openCopyDatatable: (code: string) => void;
  setPopover: (code: string | null) => void;
  selectedPopoverCode: string | null;
};

const CopyFilterButton = ({
  datatableCode,
  openCopyDatatable,
  setPopover,
  selectedPopoverCode
}: Props) => {
  const handlePopover = () => {
    if (selectedPopoverCode !== datatableCode) {
      setPopover(datatableCode);
    } else {
      setPopover(null);
    }
  };

  return (
    <>
      <div className={styles["action-menu-container"]}>
        <button
          type="button"
          data-testid={`${datatableCode}-Popover`}
          id={`${datatableCode}-Popover`}
          onClick={() => handlePopover()}
        >
          <FontAwesomeIcon
            style={{ right: "40px" }}
            iconClassName="fa-ellipsis-v"
          />
        </button>
      </div>
      <Popover
        isOpen={selectedPopoverCode === datatableCode}
        target={`${datatableCode}-Popover`}
        placement="bottom-start"
      >
        <button
          data-testid={`${datatableCode}-Popover-Copy-Filter-Button`}
          className={styles["copy-filter-button"]}
          type="button"
          onClick={() => {
            openCopyDatatable(datatableCode);
          }}
        >
          Copy Filter
        </button>
      </Popover>
    </>
  );
};

export default CopyFilterButton;
