import React, { useState } from "react";
import { Box } from "@nef/core";
import FormInput, {
  CustomChangeEvent
} from "@nef/core/lib/components/FormInput";
import { useClient } from "jsonapi-react";
import styled from "styled-components";

import { DatatableSchema, ProductFormMode } from "../../api/types";
import { useProductContext } from "../../hooks/useProductContext";
import InfiniteScroll from "../common/InfiniteScroll";

import styles from "./ProductSelectDatatableStep.module.scss";
import ProductDatatableOption from "./components/ProductDatatableOption";

const DatatableSearch = styled(FormInput)`
  width: 20vw;
  min-width: 260px;
  background-color: #f8f8f8;
  font-size: 13px;
`;

const ProductSelectDatatableStep = () => {
  const { product, productForm } = useProductContext(ProductFormMode.EDIT);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const client = useClient();

  const generateRequestBody = (after?: string | null) => {
    const requestBody: any = {
      filter: {
        vendor_code: product.vendor.code
      },
      sort: "name"
    };

    if (searchQuery) {
      requestBody.filter.name = searchQuery;
    }

    if (after) {
      requestBody.filter.after = after;
    }

    return requestBody;
  };

  const fetchDatatables = async (after?: string | null) => {
    return client.fetch([
      "datatable_schema/index_by_vendor",
      generateRequestBody(after)
    ]);
  };

  if (!productForm) return null;

  const onTypeSearch = (e: CustomChangeEvent) => {
    const inputValue = (e.target as HTMLInputElement).value;
    setSearchInputValue(inputValue);
  };

  const onSubmitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;

    const query = (e.target as HTMLInputElement).value;
    setSearchQuery(query);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content} data-testid="selectDatatableForm">
        <div className={styles.search}>
          <DatatableSearch
            type="search"
            placeholder="Search..."
            data-testid="datatables_search"
            onChange={onTypeSearch}
            value={searchInputValue}
            onKeyDown={onSubmitSearch}
          />
        </div>
        <div className={styles.list}>
          {product.vendor && (
            <InfiniteScroll<DatatableSchema>
              renderItem={(datatable, index) => {
                return (
                  <ProductDatatableOption
                    key={`${datatable.code}`}
                    datatable={datatable}
                    index={index}
                  />
                );
              }}
              renderEmptyList={
                <Box
                  paddingVertical={4}
                  data-testid="selectDatatableForm_noDatatables"
                >
                  No datatables found
                </Box>
              }
              fetchData={async (cursor: string | null) => {
                const { data: rawDatatables, meta } = await fetchDatatables(
                  cursor
                );

                return {
                  data: rawDatatables as DatatableSchema[],
                  cursor: meta?.pagination?.nextPage
                };
              }}
              queries={[searchQuery]}
              testid="selectDatatableForm-datatableList"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSelectDatatableStep;
