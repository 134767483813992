import { format, isMatch, parse } from "date-fns";

import {
  ByDay,
  NonRecurringSchedule,
  IntradayRules,
  IntradaySchedule,
  NonRecurringRules,
  RecurringRules,
  RecurringSchedule,
  Schedule
} from "../../../../api/types";

export type Freq = Schedule["freq"];
export type NonRecurringFreq = NonRecurringSchedule["freq"];
export type RecurringFreq = RecurringSchedule["freq"];
export type IntradayFreq = "intraday";

export type EndDateType = "on-this-day" | "after" | "no-end-date";

export const RRULE_DATE_FORMAT = "yyyyMMdd'T'HHmmss";
export const rruleToFieldDate = (
  dateString: string,
  fieldDateFormat = "yyyy-MM-dd'T'HH:mm"
) => {
  const baseDate = isMatch(dateString, RRULE_DATE_FORMAT)
    ? parse(dateString, RRULE_DATE_FORMAT, new Date())
    : new Date();

  return format(baseDate, fieldDateFormat);
};

export const fieldToRruleDate = (
  dateString: string,
  fieldDateFormat = "yyyy-MM-dd'T'HH:mm"
) => {
  const baseDate = isMatch(dateString, fieldDateFormat)
    ? parse(dateString, fieldDateFormat, new Date())
    : new Date();

  return format(baseDate, RRULE_DATE_FORMAT);
};

export const FREQUENCY_DATA: {
  freq: Freq;
  label: string;
}[] = [
  {
    freq: "realtime",
    label: "Streaming"
  },
  { freq: "intraday", label: "Intraday" },
  {
    freq: "history only",
    label: "One-time historical upload"
  },
  { freq: "daily", label: "Daily" },
  { freq: "weekly", label: "Weekly" },
  { freq: "monthly", label: "Monthly" },
  { freq: "annually", label: "Annually" },
  { freq: "irregular", label: "Irregular or Other" }
];

export const FREQUENCY_OPTIONS: { value: Freq; label: string }[] =
  FREQUENCY_DATA.map(({ freq, label }) => {
    return {
      value: freq,
      label
    };
  });

export type ScheduleRules = {
  nonRecurring: NonRecurringRules;
  intraday: IntradayRules;
  recurring: {
    dtstart: string;
    tzid: string;
    until?: string;
    count?: number;
    daily: RecurringRules;
    weekly: RecurringRules;
    monthly: RecurringRules;
    annually: RecurringRules;
  };
};

export const DEFAULT_SCHEDULE_RULES: ScheduleRules = {
  nonRecurring: {
    custom: {
      note: ""
    }
  },
  intraday: {
    custom: {
      deliveryInterval: {
        value: 0,
        unit: ""
      },
      firstDelivery: "09:00",
      lastDelivery: "17:00",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      description: ""
    }
  },
  recurring: {
    dtstart: fieldToRruleDate("", "yyyy-MM-dd'T'HH:mm"),
    tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
    daily: {
      interval: 1
    },
    weekly: {
      interval: 1,
      byday: []
    },
    monthly: {
      interval: 1,
      byday: [],
      bymonthday: [],
      bysetpos: []
    },
    annually: {
      interval: 1,
      byday: [],
      bymonthday: [],
      bysetpos: []
    }
  }
};

export type DayOfMonthRule = {
  byday?: ByDay[];
  bymonthday?: number[];
  bysetpos?: number[];
};

export type EndRule = {
  until?: string;
  count?: number;
};

export function isNonRecurringFreq(freq: Freq): freq is NonRecurringFreq {
  return ["realtime", "history only", "irregular"].includes(freq);
}

export function isRecurringFreq(freq: Freq): freq is RecurringFreq {
  return ["daily", "weekly", "monthly", "annually"].includes(freq);
}

export function isIntradayFreq(freq: Freq): freq is IntradayFreq {
  return freq === "intraday";
}

export function isNonRecurring(
  schedule: Schedule
): schedule is NonRecurringSchedule {
  return ["realtime", "history only", "irregular"].includes(schedule.freq);
}

export function isRecurring(schedule: Schedule): schedule is RecurringSchedule {
  return ["daily", "weekly", "monthly", "annually"].includes(schedule.freq);
}

export function isIntraday(schedule: Schedule): schedule is IntradaySchedule {
  return schedule.freq === "intraday";
}

export function isByDay(value: string): value is ByDay {
  return DAY_OPTIONS.map(option => `${option.value}`).includes(value);
}

export const integers = (from: number, to: number) => {
  const length = to - from + 1;
  return Array.from({ length }, (value, index) => {
    return from + index;
  });
};

export const DAY_OPTIONS: { value: ByDay; label: string }[] = [
  {
    value: "SU",
    label: "Sunday"
  },
  {
    value: "MO",
    label: "Monday"
  },
  {
    value: "TU",
    label: "Tuesday"
  },
  {
    value: "WE",
    label: "Wednesday"
  },
  {
    value: "TH",
    label: "Thursday"
  },
  {
    value: "FR",
    label: "Friday"
  },
  {
    value: "SA",
    label: "Saturday"
  }
];

export const DAY_OF_MONTH_ORDINALS = [
  { value: 1, label: "First" },
  { value: 2, label: "Second" },
  { value: 3, label: "Third" },
  { value: 4, label: "Fourth" },
  { value: -1, label: "Last" }
];

export const DAY_OF_MONTH_UNITS = [
  {
    value: "SU",
    label: "Sunday"
  },
  {
    value: "MO",
    label: "Monday"
  },
  {
    value: "TU",
    label: "Tuesday"
  },
  {
    value: "WE",
    label: "Wednesday"
  },
  {
    value: "TH",
    label: "Thursday"
  },
  {
    value: "FR",
    label: "Friday"
  },
  {
    value: "SA",
    label: "Saturday"
  },
  {
    value: "",
    label: "--------"
  },
  {
    value: "Day",
    label: "Day"
  },
  {
    value: "Weekday",
    label: "Weekday"
  },
  {
    value: "Weekend Day",
    label: "Weekend Day"
  }
];

export type DayOfMonthFieldState = {
  dayOfMonthMode: "numerical" | "ordinal";
  numericalDay: number;
  ordinalDay: number;
  ordinalMeasure: string;
};

export type EndFieldState = {
  endMode: string;
  until: string;
  count: number;
};

export const MONTH_OPTIONS = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" }
];
