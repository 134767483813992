import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "jsonapi-react";
import { toast } from "react-toastify";
import { decamelizeKeys } from "humps";
import { isEqual, sortBy, uniq } from "lodash";

import { updateProductAccess } from "../../../../api/api";
import Toast from "../../../../components/Toast";
import { formSchema } from "../product-drawer/schema";
import ProductSearch from "../product-drawer/product-search";
import { ProductAccessOption } from "../product-drawer/types";
import { ConfirmationModal } from "../../../../components/modals/ConfirmationModal";
import { NewUser, NewUserGroup, ProductAccess } from "../../../../api/types";
import { OrganizationTeamInvoice } from "../product-drawer";

import styles from "./index.module.scss";

export interface Product {
  name: string;
  code: string;
  productType: string;
  datatables: { code: string }[];
}

interface Props {
  organizationId: string;
  organizationName?: string;
  organizationType?: string;
  organizationTeamInvoiceId?: string;
  handleClose: () => void;
  refetch: () => void;
}

interface FormData {
  newProducts: any[];
  newProductKeys: { value: Product }[];
  singleProductControl: boolean;
}

const compareProductAccessChanges = (
  updatedProducts: { users: any[]; userGroups: any[] },
  fetchedProducts: ProductAccess
) => {
  const sortedUpdatedUsers = sortBy(updatedProducts.users, "label").map(
    user => user.label
  );
  const sortedFetchedUsers = sortBy(fetchedProducts.users, "email").map(
    user => user.email
  );
  const sortedUpdatedGroups = sortBy(updatedProducts.userGroups, "label").map(
    group => group.label
  );
  const sortedFetchedGroups = sortBy(fetchedProducts.userGroups, "name").map(
    group => group.name
  );

  const usersMatch = isEqual(sortedUpdatedUsers, sortedFetchedUsers);
  const userGroupsMatch = isEqual(sortedUpdatedGroups, sortedFetchedGroups);

  return usersMatch && userGroupsMatch;
};

const ProductAccessModal = ({
  organizationTeamInvoiceId,
  handleClose,
  refetch
}: Props) => {
  const [inputProductValue, setInputProductValue] = useState("");
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] =
    useState(false);

  const { data: existingInvoice, isLoading: isInvoiceLoading } =
    useQuery<OrganizationTeamInvoice>(
      organizationTeamInvoiceId && [
        `organization-team-invoices/${organizationTeamInvoiceId}`
      ]
    );

  const productForm = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues: {
      newProducts: [],
      newProductKeys: [],
      singleProductControl: true
    }
  });

  const { setValue, getValues } = productForm;

  const handleCancel = () => {
    const productAccessChanged = compareProductAccessChanges(
      getValues()?.newProducts[0]?.productAccess,
      existingInvoice?.productAccess as ProductAccess
    );
    if (productAccessChanged) {
      handleClose();
    } else {
      setIsDiscardChangesModalOpen(true);
    }
  };

  const handleUpdateProductAccess = async () => {
    const usersParam: string[] =
      getValues().newProducts[0]?.productAccess?.users?.map(
        (user: ProductAccessOption) => user.value
      ) ?? [];

    const userGroupsParam: string[] =
      getValues().newProducts[0]?.productAccess?.userGroups?.map(
        (group: ProductAccessOption) => group.value
      ) ?? [];

    const productAccess = {
      users: uniq(usersParam),
      userGroups: uniq(userGroupsParam)
    };

    const response = await updateProductAccess(
      { product_access: decamelizeKeys(productAccess) },
      organizationTeamInvoiceId as string
    );

    if (response.status !== 200) {
      const { error, errors } = response.data;
      const responseErrors = error ? [error] : errors;

      if (responseErrors) {
        toast(
          <Toast
            type="error"
            title="There was an error updating product access"
            details={[]}
          />
        );
        handleClose();
        return;
      }
    }
    toast(
      <Toast
        type="success"
        title="Product access has been successfully updated"
        details={[]}
      />
    );
    handleClose();
    refetch();
  };

  useEffect(() => {
    if (!isInvoiceLoading && existingInvoice) {
      setValue("newProducts", [
        {
          productAccess: {
            users: existingInvoice?.productAccess?.users.map(
              (user: NewUser): ProductAccessOption => ({
                label: user.fullName || user.email,
                type: "User",
                value: user.email
              })
            ),
            userGroups: existingInvoice?.productAccess?.userGroups.map(
              (group: NewUserGroup): ProductAccessOption => ({
                label: group.name,
                type: "User Group",
                value: group.id
              })
            )
          }
        }
      ]);
    }
  }, [isInvoiceLoading, existingInvoice]);

  return (
    <>
      {!isInvoiceLoading && (
        <Modal
          toggle={handleClose}
          isOpen
          closeOnOutsideClick={false}
          data-testid="OrganizationsProductAccessModal"
        >
          <>
            <ModalHeader
              className={styles.header}
              toggle={handleCancel}
              data-testid="OrganizationsProductAccessModal_header"
            >
              {existingInvoice?.productName}
            </ModalHeader>
            <ModalBody className={styles.body}>
              <FormProvider {...productForm}>
                <div data-testid="OrganizationsProductAccessModal_formContainer">
                  <ProductSearch
                    products={[]}
                    salesManagers={[]}
                    existingInvoice={existingInvoice as OrganizationTeamInvoice}
                    inputProductValue={inputProductValue}
                    setInputProductValue={setInputProductValue}
                    isProductAccess
                  />
                </div>

                <div className={styles["footer-buttons-container"]}>
                  <Button
                    onClick={handleUpdateProductAccess}
                    data-testid="OrganizationsProductAccessModal_submit"
                  >
                    Save
                  </Button>
                  <Button
                    data-testid="OrganizationsProductAccessModal_cancel"
                    color="light"
                    outline
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </FormProvider>
            </ModalBody>
          </>
        </Modal>
      )}
      {isDiscardChangesModalOpen && (
        <ConfirmationModal
          isOpen={isDiscardChangesModalOpen}
          title="Save Changes"
          confirmText="Yes"
          dismissText="No"
          question="Would you like to discard changes?"
          onConfirm={() => {
            setIsDiscardChangesModalOpen(false);
            handleClose();
          }}
          onDismiss={() => {
            setIsDiscardChangesModalOpen(false);
          }}
          danger
        />
      )}
    </>
  );
};

export default ProductAccessModal;
