import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import { useClient } from "jsonapi-react";
import { useHistory } from "react-router-dom";

import { PATHS } from "../../routes";
import Toast from "../../components/Toast";
import { useSelectedVendor } from "../../contexts/selectedVendor";

import styles from "./delete-user-modal.module.scss";
import { User } from "./users-table";

interface DeleteUserModalProps {
  isOpen: boolean;
  close: () => void;
  selectedUser: Pick<User, "id" | "userName" | "userInviteEmail"> | null;
  setSelectedUser?: React.Dispatch<React.SetStateAction<User | null>>;
  redirect: boolean;
  vendorId: string;
}

const DeleteUserModal = ({
  isOpen,
  close,
  selectedUser,
  setSelectedUser,
  redirect,
  vendorId
}: DeleteUserModalProps) => {
  const history = useHistory();

  const client = useClient();
  const { selectedVendor } = useSelectedVendor();

  const handleDeleteUser = async () => {
    const response = await client.delete(["vendor-users", selectedUser?.id]);

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error deleting the user"
          details={[]}
        />
      );
    } else {
      toast(
        <Toast
          type="success"
          title="User has been successfully deleted"
          details={[]}
        />
      );

      if (redirect) {
        history.push(`${PATHS.USERS}?vendorId=${vendorId}`);
        return;
      }

      handleClose();
    }
  };

  const handleClose = () => {
    if (setSelectedUser) setSelectedUser(null);
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="deleteUserModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Delete User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Remove from Publisher
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <p className={styles.description}>
              Are you sure you want to remove manager access for{" "}
              {selectedUser?.userName || selectedUser?.userInviteEmail} from{" "}
              {selectedVendor?.label}?
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              color="danger"
              onClick={handleDeleteUser}
              data-testid="deleteUserModal_submit"
            >
              Remove Access
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="deleteUserModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteUserModal;
