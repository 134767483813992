import React from "react";
import { Button, Header, Modal, ModalBody, ModalHeader } from "@nef/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { CapProductToEdit, DatatablesNameDescription } from "../../api/types";
import SchemaUploader from "../intake-datatable-modal/components/SchemaUploader";
import useCapProductContext from "../../hooks/useCapProductContext";

import styles from "./IntakeDatatableErrorModal.module.scss";
import IntakeDatatableErrors from "./IntakeDatatableErrors";

interface IntakeDatatableErrorModalProps {
  datatable: DatatablesNameDescription;
  onDismiss: () => void;
  onSubmit: (values: DatatablesNameDescription) => void;
}

const IntakeDatatableErrorModal = ({
  datatable,
  onDismiss,
  onSubmit
}: IntakeDatatableErrorModalProps) => {
  const form = useForm<DatatablesNameDescription>({
    defaultValues: datatable
  });
  const { product: contextProduct } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const { handleSubmit } = form;

  const save = async () => {
    await handleSubmit(async formData => onHandleSubmit(formData))().catch(
      () => {}
    );
  };

  const onHandleSubmit: SubmitHandler<
    DatatablesNameDescription
  > = async formData => {
    onSubmit(formData);
  };

  return (
    <Modal isOpen={true} toggle={onDismiss} closeOnOutsideClick={false}>
      <ModalHeader toggle={onDismiss}>Table Error Details</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className={styles.section}>
            <div>{datatable.name}</div>
          </div>

          <div className={styles.section}>
            <div>{datatable.description}</div>
          </div>
          <IntakeDatatableErrors />

          <div className={styles.section}>
            <Header size={3}>Upload new file</Header>
            <SchemaUploader
              showLabel={false}
              productId={product.id}
              datatableId={datatable.id}
              vendorId={product.vendor.id}
            />
          </div>

          <div className={styles.footer}>
            <Button onClick={() => save()}>Submit</Button>
            <Button onClick={() => onDismiss()} outline={true}>
              Cancel
            </Button>
          </div>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default IntakeDatatableErrorModal;
