import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FontAwesomeIcon,
  FormFieldWithIcon,
  Pagination
} from "@nef/core";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useQuery } from "jsonapi-react";
import { useHistory, Link, Redirect } from "react-router-dom";

import useQueryParam from "../../../hooks/useQueryParam";
import useDefaultAuth from "../../../hooks/useDefaultAuth";
import { Organization, OrganizationSortParam } from "../../../api/types";
import { extractPageNumber } from "../../../utils/general-utils";

import styles from "./index.module.scss";
import NewOrganizationModal from "./new-organization-modal";

const columnHelper = createColumnHelper<Organization>();

const SORT_PARAMS = [
  "name",
  "-name",
  "distributor_id",
  "-distributor_id",
  "created_at",
  "-created_at",
  "updated_at",
  "-updated_at",
  "users_count",
  "-users_count",
  "products_count",
  "-products_count"
];

const isOrganizationSortParam = (query: string): query is string => {
  return SORT_PARAMS.includes(query);
};

interface RequestBody {
  filter: {
    name?: string;
  };
  page: {
    number: number;
  };
  sort: string;
}

const OrganizationsPage = () => {
  const [isNewModalOpen, setNewModalOpen] = useState(false);
  const { isManagerAdmin, isOrganizationUser } = useDefaultAuth();
  const history = useHistory();
  const queryParams = useQueryParam();

  const query = queryParams.get("search") ?? "";
  const [searchQuery, setSearchQuery] = useState(query);
  const page = parseInt(queryParams.get("page") || "1", 10);

  const rawSortParam = useQueryParam().get("sort") || "";
  const sortParam = isOrganizationSortParam(rawSortParam)
    ? rawSortParam
    : "-created_at";

  const ascending = !sortParam.startsWith("-");
  const sortBy = ascending ? sortParam : sortParam.substring(1);

  const generateRequestBody = (): RequestBody => {
    const filter = query ? { name: query } : {};

    return {
      filter,
      page: { number: page },
      sort: sortParam
    };
  };

  const {
    data: organizations,
    links,
    isLoading
  } = useQuery<Organization[]>(["organizations", generateRequestBody()]);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (!isLoading) {
      setTotalPages(extractPageNumber(links?.last));
    }
  }, [isLoading, links]);

  const handleSearchIconClick = () => {
    loadPageWithParams({
      search: searchQuery,
      page: 1,
      sort: sortParam
    });
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearchIconClick();
    }
  };

  const setSortParam = useCallback(
    (sort: string) => {
      loadPageWithParams({
        search: searchQuery,
        page,
        sort
      });
    },
    [searchQuery, page]
  );

  const setPageParam = useCallback(
    (pageNum: number) => {
      loadPageWithParams({
        search: searchQuery,
        page: pageNum,
        sort: sortParam
      });
    },
    [page, searchQuery, sortParam]
  );

  const loadPageWithParams = (params: {
    search: string;
    page: number;
    sort: string;
  }) => {
    const queryString = Object.entries(params).reduce((url, param) => {
      const [key, value] = param;
      if (!value) return url;

      return `${url}${url ? "&" : "?"}${key}=${value}`;
    }, "");

    history.push({
      search: queryString
    });
  };

  const onPageChange = (num: number) => setPageParam(num + 1);
  const nextPage = () => page < totalPages && setPageParam(page + 1);
  const previousPage = () => page > 1 && setPageParam(page - 1);

  const updateSortParam = useCallback(
    (newSortBy: string) => {
      const newAscending = sortBy === newSortBy ? !ascending : true;
      const newPrefix = newAscending ? "" : "-";
      const newSortParam = (newPrefix + newSortBy) as OrganizationSortParam;
      setSortParam(newSortParam);
    },
    [ascending, sortBy, searchQuery]
  );

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: info => (
        <div className={styles["left-align"]}>
          <Link
            className={styles.values}
            to={`/organizations/${info.row.original.id}`}
          >
            {info.getValue()}
          </Link>
        </div>
      ),
      header: () => {
        return (
          <div
            className={sortBy === "name" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("name")}
            onKeyPress={() => updateSortParam("name")}
            tabIndex={-1}
            data-testid="name-sort"
          >
            Name
            {sortBy === "name" && (
              <FontAwesomeIcon
                iconClassName={ascending ? "fa-caret-up" : "fa-caret-down"}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("distributorId", {
      id: "distributorId",
      cell: info => info.getValue() || "-",
      header: () => (
        <div
          className={sortBy === "distributor_id" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("distributor_id")}
          onKeyPress={() => updateSortParam("distributor_id")}
          tabIndex={-1}
          data-testid="distributor_id"
        >
          Distributor ID
          {sortBy === "distributor_id" && (
            <FontAwesomeIcon
              iconClassName={ascending ? "fa-caret-up" : "fa-caret-down"}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      cell: info =>
        new Intl.DateTimeFormat("en-US").format(
          new Date(info.getValue() || Date.now())
        ),
      header: () => {
        return (
          <div
            className={sortBy === "created_at" ? styles["sorter-active"] : ""}
            role="button"
            onClick={() => updateSortParam("created_at")}
            onKeyPress={() => updateSortParam("created_at")}
            tabIndex={-1}
            data-testid="created-at-sort"
          >
            Created On
            {sortBy === "created_at" && (
              <FontAwesomeIcon
                iconClassName={ascending ? "fa-caret-up" : "fa-caret-down"}
                className={styles["caret-icon"]}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("updatedAt", {
      id: "updatedAt",
      cell: info => {
        const val = info.getValue();
        if (val !== undefined) {
          return new Intl.DateTimeFormat("en-US").format(new Date(val));
        }
        return "";
      },
      header: () => (
        <div
          className={sortBy === "updated_at" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("updated_at")}
          onKeyPress={() => updateSortParam("updated_at")}
          tabIndex={-1}
          data-testid="updated-at-sort"
        >
          Last Update
          {sortBy === "updated_at" && (
            <FontAwesomeIcon
              iconClassName={ascending ? "fa-caret-up" : "fa-caret-down"}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("usersCount", {
      id: "numberOfUsers",
      cell: info => info.getValue(),
      header: () => (
        <div
          className={sortBy === "users_count" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("users_count")}
          onKeyPress={() => updateSortParam("users_count")}
          tabIndex={-1}
          data-testid="users-count-sort"
        >
          Number of Users
          {sortBy === "users_count" && (
            <FontAwesomeIcon
              iconClassName={ascending ? "fa-caret-up" : "fa-caret-down"}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("productsCount", {
      id: "numberOfTotalProducts",
      cell: info => info.getValue(),
      header: () => (
        <div
          className={sortBy === "products_count" ? styles["sorter-active"] : ""}
          role="button"
          onClick={() => updateSortParam("products_count")}
          onKeyPress={() => updateSortParam("products_count")}
          tabIndex={-1}
          data-testid="products-count-sort"
        >
          Number of Total Products
          {sortBy === "products_count" && (
            <FontAwesomeIcon
              iconClassName={ascending ? "fa-caret-up" : "fa-caret-down"}
              className={styles["caret-icon"]}
            />
          )}
        </div>
      )
    }),
    columnHelper.accessor("id", {
      id: "viewOrganization",
      cell: info => (
        <div className={styles["right-align"]}>
          <Button color="light" outline size="sm">
            <Link
              className={styles.link}
              to={`/organizations/${info.getValue()}`}
            >
              View Organization
            </Link>
          </Button>
        </div>
      ),
      header: () => {
        return null;
      }
    })
  ];

  const table = useReactTable({
    data: organizations ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  if (!isManagerAdmin() && !isOrganizationUser()) {
    return <Redirect to="/" />;
  }

  const handleCloseNewOrg = (organization?: Pick<Organization, "id">) => {
    if (organization?.id) {
      history.push(`/organizations/${organization?.id}`);
    }
    setNewModalOpen(false);
  };

  return (
    <main className={styles.container} data-testid="organizations">
      <section className={styles["content-section"]}>
        <div className={styles.organization}>
          <h1 className={styles.heading} data-testid="organization">
            Organizations
          </h1>
          <div
            className={styles["buttons-container"]}
            data-testid="new-button-container"
          >
            <FormFieldWithIcon
              placeholder="Search"
              iconClassName="fa-search"
              addonType="append"
              value={searchQuery}
              onChange={e =>
                setSearchQuery((e.target as HTMLInputElement).value)
              }
              onIconClick={handleSearchIconClick}
              onKeyDown={handleSubmit}
            />
            {isManagerAdmin() && (
              <Button
                onClick={() => setNewModalOpen(true)}
                data-testid="new-button"
              >
                <FontAwesomeIcon
                  iconClassName="fa-plus-circle"
                  className={styles["circle-icon"]}
                />
                New Organization
              </Button>
            )}
          </div>
          {isNewModalOpen && <NewOrganizationModal close={handleCloseNewOrg} />}
        </div>

        <section data-testid="organization-record">
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`${
                        header.column.getIsSorted()
                          ? `${styles.selected} ${styles.label}`
                          : styles.label
                      } ${
                        header.column.id === "name" ? styles["left-align"] : ""
                      }`}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody data-testid="organization-table-body">
              {table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  data-testid={`organization-table-row-${row.id}`}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className={
                        cell.column.id === "name"
                          ? `${styles.values} ${styles["left-align"]}`
                          : styles.values
                      }
                      data-testid={`organization-table-${cell.column.id}-${row.id}`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <Pagination
          page={page - 1}
          pages={totalPages}
          pageSize={0}
          pageSizeOptions={[]}
          onPageChange={onPageChange}
          onPageSizeChange={() => null}
          onPageUpdate={() => null}
          nextPage={nextPage}
          previousPage={previousPage}
          showPageSizeDropdown={false}
          showPageDataCount={false}
          totalDataLength={0}
          data-testid="organization_pagination"
        />
      </section>
    </main>
  );
};

export default OrganizationsPage;
