import React, { useEffect, useState } from "react";
import {
  generatePath,
  Redirect,
  useHistory,
  useParams
} from "react-router-dom";
import FontAwesomeIcon from "@nef/core/lib/components/FontAwesomeIcon";
import { Button } from "@nef/core";
import { useMutation, useQuery } from "jsonapi-react";
import { toast } from "react-toastify";
import isEqual from "lodash/isEqual";

import DeleteUserModal from "../user/delete-user-modal";
import Toast from "../../components/Toast";
import manageClient from "../../api/manage/client";
import { PATHS } from "../../routes";
import {
  NewVendorUser,
  NewUserGroup,
  ProductPermission
} from "../../api/types";
import useQueryParam from "../../hooks/useQueryParam";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import styles from "./index.module.scss";
import ProductsTable from "./ProductAccessTable";
import UserGroupsTable from "./UserGroupsTable";

const dateFormatCa = new Intl.DateTimeFormat("en-US");

const EditUserPage = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { userId: vendorUserId } = useParams<{ userId: string }>();
  const queryParams = useQueryParam();
  const [userGroups, setUserGroups] = useState<NewUserGroup[]>([]);
  const [productPermissions, setProductPermissions] = useState<
    ProductPermission[]
  >([]);
  const [initialUserGroupsData, setInitialUserGroupsData] =
    useState<NewUserGroup[]>();
  const [initialProductPermissionsData, setInitialProductPermissionsData] =
    useState<ProductPermission[]>();

  const { isManagerAdmin, isPublisherUser, isAdminableVendor } =
    useDefaultAuth();
  const history = useHistory();

  const [updateUserAccess] = useMutation(["vendor-users", vendorUserId], {
    method: "PATCH",
    client: manageClient
  });

  const { data, isLoading } = useQuery<NewVendorUser>([
    "vendor-users",
    `${vendorUserId}`,
    { include: ["products", "vendor-user-groups"] }
  ]);

  useEffect(() => {
    if (!isLoading && data) {
      setUserGroups(data?.vendorUserGroups || []);
      setProductPermissions(data?.products || []);

      setInitialUserGroupsData(data?.vendorUserGroups || []);
      setInitialProductPermissionsData(data?.products || []);
    }
  }, [isLoading, data]);

  const onUpdateUserGroup = (groups: any) => {
    setUserGroups(groups);
  };

  const onUpdateProductPermissions = (products: any) => {
    setProductPermissions(products);
  };

  const handleDeleteUser = () => {
    setIsDeleteModalOpen(true);
  };

  const handleBack = () => {
    if (queryParams.get("from") === "dashboardUsers") {
      return history.push(
        `${generatePath(PATHS.EDIT_PUBLISHER, {
          publisherId: vendorId
        })}?tab=users`
      );
    }

    if (queryParams.get("from")) {
      return history.goBack();
    }

    return history.push(`${PATHS.USERS}?vendorId=${vendorId}`);
  };

  const onSubmit = async () => {
    const response = await updateUserAccess({
      id: vendorUserId,
      products: productPermissions.map(product => {
        return { id: product?.id };
      }),
      "vendor-user-groups": userGroups.map(group => {
        return { id: group?.id };
      })
    });

    const { error, errors } = response;

    if (error || errors) {
      toast(
        <Toast
          type="error"
          title="There was an error updating the permissions"
          details={[]}
        />
      );

      return;
    }
    toast(
      <Toast
        type="success"
        title="Permissions has been successfully updated"
        details={[]}
      />
    );
  };

  if (!data) {
    return null;
  }

  const {
    id,
    userId,
    userName,
    userEmail,
    userInviteEmail,
    userInviteAcceptedAt,
    createdAt,
    lastSignInAt,
    vendorId
  } = data;

  if (
    !isManagerAdmin() &&
    !(isPublisherUser() && isAdminableVendor(vendorId))
  ) {
    return <Redirect to="/" />;
  }

  const disableIfNotChanged =
    isEqual(initialUserGroupsData, userGroups) &&
    isEqual(initialProductPermissionsData, productPermissions);

  return (
    <div className={styles.page} data-testid="editUserPage">
      <div className={styles.container}>
        <header className={styles.header}>
          <button
            type="button"
            onClick={handleBack}
            className={styles["back-link"]}
          >
            <FontAwesomeIcon iconClassName="fa-arrow-left" />
            Back to Users
          </button>
          <div className={styles["button-container"]}>
            <Button color="danger" onClick={handleDeleteUser}>
              Remove from Publisher
            </Button>
            <DeleteUserModal
              redirect={true}
              isOpen={isDeleteModalOpen}
              close={() => setIsDeleteModalOpen(false)}
              selectedUser={{ id, userName, userInviteEmail }}
              vendorId={vendorId}
            />
            <Button
              disabled={disableIfNotChanged}
              color="primary"
              onClick={onSubmit}
              data-testid="editUserPage_saveChangesButton"
            >
              Save Changes
            </Button>
          </div>
        </header>
        <div className={styles["edit-user-data"]}>
          <div className={styles["flex-container"]}>
            <span className={styles["edit-user-name"]}>{`${
              userName || userInviteEmail || ""
            }`}</span>
          </div>
          <div>
            <span> {userEmail || userInviteEmail || ""} </span>
            <span>
              {" | "}
              <strong>Date Joined: </strong>
              {userId
                ? dateFormatCa.format(new Date(createdAt))
                : userInviteAcceptedAt
                ? dateFormatCa.format(new Date(userInviteAcceptedAt))
                : ""}
              {" | "}
            </span>
            <span>
              <strong>Last Login: </strong>
              {lastSignInAt ? dateFormatCa.format(new Date(lastSignInAt)) : ""}
            </span>
          </div>
        </div>
        <div className={styles["flex-container"]}>
          <div className={styles["products-container"]}>
            <ProductsTable
              onUpdate={onUpdateProductPermissions}
              data={productPermissions}
              vendorId={vendorId || ""}
            />
          </div>
          <div className={styles["user-groups-container"]}>
            <UserGroupsTable
              onUpdate={onUpdateUserGroup}
              data={userGroups}
              vendorId={vendorId || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserPage;
