import React, { useState } from "react";
import { Button } from "@nef/core";

import { ConfirmationModal } from "../../../modals/ConfirmationModal";

interface PlanFormActivateButtonProps {
  activatePlan: () => void;
  isExistingPlan: boolean;
}

const PlanFormActivateButton = ({
  activatePlan,
  isExistingPlan
}: PlanFormActivateButtonProps) => {
  const [shouldConfirmActivate, setShouldConfirmActivate] = useState(false);

  const onConfirm = () => {
    setShouldConfirmActivate(false);
    activatePlan();
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setShouldConfirmActivate(true)}
        data-testid="planForm_activate"
      >
        {isExistingPlan ? "Update Plan" : "Activate Plan"}
      </Button>
      <ConfirmationModal
        isOpen={shouldConfirmActivate}
        question="This pricing plan will be activated immediately. Are you sure?"
        onConfirm={() => onConfirm()}
        onDismiss={() => setShouldConfirmActivate(false)}
      />
    </>
  );
};

export default PlanFormActivateButton;
