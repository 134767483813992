import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormField,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { StringMap, useMutation, useQuery } from "jsonapi-react";
import { useHistory } from "react-router-dom";
import { startCase, sortBy, debounce } from "lodash";

import manageClient from "../../api/manage/client";
import Toast from "../Toast";
import { NewUserGroup, Vendor } from "../../api/types";
import FormFieldLabel from "../modals/components/FormFieldLabel";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";

import {
  USER_MODAL_VENDOR,
  USER_MODAL_USER_GROUP,
  USER_MODAL_ADMIN_EMAIL
} from "./hints";
import styles from "./NewUserModal.module.scss";

interface NewUserRequest {
  vendorId: string;
  vendorUserGroupIds: string;
  userInviteEmail: string;
}

interface NewUserModalProps {
  isOpen: boolean;
  vendorId: string;
  close: () => void;
}

const userRequestSchema = yup.object({
  vendorId: yup.string().required("Publisher name is a required field"),
  userInviteEmail: yup
    .string()
    .email("Email needs to be a valid email")
    .required("Email contact is a required field")
});

const NewUserModal = ({ isOpen, vendorId, close }: NewUserModalProps) => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = useForm({
    mode: "onChange",
    defaultValues: {
      vendorId: "",
      vendorUserGroupIds: "",
      userInviteEmail: ""
    },
    resolver: yupResolver(userRequestSchema)
  });
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [validateData] = useMutation(["vendor-users", "validate"], {
    client: manageClient
  });

  const validateFieldUniquenessAPI = async (
    field: "userInviteEmail",
    value: string
  ) => {
    try {
      userRequestSchema.validateSyncAt(field, { [field]: value });
    } catch (err) {
      return;
    }

    const { errors } = await validateData({
      "user-invite-email": value,
      "vendor-id": getValues().vendorId
    });

    const fieldHasBeenTaken = errors?.some(
      error =>
        error.title.includes("already exists") &&
        error.source.pointer.includes("user-invite-email")
    );

    if (fieldHasBeenTaken) {
      setError(field, {
        type: "custom",
        message: `${startCase(field)} has already been taken`
      });
    }
  };

  const debounceUserInviteValidation = useCallback(
    debounce(validateFieldUniquenessAPI, 300),
    []
  );

  const { data: userGroups, isLoading: isUserGroupLoading } = useQuery<
    NewUserGroup[]
  >(
    [
      "vendor-user-groups",
      {
        "filter[vendor_id]": watch("vendorId") || vendorId
      }
    ],
    {
      client: manageClient
    }
  );

  const userGroupOptions = !isUserGroupLoading
    ? sortBy(
        userGroups?.map(group => {
          return {
            value: group.id,
            label: group.name
          };
        }),
        ["label"]
      )
    : [];

  const [createUserRequest] = useMutation(["vendor-users"], {
    client: manageClient
  });

  const { data: vendors, isLoading: isVendorLoading } = useQuery<Vendor[]>([
    "vendors",
    { sort: "name", "fields[vendors]": "name,code" }
  ]);

  const vendorOptions = vendors?.map(vendor => {
    return {
      value: vendor.id,
      label: vendor.name
    };
  });

  const onSubmit: SubmitHandler<NewUserRequest> = useCallback(async data => {
    setIsSaveLoading(true);
    const normalizedUserRequest: any = decamelizeKeys(data, {
      separator: "-"
    });

    const response = await createUserRequest(normalizedUserRequest);

    const { data: responseData } = response;

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) {
        setIsSaveLoading(false);
        return;
      }

      toast(
        <Toast
          type="error"
          title="There was an error creating the user"
          details={[]}
        />
      );

      setIsSaveLoading(false);
      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully created"
        details={[]}
      />
    );

    if (responseData) {
      history.push(`/users/${(responseData as StringMap)?.id}`);
    }

    setIsSaveLoading(false);
    handleClose();
  }, []);

  useEffect(() => {
    if (vendors && getValues().vendorId === "") {
      setValue(
        "vendorId",
        vendors.find(vendor => vendor.id === vendorId)?.id || vendors[0].id
      );
    }
  }, [vendors]);

  const handleClose = () => {
    close();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid="newUserModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="New User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        New User
      </ModalHeader>
      <ModalBody>
        {!vendorId && (
          <div>
            <div className={styles["remove-margin"]}>
              <FormFieldLabel
                title="Select Publisher"
                tooltip={USER_MODAL_VENDOR}
                optional
              />
            </div>
            <Controller
              name="vendorId"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormSelect
                  id="VendorId"
                  name={name}
                  isLoading={isVendorLoading}
                  value={() => vendorOptions?.find(opt => opt.value === value)}
                  options={vendorOptions}
                  data-testid="newProductModal_vendorId"
                  onChange={formSelectOnChangeParam => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  invalid={!!formErrors.vendorId}
                  feedback={formErrors?.vendorId?.message}
                  onBlur={onBlur}
                  classNamePrefix="modalFormSelect"
                />
              )}
            />
          </div>
        )}
        <div className={`${!vendorId ? "" : styles["remove-margin"]}`}>
          <FormFieldLabel
            title="User Group"
            tooltip={USER_MODAL_USER_GROUP}
            optional
          />
          <Controller
            name="vendorUserGroupIds"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FormSelect
                id="vendorUserGroupIds"
                name={name}
                isLoading={isUserGroupLoading}
                value={() => userGroupOptions?.find(opt => opt.value === value)}
                options={userGroupOptions}
                data-testid="newProductModal_userGroup"
                onChange={formSelectOnChangeParam => {
                  onChange(
                    extractFormSelectOnChangeValue(formSelectOnChangeParam)
                  );
                }}
                invalid={!!formErrors.vendorUserGroupIds}
                feedback={formErrors?.vendorUserGroupIds?.message}
                onBlur={onBlur}
                classNamePrefix="modalFormSelect"
              />
            )}
          />
        </div>
        <FormFieldLabel title="Email" tooltip={USER_MODAL_ADMIN_EMAIL} />
        <Controller
          name="userInviteEmail"
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <FormField
              id="UserInviteEmail"
              name={name}
              type="email"
              placeholder=""
              value={value}
              onChange={event => {
                debounceUserInviteValidation(
                  "userInviteEmail",
                  event.value?.toString() || ""
                );
                onChange(event);
              }}
              data-testid="newUserModal_email"
              invalid={!!formErrors.userInviteEmail}
              feedback={formErrors?.userInviteEmail?.message}
              onBlur={onBlur}
            />
          )}
        />
        <div className={styles.buttons}>
          <Button
            onClick={handleSubmit(onSubmit)}
            isLoading={isSaveLoading}
            disabled={
              isSaveLoading ||
              !isDirty ||
              (!isValid && Object.keys(formErrors).length !== 0)
            }
            data-testid="newUserModal_submit"
          >
            Create New User
          </Button>
          <Button
            color="light"
            outline
            onClick={handleClose}
            data-testid="newUserModal_cancel"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewUserModal;
