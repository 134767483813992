import React from "react";
import "./MultiSelect.scss";
import { FontAwesomeIcon } from "@nef/core";

export interface MultiSelectOption {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: MultiSelectOption[];
  value: string[];
  invalid?: boolean;
  feedback?: string;
  onChange: (selected: string[]) => void;
  validate?: () => void;
}

const MultiSelect = ({
  options,
  value,
  invalid,
  feedback,
  onChange,
  validate
}: MultiSelectProps) => {
  const handleSelect = (optionValue: string) => {
    if (value.includes(optionValue)) {
      onChange(value.filter(currentValue => currentValue !== optionValue));
    } else {
      onChange([...value, optionValue]);
    }
    if (validate) validate();
  };

  return (
    <div className="multi-select-wrapper">
      <div className={`multi-select ${invalid && "invalid"}`}>
        {options.map(option => (
          <button
            data-testid={`multiSelect-${option.label}`}
            type="button"
            key={option.value}
            onClick={() => handleSelect(option.value)}
            className={`option ${
              value.includes(option.value) ? "selected" : ""
            }`}
          >
            <span className="option-title">{option.label}</span>
            <span className="option-action">
              {value.includes(option.value) ? (
                <FontAwesomeIcon
                  iconClassName="fa-minus-circle"
                  className="circle-icon-red"
                />
              ) : (
                <FontAwesomeIcon
                  iconClassName="fa-plus-circle"
                  className="circle-icon-grey"
                />
              )}
            </span>
          </button>
        ))}
      </div>
      {invalid && (
        <small color="muted" className="error">
          {feedback}
        </small>
      )}
    </div>
  );
};

export default MultiSelect;
