import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { StringMap } from "../../api/types";
import Toast from "../Toast";

interface handleCapProductResponseToastArgs {
  productName: string;
  errors?: StringMap[];
  isPublished: boolean;
}

const handleCapProductResponseToast = ({
  productName,
  errors,
  isPublished
}: handleCapProductResponseToastArgs) => {
  if (errors?.length) {
    const errorDetails = errors.map(e => {
      return { message: e.detail };
    });

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  } else if (isPublished) {
    toast(
      <Toast
        type="success"
        title="Product is updated"
        details={[{ message: `${productName} is updated successfully` }]}
      />
    );
  } else {
    toast(
      <Toast
        type="info"
        title="Progress is Saved"
        details={[
          {
            message: "Saved the draft",
            description: "You can go back to list and continue next time"
          }
        ]}
      />
    );
  }
};

const useDirty = (isDirty: boolean, setFormIsDirty: any) => {
  useEffect(() => {
    setFormIsDirty(isDirty);
  }, [isDirty]);
};

export { handleCapProductResponseToast, useDirty };
