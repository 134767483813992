const LocalStorageBuilder = (key: string) => {
  return {
    get: () => {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    },
    set: (value: any) => {
      if (value === null) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    }
  };
};

export default LocalStorageBuilder;
