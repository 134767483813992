import { FormField, FormSelect } from "@nef/core";
import React, { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import { DatatablesNameDescription } from "../../api/types";

import { FieldPathByValue } from "./types";

interface FormSelectWithFreeTextFieldProps {
  name: FieldPathByValue<DatatablesNameDescription, string>;
  label: string;
  placeholder: string;
  options: string[];
}

const FREE_TEXT_OPTION = "Other";

// A FormSelect component that shows a free text field only when the free text option
// is selected. Both the FormSelect component and the free text field map to the same
// attribute.
const FormSelectWithFreeTextField = ({
  name,
  label,
  placeholder,
  options
}: FormSelectWithFreeTextFieldProps) => {
  const { control, getValues } = useFormContext<DatatablesNameDescription>();

  const [shouldShowFreeText, setShouldShowFreeText] = useState(() => {
    const initialValue = getValues(name);
    return initialValue && !options.includes(initialValue);
  });

  const freeTextValueRef = useRef(shouldShowFreeText ? getValues(name) : "");

  const handleOnChange = (
    newValue: string,
    existingValue: string,
    onChange: (value: string) => void
  ) => {
    // if the free text option is selected:
    // - show free text field
    // - set field value to the cached free text value
    if (newValue === FREE_TEXT_OPTION) {
      setShouldShowFreeText(true);
      onChange(freeTextValueRef.current);
    } else {
      if (shouldShowFreeText) {
        // if switching from a free text option to a non-free text option
        // - hide free text
        // - save existing field value to the cached free text value
        setShouldShowFreeText(false);
        freeTextValueRef.current = existingValue;
      }

      onChange(newValue);
    }
  };

  const selectOptions = options.map(value => {
    return { value, label: value };
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        const selectedOptionValue = shouldShowFreeText
          ? FREE_TEXT_OPTION
          : value;

        return (
          <>
            <FormSelect
              id={name}
              name={name}
              label={label}
              value={
                selectOptions.find(o => o.value === selectedOptionValue) || null
              }
              isClearable={false}
              placeholder={placeholder}
              options={selectOptions}
              onChange={(formSelectOnChangeParam: any) => {
                const newValue = extractFormSelectOnChangeValue(
                  formSelectOnChangeParam
                );
                handleOnChange(newValue, value, onChange);
              }}
              classNamePrefix="modalFormSelect"
            />
            {shouldShowFreeText && (
              <FormField
                id={`${name}Text`}
                name={`${name}Text`}
                value={value}
                label=""
                onChange={onChange}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default FormSelectWithFreeTextField;
