import React from "react";

import { Product } from "../../api/types";
import useProductStatus, { PRODUCT_STATUS } from "../../hooks/useProductStatus";

import styles from "./product-status-pill.module.scss";

interface ProductStatusPillProps {
  product: Product;
}

const HIDDEN_STATUS: string[] = [
  PRODUCT_STATUS.UNSAVED,
  PRODUCT_STATUS.UNPUBLISHED
];

const ProductStatusPill = ({ product }: ProductStatusPillProps) => {
  const status = useProductStatus(product);

  if (HIDDEN_STATUS.includes(status)) return null;

  const classNames = () => {
    switch (status) {
      case "Draft":
        return "draft-pill";
      case "Published":
        return "published-pill";
      case "Waiting Approval":
        return "waiting-approval-pill";
      default:
        return "none";
    }
  };

  return (
    <div className={`${styles[classNames()]} ${styles.pill}`}>
      {status === "Draft" ? "In Progress" : status}
    </div>
  );
};

export default ProductStatusPill;
