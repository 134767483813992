import React, { useEffect, useState } from "react";
import { FontAwesomeIcon, Tab, Tabs as NefTabs } from "@nef/core";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

import AddDatatable from "../modal/add-datatable";
import RemoveDatatable from "../modal/remove-datatable";
import { formSchema } from "../schema";
import CopyDatatable from "../modal/copy-datatable";

import styles from "./DatatableTabs.module.scss";
import TabContent from "./components/TabContent";
import CopyFilterButton from "./components/CopyFilterButton";
import { copyIntersectingColumns, copyIntersectingFilters } from "./utils";
import {
  FormDatatable,
  DatatableAccessor,
  InitialVersionedFilterState
} from "./types";

interface Props {
  formName: string;
  setAddDatatableOpen: (isOpen: boolean) => void;
  isAddDatatableOpen: boolean;
  currentVersion: string;
  disableAddDatatableButton: (status: boolean) => void;
  initialVersionedFilterState: InitialVersionedFilterState;
}

const getDatatableTabs = (
  formDatatables: { [key: string]: FormDatatable },
  currentVersion: string,
  currentVersionDatatableKeys: string[]
) => {
  const datatableTabs: string[] = [];

  currentVersionDatatableKeys.forEach(code => {
    const versionKey =
      currentVersion === "Default"
        ? formDatatables[code].defaultVersion
        : currentVersion;

    if (
      formDatatables[code].filters[versionKey] &&
      formDatatables[code].selectedColumns[versionKey]
    ) {
      datatableTabs.push(code);
    }
  });

  return datatableTabs.sort();
};

const DatatableTabs = ({
  formName,
  setAddDatatableOpen,
  isAddDatatableOpen,
  currentVersion,
  disableAddDatatableButton,
  initialVersionedFilterState
}: Props) => {
  const [removeDatatableCode, setRemoveDatatableCode] = useState<string | null>(
    null
  );
  const [copyDatatableCode, setCopyDatatableCode] = useState<string | null>(
    null
  );
  const [copyPopoverDatatableCode, setCopyPopoverDatatableCode] = useState<
    string | null
  >(null);

  const { getValues, setValue, clearErrors } =
    useFormContext<yup.Asserts<typeof formSchema>>();

  const formDatatables = getValues(formName as any);
  const datatableKeys = Object.keys(initialVersionedFilterState);
  const currentVersionDatatableKeys = Object.keys(formDatatables) || [];

  const activeFormDatatables: string[] = [];
  datatableKeys.forEach(key => {
    const hasActiveValues =
      Object.keys(formDatatables[key]?.filters || {}).length > 0 &&
      Object.keys(formDatatables[key]?.selectedColumns || {}).length > 0;
    if (hasActiveValues) {
      activeFormDatatables.push(key);
    }
  });

  const datatableTabCodes = getDatatableTabs(
    formDatatables,
    currentVersion,
    currentVersionDatatableKeys
  );

  const [activeDatatableTabCode, setActiveDatatableTabCode] = useState<
    string | null
  >(datatableTabCodes[0]);

  const versionedDatatableKeys = ((): DatatableAccessor[] | undefined => {
    if (currentVersion === "Default") {
      return currentVersionDatatableKeys?.map((datatableCode: string) => {
        return {
          key: datatableCode,
          version: formDatatables[datatableCode].defaultVersion
        };
      });
    }
    return currentVersionDatatableKeys?.map((datatableCode: string) => ({
      key: datatableCode,
      version: currentVersion
    }));
  })();

  const handleAddDatatable = (addedDatatableKeys: string[]) => {
    addedDatatableKeys.forEach(addedDatatableKey => {
      setValue(
        `${formName}.${addedDatatableKey}.columns` as any,
        initialVersionedFilterState[addedDatatableKey].columns
      );
      setValue(
        `${formName}.${addedDatatableKey}.defaultVersion` as any,
        initialVersionedFilterState[addedDatatableKey].defaultVersion
      );
      setValue(
        `${formName}.${addedDatatableKey}.filterable` as any,
        initialVersionedFilterState[addedDatatableKey].filterable
      );
      setValue(
        `${formName}.${addedDatatableKey}.selectedColumns` as any,
        initialVersionedFilterState[addedDatatableKey].selectedColumns
      );
      setValue(
        `${formName}.${addedDatatableKey}.filters` as any,
        initialVersionedFilterState[addedDatatableKey].filters
      );
      setValue(
        `${formName}.${addedDatatableKey}.diffColumns` as any,
        initialVersionedFilterState[addedDatatableKey].selectedColumns
      );
    });

    if (
      datatableKeys?.length ===
      activeFormDatatables.length + addedDatatableKeys.length
    ) {
      disableAddDatatableButton(true);
    }

    setAddDatatableOpen(false);
  };

  const handleRemove = (code: string) => {
    clearErrors(`${formName}.${code}` as any);

    const currentFormValues = getValues(formName as any);

    delete currentFormValues[code];

    setValue(formName as any, currentFormValues);

    if (code === datatableTabCodes[0] && datatableTabCodes.length === 1) {
      setActiveDatatableTabCode(null);
    } else if (code === datatableTabCodes[0]) {
      setActiveDatatableTabCode(datatableTabCodes[1]);
    } else {
      setActiveDatatableTabCode(datatableTabCodes[0]);
    }

    disableAddDatatableButton(false);

    setRemoveDatatableCode(null);
  };

  const handleCopyDatatable = (
    originKey: string,
    originVersion: string,
    targetKey: string,
    targetKeyVersion: string
  ) => {
    const formValues = getValues(formName as any);

    const targetColumnBlacklist = copyIntersectingColumns(
      formValues[originKey].selectedColumns[originVersion],
      formValues[originKey].columns,
      formValues[targetKey].selectedColumns[targetKeyVersion],
      formValues[targetKey].columns
    );

    const targetColumnFilters = copyIntersectingFilters(
      formValues[originKey].filters[originVersion],
      formValues[targetKey].filters[targetKeyVersion],
      formValues[targetKey].filterable
    );

    setValue(
      `${formName}.[${targetKey}].selectedColumns[${targetKeyVersion}]` as any,
      targetColumnBlacklist,
      { shouldTouch: true, shouldDirty: true }
    );

    setValue(
      `${formName}.[${targetKey}].filters[${targetKeyVersion}]` as any,
      targetColumnFilters,
      { shouldTouch: true, shouldDirty: true }
    );

    setCopyDatatableCode(null);
    setCopyPopoverDatatableCode(null);
  };

  const activeDatatableFormName = `${formName}.${activeDatatableTabCode}`;

  useEffect(() => {
    setActiveDatatableTabCode(datatableTabCodes[0]);
  }, [currentVersion]);

  return (
    <div className={styles.tabs}>
      <NefTabs>
        {datatableTabCodes.map(datatableTabCode => (
          <Tab
            data-testid={`${datatableTabCode}-Tab`}
            id={datatableTabCode}
            active={activeDatatableTabCode === datatableTabCode}
            action={
              <div className={styles["action-container"]}>
                <CopyFilterButton
                  datatableCode={datatableTabCode}
                  openCopyDatatable={code => setCopyDatatableCode(code)}
                  setPopover={code => setCopyPopoverDatatableCode(code)}
                  selectedPopoverCode={copyPopoverDatatableCode}
                />
                <button
                  data-testid={`${datatableTabCode}-Tab-Delete-Button`}
                  type="button"
                  onClick={() => setRemoveDatatableCode(datatableTabCode)}
                >
                  <span>
                    <FontAwesomeIcon iconClassName="fa-times" />
                  </span>
                </button>
              </div>
            }
            key={datatableTabCode}
            onClick={() => {
              setActiveDatatableTabCode(datatableTabCode);
            }}
          >
            <div className={styles["tab-code"]}>{datatableTabCode}</div>
          </Tab>
        ))}
      </NefTabs>

      <TabContent
        key={activeDatatableTabCode}
        formName={activeDatatableFormName}
        currentVersion={currentVersion}
      />

      {isAddDatatableOpen && (
        <AddDatatable
          datatableKeys={
            datatableKeys?.filter(
              datatableKey => !activeFormDatatables?.includes(datatableKey)
            ) || []
          }
          onClose={() => setAddDatatableOpen(false)}
          onSubmit={handleAddDatatable}
        />
      )}

      {removeDatatableCode && (
        <RemoveDatatable
          code={removeDatatableCode}
          onSubmit={handleRemove}
          onClose={() => setRemoveDatatableCode(null)}
        />
      )}

      {copyDatatableCode && (
        <CopyDatatable
          originKey={copyDatatableCode}
          originVersion={
            currentVersion === "Default"
              ? formDatatables[copyDatatableCode].defaultVersion
              : currentVersion
          }
          datatableKeys={versionedDatatableKeys || []}
          onSubmit={handleCopyDatatable}
          onClose={() => {
            setCopyDatatableCode(null);
            setCopyPopoverDatatableCode(null);
          }}
        />
      )}
    </div>
  );
};

export default DatatableTabs;
