const SessionStorageBuilder = (key: string) => {
  return {
    get: () => {
      const value = window.sessionStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    },
    set: (value: any) => {
      if (value === null) {
        window.sessionStorage.removeItem(key);
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(value));
      }
    }
  };
};

export default SessionStorageBuilder;
