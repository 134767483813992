import { useState } from "react";

import { StepProps } from "./types";

interface StateNavigatorProps {
  children: (props: {
    navigateTo: (path: string, replace?: boolean) => void;
    matchStep: (step: StepProps) => boolean;
  }) => JSX.Element;
}

const StateNavigator = ({ children }: StateNavigatorProps) => {
  const [currentLocation, setCurrentLocation] = useState("");

  const navigateTo = (path: string) => {
    setCurrentLocation(path);
  };
  const matchStep = (step: StepProps) => {
    return step.path === currentLocation;
  };

  return children({ navigateTo, matchStep });
};

export default StateNavigator;
