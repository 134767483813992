import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormRadioCheckboxButton } from "@nef/core";

import { NewProductRequest } from "./types";

const SkipIntakeCheckbox = () => {
  const { control, watch } = useFormContext<NewProductRequest>();
  const productType = watch("productType");

  return (
    <Controller
      name="skipIntake"
      control={control}
      render={({ field: { onChange, value, name } }) => {
        return (
          <FormRadioCheckboxButton
            id="SkipIntake"
            name={name}
            type="checkbox"
            label="Skip Intake"
            value={!!value}
            onChange={onChange}
            data-testid="newProductModal_skipIntake"
            disabled={productType === "SKILL"}
          />
        );
      }}
    />
  );
};

export default SkipIntakeCheckbox;
