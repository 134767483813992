import React from "react";
import { Button } from "@nef/core";

import useHydratePlanCategories from "../../hooks/useHydratePlanCategories";
import useWizard from "../../hooks/useWizard";
import { useProductContext } from "../../hooks/useProductContext";
import { ProductFormMode } from "../../api/types";

import ProductStepHeader from "./components/ProductStepHeader";
import styles from "./GrandfatheredPricingPlansSection.module.scss";
import PlanCategoryTable from "./components/PlanCategoryTable";

const GrandfatheredPricingPlansSection = () => {
  const { navigateTo } = useWizard();
  const { product } = useProductContext(ProductFormMode.EDIT);

  const { planCategories, isLoading } = useHydratePlanCategories(
    product.planCategories,
    false,
    true
  );

  return (
    <div className={styles.container} data-testid="grandfatheredPricingPlans">
      <div className={styles["header-bar"]}>
        <ProductStepHeader size={1}>
          Grandfathered Licenced Plans
        </ProductStepHeader>
        <Button
          outline={true}
          onClick={() => navigateTo("plans")}
          data-testid="grandfatheredPricingPlans_showCurrentPlans"
        >
          Current Plan
        </Button>
      </div>
      {isLoading ? (
        <div>Loading plans...</div>
      ) : (
        planCategories?.map(planCategory => {
          return (
            <PlanCategoryTable
              planCategory={planCategory}
              key={planCategory.id}
            />
          );
        })
      )}
    </div>
  );
};

export default GrandfatheredPricingPlansSection;
