export interface User {
  id: string;
  email: string;
  apiKey: string;
  productManagementGroupIds: number[] | null;
  firstName: string;
  lastName: string;
  jobTitle: string;
  rolesArray: string[];
  adminableVendorIds: number[];
  manageableVendorIds: number[];
  manageableDatatableCollectionIds: number[];
}

interface VendorImage {
  url: string;
  large: {
    url: string;
  };
  thumb: {
    url: string;
  };
}

export type OrganizationProductPermission = {
  id: string;
  productName: string;
};

export type ProductPermission = {
  id: string;
  name: string;
};
export type NewUserGroup = {
  id: string;
  name: string;
};
export type NewVendorUser = {
  id: string;
  userId: string;
  userName: string;
  userEmail: string;
  userInviteEmail: string;
  userInviteAcceptedAt: string;
  createdAt: string;
  lastSignInAt: string;
  vendorId: string;
  vendorUserGroups: NewUserGroup[];
  products: ProductPermission[];
};
export interface Vendor {
  id: string;
  name: string;
  description?: string;
  code: string;
  profileImage?: VendorImage;
  licences?: Licence[];
  createdAt?: string;
  updatedAt?: string;
  vendorUsersCount?: number;
  activeProductsCount?: number;
  productsCount?: number;
  viewPublisher?: string;
  vendorUsersLastLoggedInAt: string;
  products?: ProductPermission[];
  hasLicences?: boolean;
}

export interface Organization {
  id: string;
  name: string;
  distributorId?: string;
  createdAt?: string;
  updatedAt?: string;
  usersCount?: number;
  productsCount?: number;
  organizationType?: string;
}

export interface OrganizationUser {
  id: string;
  fullName: string;
  role: "admin" | "user";
  email?: string;
  createdAt?: string;
  productsCount?: number;
  inviteEmail?: string;
  productAccess?: {
    id: string;
    productName: string;
  }[];
  lastLoginAt?: string;
  organizationTeams: any;
}

export interface OrganizationTeam {
  id: string;
  name: string;
  createdAt?: string;
  productsCount?: number;
  usersCount?: number;
  productAccess?: {
    id: string;
    productName: string;
  }[];
}

export interface NewUser {
  name: string;
  email: string;
  fullName: string;
}

export interface ProductAccess {
  users: NewUser[];
  userGroups: NewUserGroup[];
}

export interface OrganizationProducts {
  productAccess: ProductAccess;
  id: string;
  productName: string;
  subscriptionType: string;
  historyOption: string;
  updatedAt?: string;
  usersCount?: number;
  enabled: boolean;
}

export interface DatatableCollection {
  code: string;
  productApprovalStatus: string;
  productApprovalType: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  subscribersCount: number;
  id: string;
  administratorContactVendorUserId: string;
  administratorContactVendorUserName: string;
  administratorContactVendorUserInviteEmail: string;
  skillUrl: string;
}

export interface AISerivces {
  namespace: string;
  service: string;
  url: string;
}
export interface Licence {
  id: string;
  active: boolean;
  body: string;
  name: string;
  summary: string;
}
export interface PlanCategory {
  id: string;
  name: string;
  description: string;

  licence: Licence;
  plans?: Plan[];
}

type EmptyObject = {
  [K in any]: never;
};

export interface VendorUser {
  createdAt: string;
  id: string;
  isAdmin: boolean;
  lastSignInAt: string;
  productsCount: number;
  status: string;
  userEmail: string;
  userId: number;
  userInviteEmail: null | string;
  userName: string;
  vendorId: Number;
}

export interface UserGroup {
  id: string;
  vendorId: number;
  createdAt: string;
  totalNumberOfUsers: number;
  name: string;
  vendorUsers: VendorUser[];
}

export interface BasePlan {
  name: string;
  active: boolean;
  allowContactSales: boolean;
  allowPayment: boolean;
  amountCents: number | null;
  currency: "cad" | "usd";
  interval: "year" | "month";
  intervalCount: number;
  filterLabels: EmptyObject | { history: "Full history" };
}

export interface Plan extends BasePlan {
  id: string;
  providerId: string;
  filters: PlanFilters;
  readonly humanizedAdverb: string;
  readonly updatedAt: Date;
  readonly planCategoryId: number;
  readonly subscriberCount?: number;
}
export interface NewPlan extends BasePlan {
  id?: string;
}
export interface PlanFilters {
  [key: string]: {
    columns: { FINAL: null };
    filters: { FINAL: null };
  };
}

interface BaseProductAttributes {
  productType: ProductType;
  shares?: any;
  name: string;
  code: string;
  overview: string;
  description: string;
  history: string;
  since: string;
  coverage: string;
  reportingLag: string;
  gisSalesTeamEmail: string;
  productCategories: BaseProductCategory[];
  deliveryFrequency: string[];
  deliveryFrequencyNotes: string | null;
  dataFrequency: string[];
  dataFrequencyNotes: string | null;
  documentation: string;
  datatables: ProductDatatable[] | null;
  productManagementGroupId: number | null;
  forSale: boolean | null;
  internal: boolean | null;
  searchTags: string | null;
  recommendedProductCodes: string | null;
  skipIntake: boolean | null;

  readonly gisSalesTeam: boolean;
  readonly internalSales: boolean;
  readonly active: boolean;
  readonly allowContactSales: boolean;
  readonly exclusive: boolean;
  readonly hidden: boolean;
  readonly intraday: boolean;
  readonly premium: boolean;
  readonly sample: boolean;
}

export interface Product extends BaseProductAttributes {
  id: string;
  vendor: Vendor;
  productSupportingDocuments: ProductSupportingDocument[];
  productMetadatum: ProductMetadatum;
  capProductMetadatum: CapProductMetadatum;
  planCategories: PlanCategory[];
  plans: Plan[];
  productManagementGroup: ProductManagementGroup;

  readonly productApprovalStatus: string | null;
  readonly productApprovalType: string | null;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  readonly productModelsUpdatedAt?: Date;
  readonly productModelsUpdater?: User;
}

export interface NewProduct extends BaseProductAttributes {
  id?: string;
  vendor?: Vendor;
  productMetadatum?: NewProductMetadatum;
  productSupportingDocuments?: NewProductSupportingDocument[];
  planCategories?: PlanCategory[];
  plans?: Plan[];
}

export interface ProductToCreate extends BaseProductAttributes {
  vendor?: Vendor;

  readonly productApprovalStatus: string | null;
  readonly productApprovalType: string | null;
}

export interface ProductFormContextValues extends ProductFormValues {
  productMetadatum: ProductMetadatumFormValues;
  capProductMetadatum: CapProductMetadatum;
}

export interface ProductToEdit extends BaseProductAttributes {
  id: string;
  vendor: Vendor;
  productMetadatum: ProductMetadatumToEdit;
  capProductMetadatum: CapProductMetadatum;
  productSupportingDocuments: NewProductSupportingDocument[];
  planCategories: PlanCategory[];
  plans: Plan[];
  productManagementGroup: ProductManagementGroup;

  readonly productApprovalStatus: string | null;
  readonly productApprovalType: string | null;
}

export interface ProductFormValues extends BaseProductAttributes {
  vendor?: Vendor;
  plans?: Plan[];

  readonly productApprovalStatus: string | null;
  readonly productApprovalType: string | null;
}

export enum ProductFormMode {
  CREATE = "create",
  EDIT = "edit"
}

export type ProductType = "CORE" | "ADP" | "ESG" | "CAP";

export interface ProductDatatable {
  code: string;
}

interface BaseCapProductAttributes {
  exclusive: true;
}

export interface CapProductToCreate
  extends Omit<ProductToCreate, "exclusive">,
    BaseCapProductAttributes {
  vendor?: Vendor;
  capProductMetadatum: CapProductMetadatum;
}

export interface CapProductToEdit
  extends Omit<ProductToEdit, "exclusive">,
    BaseCapProductAttributes {
  id: string;
  vendor: Vendor;
  productMetadatum: ProductMetadatumToEdit;
  productSupportingDocuments: NewProductSupportingDocument[];
  planCategories: PlanCategory[];
  plans: Plan[];
  capProductMetadatum: CapProductMetadatum & { id: string };
}

export interface CapProductMutationResponse
  extends Omit<ProductToCreate, "productType" | "exclusive">,
    BaseCapProductAttributes {
  id: string;
}

export interface saveProductResponse {
  data: CapProductMutationResponse | undefined;
  errors: StringMap[];
}

export interface saveMetadataResponse {
  data: ProductMetadatum | undefined;
  errors: StringMap[];
}

export interface saveCapMetadataResponse {
  data: Nullable<BaseProductMetadatum> | undefined;
  errors: StringMap[];
}

export interface AllCapFormProductData {
  capProduct?: CapProductToCreate | CapProductToEdit | undefined;
  metadata?: ProductMetadatumToEdit | undefined;
  capMetadata?: CapProductMetadatum | undefined;
}

export type ADPMetadatumFieldsWithScore =
  | "dataFrequencyScore"
  | "dataQualityScore"
  | "lengthOfHistoryScore"
  | "marketAwarenessScore"
  | "uniquenessScore";

export type ADPMetadatumFieldsWithDescription =
  | "dataFrequencyDescription"
  | "dataQualityDescription"
  | "lengthOfHistoryDescription"
  | "marketAwarenessDescription"
  | "uniquenessDescription";

interface BaseProductMetadatumAttributes
  extends Record<ADPMetadatumFieldsWithScore, number | null>,
    Record<ADPMetadatumFieldsWithDescription, string | null> {
  dataFrequencyScore: number | null;
  dataFrequencyDescription: string | null;
  dataQualityScore: number | null;
  dataQualityDescription: string | null;
  lengthOfHistoryScore: number | null;
  lengthOfHistoryDescription: string | null;
  marketAwarenessScore: number | null;
  marketAwarenessDescription: string | null;
  uniquenessScore: number | null;
  uniquenessDescription: string | null;
  status: string | null;
  grade: number | null;
  sdgIndicators: string[];
  frameworkRegulationNotes: string | null;
  rank: number;
}

export interface ProductMetadatum extends BaseProductMetadatumAttributes {
  id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface NewProductMetadatum extends BaseProductMetadatumAttributes {
  id?: string;
}

export interface ProductMetadatumToEdit extends BaseProductMetadatumAttributes {
  id: string;
}

export interface ProductMetadatumFormValues
  extends BaseProductMetadatumAttributes {}

interface BaseProductSupportingDocumentAttributes {
  private?: boolean;
  name: string;
  documentType: string;
  supportingDocument: {
    identifier: string | null;
    url: string | null;
  };
}

export interface ProductSupportingDocument
  extends BaseProductSupportingDocumentAttributes {
  id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}
export interface NewProductSupportingDocumentPayload {
  product?: Pick<ProductToEdit, "id">;
  private?: boolean;
  name: string;
  documentType: string;
}

export interface SupportingDocument {
  id?: string;
  datatableCollectionId?: string;
  documentType: string;
  name: string;
  supportingDocument: {
    identifier: string | null;
    url: string | null;
  };
  createdAt?: string;
  updatedAt?: string;
  private?: boolean;
}

export interface NewProductSupportingDocument
  extends BaseProductSupportingDocumentAttributes {
  id?: string;
  isUploading?: boolean;
  isEditing?: boolean;
  product?: NewProduct | any;
}

export interface SdgIndicator {
  code: string;
  sortOrder: number;
}

interface BaseProductMetadatum {
  id: string;
  tags: string[];
  datatablesNameDescriptions: DatatablesNameDescription[];
  additionalMaterialUrls: string[];
  procurementDocumentUrls: string[];
  knownIssues: string;
  sampleDataUrl: string;
  relatedData: string;

  deliveryLag: string;
  dataTimezone: string[];
  pointInTime: string;
  dataHolidaySchedule: string;
  infosecClassification: string;
  gdprRestrictions: boolean;
  gdprRestrictionsNotes: string;
  personallyIdentifiableInfo: string;
  secRegulation: boolean;

  dataSource: string;
  dataSourceNotes: string;
  dataProvenance: string;
  dataOriginalOrDerived: string;

  licensedFromVendor: boolean;
  vendorId: number | null;
  vendorName: string;
  thirdPartyProviderLicence: string;
  vendorContactName: string;
  vendorContactEmail: string;
  vendorContactPhone: string;

  dataLicenceTermsConditions: string;
  procurementContractualConsiderations: string;
  pricingOption: string;
  visibilityPreference: string;
  searchIndexing: boolean;

  dataSourceFormat: string[];
  dataSourceFormatNotes: string;

  dataIngestMethod: string[];
  dataIngestMethodNotes: string;
  dataSize: string;
  dataUpdateSize: string;
  dataHosting: boolean;
  updatePattern: string;
  updatePatternNotes: string;

  dataOwnerName: string;
  dataOwnerEmail: string;

  technicalContactName: string;
  technicalContactEmail: string;
}

export interface DatatablesNameDescription {
  id: string;
  name: string;
  code: string;
  description: string;
  dataGranularity: string;
  dataGranularityNotes: string;
  updateFrequency: Schedule;
  reportingLag: { value: number; unit: string };
  deliveryLag: { value: number; unit: string };
  pointInTime: boolean | null;
  holidayImpacts: string[];
  customHolidayImpactDescription: string;
  sourceFormat: string;
  ingestMethod: string;
  remotePath: string;
  filePattern: string;
  portNumber: string;
  username: string;
  password: string;
  pubKey: string;
  ingestDetails: string;
  tableSize: number;
  incrementalUpdateSize: string;
  incrementalRowCount: number;
  updatePattern: string;
  sampleDataUrl: string[];
  schemaFileUrl: string[];
  schemaErrors: SchemaError[] | null;
  schemaRequiresPrimaryKeys: boolean;
}

export type SchemaErrorType =
  | "invalid-csv"
  | "table-name-missing"
  | "table-name-mismatch"
  | "column-name-missing"
  | "column-name-with-space"
  | "column-name-with-invalid-character"
  | "data-type-missing"
  | "data-type-not-supported"
  | "filter-missing"
  | "header-format-invalid"
  | "primary-key-missing";

export type SchemaError = {
  type: SchemaErrorType;
  title: string;
  message: string;
};

export type CapProductMetadatum = Nullable<BaseProductMetadatum>;

/**
 * Update Frequency related types
 */
export type NonRecurringSchedule = {
  freq: "realtime" | "history only" | "irregular";
} & NonRecurringRules;

export type NonRecurringRules = {
  custom: {
    note: string;
  };
};

export type IntradayRules = {
  custom: {
    deliveryInterval: {
      value: number;
      unit: string;
    };
    firstDelivery: string;
    lastDelivery: string;
    timezone: string;
    description: string;
  };
};

export type IntradaySchedule = {
  freq: "intraday";
} & IntradayRules;

// Recurring rules based on iCalendar RRULE. This should make it easier for the server
// to parse recurring rules and set up automations in the future.
export type RecurringRules = {
  // interval between occurrences, eg. "FREQ=WEEKLY;INTERVAL=2" means every other week
  interval: number;
  // number of occurences; cannot have both `count` and `until`
  count?: number;
  // end date of the occurrences; cannot have both `count` and `until`
  until?: string;
  // day(s) of the week
  byday?: ByDay[];
  // day(s) of the month
  bymonthday?: number[];
  // month(s)
  bymonth?: number[];
  // filters nth occurrences for byday/bymonthday/bymonth
  // eg. "FREQ=MONTHLY;BYDAY=MO,TU,WE,TH,FR;BYSETPOS=2" means second weekday of every month
  // eg. "FREQ=MONTHLY;BYDAY=SA,SU;BYSETPOS=-1" means last weekend of every month
  bysetpos?: number[];
};

export type RecurringSchedule = {
  // iCalendar format; frequency of occurrences
  freq: "daily" | "weekly" | "monthly" | "annually";
  // iCalendar format; start datetime for the recurring occurrences
  dtstart: string;
  // iCalendar format; timezone associated with the occurrences
  tzid: string;
} & RecurringRules;

export type Schedule =
  | NonRecurringSchedule
  | IntradaySchedule
  | RecurringSchedule;

export type ByDay = "MO" | "TU" | "WE" | "TH" | "FR" | "SA" | "SU";

/**
 * Product Management Group
 */
export interface ProductManagementGroup {
  id: number;
  name: string;
  vendors?: Vendor[];
  capFormAccess: boolean;
  canPublishProduct: boolean;
  userProductManagementGroups?: UserProductManagementGroup[];
}

export interface UserProductManagementGroup {
  id: string;
  userId: number;
  productManagementGroupId: number;
  productManagementGroupRoles: string[];
}

export interface DatasetData {
  columns: DatatableColumnFromAPI[];
  data: any[][];
}

interface DatatableSchemaCreator {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface DatatableSchemaSource {
  action: "replace" | "update";
  cronTime: string | null;
  filePattern: string;
  host: string;
  username: string;
  password: string;
  type: "s3" | "ftp" | "sftp";
}

export interface DatatableSchema {
  name: string;
  code: string;
  vendorCode: string;
  description?: string;
  creator: DatatableSchemaCreator;
  managedBy?: string;
  vendorId?: number;
  version: {
    code: string;
    default: boolean;
    description: string;
  };
  source: null | DatatableSchemaSource;
  columns: DatatableColumn[];
  fileFormat?: DatatableFileFormat;
  backend: "s3" | "postgres";
  partitions: string[];
  readonly updatedAt: Date;
  readonly createdAt: Date;
}

export interface DatatableColumnFromAPI {
  name: string;
  type: string;
  defaultFilters?: DefaultFilter[];
}
export interface DatatableColumn extends DatatableColumnFromAPI {
  baseType: DatatableColumnBaseTypeOption | "";
  precision?: number | null;
  scale?: number | null;
  dateFormat?: string | null;
  isNew: boolean;
  isIndex?: boolean;
  isPrimaryKey?: boolean;
  isPartitioned?: boolean;
  isSampleColumn?: boolean;
  defaultFilters?: DefaultFilter[];
}
export interface NewDatatableSchema {
  name: string;
  code: string;
  vendorCode: string;
  columns: DatatableColumn[];
  fileFormat?: DatatableFileFormat;
}
export type DatatableColumnBaseTypeOption =
  | "string"
  | "date"
  | "integer"
  | "datetime"
  | "double"
  | "decimal";

export interface DefaultFilter {
  key: string;
  operator: null | "eq" | "nin" | "in" | "gte" | "lte" | "lt" | "gt";
  value: null | string | number | string[] | number[];
}

export interface DatatableFileFormat {
  delimiter: "," | "|" | ";";
  skipRows: number;
  skipBottomRows: number;
  quotedWith: "'" | '"';
  encoding: "latin-1" | "utf";
}

export interface UploadEvent {
  id: number;
  body: {
    fileSize: string;
    errors?: string[];
  };
  createdAt: string;
  media: string;
  status: string;
}

export interface BaseProductCategory {
  categoryType?: string;
  id: string;
  name: string;
  sortOrder?: number;
  parentId?: string;
}
export interface ProductCategory extends BaseProductCategory {
  categoryType: string;
  children: BaseProductCategory[];
}

export interface HelpscoutCategory {
  id: string;
  hsName: string;
}

export interface UploadFile {
  id: string;
  path: string;
  size: string;
  lastModified: Date;
}

export interface AccumulatedColumnStats {
  [key: number]: ColumnStatsEntry;
}
export interface ColumnStatsEntry {
  types: ColumnTypeEntry;
  header: string;
}
export interface ColumnTypeEntry {
  [type: string]: ColumnTypeStats;
}
export interface ColumnTypeStats {
  type: string;
  count: number;
  whole?: number;
  scale?: number;
}

export type PaginationLinkFields = "first" | "last" | "next" | "prev";

export interface PaginationLinks extends Record<PaginationLinkFields, string> {}

export interface ResponseWithCursorPageInfo<T> {
  data: JSONAPIResource<T> | JSONAPIResource<T>[];
  page_info?: CursorPageInfoFromAPI; // eslint-disable-line camelcase
}

export interface CursorPageInfoFromAPI {
  /* eslint-disable camelcase */
  prev_page: string | null;
  next_page: string | null;
  per_page: number;
  /* eslint-enable camelcase */
}

export interface CursorPageInfo {
  prevPage: string | null;
  nextPage: string | null;
  perPage: number;
}

export type FilterableColumnValues = "isIndex" | "isPrimaryKey" | null;

type SortPrefix = "" | "-";
export type SortableProductField = "name" | "date";
export type ProductSortParam = `${SortPrefix}${SortableProductField}`;

export type SortableDatatableField = "name" | "date";
export type DatatableSortParam = `${SortPrefix}${SortableDatatableField}`;

export type SortablePublisherField =
  | "name"
  | "createdAt"
  | "updatedAt"
  | "vendorUsersCount"
  | "activeProductsCount"
  | "productsCount";
export type VendorSortParam = `${SortPrefix}${SortableProductField}`;

export type SortableOrganizationField =
  | "name"
  | "distributorId"
  | "createdAt"
  | "updatedAt"
  | "usersCount"
  | "productsCount";
export type OrganizationSortParam = `${SortPrefix}${SortableOrganizationField}`;

export type SortableUserField = "name" | "lastJoined" | "email" | "lastLogin";
export type UserSortParam = `${SortPrefix}${SortableUserField}`;

export type SortableUserGroupField =
  | "name"
  | "createdAt"
  | "totalNumberOfUsers";
export type UserGroupSortParam = `${SortPrefix}${SortableUserGroupField}`;
export interface GroupPathParams {
  groupId: string;
}
export interface AISkillPathParams {
  skillId: string;
}

/**
 * JSONAPI-related Types
 */

export interface JSONAPIResource<T> {
  id: string;
  type: string;
  attributes: T;
}

export interface JSONAPIResponse<T> {
  data: JSONAPIResource<T> | JSONAPIResource<T>[];
  links?: PaginationLinks;
}

/**
 * Utility Types
 */
export type UniqueEntry<T extends {}> = T & {
  uuid: string;
};

export interface StringIndexable {
  [key: string]: any;
}

// similar to Partial<T>, but makes every field nullable instead of optional
export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface StringMap {
  [key: string]: any;
}
