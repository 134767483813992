export const PRODUCT_CODE_INFO =
  "Code is used to uniquely identify the product on Data Link. Example is NDQ for Nasdaq.";
export const PRODUCT_DESCRIPTION_INFO =
  "Max characters for this field is 1000. Please use documentation section to add more information.";
export const PRODUCT_COVERAGE_INFO =
  "Coverage represents the market coverage of the product.";
export const PRODUCT_CONTACT_SALES_EMAIL_INFO =
  "Email address to receive sales leads generated from Nasdaq Data Link for this product.";
export const PRODUCT_DOCUMENTATION_INFO =
  "This area is used to build out the details of the product for the customer. Refer to http://www.markdownguide.org/cheat-sheet/ for details on how to use markdown language to format the information.";
export const PRODUCT_REPORTING_LAG_INFO =
  "Reporting Lag represents the time interval for your report generation.";
export const PRODUCT_PRIVATE_INFO =
  "Enable this if you are creating a page that is hidden from public users. If enabled this page will not be available to general users or in search.";
export const PRODUCT_INTRADAY_INFO =
  "If No, the user will be advised to use the API to retrieve the data. If yes, the user will be advised to use Bulk Download to retrieve the data.";
export const PRODUCT_SAMPLE_INFO =
  "Indicate on your page if sample data is available for users with an account";
export const PRODUCT_IS_INTERNAL_INFO =
  "Toggle to allow Nasdaq sales team to sell this product";
