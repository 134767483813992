import React, { useState, useEffect, ReactNode, useRef } from "react";
import { camelizeKeys } from "humps";
import { useHistory } from "react-router-dom";

import SessionAuthContext, {
  AuthenticatedUser
} from "../../contexts/session-auth";
import { fetchUserSession } from "../../api/api";
import client from "../../api/manage/client";
import UserService from "../../services/user";
import { User } from "../../api/types";

interface SessionAuthProviderProps {
  children?: ReactNode;
}

interface AuthState {
  user: AuthenticatedUser;
  isAuthenticating: boolean;
}

const SessionAuthProvider = ({ children }: SessionAuthProviderProps) => {
  const history = useHistory();

  const [authState, setAuthState] = useState<AuthState>({
    user: UserService.getUser(),
    isAuthenticating: true
  });

  const setAuthenticatedUser = (user: AuthenticatedUser) => {
    setAuthState({
      user,
      isAuthenticating: false
    });
  };

  const isUnmountedRef = useRef(false);

  const isManagerAdmin = (): boolean =>
    authState?.user?.rolesArray?.includes("manager_admin") || false;

  const isPublisherUser = (): boolean =>
    authState?.user?.rolesArray?.includes("manager_publisher_user") || false;

  const isOrganizationUser = (): boolean =>
    authState?.user?.rolesArray?.includes("manager_organization_user") || false;

  const isAdminableVendor = (vendorId: string) =>
    authState?.user?.adminableVendorIds?.includes(parseInt(vendorId, 10)) ||
    false;

  const isManageableVendor = (vendorId: string) =>
    authState?.user?.manageableVendorIds?.includes(parseInt(vendorId, 10)) ||
    false;

  const isManageableDatatableCollection = (datatableCollectionId: string) =>
    authState?.user?.manageableDatatableCollectionIds?.includes(
      parseInt(datatableCollectionId, 10)
    ) || false;

  const isProductStatusIntake = (productApprovalStatus?: string | null) => {
    return (
      productApprovalStatus === "intake_draft" ||
      productApprovalStatus === "intake_pending_approval"
    );
  };

  const isProductStatusEditor = (
    productApprovalStatus?: string | null,
    productApprovalType?: string | null
  ) => {
    return (
      productApprovalStatus === "draft" ||
      productApprovalStatus === "pending_approval" ||
      productApprovalType === "no_approval_required"
    );
  };

  const login = () => {
    return fetchUserSession()
      .then(response => {
        if (isUnmountedRef.current) {
          return;
        }

        // /api/v3/users/me wraps data in `user` attribute
        const user = camelizeKeys(response.data.user) as unknown as User;
        UserService.saveUser(user);
        client.addHeader("X-API-Token", user.apiKey);

        setAuthenticatedUser(user);
      })
      .catch(() => {
        if (isUnmountedRef.current) {
          return;
        }

        UserService.removeUser();
        client.removeHeader("X-API-Token");

        setAuthenticatedUser(null);
      });
  };

  const logout = () => {
    UserService.removeUser();
    client.removeHeader("X-API-Token");
    setAuthenticatedUser(null);
    history.push("/");
  };

  const authenticated = () => {
    return !!authState.user;
  };

  useEffect(() => {
    login();

    return () => {
      isUnmountedRef.current = true;
    };
  }, []);

  return (
    <SessionAuthContext.Provider
      value={{
        authenticatedUser: authState.user,
        setAuthenticatedUser,
        authenticated,
        isAuthenticating: authState.isAuthenticating,
        login,
        logout,
        isManagerAdmin,
        isPublisherUser,
        isOrganizationUser,
        isAdminableVendor,
        isManageableVendor,
        isManageableDatatableCollection,
        isProductStatusEditor,
        isProductStatusIntake
      }}
    >
      {children}
    </SessionAuthContext.Provider>
  );
};

export default SessionAuthProvider;
