import React, { useState } from "react";
import { FormRadioCheckboxGroup, FormRadioCheckboxButton } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { useFormContext } from "react-hook-form";
import { isEqual } from "lodash";
import styled from "styled-components";

import {
  DayOfMonthRule,
  ScheduleRules,
  DayOfMonthFieldState,
  integers,
  DAY_OF_MONTH_ORDINALS,
  DAY_OF_MONTH_UNITS,
  isByDay
} from "../util";
import styles from "../Scheduler.module.scss";
import { ByDay } from "../../../../../api/types";

const isValidBySetpos = (setpos: number[]) => {
  return [1, 2, 3, 4, -1].includes(setpos[0]);
};

const SORTED_WEEKDAYS = ["MO", "TU", "WE", "TH", "FR"].sort();
const SORTED_WEEKEND_DAYS = ["SA", "SU"];

const bydayToOrdinalMeasure = (byday: ByDay[]) => {
  const sortedByday = byday.sort();

  if (isEqual(sortedByday, SORTED_WEEKDAYS)) {
    return "Weekday";
  }

  if (isEqual(sortedByday, SORTED_WEEKEND_DAYS)) {
    return "Weekday";
  }

  if (byday.length === 1 && isByDay(byday[0])) {
    return byday[0];
  }

  return "";
};

const stateToRule = (state: DayOfMonthFieldState): DayOfMonthRule => {
  const { dayOfMonthMode, numericalDay, ordinalDay, ordinalMeasure } = state;

  if (dayOfMonthMode === "numerical") {
    return {
      bymonthday: [numericalDay]
    };
  }

  if (ordinalMeasure === "Day") {
    return {
      bymonthday: [ordinalDay]
    };
  }

  if (ordinalMeasure === "Weekday") {
    return {
      byday: ["MO", "TU", "WE", "TH", "FR"],
      bysetpos: [ordinalDay]
    };
  }

  if (ordinalMeasure === "Weekend Day") {
    return {
      byday: ["SA", "SU"],
      bysetpos: [ordinalDay]
    };
  }

  if (isByDay(ordinalMeasure)) {
    return {
      byday: [ordinalMeasure],
      bysetpos: [ordinalDay]
    };
  }

  return {};
};

const ruleToState = (rule: DayOfMonthRule): DayOfMonthFieldState => {
  const { byday = [], bymonthday = [], bysetpos = [] } = rule;

  const defaultState: DayOfMonthFieldState = {
    dayOfMonthMode: "numerical",
    numericalDay: 1,
    ordinalDay: 1,
    ordinalMeasure: "Day"
  };

  if (isValidBySetpos(bysetpos) && bydayToOrdinalMeasure(byday)) {
    return {
      ...defaultState,
      dayOfMonthMode: "ordinal",
      ordinalDay: bysetpos[0],
      ordinalMeasure: bydayToOrdinalMeasure(byday)
    };
  }

  if (bymonthday.length) {
    return {
      ...defaultState,
      numericalDay: bymonthday[0]
    };
  }

  return defaultState;
};

const useDayOfMonthFieldState = (rule: DayOfMonthRule) => {
  const { setValue } = useFormContext<ScheduleRules>();

  const [state, setState] = useState<DayOfMonthFieldState>(() =>
    ruleToState(rule)
  );

  const updateForm = (stateValue: DayOfMonthFieldState) => {
    const newRule = stateToRule(stateValue);

    // UX-wise, seems more reasonable that day of month settings remain the same
    // when switching between monthly and annually views
    setValue("recurring.monthly.byday", newRule.byday);
    setValue("recurring.monthly.bymonthday", newRule.bymonthday);
    setValue("recurring.monthly.bysetpos", newRule.bysetpos);
    setValue("recurring.annually.byday", newRule.byday);
    setValue("recurring.annually.bymonthday", newRule.bymonthday);
    setValue("recurring.annually.bysetpos", newRule.bysetpos);
  };

  const setDayOfMonthMode = (newValue: "numerical" | "ordinal") => {
    const newState = { ...state, dayOfMonthMode: newValue };
    setState(newState);
    updateForm(newState);
  };

  const setNumericalDay = (newValue: number) => {
    const newState = { ...state, numericalDay: newValue };
    setState(newState);
    updateForm(newState);
  };

  const setOrdinalDay = (newValue: number) => {
    const newState = { ...state, ordinalDay: newValue };
    setState(newState);
    updateForm(newState);
  };

  const setOrdinalMeasure = (newValue: string) => {
    const newState = { ...state, ordinalMeasure: newValue };
    setState(newState);
    updateForm(newState);
  };

  return {
    state,
    setDayOfMonthMode,
    setNumericalDay,
    setOrdinalDay,
    setOrdinalMeasure
  };
};

const StyledFormRadioCheckboxGroup = styled(FormRadioCheckboxGroup)`
  margin-bottom: 0 !important;
`;

const StyledFormRadioCheckboxButton = styled(FormRadioCheckboxButton)`
  margin: 0.5rem 0;
`;

const DayOfMonthField = ({ rule }: { rule: DayOfMonthRule }) => {
  const {
    state,
    setDayOfMonthMode,
    setNumericalDay,
    setOrdinalDay,
    setOrdinalMeasure
  } = useDayOfMonthFieldState(rule);

  const { dayOfMonthMode, numericalDay, ordinalDay, ordinalMeasure } = state;

  return (
    <>
      <div> </div>
      <div>
        <StyledFormRadioCheckboxGroup
          id="dayOfMonthMode"
          name="dayOfMonthMode"
          type="radio"
          value={dayOfMonthMode}
          onChange={({ value: newValue }: CustomChangeEvent) => {
            setDayOfMonthMode(newValue as "numerical" | "ordinal");
          }}
        >
          <StyledFormRadioCheckboxButton
            id="dayOfMonthMode_numerical"
            name="dayOfMonthMode_numerical"
            value="numerical"
            label="&nbsp;"
          >
            <select
              id="numericalDay"
              value={numericalDay}
              className={styles.input}
              onChange={e => {
                const newDayOfMonthString = e.target.value;
                const newDayOfMonth = parseInt(newDayOfMonthString, 10);
                if (!Number.isNaN(newDayOfMonth)) {
                  setNumericalDay(newDayOfMonth);
                }
              }}
              disabled={dayOfMonthMode !== "numerical"}
            >
              {integers(1, 31).map(option => (
                <option key={`numericalDay-${option}`} value={option}>
                  {option}
                </option>
              ))}
            </select>
            day
          </StyledFormRadioCheckboxButton>
          <StyledFormRadioCheckboxButton
            id="dayOfMonthMode_ordinal"
            name="dayOfMonthMode_ordinal"
            value="ordinal"
            optional={true}
            label="&nbsp;"
          >
            <select
              id="ordinalDay"
              value={ordinalDay}
              className={styles.input}
              onChange={e => {
                setOrdinalDay(parseInt(e.target.value, 10));
              }}
              disabled={dayOfMonthMode !== "ordinal"}
            >
              {DAY_OF_MONTH_ORDINALS.map(option => (
                <option key={`ordinalDay-${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <select
              id="ordinalMeasure"
              value={ordinalMeasure}
              className={styles.input}
              onChange={e => {
                setOrdinalMeasure(e.target.value);
              }}
              disabled={dayOfMonthMode !== "ordinal"}
            >
              {DAY_OF_MONTH_UNITS.map(option => (
                <option
                  key={`ordinalMeasure-${option.value}`}
                  value={option.value}
                  disabled={!option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </StyledFormRadioCheckboxButton>
        </StyledFormRadioCheckboxGroup>
      </div>
    </>
  );
};

export default DayOfMonthField;
