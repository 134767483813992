import React from "react";
import { QueryArg, useQuery } from "jsonapi-react";
import { Redirect, useParams } from "react-router-dom";

import { CapProductToEdit } from "../../api/types";
import CapSurveyForm from "../../components/cap-survey-form/CapSurveyForm";
import { PATHS } from "../../routes";

const EditCapSurveyPage = () => {
  const { productId } = useParams<{ productId: string }>();

  const query: QueryArg = productId
    ? [
        "products",
        productId,
        {
          include: [
            "vendor",
            "product-categories",
            "product-supporting-documents",
            "product-metadatum",
            "cap-product-metadatum"
          ]
        }
      ]
    : false;

  const {
    data: product,
    isLoading,
    isFetching
  } = useQuery<CapProductToEdit>(query);

  if (isLoading) return null;

  if (
    product?.productApprovalStatus === "draft" ||
    product?.productApprovalStatus === "in_progress" ||
    product?.productApprovalType === "no_approval_required"
  ) {
    return <Redirect to={PATHS.PUBLISHERS} />;
  }

  if (!product)
    return (
      <div data-testid="editProduct_notFound">Product cannot be found</div>
    );

  return <CapSurveyForm product={product} isReady={!isFetching} />;
};

export default EditCapSurveyPage;
