import React from "react";

import arrow from "../../../assets/left-arrow-white.svg";
import bookmark from "../../../assets/bookmark-white.svg";
import "./style.scss";

type Props = {
  name: string;
};

const ProductHeader: React.FC<Props> = ({ name }) => {
  return (
    <nav className="sec-sub-nav">
      <div className="sec-sub-nav__container">
        <div className="sec-sub-nav__container-heading">
          <div className="back-to-search__link">
            <img
              className="b-button__arrow--left"
              src={arrow}
              alt="long-left-arrow"
            />
          </div>
          <h2
            className="sec-sub-nav__title"
            data-testid="productPreviewHeader_name"
          >
            {name}
          </h2>
        </div>
        <div className="sec-sub-nav__container-items">
          <ul className="sec-sub-nav__container-items-list">
            <li>
              <div className="list-link-style">Documentation</div>
            </li>
            <li>
              <div className="list-link-style">Usage</div>
            </li>
          </ul>

          <div className="bookmark-button_circle">
            <img
              className="bookmark-button__icon"
              alt="Bookmark Tagged Icon"
              src={bookmark}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default ProductHeader;
