import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  RecurringFreq,
  ScheduleRules,
  fieldToRruleDate,
  rruleToFieldDate
} from "./util";
import DayOfMonthField from "./fields/DayOfMonthField";
import DayOfWeekField from "./fields/DayOfWeekField";
import EndField from "./fields/EndField";
import IntervalField from "./fields/IntervalField";
import MonthField from "./fields/MonthField";
import styles from "./Scheduler.module.scss";
import TimezoneField from "./fields/TimezoneField";

interface RecurringViewProps {
  freq: RecurringFreq;
}

const DailyView = () => {
  return (
    <>
      <div className={styles.fieldset}>
        <IntervalField freq="daily" />
      </div>
    </>
  );
};

const WeeklyView = () => {
  return (
    <>
      <div className={styles.fieldset}>
        <IntervalField freq="weekly" />
      </div>
      <div className={styles.fieldset}>
        <DayOfWeekField freq="weekly" />
      </div>
    </>
  );
};

const MonthlyView = () => {
  const { getValues } = useFormContext<ScheduleRules>();
  const { bysetpos, byday } = getValues("recurring.monthly");

  return (
    <>
      <div className={styles.fieldset}>
        <IntervalField freq="monthly" />
      </div>
      <div className={styles.fieldset}>
        <DayOfMonthField rule={{ bysetpos, byday }} />
      </div>
    </>
  );
};

const AnnuallyView = () => {
  const { getValues } = useFormContext<ScheduleRules>();
  const { bysetpos, byday } = getValues("recurring.annually");

  return (
    <>
      <div className={styles.fieldset}>
        <MonthField />
      </div>
      <div className={styles.fieldset}>
        <DayOfMonthField rule={{ bysetpos, byday }} />
      </div>
    </>
  );
};

const RecurringView = ({ freq }: RecurringViewProps) => {
  const { control, getValues } = useFormContext<ScheduleRules>();

  const { until, count } = getValues("recurring");

  return (
    <>
      <div className={styles.label}>Starting</div>
      <Controller
        name="recurring.dtstart"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <input
            type="datetime-local"
            id={name}
            value={rruleToFieldDate(value)}
            className={styles.input}
            onChange={e => {
              onChange(fieldToRruleDate(e.target.value, "yyyy-MM-dd'T'HH:mm"));
            }}
          />
        )}
      />

      <div className={styles.label}>Timezone</div>
      <Controller
        name="recurring.tzid"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TimezoneField value={value} onChange={onChange} />
        )}
      />

      {freq === "daily" && <DailyView />}

      {freq === "weekly" && <WeeklyView />}

      {freq === "monthly" && <MonthlyView />}

      {freq === "annually" && <AnnuallyView />}

      <EndField rule={{ until, count }} />
    </>
  );
};

export default RecurringView;
