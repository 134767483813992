import React from "react";
import { Button, CircleButton, FontAwesomeIcon } from "@nef/core";
import styled from "styled-components";

import useFileManagerUpload from "../../../hooks/useFileManagerUpload";
import { getDownloadFileLink } from "../../../api/api";
import FormLabel from "../../common/FormLabel";

import styles from "./IntakeUploader.module.scss";

const UploadEntry = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const DownloadButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  flex: 1;
  text-align: start;
  padding: 1rem 1.5rem 1rem 0;
  color: #0092bc;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 1rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

function IntakeUploader({
  path,
  vendorId,
  onSuccess,
  onDeleteFile,
  value,
  testid,
  maxFiles = 1,
  label = "",
  required = false,
  templateDownloadUrl = "",
  accept = {}
}: {
  path: string;
  vendorId: string;
  onSuccess?: (names: string[], files: File[]) => void;
  onDeleteFile?: (_: string) => void;
  value: string[];
  testid: string;
  maxFiles?: number;
  label?: string;
  required?: boolean;
  templateDownloadUrl?: string;
  accept?: { [_: string]: string[] };
}) {
  const { getRootProps, getInputProps, isDragActive, open, isLoading } =
    useFileManagerUpload(path, vendorId, onSuccess, maxFiles, accept, true);

  const handleDownload = React.useCallback(async (fileName: string) => {
    const response = await getDownloadFileLink(`${path}/${fileName}`, vendorId);
    const url = response.data;
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }, []);

  const downloadTemplate = () => {
    const anchor = document.createElement("a");
    anchor.href = templateDownloadUrl;
    anchor.target = "_blank";
    anchor.rel = "noreferrer";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      {templateDownloadUrl.length !== 0 && (
        <div className={styles["template-button"]}>
          <Button size="sm" onClick={downloadTemplate}>
            DOWNLOAD TEMPLATE
          </Button>
        </div>
      )}
      <div
        className={`${styles.dropzone} ${
          isDragActive && styles["dropzone-active"]
        } ${isLoading && styles["dropzone-loading"]}`}
        {...getRootProps()}
      >
        <label htmlFor={testid}>
          <input
            {...getInputProps()}
            id={testid}
            data-testid={`${testid}_upload_input`}
          />
          Drag and drop to upload or&nbsp;
          <Button
            className={styles.browse}
            outline={true}
            onClick={open}
            size="sm"
            data-testid={`${testid}_upload_button`}
          >
            browse
          </Button>
        </label>
      </div>
      {value.map((v, index) => (
        <UploadEntry key={v}>
          <DownloadButton
            type="button"
            onClick={() => {
              handleDownload(v);
            }}
            title={v}
          >
            {v}
          </DownloadButton>
          {onDeleteFile && (
            <CircleButton
              ghost
              size="sm"
              onClick={() => {
                onDeleteFile(v);
              }}
              data-testid={`${testid}_delete_${index}`}
            >
              <FontAwesomeIcon iconClassName="fa-trash" />
            </CircleButton>
          )}
        </UploadEntry>
      ))}
    </>
  );
}

export default IntakeUploader;
