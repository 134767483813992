import React, { useCallback } from "react";
import { Button, FormSelect, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "jsonapi-react";

import extractFormSelectOnChangeValue from "../../../utils/nef-utils";
import Toast from "../../../components/Toast";
import { VendorUser } from "../../../api/types";
import manageClient from "../../../api/manage/client";
import FormFieldLabel from "../../../components/modals/components/FormFieldLabel";

import styles from "./add-user-to-admin-modal.module.scss";

interface AddUserToAdminModalProps {
  close: () => void;
  vendorId: string;
  isLoading: boolean;
}

const AddUserToAdminModal = ({
  close,
  vendorId,
  isLoading
}: AddUserToAdminModalProps) => {
  const { data: vendorUsers } = useQuery<VendorUser[]>([
    "vendor-users",
    {
      "filter[is_admin]": false,
      "filter[vendor_id]": vendorId
    }
  ]);

  const addUserAdminRequestSchema = yup.object({
    vendorUser: yup.string().required("User is a required field")
  });

  const {
    control,
    getValues,
    trigger,
    formState: { errors: formErrors, isDirty, isValid },
    reset
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      vendorUser: ""
    },
    resolver: yupResolver(addUserAdminRequestSchema)
  });

  const { vendorUser } = getValues();

  const [updateUser] = useMutation(["vendor-users", vendorUser], {
    method: "PATCH",
    client: manageClient
  });

  const handleSubmit = useCallback(async () => {
    const response = await updateUser({
      "is-admin": true
    });

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error adding the user"
          details={[]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="success"
        title="User has been successfully added"
        details={[]}
      />
    );

    handleClose();
  }, [updateUser, vendorUser]);

  const userOptions = vendorUsers?.map(user => {
    return {
      value: user.id,
      label: user.userName
        ? `${user.userName} <${user.userEmail}>`
        : user.userInviteEmail
    };
  });

  const handleClose = () => {
    reset();
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="AddUserToUserGroupModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Add Admin User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Add Admin User
      </ModalHeader>
      <ModalBody>
        <div>
          <div className={styles["remove-margin"]}>
            <FormFieldLabel
              title="Select User"
              tooltip="Please select a user"
            />
          </div>
          <Controller
            name="vendorUser"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => {
              return (
                <FormSelect
                  id="VendorUserId"
                  name={name}
                  isLoading={isLoading}
                  options={userOptions}
                  value={() => userOptions?.find(o => o.value === value)}
                  data-testid="addUsertoAdminModal_vendorUserId"
                  onChange={formSelectOnChangeParam => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                    trigger("vendorUser");
                  }}
                  invalid={!!formErrors.vendorUser}
                  feedback="Select User is a required field"
                  onBlur={onBlur}
                  classNamePrefix="modalFormSelect"
                />
              );
            }}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={handleSubmit}
            disabled={
              !isDirty || !isValid || Object.keys(formErrors).length !== 0
            }
            data-testid="AddUserToAdminModal_submit"
          >
            Add Admin
          </Button>
          <Button
            color="light"
            outline
            onClick={handleClose}
            data-testid="AddUserToUserGroupModal_cancel"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUserToAdminModal;
