import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "@nef/core";

import { ScheduleRules } from "./util";
import styles from "./Scheduler.module.scss";

const NonRecurringView = () => {
  const { control } = useFormContext<ScheduleRules>();

  return (
    <div className={styles.row}>
      <Controller
        name="nonRecurring.custom.note"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            id={name}
            name={name}
            label="Notes"
            placeholder="Notes"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};

export default NonRecurringView;
