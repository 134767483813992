import { decamelizeKeys } from "humps";

import { NewPlan, PlanCategory } from "../types";

const ALLOWED_KEYS: (keyof NewPlan)[] = [
  "interval",
  "intervalCount",
  "allowContactSales",
  "allowPayment",
  "amountCents",
  "currency",
  "active",
  "name",
  "filterLabels"
];

const createPlanBody = ({
  plan,
  planCategory
}: {
  plan: Partial<NewPlan>;
  planCategory?: PlanCategory;
}) => {
  const sanitizedPlan: any = {};
  ALLOWED_KEYS.forEach(key => {
    sanitizedPlan[key] = plan[key];
  });

  if (!plan.id && planCategory) {
    sanitizedPlan.planCategory = planCategory;
  }

  return decamelizeKeys(sanitizedPlan, { separator: "-" });
};

export default createPlanBody;
