import React, { useState } from "react";
import { useMutation, StringMap } from "jsonapi-react";
import { Button } from "@nef/core";
import { useHistory, generatePath } from "react-router-dom";
import { toast } from "react-toastify";

import { Product, CapProductToEdit } from "../../../api/types";
import CapSurveyFormBody from "../CapSurveyFormBody";
import { PATHS } from "../../../routes";
import CapFinalizeButton from "../components/CapFinalizeButton";
import useCapProductContext from "../../../hooks/useCapProductContext";
import { FinalizeType } from "../../../contexts/product";
import { ConfirmationModal } from "../../modals/ConfirmationModal";
import Toast from "../../Toast";
import handleProductResponseToast from "../../product-form/product-util";
import useDefaultAuth from "../../../hooks/useDefaultAuth";

import styles from "./CapSurveyFormComplete.module.scss";

const CapSurveyFormComplete = () => {
  const history = useHistory();
  const { product } = useCapProductContext();
  const productId = (product as CapProductToEdit).id;
  const { authenticatedUser } = useDefaultAuth();

  const isProductApprover =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  const [editProduct] = useMutation<Product>(["products", productId]);

  const [shouldConfirmFinalize, setShouldConfirmFinalize] =
    useState<FinalizeType>();

  const redirectToIndexPage = () => {
    history.push(PATHS.PUBLISHERS);
  };

  const tryFinalize = () => {
    setShouldConfirmFinalize("submit_for_approval");
    return true;
  };

  const outroText = () => {
    return "Thank you for completing the form. Our Platform Operations team will review the information provided once you’ve submitted the form. If you feel further changes may be required you can save and exit to continue at a later point. If you need assistance, please contact: datalinkonboarding@nasdaq.com";
  };

  const submitForApproval = async () => {
    setShouldConfirmFinalize(undefined);

    const { error, errors } = await editProduct({
      "product-approval-status": "intake_pending_approval"
    });

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    if (allErrors.length) {
      const errorDetails = allErrors.map(e => {
        return { message: e.detail };
      });

      toast(
        <Toast
          type="error"
          title="Product cannot be sent for approval"
          details={errorDetails}
        />
      );
    } else {
      handleProductResponseToast({
        productName: product.name,
        action: "submit_for_approval",
        errors: []
      });
    }
  };

  const approveIntake = async () => {
    setShouldConfirmFinalize(undefined);

    const { error, errors } = await editProduct({
      "product-approval-status": "draft"
    });

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    if (allErrors.length) {
      const errorDetails = allErrors.map(e => {
        return { message: e.detail };
      });

      toast(
        <Toast
          type="error"
          title="Product cannot be approved"
          details={errorDetails}
        />
      );
    } else {
      handleProductResponseToast({
        productName: product.name,
        action: "approve",
        errors: []
      });

      history.push(
        generatePath(PATHS.EDIT_PRODUCT, {
          productId
        })
      );
    }
  };

  return (
    <CapSurveyFormBody>
      <p data-testid="capSurveyFormComplete">{outroText()}</p>
      <div className={styles.controls}>
        <Button
          onClick={() => redirectToIndexPage()}
          data-testid="capForm_save"
        >
          Save & Exit
        </Button>
        <CapFinalizeButton tryFinalize={tryFinalize} />

        {isProductApprover &&
          product.productApprovalStatus === "intake_pending_approval" && (
            <Button
              style={{ marginLeft: "auto" }}
              onClick={() => approveIntake()}
              data-testid="capForm_approve"
            >
              Approve
            </Button>
          )}
      </div>

      <ConfirmationModal
        isOpen={shouldConfirmFinalize === "submit_for_approval"}
        question="Your product page will be submitted for approval. Are you sure?"
        onConfirm={() => submitForApproval()}
        onDismiss={() => setShouldConfirmFinalize(undefined)}
      />
    </CapSurveyFormBody>
  );
};

export default CapSurveyFormComplete;
