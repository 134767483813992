import {
  array,
  object,
  string,
  addMethod,
  AnySchema,
  reach,
  ObjectSchema,
  AnyObjectSchema
} from "yup";

declare module "yup" {
  // tslint:disable-next-line
  interface ArraySchema<T> {
    unique(mapper: (a: T) => T, message?: any): ArraySchema<T>;
    minOf(min: number, schema: AnySchema, filterBy: string): ArraySchema<T>;
    lengthOf(
      length: number,
      schema: AnySchema,
      filterBy: string
    ): ArraySchema<T>;
  }
}

type KeyMap = {
  [key: string]: string[];
};

/**
 * Convert nested keys by removing the root key, and group them all by the original root key.
 * - Example input: ["a1.b1.c1", "a1.b2", "a2.b3"]
 * - Example output: { "a1": ["b1.c1", "b2"], "a2": ["b3"] }
 * This allows `ObjectSchema.pickNested` to recursively pick and construct from original schema.
 * @param paths
 * @returns
 */
const groupNestedKeys = (keys: string[]) => {
  const nestedPaths = keys.filter(key => key.includes("."));
  return nestedPaths
    .map(key => key.split("."))
    .map(([root, ...innerSegments]) => {
      return {
        root,
        innerKey: innerSegments.join(".")
      };
    })
    .reduce(
      (acc: KeyMap, { root, innerKey }: { root: string; innerKey: string }) => {
        acc[root] = acc[root] || [];
        acc[root].push(innerKey);

        return acc;
      },
      {}
    );
};

/* eslint-disable func-names, no-template-curly-in-string */
addMethod(array, "unique", function (mapper = (a: any) => a, message: string) {
  return this.test("unique", message, (list: any) => {
    if (!list) return false;
    return list.length === new Set(list.map(mapper)).size;
  });
});

// Similar to `ObjectSchema.pick` but works with nested fields too.
addMethod(object, "pickNested", function pickNested(keys: string[]) {
  // For non-nested paths, use `ObjectSchema.pick`
  const simpleKeys = keys.filter(key => !key.includes("."));
  let baseSchema: any = this.pick(simpleKeys);

  // For nested paths, use `groupNestedKeys` to help construct inner schema
  const nestedKeyMap = groupNestedKeys(keys);
  Object.entries(nestedKeyMap).forEach(([root, innerKey]) => {
    const baseChildSchema: AnySchema = reach(this, root);
    // If the referenced schema is ObjectSchema, recursively construct inner object schema.
    // If not, just return the schema as is
    const childSchema =
      baseChildSchema instanceof ObjectSchema
        ? baseChildSchema.pickNested(innerKey)
        : baseChildSchema;

    baseSchema = baseSchema.concat(object({ [root]: childSchema }));
  });

  return baseSchema;
});

/**
 * Similar to yup.array().min(), but only matching for specific object type, and performs
 * validation on the matched elements.
 * In order to make this work, the function requires 3 params:
 * - min: minimum number of object type in array
 * - schema: validates matched elements
 * - filterBy: the field in schema that determines the object type
 */
addMethod(
  array,
  "minOf",
  function (min: number, schema: AnyObjectSchema, filterBy: string) {
    return this.test({
      name: "filterMinOf",
      test: (entries: any[] | undefined) => {
        if (!entries) return false;

        const filter = schema.pick([filterBy]);
        return entries.filter(entry => filter.isValidSync(entry)).length >= min;
      },
      params: { typeLabel: schema.describe().label, min },
      message:
        min === 1
          ? "${typeLabel} must have at least ${min} enintry"
          : "${typeLabel} must have at least ${min} entries"
    }).test({
      name: "validateMinOf",
      test: (entries: any[] | undefined) => {
        if (!entries) return false;

        const filter = schema.pick([filterBy]);
        return entries
          .filter(entry => filter.isValidSync(entry))
          .every(entry => schema.isValidSync(entry));
      },
      params: { typeLabel: schema.describe().label, min },
      message: "${typeLabel} is missing data"
    });
  }
);

/**
 * Similar to yup.array().minOf(), but matches for an exact number of object type
 */
addMethod(
  array,
  "lengthOf",
  function (length: number, schema: AnyObjectSchema, filterBy: string) {
    return this.test({
      name: "filterLengthOf",
      test: (entries: any[] | undefined) => {
        if (!entries) return false;

        const filter = schema.pick([filterBy]);
        return (
          entries.filter(entry => filter.isValidSync(entry)).length === length
        );
      },
      params: { typeLabel: schema.describe().label, length },
      message:
        length === 1
          ? "${typeLabel} must have ${length} entry"
          : "${typeLabel} must have ${length} entries"
    }).test({
      name: "validateLengthOf",
      test: (entries: any[] | undefined) => {
        if (!entries) return false;

        const filter = schema.pick([filterBy]);
        return entries
          .filter(entry => filter.isValidSync(entry))
          .every(entry => schema.isValidSync(entry));
      },
      params: { typeLabel: schema.describe().label, length },
      message: "${typeLabel} is missing data"
    });
  }
);

addMethod(
  string,
  "requiredIf",
  function (condition: boolean, message?: string) {
    return condition ? this.required(message) : this.notRequired();
  }
);

/* eslint-enable func-names, no-template-curly-in-string */
