import * as yup from "yup";

// The builder pattern here allows the product schema to display
// our custom error messages. This is because `yup.setLocale` only
// affects schemas created after the localization is called.
// (reference: https://github.com/jquense/yup/issues/1008)
const buildProductSchema = (isDataHosted: boolean) => {
  return yup
    .object({
      name: yup.string().min(2).max(64).required(),
      code: yup.string().required().uppercase(),
      description: yup.string().required(),
      since: yup.string().nullable().when("history", {
        is: "since",
        then: yup.string().required()
      }),
      sample: yup.boolean(),
      internalSales: yup.boolean().nullable(),
      gisSalesTeamEmail: yup
        .string()
        .email("Contact sales email must be a valid email")
        .when("allowContactSales", {
          is: true,
          then: yup.string().required("Contact sales email cannot be blank"),
          otherwise: yup.string().notRequired().nullable()
        }),
      documentation: yup.string().required(),
      vendor: yup.object().required(),
      premium: yup.boolean(),
      allowContactSales: yup.boolean().required(),
      exclusive: yup.boolean().required(),
      active: yup.boolean(),
      hidden: yup.boolean(),
      sdgIndicators: yup.array(yup.string()).min(1),
      rank: yup.number(),
      datatables: yup
        .array(
          yup
            .object({
              code: yup.string().required()
            })
            .typeError("Invalid data table values")
        )
        .nullable(),
      deliveryFrequency: isDataHosted
        ? yup
            .array(yup.string())
            .min(
              1,
              "Delivery Frequency must have at least one value for a hosted datatable"
            )
        : yup.array(yup.string()),
      dataFrequency: isDataHosted
        ? yup
            .array(yup.string())
            .min(
              1,
              "Data Frequency must have at least one value for a hosted datatable"
            )
        : yup.array(yup.string()),
      plans: yup
        .array()
        .nullable()
        // check if the product is about to be published
        .when(
          ["$isDraftProduct", "active", "productApprovalStatus", "forSale"],
          {
            is: (
              isDraftProduct: boolean,
              active: boolean,
              productApprovalStatus: string | null,
              forSale: boolean | null
            ) =>
              isDraftProduct &&
              forSale &&
              (active || productApprovalStatus === "pending_approval"),
            then: schema =>
              schema
                .ensure()
                .min(1, "Published product must have at least 1 pricing plan")
          }
        )
        // this must come after `.when(["$isDraftProduct", "active"])` to override
        // the criteria when applicable (ie. "Publish Internally", "Contact Sales")
        .when(["allowContactSales", "exclusive"], {
          is: (allowContactSales: boolean, exclusive: boolean) =>
            allowContactSales || exclusive,
          then: schema => schema.min(0)
        })
    })
    .test(
      "pricing-option-fields",
      "Only 1 pricing option is allowed",
      value => {
        const isComboInvalid = !!value.allowContactSales && !!value.exclusive;
        return !isComboInvalid;
      }
    );
};

export default buildProductSchema;
