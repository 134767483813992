import React from "react";

import { FilterableColumnValues } from "../../../api/types";

import styles from "./FilterColumnButton.module.scss";

interface FilterColumnButtonProps {
  name: string;
  field: FilterableColumnValues;
  amount: number;
  setFilter: (field: FilterableColumnValues) => void;
  currentFilter: FilterableColumnValues;
}

const FilterColumnButton = ({
  name,
  amount,
  field,
  currentFilter,
  setFilter
}: FilterColumnButtonProps) => {
  const buttonClass = `${styles.button} ${
    currentFilter === field ? styles["button-active"] : ""
  }`;

  return (
    <button
      type="button"
      onClick={() =>
        currentFilter === field ? setFilter(null) : setFilter(field)
      }
      className={buttonClass}
      data-testid={`filterColumnButton_${field}`}
    >
      [{amount}] {name}
    </button>
  );
};

export default FilterColumnButton;
