import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

import {
  EndFieldState,
  EndRule,
  ScheduleRules,
  fieldToRruleDate,
  rruleToFieldDate
} from "../util";
import styles from "../Scheduler.module.scss";

const stateToRule = (state: EndFieldState): EndRule => {
  const { endMode, until, count } = state;

  const newUntil = endMode === "on-this-day" ? until : undefined;
  const newCount = endMode === "after" ? count : undefined;

  return {
    until: newUntil,
    count: newCount
  };
};

export const ruleToState = (rule: EndRule): EndFieldState => {
  const { until, count } = rule;

  const defaultState: EndFieldState = {
    endMode: "no-end-date",
    until: fieldToRruleDate("", "yyyy-MM-dd"), // default to current date
    count: 1
  };

  if (until) {
    return {
      ...defaultState,
      endMode: "on-this-day",
      until
    };
  }

  if (count) {
    return {
      ...defaultState,
      endMode: "after",
      count
    };
  }

  return defaultState;
};

const useEndFieldState = (rule: EndRule) => {
  const { setValue } = useFormContext<ScheduleRules>();

  const [state, setState] = useState<EndFieldState>(() => ruleToState(rule));

  const updateForm = (stateValue: EndFieldState) => {
    const newRule = stateToRule(stateValue);
    setValue("recurring.until", newRule.until);
    setValue("recurring.count", newRule.count);
  };

  const setEndMode = (newEndMode: string) => {
    const newState = { ...state, endMode: newEndMode };
    setState(newState);
    updateForm(newState);
  };

  const setUntil = (newUntil: string) => {
    const newState = { ...state, until: newUntil };
    setState(newState);
    updateForm(newState);
  };

  const setCount = (newCount: number) => {
    const newState = { ...state, count: newCount };
    setState(newState);
    updateForm(newState);
  };

  return { state, setEndMode, setUntil, setCount };
};

const EndField = ({ rule }: { rule: EndRule }) => {
  const { state, setEndMode, setUntil, setCount } = useEndFieldState(rule);

  const { endMode, until, count } = state;

  return (
    <>
      <div className={styles.label}>End</div>
      <div className={styles["input-group"]}>
        <select
          id="endType"
          className={styles.input}
          value={endMode}
          onChange={e => {
            setEndMode(e.target.value);
          }}
        >
          <option value="on-this-day">on this day</option>
          <option value="after">after</option>
          <option value="no-end-date">no end date</option>
        </select>

        {endMode === "on-this-day" && (
          <input
            type="date"
            id="until"
            value={rruleToFieldDate(until, "yyyy-MM-dd")}
            className={styles.input}
            onChange={e => {
              setUntil(fieldToRruleDate(e.target.value, "yyyy-MM-dd"));
            }}
          />
        )}

        {endMode === "after" && (
          <>
            <input
              type="number"
              id="count"
              value={count || 0}
              className={`${styles.input} ${styles["input--narrow"]}`}
              onChange={e => {
                setCount(parseInt(e.target.value, 10));
              }}
            />
            <div>occurrences</div>
          </>
        )}
      </div>
    </>
  );
};

export default EndField;
