import React, { useState } from "react";
import {
  FormSelect,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button
} from "@nef/core";
import { Option } from "@nef/core/lib/components/components/FormBaseSelect";

import { EventAction } from "../../types";
import { DatatableAccessor } from "../../datatable/types";

import styles from "./index.module.scss";

interface Props {
  originKey: string;
  originVersion: string;
  datatableKeys: DatatableAccessor[];
  onSubmit: (
    originKey: string,
    originVersion: string,
    selectedDatatableKeys: string,
    selectedDatatableKeyVersion: string
  ) => void;
  onClose: () => void;
}

const CopyDatatable = ({
  originKey,
  originVersion,
  datatableKeys,
  onClose,
  onSubmit
}: Props) => {
  const [selectedDatatable, setSelectedDatatable] =
    useState<DatatableAccessor | null>(null);

  const options: Option[] = datatableKeys
    .filter(datatable => datatable.key !== originKey)
    .map(datatable => {
      return {
        label: datatable.key,
        value: datatable
      };
    });

  const handleOnChange = (event: any) => {
    setSelectedDatatable(event.value.value);
  };

  return (
    <Modal isOpen data-testid="copyDatatableModal">
      <ModalHeader toggle={() => onClose()}>
        Select Datatable to copy to
      </ModalHeader>
      <ModalBody>
        <FormSelect
          placeholder="Select..."
          options={options}
          onChange={e => {
            handleOnChange(e as unknown as EventAction);
          }}
          classNamePrefix="modalFormSelect"
        />
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button
          size="md"
          className="danger"
          color="danger"
          onClick={onClose}
          data-testid="copyDatatableModal_dismiss"
        >
          Cancel
        </Button>

        <Button
          size="md"
          disabled={selectedDatatable === null}
          onClick={() => {
            if (selectedDatatable?.key && selectedDatatable?.key !== null) {
              onSubmit(
                originKey,
                originVersion,
                selectedDatatable.key,
                selectedDatatable.version
              );
            }
          }}
          data-testid="copyDatatableModal_confirm"
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CopyDatatable;
