import React, { useCallback, useEffect } from "react";
import {
  Button,
  FormField,
  FormSelect,
  FormTextArea,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import styled from "styled-components";
import { decamelizeKeys } from "humps";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { QueryArg, useMutation, useQuery } from "jsonapi-react";

import { HelpscoutCategory } from "../../api/types";
import webClient from "../../api/web/client";
import { extractErrors } from "../../utils/react-hook-form-utils";
import Toast from "../Toast";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import styles from "./ContactSupportModal.module.scss";

interface SupportRequest {
  name: string;
  email: string;
  categoryId: string;
  comment: string;
}

const DEFAULT_SUPPORT_REQUEST: SupportRequest = {
  name: "",
  email: "",
  categoryId: "",
  comment: ""
};

interface ContactSupportModalProps {
  isOpen: boolean;
  close: () => void;
}

const FullWidthTextArea = styled(FormTextArea)`
  width: 100%;
`;

const supportRequestSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  categoryId: yup.string().required("category must be selected"),
  comment: yup.string().required()
});

const ContactSupportModal = ({ isOpen, close }: ContactSupportModalProps) => {
  const { authenticatedUser } = useDefaultAuth();

  const categoriesQuery: QueryArg = isOpen ? "helpscout-categories" : false;
  const { data: helpscoutCategories } = useQuery<HelpscoutCategory[]>(
    categoriesQuery,
    { client: webClient }
  );

  const categoryOptions = (helpscoutCategories || []).map(category => {
    return {
      value: category.id,
      label: category.hsName
    };
  });

  const [createSupportRequest] = useMutation("support-requests", {
    client: webClient
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { ...DEFAULT_SUPPORT_REQUEST },
    resolver: yupResolver(supportRequestSchema)
  });

  const onSubmit: SubmitHandler<SupportRequest> = useCallback(
    async data => {
      const normalizedSupportRequest: any = decamelizeKeys(data, {
        separator: "-"
      });

      const response = await createSupportRequest(normalizedSupportRequest);

      const { error, errors } = response;

      if (error || errors) {
        const responseErrors = error ? [error] : errors;

        if (!responseErrors) return;

        const formattedErrors = responseErrors.map(e => ({
          message: e.detail
        }));

        toast(
          <Toast
            type="error"
            title="The support request cannot be submitted"
            details={formattedErrors}
          />
        );

        return;
      }

      toast(
        <Toast
          type="success"
          title="Your support request is submitted"
          details={[]}
        />
      );

      close();
    },
    [helpscoutCategories]
  );

  const onSubmitError: SubmitErrorHandler<SupportRequest> = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="The support request cannot be submitted"
        details={errorDetails}
      />
    );

    return false;
  };

  useEffect(() => {
    if (!isOpen) return;

    const defaultValues = { ...DEFAULT_SUPPORT_REQUEST };
    if (authenticatedUser) {
      defaultValues.name = `${authenticatedUser.firstName} ${authenticatedUser.lastName}`;
      defaultValues.email = authenticatedUser.email;
    }
    reset(defaultValues);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      data-testid="contactSupportModal"
      toggle={close}
      closeOnOutsideClick={false}
    >
      <ModalHeader toggle={close}>Have a question?</ModalHeader>
      {helpscoutCategories && helpscoutCategories.length && (
        <ModalBody>
          <div
            className={styles.description}
            data-testid="contactSupportModal_description"
          >
            Please fill out all the fields. We will get back to you within one
            or two business days.
          </div>
          <div>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="Name"
                  name={name}
                  type="text"
                  placeholder="Name"
                  value={value}
                  onChange={onChange}
                  data-testid="contactSupportModal_name"
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="Email"
                  name={name}
                  type="email"
                  placeholder="Email"
                  value={value}
                  onChange={onChange}
                  data-testid="contactSupportModal_email"
                />
              )}
            />
            <Controller
              name="categoryId"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormSelect
                  id="CategoryId"
                  name={name}
                  options={categoryOptions}
                  value={categoryOptions.find(o => o.value === value)}
                  placeholder="Category"
                  onChange={(formSelectOnChangeParam: any) => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  isClearable={false}
                  classNamePrefix="categoryId"
                />
              )}
            />
            <Controller
              name="comment"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FullWidthTextArea
                  id="Comments"
                  name={name}
                  placeholder="Comment"
                  value={value}
                  onChange={onChange}
                  rows={4}
                  data-testid="contactSupportModal_comment"
                />
              )}
            />
            <Button
              onClick={handleSubmit(onSubmit, onSubmitError)}
              data-testid="contactSupportModal_submit"
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default ContactSupportModal;
