import React from "react";

const ContextMenu = () => (
  <svg
    width="25"
    height="6"
    viewBox="0 0 25 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="21.347"
      cy="2.78436"
      r="2.78436"
      transform="rotate(90 21.347 2.78436)"
      fill="#949494"
    />
    <circle
      cx="12.0659"
      cy="2.78448"
      r="2.78436"
      transform="rotate(90 12.0659 2.78448)"
      fill="#949494"
    />
    <circle
      cx="2.78473"
      cy="2.78448"
      r="2.78436"
      transform="rotate(90 2.78473 2.78448)"
      fill="#949494"
    />
  </svg>
);

export default ContextMenu;
