import { CircleButton, FontAwesomeIcon } from "@nef/core";
import React, { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";

import styles from "./product-card.module.scss";
import ProductAccess from "./product-access";
import DatatableEntitlements from "./datatable";
import { formSchema } from "./schema";
import Notes from "./product-card/components/notes";
import SalesRepresentative from "./product-card/components/sales-representative";
import TechnicalContacts from "./product-card/components/technical-contacts";
import BusinessContacts from "./product-card/components/business-contacts";
import AccessEnd from "./product-card/components/access-end";
import AccessStart from "./product-card/components/access-start";
import SubscriptionType from "./product-card/components/subscription-type";
import WelcomeEmailCC from "./product-card/components/welcome-email-cc";

import { OrganizationTeamInvoice, SalesManager } from "./index";

function ProductCard({
  title,
  salesManagers,
  formName,
  children,
  existingInvoice,
  datatables
}: React.PropsWithChildren<{
  title?: string;
  formName: string;
  salesManagers: SalesManager[];
  existingInvoice?: OrganizationTeamInvoice;
  datatables?: { code: string }[];
}>) {
  const [folded, setFolded] = useState(false);

  const { getValues, setValue, control } =
    useFormContext<yup.Asserts<typeof formSchema>>();

  const { salesRepresentative, accessStart, accessEnd } =
    getValues(`${formName}` as any) || {};

  const subscriptionType = useWatch({
    control,
    name: `${formName}.subscriptionType` as any
  });

  useEffect(() => {
    if (subscriptionType === "autoRenew") {
      setValue(`${formName}.accessEnd` as any, null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
    }
  }, [subscriptionType]);

  return (
    <div
      key={title}
      className={`${styles["product-card"]} ${
        existingInvoice ? styles["existing-product-card"] : ""
      }`}
      data-testid={formName}
    >
      <div className={styles["product-header"]}>
        <div className={styles["product-title"]}>
          <CircleButton
            ghost={true}
            onClick={() => {
              setFolded(v => !v);
            }}
          >
            {folded ? (
              <FontAwesomeIcon
                iconClassName="fa-angle-down"
                iconSetClassName="fas"
              />
            ) : (
              <FontAwesomeIcon
                iconClassName="fa-angle-up"
                iconSetClassName="fas"
              />
            )}
          </CircleButton>
          <h4>{title}</h4>
        </div>
        {children}
      </div>
      <div className={styles["product-content"]}>
        {!folded && (
          <>
            <div
              className={styles["product-drawer-container"]}
              data-testid="OrganizationsAddProductDrawer_form"
            >
              <SubscriptionType
                formFieldName={`${formName}.subscriptionType`}
                initialValue={existingInvoice?.subscriptionType}
                resetValue={subscriptionType}
                autoRenew={!!existingInvoice?.autoRenewal}
                trial={!!existingInvoice?.trial}
              />
              <div className={styles.flex}>
                <AccessStart
                  formFieldName={`${formName}.accessStart`}
                  initialValue={existingInvoice?.startDate}
                  resetValue={accessStart}
                />
                <AccessEnd
                  formFieldName={`${formName}.accessEnd`}
                  initialValue={existingInvoice?.endDate}
                  resetValue={accessEnd}
                  disabled={subscriptionType === "autoRenew"}
                />
              </div>
              <div className={styles.flex}>
                <BusinessContacts
                  formFieldName={`${formName}.businessContacts`}
                />
                <TechnicalContacts
                  formFieldName={`${formName}.technicalContacts`}
                />
              </div>
              <div className={styles.flex}>
                <SalesRepresentative
                  formFieldName={`${formName}.salesRepresentative`}
                  initialValue={existingInvoice?.salesManagerId || ""}
                  resetValue={salesRepresentative}
                  salesManagers={salesManagers}
                />
                <WelcomeEmailCC formFieldName={`${formName}.welcomeEmailCc`} />
              </div>
              <Notes formFieldName={`${formName}.notes`} />
            </div>

            <ProductAccess formFieldName={`${formName}.productAccess`} />

            {datatables && (
              <DatatableEntitlements
                formName={`${formName}.datatables`}
                initialDatatables={datatables}
                existingFilters={existingInvoice?.filters || []}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
