import { SchemaError } from "../../../api/types";

import { Validation } from "./types";

import { HEADER_ROW_COUNT } from ".";

type ColumnNamesObject = {
  columnNames: string[];
};

const ColumnNamesValidation: Validation<ColumnNamesObject> = {
  extractData: (_, rows) => {
    const columnNames = rows.map(row => row[0]);

    return {
      columnNames
    };
  },
  validate: data => {
    const { columnNames } = data;

    const results: SchemaError[] = [];

    columnNames.forEach((columnName, index) => {
      const rowNumber = index + 1 + HEADER_ROW_COUNT;

      if (columnName) {
        if (columnName.match(/\s/g)) {
          results.push({
            type: "column-name-with-space",
            title: `Space included in \`${columnName}\` in Row ${rowNumber}. Please eliminate spacing.`,
            message: "Please eliminate spacing."
          });
        }

        if (columnName.match(/[^a-zA-Z0-9_\s]/g)) {
          results.push({
            type: "column-name-with-invalid-character",
            title: `Non-standard character included in \`${columnName}\` in Row ${rowNumber}. Please remove.`,
            message: "Please remove non-standard characters."
          });
        }
      } else {
        results.push({
          type: "column-name-missing",
          title: `Empty value for Column Name in Row ${rowNumber}. Please fill out value.`,
          message: "Please fill out Column Name"
        });
      }
    });

    return results;
  }
};

export default ColumnNamesValidation;
