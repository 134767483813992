import React, { useEffect, useRef, useState } from "react";
import { camelizeKeys, decamelizeKeys } from "humps";
import { StringMap, useMutation } from "jsonapi-react";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  ProductToEdit,
  ProductMetadatumFormValues,
  Product,
  ProductFormValues,
  ProductFormMode,
  ProductMetadatumToEdit,
  CapProductMetadatum
} from "../../api/types";
import ProductContext, {
  EditProductContextProps,
  FinalizeType
} from "../../contexts/product";
import { extractErrors } from "../../utils/react-hook-form-utils";
import Toast from "../Toast";
import { Wizard } from "../wizard";
import usePlansQuery from "../../hooks/usePlansQuery";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import LoadingIndicator from "../common/LoadingIndicator";
import { PATHS } from "../../routes";
import { createProductBody } from "../../api/normalizers/product";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import Header from "./Header";
import handleProductResponseToast from "./product-util";
import PricingPlansStep from "./PricingPlansStep";
import GrandfatheredPricingPlansStep from "./GrandfatheredPricingPlansStep";
import ProductStep from "./ProductStep";

interface EditProductFormProps {
  product: ProductToEdit;
  setProduct: (product: ProductToEdit) => void;
  productSchema: any;
}

class PublishError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PublishError";
  }
}

const EditProductForm = ({
  product,
  setProduct,
  productSchema
}: EditProductFormProps) => {
  const history = useHistory();
  const { authenticatedUser } = useDefaultAuth();
  const [editProduct] = useMutation<Product>(["products", product.id]);
  const [publishProductCall] = useMutation<Product>(
    ["products", product.id, "publish"],
    { method: "PATCH", invalidate: ["products"] }
  );

  const isManagerAdmin =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  const isProductFormSubmitSuccessfulRef = useRef(false);

  /**
   * Product Update Hooks
   */

  const productForm = useFormContext<ProductToEdit>();
  const {
    handleSubmit: handleProductSubmit,
    formState: {
      isSubmitSuccessful: isProductFormSubmitSuccessful,
      dirtyFields
    },
    getValues,
    reset
  } = productForm;
  isProductFormSubmitSuccessfulRef.current = isProductFormSubmitSuccessful;
  const {
    productMetadatum: productMetadatumIsDirty,
    capProductMetadatum: capProductMetadatumIsDirty,
    ...productFieldsIsDirty
  } = dirtyFields;

  const saveProduct = async () => {
    await handleProductSubmit(onSubmitAll, onSubmitAllError)().catch(() => {});
  };

  const onSubmitAll: SubmitHandler<ProductToEdit> = async formData => {
    let metadataResponse;
    let capMetadataResponse;
    let productResponse;

    if (
      productMetadatumIsDirty &&
      Object.values(productMetadatumIsDirty).some(val => val === true)
    ) {
      metadataResponse = await onSubmitUpdateMetadata(
        formData.productMetadatum
      );
    }
    if (
      capProductMetadatumIsDirty &&
      Object.values(capProductMetadatumIsDirty).some(val => val === true)
    ) {
      capMetadataResponse = await onSubmitUpdateCapMetadata(
        formData.capProductMetadatum
      );
    }
    if (
      productFieldsIsDirty &&
      Object.values(productFieldsIsDirty).some(val => val === true)
    ) {
      productResponse = await onSubmitEditProduct(formData);
    }

    const allErrors = [
      ...[productResponse?.errors || [], productResponse?.error],
      ...[metadataResponse?.errors || [], metadataResponse?.error],
      ...[capMetadataResponse?.errors || [], capMetadataResponse?.error]
    ]
      .filter((e): e is StringMap => !!e)
      .flat();

    let productData;
    if (productResponse?.data) {
      productData = camelizeKeys(productResponse.data) as unknown as Product;
    }
    let metadataData;
    if (metadataResponse?.data) {
      metadataData = camelizeKeys(
        metadataResponse.data
      ) as unknown as ProductMetadatumToEdit;
    }

    const productAlreadyPublished = productData?.active
      ? productData?.active
      : product?.active;

    const showSuccessToast =
      !isPublishingRef.current || productAlreadyPublished;

    const getActionStatus = () => {
      if (productAlreadyPublished) {
        return "update_live";
      }
      if (product.active && !product.hidden) {
        return "publish";
      }
      return "update";
    };

    if (allErrors?.length || showSuccessToast) {
      handleProductResponseToast({
        productName: product.name,
        action: getActionStatus(),
        errors: allErrors
      });
    }

    const { productMetadatum, ...productFormFields } = formData;

    reset({
      ...product,
      ...(productData || productFormFields),
      productMetadatum: {
        ...(metadataData || productMetadatum)
      }
    });

    if (allErrors?.length) {
      throw allErrors;
    }
  };

  const onSubmitAllError: SubmitErrorHandler<ProductToEdit> = async errors => {
    await onSubmitEditProductError(errors);
  };

  const showPricingPlansTab = !!product?.capProductMetadatum?.pricingOption;

  const onSubmitEditProduct: SubmitHandler<ProductToEdit> = async formData => {
    const editedProductValues = productSchema.cast(formData);
    const normalizedProduct = createProductBody(
      editedProductValues,
      false,
      isManagerAdmin
    );

    const productResponse = await editProduct(normalizedProduct);
    return productResponse;
  };

  const onSubmitEditProductError: SubmitErrorHandler<
    ProductFormValues
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };

  /**
   * Metadata Update Hooks
   */
  const metadatumSchema = yup.object({
    dataFrequencyScore: yup.number().min(0).max(5).nullable(true),
    dataFrequencyDescription: yup.string().nullable(true),
    dataQualityScore: yup.number().min(0).max(5).nullable(true),
    dataQualityDescription: yup.string().nullable(true),
    lengthOfHistoryScore: yup.number().min(0).max(5).nullable(true),
    lengthOfHistoryDescription: yup.string().nullable(true),
    marketAwarenessScore: yup.number().min(0).max(5).nullable(true),
    marketAwarenessDescription: yup.string().nullable(true),
    uniquenessScore: yup.number().min(0).max(5).nullable(true),
    uniquenessDescription: yup.string().nullable(true),
    status: yup.string().nullable(true),
    grade: yup.number().min(0).max(100).nullable(true),
    frameworkRegulations: yup.string().nullable(),
    sdgIndicators: yup.array(yup.string()),
    rank: yup.number()
  });

  const [editMetadatum] = useMutation<ProductMetadatumToEdit>([
    "product-metadata",
    product?.productMetadatum?.id
  ]);

  const [updateCapProductMetadataum] = useMutation<CapProductMetadatum>(
    ["cap-product-metadata", product?.capProductMetadatum?.id],
    {
      invalidate: ["products"]
    }
  );

  const onSubmitUpdateCapMetadata: SubmitHandler<
    CapProductMetadatum
  > = async formData => {
    const normalizedMetadata: any = decamelizeKeys(formData, {
      separator: "-"
    });

    const { data, error, errors } = await updateCapProductMetadataum(
      normalizedMetadata
    );

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    return { data, errors: allErrors };
  };

  const onSubmitUpdateMetadata: SubmitHandler<
    ProductMetadatumFormValues
  > = async formData => {
    const newMetadatum = metadatumSchema.cast(formData);
    const normalizedMetadatum = decamelizeKeys(newMetadatum, {
      separator: "-"
    });

    if (!isManagerAdmin) {
      // @ts-ignore
      delete normalizedMetadatum.rank;
    }
    const { data, error, errors } = await editMetadatum(normalizedMetadatum);

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    return { data, errors: allErrors };
  };

  /**
   * Publish hooks
   */
  const [shouldConfirmFinalize, setShouldConfirmFinalize] =
    useState<FinalizeType>();
  const isPublishingRef = useRef(false);

  const tryFinalize = (type: FinalizeType) => {
    setShouldConfirmFinalize(type);
    return true;
  };

  const publish = async () => {
    setShouldConfirmFinalize(undefined);
    isPublishingRef.current = true;
    const productRequiresPublishing = product.hidden;

    try {
      if (productRequiresPublishing) {
        productForm.setValue("active", true, {
          shouldValidate: true,
          shouldDirty: true
        });
        productForm.setValue("hidden", false, {
          shouldValidate: true,
          shouldDirty: true
        });
      }

      await saveProduct();

      if (!isProductFormSubmitSuccessfulRef.current) {
        throw new PublishError("Failed to edit product");
      }

      if (productRequiresPublishing) await publishProduct();

      await activatePlans();
      history.push(`${PATHS.PRODUCTS}?vendorId=${product.vendor.id}`);
    } catch (e) {
      if (e instanceof PublishError) {
        productForm.resetField("active");
        productForm.resetField("hidden");
      }
    } finally {
      isPublishingRef.current = false;
    }
  };

  const submitForApproval = async () => {
    setShouldConfirmFinalize(undefined);
    isPublishingRef.current = true;

    try {
      if (
        productMetadatumIsDirty &&
        Object.values(productMetadatumIsDirty).some(val => val === true)
      ) {
        const success = await onSubmitUpdateMetadata(
          getValues("productMetadatum")
        );

        if (success) {
          isPublishingRef.current = false;
          return;
        }
      }

      productForm.setValue("productApprovalStatus", "pending_approval", {
        shouldValidate: true,
        shouldDirty: true
      });

      await saveProductForApproval();

      if (isProductFormSubmitSuccessfulRef.current) {
        history.push(`${PATHS.PRODUCTS}?vendorId=${product.vendor.id}`);
      } else {
        productForm.resetField("productApprovalStatus");
      }
    } finally {
      isPublishingRef.current = false;
    }
  };

  const saveProductForApproval = async () => {
    await handleProductSubmit(
      onSaveProductForApproval,
      onSaveProductForApprovalError
    )().catch(() => {});
  };

  const onSaveProductForApproval: SubmitHandler<
    ProductFormValues
  > = async formData => {
    const editedProductValues = productSchema.cast(formData);
    const normalizedProduct = createProductBody(
      editedProductValues,
      true,
      isManagerAdmin
    );

    return editProduct(normalizedProduct).then(response => {
      const { data, errors, error } = response;

      const allErrors = [...(errors || []), error].filter(
        (e): e is StringMap => !!e
      );

      handleProductResponseToast({
        productName: product.name,
        action: "submit_for_approval",
        errors: allErrors
      });

      if (allErrors.length) {
        throw allErrors;
      }

      return !!data;
    });
  };

  const onSaveProductForApprovalError: SubmitErrorHandler<
    ProductFormValues
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Product cannot be submitted for approval"
        details={errorDetails}
      />
    );
  };

  /* Pricing Plans Activation Hook */
  const [isActivating, setActivating] = useState(false);
  const [canActivatePlans, setCanActivatePlans] = useState(!product.active);

  const { data: draftPlans, isLoading: isLoadingPlans } = usePlansQuery(
    canActivatePlans && {
      planCategories: product.planCategories,
      active: false
    }
  );

  const hasDraftPlansRef = useRef(!!draftPlans?.length);
  useEffect(() => {
    hasDraftPlansRef.current = !!draftPlans?.length;
    productForm.setValue("plans", draftPlans || []);
  }, [draftPlans?.length]);

  const [activateProductPlans] = useMutation(
    ["products", product.id, "activate_plans"],
    {
      method: "PATCH"
    }
  );

  const publishProduct = async () => {
    const { error, errors } = await publishProductCall({});

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    if (allErrors.length) {
      const errorDetails = allErrors.map(e => {
        return { message: e.detail };
      });

      toast(
        <Toast
          type="error"
          title="Product cannot be published"
          details={errorDetails}
        />
      );

      throw new PublishError("Failed to publish product");
    } else {
      handleProductResponseToast({
        productName: product.name,
        action:
          product.productApprovalType === "no_approval_required"
            ? "publish"
            : "approve",
        errors: []
      });
    }
  };

  const activatePlans = async () => {
    if (!hasDraftPlansRef.current) {
      return;
    }

    setActivating(true);
    const { error, errors } = await activateProductPlans({});
    setActivating(false);

    const allErrors = [...(errors || []), error].filter(
      (e): e is StringMap => !!e
    );

    if (allErrors.length) {
      const errorDetails = allErrors.map(e => {
        return { message: e.detail };
      });

      toast(
        <Toast
          type="error"
          title="Pricing Plans cannot be activated"
          details={errorDetails}
        />
      );
    } else {
      setCanActivatePlans(false);

      toast(
        <Toast
          type="success"
          title="Pricing Plans for the product have been activated"
          details={[]}
        />
      );
    }
  };

  /**
   * ProductContext
   */
  const contextValue: EditProductContextProps = {
    mode: ProductFormMode.EDIT,
    isReady: !isLoadingPlans,
    product,
    setProduct,
    productForm,
    saveProduct,
    tryFinalize,
    productApprovalType: product.productApprovalType,
    productApprovalStatus: product.productApprovalStatus
  };

  /**
   * render function
   */
  return (
    <ProductContext.Provider value={contextValue}>
      <Wizard header={<Header />}>
        <ProductStep />
        <>
          {showPricingPlansTab && (
            <>
              <PricingPlansStep />
              <GrandfatheredPricingPlansStep />
            </>
          )}
        </>
      </Wizard>
      <ConfirmationModal
        isOpen={shouldConfirmFinalize === "publish"}
        question="Your product page is about to be published. All Nasdaq Data Link users will be able to see the page. Are you sure?"
        onConfirm={() => publish()}
        onDismiss={() => setShouldConfirmFinalize(undefined)}
      />
      <ConfirmationModal
        isOpen={shouldConfirmFinalize === "approve"}
        question="You are about to approve and publish this product page. All Nasdaq Data Link users will be able to see the page. Are you sure?"
        onConfirm={() => publish()}
        onDismiss={() => setShouldConfirmFinalize(undefined)}
      />
      <ConfirmationModal
        isOpen={shouldConfirmFinalize === "submit_for_approval"}
        question="Your product page will be submitted for approval. All Nasdaq Data Link users will be able to see the page once it is approved. Are you sure?"
        onConfirm={() => submitForApproval()}
        onDismiss={() => setShouldConfirmFinalize(undefined)}
      />
      {isActivating && (
        <LoadingIndicator message="Activating Pricing Plans..." />
      )}
    </ProductContext.Provider>
  );
};

export default EditProductForm;
