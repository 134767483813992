import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "@nef/core";
import { toast } from "react-toastify";
import { useClient } from "jsonapi-react";

import Toast from "../../../../components/Toast";

import styles from "./index.module.scss";

export interface UserGroup {
  id: string;
  createdAt: string;
  productsCount: number;
  usersCount: number;
  name: string;
}

interface RemoveUserGroupProps {
  close: () => void;
  userGroup: UserGroup;
}

const RemoveUserGroupModal = ({ close, userGroup }: RemoveUserGroupProps) => {
  const client = useClient();
  const { id } = userGroup;

  const handleDeleteUserUserGroup = async () => {
    const response = await client.delete(["organization-teams", id]);

    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error deleting the user group"
          details={[]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="success"
        title="User group has been successfully deleted"
        details={[]}
      />
    );

    handleClose();
  };

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      isOpen
      data-testid="RemoveUserGroupModal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalHeader
        title="Delete User"
        toggle={handleClose}
        className={styles["modal-header"]}
      >
        Delete User Group
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <p className={styles.description}>
              Are you sure you want to delete {userGroup.name}?
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              color="danger"
              onClick={handleDeleteUserUserGroup}
              data-testid="RemoveUserGroupModal_submit"
            >
              Delete User Group
            </Button>
            <Button
              color="light"
              outline
              onClick={handleClose}
              data-testid="RemoveUserGroupModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RemoveUserGroupModal;
